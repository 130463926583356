import { ResourceThumbnail } from '@/components/atoms';
import { ColorBox } from '@/components/molecules';
import { DATE_FORMAT_PICKER, DAYS, Filters, PropertyTypes, locale } from '@/const';
import { FieldFilterType, ResourceFilterByRecords } from '@/types';
import { Grid } from '@mui/material';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { loadState } from './localStorage';

export const transformPropertyValue = (
  type: PropertyTypes,
  t: TFunction,
  value?: unknown,
  label?: unknown
) => {
  switch (type) {
    case PropertyTypes.NUMBER:
      return value !== undefined && value !== null ? t('number', { value }) : '';

    case PropertyTypes.DATE: {
      return value
        ? DateTime.fromISO(value as string)
            .setLocale(locale)
            .toFormat(DATE_FORMAT_PICKER as string)
        : '';
    }

    case PropertyTypes.BOOLEAN:
      return value ? t('booleanValues.yes') : value !== undefined ? t('booleanValues.no') : '';

    case PropertyTypes.SET:
      return label ?? (value || '');

    case PropertyTypes.COLOR:
      return value ? <ColorBox value={value as string} includeTextValue /> : '';

    case PropertyTypes.IMAGE:
      if (value)
        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
            <ResourceThumbnail
              src={value as string}
              sx={{ maxHeight: '2.5rem', maxWidth: '2.5rem' }}></ResourceThumbnail>
          </Grid>
        );
      return '';
    case PropertyTypes.WEEKDAYS: {
      const names = (value as string[])?.map(
        (value) => DAYS.find((day) => day.value == value)?.name
      );
      return names?.map((name) => t(`days.medium.${name}`)).join(', ') ?? '';
    }
    default:
      return value || '';
  }
};

export const isActiveResources = () => {
  const localResourceFilters = loadState<FieldFilterType[]>(Filters.RESOURCES) || [];
  const localResourceRecordFilters = loadState<ResourceFilterByRecords>(
    Filters.RESOURCES_BY_RECORDS
  ) || {
    documents: [],
    operation: 'or'
  };

  return localResourceFilters.length + localResourceRecordFilters.documents.length != 0;
};

export const isActiveDocuments = () => {
  const localResourceFilters = loadState<FieldFilterType[]>(Filters.DOCUMENTS) || [];

  return localResourceFilters.length != 0;
};

export const isActiveResourceRecords = () => {
  const localResourceRecords = loadState<FieldFilterType[]>(Filters.RESOURCERECORDS) || [];

  return localResourceRecords.length != 0;
};

export const isActiveQualifiedResources = () => {
  const localResourceFilters = loadState<FieldFilterType[]>(Filters.QUALIFIED_RESOURCES) || [];
  return localResourceFilters.length != 0;
};

export const isActiveCurriculums = () => {
  const localResourceFilters = loadState<FieldFilterType[]>(Filters.CURRICULUMS) || [];

  return localResourceFilters.length != 0;
};

export const isActiveJobs = () => {
  const localJobFilters = loadState<FieldFilterType[]>(Filters.JOBS) || [];
  return localJobFilters.length != 0;
};

export const transformFilter = ({ _id, type, value, label }: FieldFilterType) => {
  switch (type) {
    case PropertyTypes.STRING:
      if (value) {
        return { _id, value: { $regex: value, $options: 'i' } };
      }
      return undefined;

    case PropertyTypes.NUMBER:
      if (value && Object.keys(value).length > 0) {
        const newValue = Object.keys(value).reduce<Record<string, unknown>>(
          (acc, curr) => (value[curr] ? { ...acc, [curr]: +value[curr] } : acc),
          {}
        );
        if (Object.keys(newValue).length > 0) {
          return { _id, value: newValue };
        }
      }
      return undefined;

    case PropertyTypes.DATE:
      if (value && Object.keys(value).length > 0) {
        const newValue = Object.keys(value).reduce<Record<string, unknown>>(
          (acc, curr) => (value[curr] ? { ...acc, [curr]: value[curr] } : acc),
          {}
        );
        if (Object.keys(newValue).length > 0) {
          return { _id, value: newValue };
        }
      }
      return undefined;

    case PropertyTypes.OBJECTID:
      if (value && (value as unknown[]).length > 0) {
        return { _id, value: { $in: (value as { _id: string }[]).map(({ _id }) => _id) } };
      }
      return undefined;

    case PropertyTypes.SET:
      if (value && (value as unknown[]).length > 0) {
        return { _id, value: { $in: (value as { _id: string }[]).map(({ _id }) => _id) } };
      }
      return undefined;

    case PropertyTypes.WEEKDAYS:
      if (value && (value as []).length > 0) {
        return { _id, value: { $all: value } };
      }
      return undefined;

    default:
      return { _id, value };
  }
};
