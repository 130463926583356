export type MultipleInputValue = { multiple: true };

export function isMultipleInputValue(value: any): value is MultipleInputValue {
  return value && typeof value === 'object' && value?.multiple === true;
}

export type MultiplePropertyValue<T> = T | MultipleInputValue | undefined;

export type PossibleValues<T> =
  | { includeMultipleObj?: false; value: T | undefined }
  | { includeMultipleObj: true; value: MultiplePropertyValue<T> };
