import { RenderIf } from '@/components/atoms';
import { TextFilter } from '@/components/molecules';
import { AutoCompleteField, CrudModes, SETS_FIND_BY_NAME } from '@/const';
import { SystemSets } from '@/const/sets';
import { useToastContext } from '@/context/ToastContext';
import { useGet } from '@/hooks';
import { ResourceSlot, Session, SessionContinuity, SetDto, Type } from '@/types';
import { Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  TbSquareRoundedLetterD,
  TbSquareRoundedLetterP,
  TbSquareRoundedLetterR
} from 'react-icons/tb';
import { ResourceSlotCard } from '../../../../common/ResourceSlotCard';

const Sessions = ({
  sessions,
  resourceSlots,
  mode
}: {
  resourceSlots: ResourceSlot[];
  sessions: Session[];
  mode: CrudModes;
}) => {
  const { t } = useTranslation('organisms/curriculumSidePanel');
  const { t: tCurriculumTemplate } = useTranslation('templates/curriculumsTemplate');
  const { t: tCommon } = useTranslation('common');
  const { data: roles, error: errorRoles } = useGet<SetDto>(
    {
      url: SETS_FIND_BY_NAME.replace(':setName', SystemSets.Roles)
    },
    { revalidateOnMount: true }
  );
  const readOnly = mode === CrudModes.READ;

  const { setToastNotifications } = useToastContext();

  useEffect(() => {
    if (errorRoles) {
      setToastNotifications([{ message: t('errors.roles') }]);
    }
  }, [errorRoles]);

  const {
    control,
    formState: { errors }
  } = useFormContext<SessionContinuity>();

  const resourceSlotId = useWatch({
    control,
    name: 'resourceSlotId'
  });
  const sessionId = useWatch({
    control,
    name: 'sessionId'
  });
  const type = useWatch({
    control,
    name: 'type'
  });

  const currentResourceSlot = useMemo(
    () => resourceSlotId && resourceSlots.find((rs) => rs.id === resourceSlotId),
    [resourceSlotId]
  );

  const currentSession = useMemo(() => sessions.find((s) => s.id === sessionId), [sessionId]);

  return (
    <>
      <Grid container mt={0} spacing={3}>
        <Grid
          width="100%"
          item
          display={'flex'}
          gap={'1.5rem'}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between">
          <Controller
            name={'type'}
            control={control}
            rules={{ required: true }}
            defaultValue={Type.R}
            render={({ field }) => {
              return (
                <>
                  <ToggleButtonGroup
                    size="small"
                    value={field.value}
                    exclusive
                    disabled={readOnly}
                    onChange={(e, alignment) => {
                      if (alignment && !readOnly) field.onChange(alignment);
                    }}>
                    <ToggleButton
                      sx={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }}
                      value={Type.R}>
                      <TbSquareRoundedLetterR size="22" style={{ alignSelf: 'center' }} />
                      <Typography variant="subtitle2">{t('continuityTypes.required')}</Typography>
                    </ToggleButton>
                    <ToggleButton
                      sx={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }}
                      value={Type.P}>
                      <TbSquareRoundedLetterP size="22" style={{ alignSelf: 'center' }} />
                      <Typography variant="subtitle2">{t('continuityTypes.preferred')}</Typography>
                    </ToggleButton>
                    <ToggleButton
                      sx={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }}
                      value={Type.D}>
                      <TbSquareRoundedLetterD size="22" style={{ alignSelf: 'center' }} />
                      <Typography variant="subtitle2">
                        {t('continuityTypes.discontinuity')}
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </>
              );
            }}
          />
          <Controller
            name={'name'}
            control={control}
            render={({ field }) => (
              <TextFilter
                sx={{ flexGrow: 1 }}
                fullWidth={false}
                disabled={readOnly}
                {...field}
                name={'continuity_name'}
                autoComplete={AutoCompleteField}
                size="small"
                variant="outlined"
                value={field.value}
                label={t('fields.name')}
                onChange={field.onChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors?.name}
              />
            )}
          />
        </Grid>
        <Grid item width="100%">
          <Typography paddingBottom={'0.5rem'}>
            {tCurriculumTemplate('elements.resourceSlots_one')}
          </Typography>
          <Grid item>
            {!!currentResourceSlot && (
              <ResourceSlotCard
                isSelected={false}
                resourceSlot={currentResourceSlot}
                roles={roles?.items || []}
              />
            )}
          </Grid>
        </Grid>
        <Grid item display="flex" flexDirection="column" width="100%" gap="0.5rem">
          {t('overAnyTopic', {
            sessionName: currentSession?.name
          })}
        </Grid>
      </Grid>
      <RenderIf condition={type === Type.D}>
        <Typography mt="auto" variant="body2" paddingY={2} lineHeight={1}>
          <strong>{tCommon('utils.note')}</strong> {t('discontinuityNote')}
        </Typography>
      </RenderIf>
    </>
  );
};

export default Sessions;
