import { RenderIf } from '@/components/atoms';
import { useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { ResourceSlot, SetItemDto } from '@/types';
import { Box, IconButton } from '@mui/material';
import { ReactNode } from 'react';
import { FaTrash } from 'react-icons/fa';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { MdEventSeat } from 'react-icons/md';
import { Card, CustomTypography } from './ResourceSlotCard.style';
type ResourceSlotCardProps = {
  resourceSlot: ResourceSlot;
  roles?: SetItemDto[];
  onContextMenu?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onEdit?: () => void;
  isFaded?: boolean;
  onRemove?: () => void;
  isSelected: boolean;
  extraNode?: ReactNode;
  onSelect?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: ReactNode;
};

const ResourceSlotCard = ({
  resourceSlot,
  roles,
  onContextMenu,
  onEdit,
  onRemove,
  isSelected,
  extraNode,
  isFaded = false,
  onSelect
}: ResourceSlotCardProps) => {
  const { readOnly } = useCurriculumSetupContext();

  return (
    <Card
      onDoubleClick={onEdit}
      onClick={onSelect}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onContextMenu && onContextMenu(e);
      }}
      isSelected={isSelected}
      isFaded={isFaded}>
      <Box
        padding={'0.5rem'}
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column">
        <CustomTypography>{resourceSlot.name}</CustomTypography>
        <CustomTypography variant={'caption'} sx={{ lineHeight: 'normal' }}>
          <MdEventSeat style={{ verticalAlign: 'top', marginRight: '0.1rem' }} />
          {resourceSlot.quantity} -{' '}
          {roles?.find((role) => role._id == resourceSlot.role._id)?.label}{' '}
          <RenderIf condition={resourceSlot?.documents && resourceSlot?.documents?.length != 0}>
            <span style={{ marginInline: '0.1rem' }}>|</span>{' '}
            <IoDocumentAttachOutline style={{ verticalAlign: 'top', marginRight: '0.1rem' }} />
            {resourceSlot?.documents?.map((document) => document.label).join(', ')}
          </RenderIf>
        </CustomTypography>
        <RenderIf condition={!!extraNode}>{extraNode}</RenderIf>
      </Box>
      <RenderIf condition={!!onRemove}>
        <IconButton size="small" disabled={readOnly} onClick={onRemove}>
          <FaTrash fontSize="small" />
        </IconButton>
      </RenderIf>
    </Card>
  );
};

export default ResourceSlotCard;
