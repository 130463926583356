import { useFetch } from '@/hooks';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '../ToastContext';
import ModalConfirmationContext from './ModalConfirmationContext';
import { TitleHeader } from './ModalConfirmationContext.styles';

export type ModalConfirmationContextProviderProps = PropsWithChildren<unknown>;
export type ConfirmationModalContent = {
  title: string;
  message: string | ReactNode;
  isReactNode?: boolean;
  confirmButton?: string;
  cancelButton?: string;
  fetchData?: AxiosRequestConfig;
  handleError?: (error: any) => void;
};

const ModalConfirmationContextProvider = ({ children }: ModalConfirmationContextProviderProps) => {
  const { t } = useTranslation();
  const { setToastNotifications } = useToastContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [content, setContent] = useState<ConfirmationModalContent>({
    title: t('modalConfirmation.title'),
    message: t('modalConfirmation.message'),
    isReactNode: false,
    confirmButton: t('buttons.yes'),
    cancelButton: t('buttons.no')
  });
  const { loading, data, error, fetch } = useFetch();
  const resolver = useRef<(value: unknown) => unknown>();

  const handleShow = (newContent: ConfirmationModalContent) => {
    setContent(newContent);
    setShowConfirmationModal(true);

    return new Promise<unknown>((resolve) => {
      resolver.current = resolve;
    });
  };

  const handleOk = async () => {
    if (content.fetchData) {
      fetch(content.fetchData);
    } else {
      resolver.current && resolver.current(true);
      setShowConfirmationModal(false);
    }
  };

  useEffect(() => {
    if (data) {
      resolver.current && (data ? resolver.current(data) : resolver.current(false));
    }
    setShowConfirmationModal(false);
  }, [data]);

  useEffect(() => {
    if (error) {
      if (content.handleError) {
        content.handleError(error);
      } else {
        setToastNotifications([
          {
            message: t('errors.errorConfirmation', {
              message: (error as Error)?.message
                ? t('errors.detailsError', { error: (error as Error)?.message })
                : ''
            })
          }
        ]);
      }
      resolver.current && resolver.current(false);
    }
  }, [error]);

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setShowConfirmationModal(false);
  };

  return (
    <ModalConfirmationContext.Provider value={{ showConfirmation: handleShow, handleCancel }}>
      {children}
      <Dialog
        open={showConfirmationModal}
        onClose={() => {
          if (!loading) {
            handleCancel();
          }
        }}
        PaperProps={{
          style: {
            minWidth: '28rem',
            minHeight: '10rem',
            maxWidth: '40rem',
            // maxHeight: '25rem',
            borderRadius: '16px'
          }
        }}>
        <TitleHeader>{content.title}</TitleHeader>
        <>
          {!content.isReactNode ? (
            <>
              <DialogContent sx={{ padding: '1.3rem 1.5rem 1rem 1.5rem !important' }}>
                <Typography>{content.message}</Typography>
              </DialogContent>
              <DialogActions sx={{ padding: '1rem 1.5rem' }}>
                {content.cancelButton && (
                  <Button disabled={loading} onClick={handleCancel}>
                    {content.cancelButton}
                  </Button>
                )}
                <LoadingButton variant="contained" onClick={handleOk} loading={loading}>
                  {content.confirmButton}
                </LoadingButton>
              </DialogActions>
            </>
          ) : (
            content.message
          )}
        </>
      </Dialog>
    </ModalConfirmationContext.Provider>
  );
};

export default ModalConfirmationContextProvider;
