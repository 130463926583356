import { styled } from '@mui/system';

export const OuterContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const GridHeader = styled('div')`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.palette.background.default};
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  border-bottom: none;
  border-radius: 5px 5px 0 0;
`;

export const BordererGridContainer = styled('div')`
  position: relative;
  width: 100%;
  margin-top: 0.3em;
  flex: 1;
  margin: 0;
`;
