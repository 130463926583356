import { JobDto, ResourceDto } from '@/types';
import { PropsWithChildren, useState } from 'react';
import ScheduleConfigContext from './ScheduleConfigContext';

const ScheduleConfigContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedJobs, setSelectedJobs] = useState<JobDto[]>([]);
  const [schedulableJobs, setSchedulableJobs] = useState<JobDto[]>([]);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const [selectedResources, setSelectedResources] = useState<ResourceDto[]>([]);

  const handleSelectedJobs = (newJobFilter: JobDto[]) => {
    setSelectedJobs(newJobFilter);
  };

  const handleSelectedResources = (newResourcesFilter: ResourceDto[]) => {
    setSelectedResources(newResourcesFilter);
  };

  return (
    <ScheduleConfigContext.Provider
      value={{
        selectedJobs,
        schedulableJobs,
        setSchedulableJobs,
        selectedResources: selectedResources,
        isFinished,
        setSelectedJobs: handleSelectedJobs,
        setSelectedResources: handleSelectedResources,
        setIsFinished
      }}>
      {children}
    </ScheduleConfigContext.Provider>
  );
};

export default ScheduleConfigContextProvider;
