import { AlertColor } from '@mui/material';
import { ReactNode, createContext } from 'react';

export type ToastType = {
  message: string;
  type?: AlertColor;
  component?: ReactNode;
};

export type ToastContextType = {
  toastNotifications: ToastType[];
  setToastNotifications: (alerts: ToastType[]) => void;
};

export default createContext<ToastContextType>({} as ToastContextType);
