import { styled } from '@mui/material';

export const BryntumToolbar = styled('div')`
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.grey[500]};
  justify-content: space-between;
  display: flex;
  padding: 0.5em;
  align-items: center;
  height: 3.2rem;
`;
export default BryntumToolbar;
