import { theme } from '@/theme';
import { styled } from '@mui/material';

type ButtonSettingsProps = {
  width?: string;
  height?: string;
};

export const Toolbar = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.grey[500]};
  justify-content: space-between;
  display: flex;
  padding: 0.5em;
  align-items: center;
  height: 3.2rem;
`;

export const ButtonSettings = styled('button')<ButtonSettingsProps>`
  width: ${({ width }) => width || '30px'};
  height: ${({ height }) => height || '30px'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${theme.palette.grey[700]};
  transition: background-color 0.2s, color 0.2s, border 0.2s;
  background-color: ${theme.palette.grey[900]};
  user-select: none;
  color: ${theme.palette.grey[800]};
  position: relative;

  &:hover {
    border-color: ${theme.palette.grey[700]};
    background-color: ${theme.palette.grey[700]};
  }

  & > i {
    font-size: 12px;
  }
`;
