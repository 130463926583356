import { LabeledReference, PossibleValues, isMultipleInputValue } from '@/types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps
} from '@mui/material';
import { isString } from 'lodash';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type FixedSelectFilterProps = {
  label?: ReactNode;
  options: LabeledReference[];
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  onChange: (value: unknown) => void;
} & SelectProps &
  PossibleValues<string | null>;

const FixedSelectFilter = ({
  value,
  label,
  options,
  placeholder = '',
  onChange,
  ...props
}: FixedSelectFilterProps) => {
  const { t: tCommon } = useTranslation();

  const getLabel = (_id: string) => {
    return options.find((option) => option._id === _id)?.label || '';
  };
  return (
    <FormControl fullWidth>
      <InputLabel shrink={true} id="select-filter">
        {label}
      </InputLabel>
      <Select
        {...props}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={!isMultipleInputValue(value) ? value : null}
        labelId="select-filter"
        label={label}
        id="select-filter"
        sx={{ borderRadius: '4px' }}
        size="small"
        //notched={true}
        displayEmpty
        fullWidth
        renderValue={(selected) => {
          return (
            <OutlinedInput
              label={label}
              fullWidth
              inputProps={{
                style: {
                  padding: 0
                }
              }}
              sx={{
                padding: 0,
                '.MuiOutlinedInput-notchedOutline': { border: 'none', padding: 0 }
              }}
              value={isString(selected) ? getLabel(selected) : ''}
              readOnly
              placeholder={
                !isMultipleInputValue(value) ? placeholder : tCommon('text.multipleValues')
              }
            />
          );
        }}>
        {options.map((option) => (
          <MenuItem key={option._id} value={option._id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FixedSelectFilter;
