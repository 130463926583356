import { AnySchema, InferType, array, boolean, date, number, object, string } from 'yup';

export type StopProps = {
  stopAfterMinutes: number;
  stopMinPercentage: number;
};

export const scheduleConfigSchema = object({
  jobs: array().required(),
  roles: array().of(string().required()).min(1).required(),
  schedulingType: string().required(),
  endDate: date().nullable(),
  byPeriods: boolean(),
  periodSize: number().min(1).max(999),
  stopProps: object<Record<keyof StopProps, AnySchema>>()
    .shape(
      {
        stopAfterMinutes: number().when('stopMinPercentage', {
          is: (stopMinPercentage) => Boolean(stopMinPercentage),
          then: (schema) => number().required(),
          otherwise: (schema) => number()
        }),
        stopMinPercentage: number().when('stopAfterMinutes', {
          is: (stopAfterMinutes) => Boolean(stopAfterMinutes),
          then: (schema) => number().required().min(1).max(100),
          otherwise: (schema) => number().min(1).max(100)
        })
      },
      [
        ['stopAfterMinutes', 'stopMinPercentage'],
        ['stopMinPercentage', 'stopAfterMinutes']
      ]
    )
    .nullable()
});

export type ScheduleConfigSchemaType = InferType<typeof scheduleConfigSchema>;
