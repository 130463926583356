import { Typography, keyframes, styled } from '@mui/material';

const dotsAnimation = keyframes`
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
  100% {
    content: '.';
  }
`;

const delay = '1.4s';

export const LoadingText = styled(Typography)`
  &:after {
    content: '';
    display: inline-block;
    webkit-animation: ${dotsAnimation} ${delay} steps(3) infinite;
    animation: ${dotsAnimation} ${delay} steps(3) infinite;
  }
`;
