import { RenderIf } from '@/components/atoms';
import { DateInput } from '@/components/molecules';
import { AccordionSummary, FormHelperTextError } from '@/components/molecules/Card/Card.styles';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { JOBS_UNSCHEDULABLE_ROLES, JOBS_UNSCHEDULE, UnscheduleJobsModes } from '@/const';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { ButtonsContainer, theme } from '@/theme';
import { LabeledReference, StructureStatus } from '@/types';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Typography
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type UnscheduleJobsProps = {
  eventInfo: any;
  onSuccess: () => void;
};

export type UnscheduleJobsType = {
  resources: number;
  role: LabeledReference;
};

export type UnscheduleType = { label: string; value: UnscheduleJobsModes };

type FormValues = {
  deleteSeriesType: UnscheduleJobsModes;
  roles: string[];
  toDate: string;
  fromDate: string;
};

const UnscheduleJobsPanelTemplate = ({ eventInfo, onSuccess }: UnscheduleJobsProps) => {
  const [unscheduleJobsMode, setUnscheduleJobsMode] = useState<UnscheduleJobsModes>(
    UnscheduleJobsModes.FULLY
  );

  const { handleClose } = useGenericModalContext();

  const onChangeUnscheduleJobsMode = (event: ChangeEvent<HTMLInputElement>) => {
    setUnscheduleJobsMode(event.target.value as UnscheduleJobsModes);
  };

  const { t: tUnschedule } = useTranslation('organisms/unscheduleJobs');
  const { t: tCommon } = useTranslation();

  const deleteOptions = tUnschedule('options', {
    returnObjects: true
  }) as UnscheduleType[];

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    trigger
  } = useForm<FormValues>({
    mode: 'onSubmit'
  });

  const { fetch, loading, data, error } = useFetch<any>();
  const {
    fetch: unscheduleableRolesFetch,
    loading: loadingUnscheduleableRoles,
    data: unscheduleableRoles,
    error: unscheduleableRolesError
  } = useFetch<UnscheduleJobsType[]>();
  const { setToastNotifications } = useToastContext();

  const uniqueJobs = useMemo(() => {
    const uniqueJobMap = eventInfo.selection
      .filter((job) => job.data.id === job.data.job._id)
      .map((job) => job.data.job)
      .filter(
        (job) =>
          !job.beingScheduled &&
          job.status &&
          [StructureStatus.COMPLETE, StructureStatus.PARTIALLY_SCHEDULED].includes(job.status)
      );
    return uniqueJobMap;
  }, []);

  useEffect(() => {
    if (uniqueJobs) {
      unscheduleableRolesFetch({
        url: JOBS_UNSCHEDULABLE_ROLES,
        method: 'POST',
        data: {
          jobs: uniqueJobs.map((event) => event._id)
        }
      });
    }
  }, [uniqueJobs]);

  const submit = (form: FormValues) => {
    if (uniqueJobs) {
      const query = {
        url: JOBS_UNSCHEDULE,
        data: {
          jobs: uniqueJobs.map((event) => event._id),
          ...form
        },
        method: 'POST'
      };
      fetch(query);
    }
  };

  useEffect(() => {
    if (error) {
      setToastNotifications([{ message: tUnschedule('errors.unschedule') }]);
    } else if (data) {
      onSuccess();
      handleClose();
    }
  }, [error, data]);

  return (
    <Grid
      height="100%"
      minWidth={'100%'}
      width={'100%'}
      display={'flex'}
      flexDirection="column"
      container
      onSubmit={handleSubmit(submit)}
      component="form"
      noValidate>
      <HeaderModalContainer>
        <Typography color={theme.palette.common.white} textAlign={'center'} variant="h5">
          {tUnschedule('unscheduleJobs')}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer>
        <Typography margin={'1rem 0'}>
          {tUnschedule('subtitle', { count: uniqueJobs.length })}
        </Typography>

        <AccordionSummary
          sx={{
            minHeight: 'auto !important',
            cursor: 'auto !important',
            '& > *': {
              margin: '0.5rem  !important'
            }
          }}>
          <Typography>{tUnschedule('unschedulingOptions')}</Typography>
        </AccordionSummary>
        <FormControl component="fieldset" variant="standard" sx={{ padding: '1rem' }}>
          <RadioGroup
            value={unscheduleJobsMode}
            onChange={onChangeUnscheduleJobsMode}
            name="deleteSeriesType">
            {deleteOptions.map(({ label, value }: UnscheduleType) => (
              <Grid
                key={value}
                display={'flex'}
                flexDirection={'column'}
                sx={{ marginBottom: '0.5rem' }}>
                <FormControlLabel
                  key={value}
                  value={value}
                  control={
                    <Radio
                      disabled={
                        value == UnscheduleJobsModes.RESOURCES &&
                        (unscheduleableRoles?.length === 0 ||
                          !!unscheduleableRolesError ||
                          loadingUnscheduleableRoles)
                      }
                    />
                  }
                  label={label}
                />
                <Grid sx={{ pl: '1.875rem' }} display={'grid'}>
                  <Typography variant="body2" color={theme.palette.grey[500]}>
                    {tUnschedule(`optionsDescription.${value}`)}
                  </Typography>
                  <RenderIf
                    condition={
                      value == UnscheduleJobsModes.RESOURCES && unscheduleableRoles?.length == 0
                    }>
                    <Typography variant="body1">{tUnschedule(`errors.resourcesEmpty`)}</Typography>
                  </RenderIf>
                  <RenderIf
                    condition={
                      value == UnscheduleJobsModes.RESOURCES && !!unscheduleableRolesError
                    }>
                    <Typography variant="body1" color={theme.palette.error.main}>
                      {tUnschedule('errors.resourceSlots')}
                    </Typography>
                  </RenderIf>

                  <RenderIf
                    condition={
                      value === UnscheduleJobsModes.RESOURCES && loadingUnscheduleableRoles
                    }>
                    <Grid padding={0}>
                      <Grid sx={{ marginTop: '0.5rem', display: 'flex' }}>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={'1.2rem'}
                          height={'1.2rem'}
                          sx={{ marginRight: '0.5rem' }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={'8rem'}
                          height={'1.2rem'}
                        />
                      </Grid>

                      <Grid sx={{ marginTop: '0.5rem', display: 'flex' }}>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={'1.2rem'}
                          height={'1.2rem'}
                          sx={{ marginRight: '0.5rem' }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={'8rem'}
                          height={'1.2rem'}
                        />
                      </Grid>
                    </Grid>
                  </RenderIf>
                  <RenderIf condition={value === UnscheduleJobsModes.RESOURCES}>
                    <Controller
                      control={control}
                      name="roles"
                      rules={{
                        required: unscheduleJobsMode == UnscheduleJobsModes.RESOURCES
                      }}
                      render={({ field }) => (
                        <>
                          {unscheduleableRoles?.map(({ resources, role }: UnscheduleJobsType) => (
                            <FormControlLabel
                              key={role._id}
                              disabled={unscheduleJobsMode !== UnscheduleJobsModes.RESOURCES}
                              control={
                                <Checkbox
                                  style={{ padding: '4px 8px' }}
                                  name={role._id}
                                  value={role._id}
                                  checked={
                                    !!field?.value?.some(
                                      (existingValue) => existingValue === role._id
                                    )
                                  }
                                  onChange={(event, checked) => {
                                    if (checked) {
                                      if (field.value) {
                                        field.onChange([...field.value, event.target.value]);
                                      } else {
                                        field.onChange([event.target.value]);
                                      }
                                    } else {
                                      field.onChange(
                                        field.value.filter((value) => value !== event.target.value)
                                      );
                                    }
                                  }}
                                />
                              }
                              label={
                                <Grid display={'flex'} gap={'8px'} alignItems="center">
                                  <Typography>{role.label}</Typography>
                                </Grid>
                              }
                            />
                          ))}
                        </>
                      )}
                    />
                  </RenderIf>
                  <RenderIf condition={!!errors.roles && value == UnscheduleJobsModes.RESOURCES}>
                    <FormHelperTextError>{tUnschedule('errors.atLeastOne')}</FormHelperTextError>
                  </RenderIf>
                </Grid>
              </Grid>
            ))}
          </RadioGroup>
        </FormControl>
        <AccordionSummary
          sx={{
            minHeight: 'auto !important',
            cursor: 'auto !important',
            '& > *': {
              margin: '0.5rem  !important'
            }
          }}>
          <Typography>{tUnschedule('additionalSettings')}</Typography>
        </AccordionSummary>
        <FormControl component="fieldset" variant="standard" sx={{ padding: '1rem', gap: '1rem' }}>
          <Typography variant="body1" color={theme.palette.grey[500]}>
            {tUnschedule('additionalSettingsDescription')}
          </Typography>
          <Grid
            sx={{
              display: 'grid',
              gridTemplateColumns: 'fit-content(100%) fit-content(100%)',
              gridTemplateRows: 'fit-content(100%) fit-content(100%)',
              gridColumnGap: '1rem',
              alignItems: 'flex-start'
            }}>
            <Typography variant="body1">{tUnschedule('start')}</Typography>
            <Typography variant="body1">{tUnschedule('end')}</Typography>
            <Controller
              rules={{
                validate: {
                  triggerEndDate: (value) => {
                    trigger('toDate');
                    return true;
                  }
                }
              }}
              control={control}
              name="fromDate"
              render={({ field }) => (
                <DateInput
                  onChange={field.onChange}
                  value={field.value}
                  error={!!errors['fromDate']}
                  helperText={
                    errors['fromDate'] && (errors['fromDate'] as { message: string }).message
                  }
                  includeHours={false}
                />
              )}
            />

            <Controller
              rules={{
                validate: {
                  moreThan: (value: string | Date, form) => {
                    const startTimeStamp = form.fromDate;
                    const validation =
                      !value ||
                      !startTimeStamp ||
                      new Date(value).getTime() >= new Date(startTimeStamp).getTime();
                    return validation
                      ? validation
                      : tCommon('formRules.afterThat', {
                          prop1: tUnschedule('end'),
                          prop2: tUnschedule('start')
                        });
                  }
                }
              }}
              control={control}
              name="toDate"
              render={({ field }) => (
                <DateInput
                  onChange={field.onChange}
                  value={field.value}
                  error={!!errors['toDate']}
                  helperText={errors['toDate'] && (errors['toDate'] as { message: string }).message}
                  includeHours={false}
                />
              )}
            />
          </Grid>
        </FormControl>
        {/* </RenderIf> */}
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button onClick={() => handleClose()}>{tCommon('buttons.cancel')}</Button>
          <LoadingButton variant="contained" loading={loading} type="submit">
            {tUnschedule('buttons.unschedule')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </Grid>
  );
};

export default UnscheduleJobsPanelTemplate;
