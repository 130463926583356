import { useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { theme } from '@/theme';
import { Session, TimeStrategy, Topic } from '@/types';
import { Card, Portal } from '@mui/material';
import { isNumber } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { DragNDropComponents } from '../../CurriculumSetup.const';
import { HintNumber } from '../../CurriculumSetup.styles';
import { IconHeader } from '../SessionWrapper/SessionWrapper.const';
import {
  HEADER_TITLE_VARIANT,
  HeaderContainer,
  HeaderTitle
} from '../SessionWrapper/SessionWrapper.styles';
import { TopicText } from '../TopicCard/TopicCard.styles';

type DragLayerProps = {
  scale: number;
};

const DragLayer = ({ scale }: DragLayerProps) => {
  const { t } = useTranslation('organisms/curriculumSetup');
  const {
    isDragging,
    item: draggedItem,
    type,
    currentOffset,
    initialOffset
  } = useDragLayer<{
    item: any;
    type: DragNDropComponents;
    isDragging: boolean;
    currentOffset: XYCoord | null;
    initialOffset: XYCoord | null;
  }>((monitor) => ({
    item: monitor.getItem(),
    type: monitor.getItemType() as DragNDropComponents,
    currentOffset: monitor.getSourceClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging()
  }));

  const divRef = useRef<HTMLDivElement>(null);
  const divRuler = useRef<HTMLDivElement>(null);
  const { selectedTopics, selectedSessions, isOverSession, setGlobalDragging } =
    useCurriculumSetupContext();

  useEffect(() => {
    setGlobalDragging(!!isDragging);
  }, [isDragging]);

  const renderDragLayer = useCallback(() => {
    if (!isDragging || isOverSession) {
      return null;
    }

    const canvasWidth = divRef.current?.clientWidth ? divRef.current.clientWidth : 700;

    switch (type) {
      case DragNDropComponents.SESSION:
      case DragNDropComponents.NEW_TIME_SESSION:
      case DragNDropComponents.NEW_SESION: {
        const session: Session | undefined = draggedItem?.session as Session;
        return (
          <HeaderContainer
            headerStyle="shadow"
            sx={{
              width: canvasWidth,
              opacity: '1',
              borderWidth: '1px',
              borderStyle: 'solid',
              boxShadow: `0px 0px 3px 0.5px ${theme.palette.grey[500]}`
            }}>
            <IconHeader
              timeStrategy={
                session?.timeStrategy
                  ? session.timeStrategy
                  : type === DragNDropComponents.NEW_TIME_SESSION
                  ? TimeStrategy.START_TIMES
                  : TimeStrategy.RESOURCE_MISSIONS
              }
            />
            <HeaderTitle variant={HEADER_TITLE_VARIANT}>
              {type === DragNDropComponents.SESSION
                ? session?.name || ''
                : t('canvas.newElement', {
                    element:
                      type === DragNDropComponents.NEW_SESION
                        ? t('elements.sessions_one')
                        : t('elements.time_sessions_one')
                  })}
            </HeaderTitle>
          </HeaderContainer>
        );
      }
      case DragNDropComponents.TOPIC:
      case DragNDropComponents.NEW_TOPIC: {
        const topic = draggedItem.topic as Topic;

        return (
          <>
            {isNumber(selectedTopics?.length) && selectedTopics?.length > 1 && (
              <HintNumber>{selectedTopics.length}</HintNumber>
            )}
            <Card
              style={{
                width: ((canvasWidth * topic.duration) / 1440) * scale,
                backgroundColor: topic.color,
                position: 'relative',
                height: '1.625rem',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #6d6d6d',
                boxShadow: `0px 0px 3px 0.5px ${theme.palette.grey[500]}`
              }}>
              <TopicText variant="body2" color={topic.color}>
                {topic.name}
              </TopicText>
            </Card>
          </>
        );
      }
      default:
        return null;
    }
  }, [
    divRef.current,
    type,
    isDragging,
    draggedItem,
    initialOffset,
    selectedTopics,
    selectedSessions,
    isOverSession
  ]);

  return (
    <>
      <div
        ref={divRef}
        style={{
          position: 'relative',
          width: 'calc(100% - 2rem)',
          margin: '0 auto',
          overflow: 'visible',
          border: '1px solid transparent',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <div
          ref={divRuler}
          style={{
            position: 'relative',
            width: `${scale * 100}%`
          }}></div>
      </div>

      <Portal container={document.body}>
        <div
          style={{
            position: 'fixed',
            pointerEvents: 'none',
            width: 'fit-content',
            zIndex: 1000,
            left: currentOffset?.x,
            top: currentOffset?.y
          }}>
          {renderDragLayer()}
        </div>
      </Portal>
    </>
  );
};

export default DragLayer;
