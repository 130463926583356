import { TIME_FORMAT_PICKER } from '@/const';
import { PossibleValues, isMultipleInputValue } from '@/types';
import { TimePickerProps } from '@mui/lab';
import { FormControl, TextFieldProps } from '@mui/material';
import { PickerChangeHandlerContext, TimePicker, TimeValidationError } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
export type TimeInputProps = {
  label?: string | ReactNode;
  readOnly?: boolean;
  error?: boolean;
  id?: string;
  name?: string;
  autoComplete?: string;
  helperText?: ReactNode;
  onChange: (value: string | null) => void;
} & TimePickerProps<TextFieldProps> &
  PossibleValues<string | null>;

const TimeInput = ({
  value = '',
  onChange,
  label,
  error = false,
  readOnly = false,
  helperText,
  includeMultipleObj = false,
  id,
  name,
  autoComplete,
  placeholder,
  ...props
}: TimeInputProps) => {
  const { t: tCommon } = useTranslation();
  const handleChange = (
    value: DateTime | null,
    context?: PickerChangeHandlerContext<TimeValidationError>
  ) => {
    (value as DateTime)?.isValid ? onChange((value as DateTime).toFormat('HH:mm')) : onChange(null);
  };
  return (
    <FormControl fullWidth error={error}>
      <TimePicker
        sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
        slotProps={{
          textField: {
            placeholder: 'hh:mm',
            InputLabelProps: {
              shrink: true
            },
            inputProps: {
              placeholder:
                includeMultipleObj && isMultipleInputValue(value)
                  ? tCommon('text.multipleValues')
                  : placeholder || '',
              id,
              name,
              autoComplete
            },
            size: 'small',
            helperText,
            error
          }
        }}
        format={TIME_FORMAT_PICKER}
        readOnly={readOnly}
        {...props}
        label={label}
        value={value && !isMultipleInputValue(value) ? DateTime.fromISO(value) : null}
        onChange={handleChange}
        views={['hours', 'minutes']}
      />
    </FormControl>
  );
};

export default TimeInput;
