import { MenuItem, OutlinedInput, styled } from '@mui/material';

export const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px',
  fieldset: {
    borderColor: 'rgba(0, 0, 0, 0.23) !important',
    borderWidth: '1px !important'
  },
  '&:hover fieldset': {
    borderColor: 'rgba(0, 0, 0, 0.23) !important',
    borderWidth: '1px !important'
  },
  '&:active fieldset': {
    borderColor: 'rgba(0, 0, 0, 0.23) !important',
    borderWidth: '1px !important'
  }
}));

export const CustomMenuItem = styled(MenuItem)(() => ({
  fontSize: '0.875rem'
}));
