import { RenderIf, SessionIcon, StatusIcon, TopicIcon } from '@/components/atoms';
import {
  Entities,
  PROPERTIES_BY_ENTITY,
  PropertyTypes,
  SystemFilters,
  SystemProperties
} from '@/const';
import {
  EventDto,
  FieldFilterType,
  GANTT_HIERARCHY,
  JobDetailsDto,
  JobDto,
  PropertyDto,
  SpecialFilterTypes,
  StructureStatus
} from '@/types';
import { EventTaskBryntum, ResourceBryntum, TEMPORAL_CHILD_ID } from '@/types/ui/bryntum';
import {
  arrayToObjectChildren,
  arrayToObjectChildrenV2,
  arrayToObjectId,
  getPropertyValueDef,
  sortingByFetch,
  transformPropertyValue,
  transformToTaskEventBryntum
} from '@/utils';

import { BryntumGantt } from '@bryntum/gantt-react-thin';
import { Model, Store } from '@bryntum/core-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { TaskModel, AssignmentModelConfig, TaskModelConfig, TaskStore } from '@bryntum/gantt-thin';

import { Box, Grid, Skeleton } from '@mui/material';
import { TFunction } from 'i18next';
import { ForwardedRef, RefObject, useState } from 'react';

export enum RowTypes {
  JOB = 'JOB',
  SESSION = 'SESSION',
  TOPIC = 'TOPIC'
}

export type PeriodDateRange = {
  startDate: Date | null | string;
  endDate: Date | null | string;
};

const MINUSINFINIY = 0;
const MAXINFINITY = 8640000000000000;

const rowStatusMap = {
  [RowTypes.JOB]: (row: Model) => {
    return row.getData('job').status;
  },
  [RowTypes.SESSION]: (row: Model) => {
    return row.getData('status');
  },
  [RowTypes.TOPIC]: (row: Model) => {
    return row.getData('status');
  }
};

export const getExtraFields = (tCommon: TFunction) => {
  return [
    {
      name: 'Curriculum',
      _id: SystemFilters.JOBS_CURRICULUM,
      type: PropertyTypes.OBJECTID,
      order: 0,
      referencedCollection: Entities.CURRICULUMS,
      value: []
    },
    {
      name: 'Status',
      _id: SystemFilters.JOBS_STATUS,
      type: SpecialFilterTypes.OPTIONS,
      order: 1,
      options: [
        {
          _id: StructureStatus.COMPLETE,
          value: StructureStatus.COMPLETE,
          label: tCommon(`jobStatus.${StructureStatus.COMPLETE}`)
        },
        {
          _id: StructureStatus.PARTIALLY_SCHEDULED,
          value: StructureStatus.PARTIALLY_SCHEDULED,
          label: tCommon(`jobStatus.${StructureStatus.PARTIALLY_SCHEDULED}`)
        },
        {
          _id: StructureStatus.CANCELED,
          value: StructureStatus.CANCELED,
          label: tCommon(`jobStatus.${StructureStatus.CANCELED}`)
        },
        {
          _id: StructureStatus.NOT_SCHEDULED,
          value: StructureStatus.NOT_SCHEDULED,
          label: tCommon(`jobStatus.${StructureStatus.NOT_SCHEDULED}`)
        }
      ]
    },
    {
      name: 'Topic Status',
      _id: SystemFilters.TOPIC_STATUS,
      type: SpecialFilterTypes.OPTIONS,
      order: 2,
      options: [
        {
          _id: StructureStatus.PARTIALLY_SCHEDULED,
          value: StructureStatus.PARTIALLY_SCHEDULED,
          label: tCommon(`jobStatus.${StructureStatus.PARTIALLY_SCHEDULED}`)
        },
        {
          _id: StructureStatus.NOT_SCHEDULED,
          value: StructureStatus.NOT_SCHEDULED,
          label: tCommon(`jobStatus.${StructureStatus.NOT_SCHEDULED}`)
        }
      ]
    }
  ] as FieldFilterType[];
};
const getPeriodDatePropiertes = (data: EventDto): PeriodDateRange => {
  if (data)
    return {
      startDate: getPropertyValueDef(data.properties, SystemProperties.EVENTS_START_TIMESTAMP, ''),
      endDate: getPropertyValueDef(data.properties, SystemProperties.EVENTS_END_TIMESTAMP, '')
    };
  return {
    startDate: null,
    endDate: null
  };
};

export const getPeriodDateEventsGeneral = (events: EventDto[] | TaskModel[]): PeriodDateRange => {
  const getProperties = (data: EventDto | TaskModel, property: string) => {
    if (data instanceof TaskModel) {
      return data.getData(property);
    } else {
      if (property == 'startDate') {
        return getPropertyValueDef(data?.properties, SystemProperties.EVENTS_START_TIMESTAMP, '');
      } else {
        return getPropertyValueDef(data?.properties, SystemProperties.EVENTS_END_TIMESTAMP, '');
      }
    }
  };

  if (!!events) {
    const result = events.reduce(
      (acc, event) => {
        const startDateCompare = new Date(getProperties(event, 'startDate'));
        const endDateCompare = new Date(getProperties(event, 'endDate'));
        if (startDateCompare < acc.startDate) {
          acc.startDate = startDateCompare;
        }

        if (endDateCompare > acc.endDate) {
          acc.endDate = endDateCompare;
        }
        return acc;
      },
      {
        startDate: new Date(MAXINFINITY),
        endDate: new Date(MINUSINFINIY)
      }
    );
    return result;
  }
  return { startDate: null, endDate: null };
};

export const UpdateGantt = (
  dataStore: TaskStore,
  newData: EventTaskBryntum[],
  newDataEvents: EventTaskBryntum[],
  updateLocally: boolean = false,
  getStructure?: (jobId) => Promise<any>
) => {
  //Delete Record Loading Skeleton
  const listDelete = dataStore
    .findByField('skeleton', true)
    .map((record) => record.data.id) as string[];
  if (listDelete.length > 0) {
    dataStore.remove(listDelete);
  }

  newData.forEach(async (newTask) => {
    const oldTask = (await dataStore.getById(newTask.id)) as TaskModel;
    if (oldTask) {
      if (updateLocally) {
        // console.log('antes verificar localmente');
        // console.log(oldTask);
      }
      const taskUpdate = await oldTask.getData('updateLocal');
      if (taskUpdate) {
        return;
      }

      await oldTask.set({
        startDate: newTask.startDate,
        endDate: newTask.endDate,
        job: newTask.job,
        updateLocal: updateLocally,
        stateUpdate: false
      });
      if (updateLocally && !!getStructure) {
        const structure = await getStructure(oldTask.id);
        const allEventsOfJob = getAllEventJob(newDataEvents, oldTask.id);
        const formattedStructure =
          (await oldTask.get('job')?.status) !== StructureStatus.NOT_SCHEDULED
            ? formatStructure(allEventsOfJob, structure, oldTask)
            : null;

        if (formattedStructure) {
          await oldTask.clearChildren();
          console.log('children');
          console.log(formattedStructure);
          await oldTask.appendChild(formattedStructure);
        }
      }
    } else {
      await dataStore.add(newTask);
    }
  });
};

export const expandRecords = (dataStore: Store) => {
  const expandedRecords = dataStore.records.filter(async (record) => {
    return record.getData('expand');
  });
  expandedRecords.forEach(async (record) => {
    const restoredRecord = dataStore.getById(record.id);
    if (restoredRecord) {
      dataStore.toggleCollapse(restoredRecord, false);
    }
  });
};

export const transformJobsToGantt = (loading?: boolean, jobs?: JobDto[], events?: EventDto[]) => {
  let localResources: EventTaskBryntum[] | ResourceBryntum[];
  if (jobs) {
    const eventByJob = events ? arrayToObjectChildren(events, 'job', 'events') : null;

    const allJobId = jobs.reduce((jobContentObject, job) => {
      const isCollapsable = job.status !== StructureStatus.NOT_SCHEDULED;
      let dateRange = {};
      if (eventByJob && isCollapsable && job._id in eventByJob && eventByJob[job._id]?.events) {
        dateRange = getPeriodDateEventsGeneral(eventByJob[job._id]?.events);
      }
      jobContentObject[job._id] = {
        id: job._id,
        job,
        lvl: GANTT_HIERARCHY.CLASSES,
        manuallyScheduled: true,
        eventColor: localStorage.getItem(`taskColor_${job._id}`),
        type: RowTypes.JOB,
        stateUpdate: false,
        name: getPropertyValueDef(job.properties, SystemProperties.JOBS_NAME, ''),
        iconCls: isCollapsable ? '' : 'remove',
        ...dateRange,
        seeTooltip: false,
        updateLocal: false,
        children: isCollapsable
          ? [
              {
                lvl: GANTT_HIERARCHY.DAYS,
                id: job._id + TEMPORAL_CHILD_ID,
                iconCls: 'remove',
                cls: 'b-grid-leaf-cell b-grid-child-row',
                rowHeight: 28,
                manuallyScheduled: true
              }
            ]
          : undefined
      };
      job.properties.forEach((property) => {
        jobContentObject[job._id][property._id] = property.label ?? property.value;
      });
      return jobContentObject;
    }, {});

    localResources = [];
    Object.keys(allJobId).map((job: string) => {
      localResources.push(allJobId[job]);
    });
  } else {
    localResources = new Array(5)
      .fill('')
      .map((_, idx) => ({ id: `${idx}`, iconCls: 'remove', skeleton: true }));
  }
  return localResources;
};

export const getColumnsGantt = (
  ref: ForwardedRef<BryntumGantt>,
  t: TFunction,
  tMainTemplate: TFunction,
  properties?: PropertyDto[]
) => {
  setTimeout(() => {
    const columnStore: ColumnStore = (ref as RefObject<BryntumGantt>).current?.instance
      ?.columns as ColumnStore;
    columnStore?.remove(columnStore?.records?.filter((r: any) => !r.isTimeAxisColumn));
    if (properties) {
      columnStore?.add([
        {
          id: 'counter',
          field: 'counter',
          type: 'rownumber',
          cls: 'counter-header',
          cellCls: 'b-border-bottom',
          align: 'center',
          htmlEncodeHeaderText: false,
          hideable: false
        },
        {
          id: 'structure',
          field: 'structure',
          text: tMainTemplate('jobsColumns.structure', {
            jobName: properties.find((p) => p._id === SystemProperties.JOBS_NAME)?.name
          }),
          minWidth: 80,
          hideable: true,
          align: 'left',
          type: 'tree',
          sortable: false,
          renderer: ({ record: { data } }) => {
            return (
              <Box height="100%" width="100%" display="flex" alignItems="flex-start" gap="0.5rem">
                {data?.type === RowTypes.TOPIC && (
                  <TopicIcon svgProperties={{ minWidth: '0.938rem' }} size={15} />
                )}
                {data?.type === RowTypes.SESSION && (
                  <SessionIcon svgProperties={{ minWidth: '0.8rem' }} size={12} />
                )}

                {data?.name ? (
                  data.name
                ) : data.job ? (
                  transformPropertyValue(PropertyTypes.STRING, t, data[SystemProperties.JOBS_NAME])
                ) : (
                  <Skeleton height={30} width={'100%'} />
                )}
              </Box>
            );
          }
        },
        {
          id: 'Curriculum',
          field: 'curriculum',
          text: tMainTemplate('jobsColumns.curriculum'),
          width: 80,
          align: 'left',
          sortable: sortingByFetch,
          groupable: false,
          renderer: ({ record: { data } }) => {
            return !data.job ? (
              <Skeleton height={30} />
            ) : data?.job?.curriculum?.label ? (
              data.job.curriculum.label
            ) : (
              ''
            );
          }
        },
        {
          id: 'status',
          field: 'status',
          text: tMainTemplate('jobsColumns.status'),
          width: 80,
          align: 'left',
          sortable: sortingByFetch,
          groupable: false,
          renderer: ({ record }) => {
            const { data } = record;
            if (data?.stateUpdate !== undefined && data?.stateUpdate !== false)
              return (
                <Grid display="flex" alignItems="center" gap="0.3rem" height="100%">
                  <span>{data.stateUpdate}</span>
                </Grid>
              );
            if (!data.job) return <Skeleton height={30} />;

            const status = getStatus(record);
            return (
              <Grid display="flex" alignItems="center" gap="0.3rem" height="100%">
                <RenderIf condition={!!status}>
                  <StatusIcon status={status} />
                  <span>{t(`jobStatus.${status}`) as string}</span>
                </RenderIf>
              </Grid>
            );
          }
        },
        ...(properties as PropertyDto[])
          .sort((p1, p2) => p1._id.localeCompare(p2._id))
          .map(({ _id: id, name: text, type }) => ({
            id,
            ...(Object.values(SystemProperties).includes(id as SystemProperties)
              ? {}
              : { hidden: true }),
            field: id,
            text,
            width: 80,
            align: type == PropertyTypes.NUMBER ? 'right' : 'left',
            sortable: sortingByFetch,
            groupable: false,
            renderer: ({ record: { data } }) => {
              return !data.job ? (
                <Skeleton height={30} />
              ) : (
                transformPropertyValue(type, t, data[id])
              );
            }
          }))
      ]);
    } else {
      columnStore?.add([
        ...new Array(7).fill('').map(() => ({
          text: '...',
          width: 40,
          renderer: () => {
            return <Skeleton height={30} />;
          }
        }))
      ]);
    }
  }, 0);
};

export const TemporalSchedulingJobs = (
  listJobIdEdit: string[],
  status: string,
  dataStore: TaskStore
  // t: TFunction,
  // tMainTemplate: TFunction,
  // properties?: PropertyDto[]
) => {
  listJobIdEdit.forEach((idJob: string) => {
    setTimeout(async () => {
      const dataRow = dataStore.getById(idJob);
      if (!!dataRow) {
        await dataRow.set({
          job: null,
          stateUpdate: status,
          startDate: null,
          endDate: null,
          updateLocal: false
        });

        if (dataRow?.children) {
          await dataRow.clearChildren();
        }
      }
    }, 0);
  });
};

export const getDetailsFromJobEvents = (events?: EventDto[], jobs?: JobDto[], t?: TFunction) => {
  const JobEventsDetails: EventTaskBryntum[] = [];
  let jobAssignments = {};
  if (events && jobs && t) {
    jobAssignments = arrayToObjectChildren(events, 'sessionId', 'topic');

    events.forEach((event) => {
      const render = transformToTaskEventBryntum(event);
      const join = event.join && ('n' as unknown as number);
      if (join)
        render.name = `${render.name}${t('text.joinedSuffix', {
          count: join
        })}`;
      JobEventsDetails.push(render);
      //jobAssignments.push(transformToAssignmentBryntumGantt(event._id, event.job));
    });
  }

  return {
    jobAssignments,
    JobEventsDetails
  };
};

export const getAllEventJob = (array: EventTaskBryntum[], jobId) => {
  return array.filter((array) => {
    return array?.job != undefined && array?.job[0] == jobId;
  });
};

export const verifySessionExist = (
  structure: JobDetailsDto,
  jobDetails: EventTaskBryntum[]
): boolean => {
  const detailSessionJob = arrayToObjectChildren(jobDetails, 'sessionId', 'topics');

  const filtersData = structure.sessions.map((session) => {
    if (detailSessionJob[session.id] !== undefined) {
      let corruptedData = true;
      if (!!detailSessionJob[session.id].startDate && !!detailSessionJob[session.id].endDate) {
        corruptedData = false;
      }
      return { corrupted: corruptedData };
    }
    return { corrupted: true };
  });
  const result = filtersData.filter((data) => {
    return data.corrupted;
  });

  return !(result.length > 0);
};

export const formatStructure = (
  jobDetails: EventTaskBryntum[],
  structure: JobDetailsDto,
  record: Model,
  isUpdate?: boolean,
  isExpanded?: (id: string) => boolean
) => {
  if (record === undefined) {
    return;
  }
  const jobName = record.getData(SystemProperties.JOBS_NAME);
  const detailSessionJob = arrayToObjectChildren(jobDetails, 'sessionId', 'topics');
  const curriculumName = record.getData('job').curriculum.label;
  const curriculumColor = record.getData('job').curriculum?.color ?? undefined;
  const propertiesJob = record.getData('job').properties;
  const statusJob = record.getData('job').status;
  const jobId = record.id;
  const a = structure.sessions.map((session) => {
    const isScheduled = session.status !== StructureStatus.NOT_SCHEDULED;
    let details = {};
    let eventInfo = {};
    if (isScheduled && detailSessionJob[session.id] !== undefined) {
      const { eventColor = '' } = detailSessionJob[session.id];
      const listTopics = detailSessionJob[session.id].topics;
      eventInfo = detailSessionJob[session.id];
      details = {
        //eventColor,
        ...getPeriodDateEventsGeneral(listTopics)
      };
    }

    return new TaskModel({
      id: jobId + '_' + session.id,
      parentId: jobId,
      // cls: 'b-grid-leaf-cell b-grid-child-row',
      rowHeight: 35,
      status: session.status,
      sessionId: session.id,
      ...(isUpdate && isExpanded && { expanded: isExpanded(jobId + '_' + session.id) }),
      lvl: GANTT_HIERARCHY.DAYS,
      type: RowTypes.SESSION,
      seeTooltip: false,
      eventColor: localStorage.getItem(`taskColor_${jobId + '_' + session.id}`) ?? session?.color,
      style: 'border',
      ...details,
      manuallyScheduled: true,
      name: isScheduled ? session.name : '',
      [SystemProperties.JOBS_NAME]: jobName,
      job: { _id: jobId, curriculum: { label: curriculumName }, eventColor: curriculumColor },
      children: session.topics.map((topic) => {
        let dateRange = {};
        let detailsTopic = {};
        if (
          topic.status !== StructureStatus.NOT_SCHEDULED &&
          detailSessionJob[session.id] !== undefined
        ) {
          let detailTopic = arrayToObjectId(detailSessionJob[session.id].topics, 'topicId');
          dateRange = getPeriodDatePropiertes(detailTopic[topic.id]);

          detailsTopic = {
            resources: detailTopic[topic.id]?.resources,
            eventColor: detailTopic[topic.id]?.eventColor,
            eventId: detailTopic[topic.id]?.id,
            name: detailTopic[topic.id]?.name
          };
        } else {
          detailsTopic = {
            eventId: topic.id,
            name: topic.name
          };
        }

        const subChild = new TaskModel({
          style: 'height:15px;',
          topicId: topic.id,
          manuallyScheduled: true,
          lvl: GANTT_HIERARCHY.TOPICS,
          cls: 'b-grid-leaf-cell b-grid-child-row',
          type: RowTypes.TOPIC,
          ...detailsTopic,
          seeTooltip: true,
          ...dateRange,
          iconCls: 'hide',
          status: topic.status,
          properties: propertiesJob,
          eventSession: eventInfo,
          job: {
            _id: jobId,
            curriculum: { label: curriculumName },

            status: statusJob
          },
          [SystemProperties.JOBS_NAME]: jobName,
          id: jobId + '_' + session.id + '_' + topic.id,
          parentId: jobId + '_' + session.id
        });
        return subChild;
      })
    } as Partial<TaskModelConfig>);
  });
  return a;
};

export const createSecondaryAssignmentsGantt = (
  assignments: AssignmentModelConfig[],
  jobId: string
): AssignmentModelConfig[] => {
  const newAssignments: AssignmentModelConfig[] = [];
  assignments.forEach((assignment) => {
    const event: TaskModel = assignment.event as TaskModel;
    newAssignments.push({
      id: event.getData('id') + '@' + jobId + '_' + event.getData('sessionId'),
      event: event.getData('id'),
      resource: jobId + '_' + event.getData('sessionId')
    });
    newAssignments.push({
      id: event.getData('id') + '@' + jobId + '_' + event.getData('sessionId'),
      event: event.getData('id'),
      resource: jobId + '_' + event.getData('sessionId') + '_' + event.getData('topicId')
    });
  });
  return newAssignments;
};

export const filterDataJobsProperties = {
  url: PROPERTIES_BY_ENTITY.replace(':entity_name', Entities.JOBS)
};

export const getStatus = (row: Model) => {
  const type = row.getData('type') as StructureStatus;
  const status = rowStatusMap[type](row);
  return status;
};
