import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import common from './lang/en/commons/common.json';
import scheduling from './lang/en/commons/scheduling.json';
import colorInput from './lang/en/molecules/colorInput.json';
import curriculumSetup from './lang/en/organisms/curriculumSetup.json';
import curriculumSidePanel from './lang/en/organisms/curriculumSidePanel.json';
import deletionSeries from './lang/en/organisms/deletionSeries.json';
import dropFile from './lang/en/organisms/dropFile.json';
import fullText from './lang/en/organisms/fullText.json';
import globalFilter from './lang/en/organisms/globalFilter.json';
import navbar from './lang/en/organisms/navbar.json';
import propertiesForm from './lang/en/organisms/propertiesForm.json';
import recurrentEvent from './lang/en/organisms/recurrentEvent.json';
import resourceAssignment from './lang/en/organisms/resourceAssignment.json';
import resourceSlot from './lang/en/organisms/resourceSlot.json';
import scheduleProcessMenuItem from './lang/en/organisms/scheduleProcessMenuItem.json';
import scheduleSummary from './lang/en/organisms/scheduleSummary.json';
import session from './lang/en/organisms/session.json';
import topic from './lang/en/organisms/topic.json';
import unscheduleJobs from './lang/en/organisms/unscheduleJobs.json';
import missionsWorkRulesTemplate from './lang/en/templates/MissionsWorkRulesTemplate.json';
import changePasswordPanelTemplate from './lang/en/templates/changePasswordPanelTemplate.json';
import curriculumsTemplate from './lang/en/templates/curriculumsTemplate.json';
import documentPanelTemplate from './lang/en/templates/documentPanelTemplate.json';
import documentsTemplate from './lang/en/templates/documentsTemplate.json';
import entityTypePanelTemplate from './lang/en/templates/entityTypePanelTemplate.json';
import generalTemplate from './lang/en/templates/generalTemplate.json';
import jobEventPanelTemplate from './lang/en/templates/jobEventPanelTemplate.json';
import jobPanelTemplate from './lang/en/templates/jobPanelTemplate.json';
import loginTemplate from './lang/en/templates/loginTemplate.json';
import mainTemplate from './lang/en/templates/mainTemplate.json';
import myCalendarTemplate from './lang/en/templates/myCalendarTemplate.json';
import propertiesTemplate from './lang/en/templates/propertiesTemplate.json';
import propertyPanelTemplate from './lang/en/templates/propertyPanelTemplate.json';
import propertyPresetPanelTemplate from './lang/en/templates/propertyPresetPanelTemplate.json';
import qualifiedResourcesPanelTemplate from './lang/en/templates/qualifiedResourcesPanelTemplate.json';
import resourceCalendarPanelTemplate from './lang/en/templates/resourceCalendarPanelTemplate.json';
import resourcesImportPanelTemplate from './lang/en/templates/resourceImportPanelTemplate.json';
import resourcePanelTemplate from './lang/en/templates/resourcePanelTemplate.json';
import rolePanelTemplate from './lang/en/templates/rolePanelTemplate.json';
import scheduleConfigTemplate from './lang/en/templates/scheduleConfigTemplate.json';
import scheduleResultTemplate from './lang/en/templates/scheduleResultTemplate.json';
import setPanelTemplate from './lang/en/templates/setPanelTemplate.json';
import setsTemplate from './lang/en/templates/setsTemplate.json';
import signupTemplate from './lang/en/templates/signupTemplate.json';
import userPanelTemplate from './lang/en/templates/userPanelTemplate.json';
import usersTemplate from './lang/en/templates/usersTemplate.json';
declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: (typeof resources)['en-US'];
  }
}

export const defaultNS = 'common';
export const resources = {
  'en-US': {
    common,
    'organisms/scheduleProcessMenuItem': scheduleProcessMenuItem,
    'commons/scheduling': scheduling,
    'molecules/colorInput': colorInput,
    'organisms/scheduleSummary': scheduleSummary,
    'organisms/navbar': navbar,
    'organisms/globalFilter': globalFilter,
    'organisms/fullText': fullText,
    'organisms/recurrentEvent': recurrentEvent,
    'organisms/propertiesForm': propertiesForm,
    'organisms/topic': topic,
    'organisms/session': session,
    'organisms/resourceSlot': resourceSlot,
    'organisms/curriculumSetup': curriculumSetup,
    'organisms/curriculumSidePanel': curriculumSidePanel,
    'organisms/deletionSeries': deletionSeries,
    'organisms/dropFile': dropFile,
    'templates/mainTemplate': mainTemplate,
    'templates/qualifiedResourcesPanelTemplate': qualifiedResourcesPanelTemplate,
    'templates/scheduleConfigTemplate': scheduleConfigTemplate,
    'templates/scheduleResultTemplate': scheduleResultTemplate,
    'templates/propertiesTemplate': propertiesTemplate,
    'templates/jobEventPanelTemplate': jobEventPanelTemplate,
    'templates/resourcePanelTemplate': resourcePanelTemplate,
    'templates/documentPanelTemplate': documentPanelTemplate,
    'templates/propertyPanelTemplate': propertyPanelTemplate,
    'templates/setPanelTemplate': setPanelTemplate,
    'templates/setsTemplate': setsTemplate,
    'templates/generalTemplate': generalTemplate,
    'templates/documentsTemplate': documentsTemplate,
    'templates/loginTemplate': loginTemplate,
    'templates/curriculumsTemplate': curriculumsTemplate,
    'templates/entityTypePanelTemplate': entityTypePanelTemplate,
    'templates/myCalendarTemplate': myCalendarTemplate,
    'templates/signupTemplate': signupTemplate,
    'templates/resourceCalendarPanelTemplate': resourceCalendarPanelTemplate,
    'templates/usersTemplate': usersTemplate,
    'templates/userPanelTemplate': userPanelTemplate,
    'templates/rolePanelTemplate': rolePanelTemplate,
    'templates/resourceImportPanelTemplate': resourcesImportPanelTemplate,
    'templates/missionsWorkRulesTemplate': missionsWorkRulesTemplate,
    'templates/changePasswordPanelTemplate': changePasswordPanelTemplate,
    'templates/jobPanelTemplate': jobPanelTemplate,
    'templates/propertyPresetPanelTemplate': propertyPresetPanelTemplate,
    'organisms/unscheduleJobs': unscheduleJobs,
    'organisms/resourceAssignment': resourceAssignment
  }
};

await i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  // load: 'languageOnly', // If use only language without region
  // lng: navigator.language,
  ns: [
    'common',
    'organisms/scheduleProcessMenuItem',
    'molecules/colorInput',
    'organisms/scheduleSummary',
    'organisms/navbar',
    'organisms/globalFilter',
    'organisms/fullText',
    'organisms/recurrentEvent',
    'organisms/propertiesForm',
    'organisms/topic',
    'organisms/session',
    'organisms/resourceSlot',
    'organisms/curriculumSetup',
    'organisms/curriculumSidePanel',
    'organisms/deletionSeries',
    'organisms/dropFile',
    'organisms/unscheduleJobs',
    'organisms/resourceAssignment',
    'templates/mainTemplate',
    'templates/scheduleConfigTemplate',
    'templates/scheduleResultTemplate',
    'templates/resourcePanelTemplate',
    'templates/documentPanelTemplate',
    'templates/generalTemplate',
    'templates/documentsTemplate',
    'templates/loginTemplate',
    'templates/curriculumsTemplate',
    'templates/setPanelTemplate',
    'templates/setsTemplate',
    'templates/entityTypePanelTemplate',
    'templates/propertiesTemplate',
    'templates/jobEventPanelTemplate',
    'templates/myCalendarTemplate',
    'templates/signupTemplate',
    'templates/resourceCalendarPanelTemplate',
    'templates/usersTemplate',
    'templates/rolePanelTemplate',
    'templates/userPanelTemplate',
    'templates/missionsWorkRulesTemplate',
    'templates/changePasswordPanelTemplate',
    'templates/jobPanelTemplate',
    'templates/resourceImportPanelTemplate',
    'templates/propertyPresetPanelTemplate'
  ],
  resources: resources,
  defaultNS,
  fallbackLng: 'en-US'
});

i18next.services.formatter?.add('lowercase', (value, lng, options) => {
  return value.toLowerCase();
});

export default i18next;
