import {
  CheckFilter,
  ColorInput,
  FixedAutocomplete,
  ImageInput,
  IntervalFilter,
  ObjectidAutocomplete,
  ObjectidAutocompleteProps,
  PeriodFilter,
  SelectFilter,
  SetAutocomplete,
  SetAutocompleteProps,
  TextFilter,
  TimeInput,
  WeekdayInput
} from '@/components/molecules';
import { PropertyTypes, SystemFilters } from '@/const';
import { FieldFilterType, SpecialFilterTypes } from '@/types';
import { ComponentType } from 'react';

export const regExp: Record<string, RegExp> = {
  // string: new RegExp("^[a-zA-Z0-9]"),
};

export const component: Record<FieldFilterType['type'], ComponentType<any>> = {
  [PropertyTypes.STRING]: TextFilter as (props: any) => JSX.Element,
  [PropertyTypes.BOOLEAN]: CheckFilter as (props: any) => JSX.Element,
  [SpecialFilterTypes.CUSTOM_BOOLEAN]: CheckFilter as (props: any) => JSX.Element,
  [SpecialFilterTypes.CUSTOM_SET]: SelectFilter as (props: any) => JSX.Element,
  [PropertyTypes.NUMBER]: IntervalFilter as (props: any) => JSX.Element,
  [PropertyTypes.DATE]: PeriodFilter as (props: any) => JSX.Element,
  [PropertyTypes.DATETIME]: PeriodFilter as (props: any) => JSX.Element,
  [PropertyTypes.OBJECTID]: ObjectidAutocomplete as (props: any) => JSX.Element,
  [PropertyTypes.SET]: SetAutocomplete as (props: any) => JSX.Element,
  [PropertyTypes.COLOR]: ColorInput as (props: any) => JSX.Element,
  [PropertyTypes.TIME]: TimeInput as (props: any) => JSX.Element,
  [PropertyTypes.IMAGE]: ImageInput as (props: any) => JSX.Element,
  [PropertyTypes.WEEKDAYS]: WeekdayInput as (props: any) => JSX.Element,
  [SpecialFilterTypes.OPTIONS]: FixedAutocomplete as (props: any) => JSX.Element
};

export const getPropertiesByType = (
  type: FieldFilterType['type'],
  properties: Record<string, unknown>
) => {
  if (type === PropertyTypes.OBJECTID) {
    return {
      referencedCollection: properties.referencedCollection,
      multiple: true
    } as Partial<ObjectidAutocompleteProps>;
  }
  if (type === PropertyTypes.BOOLEAN || type === SpecialFilterTypes.CUSTOM_BOOLEAN) {
    return {
      sx: { margin: '0 !important' }
    };
  }
  if (type === PropertyTypes.SET) {
    return {
      referencedSet: properties.referencedSet,
      multiple: true
    } as Partial<SetAutocompleteProps>;
  }
  if (type === SpecialFilterTypes.OPTIONS) {
    return {
      options: properties.options,
      getLabel: properties.getLabel,
      multiple: true
    } as Partial<SetAutocompleteProps>;
  }
  if (type === SpecialFilterTypes.CUSTOM_SET) {
    if (properties.referencedSet === 'resourceTypes') {
      return {
        referencedCollection: 'entity-types/by-entity/resources',
        multiple: true,
        selectProperty: 'name'
      };
    }
    if (properties.referencedSet === 'documentTypes') {
      return {
        referencedCollection: 'entity-types/by-entity/documents',
        multiple: true,
        selectProperty: 'name'
      };
    }
    if (properties.referencedSet === SystemFilters.MISSIONS) {
      return {
        referencedCollection: 'missions/labels',
        multiple: true,
        selectProperty: 'label'
      };
    }
    if (properties.referencedSet === SystemFilters.WORK_RULES) {
      return {
        referencedCollection: 'work-rules/labels',
        multiple: true,
        selectProperty: 'label'
      };
    }
  }

  return {};
};
