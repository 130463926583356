import { Entities, PROPERTIES_BY_ENTITY, PropertyTypes, SystemFilters } from '@/const';
import {
  DocumentDto,
  FieldFilterType,
  PropertyDto,
  ResourceBryntum,
  SpecialFilterTypes
} from '@/types';
import { sortingByFetch, transformPropertyValue } from '@/utils';
import { BryntumGrid, BryntumGridProps } from '@bryntum/grid-react-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { Skeleton } from '@mui/material';
import { TFunction } from 'i18next';
import { ForwardedRef, RefObject } from 'react';

export const extraFields: FieldFilterType[] = [
  {
    name: 'Type',
    _id: SystemFilters.ENTITY_TYPE,
    type: SpecialFilterTypes.CUSTOM_SET,
    order: 0,
    referencedSet: 'documentTypes',
    value: []
  }
];

export const getGridConfig = (extraItems: Partial<BryntumGridProps>): BryntumGridProps => {
  return {
    animateRemovingRows: false,
    columns: [
      ...Array(4)
        .fill('')
        .map(() => ({
          text: '...',
          width: 220
        }))
    ],
    filterBarFeature: false,
    stripeFeature: true,
    selectionMode: {
      row: true,
      cell: false,
      multiSelect: true
    },
    cellEditFeature: false,
    rowHeight: 32,
    ...extraItems
  };
};

export const transformDocuments = (documents?: DocumentDto[]) => {
  let localDocuments: ResourceBryntum[];

  if (documents)
    localDocuments = documents.map((document) => {
      const localResource: ResourceBryntum = {
        id: document._id,
        document
      };
      document.properties.forEach(
        (property) => (localResource[property._id] = property.label ?? property.value)
      );
      return localResource;
    });
  else localDocuments = new Array(5).fill('').map((_, idx) => ({ id: `${idx}` }));
  return localDocuments;
};

export const getColumns = (
  ref: ForwardedRef<BryntumGrid>,
  t: TFunction,
  properties?: PropertyDto[]
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumGrid>).current?.instance
    .columns as ColumnStore;
  columnStore.removeAll();
  if (properties) {
    columnStore.add([
      {
        id: 'counter',
        type: 'rownumber',
        field: 'counter',
        cls: 'counter-header',
        cellCls: 'b-border-bottom',
        align: 'center',
        htmlEncodeHeaderText: false,
        hideable: false
      },
      {
        text: 'Type',
        id: 'Type',
        field: 'type',
        width: 220,
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record: { data } }) => {
          return !data.document ? (
            <Skeleton height={30} />
          ) : data?.document?.type?.label ? (
            data.document.type.label
          ) : (
            ''
          );
        }
      },
      ...(properties as PropertyDto[])
        .sort((p1, p2) => p1._id.localeCompare(p2._id))
        .map(({ _id: id, name: text, type }) => ({
          id,
          field: id,
          text,
          width: 220,
          align: type == PropertyTypes.NUMBER ? 'right' : 'left',
          sortable: sortingByFetch,
          groupable: false,
          renderer: ({ record: { data } }) => {
            return !data.document ? (
              <Skeleton height={30} />
            ) : (
              transformPropertyValue(type, t, data[id])
            );
          }
        }))
    ]);
  } else {
    columnStore.add([
      ...new Array(5).fill('').map(() => ({
        text: '...',
        width: 220,
        renderer: () => {
          return <Skeleton height={30} />;
        }
      }))
    ]);
  }
};

export const filterDataDocumentsProperties = {
  url: PROPERTIES_BY_ENTITY.replace(':entity_name', Entities.DOCUMENTS)
};
