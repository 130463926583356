import { CurriculumDto, TimeStrategy, Topic } from '@/types';

import { RenderIf } from '@/components/atoms';
import { ActionTypes, useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { checkStringsExist } from '@/utils';
import { DragEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useFormContext, useWatch } from 'react-hook-form';
import { DragNDropComponents, TabOptions } from '../../CurriculumSetup.const';
import { DROP_SIDES, formatDate } from '../SessionWrapper/SessionWrapper.const';
import { TopicContainer, TopicText } from './TopicCard.styles';

export type TimeSessionTopic = {
  sessionStrategy: TimeStrategy.START_TIMES;
  overlapCount: number;
  moveItem?: never;
  addItem?: never;
};

export type ResourceMissionTopic = {
  sessionStrategy: TimeStrategy.RESOURCE_MISSIONS;
  overlapCount?: never;
  moveItem: (
    sourceSessionIdx: number,
    dragTopicIdx: number,
    destinySessionIdx: number,
    dropTopicIdx: number
  ) => unknown;
  addItem: (sessionIndex: number, dropTopicIdx: number, newItem: Topic) => unknown;
};

export type TopicCardProps = {
  index: number;
  scale: number;
  sessionIndex: number;
  sessionId: string;
  forceUpdate?: () => void;
} & (TimeSessionTopic | ResourceMissionTopic);

const TopicCard = ({
  index,
  sessionIndex,
  sessionStrategy,
  overlapCount,
  moveItem,
  addItem,
  sessionId,
  scale,
  forceUpdate
}: TopicCardProps) => {
  const {
    selectedTopics,
    readOnly,
    setSelectedType,
    handleSelectedTopics,
    handleSelectedSessions,
    setPosition,
    globalDragging,
    isOverSession,
    highlightedItems
  } = useCurriculumSetupContext();
  const { control, getValues } = useFormContext<CurriculumDto>();
  const refContainer = useRef<HTMLDivElement>();

  const topicName = useWatch({
    control,
    name: `structure.sessions.${sessionIndex}.topics.${index}.name`,
    exact: true
  });
  const topicColor = useWatch({
    control,
    name: `structure.sessions.${sessionIndex}.topics.${index}.color`,
    exact: true
  });
  const topicDuration = useWatch({
    control,
    name: `structure.sessions.${sessionIndex}.topics.${index}.duration`,
    exact: true
  });

  const topicStartTime = useWatch({
    control,
    name: `structure.sessions.${sessionIndex}.topics.${index}.startTime`,
    defaultValue: '00:00'
    //exact: true
  });

  const topicSeparation = useWatch({
    control,
    name: `structure.sessions.${sessionIndex}.topics.${index}.postSeparation`,
    exact: true
  });

  useEffect(() => {
    //TODO: Ideally make a calculation to determine if an update is necessary or not
    forceUpdate && !globalDragging && forceUpdate();
  }, [topicDuration, topicStartTime]);

  const renderTopicProps = useMemo(
    () => ({ ...getValues(`structure.sessions.${sessionIndex}.topics.${index}`) }),
    [
      topicName,
      topicColor,
      topicDuration,
      topicSeparation,
      topicStartTime,
      sessionStrategy,
      sessionIndex
    ]
  );

  const topicId = useWatch({
    control,
    name: `structure.sessions.${sessionIndex}.topics.${index}.id`,
    exact: true
  });

  const resourceSlots = useWatch({
    control,
    name: `structure.sessions.${sessionIndex}.topics.${index}.resourceSlots`,
    defaultValue: []
  });

  const isSelec = useMemo(
    () => selectedTopics.map(({ id }) => id).includes(topicId),
    [selectedTopics]
  );

  const [{ isLocalDragging }, drag, preview] = useDrag(
    {
      type: DragNDropComponents.TOPIC,
      item: {
        index,
        sessionIndex,
        topic: renderTopicProps,
        width: refContainer.current?.clientWidth
      },
      canDrag: (monitor) => !readOnly,
      collect: (monitor) => ({ isLocalDragging: !!monitor.isDragging() })
    },
    [renderTopicProps, index, isSelec, sessionIndex, refContainer.current, readOnly]
  );

  const [dropSide, setDropSide] = useState<DROP_SIDES.left | DROP_SIDES.right | null>(null);

  const [{ isOver, overItem }, drop] = useDrop(
    {
      accept: [DragNDropComponents.TOPIC, DragNDropComponents.NEW_TOPIC],
      hover: (
        item: { index: number; sessionIndex: number; topic: Topic; width?: number },
        monitor
      ) => {
        // Determine the rectangle on screen
        const hoverBoundingRect = refContainer.current?.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();
        if (hoverBoundingRect && clientOffset) {
          const hoverMiddleX = hoverBoundingRect.width / 2;

          // Determine mouse position

          const hoverClientX = clientOffset.x - hoverBoundingRect.left;

          if (hoverClientX <= hoverMiddleX) {
            setDropSide(DROP_SIDES.left);
          }
          if (hoverClientX > hoverMiddleX) {
            setDropSide(DROP_SIDES.right);
          }
        } else {
          setDropSide(null);
        }
      },
      drop: (item: { index: number; sessionIndex: number; topic: Topic }, monitor) => {
        const itemType = monitor.getItemType() as DragNDropComponents | null;
        const droppedItem = monitor.getItem().topic as Topic;
        const dragIndex = item.index;
        const dragSessionIdx = item.sessionIndex;
        const hoverIndex = index;

        if (sessionStrategy === TimeStrategy.START_TIMES) {
          return;
        }

        // Don't replace items with themselves

        if (itemType === DragNDropComponents.TOPIC) {
          if (dragIndex < hoverIndex && sessionIndex === dragSessionIdx) {
            if (dropSide === DROP_SIDES.left) {
              moveItem(
                item.sessionIndex,
                dragIndex,
                sessionIndex,
                hoverIndex - 1 >= 0 ? hoverIndex - 1 : 0
              );
            } else if (dropSide === DROP_SIDES.right) {
              moveItem(item.sessionIndex, dragIndex, sessionIndex, hoverIndex);
            } else {
              return;
            }
          } else {
            if (dropSide === DROP_SIDES.left) {
              moveItem(item.sessionIndex, dragIndex, sessionIndex, hoverIndex);
            } else if (dropSide === DROP_SIDES.right) {
              moveItem(item.sessionIndex, dragIndex, sessionIndex, hoverIndex + 1);
            } else {
              return;
            }
          }
        } else if (itemType === DragNDropComponents.NEW_TOPIC) {
          addItem(sessionIndex, hoverIndex, droppedItem);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        overItem: { item: monitor.getItem(), type: monitor.getItemType() }
      })
    },
    [sessionStrategy, dropSide, renderTopicProps, selectedTopics]
  );

  const combinedRef = (node: any) => {
    refContainer.current = node;
    drag(node);
    drop(node);
  };
  const handleTopicSelection = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      topicId: string,
      sessionId: string,
      topicIndex: number,
      sessionIndex: number
    ) => {
      if (event && topicId) {
        handleSelectedSessions({ type: ActionTypes.DELETE_ALL });
        if (navigator.userAgent.includes('Mac') ? event.metaKey : event.ctrlKey) {
          if (selectedTopics.map(({ id }) => id).includes(topicId)) {
            handleSelectedTopics({
              selection: {
                id: topicId,
                index: topicIndex,
                sourceSession: { id: sessionId, index: sessionIndex }
              },
              type: ActionTypes.DELETE
            });
          } else {
            handleSelectedTopics({
              selection: {
                id: topicId,
                index: topicIndex,
                sourceSession: { id: sessionId, index: sessionIndex }
              },
              type: ActionTypes.ADD
            });
          }
        } else {
          handleSelectedTopics({
            selection: [
              {
                id: topicId,
                index: topicIndex,
                sourceSession: { id: sessionId, index: sessionIndex }
              }
            ],
            type: ActionTypes.REPLACE
          });
        }
      }
    },
    [selectedTopics]
  );

  const onDragStart: DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      handleSelectedSessions({ type: ActionTypes.DELETE_ALL });
      if (!selectedTopics.find((selectedTopic) => selectedTopic.id === topicId)) {
        handleSelectedTopics({
          selection: [
            {
              id: topicId,
              index: index,
              sourceSession: { id: sessionId, index: sessionIndex }
            }
          ],
          type: ActionTypes.REPLACE
        });
      }
      setSelectedType(TabOptions.TOPIC);
    },
    [topicId, selectedTopics, sessionId, index, sessionIndex]
  );

  const topicContainerProps = useMemo(() => {
    const time = formatDate(renderTopicProps?.startTime || '00:00');
    return sessionStrategy === TimeStrategy.START_TIMES
      ? {
          sessionStrategy,
          startTime: Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]),
          overlapCount
        }
      : { sessionStrategy };
  }, [sessionStrategy, overlapCount, renderTopicProps]);

  preview(getEmptyImage());
  if (!topicId || !renderTopicProps) return null;
  else if (
    !isOverSession &&
    (isLocalDragging ||
      (globalDragging && selectedTopics.map((selectedTopic) => selectedTopic.id).includes(topicId)))
  ) {
    return <div ref={combinedRef} />;
  } else {
    return (
      <TopicContainer
        isTransparent={checkStringsExist(highlightedItems, [
          ...resourceSlots.map((slot) => slot.id),
          topicId
        ])}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (selectedTopics.length === 1 || !isSelec) {
            handleTopicSelection(e, topicId, sessionId, index, sessionIndex);
            setSelectedType(TabOptions.TOPIC);
          }
          setPosition({ x: e.clientX, y: e.clientY });
        }}
        ref={combinedRef}
        index={index}
        onDragStart={onDragStart}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        {...topicContainerProps}
        duration={renderTopicProps?.duration || 60}
        separation={renderTopicProps?.postSeparation || 0}
        scale={scale}
        color={renderTopicProps?.color}
        isSelected={isSelec}
        isOver={isOver}
        dropWidth={overItem?.item?.width}
        dropSide={dropSide}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleTopicSelection(e, topicId, sessionId, index, sessionIndex);
          setSelectedType(TabOptions.TOPIC);
        }}>
        <RenderIf
          condition={
            sessionStrategy === TimeStrategy.RESOURCE_MISSIONS && isOver && dropSide !== null
          }>
          <div
            style={{
              width: overItem?.item?.width ? `${overItem.item.width}px` : '100%',
              height: '2rem',
              display: 'grid',
              placeItems: 'center',
              position: 'absolute',
              right: dropSide === DROP_SIDES.right ? undefined : '99%',
              left: dropSide === DROP_SIDES.left ? undefined : '99%'
            }}></div>
        </RenderIf>
        <TopicText variant="body2" color={renderTopicProps?.color}>
          {renderTopicProps?.name}
        </TopicText>
      </TopicContainer>
    );
  }
};

export default TopicCard;
