import { SelectedTopic } from '@/context/CurriculmSetupContext';
import { Session, TimeStrategy, Topic } from '@/types';

export const shouldIgnore = (
  session: Session,
  selectedTopics: SelectedTopic[],
  name: keyof Topic
) => {
  if (
    session.timeStrategy === TimeStrategy.RESOURCE_MISSIONS &&
    name === 'startTime' &&
    selectedTopics.length > 1
  )
    return true;
  return false;
};
