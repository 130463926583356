//General routes
export const ADVANCED_SEARCH = '/api/:entity/advanced-search';
export const LIGHT_SEARCH = '/api/:entity/light-search';
export const LABELS_SEARCH = '/api/:entity/labels';

export const COLLECTIONS_FIND_ALL = '/api/:collection_name';
export const COLLECTIONS_FIND_ONE = '/api/:collection_name/:id';
export const COLLECTIONS_BATCH_DELETE = '/api/:collection_name/batch-delete';
export const COLLECTIONS_BATCH_CREATE = '/api/:collection_name/batch-create';
export const COLLECTIONS_BATCH_UPDATE = '/api/:collection_name/batch-update';
export const COLLECTIONS_BATCH_UPDATE_PREPARE = '/api/:collection_name/batch-update/prepare';

export const FULL_SEARCH = '/api/full-text-search';

//Entity Types routes
export const ENTITY_TYPES_BY_ENTITY = '/api/entity-types/by-entity/:entity';
export const ENTITY_TYPES_IS_DELETABLE = '/api/entity-types/is-deletable/:id';

//Properties Routes
export const PROPERTIES_BY_ENTITY = '/api/properties/by-entity/:entity_name';
export const PROPERTIES_ORDER = '/api/properties/order';

//Property switches routes
export const PROPERTY_SWITCHES_BY_ENTITY_TYPE =
  '/api/property-switches/by-switcher/:entity/:entityType';
export const PROPERTY_SWITCHES_BY_PROPERTY = '/api/property-switches/by-property/:entity/:property';
export const PROPERTY_SWITCHES_MANAGE = '/api/property-switches/manage/:entity/:property';
export const PROPERTY_SWITCHES_PROPERTY_HAS_VALUES =
  '/api/property-switches/has-values/:entity/:property';

//Resources routes
export const IMPORT_RESOURCES = '/api/resources/import';

//Jobs routes
export const JOBS_FIRST_EVENT = '/api/jobs/first-event/:id';
export const JOBS_NOT_BEING_SCHEDULED = '/api/jobs/find-not-being-scheduled';
export const JOBS_SCHEDULABLE_ROLES = '/api/jobs/get-schedulable-roles';
export const JOBS_UNSCHEDULABLE_ROLES = '/api/jobs/get-unschedulable-roles';
export const JOBS_ASSIGNABLE_SLOTS = '/api/jobs/get-assignable-slots';
export const JOBS_UNSCHEDULE = '/api/jobs/unschedule';
export const JOBS_CANCEL = '/api/jobs/cancel';
export const JOBS_BATCH_ASSIGN_RESOURCES = '/api/jobs/batch-assign-resources';
export const JOBS_STATUS_DETAILS = '/api/jobs/status-details/:id';
export const JOBS_STATUS_DETAILS_BATCH = '/api/jobs/status-details/batch-get';

//Events routes
export const EVENTS_BY_JOBS = '/api/events/jobs?size=50000&startDate=:startDate&endDate=:endDate';
export const EVENTS_GET_BY_JOBS = '/api/events/jobs';
export const EVENTS_BY_RESOURCES =
  '/api/events/resources?size=50000&startDate=:startDate&endDate=:endDate';
export const EVENTS_BY_RESOURCE =
  '/api/events/resource/:id?size=50000&startDate=:startDate&endDate=:endDate';
export const EVENTS_GET_FOR_EDITION = '/api/events/edit/:id';
export const EVENTS_DELETE_SERIES = '/api/events/series/delete';
export const EVENTS_REMOVE_RESOURCES = '/api/events/resources/remove';

//Sets routes
export const SETS_FIND_BY_NAME = '/api/sets/:setName';
export const SETS_FIND_ALL = '/api/sets/all-items';
export const SETS_IS_DELETABLE = '/api/sets/is-deletable/:setName';

//Updates middleware (socket)
export const SOCKET_URL = `${process.env.REACT_APP_API_URL}/api/socket-middleware`.replace(
  /^http/,
  'ws'
);

//Scheduling routes
export const SCHEDULING_PROCESS_SUMMARY = '/api/scheduling/process/summary';
export const SCHEDULING_PROCESS_GET = '/api/scheduling/process/:id';
export const SCHEDULING_PROCESS_CREATE = '/api/scheduling/process';
export const SCHEDULING_PROCESS_ACTION = '/api/scheduling/process/action';
export const SCHEDULING_QUALIFIED_RESOURCES = '/api/scheduling/qualified-resources';

//Curriculums routes
export const CURRICULUM_IS_READ_ONLY = '/api/curriculums/is-read-only/:id';

//Auth routes
export const AUTH_INVITES = '/api/auth/external/invites';
export const AUTH_INVITES_BY_ID = '/api/auth/external/invites/:id';
export const AUTH_GET_INVITE = '/api/auth/external/get-invite';
export const AUTH_ACCEPT_INVITE = '/api/auth/external/accept-invite';
export const AUTH_ROLES = '/api/auth/external/roles';
export const AUTH_ROLES_BY_ID = `${AUTH_ROLES}/:id`;
export const AUTH_ROLES_LABELS = `${AUTH_ROLES}/labels`;
export const AUTH_ROLES_PERMISSIONS = `${AUTH_ROLES}/get-permissions`;
export const AUTH_USERS = '/api/auth/external/users';
export const AUTH_USERS_BY_ID = `${AUTH_USERS}/:id`;
export const AUTH_USERS_BY_ROLES = `${AUTH_USERS}/by-roles`;
export const AUTH_USERS_BATCH_DELETE = `${AUTH_USERS}/batch-delete`;

//Files routes
export const FILES = '/api/files';
export const FILES_BY_ID = '/api/files/:id';
export const FILES_URL_BY_ID = '/api/files/url/:id';

//System settings routes
export const SYSTEM_SETTINGS = '/api/settings/system';
export const SYSTEM_SETTINGS_SEARCH = `${SYSTEM_SETTINGS}/search`;
export const SYSTEM_SETTINGS_BY_ID = `${SYSTEM_SETTINGS}/:id`;
