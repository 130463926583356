import { useEffect } from 'react';

type WindowUnloadHandler = (event?: BeforeUnloadEvent) => void;

const useWindowUnloadEffect = (handler: WindowUnloadHandler, callOnCleanup: boolean) => {
  useEffect(() => {
    window.addEventListener('beforeunload', handler);

    return () => {
      if (callOnCleanup) handler();

      window.removeEventListener('beforeunload', handler);
    };
  }, [callOnCleanup]);
};

export default useWindowUnloadEffect;
