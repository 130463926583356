import { PropertyTypes } from '@/const';
import { InferType, boolean, object, string } from 'yup';

export const PropertiesFormSchema = object({
  name: string().required().max(100),
  type: string().required(),
  unique: boolean().required(),
  immutable: boolean().required(),
  required: boolean().required(),
  referencedCollection: string().when('type', ([type], schema) =>
    type == PropertyTypes.OBJECTID ? schema.required() : schema
  ),
  referencedSet: string().when('type', ([type], schema) =>
    type == PropertyTypes.SET ? schema.required() : schema
  )
});

export type PropertiesFormSchemaType = InferType<typeof PropertiesFormSchema>;
