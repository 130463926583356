import { ResourceDto } from '@/types';
import { createContext } from 'react';

export type ResourceType = Record<ResourceDto['_id'], ResourceDto>;

export type ResourceContextType = {
  resources: ResourceType;
  handleResources: (newResources: ResourceType) => void;
  addResources: (newResources: ResourceDto[]) => void;
  deleteResource: (idResource: string) => void;
};

export default createContext<ResourceContextType>({} as ResourceContextType);
