import { ResourceSlot, Session, TimeStrategy, Topic, TopicResourceSlot } from '@/types';
import { isTimeGreater } from '@/utils';

export const filterByName = (
  sessions: Session[],
  searchQuery: string,
  getResourceSlot: (id: string) => ResourceSlot
) => {
  return sessions.reduce((acc, curr) => {
    if (curr.name?.toLowerCase().includes(searchQuery)) return acc.concat(curr);
    const topics = curr.topics.reduce((acc, curr) => {
      if (curr.name?.toLowerCase().includes(searchQuery)) return acc.concat(curr);
      const resourceSlots = curr.resourceSlots.reduce((acc, curr) => {
        const rs = getResourceSlot(curr.id);
        if (rs.name?.toLowerCase().includes(searchQuery)) return acc.concat(curr);
        return acc;
      }, [] as TopicResourceSlot[]);
      if (resourceSlots.length > 0) return acc.concat({ ...curr, resourceSlots });
      return acc;
    }, [] as Topic[]);
    if (topics.length > 0) return acc.concat({ ...curr, topics });
    return acc;
  }, [] as Session[]);
};

export const filterNulls = (sessions: Session[]) => {
  return sessions.reduce((acc, curr) => {
    const topics = curr.topics?.reduce((acc, curr) => {
      if (curr.resourceSlots.length === 0) return acc;
      return acc.concat(curr);
    }, [] as Topic[]);
    if (topics.length > 0) return acc.concat({ ...curr, topics });
    return acc;
  }, [] as Session[]);
};

export const sortSessions = (sessions: Session[]): Session[] => {
  return sessions.map((session) => {
    if (session.timeStrategy === TimeStrategy.RESOURCE_MISSIONS) return session;
    return {
      ...session,
      topics: session.topics.sort((a, b) => (isTimeGreater(a.startTime, b.startTime) ? 1 : -1))
    };
  });
};
