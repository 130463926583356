import { RenderIf } from '@/components/atoms';
import { MultipleInputRow } from '@/components/molecules';
import { AutoCompleteField } from '@/const';
import { Properties, PropertyDto, PropertySwitchDto, ValidationFunction } from '@/types';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Control, Controller, FieldValues, FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';
import {
  COMPONENTS_BY_TYPE,
  getPropertiesByType,
  validateByType,
  validateRequired
} from './PropertiesForm.const';

export type PropertiesFormProps = {
  properties: Omit<PropertyDto[], 'entity'>;
  control: Control<Properties & any, any>;
  initialValues?: Properties;
  formState: FormState<FieldValues>;
  isMultipleEntity?: boolean;
  extraValidations?: Record<string, Record<string, ValidationFunction>>;
  propertySwitches?: PropertySwitchDto[];
};

const PropertiesForm = ({
  properties,
  control,
  isMultipleEntity = false,
  initialValues,
  formState: { errors, isDirty, ...others },
  extraValidations = {},
  propertySwitches
}: PropertiesFormProps) => {
  const { t } = useTranslation('organisms/propertiesForm');
  const { t: tCommon } = useTranslation();
  const propertySwitchesIds = propertySwitches?.map(({ property }) => property) ?? [];
  return (
    <>
      <RenderIf condition={properties.length === 0}>
        <Typography>{t('empty')}</Typography>
      </RenderIf>
      <Grid container display={'grid'} gridTemplateColumns={'1fr'} width={'100%'}>
        {properties.map(({ type, name, _id, disabled = false, ...optionals }, idx) => {
          const Component = COMPONENTS_BY_TYPE[type];
          const others = getPropertiesByType(type, optionals);
          return (
            <Fragment key={idx}>
              <Grid width={'100%'} margin={'0.5rem 0'}>
                <Controller
                  key={name}
                  name={`properties.${_id}`}
                  control={control}
                  rules={{
                    validate: {
                      type: validateByType(type),
                      required: validateRequired(optionals?.required, type),
                      ...extraValidations[_id]
                    }
                  }}
                  render={({ field }) => {
                    if (isMultipleEntity) {
                      return (
                        <MultipleInputRow
                          fitInput
                          initialValue={initialValues?.properties?.[_id]}
                          value={field.value}
                          additionalClearRenderConditions={
                            !(optionals?.unique && optionals?.required) && !disabled
                          }
                          onChange={field.onChange}>
                          <Component
                            {...others}
                            label={
                              <>
                                {name}
                                {optionals.required && (
                                  <FaAsterisk size={12} style={{ marginLeft: '5px' }}></FaAsterisk>
                                )}
                              </>
                            }
                            includeMultipleObj
                            id={`properties.${_id}`}
                            name={`properties.${_id}`}
                            autoComplete={AutoCompleteField}
                            disabled={
                              disabled ||
                              optionals?.unique ||
                              (propertySwitches
                                ? propertySwitchesIds.includes(`properties.${_id}`)
                                : false)
                            }
                            value={field.value}
                            onChange={field.onChange}
                            error={!!errors?.['properties']?.[_id]}
                            helperText={
                              errors?.['properties']?.[_id] &&
                              (errors['properties'][_id] as { message: string }).message
                            }
                          />
                        </MultipleInputRow>
                      );
                    } else {
                      return (
                        <>
                          <Component
                            {...others}
                            label={
                              <>
                                {name}
                                {optionals.required && (
                                  <FaAsterisk size={12} style={{ marginLeft: '5px' }}></FaAsterisk>
                                )}
                              </>
                            }
                            name={`properties.${_id}`}
                            id={`properties.${_id}`}
                            autoComplete={AutoCompleteField}
                            readOnly={isMultipleEntity && optionals?.unique}
                            disabled={
                              disabled ||
                              (isMultipleEntity && optionals?.unique) ||
                              (propertySwitches
                                ? propertySwitchesIds.includes(`properties.${_id}`)
                                : false)
                            }
                            value={field.value}
                            onChange={field.onChange}
                            error={!!errors?.['properties']?.[_id]}
                            helperText={
                              errors?.['properties']?.[_id] &&
                              (errors['properties'][_id] as { message: string }).message
                            }
                          />
                        </>
                      );
                    }
                  }}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};

export default PropertiesForm;
