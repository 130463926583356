import { theme } from '@/theme';
import { CSSProperties } from 'styled-components';
import { RenderIf } from '../RenderIf';
import { CustomIconsProps, IconVariant } from './CustomIcons.const';

const TopicIcon = ({
  size = 17,
  color = theme.palette.common.black,
  style = { backgroundColor: theme.palette.divider },
  svgProperties: cssProperties = {},
  variant
}: CustomIconsProps) => {
  return (
    <svg
      style={cssProperties as CSSProperties}
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_16_2" fill={theme.palette.common.white}>
        <path d="M3 32C3 28.134 6.13401 25 10 25H91C94.866 25 98 28.134 98 32V68C98 71.866 94.866 75 91 75H10C6.13401 75 3 71.866 3 68V32Z" />
      </mask>
      <path
        d="M3 32C3 28.134 6.13401 25 10 25H91C94.866 25 98 28.134 98 32V68C98 71.866 94.866 75 91 75H10C6.13401 75 3 71.866 3 68V32Z"
        fill={style.backgroundColor}
      />
      <path
        d="M10 28H91V22H10V28ZM95 32V68H101V32H95ZM91 72H10V78H91V72ZM6 68V32H0V68H6ZM10 72C7.79086 72 6 70.2091 6 68H0C0 73.5228 4.47715 78 10 78V72ZM95 68C95 70.2091 93.2091 72 91 72V78C96.5228 78 101 73.5228 101 68H95ZM91 28C93.2091 28 95 29.7909 95 32H101C101 26.4772 96.5228 22 91 22V28ZM10 22C4.47715 22 0 26.4772 0 32H6C6 29.7909 7.79086 28 10 28V22Z"
        fill={color}
        mask="url(#path-1-inside-1_16_2)"
      />
      <RenderIf condition={variant === IconVariant.ADD}>
        <circle cx="74.5" cy="62.5" r="22.5" fill={color} stroke={color} strokeWidth="4" />
        <path
          d="M62.25 62.5H86.75"
          stroke={theme.palette.common.white}
          strokeWidth="7"
          strokeLinecap="round"
        />
        <path
          d="M74.5 50.25V74.75"
          stroke={theme.palette.common.white}
          strokeWidth="7"
          strokeLinecap="round"
        />
      </RenderIf>
    </svg>
  );
};

export default TopicIcon;
