import { styled } from '@mui/material';

export const Options = styled('div')`
  width: 100%;
  position: sticky;
  height: 2rem;
  top: 0;
  right: 1rem;
  background-color: #fff;
  z-index: 10;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
`;

export const List = styled('div')`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  align-items: center;
  overflow-y: auto;
`;
