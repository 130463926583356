import { StatusIcon } from '@/components/atoms';
import { BryntumHint, Loading } from '@/components/molecules';
import { EntityFilter } from '@/components/organisms';
import Filter from '@/components/organisms/Filter/Filter';
import { JobEventPanelTemplate, JobPanelTemplate } from '@/components/templates/EntityPanels';
import ScheduleConfigModal from '@/components/templates/ScheduleConfigModal';
import { UnscheduleJobsPanelTemplate } from '@/components/templates/UnscheduleJobsPanelTemplate';
import {
  ADVANCED_SEARCH,
  COLLECTIONS_BATCH_DELETE,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  EVENTS_BY_JOBS,
  Entities,
  EntityRoutes,
  FILES_URL_BY_ID,
  Filters,
  JOBS_CANCEL,
  JOBS_FIRST_EVENT,
  JOBS_STATUS_DETAILS,
  JOBS_STATUS_DETAILS_BATCH,
  RESOURCE_LABEL_PROP_IDS,
  SystemProperties
} from '@/const';
import { useFilterContext } from '@/context/FilterContext';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useJobContext } from '@/context/JobsContext';
import { useModalConfirmationContext } from '@/context/ModalConfirmationContext';
import { useResourceContext } from '@/context/ResourceContext';
import { useScheduleConfigContext } from '@/context/ScheduleConfigContext';
import { useScheduleContext } from '@/context/SchedulerContext';
import { useSocketContext } from '@/context/SocketContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch, useGetFilteredData } from '@/hooks';
import useCustomBryntumSorter from '@/hooks/useCustomBryntumSorter';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import useWindowUnloadEffect from '@/hooks/useWindowOnLoad';
import {
  equalResources,
  loadStateConfigBryntum,
  saveStateConfigBryntum,
  setLoading,
  setLoadingTimeAxis
} from '@/services/utils/utils';
import { theme } from '@/theme';
import {
  AssignmentBryntum,
  BryntumCellMenuContext,
  BryntumEventMenuContext,
  DbClickEvent,
  EventBryntum,
  EventDto,
  EventTooltipFeatureData,
  FieldFilterType,
  JobDetailsDto,
  JobDto,
  PropertyDto,
  ResourceBryntum,
  ResourceDto,
  ResourceQueryResult,
  SchedulerNames,
  StructureStatus,
  TEMPORAL_CHILD_ID,
  ZoomLevels
} from '@/types';
import {
  defaultEventMenuContextOptions,
  defaultMenuContextOptions,
  getCommonSchedulerConfig,
  getEventLocation,
  getPropertyValueDef,
  loadState,
  replaceTo,
  saveState
} from '@/utils';
import { ContainerItemConfig, Menu, Model, Store, StringHelper } from '@bryntum/core-thin';
import { ColumnStore, Grid } from '@bryntum/grid-thin';
import { BryntumScheduler, BryntumSchedulerProps } from '@bryntum/scheduler-react-thin';
import {
  AssignmentModel,
  AssignmentStore,
  EventModel,
  EventStore,
  ResourceModel,
  Scheduler
} from '@bryntum/scheduler-thin';
import { Grid as GridMUI, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { isArray } from 'lodash';
import { DateTime } from 'luxon';
import {
  ForwardedRef,
  MutableRefObject,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { ScheduleContainer } from '../../MainTemplate.styles';
import {
  RowTypes,
  createSecondaryAssignments,
  formatStructure,
  getAssignmentsFromEvents as getAssignmentsFromJobEvents,
  getColumns,
  getExtraFields,
  getStatus,
  transformJobsToScheduler
} from './SchedulerJobs.const';
import { use } from 'i18next';
export type SchedulerJobsProps = {
  open: boolean;
  resourceFilter?: string[];
  jobsFilterSetter: (newJobs) => unknown;
  jobsFetchTrigger?: Date;
  deleteCommonEvent: (eventId: string) => unknown;
  fullText: string | undefined;
  masterDetail: number;
  toggleFilter: () => void;
  updateEvents: (
    scheduler: SchedulerNames,
    events: EventBryntum[],
    assignments: AssignmentBryntum[],
    silent?: boolean
  ) => void;
  triggerScheduler: (scheduler: SchedulerNames) => void;
  onEditEvents: (editedEvents: EventDto[], silent?: boolean) => unknown;
};

const SchedulerJobs = (
  {
    open,
    resourceFilter,
    jobsFilterSetter,
    deleteCommonEvent,
    jobsFetchTrigger,
    fullText,
    masterDetail,
    toggleFilter,
    triggerScheduler,
    onEditEvents
  }: SchedulerJobsProps,
  ref: ForwardedRef<BryntumScheduler>
) => {
  const { t: tMainTemplate } = useTranslation('templates/mainTemplate');
  const propertiesRef = useRef<HTMLButtonElement>(null);
  const { t: tCommon } = useTranslation();
  const { isProcessingScheduling } = useScheduleContext();
  const { showConfirmation, handleCancel } = useModalConfirmationContext();

  const { settings: jobSettings, setSettings: setJobSettings } = useFilterContext(Entities.JOBS);

  const localJobsFilters = loadState<FieldFilterType[]>(Filters.JOBS) || [];

  const [jobFilters, setJobFilters] = useState<FieldFilterType[]>(localJobsFilters);

  const handleJobFilters = (form: FieldFilterType[]) => {
    setJobFilters(form);
    saveState(Filters.JOBS, form);
  };

  const { initialize, handleClose, handleDirtyModal, preventFromClose } = useGenericModalContext();
  const { selectedJobs, setSelectedJobs } = useScheduleConfigContext();
  const { resources: localResources, addResources } = useResourceContext();
  const { jobs: localJobs, addJobs, deleteJob } = useJobContext();
  const { setToastNotifications } = useToastContext();

  const { loading: globalLoading, data: globalData, fetchByJobs } = useGetFilteredData();

  const newSorter = useCustomBryntumSorter({
    gridRef: ref as MutableRefObject<BryntumScheduler>,
    refName: SchedulerNames.JOBS
  });

  const { processProgress } = useScheduleContext();

  const fetchData = () => {
    console.log('fetchData123jobs', masterDetail);
    if (masterDetail == 1) {
      fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
    }
    if (masterDetail == 2 && Array.isArray(resourceFilter)) {
      fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
    }
    if (masterDetail == 0) {
      fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
    }
  };

  useEffect(() => {
    properties && fetchData();
  }, [jobFilters, fullText, resourceFilter, newSorter, jobsFetchTrigger]);

  useEffect(() => {
    if (processProgress) {
      for (const id in processProgress) {
        if (Object.prototype.hasOwnProperty.call(processProgress, id)) {
          const job = processProgress[id];
          if (job.completed) {
            fetchData();
          }
        }
      }
    }
  }, [processProgress]);

  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    const columnStore: ColumnStore = (ref as RefObject<BryntumScheduler>).current?.instance
      .columns as ColumnStore;
    if (columnStore && columnStore.getById('counter')) {
      columnStore.getById('counter').text = renderToString(
        <GridMUI display={'flex'} flexWrap="wrap">
          {tCommon('text.count')}
          <Typography>{tCommon('number', { value: counter })}</Typography>
        </GridMUI>
      );
    }
  }, [ref, counter]);

  const jobs = useMemo(() => {
    if (globalData) {
      setCounter(globalData?.count || 0);
      return globalData?.jobs;
    }
  }, [globalData]);

  useEffect(() => {
    if (jobs) {
      jobsFilterSetter(jobs.map((job) => job._id));
    }
  }, [jobs]);

  const [currentStartDate, setCurrentStartDate] = useState<Date>(
    DateTime.now().startOf('month').minus({ months: 1 }).toJSDate()
  );
  const [currentEndDate, setCurrentEndDate] = useState<Date>(
    DateTime.now().startOf('month').plus({ months: 1 }).endOf('month').toJSDate()
  );

  const filterDataJobsEvents = useMemo(() => {
    if (jobs) {
      const store = ((ref as MutableRefObject<BryntumScheduler>).current.instance.subGrids as any)
        .locked.store as Store;
      if (store.allRecords.length > 0) {
        return {
          url: replaceTo(
            replaceTo(EVENTS_BY_JOBS, ':startDate', currentStartDate.toISOString()),
            ':endDate',
            currentEndDate.toISOString()
          ),
          method: 'POST',
          data:
            store.allRecords[0].id === 0
              ? jobs.map(({ _id }) => _id)
              : store.allRecords
                  .filter((jobRecord) => jobRecord.id == jobRecord.getData('job')?._id)
                  .map(({ id }) => id)
        };
      } else {
        const eventStore = (ref as MutableRefObject<BryntumScheduler>).current.instance
          .eventStore as EventStore;
        eventStore.removeAll();
      }
    }
  }, [jobs, currentStartDate, currentEndDate]);

  const {
    data: jobEvents,
    loading: loadingEvents,
    error: errorJobEvents
  } = useFetch<EventDto[]>(filterDataJobsEvents);

  useEffect(() => {
    setLoadingTimeAxis(ref, loadingEvents);
  }, [loadingEvents]);

  useEffect(() => {
    if (errorJobEvents) {
      //setToastNotifications([{ message: tMainTemplate('errors.loadingJobEvents') }]);
    }
  }, [errorJobEvents]);

  const { loading: loadingProperties, properties } = useGetPropertiesByEntity<PropertyDto[]>(
    Entities.JOBS,
    undefined,
    (data) => setJobFilters(data)
  );

  const { fetch: eventsRemover, error: errorEventsRemover } = useFetch(
    undefined,
    () => fetchByJobs(jobFilters, fullText, resourceFilter, newSorter),
    [jobFilters, fullText, resourceFilter, newSorter]
  );
  const { fetch: fetchFirstEvent, error: errorFirstEvent } = useFetch<EventDto, AxiosError>();

  const { fetch: fetchJobForHint, error: errorJobHint } = useFetch<JobDto>();
  const { fetch: fetchResourcesForHint, error: errorResourcesForHint } =
    useFetch<ResourceQueryResult>();
  const { fetch: fetchFile, error: errorImage } = useFetch<string>();

  useEffect(() => {
    if (errorImage) {
      setToastNotifications([{ message: tCommon('errors.loadingImage') }]);
    }
  }, [errorImage]);

  useEffect(() => {
    if (errorJobHint || errorResourcesForHint) {
      setToastNotifications([{ message: tMainTemplate('errors.loadingHint') }]);
    }
  }, [errorJobHint, errorResourcesForHint]);

  const extraItems = {
    eventMenuFeature: false,
    scheduleMenuFeature: false,
    eventTooltipFeature: {
      allowOver: true,
      onBeforeShow({ source: tooltip }) {
        tooltip['html'] = renderToString(<Loading />);
        tooltip['title'] = StringHelper.encodeHtml(tooltip.eventRecord?.name);
      },
      template: async ({ eventRecord }: EventTooltipFeatureData<EventBryntum<EventDto>>) => {
        let job: JobDto;
        let resources: Record<string, ResourceDto> = {};
        const fill: ResourceDto['_id'][] = [];

        const { originalData } = eventRecord;

        if (originalData.job in localJobs) job = localJobs[originalData.job];
        else {
          job = (await fetchJobForHint({
            url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.JOBS).replace(
              ':id',
              originalData.job
            )
          })) as JobDto;
          addJobs([job]);
        }

        originalData.resources?.forEach(({ resource }) => {
          if (resource in localResources)
            resources = { ...resources, [resource]: localResources[resource] };
          else fill.push(resource);
        });

        if (fill.length > 0) {
          const result = (await fetchResourcesForHint({
            url: ADVANCED_SEARCH.replace(':entity', Entities.RESOURCES),
            method: 'POST',
            data: { resourceIds: fill }
          })) as ResourceQueryResult;

          addResources(result.resources);
          const newResult = result.resources.reduce<Record<string, ResourceDto>>(
            (acc, curr) => ({
              ...acc,
              [curr._id]: curr
            }),
            {}
          );
          resources = { ...resources, ...newResult };
        }

        const resourceProperties =
          originalData.resources?.reduce<Record<string, Record<string, string>[]>>(
            (acc, { roleName, resource }) => {
              const resourceWithProperties = { _id: resource };
              resources[resource]?.properties
                .filter((prop) => RESOURCE_LABEL_PROP_IDS.has(prop._id))
                .forEach((prop) => {
                  resourceWithProperties[prop._id] = prop.value;
                });

              return {
                ...acc,
                [roleName]:
                  roleName in acc
                    ? [...acc[roleName], resourceWithProperties]
                    : [resourceWithProperties]
              };
            },
            {}
          ) || {};

        const imagesUrls = await Promise.all(
          Object.keys(resourceProperties)
            .map((roleName) => resourceProperties[roleName])
            .reduce((acc, current) => acc.concat(current), [])
            .map((resource) => {
              if (resource[SystemProperties.RESOURCES_PICTURE]) {
                return fetchFile({
                  url: FILES_URL_BY_ID.replace(':id', resource[SystemProperties.RESOURCES_PICTURE])
                });
              }
            })
        );
        const images = Object.keys(resourceProperties)
          .map((roleName) => resourceProperties[roleName])
          .reduce((acc, current) => acc.concat(current), [])
          .reduce(
            (acc, current, idx) => ({ ...acc, [current._id]: imagesUrls[idx] as string }),
            {}
          );
        return renderToString(
          <BryntumHint
            key={originalData._id}
            startDate={originalData.startDate}
            endDate={originalData.endDate}
            location={getEventLocation(originalData) as string}
            job={[getPropertyValueDef(job.properties, SystemProperties.JOBS_NAME, '')]}
            additionalHeaderRender={
              <>
                {job.status === StructureStatus.CANCELED && (
                  <div className="b-hint-resource">
                    <StatusIcon size={1} status={job.status} />
                    <Typography>{tCommon(`jobStatus.${job.status}`) as string}</Typography>
                  </div>
                )}
                {originalData.join && (
                  <div className="b-hint-resource">
                    <Typography>
                      {tMainTemplate('text.joinedCount', { count: 'n' as unknown as number })}
                    </Typography>
                  </div>
                )}
              </>
            }
            resources={Object.keys(resourceProperties).map((roleName) => (
              <>
                <Typography key={roleName} variant="body1" className="b-resource-title">
                  {roleName}
                </Typography>
                {resourceProperties[roleName].map((resource, idx) => (
                  <div
                    key={resource._id}
                    style={{ marginTop: idx === 0 ? '0' : '4px' }}
                    className="b-hint-resource">
                    <GridMUI
                      sx={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center' }}>
                      {images[resource._id] && !((images[resource._id] as any) instanceof Error) ? (
                        <img
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '20px',
                            border: `solid ${theme.palette.grey[400]} 1px`
                          }}
                          src={images[resource._id]}
                        />
                      ) : (
                        <FaUserCircle color={theme.palette.grey[400]} size={24} />
                      )}
                    </GridMUI>

                    <Typography component="div" variant="body2">
                      {`${
                        resource[SystemProperties.RESOURCES_FIRST_NAME]
                          ? `${resource[SystemProperties.RESOURCES_FIRST_NAME]} `
                          : ''
                      }${resource[SystemProperties.RESOURCES_NAME] ?? tCommon('unknown')} (${
                        resource[SystemProperties.RESOURCES_ID] ?? tCommon('unknown')
                      })`}
                    </Typography>
                  </div>
                ))}
              </>
            ))}
          />
        );
      }
    }
  } as Partial<BryntumSchedulerProps>;

  const headerMenuFeature = useMemo(() => {
    return {
      processItems({ items }) {
        if (!globalLoading) {
          items.groupAsc = false;
          items.groupDesc = false;
        } else {
          items.columnPicker = false;
          items.groupAsc = false;
          items.groupDesc = false;
          items.hideColumn = false;
          items.multiSort = false;
          items.sortAsc = false;
          items.sortDesc = false;
        }
      }
    };
  }, [globalLoading]);

  const updateJob = useCallback(
    async (jobs: JobDto | JobDto[]) => {
      const store = ((ref as MutableRefObject<BryntumScheduler>).current.instance.subGrids as any)
        .locked.store as Store;
      const gridRef = (ref as RefObject<BryntumScheduler>)?.current?.instance as Grid;
      const jobsArr: JobDto[] = isArray(jobs) ? jobs : [jobs];

      for (const row of jobsArr) {
        deleteJob(row._id);
        addJobs([row]);

        const job: ResourceBryntum = transformJobsToScheduler(false, [row])[0];
        const data: Model = store.getById(row._id);
        properties?.forEach((prop) => {
          data.set(prop._id, job[prop._id]);
        });

        data.set('job', row);
        gridRef.selectRow({ record: data, scrollIntoView: false });
      }
    },
    [properties]
  );

  const addNewJobs = async (job: JobDto | JobDto[]) => {
    const store = ((ref as MutableRefObject<BryntumScheduler>).current.instance.subGrids as any)
      .locked.store as Store;
    const gridRef = (ref as RefObject<BryntumScheduler>)?.current?.instance as Grid;
    const jobArr = isArray(job) ? job : [job];
    addJobs(jobArr);
    const newJobArr: ResourceBryntum[] = transformJobsToScheduler(false, jobArr);

    const newRows: Model[] = store.insert(0, newJobArr);
    setCounter((last) => last + newJobArr.length);
    if (gridRef) {
      gridRef.selectedRecords = newRows;
      gridRef.scrollToTop();
    }
  };

  const onCellDblClick = useCallback(
    (ev: DbClickEvent) => {
      if (
        !loadingProperties &&
        ev.column.getData('type') !== 'timeAxis' &&
        ev.record.getData('job')?._id
      ) {
        initialize(
          <JobPanelTemplate
            mode={CrudModes.EDIT}
            jobId={ev.record.getData('job')._id}
            onSuccess={(e) => {
              if (e) {
                updateJob(e);
                handleClose(false);
              }
            }}
            onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.jobs_one'))}
            onLoading={preventFromClose}
            onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.jobs_one'))}
          />,
          { modalName: tCommon('entities.jobs_one') }
        );
      }
    },
    [loadingProperties, resourceFilter]
  );

  const handleError = (message: string) => {
    setToastNotifications([{ message }]);
  };

  const eventMenuFeature = useMemo(
    () => ({
      processItems(context: BryntumEventMenuContext) {
        const { items, eventRecord, assignmentRecord } = context;
        defaultEventMenuContextOptions(items);
        items.customEditEvent = {
          text: tMainTemplate('actions.editEvent'),
          icon: 'b-icon b-fa-pen-to-square',
          disabled: !!eventRecord.getData('join') || globalLoading || loadingProperties,
          onItem: async (ev) => {
            initialize(
              <JobEventPanelTemplate
                mode={CrudModes.EDIT}
                eventId={assignmentRecord.getData('eventId')}
                resourceId={assignmentRecord.getData('resourceId')}
                onSuccess={(editedEvent, oldEvent) => {
                  const silent = equalResources(editedEvent, oldEvent);
                  onEditEvents([editedEvent], silent);
                  handleClose(false);
                }}
                onDirtyFields={(isDirty) =>
                  handleDirtyModal(isDirty, tCommon('entities.events_one'))
                }
                onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
                onLoading={(loading) => preventFromClose(loading)}
              />,
              { modalName: tCommon('entities.events_one') }
            );
          }
        };
        items.customDeleteEvent = {
          text: tCommon('actions.deleteElement', {
            element: tCommon('entities.events_one')
          }),
          icon: 'b-icon b-fa-trash-can',
          disabled:
            !!assignmentRecord.getData('showAsJoined') || globalLoading || loadingProperties,
          onItem: async (ev) => {
            const result: Record<string, unknown> = await showConfirmation({
              title: tCommon('modalConfirmationDelete.title'),
              message: tCommon('modalConfirmationDelete.message_one', {
                element: tCommon('entities.events_one')
              }),
              confirmButton: tCommon('buttons.delete'),
              cancelButton: tCommon('buttons.cancel'),
              fetchData: {
                url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.EVENTS).replace(
                  ':id',
                  assignmentRecord.getData('eventId')
                ),
                method: 'DELETE'
              },
              handleError: (error) => {
                setToastNotifications([{ message: tCommon('errors.deletionError') }]);
              }
            });
            if (result) {
              deleteCommonEvent(assignmentRecord.getData('eventId'));
            }
          }
        };
      }
    }),
    [globalLoading, loadingProperties, masterDetail]
  );

  const cellMenuFeature = useMemo(() => {
    return {
      processItems(context: BryntumCellMenuContext) {
        const { items, selection, record } = context;
        const selectedJobs: JobDto[] = selection
          .map((jobModel: Model) => jobModel.getData('job') as JobDto)
          .filter((job) => !!job) as JobDto[];

        const selectedJobsWithoutChildren: JobDto[] = selection
          .filter((jobModel: Model) => jobModel.id === jobModel.getData('job')?._id)
          .map((jobModel: Model) => jobModel.getData('job') as JobDto);

        const uniqueJobIds: string[] = Array.from(
          new Set(
            selection
              .filter((jobModel: Model) => jobModel.getData('job')?._id)
              .map((jobModel: Model) => jobModel.getData('job')._id as string)
          )
        );
        const cancelableJobs = selectedJobs.filter(
          (job) =>
            (job?.status == StructureStatus.PARTIALLY_SCHEDULED ||
              job?.status == StructureStatus.COMPLETE) &&
            !job?.beingScheduled
        );
        const expandedJobRows = selection.filter(
          (rowModel: Model) =>
            rowModel.firstChild?.id !== rowModel.id + TEMPORAL_CHILD_ID &&
            rowModel.getData('type') === RowTypes.JOB
        );
        if (!globalLoading) {
          defaultMenuContextOptions(items);
          items.createJob = {
            text: `${tMainTemplate('actions.createJob')}`,
            icon: 'b-icon b-fa-circle-plus',
            onItem: async (_ev) => {
              initialize(
                <JobPanelTemplate
                  mode={CrudModes.CREATE}
                  onSuccess={(e) => {
                    if (e) {
                      addNewJobs(e);
                      handleClose(false);
                    }
                  }}
                  onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.jobs_one'))}
                  onLoading={preventFromClose}
                  onDirtyFields={(isDirty) =>
                    handleDirtyModal(isDirty, tCommon('entities.jobs_one'))
                  }
                />,
                { modalName: tCommon('entities.jobs_one') }
              );
            }
          };
          items.editJobs = {
            text: tCommon('actions.editElement', {
              element: tCommon(`entities.${selection.length > 1 ? 'jobs_other' : 'jobs_one'}`)
            }),
            icon: 'b-icon b-fa-pen-to-square',
            disabled:
              uniqueJobIds.length === 0 ||
              selectedJobsWithoutChildren.filter((job) => job.beingScheduled).length > 0,
            onItem: (ev) => {
              const jobId: string | string[] =
                uniqueJobIds.length > 1 ? uniqueJobIds : (ev?.record?.getData('job')._id as string);
              initialize(
                <JobPanelTemplate
                  mode={CrudModes.EDIT}
                  jobId={jobId}
                  onSuccess={(e) => {
                    if (e) {
                      updateJob(e);
                      handleClose(false);
                    }
                  }}
                  onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.jobs_one'))}
                  onLoading={preventFromClose}
                  onDirtyFields={(isDirty) =>
                    handleDirtyModal(isDirty, tCommon('entities.jobs_one'))
                  }
                />,
                { modalName: tCommon('entities.jobs_one') }
              );
            }
          };
          items.deleteJobs = {
            text: tCommon('actions.deleteElement', {
              element: tCommon('entities.jobs', { count: selection.length })
            }),
            icon: 'b-icon b-fa-trash-can',
            onItem: async (ev) => {
              const result: Record<string, unknown> = await showConfirmation({
                title: tCommon('modalConfirmationDelete.title'),
                message: (
                  <>
                    {tCommon('modalConfirmationDelete.message', {
                      count: uniqueJobIds.length,
                      number: uniqueJobIds.length,
                      element: tCommon('entities.jobs', {
                        count: uniqueJobIds.length
                      })
                    })}
                    <Typography variant="body2" marginTop="1rem">
                      <strong>{tCommon('utils.note') + ' '}</strong>
                      {tMainTemplate('noteDeleteJob')}
                    </Typography>
                  </>
                ),
                confirmButton: tCommon('buttons.delete'),
                cancelButton: tCommon('buttons.cancel'),
                fetchData:
                  uniqueJobIds.length > 1
                    ? {
                        url: COLLECTIONS_BATCH_DELETE.replace(
                          ':collection_name',
                          EntityRoutes.JOBS
                        ),
                        method: 'POST',
                        data: {
                          ids: uniqueJobIds
                        }
                      }
                    : {
                        url: COLLECTIONS_FIND_ONE.replace(
                          ':collection_name',
                          EntityRoutes.JOBS
                        ).replace(':id', ev?.record?.getData('job')._id),
                        method: 'DELETE'
                      },
                handleError: (error) => {
                  setToastNotifications([{ message: tCommon('errors.deletionError') }]);
                }
              });
              if (result) {
                fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
                selection.length > 1
                  ? (result?.validJobs as string[] | undefined)?.forEach((job) => {
                      deleteJob(job as string);
                    })
                  : deleteJob(ev?.record?.id as string);
              }
            }
          };
          items.runSchedule = {
            text: tMainTemplate('actions.runSchedule'),
            disabled:
              globalLoading ||
              !(
                selectedJobsWithoutChildren.filter(
                  (job) => job?.status !== StructureStatus.CANCELED
                ).length > 0
              ) ||
              selectedJobsWithoutChildren.length < 1,
            icon: 'b-icon b-fa-play',
            cls: 'b-separator',
            onItem: async (ev) => {
              if (isProcessingScheduling()) {
                const result = await showConfirmation({
                  title: tMainTemplate('noteScheduleJob.title'),
                  confirmButton: tCommon('buttons.continue'),
                  cancelButton: tCommon('buttons.cancel'),
                  message: tMainTemplate('noteScheduleJob.message')
                });

                if (!result) return;
              }
              setSelectedJobs(
                selectedJobsWithoutChildren.filter(
                  (job) => job?.status !== StructureStatus.CANCELED
                )
              );
              initialize(<ScheduleConfigModal />);
            }
          };
          items.goToFirstEvent = {
            text:
              selection.filter((rowModel: Model) =>
                [RowTypes.JOB, RowTypes.SESSION].includes(rowModel.getData('type'))
              ).length > 0
                ? tMainTemplate('actions.goToFirstEvent')
                : tMainTemplate('actions.goToEvent'),
            icon: 'b-icon b-fa-calendar',
            disabled:
              globalLoading ||
              selection.length > 1 ||
              selection.filter((rowModel: Model) => {
                return [StructureStatus.NOT_SCHEDULED as string, undefined].includes(
                  getStatus(rowModel)
                );
              }).length > 0,
            onItem: async (ev) => {
              const params = {
                sessionId: selection[0].getData('sessionId'),
                topicId: selection[0].getData('topicId')
              };
              const firstEvent = (await fetchFirstEvent({
                url: JOBS_FIRST_EVENT.replace(':id', selection[0].getData('job')._id),
                method: 'GET',
                params
              })) as EventDto;
              if (firstEvent && firstEvent.properties) {
                const firstEventDate = new Date(
                  getPropertyValueDef(
                    firstEvent.properties,
                    SystemProperties.EVENTS_START_TIMESTAMP,
                    ''
                  )
                );
                if (!isNaN(firstEventDate.getTime())) {
                  (ref as RefObject<BryntumScheduler>)?.current?.instance.scrollToDate(
                    firstEventDate,
                    {
                      animate: {
                        easing: 'easeFromTo',
                        duration: 600
                      },
                      block: 'center'
                    }
                  );
                  setTimeout(() => {
                    (ref as RefObject<BryntumScheduler>)?.current?.instance.scrollToDate(
                      firstEventDate
                    );
                  }, 600);
                }
              }
            }
          };
          items.unschedule = {
            disabled:
              globalLoading ||
              selectedJobs.filter(
                (job) =>
                  !job.beingScheduled &&
                  job.status &&
                  [StructureStatus.COMPLETE, StructureStatus.PARTIALLY_SCHEDULED].includes(
                    job.status
                  )
              ).length === 0 ||
              selectedJobsWithoutChildren.length < 1,
            text: tMainTemplate('actions.unschedule'),
            icon: 'b-icon b-fa-times',
            onItem: async (ev) => {
              initialize(
                <UnscheduleJobsPanelTemplate
                  eventInfo={ev}
                  onSuccess={() => fetchByJobs(jobFilters, fullText, resourceFilter, newSorter)}
                />
              );
            }
          };
          items.cancel = {
            text: tCommon('buttons.cancel'),
            disabled: cancelableJobs.length === 0,
            icon: 'b-icon b-fa-circle-minus',
            onItem: async (ev) => {
              const jobs = cancelableJobs.map((job) => job._id);
              const result: JobDto[] = await showConfirmation({
                title: tCommon('modalConfirmationCancel.title'),
                message: (
                  <>
                    {tCommon('modalConfirmationCancel.message', {
                      element: tCommon('entities.jobs', { count: jobs.length }),
                      count: jobs.length
                    })}
                    <Typography variant="body2" marginTop="1rem">
                      <strong>{tCommon('utils.note') + ' '}</strong>
                      {tMainTemplate('noteCancelJob')}
                    </Typography>
                  </>
                ),
                confirmButton: tCommon('buttons.yes'),
                cancelButton: tCommon('buttons.no'),
                fetchData: {
                  url: JOBS_CANCEL,
                  data: {
                    jobs
                  },
                  method: 'POST'
                }
              });
              if (result) {
                const jobStore = (ref as MutableRefObject<BryntumScheduler>).current.instance
                  .resourceStore;
                jobStore.beginBatch();
                result.forEach((job) => {
                  updateJob(job);
                });
                const jobIds = expandedJobRows.map((rowModel: Model) => rowModel.id);
                const details = await axios
                  .post<JobDetailsDto[]>(JOBS_STATUS_DETAILS_BATCH, {
                    ids: jobIds
                  })
                  .catch(() => handleError(tCommon('errors.defaultError')));
                if (!details) return;

                details.data.map((job) => {
                  job.sessions.map((session) => {
                    const rowSession = jobStore.getById(job._id + '_' + session.id);
                    rowSession.set('status', session.status);
                    session.topics.map((topic) => {
                      const rowTopic = jobStore.getById(
                        job._id + '_' + session.id + '_' + topic.id
                      );
                      rowTopic.set('status', topic.status);
                    });
                  });
                });

                const jobEvents = (
                  await axios.post(
                    EVENTS_BY_JOBS.replace(':startDate', currentStartDate.toISOString()).replace(
                      ':endDate',
                      currentEndDate.toISOString()
                    ),
                    result.map((job) => job._id)
                  )
                ).data as EventDto[];
                onEditEvents(jobEvents);
                jobStore.endBatch();
              }
            }
          };
          items.selectAllResources = {
            text: `${tCommon('actions.selectAll')}`,
            icon: 'b-icon b-fa-list-check',
            cls: 'b-separator',
            onItem: async () => {
              const instanceRef = (ref as RefObject<BryntumScheduler>)?.current?.instance;
              instanceRef?.selectAll();
            }
          };
        } else {
          defaultMenuContextOptions(items);
        }
      }
    };
  }, [globalLoading, isProcessingScheduling, ref]);

  const menu = useMemo(() => {
    return new Menu({
      autoShow: false,
      items: [
        {
          text: `${tMainTemplate('actions.createJob')}`,
          icon: 'b-icon b-fa-circle-plus',
          onItem: async (_ev) => {
            initialize(
              <JobPanelTemplate
                mode={CrudModes.CREATE}
                onSuccess={(e) => {
                  if (e) {
                    addNewJobs(e);
                    handleClose(false);
                  }
                }}
                onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.jobs_one'))}
                onLoading={preventFromClose}
                onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.jobs_one'))}
              />,
              { modalName: tCommon('entities.jobs_one') }
            );
          }
        },
        {
          text: `${tCommon('actions.selectAll')}`,
          icon: 'b-icon b-fa-list-check',
          cls: 'b-separator',
          onItem: async (_ev) => {
            const instanceRef = (ref as RefObject<BryntumScheduler>)?.current?.instance;
            instanceRef?.selectAll();
          }
        } as Partial<ContainerItemConfig>
      ] as Partial<ContainerItemConfig>[]
    });
  }, []);

  useEffect(() => {
    const gridRef = ref as RefObject<BryntumScheduler>;
    if (gridRef && gridRef.current) {
      gridRef.current.element.onkeydown = (ev) => {
        if (ev.ctrlKey && ev.key == 'a') {
          gridRef.current?.instance.selectAll();
          ev.stopPropagation();
          ev.preventDefault();
        }
      };
      (gridRef.current.instance.subGrids as any)['locked'].currentElement.addEventListener(
        'contextmenu',
        (ev: any) => {
          if (gridRef && gridRef.current && ev.target.$refOwnerId) {
            ev.preventDefault();
            menu.showBy([ev.clientX, ev.clientY]);
          }
        }
      );
    }
  }, []);

  const configSchedule = useMemo(() => getCommonSchedulerConfig(extraItems), []);

  useEffect(() => getColumns(ref, tCommon, tMainTemplate, properties), [properties]);

  const renderJobs = useMemo(
    () => transformJobsToScheduler(globalLoading, jobs),
    [jobs, globalLoading]
  );

  useEffect(() => {
    // generate events and assignments to render in bryntum
    const { jobAssignments, jobEventsRender } = getAssignmentsFromJobEvents(
      jobEvents,
      jobs,
      tMainTemplate
    );
    setTimeout(() => {
      // first clear stores: assignments and events
      const assignmentStore = (ref as MutableRefObject<BryntumScheduler>).current.instance
        .assignmentStore as AssignmentStore;
      assignmentStore.removeAll();
      const eventStore = (ref as MutableRefObject<BryntumScheduler>).current.instance
        .eventStore as EventStore;
      const resourceStore = (ref as MutableRefObject<BryntumScheduler>).current.instance
        .resourceStore;
      eventStore.removeAll();
      // assign data: first events, then assignments
      if (Array.isArray(jobEventsRender)) {
        eventStore.add(jobEventsRender, true);
      }
      if (Array.isArray(jobAssignments)) {
        assignmentStore.add(jobAssignments);
      }
      resourceStore.allRecords
        .filter((record) => record.isExpanded(resourceStore))
        .forEach((record) => {
          const allAssignments = assignmentStore.getAssignmentsForResource(record as ResourceModel);
          const jobId = String(record.id);
          if (jobId.includes('_')) return;
          const newAssignments = createSecondaryAssignments(allAssignments, jobId);
          assignmentStore.add(newAssignments);
        });
    }, 0);
  }, [jobs, jobEvents]);

  useEffect(() => {
    const store = ((ref as MutableRefObject<BryntumScheduler>).current.instance.subGrids as any)
      .locked.store as Store;
    if (Array.isArray(renderJobs)) {
      store.removeAll();
      (ref as MutableRefObject<BryntumScheduler>).current.instance.eventStore.removeAll();
      store.add(renderJobs);
    }
  }, [renderJobs]);

  useEffect(() => {
    if (properties) {
      loadStateConfigBryntum(ref, SchedulerNames.JOBS);
    }
  }, [properties]);

  useEffect(() => {
    if (globalLoading) {
      setLoading(ref);
    }
  }, [globalLoading]);
  useEffect(() => {
    setLoadingTimeAxis(ref, true);
  }, []);
  useWindowUnloadEffect(() => {
    const jobScheduler = (ref as RefObject<BryntumScheduler>)?.current?.instance;
    jobScheduler && saveStateConfigBryntum(jobScheduler, SchedulerNames.JOBS);
  }, true);

  useEffect(() => {
    if (errorFirstEvent) {
      setToastNotifications([{ message: tCommon('errors.goToFirstEventError') }]);
    }
  }, [errorFirstEvent]);
  useEffect(() => {
    if (errorEventsRemover) {
      setToastNotifications([{ message: tCommon('errors.unscheduleJobError') }]);
    }
  }, [errorEventsRemover]);

  const eventDblClick = (ev: {
    source: Scheduler;
    eventRecord: EventModel;
    assignmentRecord: AssignmentModel;
    event: MouseEvent;
  }) => {
    if (!ev.eventRecord.getData('join')) {
      initialize(
        <JobEventPanelTemplate
          mode={CrudModes.EDIT}
          eventId={ev.assignmentRecord.getData('eventId')}
          resourceId={ev.assignmentRecord.getData('resourceId')}
          onSuccess={(editedEvent, oldEvent) => {
            const silent = equalResources(editedEvent, oldEvent);
            onEditEvents([editedEvent], silent);
            handleClose(false);
          }}
          onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.events_one'))}
          onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
          onLoading={(loading) => preventFromClose(loading)}
        />,
        { modalName: tCommon('entities.events_one') }
      );
    }
  };

  useEffect(() => {
    const eventStore = (ref as RefObject<BryntumScheduler>)?.current?.instance?.eventStore;
    if (eventStore) {
      if (masterDetail === 1) {
        eventStore.onAdd = (e) => {
          e.records
            .filter((record) => {
              const resource = (record as EventModel).getResource();
              const hasBeenExpanded = !resource.allChildren.some(
                (child) => child.id === record.id + TEMPORAL_CHILD_ID
              );
              return hasBeenExpanded;
            })
            .forEach((record) => {
              const jobId = record.getData('job');
              const assignmentStore = (ref as MutableRefObject<BryntumScheduler>).current.instance
                .assignmentStore;
              const allAssignments = assignmentStore.getAssignmentsForResource(
                record as ResourceModel
              );
              const newAssignments = createSecondaryAssignments(allAssignments, jobId);
              assignmentStore.add(newAssignments);
            });
          triggerScheduler(SchedulerNames.RESOURCES);
        };
      } else {
        eventStore.onAdd = (event) => {
          event.records
            .filter((record) => {
              const resource = (record as EventModel).getResource();
              const hasBeenExpanded = !resource.allChildren.some(
                (child) => child.id === record.id + TEMPORAL_CHILD_ID
              );
              return hasBeenExpanded;
            })
            .forEach((record) => {
              const jobId = record.getData('job');
              const assignmentStore = (ref as MutableRefObject<BryntumScheduler>).current.instance
                .assignmentStore;
              const allAssignments = assignmentStore.getAssignmentsForResource(
                record as ResourceModel
              );

              const newAssignments = createSecondaryAssignments(allAssignments, jobId);
              assignmentStore.add(newAssignments);
            });
        };
      }
    }
  }, [masterDetail]);

  const getStructure = async (jobId: string) => {
    try {
      const data = (await axios.get(JOBS_STATUS_DETAILS.replace(':id', jobId)))
        .data as JobDetailsDto;
      return data;
    } catch (e: any) {
      setToastNotifications([{ message: tMainTemplate('errors.loadingJobStructure') }]);
      throw new Error('errors.loadingJobStructure', e);
    }
  };

  const onExpandNode = async (record: Model) => {
    const isFirsTimeExpanded = record.firstChild.id === record.id + TEMPORAL_CHILD_ID;
    const isRootNode = record.parentId === null;

    // Avoid load structure on expand second time or expand a session row
    if (!isFirsTimeExpanded || !isRootNode) return;

    const jobId = record.id as string;
    const structure = await getStructure(jobId);
    if (!structure) return;
    const assignmentStore = (ref as MutableRefObject<BryntumScheduler>).current.instance
      .assignmentStore as AssignmentStore;

    const formattedStructure = formatStructure(structure, record);
    const firstChild = record.children[0];
    setTimeout(() => {
      record.appendChild(formattedStructure);
      record.removeChild(firstChild);
      const allAssignments = assignmentStore.getAssignmentsForResource(record as ResourceModel);

      const newAssignments = createSecondaryAssignments(allAssignments, jobId);

      assignmentStore.add(newAssignments);
    }, 0);
  };

  const { socket, online } = useSocketContext();

  useEffect(() => {
    console.log('DIOSSSSSSSSSSS', socket, online);

    if (online && socket) {
      console.log('montando socket ...');
      socket.on('scheduling.dev', (payload) => {
        console.log('scheduling.dev', payload);
      });
      socket.emit('hello', 'Hello from React!');
    }

    return () => {
      if (socket) {
        socket.off('scheduling.dev');
      }
    };
  }, [socket]);

  return (
    <ScheduleContainer>
      <Filter
        open={open}
        toggleFilter={toggleFilter}
        refs={[propertiesRef]}
        title={tCommon('filters.title', { entity: tCommon('entities.jobs_other') })}>
        <EntityFilter
          entity={Entities.JOBS}
          title={tCommon('filters.byProperties')}
          onSuccess={handleJobFilters}
          defaultValues={jobFilters}
          extraFields={getExtraFields(tCommon)}
          selected={jobSettings}
          setSelected={setJobSettings}
          propertiesRef={propertiesRef}
        />
      </Filter>
      <BryntumScheduler
        treeFeature={true}
        rowCopyPasteFeature={false}
        {...configSchedule}
        emptyText={tMainTemplate('text.emptyRows', {
          row: tCommon('entities.jobs_other')
        })}
        onBeforeToggleNode={async ({ record, collapse }) => {
          if (!collapse) onExpandNode(record);
        }}
        onBeforeDestroy={(e) => {
          const source: Scheduler = e.source as Scheduler;
          saveStateConfigBryntum(source, SchedulerNames.JOBS);
        }}
        ref={ref}
        // itemCls={'dropTargetScheduler'}
        hideHeaders={false}
        onCellDblClick={onCellDblClick}
        onEventDblClick={eventDblClick}
        createEventOnDblClick={false}
        eventEditFeature={false}
        eventMenuFeature={eventMenuFeature}
        eventResizeFeature={false}
        eventDragFeature={false}
        enableDeleteKey={false}
        zoomOnMouseWheel={true}
        minZoomLevel={ZoomLevels.MIN}
        maxZoomLevel={ZoomLevels.MAX}
        cellMenuFeature={cellMenuFeature}
        headerMenuFeature={headerMenuFeature}
        flex="1 1 0px"
        onDateRangeChange={(e) => {
          if (e.new.startDate < currentStartDate || e.new.endDate > currentEndDate) {
            setCurrentStartDate(e.new.startDate);
            setCurrentEndDate(e.new.endDate);
          }
        }}
      />
    </ScheduleContainer>
  );
};

export default forwardRef<BryntumScheduler, SchedulerJobsProps>(SchedulerJobs);
