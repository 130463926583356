import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AiFillStepForward } from 'react-icons/ai';

type Card = {
  backgroundcolor: string;
};

export const Result = styled(Paper)<Card>`
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  position: relative;
  width: 9.3rem;
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AiFillStepForwardStyled = styled(AiFillStepForward)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
