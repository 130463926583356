import { SystemFilters } from '@/const';
import { loadState, saveState } from '@/utils/localStorage';
import { PropsWithChildren, useCallback, useState } from 'react';
import FilterContext, { ValueOf, settingsType } from './FilterContext';

export type FilterContextProviderProps = PropsWithChildren<unknown>;

const FilterContextProvider = ({ children }: FilterContextProviderProps) => {
  const localSettings = loadState<settingsType>(SystemFilters.SETTINGS) || {};
  const localFullText = loadState<Record<string, string>>(SystemFilters.FULLTEXT) || {};
  const localMasterDetail = loadState<number>(SystemFilters.MASTER_DETAIL) || 0;
  const localSize = loadState<number>(SystemFilters.SIZE) || 200;

  const [settings, setSettings] = useState<settingsType>(localSettings);
  const [fullText, setFullText] = useState<Record<string, string>>(localFullText);
  const [masterDetail, setMasterDetail] = useState<number>(localMasterDetail);
  const [size, setSize] = useState<number>(localSize);
  const handleSettings = (
    key: string,
    settings: ValueOf<settingsType> | ((value?: ValueOf<settingsType>) => ValueOf<settingsType>)
  ) => {
    setSettings((before) => {
      const newSettings = {
        ...before,
        [key]: typeof settings === 'function' ? settings(before[key]) : settings
      };
      saveState<settingsType>(SystemFilters.SETTINGS, newSettings);
      return newSettings;
    });
  };

  const handleSetSize = (size: number) => {
    saveState<number>(SystemFilters.SIZE, size);
    setSize(size);
  };

  const handleFullText = (key: string, fullText: string) => {
    setFullText((before) => {
      const newFullText = {
        ...before,
        [key]: fullText
      };
      saveState<Record<string, string>>(SystemFilters.FULLTEXT, newFullText);
      return newFullText;
    });
  };

  const handleMasterDetail = (newMasterDetail: number) => {
    setMasterDetail(newMasterDetail);
    saveState<number>(SystemFilters.MASTER_DETAIL, newMasterDetail);
  };

  const getSetting = useCallback((key: string) => settings[key], [settings]);

  return (
    <FilterContext.Provider
      value={{
        fullText,
        settings,
        masterDetail,
        handleMasterDetail,
        getSetting,
        handleSettings,
        handleFullText,
        size,
        setSize: handleSetSize
      }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
