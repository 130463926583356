import { PropsWithChildren } from 'react';
import { SuccessCardContainer, SuccessCardInnerContainer } from './SuccessCard.style';

const SuccessCard = ({ color, children }: PropsWithChildren<{ color: string }>) => {
  return (
    <SuccessCardContainer sx={{ backgroundColor: color }}>
      <SuccessCardInnerContainer>{children}</SuccessCardInnerContainer>
    </SuccessCardContainer>
  );
};

export default SuccessCard;
