import { RenderIf } from '@/components/atoms';
import { BryntumHint, Loading } from '@/components/molecules';
import { EntityFilter } from '@/components/organisms';
import Filter from '@/components/organisms/Filter/Filter';
import {
  ButtonFilter,
  ButtonSettings
} from '@/components/organisms/GlobalFilter/GlobalFilter.styles';
import {
  ADVANCED_SEARCH,
  EVENTS_BY_RESOURCES,
  Entities,
  Filters,
  RESOURCE_LABEL_PROP_IDS,
  SCHEDULING_QUALIFIED_RESOURCES,
  SystemFilters,
  SystemProperties
} from '@/const';
import { useFilterContext } from '@/context/FilterContext';
import { useFetch, useGetFilteredData } from '@/hooks';
import useCustomBryntumSorter from '@/hooks/useCustomBryntumSorter';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import useWindowUnloadEffect from '@/hooks/useWindowOnLoad';
import { loadStateConfigBryntum, saveStateConfigBryntum, setLoading } from '@/services/utils/utils';
import { theme } from '@/theme';
import {
  AssignmentBryntum,
  EventBryntum,
  EventDto,
  EventTooltipFeatureData,
  FieldFilterType,
  JobDto,
  PropertyDto,
  QualifiedResourcesDto,
  RequestQualifiedResources,
  ResourceDto,
  ResourceQueryResult,
  ResourceSlot,
  SchedulerNames,
  SpecialFilterTypes,
  ZoomLevels
} from '@/types';
import {
  defaultEventMenuContextOptions,
  getEventLocation,
  getPropertyValueDef,
  goToToday,
  loadState,
  replaceTo,
  zoomIn,
  zoomOut
} from '@/utils';
import { Model, Store, StringHelper } from '@bryntum/core-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { AssignmentStore, EventStore, SchedulerPro } from '@bryntum/schedulerpro-thin';
import { Box, Grid as GridMUI, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import {
  ForwardedRef,
  MutableRefObject,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';

import { useToastContext } from '@/context/ToastContext';
import {
  getAssignmentsFromEvents,
  getColumns,
  getSchedulerConfig,
  transformResourcesToScheduler
} from './QualifiedResourcesListView.const';
import {
  BordererGridContainer,
  GridHeader,
  OuterContainer
} from './QualifiedResourcesListView.styles';

type QualifiedResourcesListViewProps = {
  readOnly: boolean;
  resourceSlot: ResourceSlot;
  qualifiedQuery: RequestQualifiedResources;
  onSelectionChange: (selectedResources: Model[]) => void;
  availableQuantity?: number;
  isValidQuantity: boolean;
};

const QualifiedResourcesListView = (
  {
    resourceSlot,
    onSelectionChange,
    readOnly,
    qualifiedQuery,
    availableQuantity,
    isValidQuantity
  }: QualifiedResourcesListViewProps,
  ref: ForwardedRef<BryntumSchedulerPro>
) => {
  const { t: tCommon } = useTranslation();
  const { t: tScheduling } = useTranslation('commons/scheduling');
  const { t: tQualified } = useTranslation('templates/qualifiedResourcesPanelTemplate');
  const propertiesRef = useRef<HTMLButtonElement | null>(null);
  const extraFields: FieldFilterType[] = useMemo(
    () => [
      {
        name: 'Type',
        _id: SystemFilters.ENTITY_TYPE,
        type: SpecialFilterTypes.CUSTOM_SET,
        order: 0,
        referencedSet: 'resourceTypes',
        value: []
      },
      {
        name: tCommon('entities.missions_other'),
        _id: SystemFilters.MISSIONS,
        type: SpecialFilterTypes.CUSTOM_SET,
        referencedSet: SystemFilters.MISSIONS,
        value: []
      },
      {
        name: tCommon('entities.work-rules_other'),
        _id: SystemFilters.WORK_RULES,
        type: SpecialFilterTypes.CUSTOM_SET,
        referencedSet: SystemFilters.WORK_RULES,
        value: []
      }
    ],
    []
  );

  const { settings: resourceSettings, setSettings: setResourceSettings } = useFilterContext(
    Entities.RESOURCES
  );

  const { properties, loading: loadingProperties } = useGetPropertiesByEntity<PropertyDto[]>(
    Entities.RESOURCES,
    undefined
  );

  const localResourceFilters: FieldFilterType[] = useMemo(
    () => loadState<FieldFilterType[]>(Filters.QUALIFIED_RESOURCES) || [],
    [properties]
  );
  const [resourceFilters, setResourceFilters] = useState<FieldFilterType[]>(localResourceFilters);

  const handleResourceFilters = (form: FieldFilterType[]) => {
    setResourceFilters(form);
    // save filter state
    // saveState(Filters.QUALIFIED_RESOURCES, form);
  };

  const { setToastNotifications } = useToastContext();

  const { loading: globalLoading, data: globalData, fetchByQualification } = useGetFilteredData();

  const {
    fetch: resourceIdsFetcher,
    data: qualifiedResources,
    loading: loadingResourceIds,
    error: errorResourceIds
  } = useFetch<QualifiedResourcesDto>();

  useEffect(() => {
    if (errorResourceIds) {
      setToastNotifications([{ message: t('errors.resourceIds') }]);
    }
  }, [errorResourceIds]);

  useEffect(() => {
    if (qualifiedQuery.documents || qualifiedQuery.location) {
      resourceIdsFetcher({
        url: SCHEDULING_QUALIFIED_RESOURCES,
        method: 'POST',
        data: qualifiedQuery
      });
    }
  }, []);

  const newSorter = useCustomBryntumSorter({
    gridRef: ref as MutableRefObject<BryntumSchedulerPro>,
    refName: SchedulerNames.QUALIFIED_RESOURCES
  });

  const fetchData = useCallback(() => {
    fetchByQualification(resourceFilters, qualifiedResources, newSorter);
  }, [resourceFilters, newSorter, qualifiedResources]);

  useEffect(() => {
    properties && !loadingResourceIds && fetchData();
  }, [properties, resourceFilters, newSorter, qualifiedResources]);

  useEffect(() => {
    const gridRef = ref as RefObject<BryntumSchedulerPro>;
    const gridRefInstance = gridRef.current?.instance;
    if (gridRefInstance) {
      if (gridRef && gridRef?.current && gridRef.current.element) {
        gridRef.current.element.onkeydown = (ev) => {
          if (ev.ctrlKey && ev.key == 'a') {
            gridRefInstance.selectAll();
            ev.stopPropagation();
            ev.preventDefault();
          }
        };
      }
    }
  }, []);

  const resources = useMemo(() => {
    if (globalData) {
      const columnStore: ColumnStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance
        .columns as ColumnStore;
      if (columnStore && columnStore.getById('counter')) {
        columnStore.getById('counter').text = renderToString(
          <GridMUI display={'flex'} flexWrap="wrap">
            {tCommon('text.count')}
            <Typography>{tCommon('number', { value: globalData.count ?? 0 })}</Typography>
          </GridMUI>
        );
        return globalData?.resources;
      }
    }
  }, [globalData]);

  const [currentStartDate, setCurrentStartDate] = useState<Date>(
    DateTime.now().startOf('month').minus({ months: 1 }).toJSDate()
  );
  const [currentEndDate, setCurrentEndDate] = useState<Date>(
    DateTime.now().startOf('month').plus({ months: 1 }).endOf('month').toJSDate()
  );

  const filterDataResourcesEvents = useMemo(() => {
    if (resources) {
      return {
        url: replaceTo(
          replaceTo(EVENTS_BY_RESOURCES, ':startDate', currentStartDate.toISOString()),
          ':endDate',
          currentEndDate.toISOString()
        ),
        method: 'POST',
        data: resources.map(({ _id }) => _id)
      };
    }
  }, [resources, currentStartDate, currentEndDate]);

  const { data: resourcesEvents, error: errorResourcesEvents } =
    useFetch<EventDto[]>(filterDataResourcesEvents);

  useEffect(() => {
    if (errorResourcesEvents) {
      setToastNotifications([{ message: t('errors.resourcesEvents') }]);
    }
  }, [errorResourcesEvents]);

  const { fetch: fetchJobForHint, error: errorJobHint } = useFetch<any>();
  const { fetch: fetchResourcesForHint, error: errorResourcesForHint } =
    useFetch<ResourceQueryResult>();

  useEffect(() => {
    if (errorJobHint || errorResourcesForHint) {
      setToastNotifications([{ message: t('errors.loadingHint') }]);
    }
  }, [errorJobHint, errorResourcesForHint]);

  const extraItems = {
    eventMenuFeature: false,
    scheduleMenuFeature: false,
    eventTooltipFeature: {
      onBeforeShow({ source: tooltip }) {
        tooltip.html = renderToString(<Loading />);
        tooltip.title = StringHelper.encodeHtml(tooltip.eventRecord.name);
      },
      template: async ({
        eventRecord: { originalData }
      }: EventTooltipFeatureData<EventBryntum<EventDto>>) => {
        let jobs: JobDto[] | undefined;
        let resources: Record<string, ResourceDto> = {};
        const fill: ResourceDto['_id'][] = [];
        if (originalData.job) {
          jobs = (
            await fetchJobForHint({
              url: ADVANCED_SEARCH.replace(':entity', 'jobs'),
              method: 'POST',
              data: {
                jobIds: originalData.job
              }
            })
          ).jobs as JobDto[];
        }

        originalData.resources.forEach(({ resource }) => {
          fill.push(resource);
        });

        if (fill.length > 0) {
          const result = (await fetchResourcesForHint({
            url: ADVANCED_SEARCH.replace(':entity', Entities.RESOURCES),
            method: 'POST',
            data: { resourceIds: fill }
          })) as ResourceQueryResult;

          const newResult = result.resources.reduce<Record<string, ResourceDto>>(
            (acc, curr) => ({
              ...acc,
              [curr._id]: curr
            }),
            {}
          );
          resources = { ...resources, ...newResult };
        }

        const resourceProperties = originalData.resources.reduce<
          Record<string, Record<string, string>[]>
        >((acc, { roleName, resource }) => {
          const resourceWithProperties = { _id: resource };
          resources[resource]?.properties
            .filter((prop) => RESOURCE_LABEL_PROP_IDS.has(prop._id))
            .forEach((prop) => {
              resourceWithProperties[prop._id] = prop.value;
            });
          return {
            ...acc,
            [roleName]:
              roleName in acc
                ? [...acc[roleName], resourceWithProperties]
                : [resourceWithProperties]
          };
        }, {});

        return renderToString(
          <BryntumHint
            startDate={originalData.startDate}
            endDate={originalData.endDate}
            location={getEventLocation(originalData) as string}
            job={
              jobs?.map((job) =>
                getPropertyValueDef(job.properties, SystemProperties.JOBS_NAME, '')
              ) ?? []
            }
            resources={Object.keys(resourceProperties).map((roleName) => (
              <Typography key={roleName} variant="body1" className="b-resource-title">
                {roleName}
                {resourceProperties[roleName].map((resource) => (
                  <div key={resource._id} className="b-hint-resource">
                    <GridMUI
                      sx={{
                        width: '24px',
                        height: '24px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      <FaUserCircle color={theme.palette.grey[400]} size={24} />
                    </GridMUI>
                    <Typography component="div" variant="body2">
                      {`${
                        resource[SystemProperties.RESOURCES_FIRST_NAME]
                          ? `${resource[SystemProperties.RESOURCES_FIRST_NAME]} `
                          : ''
                      }${resource[SystemProperties.RESOURCES_NAME] ?? tCommon('unknown')} (${
                        resource[SystemProperties.RESOURCES_ID] ?? tCommon('unknown')
                      })`}
                    </Typography>
                  </div>
                ))}
              </Typography>
            ))}
          />
        );
      }
    }
  };

  const expandJoinedEvents = (event: EventBryntum, assignment: AssignmentBryntum) => {
    if (event.join && assignment.joinedAssignments) {
      const assignmentStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance
        .assignmentStore;
      if (assignmentStore) {
        assignmentStore.remove(assignment.id);
        assignmentStore.add(assignment.joinedAssignments);
      }
    }
  };

  const collapseJoinedEvents = (event: EventBryntum, resourceId: string) => {
    if (event.join) {
      const eventStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance.eventStore;
      if (eventStore) {
        const joinedEvent = eventStore.getById(event.join) as any;
        const joinedAssignment = joinedEvent?.data.joinedAssignments.get(resourceId);
        if (joinedAssignment) {
          const assignmentStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance
            .assignmentStore;
          if (assignmentStore) {
            assignmentStore.remove(joinedAssignment.joinedAssignments.map((ja) => ja.id));
            assignmentStore.add(joinedAssignment);
          }
        }
      }
    }
  };

  const eventMenuFeature = useMemo(
    () => ({
      processItems({ items, eventRecord, assignmentRecord }) {
        defaultEventMenuContextOptions(items);

        if (eventRecord.data.join && assignmentRecord.data.joined) {
          if (assignmentRecord.data.showAsJoined) {
            items.customExpandJoined = {
              text: tScheduling('actions.expandJoined'),
              icon: 'b-icon b-fa-angles-down',
              disabled: globalLoading || loadingProperties,
              cls: 'b-separator',
              onItem: async (ev) => {
                expandJoinedEvents(ev.eventRecord.data, ev.assignmentRecord.data);
              }
            };
          } else {
            items.customCollapseJoined = {
              text: tScheduling('actions.collapseJoined'),
              icon: 'b-icon b-fa-angles-up',
              disabled: globalLoading || loadingProperties,
              cls: 'b-separator',
              onItem: async (ev) => {
                collapseJoinedEvents(ev.eventRecord.data, ev.assignmentRecord.data.resourceId);
              }
            };
          }
        }
      }
    }),
    [globalLoading, loadingProperties]
  );

  const headerMenuFeature = useMemo(() => {
    return {
      processItems({ items }) {
        if (!globalLoading && !loadingProperties) {
          items.groupAsc = false;
          items.groupDesc = false;
        } else {
          items.columnPicker = false;
          items.groupAsc = false;
          items.groupDesc = false;
          items.hideColumn = false;
          items.multiSort = false;
          items.sortAsc = false;
          items.sortDesc = false;
        }
      }
    };
  }, [globalLoading, loadingProperties]);

  const configSchedule = useMemo(() => {
    return getSchedulerConfig(extraItems);
  }, []);

  useEffect(() => getColumns(ref, tCommon, properties), [properties]);

  const renderResources = useMemo(
    () => transformResourcesToScheduler(globalLoading, resources),
    [resources]
  );

  useEffect(() => {
    const store = ((ref as MutableRefObject<BryntumSchedulerPro>).current.instance.subGrids as any)
      .locked.store as Store;
    if (Array.isArray(renderResources)) {
      store.removeAll();
      store.data = renderResources;
    }
  }, [renderResources]);

  useEffect(() => {
    // generate events and assignments to render in bryntum
    const { resourceEventsRender, resourceAssignments } = getAssignmentsFromEvents(
      resourcesEvents,
      resources,
      tScheduling
    );
    // first clear stores: assignments and events
    const assignmentStore = (ref as MutableRefObject<BryntumSchedulerPro>).current.instance
      .assignmentStore as AssignmentStore;
    assignmentStore.removeAll();
    const eventStore = (ref as MutableRefObject<BryntumSchedulerPro>).current.instance
      .eventStore as EventStore;
    eventStore.removeAll();
    // assign data: first events, then assignments
    if (Array.isArray(resourceEventsRender)) {
      eventStore.data = resourceEventsRender;
    }
    if (Array.isArray(resourceAssignments)) {
      assignmentStore.data = resourceAssignments;
    }
  }, [resources, resourcesEvents]);

  useEffect(() => {
    if (properties) {
      loadStateConfigBryntum(ref, SchedulerNames.QUALIFIED_RESOURCES);
    }
  }, [properties]);

  useEffect(() => {
    globalLoading && setLoading(ref);
  }, [globalLoading]);

  useWindowUnloadEffect(() => {
    const resourceScheduler = (ref as RefObject<BryntumSchedulerPro>)?.current?.instance;
    resourceScheduler &&
      saveStateConfigBryntum(resourceScheduler, SchedulerNames.QUALIFIED_RESOURCES);
  }, true);

  const activeFilters = useMemo(() => resourceFilters.length > 0, [resourceFilters]);
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <>
      <OuterContainer>
        <GridHeader>
          <Box display="flex" gap="0.5rem" alignItems="center">
            <Tooltip
              title={`${tCommon('filters.title', {
                entity: tCommon('entities.resources_other')
              })}`}>
              <ButtonFilter
                letter=""
                $isactive={activeFilters}
                onClick={(e) => {
                  setOpenFilter((before) => !before);
                }}>
                <i className="b-icon b-icon-filter" />
              </ButtonFilter>
            </Tooltip>
          </Box>

          <RenderIf
            condition={
              resourceSlot.restrictLocation && readOnly && availableQuantity === undefined
            }>
            <Typography variant="body2" paddingY={2} lineHeight={1}>
              {tQualified('noteRestrictLocation')}
            </Typography>
          </RenderIf>
          <RenderIf condition={availableQuantity !== undefined}>
            <Typography
              color={isValidQuantity || readOnly ? 'default' : 'error'}
              variant="body2"
              paddingY={2}
              lineHeight={1}>
              {tQualified('availableQuantity', {
                name: resourceSlot.name,
                quantity: availableQuantity
              })}
            </Typography>
          </RenderIf>
          <Box display="flex" gap="0.5rem" alignItems="baseline">
            <Tooltip title={tScheduling('actions.goToToday')}>
              <ButtonSettings onClick={() => goToToday(ref)}>
                <i className="b-icon b-fa-calendar-day" />
              </ButtonSettings>
            </Tooltip>
            <Tooltip title={tCommon('actions.zoomOut')}>
              <ButtonSettings onClick={() => zoomOut(ref)}>
                <i className="b-icon b-icon-search-minus" />
              </ButtonSettings>
            </Tooltip>
            <Tooltip title={tCommon('actions.zoomIn')}>
              <ButtonSettings onClick={() => zoomIn(ref)}>
                <i className="b-icon b-icon-search-plus" />
              </ButtonSettings>
            </Tooltip>
          </Box>
        </GridHeader>
        <BordererGridContainer>
          <BryntumSchedulerPro
            rowCopyPasteFeature={false}
            {...configSchedule}
            cls={'b-custom-top-border'}
            emptyText={tCommon('text.emptyRows', {
              row: tCommon('entities.resources_other')
            })}
            onBeforeDestroy={(e) => {
              const source: SchedulerPro = e.source as SchedulerPro;
              saveStateConfigBryntum(source, SchedulerNames.QUALIFIED_RESOURCES);
            }}
            onSelectionChange={(e) => onSelectionChange(e.selection)}
            ref={ref}
            hideHeaders={false}
            createEventOnDblClick={false}
            eventEditFeature={false}
            eventMenuFeature={eventMenuFeature}
            eventResizeFeature={false}
            eventDragFeature={false}
            enableDeleteKey={false}
            headerMenuFeature={headerMenuFeature}
            flex="1 1 0px"
            minHeight="12em"
            zoomOnMouseWheel={true}
            minZoomLevel={ZoomLevels.MIN}
            maxZoomLevel={ZoomLevels.MAX}
            onDateRangeChange={(e) => {
              if (e.new.startDate < currentStartDate || e.new.endDate > currentEndDate) {
                setCurrentStartDate(e.new.startDate);
                setCurrentEndDate(e.new.endDate);
              }
            }}
          />
        </BordererGridContainer>
      </OuterContainer>
      <Filter
        open={openFilter}
        toggleFilter={() => setOpenFilter((before) => !before)}
        refs={[propertiesRef]}
        title={tCommon('filters.title', { entity: tCommon('entities.resources_other') })}>
        <EntityFilter
          entity={Entities.RESOURCES}
          title={tCommon('filters.byProperties')}
          onSuccess={handleResourceFilters}
          defaultValues={resourceFilters}
          extraFields={extraFields}
          selected={resourceSettings}
          setSelected={setResourceSettings}
          propertiesRef={propertiesRef}
        />
      </Filter>
    </>
  );
};

export default forwardRef<BryntumSchedulerPro, QualifiedResourcesListViewProps>(
  QualifiedResourcesListView
);

// export default QualifiedResourcesListView;
