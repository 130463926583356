import { RenderIf } from '@/components/atoms';
import { Autocomplete } from '@/components/molecules/Autocomplete/SelectFilter/SelectFilter.styles';
import { AccordionSummary } from '@/components/molecules/Card/Card.styles';
import CardSkeleton from '@/components/molecules/CardSkeleton/CardSkeleton';
import { Container } from '@/components/organisms/FilterByRecord/FilterByRecord.styles';
import { QualifiedResourcesPanelTemplate } from '@/components/templates';
import { AutoCompleteField, CrudModes, SETS_FIND_BY_NAME } from '@/const';
import { SystemSets } from '@/const/sets';
import { SelectedTopic, useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useGet } from '@/hooks';
import { useConfirmDeletion } from '@/hooks/useConfirmDeletion';
import { TextField, theme } from '@/theme';
import {
  ContinuityClasses,
  CurriculumForm,
  GeneralContinuity,
  MultipleInputValue,
  ResourceSlot,
  SetDto,
  TopicResourceSlot,
  Type
} from '@/types';
import { ContainerItemConfig, Menu } from '@bryntum/core-thin';
import { Box, Button, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ContinuityPanel from '../../../Continuities/ContinuityPanel/ContinuityPanel';
import { ResourceSlotsPanel } from '../../../common/ResourceSlotPanel';
import { TopicResourceSlotCard } from '../TopicResourceSlot';

const FormTopicSlots = () => {
  const listRef = useRef<HTMLDivElement>(null);
  const { initialize, handleClose, handleDirtyModal } = useGenericModalContext();
  const { readOnly, selectedTopics, getUniqueKey, setCurrentTab, setSelectedContinuities } =
    useCurriculumSetupContext();
  const { t: tResourceSlot } = useTranslation('organisms/resourceSlot');
  const { t: tCommon } = useTranslation('common');
  const { t: tTopic } = useTranslation('organisms/topic');
  const { t: tCurrTemplate } = useTranslation('templates/curriculumsTemplate');
  const { handleConfirmation } = useConfirmDeletion({
    confirmButtonText: tCommon('actions.remove')
  });

  const isMultiple = useMemo(() => selectedTopics.length > 1, [selectedTopics]);
  const [selected, setSelected] = useState<TopicResourceSlot>({
    id: '',
    joinable: false
  });

  const { data: roles, loading } = useGet<SetDto>({
    url: SETS_FIND_BY_NAME.replace(':setName', SystemSets.Roles),
    method: 'GET'
  });

  const getResourceSlotName = (resourceSlotId: string) => {
    const resourceSlot = getValues(`structure.resourceSlots`)?.find(
      (resourceSlot) => resourceSlot.id === resourceSlotId
    );
    if (!resourceSlot) throw new Error(`Resource slot with id ${resourceSlotId} not found`);
    return resourceSlot.name;
  };

  const { control, getValues, setValue } = useFormContext<CurriculumForm>();

  const { prepend } = useFieldArray({
    control,
    name: 'structure.continuities'
  });

  const findCommonResourceSlots = (allResourceSlots: Array<TopicResourceSlot[]>) => {
    if (allResourceSlots.length == 1)
      return allResourceSlots[0].map((el) => ({ ...el, multiple: false })) as (TopicResourceSlot & {
        multiple?: boolean;
      })[];
    const ids: Record<string, TopicResourceSlot[]> = {};
    const commonResourceSlots: (TopicResourceSlot & { multiple?: boolean })[] = [];
    allResourceSlots.forEach((resourceSlots) => {
      resourceSlots.forEach((resourceSlot) => {
        const id = resourceSlot.id;
        if (!ids[id]) {
          ids[id] = [resourceSlot];
        } else {
          ids[id].push(resourceSlot);
          if (ids[id].length === allResourceSlots.length) {
            commonResourceSlots.push(resourceSlot);
          }
        }
      });
    });
    commonResourceSlots.forEach((commonResourceSlot) => {
      const id = commonResourceSlot.id;
      const differentValues = new Set([...ids[id].map((resourceSlot) => resourceSlot.joinable)]);
      if (differentValues.size === 1) {
        commonResourceSlot.multiple = false;
      } else commonResourceSlot.multiple = true;
      return commonResourceSlots;
    });
    return commonResourceSlots;
  };

  const allTopicSlots = useMemo(() => {
    const allSlots: Array<TopicResourceSlot[]> = [];
    const sessions = getValues(`structure.sessions`);
    for (const slctedTopic of selectedTopics) {
      const topic = sessions[slctedTopic.sourceSession.index].topics[slctedTopic.index];
      allSlots.push(topic?.resourceSlots || []);
    }
    return allSlots;
  }, [selectedTopics, selected, setSelected]);

  const commonSlots = useMemo(
    () =>
      findCommonResourceSlots(allTopicSlots).sort((a, b) =>
        getResourceSlotName(a.id).localeCompare(getResourceSlotName(b.id))
      ),
    [allTopicSlots]
  );

  const updateMultiple = (topicSlot: TopicResourceSlot) => {
    const id = topicSlot.id;
    const sessions = getValues(`structure.sessions`);
    for (const slctedTopic of selectedTopics) {
      const topic = sessions[slctedTopic.sourceSession.index].topics[slctedTopic.index];
      const resourceSlotIndex = topic?.resourceSlots.findIndex((rs) => rs.id === id);
      const resourceSlots = topic?.resourceSlots || [];
      if (resourceSlotIndex === -1) {
        setValue(
          `structure.sessions.${slctedTopic.sourceSession.index}.topics.${slctedTopic.index}.resourceSlots`,
          [...resourceSlots, { ...topicSlot }],
          { shouldDirty: true }
        );
      }
    }
  };
  const reset = () => {
    setSelected({ id: '', joinable: selected.joinable });
  };

  const resourceSlots = useWatch({
    control,
    name: 'structure.resourceSlots',
    defaultValue: []
  });

  const updateTopicSlot = useCallback(
    (topicSlot: TopicResourceSlot) => {
      const sessions = getValues(`structure.sessions`);
      for (const slctedTopic of selectedTopics) {
        const topic = sessions[slctedTopic.sourceSession.index].topics[slctedTopic.index];
        const resourceSlotIndex = topic?.resourceSlots.findIndex((rs) => rs.id === topicSlot.id);
        if (resourceSlotIndex !== -1) {
          const currentValue = getValues(
            `structure.sessions.${slctedTopic.sourceSession.index}.topics.${slctedTopic.index}.resourceSlots.${resourceSlotIndex}`
          );
          setValue(
            `structure.sessions.${slctedTopic.sourceSession.index}.topics.${slctedTopic.index}.resourceSlots.${resourceSlotIndex}`,
            { ...currentValue, ...topicSlot },
            { shouldDirty: true }
          );
          reset();
        }
      }
    },
    [selectedTopics]
  );

  const getValueForMultiple = useCallback(
    <K extends keyof TopicResourceSlot>(name: K, id: string) => {
      const values: Array<TopicResourceSlot[typeof name]> = [];
      const sessions = getValues(`structure.sessions`);
      for (const slctedTopic of selectedTopics) {
        const topic = sessions[slctedTopic.sourceSession.index].topics[slctedTopic.index];
        const resourceSlot = topic?.resourceSlots.find((rs) => rs.id === id);
        if (resourceSlot) values.push(resourceSlot[name]);
      }
      const uniqueValues = [...new Set(values)];
      if (uniqueValues.length === 1) {
        return uniqueValues[0];
      }
      return { multiple: true } as MultipleInputValue;
    },
    [selectedTopics]
  );

  const handleCreateResourceSlot = () => {
    const newResourceSlotId = 'RS' + getUniqueKey().toString();
    initialize(
      <ResourceSlotsPanel
        mode={CrudModes.CREATE}
        isMultiple={selectedTopics.length > 0}
        topicSlot={{ id: newResourceSlotId } as TopicResourceSlot}
        resourceSlot={{ id: newResourceSlotId } as ResourceSlot}
        onCancel={(isDirty) => handleClose(isDirty, tCurrTemplate('elements.resourceSlots_one'))}
        onDirtyFields={(isDirty) =>
          handleDirtyModal(isDirty, tCurrTemplate('elements.resourceSlots_one'))
        }
        onSuccess={(resourceSlot, topicSlot) => {
          createResourceSlot(resourceSlot);
          topicSlot && updateMultiple(topicSlot);
          reset();
          handleClose(false);
        }}
      />,
      { modalName: tCurrTemplate('elements.resourceSlots_one') }
    );
  };

  const launchResourceSlotPanel = (mode: CrudModes, topicSlot: TopicResourceSlot) => {
    if (!resourceSlots) throw new Error('ResourceSlots not found');
    const resourceSlotIndex = resourceSlots?.findIndex((rs) => rs.id === topicSlot.id);
    if (resourceSlotIndex === -1) throw new Error('ResourceSlot not found');

    const resourceSlot = resourceSlots[resourceSlotIndex];

    initialize(
      <ResourceSlotsPanel
        mode={mode}
        isMultiple={selectedTopics.length > 1}
        resourceSlot={resourceSlot}
        topicSlot={{
          ...topicSlot,
          joinable: getValueForMultiple('joinable', topicSlot.id),
          contentId: getValueForMultiple('contentId', topicSlot.id)
        }}
        onCancel={(isDirty) => handleClose(isDirty, tCurrTemplate('elements.resourceSlots_one'))}
        onDirtyFields={(isDirty) =>
          handleDirtyModal(isDirty, tCurrTemplate('elements.resourceSlots_one'))
        }
        onSuccess={(resourceSlot, topicSlot) => {
          updateResourceSlot(resourceSlot, resourceSlotIndex);
          if (topicSlot) updateTopicSlot(topicSlot);

          handleClose(false);
        }}
      />,
      { modalName: tCurrTemplate('elements.resourceSlots_one') }
    );
  };

  const createResourceSlot = (resourceSlot: ResourceSlot) => {
    if (resourceSlots)
      setValue(`structure.resourceSlots`, [resourceSlot, ...resourceSlots], { shouldDirty: true });
    else setValue(`structure.resourceSlots`, [resourceSlot], { shouldDirty: true });
  };
  const updateResourceSlot = (resourceSlot: ResourceSlot, resourceSlotIndex: number) => {
    setValue(`structure.resourceSlots.${resourceSlotIndex}`, resourceSlot, { shouldDirty: true });
  };
  const validateContinuities = (selectedTopics: SelectedTopic[], resourceSlotId: string) => {
    const continuities = getValues('structure.continuities') || [];
    const validContinuities = continuities.reduce(
      (acc, continuity) => {
        if (continuity.continuityClass !== ContinuityClasses.GENERAL) return [...acc, continuity];
        const topicResourceSlotIds = (continuity as GeneralContinuity).topicResourceSlotIds;
        selectedTopics.forEach((slctedTopic) => {
          if (topicResourceSlotIds[slctedTopic.id] === resourceSlotId) {
            delete topicResourceSlotIds[slctedTopic.id];
          }
        });
        if (Object.keys(topicResourceSlotIds).length >= 2) {
          return [...acc, continuity];
        } else {
          return acc;
        }
      },
      [] as typeof continuities
    );
    return {
      validContinuities,
      invalidContinuities: continuities.length - validContinuities.length
    };
  };

  const removeMultiple = async (id: string) => {
    const { validContinuities, invalidContinuities } = validateContinuities(selectedTopics, id);

    const body = tCurrTemplate('resourceSlotsMessages.remove', { count: selectedTopics.length });

    const note =
      invalidContinuities > 0
        ? tCommon('modalConfirmationDelete.warningDeletion', {
            counter: invalidContinuities,
            name: tCurrTemplate('elements.continuities', { count: invalidContinuities })
          })
        : '';
    const result = await handleConfirmation(body, note);
    if (!result) return;

    const sessions = getValues(`structure.sessions`);

    for (const slctedTopic of selectedTopics) {
      const topic = sessions[slctedTopic.sourceSession.index].topics[slctedTopic.index];
      const resourceSlots = topic?.resourceSlots || [];

      setValue(
        `structure.sessions.${slctedTopic.sourceSession.index}.topics.${slctedTopic.index}.resourceSlots`,
        resourceSlots.filter((rs) => rs.id !== id),
        { shouldDirty: true }
      );
    }
    setValue('structure.continuities', validContinuities, { shouldDirty: true });

    reset();
  };

  const createMenu = new Menu({
    autoShow: false,
    items: [
      {
        disabled: readOnly,
        text: `${tCommon('actions.createAndAdd', {
          element: tResourceSlot('resourceSlots_one')
        })}`,
        icon: 'b-icon b-fa-circle-plus',
        onItem: (event) => {
          handleCreateResourceSlot();
        }
      } as Partial<ContainerItemConfig>
    ]
  });
  const menu = new Menu({
    autoShow: false,
    items: [
      {
        disabled: readOnly,
        text: `${tCommon('actions.createAndAdd', {
          element: tResourceSlot('resourceSlots_one')
        })}`,
        icon: 'b-icon b-fa-circle-plus',
        onItem: (event) => {
          handleCreateResourceSlot();
        }
      },
      {
        hidden: readOnly,
        text: `${tCommon('actions.editElement', {
          element: tResourceSlot('resourceSlots_one')
        })} `,
        icon: 'b-icon b-fa-pen-to-square',
        onItem: (event) => {
          const { referencedResourceSlot } = event.menu;
          launchResourceSlotPanel(CrudModes.EDIT, referencedResourceSlot);
        }
      },
      {
        hidden: !readOnly,
        text: `${tCommon('actions.readElement', {
          element: tResourceSlot('resourceSlots_one')
        })}`,
        icon: 'b-icon b-fa-eye',
        onItem: (event) => {
          const { referencedResourceSlot } = event.menu;
          launchResourceSlotPanel(CrudModes.READ, referencedResourceSlot);
        }
      },
      {
        disabled: readOnly,
        text: `${tCommon('actions.removeElement', {
          element: tResourceSlot('resourceSlots_one')
        })}`,
        icon: 'b-icon b-fa-trash-can',
        onItem: (event) => {
          const { referencedResourceSlot } = event.menu;
          removeMultiple(referencedResourceSlot.id);
        }
      },
      {
        hidden: !isMultiple,
        disabled: readOnly,
        text: `${tCommon('actions.createElement', {
          element: tCurrTemplate('elements.continuities_one')
        })} `,
        icon: 'b-icon b-fa-link',
        onItem: (event) => {
          const resourceSlotId = event.menu.referencedResourceSlot.id;
          const resourceSlotName = getResourceSlotName(resourceSlotId) || '';
          const topicResourceSlotIds = selectedTopics.reduce(
            (acc, topic) => ({
              ...acc,
              [topic.id]: resourceSlotId
            }),
            {} as Record<string, string>
          );

          initialize(
            <ContinuityPanel<GeneralContinuity>
              mode={CrudModes.CREATE}
              continuityClass={ContinuityClasses.GENERAL}
              resourceSlots={getResourceSlots()}
              sessions={getValues('structure.sessions')}
              values={{
                id: 'GC' + getUniqueKey().toString(),
                name: resourceSlotName,
                topicResourceSlotIds,
                type: Type.R,
                continuityClass: ContinuityClasses.GENERAL
              }}
              onCancel={(isDirty) =>
                handleClose(isDirty, tCurrTemplate('elements.continuities_one'))
              }
              onDirtyFields={(isDirty) =>
                handleDirtyModal(isDirty, tCurrTemplate('elements.continuities_one'))
              }
              onSuccess={(continuity) => {
                prepend(continuity);
                setSelectedContinuities([continuity.id]);
                setCurrentTab('3');
                handleClose(false);
              }}
            />,
            { modalName: tCurrTemplate('elements.continuities_one') }
          );
        }
      },
      {
        text: tCurrTemplate('qualifiedResources'),
        icon: 'b-icon b-fa-file-circle-check',
        cls: 'b-separator',
        onItem: async (ev) => {
          const { referencedResourceSlot } = ev.menu;
          const resourceSlot = getValues('structure.resourceSlots')?.find(
            (rs) => rs.id === referencedResourceSlot.id
          );
          if (!resourceSlot) throw new Error('Resource slot not found');
          const documentIds = resourceSlot.documents?.map((d) => d._id);
          if (!documentIds) throw new Error('At least one document is required');
          initialize(
            <QualifiedResourcesPanelTemplate
              qualifiedQuery={{
                documents: documentIds,
                date: DateTime.local().toISODate() || ''
              }}
              onClose={() => handleClose(false)}
              onSuccess={() => handleClose(false)}
              resourceSlot={resourceSlot}
              readOnly={true}
            />,
            {
              style: {
                height: '90vh',
                width: '90vw',
                minWidth: '28rem',
                minHeight: '26rem',
                maxWidth: '90vw',
                maxHeight: '90rem',
                borderRadius: '16px'
              }
            }
          );
        }
      } as Partial<ContainerItemConfig>
    ] as Partial<ContainerItemConfig>[]
  });

  const getResourceSlots = () => {
    return getValues('structure.resourceSlots') || [];
  };
  const atLeastOneDocument = (resourceSlotId: string) => {
    const resourceSlot = resourceSlots?.find((rs) => rs.id === resourceSlotId);
    if (!resourceSlot || !resourceSlot.documents) return false;

    return resourceSlot.documents.length > 0;
  };

  return (
    <Box>
      <AccordionSummary
        sx={{
          marginBlock: '0.5rem 1.2rem !important',
          paddingLeft: '1rem !important',
          cursor: 'auto !important',
          minHeight: '2.5rem !important',
          margin: '0! important'
        }}>
        <Typography>
          {tTopic('topicSlotsWithCount', {
            count: selectedTopics.length,
            quantity: commonSlots.length
          })}
        </Typography>
      </AccordionSummary>
      <Box display="flex" flexDirection="column" gap="1rem" p="1rem" pb="0.5rem">
        <Box display="flex" alignItems="center" width="100%" gap="0.5rem">
          <Autocomplete
            disabled={readOnly}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: AutoCompleteField
                }}
                name={`resourceSlot_name`}
                variant="outlined"
                size="small"
                sx={{ flexGrow: '1' }}
              />
            )}
            noOptionsText={tResourceSlot('emptyList')}
            getOptionLabel={(option) => option.name}
            options={getResourceSlots().filter((slot) => !commonSlots.some((f) => f.id == slot.id))}
            value={getResourceSlots().find((el) => el.id == selected.id) || null}
            onChange={(_, newValue) => {
              if (newValue) setSelected((current) => ({ ...current, id: newValue?.id }));
              else setSelected({ id: '', joinable: false });
            }}
            sx={{ flexGrow: '1', marginTop: 0 }}
          />

          <Button
            disabled={readOnly || !selected.id}
            variant="contained"
            onClick={() => {
              updateMultiple(selected);
              reset();
            }}>
            {tCommon('buttons.add')}
          </Button>
        </Box>
        <Container
          onContextMenu={(e) => {
            e.preventDefault();
            createMenu.showBy([e.clientX, e.clientY]);
          }}
          ref={listRef}>
          <RenderIf condition={loading}>
            <CardSkeleton />
          </RenderIf>

          <RenderIf condition={!loading}>
            {commonSlots?.map((referencedResourceSlot, index) => {
              const resourceSlot = getValues('structure.resourceSlots')?.find(
                (slot) => slot.id === referencedResourceSlot.id
              );
              if (!resourceSlot) return null;
              return (
                <TopicResourceSlotCard
                  key={resourceSlot.id}
                  resourceSlot={resourceSlot}
                  multiple={referencedResourceSlot.multiple}
                  //status should be a third option when isMultiple and there are differente values
                  status={referencedResourceSlot.joinable ?? false}
                  roles={roles?.items || []}
                  onContextMenu={(e) => {
                    menu.items[3].text = `${tCommon('actions.createElement', {
                      element: tCurrTemplate('elements.continuities_one')
                    })} `;
                    menu.items[4].disabled = !atLeastOneDocument(resourceSlot.id);
                    menu.setConfig({ referencedResourceSlot });
                    menu.showBy([e.clientX, e.clientY]);
                  }}
                  onEdit={() =>
                    launchResourceSlotPanel(
                      readOnly ? CrudModes.READ : CrudModes.EDIT,
                      referencedResourceSlot
                    )
                  }
                  onRemove={() => removeMultiple(referencedResourceSlot.id)}
                />
              );
            })}
          </RenderIf>
          <RenderIf condition={commonSlots?.length === 0}>
            <Typography sx={{ color: theme.palette.grey[500] }} mr="auto">
              {tResourceSlot('noResourceSlotsAdded')}
            </Typography>
          </RenderIf>
        </Container>
      </Box>
    </Box>
  );
};

export default FormTopicSlots;
