import { theme } from '@/theme';
import { styled } from '@mui/material';

export const DropContainer = styled('div')<{ isOver: boolean; multiple: boolean }>`
  position: relative;
  width: 33rem;
  height: 15rem;
  border: 0.2rem dashed ${theme.palette.grey[600]};
  border-radius: 0.5rem;
  background-color: ${(props) =>
    props.isOver ? theme.palette.divider : theme.palette.background.paper};
  cursor: pointer;
  ${(props) => {
    if (!props.multiple) {
      return `
        display:flex;
        align-items:center;
        justify-content:center;

        `;
    } else {
      return `display: flex;
  flex-flow: row wrap;
  overflow: auto;
  padding: 1rem;
  gap: 1rem;`;
    }
  }}
`;
