import { RenderIf } from '@/components/atoms';
import { FormHelperTextError } from '@/components/molecules/Card/Card.styles';
import {
  ActionTypes,
  DEFAULT_ID,
  ScaleConstraints,
  SelectedTopic,
  useCurriculumSetupContext
} from '@/context/CurriculmSetupContext';
import { useWindowSize } from '@/hooks';
import { theme } from '@/theme';
import { CurriculumForm, TimeStrategy, Topic } from '@/types';
import { calculateNewHour } from '@/utils';
import { Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { useDebouncyEffect } from 'use-debouncy';
import { DragNDropComponents, TabOptions, TimeInterval } from '../../../CurriculumSetup.const';
import { TopicCard } from '../../TopicCard';
import { formatDate } from '../SessionWrapper.const';
import { ScrollButton } from '../SessionWrapper.styles';
import { OuterContainer, SessionContainer } from './TimeSessionRow.styles';

export type TimeSessionRowProps = {
  index: number;
  sessionId: string;
  scale: number;
};

const TimeSessionRow = ({ index, sessionId, scale }: TimeSessionRowProps) => {
  const {
    selectedTopics,
    stopScrolling,
    onDragToScroll,
    handleSelectedTopics,
    handleSelectedSessions,
    setSelectedType,
    setIsOverSession,
    getUniqueKey
  } = useCurriculumSetupContext();
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext<CurriculumForm>();
  const [width] = useWindowSize(20);
  const { t } = useTranslation('organisms/curriculumSetup');
  const isPressing = useRef(false);
  const startX = useRef(0);
  const topics: Topic[] = useWatch({
    control,
    name: `structure.sessions.${index}.topics`,
    exact: true,
    defaultValue: []
  });

  const moveItem = useCallback(
    (initialTopicId: string, newTime: string) => {
      const destinationSessionTopics: Topic[] = cloneDeep(
        getValues(`structure.sessions.${index}.topics`)
      );
      const newSelection = cloneDeep(selectedTopics);
      const initialSelectedTopic = selectedTopics.find(
        (selectedTopic) => selectedTopic.id === initialTopicId
      );
      if (initialSelectedTopic) {
        const initialTopic = getValues(
          `structure.sessions.${initialSelectedTopic.sourceSession.index}.topics.${initialSelectedTopic.index}`
        );
        if (
          (initialTopic &&
            initialTopic.startTime !== newTime &&
            initialSelectedTopic.sourceSession.index === index) ||
          initialSelectedTopic.sourceSession.index !== index
        ) {
          selectedTopics.forEach((selectedTopic: SelectedTopic, selectionIdx, actualArr) => {
            if (selectedTopic.sourceSession.index === index) {
              //on the same session.
              const tempTopic = destinationSessionTopics[selectedTopic.index];
              if (initialTopic.id !== selectedTopic.id) {
                const newDifferenceTime = calculateNewHour(
                  initialTopic.startTime,
                  destinationSessionTopics[selectedTopic.index].startTime,
                  newTime
                );
                destinationSessionTopics[selectedTopic.index] = {
                  ...tempTopic,
                  startTime: newDifferenceTime
                };
              } else {
                destinationSessionTopics[selectedTopic.index] = {
                  ...tempTopic,
                  startTime: newTime
                };
              }
            } else {
              //On other session.
              const sourceSessionTopics: Topic[] = cloneDeep(
                getValues(`structure.sessions.${selectedTopic.sourceSession.index}.topics`)
              );

              const selectedFixedIndex = sourceSessionTopics.findIndex(
                (actualTopic) => actualTopic.id === selectedTopic.id
              );

              if (selectedFixedIndex !== -1 && newTime) {
                const [retrievedTopic] = sourceSessionTopics.splice(selectedFixedIndex, 1);
                if (initialTopic.id !== selectedTopic.id) {
                  const newDifferenceTime = calculateNewHour(
                    initialTopic.startTime,
                    retrievedTopic.startTime,
                    newTime
                  );
                  retrievedTopic.startTime = newDifferenceTime;
                } else {
                  retrievedTopic.startTime = newTime;
                }

                setValue(
                  `structure.sessions.${selectedTopic.sourceSession.index}.topics`,
                  sourceSessionTopics,
                  { shouldDirty: true }
                );
                destinationSessionTopics.push(retrievedTopic);
                newSelection[selectionIdx].sourceSession = { index, id: sessionId };
                newSelection[selectionIdx].index = destinationSessionTopics.length - 1;
              }
            }
          });
          setValue(`structure.sessions.${index}.topics`, destinationSessionTopics, {
            shouldDirty: true
          });
          clearErrors(`structure.sessions.${index}.id`);
          handleSelectedSessions({ type: ActionTypes.DELETE_ALL });
          handleSelectedTopics({ type: ActionTypes.REPLACE, selection: newSelection });
          setSelectedType(TabOptions.TOPIC);
        }
      }
    },
    [selectedTopics, index]
  );
  const [forceUpdate, setForceUpdate] = useState(false);

  const [actualTime, setActualTime] = useState<string | undefined>();

  const refContainer = useRef<HTMLDivElement>();
  const [{ isOver, droppedItemType, starterTopic }, drop] = useDrop(
    {
      accept: [DragNDropComponents.TOPIC, DragNDropComponents.NEW_TOPIC],
      hover: (item: { index: number; sessionIndex: number; topic: Topic }, monitor) => {
        const dropPosition = monitor.getSourceClientOffset();
        if (refContainer.current && dropPosition) {
          const rectPosition = refContainer.current.getBoundingClientRect();
          const dropX =
            dropPosition.x >= rectPosition.left && dropPosition.x <= rectPosition.right
              ? dropPosition.x - rectPosition.left
              : dropPosition.x < rectPosition.left
              ? 0
              : rectPosition.width;
          const width = refContainer.current.clientWidth;
          const ratio = dropX / width;
          const hours = Math.floor(ratio * 24);
          const minutes = Math.floor(ratio * 24 * 60 - hours * 60);
          const time =
            hours.toString().padStart(2, '0') +
            ':' +
            (minutes - (minutes % TimeInterval)).toString().padStart(2, '0');
          if (time !== actualTime) {
            setActualTime(time);
          }
        }
      },
      drop: (item: { index: number; sessionIndex: number; topic: Topic }, monitor) => {
        if (refContainer.current) {
          setActualTime(undefined);
        }
      },
      collect: (monitor) => {
        const topic = monitor.getItem()?.topic as Topic | undefined;
        if (topic) {
          topic.id = topic.id === DEFAULT_ID ? 'T' + getUniqueKey() : topic.id;
        }
        return {
          isOver: !!monitor.isOver(),
          droppedItemType: monitor.getItemType() as DragNDropComponents | null,
          starterTopic: topic
        };
      }
    },
    [selectedTopics, index, actualTime]
  );

  const overlapCounts = useMemo(() => {
    const topics = getValues(`structure.sessions.${index}.topics`);
    const sortedTopics: {
      id: string;
      startDate: number;
      endDate: number;
      yIndex: number;
    }[] = topics
      .map((newTopic) => {
        const startDate = formatDate(newTopic.startTime || '00:00');
        const convertedStartDate =
          Number(startDate.split(':')[0] || 0) * 60 + Number(startDate.split(':')[1] || 0);
        return {
          id: newTopic.id,
          startDate: convertedStartDate,
          endDate: convertedStartDate + (newTopic.duration || 60),
          yIndex: 0
        };
      })
      .sort((a, b) => {
        if (a.startDate !== b.startDate) {
          return a.startDate - b.startDate;
        }
        if (a.endDate !== b.endDate) {
          return b.endDate - a.endDate;
        }
        return a.id.localeCompare(b.id);
      });
    if (topics.length > 1) {
      const yEd = [sortedTopics[0].endDate];
      sortedTopics.slice(1).forEach((sortedTopic) => {
        let foundIndex = false;
        for (let i = 0; i < yEd.length; i++) {
          if (yEd[i] <= sortedTopic.startDate) {
            sortedTopic.yIndex = i;
            if (sortedTopic.endDate > yEd[i]) {
              yEd[i] = sortedTopic.endDate;
            }
            foundIndex = true;
            break;
          }
        }
        if (!foundIndex) {
          sortedTopic.yIndex = yEd.length;
          yEd.push(sortedTopic.endDate);
        }
      });
    }

    return sortedTopics;
  }, [topics.length, actualTime === undefined, forceUpdate]);

  useEffect(() => {
    if (droppedItemType && actualTime && starterTopic && isOver) {
      if (droppedItemType === DragNDropComponents.TOPIC && starterTopic) {
        moveItem(starterTopic.id, actualTime);
        return;
      }
      if (
        droppedItemType === DragNDropComponents.NEW_TOPIC &&
        selectedTopics.find((topic) => topic.id === starterTopic.id)
      ) {
        moveItem(starterTopic.id, actualTime);
      } else {
        const destinationSessionTopics: Topic[] = cloneDeep(
          getValues(`structure.sessions.${index}.topics`)
        );
        destinationSessionTopics.push(starterTopic);
        setValue(`structure.sessions.${index}.topics`, destinationSessionTopics, {
          shouldDirty: true
        });
        clearErrors(`structure.sessions.${index}.id`);
        handleSelectedSessions({ type: ActionTypes.DELETE_ALL });
        handleSelectedTopics({
          type: ActionTypes.REPLACE,
          selection: [
            {
              id: starterTopic.id,
              index: destinationSessionTopics.length - 1,
              sourceSession: { index, id: sessionId }
            }
          ]
        });
        setSelectedType(TabOptions.TOPIC);
      }
    }
  }, [actualTime, droppedItemType, starterTopic, selectedTopics, isOver]);

  useEffect(() => {
    setIsOverSession(!!isOver);
    if (!isOver && scrollElement) {
      const hours = (scrollElement.scrollLeft * 24) / scrollElement.scrollWidth;
      setValue(`viewSettings.scrollTimes.${sessionId}`, hours, { shouldDirty: true });
    }
  }, [isOver]);

  const combinedRef = (node: any) => {
    drop(node);
    refContainer.current = node;
  };

  const [scrollElement, setScrollElement] = useState<HTMLDivElement>();

  const getXOffset = useCallback(
    (direction: 'left' | 'right') => {
      if (scrollElement) {
        const { scrollWidth, scrollLeft } = scrollElement;
        const range = scrollWidth / 24;
        if (direction === 'left') return Math.floor((scrollLeft - 1) / range) * range;
        if (direction === 'right') return Math.ceil((scrollLeft + 1) / range) * range;
      }
      return 0;
    },
    [scrollElement, scale]
  );
  const intervalId = useRef<NodeJS.Timer>();
  const handleScroll = useCallback(
    (direction: 'left' | 'right') => {
      if (scrollElement) {
        const xOffset = getXOffset(direction);
        const hours = (xOffset * 24) / scrollElement.scrollWidth;
        setValue(`viewSettings.scrollTimes.${sessionId}`, hours, { shouldDirty: true });
        scrollToElement(xOffset);

        intervalId.current = setInterval(() => {
          const xOffset = getXOffset(direction);
          scrollToElement(xOffset);
        }, 300);
      }
    },
    [scrollElement, scale]
  );

  const scrollToElement = useCallback(
    (xOffset: number) => {
      if (scrollElement)
        scrollElement.scrollTo({
          left: xOffset,
          behavior: 'smooth'
        });
    },
    [scrollElement]
  );

  const handleStopScrolling = useCallback(
    (e) => {
      clearInterval(intervalId.current as NodeJS.Timeout);
      if (scrollElement && intervalId && !isOver && isPressing.current) {
        const hours = (scrollElement?.scrollLeft * 24) / scrollElement.scrollWidth;
        setValue(`viewSettings.scrollTimes.${sessionId}`, hours, { shouldDirty: true });
        isPressing.current = false;
      }
      intervalId.current = undefined;
    },
    [intervalId.current, scrollElement, isOver]
  );

  useEffect(() => {
    window.addEventListener('mouseup', handleStopScrolling);
    return () => {
      window.removeEventListener('mouseup', handleStopScrolling);
    };
  }, [intervalId.current, handleStopScrolling]);

  const [canScroll, setCanScroll] = useState<boolean>(false);

  useDebouncyEffect(
    () => {
      if (scrollElement) {
        const { scrollWidth, offsetWidth } = scrollElement;
        setCanScroll(() => scrollWidth > offsetWidth);
      } else setCanScroll(false);
    },
    505,
    [scrollElement, scale, topics]
  );

  const formattedTime = DateTime.now().toLocaleString(DateTime.TIME_SIMPLE).toLocaleLowerCase();

  useEffect(() => {
    if (scrollElement) {
      const xOffset = getScrollPosition();
      scrollToElement(xOffset);
    }
  }, [width]);

  useEffect(() => {
    if (scrollElement) {
      setTimeout(() => {
        const xOffset = getScrollPosition();
        scrollToElement(xOffset);
        if (scale === ScaleConstraints.MIN)
          setValue(`viewSettings.scrollTimes.${sessionId}`, 0, { shouldDirty: true });
      }, 500);
    }
  }, [scale, scrollElement]);

  const getScrollPosition = () => {
    const scrollTime = getValues(`viewSettings.scrollTimes.${sessionId}`);
    if (scrollTime && scrollElement) return (scrollTime * scrollElement.scrollWidth) / 24;
    return 0;
  };
  useEffect(() => {
    if (scrollElement) scrollElement.scrollLeft = getScrollPosition();
  }, [scrollElement]);

  return (
    <>
      <RenderIf condition={canScroll}>
        <ScrollButton
          onClick={(e) => e.stopPropagation()}
          direction="left"
          style={{
            left: '-1.25rem'
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            isPressing.current = true;
            handleScroll('left');
          }}
          onMouseUp={() => (isPressing.current = false)}>
          <BsChevronCompactLeft size={16} />
        </ScrollButton>
        <ScrollButton
          onClick={(e) => e.stopPropagation()}
          direction="right"
          style={{
            right: '-1.25rem'
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            isPressing.current = true;
            handleScroll('right');
          }}
          onMouseUp={() => (isPressing.current = false)}>
          <BsChevronCompactRight size={16} />
        </ScrollButton>
      </RenderIf>
      <OuterContainer
        onMouseDown={(e) => {
          startX.current = e.pageX;
          e.stopPropagation();
        }}
        onClick={(e) => {
          if (Math.abs(startX.current - e.pageX) > 2) {
            e.stopPropagation();
          }
        }}
        ref={(node) => {
          if (node) setScrollElement(node);
        }}>
        <RenderIf condition={!!errors.structure?.sessions?.[index]?.id?.type}>
          <FormHelperTextError
            height="0px"
            variant="subtitle2"
            position="sticky"
            top="0.5rem"
            left="1rem">
            {t(('errors.' + errors.structure?.sessions?.[index]?.id?.type) as any)}
          </FormHelperTextError>
        </RenderIf>
        <SessionContainer
          onMouseDown={(e) => {
            if (scrollElement) {
              onDragToScroll(scrollElement, e.pageX, sessionId);
            }
          }}
          onMouseUp={(e) => {
            stopScrolling(setValue);
          }}
          ref={combinedRef}
          scale={scale}
          height={`calc(${
            (Math.max(...overlapCounts.map((overlap) => overlap.yIndex)) + 1) * 2
          }rem + 0.6rem)`}>
          {topics.map((topic, topicIdx) => {
            return (
              <TopicCard
                key={topic.id}
                index={topicIdx}
                sessionIndex={index}
                sessionStrategy={TimeStrategy.START_TIMES}
                overlapCount={overlapCounts.find((overlap) => overlap.id === topic.id)?.yIndex || 0}
                sessionId={sessionId}
                scale={scale}
                forceUpdate={() => {
                  setForceUpdate(!forceUpdate);
                }}
              />
            );
          })}
        </SessionContainer>

        <div
          style={{
            position: 'absolute',
            display: 'grid',
            width: `${scale * 100}%`,
            gridTemplateColumns: 'repeat(24,1fr)',
            height: '0.35rem',
            bottom: 0
          }}>
          {new Array(24).fill(1).map((val, counterIdx) => {
            return (
              <div
                key={counterIdx}
                style={{
                  position: 'relative',
                  width: '100%',
                  borderLeft: counterIdx !== 0 ? `1px solid ${theme.palette.divider}` : undefined
                }}>
                {counterIdx !== 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      width: '1rem',
                      height: '0.3rem',
                      display: 'grid',
                      justifyContent: 'center',
                      bottom: '0.25rem',
                      left: '-0.5rem'
                    }}>
                    <Typography
                      color={theme.palette.divider}
                      variant="overline"
                      fontSize={'0.5rem'}
                      lineHeight={0}>
                      {counterIdx === 12
                        ? counterIdx
                        : formattedTime.includes('a') || formattedTime.includes('p')
                        ? counterIdx % 12
                        : counterIdx % 24}
                    </Typography>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </OuterContainer>
    </>
  );
};
export default memo(TimeSessionRow);
