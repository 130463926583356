import { SystemSets } from '@/const/sets';
import { InferType, array, boolean, mixed, number, object, string } from 'yup';

export enum Tags {
  DELETED = 'deleted',
  CREATED = 'created',
  UPDATED = 'updated',
  UNCHANGED = 'unchanged'
}
export const ItemSchema = object({
  _id: string().required(),
  label: string()
    .required()
    // .test('required', 'validations.required', function (value) {
    //   const parent = this.parent;
    //   if (parent.tag == Tags.DELETED) {
    //     console.log('deleted:', true);
    //     return true;
    //   }
    //   console.log('text:', value !== undefined && value !== null && value !== '');
    //   return value !== undefined && value !== null && value !== '';
    // })
    .test('label', 'validations.atLeastOneItem', function (value) {
      const items = (this as any).from[1].value.items;
      return items.filter((item) => item.tag !== Tags.DELETED).length > 0;
    })
    .test('label', 'validations.uniqueLabels', function (value) {
      const items = (this as any).from[1].value.items;
      const parent = this.parent;
      for (const item of items) {
        if (
          item.label?.toLowerCase() === value?.toLowerCase() &&
          parent.tag !== Tags.DELETED &&
          parent._id !== item._id &&
          item.tag !== Tags.DELETED
        ) {
          return false;
        }
      }
      return true;
    }),

  setName: string(),
  data: mixed().test('data', 'validations.requiredData', function (value, context) {
    return (this as any).from[1]?.value?.system === SystemSets.Locations ? !!value : true;
  }),
  tag: string(),
  index: number()
});

export const SetSchema = object({
  newItem: string(),
  name: string()
    .max(100, 'validations.tooLong')
    .test('label', 'validations.notNull', function (value) {
      return value !== '';
    })
    .required(),
  system: boolean().default(false),
  items: array().of(ItemSchema).min(1, 'validations.atLeastOneItem').required()
});

export type SetType = Omit<InferType<typeof SetSchema>, 'system'> & { system: boolean | string };

export type ItemType = InferType<typeof ItemSchema>;
