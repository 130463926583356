import { TextField } from '@/theme';
import { PossibleValues, isMultipleInputValue } from '@/types';
import { TextFieldProps } from '@mui/material';
import { isString } from 'lodash';
import { ChangeEvent, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export type TextFilterValue = string | null;

export type TextFilterProps = {
  onChange: (value: TextFilterValue) => void;
  readOnly?: boolean;
} & Omit<TextFieldProps, 'onChange' | 'value'> &
  PossibleValues<TextFilterValue>;

const TextFilter = forwardRef(
  ({ value, includeMultipleObj = false, onChange, readOnly, ...props }: TextFilterProps, ref) => {
    const { t: tCommon } = useTranslation();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(isString(e.target.value) && e.target.value.length > 0 ? e.target.value : null);
    };

    if (includeMultipleObj) {
      return (
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder={
            value && isMultipleInputValue(value)
              ? tCommon('text.multipleValues') //TODO: Generalize this inside MultipleInputComponent
              : props.placeholder || ''
          }
          value={isString(value) ? value : ''}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{ readOnly }}
          {...props}
        />
      );
    } else {
      return (
        <TextField
          {...props}
          fullWidth
          size="small"
          variant="outlined"
          value={isString(value) ? value : ''}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{ readOnly }}
        />
      );
    }
  }
);

export default TextFilter;
