import errorImg from '@/assets/imgs/404.png';
import { theme } from '@/theme';
import { Button, Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

const Img = styled('img')`
  width: 20rem;
  margin-bottom: 1rem;
`;

const MyErrorBoundary = () => {
  const error = useRouteError() as any;
  const { t: tCommon } = useTranslation();
  const onReset = () => {
    window.location.reload();
  };

  return (
    <Grid
      container
      sx={{
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.background.default
      }}>
      <Img src={errorImg} width={'4rem'} />
      <Typography variant="h4">{tCommon('errors.defaultError')}</Typography>
      <pre>{error?.message}</pre>
      <Button variant="contained" onClick={onReset}>
        {tCommon('buttons.tryAgain')}
      </Button>
    </Grid>
  );
};

export default MyErrorBoundary;
