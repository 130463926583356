import { RenderIf } from '@/components/atoms';
import { Card } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { IMPORT_RESOURCES } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { ButtonsContainer, Container, theme } from '@/theme';
import { ImportConfigDto } from '@/types';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaExclamationCircle, FaExclamationTriangle } from 'react-icons/fa';
import { LoadingText } from './FileSubmit.styles';

export type FileSubmitProps = {
  config: ImportConfigDto;
  file: File;
  onLoading: (loading: boolean) => unknown;
  onClose: (data?: any) => unknown;
};

const FileSubmit = ({ config, file, onClose, onLoading }: FileSubmitProps) => {
  const { setToastNotifications } = useToastContext();
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('templates/resourceImportPanelTemplate');

  const {
    fetch,
    data,
    loading: loadingFetch,
    error
  } = useFetch<{
    file: File;
    config: ImportConfigDto;
    output: {
      mappingWarnings: string[];
      rowFeedback: { index: number; warnings?: string[]; errors?: string[] }[];
      created: { count: number; ids: string[] };
    };
  }>();

  useEffect(() => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('config', JSON.stringify(config));

    fetch({
      url: IMPORT_RESOURCES,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });
  }, []);

  useEffect(() => {
    if (error) {
      setToastNotifications([
        {
          message: tCommon('errors.errorConfirmation', { message: (error as Error)?.message || '' })
        }
      ]);
      onClose(undefined);
    }
  }, [error]);

  useEffect(() => {
    onLoading(loadingFetch);
  }, [loadingFetch]);

  return (
    <Container>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {`${tCommon('actions.import')} ${tCommon('entities.resources_other')}`}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '2rem 2rem 0rem 2rem', height: '100%' }}>
        <RenderIf condition={loadingFetch}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexFlow: 'column',
              gap: '1rem',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress />
            <div style={{ display: 'flex', gap: '0.2rem' }}>
              <Typography variant="subtitle1">{t('fileSubmit.text.importing')}</Typography>
              <LoadingText sx={{ minWidth: '1rem' }} variant="subtitle1" />
            </div>
          </Box>
        </RenderIf>
        <RenderIf condition={!loadingFetch && !!data}>
          <List sx={{ padding: 0 }}>
            <ListItem sx={{ display: 'block', padding: 0 }}>
              <Card
                title={t('fileSubmit.text.summary')}
                scrollOnClick={false}
                contentProps={{ sx: { pt: 0, pb: '0.5rem' } }}
                sx={{
                  flex: 0,
                  flexShrink: '0',
                  paddingY: '0',
                  cursor: 'auto !important'
                }}>
                <List>
                  <ListItem sx={{ py: 0 }} disablePadding>
                    <ListItemIcon sx={{ minWidth: '2rem' }}>
                      <i className="b-tree-icon b-icon b-icon-tree-leaf"></i>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          {t('fileSubmit.text.importedResources')}
                          {'\t'}
                          <b>{data?.output.created.count || 0}</b>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }} disablePadding>
                    <ListItemIcon sx={{ minWidth: '2rem' }}>
                      <i className="b-tree-icon b-icon b-icon-tree-leaf"></i>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          {t('fileSubmit.text.rowsWErrors')}
                          {'\t'}
                          <b>
                            {data?.output.rowFeedback.filter(
                              (row) => row.errors && row.errors.length > 0
                            ).length || 0}
                          </b>
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Card>
            </ListItem>
            {data && data.output.mappingWarnings.length > 0 && (
              <ListItem sx={{ display: 'block', padding: 0 }}>
                <Card
                  title={t('fileSubmit.text.generalWarnings')}
                  scrollOnClick={false}
                  contentProps={{ sx: { pt: 0, pb: '0.5rem' } }}
                  defaultExpanded={false}
                  sx={{
                    flex: 0,
                    flexShrink: '0',
                    paddingY: '0',
                    cursor: 'auto !important'
                  }}>
                  <List>
                    {data.output.mappingWarnings.map((warning, key) => {
                      return (
                        <ListItem
                          key={key}
                          sx={{
                            py: 0,
                            my: '0.5rem'
                          }}
                          disablePadding>
                          <ListItemIcon sx={{ minWidth: '2rem' }}>
                            <FaExclamationTriangle color={theme.palette.warning.main} />
                          </ListItemIcon>
                          <ListItemText primary={warning} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Card>
              </ListItem>
            )}
            {data && data.output.rowFeedback.length > 0 && (
              <ListItem sx={{ display: 'block', padding: 0 }}>
                <Card
                  title={t('fileSubmit.text.feedBackByRow')}
                  scrollOnClick={false}
                  contentProps={{ sx: { pt: 0, pb: '0.5rem' } }}
                  defaultExpanded={false}
                  sx={{
                    flex: 1,
                    flexShrink: '0',
                    paddingY: '0',
                    cursor: 'auto !important'
                  }}>
                  <List sx={{ overflow: 'auto' }}>
                    {data.output.rowFeedback
                      .filter(
                        (feedback) =>
                          (feedback.errors && feedback.errors.length > 0) ||
                          (feedback.warnings && feedback.warnings.length > 0)
                      )
                      .map((feedback) => {
                        return (
                          <ListItem
                            key={feedback.index}
                            sx={{ display: 'block', paddingY: 0 }}
                            disablePadding>
                            <Card
                              title={t('fileSubmit.text.rowN', { number: feedback.index })}
                              scrollOnClick={false}
                              contentProps={{ sx: { pt: 0, pb: '0.5rem' } }}
                              sx={{
                                flex: 0,
                                flexShrink: '0',
                                paddingY: '0',
                                cursor: 'auto !important'
                              }}>
                              <List sx={{ paddingY: '0' }}>
                                {feedback.warnings &&
                                  feedback.warnings.map((warning, idx) => (
                                    <ListItem
                                      key={`warning_${idx}`}
                                      sx={{
                                        py: 0,
                                        paddingLeft: '0.5rem',
                                        my: '0.5rem'
                                      }}>
                                      <ListItemIcon sx={{ minWidth: '2rem' }}>
                                        <FaExclamationTriangle color={theme.palette.warning.main} />
                                      </ListItemIcon>
                                      <ListItemText primary={warning} />
                                    </ListItem>
                                  ))}
                                {feedback.errors &&
                                  feedback.errors.map((error, idx) => (
                                    <ListItem
                                      key={`error_${idx}`}
                                      sx={{
                                        py: 0,
                                        paddingLeft: '0.5rem',
                                        my: '0.5rem'
                                      }}>
                                      <ListItemIcon sx={{ minWidth: '2rem' }}>
                                        <FaExclamationCircle color={theme.palette.error.main} />
                                      </ListItemIcon>
                                      <ListItemText primary={error} />
                                    </ListItem>
                                  ))}
                              </List>
                            </Card>
                          </ListItem>
                        );
                      })}
                  </List>
                </Card>
              </ListItem>
            )}
          </List>
        </RenderIf>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button
            disabled={loadingFetch}
            variant="contained"
            onClick={() => {
              onClose();
            }}>
            {tCommon('buttons.close')}
          </Button>
        </ButtonsContainer>
      </FooterModalContainer>
    </Container>
  );
};

export default FileSubmit;
