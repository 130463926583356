import { Alert, Unstable_Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';

export const ScheduleConfigContainer = styled(Grid)`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 576px) {
    padding: 12px !important;
  }
`;

// export const EntitiesContainer = styled("div")`
//   display:
// `

export const EntitiesSkeleton = styled('div')`
  display: flex;
  & > * {
    margin-top: 0.5rem;
  }

  & > *:first-of-type {
    margin-right: 0.5rem;
  }
`;

export const AlertJobs = styled(Alert)`
  margin: 0 0;
  width: fit-content;
`;

export const TooltipGrid = styled(Grid)`
  min-width: 200px;
  display: flex;
  align-items: center;
  ul {
    padding-left: 1rem;
    margin: 0.5rem 0rem;
  }
  max-height: 10rem;
  overflow-y: auto;
`;
