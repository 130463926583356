import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const AutocompleteComponent = styled(MuiAutocomplete)`
  width: 300px;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const Autocomplete: <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => JSX.Element = AutocompleteComponent as any;
