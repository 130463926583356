import isologoAnimated from '@/assets/imgs/animacion-logo-xo.gif';
import isologoStopped from '@/assets/imgs/xo-sheduler-1.png';
import { RenderIf } from '@/components/atoms';
import { BoxProgress } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import ScheduleSummary from '@/components/organisms/ScheduleSummary';
import {
  DATETIME_FORMAT_PICKER,
  FinishedErrorStates,
  FinishedOKStates,
  FinishedStates,
  RunningDetailedStates,
  RunningStates,
  SCHEDULING_PROCESS_ACTION,
  ScheduleModes,
  ScheduleProcessStatus,
  locale
} from '@/const';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useScheduleConfigContext } from '@/context/ScheduleConfigContext';
import { useScheduleContext } from '@/context/SchedulerContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { ButtonsContainer } from '@/theme';
import { ScheduleResultDto, StatsDto } from '@/types';
import { dateDiffInSeconds } from '@/utils';
import { LoadingButton } from '@mui/lab';
import { Button, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { JobPeriodChart, ValueChart, chartOptions } from '../../ScheduleResultTemplate.schema';
import {
  BigTitle,
  CardsContainer,
  CardsInnerContainer,
  CardsOuterContainer
} from '../../ScheduleResultTemplate.styles';

export const ScheduleResultJobByJob = ({ byPeriods }: { byPeriods?: boolean }) => {
  const { handleClose } = useGenericModalContext();
  const [dataChart, setDataChart] = useState<[number, ValueChart][]>([
    [0, { value: 0, slotsToFill: undefined, slotsFilled: undefined }]
  ]);
  const [startTime, setStartTime] = useState<string>();
  const [jobs, setJobs] = useState<JobPeriodChart[]>([]);
  const [finished, setFinished] = useState<boolean>(false);
  const [runningTime, setRunningTime] = useState<number>();
  const [actualJobId, setActualJobId] = useState<string>();
  const { t } = useTranslation('templates/scheduleResultTemplate');
  const { t: tCommon } = useTranslation();
  const [error, setError] = useState<string | undefined>(undefined);
  const { currentProcess } = useScheduleContext();
  const { setToastNotifications } = useToastContext();
  const { fetch, loading: loadingActions, error: errorActions } = useFetch();
  const { schedulableJobs, selectedJobs } = useScheduleConfigContext();
  useEffect(() => {
    if (errorActions) {
      setToastNotifications([{ message: t('errors.actions') }]);
    }
  }, [errorActions]);

  const series: ApexAxisChartSeries = [
    {
      name: 'Progress',
      data: dataChart.map((data) => [data[0], data[1].value ?? 0])
    }
  ];

  const updatePeriodOfJob = (
    oldJobs: JobPeriodChart[],
    jobIdToUpdate: string,
    periodToUpdate: number,
    totalPeriods: number
  ) => {
    const newState = oldJobs.map((job) => {
      if (job.jobId == jobIdToUpdate && job.actualPeriod != periodToUpdate) {
        job.actualPeriod = periodToUpdate;
        job.totalPeriods = totalPeriods;
      }
      return job;
    });
    return newState;
  };

  const updateJob = (
    data: ScheduleResultDto,
    oldJobs: JobPeriodChart[],
    customJob?: JobPeriodChart
  ) => {
    const newState = oldJobs.map((oldJob: JobPeriodChart) => {
      if (oldJob.jobId == data.currentJob) {
        const details = data?.details?.[data.currentJob ?? ''];
        if (details) {
          const phase_infos = details.phase_infos?.[0];
          return {
            jobId: oldJob.jobId,
            percentage: phase_infos?.percentage ?? 0,
            state: (phase_infos?.inner_status ?? details.status ?? '').toUpperCase(),
            jobs_processed: details.stats?.jobs_processed,
            jobs_to_process: currentProcess?.config.jobs.length ?? 0,
            running_time: details.stats?.running_time,
            slots_filled: details.stats?.slots_filled,
            slots_to_fill: details.stats?.slots_to_fill
          };
        } else {
          return (
            customJob ?? {
              jobId: oldJob.jobId,
              percentage: tCommon('utils.n/a'),
              state: ScheduleProcessStatus.FINISHED.name,
              jobs_processed: 0,
              jobs_to_process: 0,
              running_time: 0,
              slots_filled: -1,
              slots_to_fill: -1
            }
          );
        }
      }
      return oldJob;
    });
    return newState;
  };

  useEffect(() => {
    if (currentProcess) {
      setStartTime(
        DateTime.fromISO(currentProcess.startTime)
          .setLocale(locale)
          .toFormat(DATETIME_FORMAT_PICKER)
      );
      setFinished(FinishedStates.includes(currentProcess.status.toUpperCase()));
      if (currentProcess.endTime) {
        setRunningTime(dateDiffInSeconds(currentProcess.startTime, currentProcess.endTime));
      }
      if (actualJobId != currentProcess.currentJob) {
        setActualJobId(currentProcess.currentJob);
      }

      if (currentProcess.status == ScheduleProcessStatus.FAULTED.name) {
        setJobs((oldJobs: JobPeriodChart[]) =>
          updateJob(currentProcess, oldJobs, {
            jobId: currentProcess.currentJob,
            percentage: tCommon('utils.noValue'),
            state: ScheduleProcessStatus.FAULTED.name,
            slots_filled: -1,
            slots_to_fill: -1,
            jobs_processed:
              currentProcess.config.jobs.findIndex((job) => job == currentProcess.currentJob) + 1,
            jobs_to_process: currentProcess.config.jobs.length,
            running_time: runningTime || 0
          })
        );
      } else {
        setJobs((oldJobs: JobPeriodChart[]) => updateJob(currentProcess, oldJobs));
      }

      const details = currentProcess.details?.[currentProcess.currentJob ?? ''];
      if (!details) {
        return;
      }
      if (details.errorMsg) {
        setError(details.errorMsg);
        return;
      }

      if (
        byPeriods &&
        actualJobId &&
        details.phase_infos &&
        jobs.find((job) => job.jobId == actualJobId)?.actualPeriod != details.phase_infos.length - 1
      ) {
        setJobs((oldJobs) => {
          return updatePeriodOfJob(
            oldJobs,
            actualJobId,
            details?.phase_infos?.length,
            details?.total_phases
          );
        });
      }

      setDataChart([
        [
          0,
          {
            value: 0,
            slotsToFill: undefined,
            slotsFilled: undefined
          }
        ],
        ...(details.phase_infos?.[0]?.percentage_history ?? []).map((item) => {
          return [
            item.time,
            {
              value: item.pct,
              slotsToFill: details.stats.slots_to_fill,
              slotsFilled: item.slots
            }
          ];
        })
      ]);
    }
  }, [currentProcess, finished, actualJobId]);

  const stopProcess = () => {
    if (currentProcess?._id) {
      fetch({
        url: SCHEDULING_PROCESS_ACTION,
        method: 'PATCH',
        data: {
          processId: currentProcess._id,
          action: 'stop'
        }
      });
    }
  };

  const nextJob = () => {
    if (currentProcess?._id) {
      fetch({
        url: SCHEDULING_PROCESS_ACTION,
        method: 'PATCH',
        data: {
          processId: currentProcess._id,
          action: 'nextJob',
          jobId: currentProcess.currentJob
        }
      });
    }
  };

  const options = {
    ...chartOptions(
      t,
      tCommon,
      dataChart.map((data) => data[1].slotsFilled),
      dataChart.map((data) => data[1].slotsToFill)
    ),
    chart: {
      id: `basic-bar${Math.random()}`,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`
    }
  };

  const getTime = (status: string, updated_time?: string, running_time = 0) => {
    if (!updated_time) return;

    if (!RunningStates.includes(status)) {
      return Duration.fromObject({ seconds: running_time });
    }
    return DateTime.now().diff(DateTime.fromISO(updated_time)).plus({ seconds: running_time });
  };

  return (
    <Grid
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      minWidth={'100%'}
      height={'100%'}>
      <HeaderModalContainer textAlign={'center'} display="flex" justifyContent="center" gap="1rem">
        <img
          style={{ height: '1.6rem' }}
          src={
            RunningStates.includes(currentProcess?.status ?? '') ? isologoAnimated : isologoStopped
          }
        />
        <BigTitle variant="h5">{finished ? t('titleResult') : t('title')}</BigTitle>
      </HeaderModalContainer>
      <BodyModalContainer>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: 'wrap'
          }}>
          <Typography variant="h6">
            {startTime ? t('startDate', { date: startTime }) : t('initializing')}
          </Typography>
          {!!currentProcess?.startedBy?.label && (
            <Typography variant="h6" sx={{ textAlign: 'end' }}>
              {t('by', { name: currentProcess.startedBy.label })}
            </Typography>
          )}
        </Grid>
        <CardsOuterContainer>
          <CardsInnerContainer>
            <CardsContainer>
              {currentProcess?.config.jobs.map((jobId: string, idx: number) => {
                const jobDetails = currentProcess.details?.[jobId];
                const jobStatus = jobDetails?.status;
                const jobStats = jobDetails?.stats;
                const jobPhaseInfo = jobDetails?.phase_infos?.[0];
                const jobInnerStatus = jobPhaseInfo?.inner_status?.toUpperCase() ?? '';
                const jobinfo = selectedJobs.find(selectedJob => selectedJob._id == jobId);
                const className = jobinfo?.properties.find(prop => prop._id == '000000000000000000020001')?.value as string

                return (
                  <Grid sx={{ height: 'min-content' }} key={idx}>
                    <BoxProgress
                      title={className} 
                      initialTime={getTime(
                        jobStatus,
                        currentProcess.updatedAt,
                        jobStats?.running_time
                      )}
                      percentage={
                        (isUndefined(jobStats?.slots_to_fill) || jobStats?.slots_to_fill == 0) &&
                        jobStatus === ScheduleProcessStatus.FINISHED.name
                          ? tCommon('utils.n/a')
                          : jobPhaseInfo?.percentage ??
                            (jobPhaseInfo?.optimization_time > 0 ? 0 : tCommon('utils.noValue'))
                      }
                      state={jobStatus}
                      detailedState={
                        !isUndefined(jobStatus) &&
                        !FinishedOKStates.includes(jobStatus) &&
                        !FinishedErrorStates.includes(jobStatus)
                          ? RunningDetailedStates.includes(jobInnerStatus)
                            ? jobInnerStatus
                            : RunningDetailedStates.includes(jobStatus)
                            ? jobStatus
                            : ScheduleProcessStatus.RUNNING.name
                          : undefined
                      }
                      assigned={{
                        x: jobStats?.slots_filled ?? -1,
                        y: jobStats?.slots_to_fill ?? -1
                      }}
                    />
                  </Grid>
                );
              })}
            </CardsContainer>
          </CardsInnerContainer>
        </CardsOuterContainer>

        <Grid display={'flex'} justifyContent={'center'}>
          <Grid minHeight={300} minWidth="20vw" width="60%" xs={12} marginBottom="1rem">
            <Chart type="line" options={options} series={series} height="100%" width={'100%'} />
          </Grid>
        </Grid>
        <CSSTransition timeout={500} in={finished} classNames="fade">
          <RenderIf condition={finished}>
            <Grid width={'100%'}>
              <ScheduleSummary
                scheduleMode={ScheduleModes.JOBBYJOB}
                resultStats={
                  {
                    running_time: runningTime ?? 0,
                    jobs_to_process: currentProcess?.config.jobs.length || 0,
                    jobs_processed:
                      (currentProcess?.config.jobs.length || 0) -
                      (currentProcess?.jobQueue.length || 0),
                    slots_to_fill: currentProcess?.details
                      ? Object.values(currentProcess.details).reduce(
                          (slotsToFillCount, detail) =>
                            slotsToFillCount + (detail.stats?.slots_to_fill ?? 0),
                          0
                        )
                      : 0,
                    slots_filled: currentProcess?.details
                      ? Object.values(currentProcess.details).reduce(
                          (slotsFilledCount, detail) =>
                            slotsFilledCount + (detail.stats?.slots_filled ?? 0),
                          0
                        )
                      : 0
                  } as StatsDto
                }
                jobIds={currentProcess?.config.jobs}
                status={jobs.length > 0 ? jobs[jobs.length - 1].state : ''}
                error={error}
              />
            </Grid>
          </RenderIf>
        </CSSTransition>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <RenderIf condition={!finished}>
            <LoadingButton variant="contained" loading={loadingActions} onClick={nextJob}>
              {t('byjobs.nextJob')}
            </LoadingButton>
            <LoadingButton variant="contained" loading={loadingActions} onClick={stopProcess}>
              {t('terminate')}
            </LoadingButton>
          </RenderIf>
          <RenderIf condition={finished}>
            <Button onClick={() => handleClose(false)} variant="contained">
              {tCommon('buttons.close')}
            </Button>
          </RenderIf>
        </ButtonsContainer>
      </FooterModalContainer>
    </Grid>
  );
};
