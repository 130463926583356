import { TFunction } from 'i18next';

export const ScheduleProcessStatus = {
  STARTED: {
    name: 'STARTED',
    key: 'started',
    color: 'info',
    textColor: '#fff',
    text: ''
  },
  LOADING_DATA: {
    name: 'LOADING_DATA',
    key: 'loading_data',
    color: 'info',
    textColor: '#fff',
    text: ''
  },
  RUNNING: {
    name: 'RUNNING',
    key: 'running',
    color: 'info',
    textColor: '#fff',
    text: ''
  },
  SAVING_DATA: {
    name: 'SAVING_DATA',
    key: 'saving_data',
    color: 'info',
    textColor: '#fff',
    text: ''
  },
  FAULTED: {
    name: 'FAULTED',
    key: 'faulted',
    color: 'error',
    textColor: '#fff',
    text: ''
  },
  STOPPING: {
    name: 'STOPPING',
    key: 'stopping',
    color: 'info',
    textColor: '#fff',
    text: ''
  },
  STOPPED: {
    name: 'STOPPED',
    key: 'stopped',
    color: 'success',
    textColor: '#1A1A19',
    text: ''
  },
  FINISHED: {
    name: 'FINISHED',
    key: 'finished',
    color: 'success',
    textColor: '#1A1A19',
    text: ''
  },
  CANCELLED: {
    name: 'CANCELLED',
    key: 'cancelled',
    color: 'secondary',
    textColor: '#fff',
    text: ''
  },
  UNDEFINED: {
    key: 'undefined',
    color: 'secondary',
    textColor: '#fff',
    text: ''
  }
};

export enum ScheduleModes {
  JOBBYJOB = 'jobByJob',
  COMPLETE = 'complete'
}

export const getScheduleProcessStatus = (status: string, t: TFunction) => {
  let processStatus = ScheduleProcessStatus[status.toUpperCase()];
  if (!processStatus) {
    processStatus = ScheduleProcessStatus.UNDEFINED;
  }
  processStatus.text = t(`status.${processStatus.key}`);
  return processStatus;
};

export enum SeriesDeletionModes {
  THIS = 'this',
  ALL = 'all',
  FOLLOWING = 'thisAndFollowing'
}

export enum UnscheduleJobsModes {
  FULLY = 'fully',
  RESOURCES = 'resources'
}

export const RunningDetailedStates = [
  ScheduleProcessStatus.LOADING_DATA.name,
  ScheduleProcessStatus.RUNNING.name,
  ScheduleProcessStatus.SAVING_DATA.name,
  ScheduleProcessStatus.STOPPING.name,
  // internal optimization states (phase)
  'CONFIGURING'
];

export const RunningStates = [
  ScheduleProcessStatus.STARTED.name,
  ...RunningDetailedStates,
  // internal optimization states (phase)
  'OPTIMIZING'
];

export const FinishedOKStates = [
  ScheduleProcessStatus.FINISHED.name,
  ScheduleProcessStatus.STOPPED.name,
  // internal optimization state
  'INTERRUPTED'
];

export const FinishedErrorStates = [
  ScheduleProcessStatus.FAULTED.name,
  // internal optimization states (phase)
  'INFEASIBLE',
  'KEYERROR',
  'ERROR'
];

export const FinishedStates = [
  ScheduleProcessStatus.CANCELLED,
  ...FinishedOKStates,
  ...FinishedErrorStates
];
