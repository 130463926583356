import { Switch, SwitchTag } from '@/components/atoms';
import { Entities, Entity, PropertyTypes } from '@/const';
import { EntityTypeDto, PropertyDto, ResourceBryntum } from '@/types';
import { transformPropertyValue } from '@/utils';
import { BryntumGrid, BryntumGridProps } from '@bryntum/grid-react-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { Grid as GridMUI, Skeleton, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { ForwardedRef, RefObject } from 'react';
import { renderToString } from 'react-dom/server';

export const propertyAttributes = [
  { label: 'name', type: PropertyTypes.STRING },
  { label: 'type', type: PropertyTypes.SET },
  { label: 'unique', type: PropertyTypes.BOOLEAN },
  { label: 'immutable', type: PropertyTypes.BOOLEAN },
  { label: 'required', type: PropertyTypes.BOOLEAN }
] as const;

export type AttributeDto = {
  label: string;
  type: string;
};

export const getGridConfig: BryntumGridProps = {
  animateRemovingRows: false,
  columns: [
    ...Array(4)
      .fill('')
      .map(() => ({
        text: '...',
        width: 220
      }))
  ],
  filterBarFeature: false,
  stripeFeature: true,
  selectionMode: {
    row: true,
    cell: false,
    multiSelect: false,
    deselectOnClick: false
  },
  cellEditFeature: false,
  rowHeight: 32
};

export const transformProperties = (properties?: PropertyDto[]) => {
  let localProperties: ResourceBryntum[];

  if (properties)
    localProperties = properties.map((property) => {
      const localResource: ResourceBryntum = {
        id: property._id,
        switch: undefined,
        loadingSwitch: false,
        loadingFullSwitch: false
      };
      propertyAttributes.forEach(({ label }) => (localResource[label] = property[label]));
      localResource['referencedSet'] = property['referencedSet'];
      localResource['referencedCollection'] = property['referencedCollection'];
      return localResource;
    });
  else localProperties = new Array(5).fill('').map((_, idx) => ({ id: `${idx}` }));
  return localProperties;
};
export const transformPropertiesEntityType = (entity: Entity, entityTypes?: EntityTypeDto[]) => {
  let localEntityTypes: ResourceBryntum[] = [];

  if (entityTypes && (entity == Entities.RESOURCERECORDS || entity == Entities.RESOURCES))
    localEntityTypes = entityTypes.map((entityType) => {
      const localResource: ResourceBryntum = {
        id: entityType._id,
        name: entityType.name
      };
      return localResource;
    });
  else localEntityTypes = [];
  return localEntityTypes;
};

export const transformTypes = (
  t: TFunction,
  tCommon: TFunction,
  typeOption: string,
  referencedSet?: string,
  referencedCollection?: string
) => {
  if (typeOption == PropertyTypes.SET) {
    return tCommon(`typeOptions.${typeOption}`) + ` (${referencedSet})`;
  }
  if (typeOption == PropertyTypes.OBJECTID) {
    return (
      tCommon(`typeOptions.${typeOption}`) +
      ` (` +
      tCommon(`entities.${referencedCollection}_other`) +
      `)`
    );
  }
  return tCommon(`typeOptions.${typeOption}`);
};

export const getColumns = (
  ref: ForwardedRef<BryntumGrid>,
  t: TFunction,
  tCommon: TFunction,
  onSwitch: (switched: boolean, id: string) => void,
  entity: Entity,
  isLoadingProperties?: boolean,
  rowsNumber?: number
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumGrid>).current?.instance
    .columns as ColumnStore;
  columnStore.removeAll();
  const columns: object[] = [
    {
      id: 'counter',
      type: 'rownumber',
      cls: 'counter-header',
      hideable: false,
      text: renderToString(
        <GridMUI display={'flex'} flexWrap="wrap">
          <>
            <Typography>{tCommon('text.count') as string}</Typography>
            <Typography>{tCommon('number', { value: rowsNumber ?? 0 }) as string}</Typography>
          </>
        </GridMUI>
      ),
      cellCls: 'b-border-bottom',
      align: 'center',
      htmlEncodeHeaderText: false
    },

    ...propertyAttributes.map(({ label, type }) => ({
      id: label,
      groupable: false,
      resizable: label == 'unique' || label == 'immutable' || label == 'required' ? false : true,
      text: t(`attributes.${label}`),
      width: label == 'unique' || label == 'immutable' || label == 'required' ? '6rem' : 'auto',
      minWidth: '6rem',
      flex:
        label == 'unique' || label == 'immutable' || label == 'required' ? '0 1 6rem' : '1 1 6rem',
      align: 'left',
      sortable: false,
      renderer: ({ record: { data } }) => {
        return data[label] == undefined || isLoadingProperties ? (
          <Skeleton height={30} />
        ) : label == 'type' ? (
          transformTypes(
            t,
            tCommon,
            data[label],
            data['referencedSet'],
            data['referencedCollection']
          )
        ) : (
          transformPropertyValue(type, tCommon, data[label])
        );
      }
    }))
  ];
  if (entity == Entities.RESOURCERECORDS || entity == Entities.RESOURCES) {
    columns.push({
      id: 'switch',
      groupable: false,
      text: t('switch'),
      resizable: false,
      sortable: false,
      cellCls: 'switch-cell',
      align: 'center',
      htmlEncodeHeaderText: false,
      width: '6rem',
      minWidth: '6rem',
      flex: '0 1 6rem',
      renderer: ({ record: { data } }) => {
        return (
          <>
            {data.loadingFullSwitch ? (
              <Skeleton height={30} />
            ) : (
              <GridMUI
                display={'flex'}
                flexWrap={'wrap'}
                alignItems={'center'}
                justifyContent={'center'}
                height={'100%'}>
                {data.required && data.immutable ? (
                  <SwitchTag
                    size="small"
                    label={tCommon('buttons.on').toLocaleUpperCase()}
                    tooltipLabel={t('propertyRequired')}
                  />
                ) : (
                  <Switch
                    loading={data.loadingSwitch}
                    size="small"
                    falseLabel="Off"
                    trueLabel="On"
                    status={!data.switch}
                    variantColor="grey"
                    handleChange={(change) => onSwitch(change, data.id)}
                  />
                )}
              </GridMUI>
            )}
          </>
        );
      }
    });
  }

  columnStore.add(columns);
};
export const getColumnsEntityType = (
  ref: ForwardedRef<BryntumGrid>,
  t: TFunction,
  tCommon: TFunction,
  entity: Entity,
  isLoadingProperties?: boolean
) => {
  if (ref && (ref as RefObject<BryntumGrid>).current) {
    const columnStore: ColumnStore = (ref as RefObject<BryntumGrid>).current?.instance
      .columns as ColumnStore;
    columnStore.removeAll();
    columnStore.add([
      {
        id: 'switchers',
        htmlEncodeHeaderText: false,
        text: renderToString(
          <GridMUI display={'flex'} flexWrap="wrap">
            <Typography style={{ lineHeight: 2.515 }}>
              {
                t(`switchers`, {
                  entity: tCommon(
                    `entities.${
                      entity == Entities.RESOURCERECORDS ? Entities.DOCUMENTS : entity
                    }_one`
                  ) as string
                }) as string
              }
            </Typography>
          </GridMUI>
        ),
        minWidth: '12rem',
        align: 'left',
        resizable: false,
        sortable: false,
        groupable: false,
        renderer: ({ record: { data } }) => {
          return isLoadingProperties || !data ? <Skeleton height={30} /> : data.name;
        }
      }
    ]);
  }
};
