import { PropertyTypes } from '@/const';
import { DateTime } from 'luxon';
import { LabeledReference, PropertyDto, PropertyValue } from '../dto';

export type DefaultOptionType = Record<string, unknown> & {
  value: number | string;
};

export type IntervalNumberValue = number;

export type IntervalNumber = {
  $gte?: IntervalNumberValue;
  $lte?: IntervalNumberValue;
};

export type PeriodDateValue = DateTime | null;

export type PeriodDate = {
  $gte?: PeriodDateValue;
  $lte?: PeriodDateValue;
  start?: PeriodDateValue;
  end?: PeriodDateValue;
};

export type PropertySortingType = {
  property: string;
  ascending: boolean;
};

export enum SpecialFilterTypes {
  CUSTOM_BOOLEAN = 'custom_boolean',
  CUSTOM_SET = 'custom_set',
  OPTIONS = 'options'
}

export type FieldFilterType = (
  | {
      type: PropertyTypes | SpecialFilterTypes;
    }
  | {
      type: SpecialFilterTypes.OPTIONS;
      options: PropertyValue[];
    }
) &
  Omit<PropertyDto, 'unique' | 'required' | 'inmutable' | 'entity' | 'type'> & {
    value?: unknown;
    order?: number;
  };

export type DocumentCriterium = {
  _id: string;
  name: string;
  type: string;
  assigned: boolean;
};
type SelectedType = LabeledReference;
export type ResourceFilterByRecords = {
  documents: DocumentCriterium[];
  operation: string;
  selected?: SelectedType;
};
