import { RenderIf } from '@/components/atoms';
import { Card, NumberInput } from '@/components/molecules';
import { DATE_FORMAT_PICKER, locale } from '@/const';
import { theme } from '@/theme';
import { GridColumnConfig, Model, Store } from '@bryntum/core-thin';
import { BryntumGrid, BryntumGridProps } from '@bryntum/grid-react-thin';
import { Grid } from '@bryntum/grid-thin';
import {
  FormControlLabel,
  FormHelperText,
  IconButton,
  Grid as MuiGrid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { JobFormDto, SelectedSeries } from '../../JobPanelTemplate.const';

const RecurrentJobForm = () => {
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(null);
  const { t: tCommon } = useTranslation('common');
  const { t: tJob } = useTranslation('templates/jobPanelTemplate');
  const ref = useRef<BryntumGrid>(null);
  const {
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = useFormContext<JobFormDto>();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'dated'
  });

  const modifyCounter = useCallback(
    (id: string, val: number) => {
      if (!(watch('selectedSeries') !== SelectedSeries.dated)) {
        const data: Model = ((ref.current?.instance as Grid).store as Store).getById(id);
        const actualFields = watch('dated');
        const foundIdx = actualFields.findIndex((record) => record.date === data.get('date'));
        if (isNumber(val) && val > 0 && val < 100 && foundIdx !== -1) {
          setValue(`dated.${foundIdx}.count`, val);
          data.set('count', val);
        }
      }
    },
    [ref]
  );

  const onCellClick: BryntumGridProps['onCellClick'] = useCallback(
    (event) => {
      const date = (event.record as Model).get('date');
      if (
        date &&
        !(watch('selectedSeries') !== SelectedSeries.dated) &&
        (!selectedDate || (selectedDate && date !== selectedDate.toISODate()))
      ) {
        setSelectedDate(DateTime.fromISO(date));
      }
    },
    [selectedDate, fields]
  );

  const removeByDate = useCallback(
    (date: string) => {
      if (!(watch('selectedSeries') !== SelectedSeries.dated)) {
        const actualFields = watch('dated');
        const foundIdx = actualFields.findIndex((record) => record.date === date);
        if (foundIdx !== -1) {
          remove(foundIdx);
          setSelectedDate(null);
        }
      }
    },
    [selectedDate, ref]
  );

  useEffect(() => {
    if (ref) {
      const gridRef = ref.current?.instance as Grid;

      if (selectedDate) {
        const formattedDate = selectedDate.toISODate();
        const foundField = fields.find((field) => field.date === formattedDate);
        if (foundField) {
          const data: Model = ((ref.current?.instance as Grid).store as Store).getById(
            foundField.id
          );
          gridRef.selectedRecord = data;
        }
      } else {
        gridRef.deselectAll();
      }
    }
  }, [ref, selectedDate, fields]);

  const [actualView, setActualView] = useState<'year' | 'day'>('day');

  const addNewDated = useCallback(
    (value: DateTime | null) => {
      const formattedDate = value?.toISODate();
      if (formattedDate) {
        const foundField = fields.find((field) => field.date === formattedDate);
        if (foundField) {
          setSelectedDate(value);
        } else if (actualView !== 'year') {
          if (errors.dated) clearErrors('dated');
          setSelectedDate(value);
          append({
            date: formattedDate,
            count: 1
          });
        } else {
          setSelectedDate(null);
        }
      }
    },
    [fields, errors, actualView]
  );

  const renderFields = useMemo(
    () => fields.sort((fieldA, fieldB) => fieldA.date.localeCompare(fieldB.date)),
    [fields]
  );

  const bryntumProps: Partial<BryntumGridProps> = useMemo(
    () => ({
      height: '100%',
      width: '100%',
      rowHeight: 30,
      stripeFeature: true,
      cls: 'b-custom-header-resized',
      readOnly: true,
      emptyText: tJob('recurrentJob.table.emptyText')
    }),
    []
  );

  const columns = useMemo(
    () =>
      [
        {
          text: tJob('recurrentJob.table.columns.date'),
          id: 'date',
          minWidth: 0,
          field: 'date',
          draggable: false,
          hideable: false,
          resizable: false,
          width: '6rem',
          align: 'left',
          sortable: false,
          groupable: false,
          renderer: ({
            record: {
              data: { date, count, id }
            }
          }) => {
            return (
              DateTime.fromISO(date as string)
                .setLocale(locale)
                .toFormat(DATE_FORMAT_PICKER as string) || ''
            );
          }
        },
        {
          text: tJob('recurrentJob.table.columns.count'),
          id: 'count',
          minWidth: 0,
          align: 'left',
          field: 'count',
          draggable: false,
          hideable: false,
          resizable: false,
          width: '6rem',
          renderer: ({
            record: {
              data: { date, count, id }
            }
          }) => {
            return (
              <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                <Typography fontSize={'inherit'}>{count || ''}</Typography>
                <div style={{ marginLeft: '0.5rem' }}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      modifyCounter(id, count - 1);
                    }}>
                    <i
                      style={{ fontSize: '0.9rem' }}
                      className="b-icon b-fa-circle-minus"
                      aria-hidden="true"></i>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      modifyCounter(id, count + 1);
                    }}>
                    <i
                      style={{ fontSize: '0.9rem' }}
                      className="b-icon b-fa-circle-plus"
                      aria-hidden="true"></i>
                  </IconButton>
                </div>
              </div>
            );
          },
          sortable: false,
          groupable: false
        },
        {
          text: tJob('recurrentJob.table.columns.action'),
          headerRenderer: ({ header }) => '<i class="b-icon b-fa-trash" style="margin:0 0 0 1px"/>',
          align: 'center',
          draggable: false,
          hideable: false,
          resizable: false,
          minWidth: 0,
          width: '3.2rem',
          id: 'action',
          field: 'action',
          renderer: ({
            record: {
              data: { date, count, id }
            }
          }) => {
            return (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    if (date) {
                      removeByDate(date);
                    }
                  }}>
                  <i style={{ fontSize: '0.8rem' }} className="b-icon b-fa-trash"></i>
                </IconButton>
              </div>
            );
          },
          sortable: false,
          groupable: false
        }
      ] as Partial<GridColumnConfig>[],
    []
  );

  return (
    <Card
      title={tJob('recurrentJob.title')}
      sx={{
        marginBlock: '1rem !important',
        cursor: 'auto !important'
      }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={SelectedSeries.none}
        name="radio-buttons-group">
        <FormControlLabel
          value={SelectedSeries.none}
          control={
            <Radio
              onChange={(e) => {
                setValue('selectedSeries', SelectedSeries.none);
                clearErrors('distributed');
                clearErrors('dated');
              }}
            />
          }
          label={tJob('recurrentJob.types.none')}
        />
        <FormControlLabel
          sx={{ mt: '0.6rem' }}
          value={SelectedSeries.distributed}
          control={
            <Radio
              onChange={(e) => {
                setValue('selectedSeries', SelectedSeries.distributed);
                clearErrors('dated');
              }}
            />
          }
          label={
            <MuiGrid display={'flex'} gap={'8px'} alignItems="center">
              <Typography sx={{ flex: 0 }}>{tCommon('actions.create')}</Typography>
              <Controller
                control={control}
                name="distributed"
                rules={{
                  validate: {
                    required: (value, formValues) => {
                      return formValues.selectedSeries === SelectedSeries.distributed
                        ? value?.count > 0
                        : true;
                    }
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <NumberInput
                    sx={{ flex: 1 }}
                    maxLength={5}
                    disabled={watch('selectedSeries') !== SelectedSeries.distributed}
                    allowDecimals={false}
                    allowNegative={false}
                    value={value?.count || undefined}
                    onChange={(value) => onChange({ count: value })}
                    error={!!errors?.distributed}
                  />
                )}
              />

              <Typography sx={{ flex: 4 }}>{tJob('recurrentJob.types.distributed')}</Typography>
            </MuiGrid>
          }
        />
        <FormControlLabel
          sx={{ mt: '0.6rem' }}
          value={SelectedSeries.dated}
          control={
            <Radio
              onChange={(e) => {
                setValue('selectedSeries', SelectedSeries.dated);
                clearErrors('distributed');
              }}
            />
          }
          label={tJob('recurrentJob.types.dated')}
        />
        <Controller
          control={control}
          name="dated"
          rules={{
            validate: {
              required: (value, formValues) => {
                return formValues.selectedSeries === SelectedSeries.dated
                  ? value?.length > 0
                  : true;
              }
            }
          }}
          render={() => (
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexFlow: 'row wrap',
                marginLeft: '2rem',
                gap: '2.1rem',
                alignItems: 'center',
                height: '14rem'
              }}>
              <div
                style={{
                  position: 'relative',
                  width: '14rem',
                  height: '14rem',
                  border: `1px solid ${theme.palette.divider}`,
                  overflow: 'hidden'
                }}>
                <DateCalendar
                  disabled={watch('selectedSeries') !== SelectedSeries.dated}
                  sx={{
                    width: '100%',
                    height: '100%',
                    '& .MuiYearCalendar-root': {
                      width: '100%',
                      maxHeight: '10rem'
                    },
                    '& .MuiPickersYear-root': {
                      flexBasis: '22%'
                    },
                    '& .MuiPickersYear-yearButton': {
                      width: '100%'
                    },
                    '& .MuiPickersCalendarHeader-root': {
                      padding: '0 0.5rem'
                    },
                    '& .MuiPickersDay-root': {
                      height: '1rem',
                      minWidth: '1rem'
                    }
                  }}
                  slotProps={{
                    switchViewButton: { sx: { padding: '0' } },
                    previousIconButton: { sx: { padding: '0' } },
                    nextIconButton: { sx: { padding: '0' } }
                  }}
                  openTo="day"
                  value={selectedDate}
                  onViewChange={(view) => {
                    setActualView(view as 'year' | 'day');
                  }}
                  onChange={(newValue, _) => {
                    addNewDated(newValue);
                  }}
                />
              </div>
              <div
                style={{
                  width: '16rem',
                  height: '100%',
                  border: `1px solid ${
                    errors?.dated ? theme.palette.error.main : theme.palette.divider
                  }`
                }}>
                <BryntumGrid
                  disabled={watch('selectedSeries') !== SelectedSeries.dated}
                  ref={ref}
                  {...bryntumProps}
                  data={renderFields}
                  columns={columns}
                  onCellClick={onCellClick}
                />
                <RenderIf condition={!!errors?.dated}>
                  <FormHelperText sx={{ width: '100%', textAlign: 'center' }} error>
                    {tJob('errors.dated')}
                  </FormHelperText>
                </RenderIf>
              </div>
            </div>
          )}
        />
      </RadioGroup>
    </Card>
  );
};

export default RecurrentJobForm;
