export const ucWords = (str: string) => {
  return `${str}`.replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const replaceTo = (str: string, from: string, to: string) => {
  const regex = new RegExp(from, 'g');
  return `${str}`.replace(regex, to);
};

export const checkStringsExist = (A: string[], B: string[]): boolean => {
  if (A.length == 0) return false;
  for (const string of B) {
    if (A.includes(string)) {
      return false;
    }
  }
  return true;
};
