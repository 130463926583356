import { JobDto } from '@/types';
import { PropsWithChildren } from 'react';
import JobContext, { JobType } from './JobContext';

export type JobContextProviderProps = PropsWithChildren<unknown>;

const JobContextProvider = ({ children }: JobContextProviderProps) => {
  const jobs: JobType = {};

  const handleJobs = (newJobs: JobType) => {
    Object.assign(jobs, newJobs);
  };

  const addJobs = (newJobs: JobDto[]) => {
    Object.assign(
      jobs,
      newJobs.reduce((acc, curr) => ({ ...acc, [curr._id]: curr }), {})
    );
  };

  const deleteJob = (idJob: string) => {
    delete jobs[idJob];
  };

  return (
    <JobContext.Provider
      value={{
        jobs,
        addJobs,
        handleJobs,
        deleteJob
      }}>
      {children}
    </JobContext.Provider>
  );
};

export default JobContextProvider;
