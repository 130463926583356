import { InferType, array, object, string } from 'yup';

export const UserFormSchema = object().shape({
  email: string()
    .required()
    .test('bad_email_pattern', 'bad_email_pattern', (value) => {
      const pattern = /^admin-.+@tillent\.com$/;
      return !pattern.test(value);
    }),
  roles: array()
    .min(1)
    .of(
      object().shape({
        _id: string().required(),
        label: string().required()
      })
    )
    .required(),
  resource: object()
    .shape({
      _id: string(),
      label: string()
    })
    .nullable()
});

export type UserFormSchemaType = InferType<typeof UserFormSchema>;
