import { RenderIf, ResourceThumbnail } from '@/components/atoms';
import { ResourceSlotCard } from '@/components/organisms/CurriculumSidePanel/components/common/ResourceSlotCard';
import { QualifiedResourcesPanelTemplate } from '@/components/templates/QualifiedResourcesPanelTemplate';
import { SCHEDULING_QUALIFIED_RESOURCES, SystemProperties } from '@/const';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { ActionResource, ResourcePosition } from '@/hooks';
import { theme } from '@/theme';
import { ResourceDto, SetItemDto } from '@/types';
import { Avatar, Box, Collapse, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { Dispatch, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiSolidErrorCircle } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';
import { FaFileCircleCheck } from 'react-icons/fa6';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';
import useSWR from 'swr';
import { JobEventForm } from '../../JobEventPanelTemplate';
import EmptyResource from '../EmptyResource/EmptyResource';
import ResourceSearch from '../ResourceSearch/ResourceSearch';
import { transformResource } from './ResourceSlot.const';
import { ResourceContainer, ResourceList } from './ResourceSlot.style';
type ResourceSlotProps = {
  resourceSlot: JobEventForm['resourceSlots'][0];
  roles: SetItemDto[];
  disabled?: boolean;
  dispatch: Dispatch<ActionResource>;
  index: number;
};
const ResourceSlot = ({
  resourceSlot,
  roles,
  dispatch,
  index,
  disabled = false
}: ResourceSlotProps) => {
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext<JobEventForm>();

  const { slide, removeSlide } = useGenericModalContext();
  const { t: jobEventPanelTemplate } = useTranslation('templates/jobEventPanelTemplate');
  const { t: tCommon } = useTranslation();
  const fetcher = async () => {
    if (!resourceSlot.documents && !resourceSlot.restrictLocation) return {};

    const data = (
      await axios.post(SCHEDULING_QUALIFIED_RESOURCES, {
        documents: resourceSlot.documents?.map((doc) => doc._id),
        ...(resourceSlot.restrictLocation
          ? { location: getValues(`properties.${SystemProperties.EVENTS_LOCATION}`)._id }
          : {}),
        exactPeriod: {
          start: getValues(`properties.${SystemProperties.EVENTS_START_TIMESTAMP}`),
          end: getValues(`properties.${SystemProperties.EVENTS_END_TIMESTAMP}`)
        }
      })
    ).data;
    return {
      ids: data.map((q) => q.resourceId)
    };
  };

  const { data: qualifiedResources } = useSWR<{ ids?: string[] }>(
    SCHEDULING_QUALIFIED_RESOURCES + resourceSlot.id,
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      refreshInterval: 60000
    }
  );

  const isLoadingQualifiedResources = useMemo(
    () => qualifiedResources === undefined,
    [qualifiedResources]
  );

  const { fields: resources, update: updateResource } = useFieldArray({
    control,
    name: `resourceSlots.${index}.resources`,
    keyName: 'customId'
  });

  const isQualified = (id: string) => {
    if (isEmpty(qualifiedResources)) return true;
    return qualifiedResources?.ids?.includes(id);
  };

  const launchQualifiedResourcesSearch = () => {
    const availableQuantity = resourceSlot.resources.filter((rs) => !rs._id).length;
    const readOnly = !!resourceSlot.documents;
    slide(
      <QualifiedResourcesPanelTemplate
        onClose={() => removeSlide()}
        onSuccess={(newResources) => {
          const positions: ResourcePosition[] = [];
          const resourceIds: string[] = [];
          const updatedResources = resources.map((resource, resourceIndex) => {
            const { customId, ...rest } = resource;
            if (resource._id) return rest;
            const newResource = newResources.shift();
            if (!newResource) return {};
            positions.push({ slotIndex: index, resourceIndex });
            resourceIds.push(newResource._id);
            const { _id, label, picture } = transformResource(newResource);
            return { _id, label, picture, qualified: true };
          });

          setValue(`resourceSlots.${index}.resources`, updatedResources, {
            shouldDirty: true
          });
          dispatch({
            type: 'ADD_RESOURCES',
            positions,
            resourceIds
          });

          removeSlide();
        }}
        qualifiedQuery={{
          ...(resourceSlot.documents
            ? { documents: resourceSlot.documents.map((doc) => doc._id) }
            : {}),
          ...(resourceSlot.restrictLocation
            ? { location: getValues(`properties.${SystemProperties.EVENTS_LOCATION}`)._id }
            : {}),
          exactPeriod: {
            start: getValues(`properties.${SystemProperties.EVENTS_START_TIMESTAMP}`),
            end: getValues(`properties.${SystemProperties.EVENTS_END_TIMESTAMP}`)
          }
        }}
        resourceSlot={resourceSlot}
        availableQuantity={availableQuantity}
        readOnly={readOnly}
      />,
      'left'
    );
  };

  const [open, setOpen] = useState(true && !disabled);
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        display="flex"
        alignItems="center"
        px={'0.5rem'}
        sx={{
          backgroundColor: theme.palette.background.default,
          borderRadius: '5px',
          cursor: 'pointer'
        }}
        onClick={() => !disabled && setOpen(!open)}>
        <RenderIf condition={!disabled}>
          {open ? <MdOutlineExpandLess size={24} /> : <MdOutlineExpandMore size={24} />}
        </RenderIf>
        <ResourceSlotCard isSelected={false} resourceSlot={resourceSlot} roles={roles} />
        <Tooltip title={jobEventPanelTemplate('showQualified')}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              launchQualifiedResourcesSearch();
            }}>
            <FaFileCircleCheck />
          </IconButton>
        </Tooltip>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ResourceList>
          {resources.map((resource, idx) => {
            return (
              <Box key={resource.customId} display="flex" alignItems="center" flex="1">
                <RenderIf condition={!resource._id && !resourceSlot.documents}>
                  <ResourceSearch
                    onChange={(resource: ResourceDto) => {
                      const { _id, label, picture } = transformResource(resource);
                      updateResource(idx, {
                        _id,
                        label,
                        picture,
                        qualified: isQualified(resource._id) ?? true
                      });
                      dispatch({
                        type: 'ADD_RESOURCE',
                        position: { slotIndex: index, resourceIndex: idx },
                        resourceId: resource._id
                      });
                    }}
                    qualifiedIds={qualifiedResources}
                    isLoading={isLoadingQualifiedResources}
                  />
                </RenderIf>
                <RenderIf condition={!resource._id && !!resourceSlot.documents}>
                  <EmptyResource />
                </RenderIf>
                <RenderIf condition={!!resource._id}>
                  <ResourceContainer>
                    <RenderIf condition={!isLoadingQualifiedResources}>
                      {isQualified(resource._id) ? (
                        <Tooltip title={jobEventPanelTemplate('qualified')} placement="top">
                          <Box display="flex" alignItems="center">
                            <IoIosCheckmarkCircle size={19.2} color={theme.palette.success.main} />
                          </Box>
                        </Tooltip>
                      ) : (
                        <Tooltip title={jobEventPanelTemplate('notQualified')} placement="top">
                          <Box display="flex" alignItems="center">
                            <BiSolidErrorCircle size={19.2} color={theme.palette.error.main} />
                          </Box>
                        </Tooltip>
                      )}{' '}
                    </RenderIf>
                    {isLoadingQualifiedResources && (
                      <Tooltip placement="top" title={tCommon('text.loading')}>
                        <Box display="flex" alignItems="center">
                          <Skeleton variant="circular" width="1.2rem" height="1.2rem" />
                        </Box>
                      </Tooltip>
                    )}
                    {resource.picture ? (
                      <ResourceThumbnail src={resource.picture} />
                    ) : (
                      <Avatar
                        color={theme.palette.primary.main}
                        style={{ maxWidth: '1.75rem', maxHeight: '1.75rem' }}
                      />
                    )}
                    <Typography
                      sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                      color={
                        errors.resourceSlots?.[index]?.resources?.[idx]
                          ? theme.palette.error.main
                          : ''
                      }>
                      {resource.label}
                    </Typography>
                    <IconButton
                      sx={{ marginRight: '0.4rem', marginLeft: 'auto' }}
                      onClick={() => {
                        updateResource(idx, {});
                        clearErrors(`resourceSlots.${index}.resources.${idx}`);
                        dispatch({
                          type: 'REMOVE_RESOURCE',
                          position: { slotIndex: index, resourceIndex: idx },
                          resourceId: resource._id
                        });
                      }}>
                      <FaTrash fontSize="small" />
                    </IconButton>
                  </ResourceContainer>
                </RenderIf>
              </Box>
            );
          })}
        </ResourceList>
      </Collapse>
    </Box>
  );
};

export default ResourceSlot;
