import { InputBox, RenderIf } from '@/components/atoms';
import { DaysValuesType, getDaysOfWeek } from '@/const';
import { PossibleValues, isMultipleInputValue } from '@/types';
import { Box, FormControlProps, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Circle } from './WeekdayInput.styles';

export type WeekdayInputProps = {
  onChange: (value: DaysValuesType[] | null) => void;
  label?: string;
  asInput?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  helperText?: string;
  placeholder?: string;
  name?: string;
} & Omit<FormControlProps, 'onChange' | 'children'> &
  PossibleValues<DaysValuesType[] | null>;

const WeekdayInput = ({
  value,
  onChange,
  label,
  includeMultipleObj,
  asInput = true,
  disabled = false,
  error,
  placeholder,
  readOnly,
  helperText,
  name,
  id
}: WeekdayInputProps) => {
  const days = useMemo(() => getDaysOfWeek(), []);
  const { t: tCommon } = useTranslation();

  const handleClickDay = useCallback(
    (day: DaysValuesType) => {
      if (isMultipleInputValue(value)) {
        onChange([day]);
      } else if (value && value.includes(day)) {
        const newVal = value.filter((valueDay) => valueDay !== day);
        onChange(newVal.length > 0 ? newVal : null);
      } else {
        onChange(value ? [day, ...value] : [day]);
      }
    },
    [value]
  );

  return asInput ? (
    <InputBox id={id} name={name} disabled={disabled} label={label} error={error}>
      <Box display="flex" gap="0.3rem">
        {days.map((day, index) => (
          <Circle
            sx={{
              height: '1rem',
              minHeight: '1rem',
              maxHeight: '1rem',
              width: '1rem',
              minWidth: '1rem',
              maxWidth: '1rem',
              padding: '0.75rem'
            }}
            key={index}
            variant={
              !isMultipleInputValue(value) && value?.includes(day.value) ? 'contained' : 'text'
            }
            disabled={disabled}
            onClick={() => handleClickDay(day.value)}>
            <Typography sx={{ paddingTop: '1px' }} variant="body2">
              {tCommon(`days.short.${day.name}`)}
            </Typography>
          </Circle>
        ))}
      </Box>
      <RenderIf condition={includeMultipleObj && isMultipleInputValue(value)}>
        <OutlinedInput
          readOnly
          placeholder={tCommon('text.multipleValues')}
          sx={{
            '.MuiOutlinedInput-notchedOutline': { border: 'none' }
          }}
        />
      </RenderIf>
      <RenderIf condition={!!helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </RenderIf>
    </InputBox>
  ) : (
    <Box display="flex" gap="0.3rem">
      {days.map((day, index) => (
        <Circle
          key={index}
          variant={
            value && !isMultipleInputValue(value) && value.includes(day.value)
              ? 'contained'
              : 'text'
          }
          disabled={disabled}
          onClick={() => handleClickDay(day.value)}>
          <Typography sx={{ paddingTop: '1px' }} variant="body2">
            {tCommon(`days.short.${day.name}`)}
          </Typography>
        </Circle>
      ))}
    </Box>
  );
};

export default WeekdayInput;
