import { EntityFilter, Filter } from '@/components/organisms';
import { ButtonFilter } from '@/components/organisms/GlobalFilter/GlobalFilter.styles';
import { getGridConfig } from '@/components/templates/DocumentsTemplate/DocumentsTemplate.const';
import {
  COLLECTIONS_BATCH_DELETE,
  CrudModes,
  DelayTimes,
  ENTITY_TYPES_BY_ENTITY,
  Entities,
  EntityRoutes,
  Filters,
  PROPERTIES_BY_ENTITY
} from '@/const';
import { useFilterContext } from '@/context/FilterContext';
import { useModalConfirmationContext } from '@/context/ModalConfirmationContext';
import { useToastContext } from '@/context/ToastContext';
import { useGet, useGetFilteredData } from '@/hooks';
import useCustomBryntumSorter from '@/hooks/useCustomBryntumSorter';
import { setLoading } from '@/services/utils/utils';
import {
  BryntumCellMenuContext,
  DbClickEvent,
  DocumentDto,
  EntityTypeDto,
  FieldFilterType,
  GridNames,
  PropertyDto,
  RecordDocumentDto,
  RecordDto,
  ResourceBryntum,
  ResourceDto
} from '@/types';
import {
  debounce,
  defaultMenuContextOptions,
  isActiveResourceRecords,
  loadState,
  saveState
} from '@/utils';
import { ContainerItemConfig, Menu, Model, Store } from '@bryntum/core-thin';
import { BryntumGrid } from '@bryntum/grid-react-thin';
import { ColumnStore, Grid } from '@bryntum/grid-thin';
import { Unstable_Grid2 as GridMUI, Skeleton, Tooltip, Typography } from '@mui/material';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { extraFields, getColumns, transformRecords } from './RecordListView.const';
import { BordererGridContainer, GridHeader, OuterContainer } from './RecordListView.styles';

export type RecordListViewProps = {
  isActive: boolean;
  resource: ResourceDto;
  openRecord: (
    mode: CrudModes,
    document: RecordDocumentDto | undefined,
    recordId: string | undefined,
    onSucess: (record: any) => unknown
  ) => unknown;
};

const RecordListView = ({ isActive, openRecord, resource }: RecordListViewProps) => {
  const { size } = useFilterContext('');
  const bryntumRef = useRef<BryntumGrid>(null);
  const { t: tResourcePanel } = useTranslation('templates/resourcePanelTemplate');
  const { setToastNotifications } = useToastContext();
  const { t: tCommon } = useTranslation();
  const { showConfirmation } = useModalConfirmationContext();
  const newSorter = useCustomBryntumSorter({
    gridRef: bryntumRef,
    refName: GridNames.RESOURCERECORDS
  });
  const localDocumentsFilters = loadState<FieldFilterType[]>(Filters.RESOURCERECORDS) || [];
  const [resourceRecordsFilters, setResourceRecordsFilters] =
    useState<FieldFilterType[]>(localDocumentsFilters);

  const handleRecordsFilters = (form: FieldFilterType[]) => {
    setResourceRecordsFilters(form);
    saveState(Filters.RESOURCERECORDS, form);
  };

  const { settings: resourceRecordsSettings, setSettings: setResourceRecordsSettings } =
    useFilterContext('resourcerecords');

  const activeFilters = isActiveResourceRecords();

  const { data: properties, error: propertiesError } = useGet<PropertyDto[]>(
    {
      url: PROPERTIES_BY_ENTITY.replace(':entity_name', 'resourcerecords')
    },
    { revalidateOnMount: true }
  );
  useEffect(() => {
    getColumns(bryntumRef, tCommon, properties);
  }, [properties]);

  const applyStateHandler = useCallback(() => {
    const gridRef = bryntumRef.current?.instance as Grid;
    const state = JSON.parse(localStorage.getItem(GridNames.RESOURCERECORDS) || '{}');
    if (state) {
      gridRef.state = state;
    }
  }, []);

  useEffect(() => {
    if (properties && properties.length != 0) {
      applyStateHandler();
    }
  }, [properties]);

  const { data: resourceRecords, loading, fetchByResourceRecords, error } = useGetFilteredData();

  useEffect(() => {
    if (error || propertiesError) {
      setToastNotifications([
        {
          message: tCommon('errors.loadingEntityList', {
            entity: tCommon('entities.resourcerecords_other')
          })
        }
      ]);
    }
  }, [error, propertiesError]);

  useEffect(() => {
    properties && fetchByResourceRecords(resourceRecordsFilters, [resource._id], newSorter);
  }, [isActive, properties, resourceRecordsFilters, newSorter]);

  useEffect(() => {
    loading && setLoading(bryntumRef);
  }, [loading]);

  const headerMenuFeature = useMemo(() => {
    return {
      processItems({ items }) {
        if (!loading && !propertiesError) {
          items.groupAsc = false;
          items.groupDesc = false;
        } else {
          items.columnPicker = false;
          items.groupAsc = false;
          items.groupDesc = false;
          items.hideColumn = false;
          items.multiSort = false;
          items.sortAsc = false;
          items.sortDesc = false;
        }
      }
    };
  }, [loading, propertiesError]);

  const renderRecords = useMemo(() => {
    return transformRecords(resourceRecords?.records);
  }, [properties, resourceRecords]);

  const [rowsNumber, setRowsNumber] = useState<number>();

  useEffect(() => {
    if (resourceRecords && bryntumRef) {
      setRowsNumber(resourceRecords.count);
      if (bryntumRef.current && bryntumRef.current.element) {
        bryntumRef.current.element.onkeydown = (ev) => {
          if (ev.ctrlKey && ev.key == 'a') {
            bryntumRef.current?.instance.selectAll();
            ev.stopPropagation();
            ev.preventDefault();
          }
        };
      }
    }
  }, [resourceRecords, bryntumRef]);

  useEffect(() => {
    if (resourceRecords && bryntumRef) {
      const columnStore: ColumnStore = (bryntumRef as RefObject<BryntumGrid>).current?.instance
        .columns as ColumnStore;
      if (columnStore && columnStore.getById('counter')) {
        columnStore.getById('counter').text = renderToString(
          <GridMUI display={'flex'} flexWrap="wrap">
            {tCommon('text.count')}
            <Typography>{tCommon('number', { value: rowsNumber ?? 0 })}</Typography>
          </GridMUI>
        );
      }
    }
  }, [rowsNumber, bryntumRef]);

  const gridConf = useMemo(() => {
    return getGridConfig({
      columns: {
        data: new Array(4).fill('').map((element) => ({
          text: '...',
          width: 220,
          flex: 1,
          renderer: () => {
            return <Skeleton height={30} />;
          }
        }))
      }
    });
  }, []);

  const { data: types, loading: isLoadingDocuments } = useGet<EntityTypeDto[]>({
    url: ENTITY_TYPES_BY_ENTITY.replace(':entity', Entities.DOCUMENTS)
  });

  const removeRow = useCallback((id: string) => {
    ((bryntumRef.current?.instance as Grid).store as Store).remove(id);
    setRowsNumber((beforeVal) => (beforeVal ?? 0) - 1);
  }, []);
  const insertRow = useCallback(
    async (row) => {
      const gridStore = (bryntumRef.current?.instance as Grid).store as Store;
      const gridRef = bryntumRef.current?.instance as Grid;
      const record: ResourceBryntum = transformRecords([row])[0];
      const newRow: Model[] = gridStore.insert(0, record);
      gridRef.selectedRecord = newRow[0];
      gridRef.scrollToTop();
      setRowsNumber((beforeVal) => (beforeVal ?? 0) + 1);
    },
    [types]
  );
  const updateRow = useCallback(
    async (row) => {
      const gridRef = bryntumRef.current?.instance as Grid;
      const record: ResourceBryntum = transformRecords([row])[0];
      const data: Model = ((bryntumRef.current?.instance as Grid).store as Store).getById(row._id);
      properties?.forEach((prop) => {
        data.set(prop._id, record[prop._id]);
      });
      gridRef.selectedRecord = data;
    },
    [properties]
  );

  const menu = useMemo(() => {
    return new Menu({
      autoShow: false,
      items: [
        {
          text: tResourcePanel('actions.createRecord'),
          icon: 'b-icon b-fa-circle-plus',
          disabled: error || propertiesError,
          onItem: () => {
            openRecord(CrudModes.CREATE, undefined, undefined, (record) => insertRow(record));
          }
        },
        {
          text: `${tCommon('actions.selectAll')}`,
          icon: 'b-icon b-fa-list-check',
          disabled: error || propertiesError,
          cls: 'b-separator',
          onItem: async (ev) => {
            const gridRef = bryntumRef.current?.instance as Grid;
            gridRef.selectAll();
          }
        } as Partial<ContainerItemConfig>
      ] as Partial<ContainerItemConfig>[]
    });
  }, [types, propertiesError, error]);

  useEffect(() => {
    if (bryntumRef && (bryntumRef as MutableRefObject<BryntumGrid>).current && types && isActive) {
      ((bryntumRef as MutableRefObject<BryntumGrid>).current.instance.subGrids as any)[
        'normal'
      ].currentElement.addEventListener('contextmenu', (ev: any) => {
        if (
          bryntumRef &&
          (bryntumRef as MutableRefObject<BryntumGrid>).current &&
          ev.target.$refOwnerId
        ) {
          ev.preventDefault();
          menu.showBy([ev.clientX, ev.clientY]);
        }
      });
      const handleScroll = debounce(() => {
        const gridRef = bryntumRef.current?.instance as Grid;
        localStorage.setItem(GridNames.RESOURCERECORDS, JSON.stringify(gridRef.state));
      }, DelayTimes.DEBOUNCE_ACTION);
      const currentElement = (
        (bryntumRef as MutableRefObject<BryntumGrid>).current.instance.subGrids as any
      )['normal'].currentElement;

      currentElement.addEventListener('scroll', handleScroll);
      const columnStore: ColumnStore = (bryntumRef as RefObject<BryntumGrid>).current?.instance
        .columns as ColumnStore;

      columnStore.addListener('update', (e) => {
        const gridRef = bryntumRef.current?.instance as Grid;
        localStorage.setItem(GridNames.RESOURCERECORDS, JSON.stringify(gridRef.state));
      });
    }
  }, [types, isActive, menu]);

  const gridCellMenuFeature = useMemo(() => {
    return {
      processItems({ items, selection }: BryntumCellMenuContext) {
        if (!isLoadingDocuments && !error && !propertiesError) {
          defaultMenuContextOptions(items);
          items.createRecord = {
            text: tResourcePanel('actions.createRecord'),
            icon: 'b-icon b-fa-circle-plus',
            onItem: (ev) => {
              openRecord(CrudModes.CREATE, undefined, undefined, (record) => insertRow(record));
            }
          };
          items.editRecord = {
            text: tResourcePanel('actions.editRecord'),
            icon: 'b-icon b-fa-pen-to-square',
            disabled: selection.length > 1,
            onItem: (ev) => {
              const documentRecord: RecordDocumentDto = ev?.record?.getData('record').document;
              if (documentRecord && ev?.record?.id)
                openRecord(CrudModes.EDIT, documentRecord, ev.record.id.toString(), (record) =>
                  updateRow(record)
                );
            }
          };
          items.deleteRecord = {
            text: tResourcePanel('actions.deleteRecords', {
              count: selection.length
            }),
            icon: 'b-icon b-fa-trash-can',
            onItem: async (ev) => {
              const itemsToDelete: string[] = selection.map((selection) => selection.id.toString());

              if (itemsToDelete && itemsToDelete.length >= 1) {
                const result: DocumentDto = await showConfirmation({
                  title: tCommon('modalConfirmationDelete.title'),
                  message: tCommon('modalConfirmationDelete.message', {
                    count: itemsToDelete.length,
                    number: itemsToDelete.length,
                    element: tCommon('entities.resourcerecords', {
                      count: itemsToDelete.length
                    })
                  }),
                  confirmButton: tCommon('buttons.delete'),
                  cancelButton: tCommon('buttons.cancel'),
                  fetchData: {
                    url: COLLECTIONS_BATCH_DELETE.replace(
                      ':collection_name',
                      EntityRoutes.RESOURCE_RECORDS
                    ),
                    method: 'POST',
                    data: { ids: itemsToDelete }
                  }
                });
                if (result) {
                  itemsToDelete.forEach((id) => removeRow(id));
                }
              }
            }
          };
          items.selectAllResources = {
            text: `${tCommon('actions.selectAll')}`,
            icon: 'b-icon b-fa-list-check',
            cls: 'b-separator',
            onItem: async (ev) => {
              const gridRef = bryntumRef.current?.instance as Grid;
              gridRef.selectAll();
            }
          };
        } else {
          defaultMenuContextOptions(items);
        }
      }
    };
  }, [isLoadingDocuments, error, propertiesError, insertRow, updateRow, removeRow]);

  const onCellDblClick = useCallback(
    (ev: DbClickEvent) => {
      const record: RecordDto | undefined = ev.record.getData('record');
      if (!isLoadingDocuments && record && record.document && ev.record.id && updateRow)
        openRecord(CrudModes.EDIT, record.document, ev.record.id.toString(), (record) =>
          updateRow(record)
        );
    },
    [isLoadingDocuments, updateRow]
  );

  const headerRef = useRef<HTMLDivElement>(null);
  const propertiesRef = useRef(null);

  const [openFilter, setOpenFilter] = useState(false);

  return (
    <>
      <OuterContainer>
        <GridHeader ref={headerRef}>
          <Tooltip
            title={`${tCommon('filters.title', {
              entity: tCommon('entities.resourcerecords_other')
            })}`}>
            <ButtonFilter
              letter=""
              $isactive={activeFilters}
              onClick={(e) => {
                setOpenFilter((before) => !before);
              }}>
              <i className="b-icon b-icon-filter" />
            </ButtonFilter>
          </Tooltip>
          <Typography variant="body2" paddingY={2} lineHeight={1}>
            <strong>{tCommon('utils.note')}</strong> {tResourcePanel('recordTab.note')}
          </Typography>
        </GridHeader>
        <BordererGridContainer>
          <BryntumGrid
            rowCopyPasteFeature={false}
            emptyText={tResourcePanel('emptyRecords')}
            cls={'b-custom-full-border'}
            ref={bryntumRef}
            {...gridConf}
            data={renderRecords}
            headerMenuFeature={headerMenuFeature}
            cellMenuFeature={gridCellMenuFeature}
            onCellDblClick={onCellDblClick}
          />
        </BordererGridContainer>
      </OuterContainer>
      <Filter
        open={openFilter}
        toggleFilter={() => {
          setOpenFilter((before) => !before);
        }}
        refs={[propertiesRef]}
        title={`${tCommon('filters.title', {
          entity: tCommon('entities.resourcerecords_other')
        })}`}>
        <EntityFilter
          entity={Entities.RESOURCERECORDS}
          title={tCommon('filters.byProperties')}
          extraFields={extraFields}
          onSuccess={handleRecordsFilters}
          defaultValues={resourceRecordsFilters}
          selected={resourceRecordsSettings}
          setSelected={setResourceRecordsSettings}
          propertiesRef={propertiesRef}
        />
      </Filter>
    </>
  );
};

export default RecordListView;
