import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ScheduleResultContainer = styled('div')`
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;

  .fade-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-enter-active {
    opacity: 1 !important;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .fade-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }
`;

export const BigTitle = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const CardsContainer = styled('div')`
  display: grid;
  grid-gap: 16px;
  padding: 16px 0;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-auto-flow: column;
  grid-auto-columns: minmax(10rem, 1fr);
  flex: 0 0 auto;
  width: '100%';
  align-self: center;
`;

export const CardsOuterContainer = styled('div')`
  display: flex;
  flex: 0 0 min-content;
  max-width: 100%;
`;

export const CardsInnerContainer = styled('div')`
  display: flex;
  overflow-x: auto;
  width: min-content;
`;
