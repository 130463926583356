import { Permission } from '@/components/templates/EntityPanels/RolePanelTemplate/RolePanelTemplate.schema';
import { useAuthContext } from '@/context/AuthContext';
import { ReactNode, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../Loading';

type ProtectedRouteProps = {
  children: ReactNode;
  reverse?: boolean;
  necessaryPermission?: Permission;
};

const ProtectedRoute = ({
  children,
  reverse = false,
  necessaryPermission
}: ProtectedRouteProps) => {
  const { user, loading, userPermissions, errorPermissions, errorUserResource } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && reverse && userPermissions) {
      const intendedDestination = sessionStorage.getItem('intended-destination');
      if (intendedDestination && intendedDestination != 'null') {
        navigate(intendedDestination);
      } else if (userPermissions.includes(Permission.MainFullAccess)) {
        navigate('/main');
      } else if (userPermissions.includes(Permission.MyCalendarFullAccess)) {
        navigate('/my-calendar');
      } else {
        navigate('/main');
      }
      sessionStorage.removeItem('intended-destination');
    }
  }, [userPermissions, user]);

  useEffect(() => {
    if (errorPermissions || errorUserResource) {
      navigate('/unauthorized');
    }
  }, [errorPermissions, errorUserResource]);

  if (loading) return <Loading />;
  if (!user && !reverse) {
    if (
      location.pathname != '/unauthorized' &&
      sessionStorage.getItem('intended-destination') != 'null'
    ) {
      sessionStorage.setItem('intended-destination', location.pathname);
    }
    return <Navigate to={'/login'} />;
  } else if (
    !loading &&
    necessaryPermission &&
    userPermissions &&
    !userPermissions.includes(necessaryPermission)
  ) {
    return <Navigate to={'/unauthorized'} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
