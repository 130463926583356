import { ComponentType, createContext, CSSProperties } from 'react';

export type GenericModalContextType = {
  isOpen: boolean;
  handleClose: (isDirty?: boolean, modalName?: string) => unknown;
  handleDirtyModal: (isDirty: boolean, modalName?: string) => unknown;
  preventFromClose: (prevent: boolean) => unknown;
  initialize: (
    modalContent: JSX.Element | ComponentType<any>,
    modalProperties?: { style?: CSSProperties; modalName?: string }
  ) => unknown;
  slide: (
    modalContent: JSX.Element | ComponentType<any>,
    direction: 'left' | 'right',
    modalProps?: {
      style?: CSSProperties;
      modalName?: string;
    }
  ) => unknown;
  removeSlide: (isDirty?: boolean, modalName?: string, idx?: number) => void;
};

export default createContext<GenericModalContextType>({} as GenericModalContextType);
