import { RenderIf } from '@/components/atoms';
import { DATETIME_FORMAT_PICKER, TIME_FORMAT_PICKER, locale } from '@/const';
import { sortAlphaArray } from '@/services/utils/utils';
import { theme } from '@/theme';
import { EventBryntum } from '@/types';
import { Divider, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePaperClip, AiTwotoneCalendar } from 'react-icons/ai';
import { IoLocationSharp } from 'react-icons/io5';

export type BryntumHintProps = {
  job?: string[];
  resources?: ReactNode[];
  location?: string;
  additionalHeaderRender?: ReactNode;
  additionalFooterRender?: ReactNode;
} & Pick<EventBryntum, 'startDate' | 'endDate'>;

const BryntumHint = ({
  startDate,
  endDate,
  resources,
  job,
  location,
  additionalFooterRender,
  additionalHeaderRender
}: BryntumHintProps) => {
  const { t } = useTranslation('templates/mainTemplate');
  return (
    <>
      <RenderIf condition={!!startDate && !!endDate}>
        <div className="b-hint-resource">
          <AiTwotoneCalendar color={theme.palette.primary.main} size={20} />
          <Typography variant="body1">
            {`${DateTime.fromJSDate(startDate)
              .setLocale(locale)
              .toFormat(DATETIME_FORMAT_PICKER)} - ${DateTime.fromJSDate(endDate)
              .setLocale(locale)
              .toFormat(
                DateTime.fromJSDate(startDate).toISODate() ===
                  DateTime.fromJSDate(endDate).toISODate()
                  ? (TIME_FORMAT_PICKER as string)
                  : (DATETIME_FORMAT_PICKER as string)
              )}`}
          </Typography>
        </div>
      </RenderIf>
      <RenderIf condition={location != ''}>
        <div className="b-hint-resource">
          <IoLocationSharp color={theme.palette.primary.main} size={20} />
          <Typography variant="body1">{location}</Typography>
        </div>
      </RenderIf>
      <RenderIf condition={!!job && (job as string[]).length > 0 && job[0] != ''}>
        <div className="b-hint-resource">
          <AiOutlinePaperClip color={theme.palette.primary.main} size={20} />
          <Grid display={'flex'} flexDirection={'column'}>
            {Array.isArray(job) ? (
              sortAlphaArray(job)
                .slice(0, 4)
                .map((jobElement, idx) => (
                  <Typography key={idx} variant="body1">
                    {jobElement}
                  </Typography>
                ))
            ) : (
              <Typography key={job} variant="body1">
                {job}
              </Typography>
            )}
            {Array.isArray(job) && job.length > 4 && (
              <Typography variant="body1">
                {job.length == 5 ? job[4] : t('text.jobsJoined', { jobs: job.length - 4 })}
              </Typography>
            )}
          </Grid>
        </div>
      </RenderIf>
      {additionalHeaderRender}
      {resources != undefined && resources.length > 0 && (
        <>
          <Divider />
          {resources.map((node, idx) => (
            <Fragment key={idx}>{node}</Fragment>
          ))}
        </>
      )}
      {!!additionalFooterRender && (
        <>
          <Divider />
          {additionalFooterRender}
        </>
      )}
    </>
  );
};

export default BryntumHint;
