import { Transition } from '@/components/atoms';
import { BryntumHint, Loading } from '@/components/molecules';
import { DeletionSeries, EntityFilter, FilterByRecord } from '@/components/organisms';
import Filter from '@/components/organisms/Filter/Filter';
import {
  EventPanelTemplate,
  JobEventPanelTemplate,
  ResourceImportPanelTemplate,
  ResourcePanelTemplate
} from '@/components/templates/EntityPanels';
import { ResourceCalendarPanelTemplate } from '@/components/templates/ResourceCalendarPanelTemplate';
import {
  ADVANCED_SEARCH,
  COLLECTIONS_BATCH_DELETE,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  ENTITY_TYPES_BY_ENTITY,
  EVENTS_BY_RESOURCES,
  EVENTS_REMOVE_RESOURCES,
  Entities,
  EntityRoutes,
  FILES_URL_BY_ID,
  Filters,
  RESOURCE_LABEL_PROP_IDS,
  SystemFilters,
  SystemProperties
} from '@/const';
import { CalendarContextProvider } from '@/context/CalendarContext';
import { useFilterContext } from '@/context/FilterContext';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useJobContext } from '@/context/JobsContext';
import { useModalConfirmationContext } from '@/context/ModalConfirmationContext';
import { useResourceContext } from '@/context/ResourceContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch, useGet, useGetFilteredData } from '@/hooks';
import useCustomBryntumSorter from '@/hooks/useCustomBryntumSorter';
import { useGetDocuments } from '@/hooks/useGetDocuments';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import useWindowUnloadEffect from '@/hooks/useWindowOnLoad';
import {
  checkIfAllValuesAreRepeated,
  equalResources,
  loadStateConfigBryntum,
  saveStateConfigBryntum,
  setLoading,
  setLoadingTimeAxis
} from '@/services/utils/utils';
import { theme } from '@/theme';
import {
  AssignmentBryntum,
  AssignmentBryntumPro,
  BryntumCellMenuContext,
  BryntumEventMenuContext,
  BryntumEventMenuContextPro,
  EntityTypeDto,
  EventBryntum,
  EventDto,
  EventProBryntum,
  EventTooltipFeatureData,
  FieldFilterType,
  JobDto,
  PropertyDto,
  RemoveResourcesDto,
  ResourceBryntum,
  ResourceDto,
  ResourceFilterByRecords,
  ResourceQueryResult,
  SchedulerNames,
  SpecialFilterTypes,
  UpdatedEvents,
  ZoomLevels
} from '@/types';
import {
  defaultEventMenuContextOptions,
  defaultMenuContextOptions,
  getCommonSchedulerProConfig,
  getEventLocation,
  getPropertyValueDef,
  loadState,
  replaceTo,
  saveState,
  transformToAssignmentBryntumPro,
  transformToEventProBryntum
} from '@/utils';
import { Model, Menu, Store, StringHelper } from '@bryntum/core-thin';
import { Column, ColumnStore, Grid } from '@bryntum/grid-thin';
import { EventStore, SchedulerPro, AssignmentStore } from '@bryntum/schedulerpro-thin';

import { AssignmentModel, EventModel, Scheduler } from '@bryntum/scheduler-thin';

import { ScheduleMenuConfig } from '@bryntum/calendar-thin';
import { BryntumSchedulerPro, BryntumSchedulerProProps } from '@bryntum/schedulerpro-react-thin';

import { Dialog, Grid as GridMUI, Typography } from '@mui/material';
import { t } from 'i18next';
import { isArray } from 'lodash';
import { DateTime } from 'luxon';
import {
  ForwardedRef,
  MutableRefObject,
  RefObject,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { ScheduleContainer } from '../../MainTemplate.styles';
import { getAssignmentsFromEventsPro as getAssignmentsFromJobEvents } from '../SchedulerJobs/SchedulerJobs.const';
import {
  getAssignmentsFromEvents,
  getColumns,
  transformResourcesToScheduler
} from './SchedulerResourcesPro.const';

export type SchedulerResourcesProProps = {
  open: boolean;
  jobsFilter?: string[];
  resourcesFetchTrigger?: Date;
  resourcesFilterSetter: (newResources) => unknown;
  deleteCommonEvent: (eventId: string) => unknown;
  fullText: string | undefined;
  masterDetail: number;
  toggleFilter: () => void;
  updateEvents: (
    scheduler: SchedulerNames,
    events: EventProBryntum[],
    assignments: AssignmentBryntumPro[],
    silent?: boolean
  ) => void;
  triggerScheduler: (scheduler: SchedulerNames) => void;
  onEditEvents: (editedEvents: EventDto[], silent: boolean) => unknown;
};

const SchedulerProResources = (
  {
    open,
    jobsFilter,
    resourcesFilterSetter,
    resourcesFetchTrigger,
    fullText,
    deleteCommonEvent,
    masterDetail,
    toggleFilter,
    updateEvents,
    triggerScheduler,
    onEditEvents
  }: SchedulerResourcesProProps,
  ref: ForwardedRef<BryntumSchedulerPro>
) => {
  const { t: tMainTemplate } = useTranslation('templates/mainTemplate');
  const { t: tCommon } = useTranslation();
  const propertiesRef = useRef<HTMLButtonElement | null>(null);
  const documentsRef = useRef<HTMLButtonElement | null>(null);
  const [isOpenCalendar, setIsOpenCalendar] = useState<any>();
  const [dirtyEvent, setDirtyEvent] = useState<boolean>(false);

  const extraFields: FieldFilterType[] = useMemo(
    () => [
      {
        name: 'Type',
        _id: SystemFilters.ENTITY_TYPE,
        type: SpecialFilterTypes.CUSTOM_SET,
        order: 0,
        referencedSet: 'resourceTypes',
        value: []
      },
      {
        name: tCommon('entities.missions_other'),
        _id: SystemFilters.MISSIONS,
        type: SpecialFilterTypes.CUSTOM_SET,
        referencedSet: SystemFilters.MISSIONS,
        value: []
      },
      {
        name: tCommon('entities.work-rules_other'),
        _id: SystemFilters.WORK_RULES,
        type: SpecialFilterTypes.CUSTOM_SET,
        referencedSet: SystemFilters.WORK_RULES,
        value: []
      }
    ],
    []
  );

  const { settings: resourceSettings, setSettings: setResourceSettings } = useFilterContext(
    Entities.RESOURCES
  );

  const { fetch: fetchRemove, error: errorRemove } = useFetch();

  useEffect(() => {
    if (errorRemove) {
      setToastNotifications([{ message: tMainTemplate('errors.loadingRemovedAssignments') }]);
    }
  }, [errorRemove]);

  const { properties, loading: loadingProperties } = useGetPropertiesByEntity<PropertyDto[]>(
    Entities.RESOURCES,
    undefined,
    (data) => setResourceFilters(data)
  );

  const localResourceFilters: FieldFilterType[] = useMemo(
    () => loadState<FieldFilterType[]>(Filters.RESOURCES) || [],
    [properties]
  );

  const [resourceFilters, setResourceFilters] = useState<FieldFilterType[]>(localResourceFilters);

  const handleResourceFilters = (form: FieldFilterType[]) => {
    setResourceFilters(form);
    saveState(Filters.RESOURCES, form);
  };

  const localResourceRecordFilters = loadState<ResourceFilterByRecords>(
    Filters.RESOURCES_BY_RECORDS
  ) || {
    documents: [],
    operation: 'or',
    selected: { _id: '', label: '' }
  };

  const [resourceRecordFilters, setResourceRecordFilters] = useState<ResourceFilterByRecords>(
    localResourceRecordFilters
  );

  const handleResourceRecordsFilters = (form: ResourceFilterByRecords) => {
    setResourceRecordFilters(form);
    saveState(Filters.RESOURCES_BY_RECORDS, form);
  };

  const { resources: localResources, addResources, deleteResource } = useResourceContext();
  const { jobs: localJobs, addJobs } = useJobContext();
  const { showConfirmation, handleCancel } = useModalConfirmationContext();
  const { initialize, handleClose, handleDirtyModal, preventFromClose } = useGenericModalContext();

  const handleCloseDeletionSeries = (selection?: string, eventInfo?: any) => {
    if (selection) {
      if (selection == 'thisAndFollowing' || selection == 'all') {
        eventInfo.forEach((event) => {
          removeEvent(event);
        });
      } else {
        removeEvent(eventInfo.assignmentRecord.data.eventId);
      }
    }
    handleCancel();
  };

  const eventDblClick = (event: {
    source: Scheduler;
    eventRecord: EventModel;
    assignmentRecord: AssignmentModel;
    event: globalThis.MouseEvent;
  }) => {
    if (!event.eventRecord.getData('job') && !event.assignmentRecord.getData('joined')) {
      initialize(
        <EventPanelTemplate
          mode={CrudModes.EDIT}
          eventId={event.assignmentRecord.getData('eventId')}
          resourceId={event.assignmentRecord.getData('resourceId')}
          onSuccess={(editedEvent) => {
            if (editedEvent) {
              updateEvent(editedEvent as EventDto);
              handleClose(false);
            }
          }}
          onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.events_one'))}
          onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
          onLoading={(loading) => preventFromClose(loading)}
        />,
        { modalName: tCommon('entities.events_one') }
      );
    } else
      initialize(
        <JobEventPanelTemplate
          mode={CrudModes.EDIT}
          eventId={event.assignmentRecord.getData('eventId')}
          resourceId={event.assignmentRecord.getData('resourceId')}
          onSuccess={(editedEvent, oldEvent) => {
            const silent = equalResources(editedEvent, oldEvent);
            onEditEvents([editedEvent], silent);
            handleClose(false);
          }}
          onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.events_one'))}
          onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
          onLoading={(loading) => preventFromClose(loading)}
        />,
        { modalName: tCommon('entities.events_one') }
      );
  };

  const { fetch: fetchDocuments, data: documents } = useGetDocuments();

  useEffect(() => {
    (async () => {
      const { documents } = await fetchDocuments(
        resourceRecordFilters.documents?.map((d) => d._id)
      );
      if (documents.length != resourceRecordFilters.documents.length) {
        const purgedRecords = localResourceRecordFilters.documents.filter((localDoc) =>
          documents.find((doc) => doc._id == localDoc._id)
        );
        saveState(Filters.RESOURCES_BY_RECORDS, {
          ...localResourceRecordFilters,
          documents: purgedRecords
        });
        setResourceRecordFilters({ ...localResourceRecordFilters, documents: purgedRecords });
      }
    })();
  }, []);

  const { loading: globalLoading, data: globalData, fetchByResources } = useGetFilteredData();

  const newSorter = useCustomBryntumSorter({
    gridRef: ref as MutableRefObject<BryntumSchedulerPro>,
    refName: SchedulerNames.RESOURCES
  });

  const fetchData = useCallback(() => {
    if (masterDetail == 2) {
      fetchByResources(resourceFilters, fullText, jobsFilter, resourceRecordFilters, newSorter);
      return;
    }
    if (masterDetail == 1 && Array.isArray(jobsFilter)) {
      fetchByResources(resourceFilters, fullText, jobsFilter, resourceRecordFilters, newSorter);
    }
    if (masterDetail == 0) {
      fetchByResources(resourceFilters, fullText, jobsFilter, resourceRecordFilters, newSorter);
    }
  }, [masterDetail, resourceFilters, fullText, jobsFilter, resourceRecordFilters, newSorter]);

  useEffect(() => {
    Array.isArray(documents?.documents) && properties && fetchData();
  }, [
    documents,
    properties,
    resourceFilters,
    fullText,
    jobsFilter,
    newSorter,
    resourcesFetchTrigger
  ]);

  useEffect(() => {
    const gridRef = ref as RefObject<BryntumSchedulerPro>;
    const gridRefInstance = gridRef.current?.instance;
    if (gridRefInstance) {
      if (gridRef && gridRef?.current && gridRef.current.element) {
        gridRef.current.element.onkeydown = (ev) => {
          if (ev.ctrlKey && ev.key == 'a') {
            gridRefInstance.selectAll();
            ev.stopPropagation();
            ev.preventDefault();
          }
        };
      }
    }
  }, []);

  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    const columnStore: ColumnStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance
      .columns as ColumnStore;
    if (columnStore && columnStore.getById('counter')) {
      columnStore.getById('counter').text = renderToString(
        <GridMUI display={'flex'} flexWrap="wrap">
          {tCommon('text.count')}
          <Typography>{tCommon('number', { value: counter })}</Typography>
        </GridMUI>
      );
    }
  }, [ref, counter]);

  const resources = useMemo(() => {
    if (globalData) {
      setCounter(globalData?.count || 0);
      return globalData?.resources;
    }
  }, [globalData]);

  useEffect(() => {
    if (resources) {
      resourcesFilterSetter(resources.map((resource) => resource._id));
    }
  }, [resources]);

  const [currentStartDate, setCurrentStartDate] = useState<Date>(
    DateTime.now().startOf('month').minus({ months: 1 }).toJSDate()
  );
  const [currentEndDate, setCurrentEndDate] = useState<Date>(
    DateTime.now().startOf('month').plus({ months: 1 }).endOf('month').toJSDate()
  );

  const filterDataResourcesEvents = useMemo(() => {
    if (resources) {
      const store = (
        (ref as MutableRefObject<BryntumSchedulerPro>).current.instance.subGrids as any
      ).locked.store as Store;
      if (store.allRecords.length > 0) {
        return {
          url: replaceTo(
            replaceTo(EVENTS_BY_RESOURCES, ':startDate', currentStartDate.toISOString()),
            ':endDate',
            currentEndDate.toISOString()
          ),
          method: 'POST',
          data:
            store.allRecords[0].id === 0
              ? resources.map(({ _id }) => _id)
              : store.allRecords.map(({ id }) => id)
        };
      } else {
        const eventStore = (ref as MutableRefObject<BryntumSchedulerPro>).current.instance
          .eventStore as EventStore;
        eventStore.removeAll();
      }
    }
  }, [resources, currentStartDate, currentEndDate]);

  const {
    data: resourcesEvents,
    error: errorResourceEvents,
    loading: loadingEvents
  } = useFetch<EventDto[]>(filterDataResourcesEvents);

  useEffect(() => {
    setLoadingTimeAxis(ref, loadingEvents);
  }, [loadingEvents]);

  useEffect(() => {
    if (errorResourceEvents) {
      setToastNotifications([{ message: tMainTemplate('errors.loadingResourceEvents') }]);
    }
  }, [errorResourceEvents]);

  const { data: resourceTypes } = useGet<EntityTypeDto[]>(
    {
      url: ENTITY_TYPES_BY_ENTITY.replace(':entity', Entities.RESOURCES)
    },
    { revalidateOnMount: true }
  );

  const { setToastNotifications } = useToastContext();

  const { fetch: fetchJobForHint, error: errorJobForHint } = useFetch<any>();
  const { fetch: fetchResourcesForHint, error: errorResourceForHint } =
    useFetch<ResourceQueryResult>();
  const { fetch: fetchFile, error: errorImage } = useFetch<string>();

  useEffect(() => {
    if (errorImage) {
      setToastNotifications([{ message: tCommon('errors.loadingImage') }]);
    }
  }, [errorImage]);

  useEffect(() => {
    if (errorJobForHint || errorResourceForHint) {
      setToastNotifications([{ message: tMainTemplate('errors.loadingHint') }]);
    }
  }, [errorJobForHint, errorResourceForHint]);

  const menu = useMemo(() => {
    const items = [
      {
        text: tMainTemplate('actions.createResource'),
        icon: 'b-icon b-fa-circle-plus',
        menu: resourceTypes
          ?.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }))
          .map(({ name, _id }) => {
            return {
              text: tCommon('actions.menu', { text: name }),
              onItem: () => {
                openModal(CrudModes.CREATE, '', { _id, label: name });
              }
            };
          })
      },
      {
        text: tMainTemplate('actions.importResources'),
        icon: 'b-icon b-fa-file-import',
        cls: 'b-separator',
        onItem: () => {
          initialize(
            <ResourceImportPanelTemplate
              onLoading={(loading) => {
                preventFromClose(loading);
              }}
              onLoadedData={handleDirtyModal}
              onSuccess={(_data) => {
                handleClose(false);
              }}
              onCancel={handleClose}
            />
          );
        }
      },
      {
        text: `${tCommon('actions.selectAll')}`,
        icon: 'b-icon b-fa-list-check',
        cls: 'b-separator',
        onItem: async () => {
          const instanceRef = (ref as RefObject<BryntumSchedulerPro>)?.current?.instance;
          instanceRef?.selectAll();
        }
      }
    ];
    return new Menu({
      autoShow: false,
      items
    });
  }, [resourceTypes]);

  useEffect(() => {
    if (ref && (ref as MutableRefObject<BryntumSchedulerPro>).current && resourceTypes) {
      const handleContextMenu = (ev) => {
        if ((ref as MutableRefObject<BryntumSchedulerPro>).current && ev.target.$refOwnerId) {
          ev.preventDefault();
          if (globalLoading || loadingProperties) {
            menu.hide();
          } else {
            menu.showBy([ev.clientX, ev.clientY]);
          }
        }
      };

      const instanceRefElem = (
        (ref as MutableRefObject<BryntumSchedulerPro>).current.instance.subGrids as any
      )['locked'].currentElement;
      instanceRefElem.addEventListener('contextmenu', handleContextMenu);

      return () => {
        instanceRefElem.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [resourceTypes, globalLoading, loadingProperties]);

  // JobsFilter is necessary here to update the fetchresources
  const onCellDblClick = useCallback(
    (ev: {
      grid: Grid;
      record: Model;
      column: Column;
      cellElement: HTMLElement;
      target: HTMLElement;
      event: globalThis.MouseEvent;
    }) => {
      if (
        !loadingProperties &&
        ev.column.getData('type') !== 'timeAxis' &&
        ev.record.getData('resource')
      ) {
        openModal(CrudModes.EDIT, ev.record.id.toString());
      }
    },
    [loadingProperties, jobsFilter]
  );

  const extraItems = {
    eventTooltipFeature: {
      allowOver: true,
      onBeforeShow({ source: tooltip }) {
        tooltip['title'] = StringHelper.encodeHtml(tooltip.eventRecord.name);
        tooltip['html'] = renderToString(<Loading />);
      },
      template: async ({
        eventRecord: { originalData }
      }: EventTooltipFeatureData<EventBryntum<EventDto>>) => {
        let jobs: JobDto[] | undefined;
        let resources: Record<string, ResourceDto> = {};
        const fill: ResourceDto['_id'][] = [];
        if (
          originalData.job &&
          checkIfAllValuesAreRepeated(originalData.job, Object.keys(localJobs))
        ) {
          jobs = originalData.job.map((originalJob) => localJobs[originalJob]);
        } else {
          if (originalData.job) {
            jobs = (
              await fetchJobForHint({
                url: ADVANCED_SEARCH.replace(':entity', 'jobs'),
                method: 'POST',
                data: {
                  jobIds: originalData.job
                }
              })
            ).jobs as JobDto[];
            addJobs(jobs);
          } else {
            jobs = undefined;
          }
        }
        originalData.resources.forEach(({ resource }) => {
          if (resource in localResources)
            resources = { ...resources, [resource]: localResources[resource] };
          else fill.push(resource);
        });

        if (fill.length > 0) {
          const result = (await fetchResourcesForHint({
            url: ADVANCED_SEARCH.replace(':entity', Entities.RESOURCES),
            method: 'POST',
            data: { resourceIds: fill }
          })) as ResourceQueryResult;

          addResources(result.resources);
          const newResult = result.resources.reduce<Record<string, ResourceDto>>(
            (acc, curr) => ({
              ...acc,
              [curr._id]: curr
            }),
            {}
          );
          resources = { ...resources, ...newResult };
        }

        const resourceProperties = originalData.resources.reduce<
          Record<string, Record<string, string>[]>
        >((acc, { roleName, resource }) => {
          const resourceWithProperties = { _id: resource };
          resources[resource]?.properties
            .filter((prop) => RESOURCE_LABEL_PROP_IDS.has(prop._id))
            .forEach((prop) => {
              resourceWithProperties[prop._id] = prop.value;
            });

          return {
            ...acc,
            [roleName]:
              roleName in acc
                ? [...acc[roleName], resourceWithProperties]
                : [resourceWithProperties]
          };
        }, {});

        const imagesUrls = await Promise.all(
          Object.keys(resourceProperties)
            .map((roleName) => resourceProperties[roleName])
            .reduce((acc, current) => acc.concat(current))
            .map((resource) => {
              if (resource[SystemProperties.RESOURCES_PICTURE]) {
                return fetchFile({
                  url: FILES_URL_BY_ID.replace(':id', resource[SystemProperties.RESOURCES_PICTURE])
                });
              }
            })
        );
        const images = Object.keys(resourceProperties)
          .map((roleName) => resourceProperties[roleName])
          .reduce((acc, current) => acc.concat(current))
          .reduce(
            (acc, current, idx) => ({ ...acc, [current._id]: imagesUrls[idx] as string }),
            {}
          );
        return renderToString(
          <BryntumHint
            key={originalData._id}
            startDate={originalData.startDate}
            endDate={originalData.endDate}
            location={getEventLocation(originalData) as string}
            job={
              jobs?.map((job) =>
                getPropertyValueDef(job.properties, SystemProperties.JOBS_NAME, '')
              ) ?? []
            }
            resources={Object.keys(resourceProperties).map((roleName) => (
              <>
                <Typography key={roleName} variant="body1" className="b-resource-title">
                  {roleName}
                </Typography>
                {resourceProperties[roleName].map((resource, idx) => (
                  <div
                    key={resource._id}
                    style={{ marginTop: idx === 0 ? '0' : '4px' }}
                    className="b-hint-resource">
                    <GridMUI
                      sx={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center' }}>
                      {images[resource._id] && !((images[resource._id] as any) instanceof Error) ? (
                        <img
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '20px',
                            border: `solid ${theme.palette.grey[400]} 1px`
                          }}
                          src={images[resource._id]}
                        />
                      ) : (
                        <FaUserCircle color={theme.palette.grey[400]} size={24} />
                      )}
                    </GridMUI>
                    {/* <ResourceAvatar key={idx} src={resource[SystemProperties.RESOURCES_PICTURE]} /> */}

                    <Typography component="div" variant="body2">
                      {`${
                        resource[SystemProperties.RESOURCES_FIRST_NAME]
                          ? `${resource[SystemProperties.RESOURCES_FIRST_NAME]}`
                          : ''
                      }${resource[SystemProperties.RESOURCES_NAME] ?? tCommon('unknown')} (${
                        resource[SystemProperties.RESOURCES_ID] ?? tCommon('unknown')
                      })`}
                    </Typography>
                  </div>
                ))}
              </>
            ))}
          />
        );
      }
    }
  } as Partial<BryntumSchedulerProProps>;

  const scheduleMenuFeature: Partial<ScheduleMenuConfig> = useMemo(
    () => ({
      processItems({ items, resourceRecord, date }) {
        items.addEvent = false;
        items.createEvent = {
          text: tMainTemplate('actions.createEvent'),
          icon: 'b-icon b-fa-circle-plus',
          disabled: globalLoading || loadingProperties || !globalData || !resourceRecord,
          onItem: async (ev) => {
            initialize(
              <EventPanelTemplate
                mode={CrudModes.CREATE}
                resourceId={resourceRecord.id.toString()}
                startDate={date}
                onSuccess={(newEvents) => {
                  if (newEvents) {
                    addNewEvents(Array.isArray(newEvents) ? newEvents : [newEvents]);
                    handleClose(false);
                  }
                }}
                onDirtyFields={(isDirty) =>
                  handleDirtyModal(isDirty, tCommon('entities.events_one'))
                }
                onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
                onLoading={(loading) => preventFromClose(loading)}
              />,
              { modalName: tCommon('entities.events_one') }
            );
          }
        };
      }
    }),
    [globalLoading, loadingProperties, globalData]
  );

  const eventMenuFeature = useMemo(
    () => ({
      processItems({ items, eventRecord, assignmentRecord }: BryntumEventMenuContextPro) {
        defaultEventMenuContextOptions(items);
        items.customCreateEvent = {
          text: tMainTemplate('actions.createEvent'),
          icon: 'b-icon b-fa-circle-plus',
          disabled: globalLoading || loadingProperties,
          onItem: async (ev) => {
            initialize(
              <EventPanelTemplate
                mode={CrudModes.CREATE}
                resourceId={assignmentRecord.getData('resourceId')}
                startDate={eventRecord.getData('startDate')}
                onSuccess={(newEvents) => {
                  if (newEvents) {
                    addNewEvents(Array.isArray(newEvents) ? newEvents : [newEvents]);
                    handleClose(false);
                  }
                }}
                onDirtyFields={(isDirty) =>
                  handleDirtyModal(isDirty, tCommon('entities.events_one'))
                }
                onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
                onLoading={(loading) => preventFromClose(loading)}
              />,
              { modalName: tCommon('entities.events_one') }
            );
          }
        };
        items.customEditEvent = {
          text: tMainTemplate('actions.editEvent'),
          icon: 'b-icon b-fa-pen-to-square',
          disabled: globalLoading || loadingProperties || !!assignmentRecord.getData('joined'),
          onItem: async (ev) => {
            if (!!eventRecord.getData('job'))
              initialize(
                <JobEventPanelTemplate
                  mode={CrudModes.EDIT}
                  eventId={assignmentRecord.getData('eventId')}
                  resourceId={assignmentRecord.getData('resourceId')}
                  onSuccess={(editedEvent, oldEvent) => {
                    const silent = equalResources(editedEvent, oldEvent);
                    onEditEvents([editedEvent], silent);
                    handleClose(false);
                  }}
                  onDirtyFields={(isDirty) =>
                    handleDirtyModal(isDirty, tCommon('entities.events_one'))
                  }
                  onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
                  onLoading={(loading) => preventFromClose(loading)}
                />,
                { modalName: tCommon('entities.events_one') }
              );
            else
              initialize(
                <EventPanelTemplate
                  mode={CrudModes.EDIT}
                  eventId={assignmentRecord.getData('eventId')}
                  resourceId={assignmentRecord.getData('resourceId')}
                  onSuccess={(editedEvent) => {
                    if (editedEvent) {
                      updateEvent(editedEvent as EventDto);
                      handleClose(false);
                    }
                  }}
                  onDirtyFields={(isDirty) =>
                    handleDirtyModal(isDirty, tCommon('entities.events_one'))
                  }
                  onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
                  onLoading={(loading) => preventFromClose(loading)}
                />,
                { modalName: tCommon('entities.events_one') }
              );
          }
        };
        items.customDeleteEvent = {
          text: tMainTemplate('actions.deleteEvent'),
          icon: 'b-icon b-fa-trash-can',
          disabled:
            !!assignmentRecord.getData('showAsJoined') || globalLoading || loadingProperties,
          onItem: async (ev) => {
            if (!!eventRecord.getData('series')) {
              await showConfirmation({
                title: tCommon('modalConfirmationDelete.title'),
                message: <DeletionSeries eventInfo={ev} handleCancel={handleCloseDeletionSeries} />,
                isReactNode: true
              });
            } else {
              const result: Record<string, unknown> = await showConfirmation({
                title: tCommon('modalConfirmationDelete.title'),
                message: tCommon('modalConfirmationDelete.message_one', {
                  element: tCommon('entities.events_one')
                }),
                confirmButton: tCommon('buttons.delete'),
                cancelButton: tCommon('buttons.cancel'),
                fetchData: {
                  url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.EVENTS).replace(
                    ':id',
                    assignmentRecord.getData('eventId')
                  ),
                  method: 'DELETE'
                },
                handleError: (error) => {
                  if (error?.response?.data?.code == 'event_not_found') {
                    setToastNotifications([
                      {
                        message: tCommon('errors.entityNotFound', {
                          entity: tCommon('entities.events_one')
                        })
                      }
                    ]);
                  } else {
                    setToastNotifications([{ message: tCommon('errors.deletionError') }]);
                  }
                }
              });
              if (result) {
                deleteCommonEvent(assignmentRecord.getData('eventId'));
              }
            }
          }
        };
        const refInstance = (ref as RefObject<BryntumSchedulerPro>).current?.instance;
        const assignments =
          refInstance?.selectedAssignments.filter(
            (assignment) =>
              !!(assignment.event as EventModel).getData('job') && !assignment.getData('joined')
          ) ?? [];
        items.unassign = {
          text: tCommon('actions.removeElement', {
            element: tCommon('entities.resourceassignments', { count: assignments.length })
          }),
          icon: 'b-icon b-fa-user-slash',
          cls: 'b-separator',
          disabled: globalLoading || loadingProperties || assignments.length == 0,
          onItem: async (ev) => {
            const result: Record<string, unknown> = await showConfirmation({
              title: tCommon('modalConfirmation.title'),
              message: tCommon('modalConfirmationRemove.message', {
                count: assignments.length,
                number: assignments.length,
                element: tCommon('entities.resourceassignments', { count: assignments.length })
              }),
              confirmButton: tCommon('buttons.remove'),
              cancelButton: tCommon('buttons.cancel')
            });
            if (result) {
              const assignmentsMap: Record<string, string[]> = {};

              assignments.forEach((assignment) => {
                const { resourceId, eventId } = assignment;
                if (assignmentsMap[eventId]) {
                  assignmentsMap[eventId].push(resourceId as string);
                } else {
                  assignmentsMap[eventId] = [resourceId as string];
                }
              });

              const request: RemoveResourcesDto = { assignments: [] };
              Object.keys(assignmentsMap).forEach((eventId) => {
                request.assignments.push({
                  event: eventId,
                  resources: assignmentsMap[eventId]
                });
              });
              const { updated, deletedIds } = (await fetchRemove({
                url: EVENTS_REMOVE_RESOURCES,
                method: 'POST',
                data: request
              })) as UpdatedEvents;
              deletedIds.forEach((eventId) => {
                refInstance?.eventStore.remove(eventId);
              });
              const { resourceAssignments, resourceEventsRender } = getAssignmentsFromEvents(
                updated,
                resources,
                tMainTemplate
              );
              updateEvents(
                SchedulerNames.RESOURCES,
                resourceEventsRender,
                resourceAssignments,
                true
              );
              const { jobAssignments, jobEventsRender } = getAssignmentsFromJobEvents(updated, []);
              updateEvents(SchedulerNames.JOBS, jobEventsRender, jobAssignments, true);
            }
          }
        };
        if (eventRecord.getData('join') && assignmentRecord.getData('joined')) {
          if (assignmentRecord.getData('showAsJoined')) {
            items.customExpandJoined = {
              text: tMainTemplate('actions.expandJoined'),
              icon: 'b-icon b-fa-angles-down',
              disabled: globalLoading || loadingProperties,
              cls: 'b-separator',
              onItem: async (ev) => {
                expandJoinedEvents(eventRecord, assignmentRecord);
              }
            };
          } else {
            items.customCollapseJoined = {
              text: tMainTemplate('actions.collapseJoined'),
              icon: 'b-icon b-fa-angles-up',
              disabled: globalLoading || loadingProperties,
              cls: 'b-separator',
              onItem: async (ev) => {
                const resourceId: string | undefined = assignmentRecord.getData('resourceId');
                resourceId && collapseJoinedEvents(eventRecord, resourceId);
              }
            };
          }
        }
      }
    }),
    [globalLoading, loadingProperties, ref, resources, masterDetail]
  );

  const addNewEvents = (events: EventDto[]) => {
    const refInstance = (ref as RefObject<BryntumSchedulerPro>).current?.instance;
    if (refInstance) {
      const eventsBryntum: EventProBryntum[] = [];
      const assignmentsBryntum: AssignmentBryntumPro[] = [];
      for (const event of events) {
        eventsBryntum.push(transformToEventProBryntum(event));
        for (const evRes of event.resources) {
          assignmentsBryntum.push(transformToAssignmentBryntumPro(event._id, evRes.resource));
        }
      }
      const newEvents = refInstance.eventStore.add(eventsBryntum);
      refInstance.assignmentStore.add(assignmentsBryntum);

      // scroll to first event as long as it is not inside visible range already
      const firstEvent = newEvents[0];
      const visibleDateRange = refInstance?.visibleDateRange;
      if (
        firstEvent &&
        (!visibleDateRange ||
          (visibleDateRange.startDate &&
            visibleDateRange.endDate &&
            (firstEvent.startDate < visibleDateRange?.startDate ||
              firstEvent.endDate > visibleDateRange?.endDate)))
      ) {
        // refInstance?.scrollToDate(firstEvent.startDate as Date, {
        //   animate: {
        //     easing: 'easeFromTo',
        //     duration: 800
        //   },
        //   block: 'center'
        // });
      }
    }
  };

  const removeEvent = (eventId: string) => {
    const eventStore: EventStore | undefined = (ref as RefObject<BryntumSchedulerPro>).current
      ?.instance.eventStore;
    return eventStore?.remove(eventId);
  };

  const updateEvent = (event: EventDto) => {
    if (removeEvent(event._id)) {
      addNewEvents([event]);
    }
  };

  const expandJoinedEvents = (event: EventModel, assignment: AssignmentModel) => {
    if (event.getData('join') && assignment.getData('joinedAssignments')) {
      const assignmentStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance
        .assignmentStore;
      if (assignmentStore) {
        assignmentStore.remove(assignment.id);
        assignmentStore.add(assignment.getData('joinedAssignments'));
      }
    }
  };

  const collapseJoinedEvents = (event: EventModel, resourceId: string) => {
    if (!!event.getData('join')) {
      const eventStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance.eventStore;
      if (eventStore) {
        const joinedEvent = eventStore.getById(event.getData('join')) as EventModel;
        const joinedAssignment = (
          joinedEvent.getData('joinedAssignments') as Map<string, AssignmentBryntumPro>
        )?.get(resourceId);
        if (!!joinedAssignment) {
          const joinedAssignments = joinedAssignment.joinedAssignments?.map((ja) => ja.id);

          const assignmentStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance
            .assignmentStore;
          if (assignmentStore && joinedAssignments) {
            assignmentStore.remove(joinedAssignments);
            assignmentStore.add(joinedAssignment);
          }
        }
      }
    }
  };

  const updateResource = useCallback(
    async (resource: ResourceDto | ResourceDto[]) => {
      const store = (
        (ref as MutableRefObject<BryntumSchedulerPro>).current.instance.subGrids as any
      ).locked.store as Store;
      const resourcesArr = isArray(resource) ? resource : [resource];
      for (const { _id } of resourcesArr) deleteResource(_id);
      addResources(resourcesArr);
      const gridRef = (ref as RefObject<BryntumSchedulerPro>)?.current?.instance as Grid;
      const newResources: ResourceBryntum[] = transformResourcesToScheduler(false, resourcesArr);
      const modelArr: Model[] = [];
      newResources.forEach((newResource: ResourceBryntum) => {
        const data: Model = store.getById(newResource.id);
        modelArr.push(data);
        properties?.forEach((prop: PropertyDto) => {
          data.set(prop._id, newResource[prop._id]);
        });
        data.set('resource', newResource['resource']);
      });
      gridRef.selectedRecords = modelArr;
    },
    [properties]
  );

  const addNewResource = async (resource: ResourceDto | ResourceDto[]) => {
    const store = ((ref as MutableRefObject<BryntumSchedulerPro>).current.instance.subGrids as any)
      .locked.store as Store;
    const gridRef = (ref as RefObject<BryntumSchedulerPro>)?.current?.instance as Grid;
    const resourcesArr = isArray(resource) ? resource : [resource];
    const newResources: ResourceBryntum[] = transformResourcesToScheduler(false, resourcesArr);
    const newRows: Model[] = store.insert(0, newResources);
    setCounter((last) => last + newResources.length);
    if (gridRef) {
      gridRef.selectedRecords = newRows;
      gridRef.scrollToTop();
    }
  };

  const editResourceSuccess = useCallback(
    (action: CrudModes, entity?: ResourceDto | ResourceDto[]) => {
      if (entity) {
        if (action == CrudModes.CREATE) addNewResource(entity);
        if (action == CrudModes.EDIT) {
          updateResource(entity);
        }
      }
      handleClose(false);
    },
    [updateResource]
  );

  const openModal = (
    action: Exclude<CrudModes, 'delete' | 'read'>,
    entityId: string | string[],
    entityType?: {
      label: string;
      _id: string;
    }
  ) => {
    initialize(
      <ResourcePanelTemplate
        mode={action}
        resourceId={entityId}
        resourceType={entityType}
        onLoading={preventFromClose}
        onSuccess={(entity) => editResourceSuccess(action, entity)}
        onDirtyFields={handleDirtyModal}
        onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.resources_one'))}
      />,
      {
        style: {
          height: '90vh',
          width: '55vw',
          minWidth: '28rem',
          minHeight: '26rem',
          maxWidth: '88rem',
          maxHeight: '90rem',
          borderRadius: '16px'
        },
        modalName: tCommon('entities.resources_one')
      }
    );
  };

  const eventKeyDown: BryntumSchedulerProProps['onEventKeyDown'] = async (e) => {
    const { eventRecords, assignmentRecords, event } = e;
    if (event.key === 'Delete') {
      const firstEventRecord = eventRecords[0];
      const eventId: string | undefined = firstEventRecord.get('id');
      const hasJob = !!firstEventRecord.get('job');
      const isJoined = !!assignmentRecords[0].get('joined');
      if (eventId && !hasJob && !isJoined) {
        const result: Record<string, unknown> = await showConfirmation({
          title: tCommon('modalConfirmationDelete.title'),
          message: tCommon('modalConfirmationDelete.message_one', {
            element: tCommon('entities.events_one')
          }),
          confirmButton: tCommon('buttons.delete'),
          cancelButton: tCommon('buttons.cancel'),
          fetchData: {
            url: COLLECTIONS_FIND_ONE.replace(':collection_name', 'events').replace(':id', eventId),
            method: 'DELETE'
          },
          handleError: (error) => {
            setToastNotifications([{ message: tCommon('errors.deletionError') }]);
          }
        });
        if (result) {
          removeEvent(eventId);
        }
      }
    }
  };

  const updateEventFromCalendar = (event: EventDto[] | string, action: CrudModes) => {
    if (action == CrudModes.CREATE && typeof event != 'string') {
      addNewEvents(event);
    } else if (action == CrudModes.DELETE && typeof event == 'string') {
      removeEvent(event);
    } else if (action == CrudModes.EDIT && typeof event != 'string') {
      updateEvent(event[0]);
    }
  };

  const headerMenuFeature = useMemo(() => {
    return {
      processItems({ items }) {
        if (!globalLoading && !loadingProperties) {
          items.groupAsc = false;
          items.groupDesc = false;
        } else {
          items.columnPicker = false;
          items.groupAsc = false;
          items.groupDesc = false;
          items.hideColumn = false;
          items.multiSort = false;
          items.sortAsc = false;
          items.sortDesc = false;
        }
      }
    };
  }, [globalLoading, loadingProperties]);

  const cellMenuFeature = useMemo(() => {
    return {
      processItems({ items, selection, record }: BryntumCellMenuContext) {
        if (!globalLoading && !loadingProperties) {
          defaultMenuContextOptions(items);
          items.createResource = {
            text: tMainTemplate('actions.createResource'),
            icon: 'b-icon b-fa-circle-plus',
            menu: resourceTypes
              ?.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }))
              .map(({ name, _id }) => {
                return {
                  text: tCommon('actions.menu', { text: name }),
                  onItem: () => {
                    openModal(CrudModes.CREATE, '', { _id, label: name });
                  }
                };
              })
          };
          items.editResource = {
            text: tCommon('actions.editElement', {
              element: tCommon('entities.resources', { count: selection.length })
            }),
            icon: 'b-icon b-fa-pen-to-square',
            onItem: (ev) => {
              openModal(
                CrudModes.EDIT,
                selection && selection.length > 1
                  ? selection?.map((record) => record?.id.toString()).filter((record) => !!record)
                  : record.id.toString()
              );
            }
          };
          items.deleteResources = {
            text: tCommon('actions.deleteElement', {
              element: tCommon('entities.resources', { count: selection.length })
            }),
            icon: 'b-icon b-fa-trash-can',
            onItem: async (ev) => {
              const resourceIds: string[] = selection
                ?.map((selection) => selection.id.toString())
                ?.filter((val) => !!val);

              const result: Record<string, unknown> | false | undefined = await showConfirmation({
                title: tCommon('modalConfirmationDelete.title'),
                message: (
                  <>
                    {tCommon('modalConfirmationDelete.message', {
                      count: selection.length,
                      number: selection.length,
                      element: tCommon('entities.resources', {
                        count: selection.length
                      })
                    })}
                    <Typography variant="body2" marginTop="1rem">
                      <strong>{tCommon('utils.note') + ' '}</strong>
                      {tMainTemplate('noteDeleteResource')}
                    </Typography>
                  </>
                ),

                confirmButton: tCommon('buttons.delete'),
                cancelButton: tCommon('buttons.cancel'),
                fetchData:
                  selection.length > 1
                    ? {
                        url: COLLECTIONS_BATCH_DELETE.replace(
                          ':collection_name',
                          EntityRoutes.RESOURCES
                        ),
                        method: 'POST',
                        data: {
                          ids: selection.map((selected) => selected.id)
                        }
                      }
                    : {
                        url: COLLECTIONS_FIND_ONE.replace(
                          ':collection_name',
                          EntityRoutes.RESOURCES
                        ).replace(':id', record.id.toString()),
                        method: 'DELETE'
                      },
                handleError: (error) => {
                  setToastNotifications([{ message: tCommon('errors.deletionError') }]);
                }
              });
              if (result) {
                fetchByResources(
                  resourceFilters,
                  fullText,
                  jobsFilter,
                  resourceRecordFilters,
                  newSorter
                );
                resourceIds.forEach((resourceId) => deleteResource(resourceId));
              }
            }
          };
          items.openResourceCalendar = {
            text: `${tMainTemplate('actions.viewResourceCalendar')}`,
            icon: 'b-icon b-fa-calendar',
            cls: 'b-separator',
            disabled: selection.length > 1,
            onItem: (ev) => {
              const timeAxis = (ref as MutableRefObject<BryntumSchedulerPro>).current.instance
                .timeAxis;
              const duration = DateTime.fromJSDate(timeAxis.endDate).diff(
                DateTime.fromJSDate(timeAxis.startDate)
              );
              const date = DateTime.fromJSDate(timeAxis.startDate)
                .plus({
                  days: duration.shiftTo('days').days / 2
                })
                .toJSDate();
              setIsOpenCalendar({
                onCancel: () => {
                  setIsOpenCalendar(undefined);
                },
                date: date,
                resource: record.getData('resource') as ResourceDto,
                onChangeEvent: updateEventFromCalendar,
                style: {
                  height: '90vh',
                  width: '80vw',
                  minWidth: '28rem',
                  minHeight: '26rem',
                  maxWidth: '88rem',
                  maxHeight: '90rem',
                  borderRadius: '16px'
                }
              });
            }
          };
          items.importResources = {
            text: tMainTemplate('actions.importResources'),
            icon: 'b-icon b-fa-file-import',
            cls: 'b-separator',
            onItem: () => {
              initialize(
                <ResourceImportPanelTemplate
                  onLoading={(loading) => {
                    preventFromClose(loading);
                  }}
                  onSuccess={(_data) => {
                    handleClose(false);
                  }}
                  onLoadedData={handleDirtyModal}
                  onCancel={handleClose}
                />,
                {
                  style: {
                    height: '90vh',
                    width: '80vw',
                    minWidth: '28rem',
                    minHeight: '26rem',
                    maxWidth: '88rem',
                    maxHeight: '90rem',
                    borderRadius: '16px'
                  }
                }
              );
            }
          };
          items.selectAllResources = {
            text: `${tCommon('actions.selectAll')}`,
            icon: 'b-icon b-fa-list-check',
            cls: 'b-separator',
            onItem: async () => {
              const instanceRef = (ref as RefObject<BryntumSchedulerPro>)?.current?.instance;
              instanceRef?.selectAll();
            }
          };
        } else {
          defaultMenuContextOptions(items);
        }
      }
    };
  }, [globalLoading, loadingProperties]);

  const configSchedule = useMemo(() => {
    return getCommonSchedulerProConfig(extraItems);
  }, []);

  useEffect(() => getColumns(ref, tCommon, properties), [properties]);

  const renderResources = useMemo(
    () => transformResourcesToScheduler(globalLoading, resources),
    [resources]
  );

  useEffect(() => {
    const store = ((ref as MutableRefObject<BryntumSchedulerPro>).current.instance.subGrids as any)
      .locked.store as Store;
    if (Array.isArray(renderResources)) {
      store.removeAll();
      (ref as MutableRefObject<BryntumSchedulerPro>).current.instance.eventStore.removeAll();
      setTimeout(() => {
        store.add(renderResources);
      }, 0);
    }
  }, [renderResources]);

  useEffect(() => {
    // generate events and assignments to render in bryntum
    const { resourceEventsRender, resourceAssignments } = getAssignmentsFromEvents(
      resourcesEvents,
      resources,
      tMainTemplate
    );
    // first clear stores: assignments and

    setTimeout(() => {
      const assignmentStore = (ref as MutableRefObject<BryntumSchedulerPro>).current.instance
        .assignmentStore as AssignmentStore;
      assignmentStore.removeAll();
      const eventStore = (ref as MutableRefObject<BryntumSchedulerPro>).current.instance
        .eventStore as EventStore;
      eventStore.removeAll();
      // assign data: first events, then assignments
      if (Array.isArray(resourceEventsRender)) {
        eventStore.add(resourceEventsRender, true);
      }
      if (Array.isArray(resourceAssignments)) {
        assignmentStore.add(resourceAssignments);
      }
    }, 0);
  }, [resourcesEvents]);

  useEffect(() => {
    if (properties) {
      loadStateConfigBryntum(ref, SchedulerNames.RESOURCES);
    }
  }, [properties]);

  useEffect(() => {
    if (globalLoading) {
      setLoading(ref);
    }
  }, [globalLoading]);

  useEffect(() => {
    setLoadingTimeAxis(ref, true);
  }, []);

  useWindowUnloadEffect(() => {
    const resourceScheduler = (ref as RefObject<BryntumSchedulerPro>)?.current?.instance;
    resourceScheduler && saveStateConfigBryntum(resourceScheduler, SchedulerNames.RESOURCES);
  }, true);

  useEffect(() => {
    const eventStore = (ref as RefObject<BryntumSchedulerPro>)?.current?.instance?.eventStore;
    if (eventStore) {
      if (masterDetail === 2) {
        eventStore.onAdd = () => {
          triggerScheduler(SchedulerNames.JOBS);
        };
      } else {
        eventStore.onAdd = () => null;
      }
    }
  }, [masterDetail]);

  return (
    <>
      {isOpenCalendar && (
        <Dialog
          TransitionComponent={Transition}
          open={isOpenCalendar}
          onClose={async (
            event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement, MouseEvent>
          ) => {
            event.preventDefault();
            if (dirtyEvent) {
              const continueOnClose = await showConfirmation({
                title: t('modalConfirmation.title'),
                message: (
                  <>
                    <Typography component={'span'}>
                      {tCommon('modalConfirmation.messageWEntity', {
                        entityName: tCommon('entities.events_one')
                      })}
                    </Typography>
                    <br />
                    <br />
                    <Typography component={'span'} marginTop={'1rem'}>
                      {tCommon('modalConfirmation.leave_simple')}
                    </Typography>
                  </>
                ),
                isReactNode: false,
                confirmButton: tCommon('buttons.leave'),
                cancelButton: tCommon('buttons.cancel')
              });
              if (continueOnClose) {
                setIsOpenCalendar(undefined);
              }
            }
            if (!dirtyEvent) {
              setIsOpenCalendar(undefined);
            }
          }}
          disableEnforceFocus
          sx={{ zIndex: '1100' }}
          PaperProps={{
            sx: { ...isOpenCalendar.style, overflow: 'hidden' }
          }}>
          <CalendarContextProvider>
            <ResourceCalendarPanelTemplate
              onCancel={isOpenCalendar.onCancel}
              resource={isOpenCalendar.resource}
              date={isOpenCalendar.date}
              dirtyEvent={dirtyEvent}
              handleDirtyEvent={(dirty) => {
                setDirtyEvent(dirty);
              }}
              onChangeEvent={isOpenCalendar.onChangeEvent}></ResourceCalendarPanelTemplate>
          </CalendarContextProvider>
        </Dialog>
      )}
      <ScheduleContainer>
        <Filter
          open={open}
          toggleFilter={toggleFilter}
          refs={[documentsRef, propertiesRef]}
          title={tCommon('filters.title', { entity: tCommon('entities.resources_other') })}>
          <EntityFilter
            entity={Entities.RESOURCES}
            title={tCommon('filters.byProperties')}
            onSuccess={handleResourceFilters}
            defaultValues={resourceFilters}
            extraFields={extraFields}
            selected={resourceSettings}
            setSelected={setResourceSettings}
            propertiesRef={propertiesRef}
          />
          <FilterByRecord
            defaultValues={resourceRecordFilters}
            title={tMainTemplate('filters.byRecords')}
            onSuccess={handleResourceRecordsFilters}
            onCancel={toggleFilter}
            documentsRef={documentsRef}
          />
        </Filter>
        <BryntumSchedulerPro
          rowCopyPasteFeature={false}
          {...configSchedule}
          bodyCls={'targetScheduler'}
          cls={'b-custom-top-border'}
          emptyText={tMainTemplate('text.emptyRows', {
            row: tCommon('entities.resources_other')
          })}
          onBeforeDestroy={(e) => {
            const source: SchedulerPro = e.source as SchedulerPro;
            saveStateConfigBryntum(source, SchedulerNames.RESOURCES);
          }}
          ref={ref}
          hideHeaders={false}
          onCellDblClick={onCellDblClick}
          taskEditFeature={false}
          onEventDblClick={eventDblClick}
          onEventKeyDown={eventKeyDown}
          createEventOnDblClick={false}
          eventEditFeature={false}
          dependenciesFeature={false}
          cellEditFeature={false}
          eventResizeFeature={false}
          eventDragFeature={false}
          enableDeleteKey={false}
          scheduleMenuFeature={scheduleMenuFeature}
          headerMenuFeature={headerMenuFeature}
          eventMenuFeature={eventMenuFeature}
          cellMenuFeature={cellMenuFeature}
          flex="1 1 0px"
          minHeight="12em"
          zoomOnMouseWheel={true}
          minZoomLevel={ZoomLevels.MIN}
          maxZoomLevel={ZoomLevels.MAX}
          onEventContextMenu={(e) => {
            const refInstance = (ref as RefObject<BryntumSchedulerPro>).current?.instance;
            if (refInstance && !e.event.ctrlKey) {
              refInstance.selectAssignment(e.assignmentRecord);
            }
          }}
          onEventClick={(e) => {
            const refInstance = (ref as RefObject<BryntumSchedulerPro>).current?.instance;
            if (refInstance && !e.event.ctrlKey) {
              refInstance.clearEventSelection();
              refInstance.selectAssignment(e.assignmentRecord);
            }
          }}
          onDateRangeChange={(e) => {
            if (e.new.startDate < currentStartDate) {
              setCurrentStartDate(e.new.startDate);
            }
            if (e.new.endDate > currentEndDate) {
              setCurrentEndDate(e.new.endDate);
            }
          }}
          eventRenderer={({ eventRecord }) => {
            return `<span>${eventRecord.name}</span>`;
          }}
        />
      </ScheduleContainer>
    </>
  );
};

export default forwardRef<BryntumSchedulerPro, SchedulerResourcesProProps>(SchedulerProResources);
