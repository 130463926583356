import { Entities, PROPERTIES_BY_ENTITY, PropertyTypes, SystemProperties } from '@/const';
import { theme } from '@/theme';
import { EventDto, PropertyDto, ResourceDto, StructureStatus } from '@/types';
import {
  AssignmentBryntum,
  EventBryntum,
  JoinedEventBryntum,
  ResourceBryntum
} from '@/types/ui/bryntum';
import {
  hexToRGB,
  sortingByFetch,
  transformPropertyValue,
  transformToAssignmentBryntum,
  transformToEventBryntum,
  transformToJoinedEventBryntum
} from '@/utils';
import { DragHelper, Model, ScrollManager, Store } from '@bryntum/core-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { BryntumScheduler } from '@bryntum/scheduler-react-thin';
import { Scheduler } from '@bryntum/scheduler-thin';
import { Skeleton, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { ForwardedRef, RefObject } from 'react';
import { renderToString } from 'react-dom/server';

export const transformResourcesToScheduler = (loading?: boolean, resources?: ResourceDto[]) => {
  let localResources: ResourceBryntum[];
  if (resources) {
    localResources = resources.map((resource) => {
      const localResource: ResourceBryntum = {
        id: resource._id,
        resource
      };
      resource.properties.forEach(
        (property) => (localResource[property._id] = property.label ?? property.value)
      );
      return localResource;
    });
  } else {
    localResources = new Array(5).fill('').map((_, idx) => ({ id: `${idx}` }));
  }
  return localResources;
};

export const getAlignmentByType = (type: PropertyTypes) => {
  if (type == PropertyTypes.NUMBER) {
    return 'right';
  }
  if (type == PropertyTypes.IMAGE) {
    return 'center';
  }
  return 'left';
};

export const getColumns = (
  ref: ForwardedRef<BryntumScheduler>,
  t: TFunction,
  properties?: PropertyDto[]
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumScheduler>).current?.instance
    .columns as ColumnStore;
  columnStore.remove(columnStore.records.filter((r: any) => !r.isTimeAxisColumn));
  if (properties) {
    columnStore.add([
      {
        id: 'counter',
        field: 'counter',
        type: 'rownumber',
        cls: 'counter-header',
        cellCls: 'b-border-bottom',
        align: 'center',
        htmlEncodeHeaderText: false,
        hideable: false
      },
      {
        text: 'Type',
        field: 'type',
        id: 'Type',
        width: 80,
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record: { data } }) => {
          if (!data.resource) {
            return <Skeleton height={30} />;
          }
          return data?.resource?.type?.label ?? '';
        }
      },
      ...(properties as PropertyDto[]).map(({ _id: id, name: text, type }) => ({
        id,
        ...(Object.values(SystemProperties).includes(id as SystemProperties)
          ? {}
          : { hidden: true }),
        field: id,
        text,
        width: 80,
        sortable: sortingByFetch,
        align: type == PropertyTypes.NUMBER ? 'right' : 'left',
        groupable: false,
        renderer: ({ record: { data } }) => {
          return !data.resource ? (
            <Skeleton height={30} />
          ) : (
            transformPropertyValue(type, t, data[id])
          );
        }
      })),
      {
        text: t('entities.missions_other'),
        field: 'missions',
        id: 'missions',
        width: 80,
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record: { data } }) => {
          if (!data.resource) {
            return <Skeleton height={30} />;
          }
          return data?.resource?.missions?.label ?? '';
        }
      },
      {
        text: t('entities.work-rules_other'),
        field: 'workRules',
        id: 'workRules',
        width: 80,
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record: { data } }) => {
          if (!data.resource) {
            return <Skeleton height={30} />;
          }
          return data?.resource?.workRules?.label ?? '';
        }
      }
    ]);
  } else {
    columnStore.add([
      ...new Array(7).fill('').map(() => ({
        text: '...',
        width: 40,
        renderer: () => {
          return <Skeleton height={30} />;
        }
      }))
    ]);
  }
};

export const getAssignmentsFromEvents = (
  events?: EventDto[],
  resources?: ResourceDto[],
  t?: TFunction
) => {
  const resourceEventsRender: EventBryntum[] = [];
  const resourceAssignments: AssignmentBryntum[] = [];

  if (events && resources && t) {
    const joinedEventsRender = new Map<string, JoinedEventBryntum>();
    const joinedAssignmentBryntumByResource = new Map<string, AssignmentBryntum>();

    events.forEach((event) => {
      const eventBryntum = transformToEventBryntum(event);
      resourceEventsRender.push(eventBryntum);

      event.resources?.forEach((resource) => {
        const assignmentBryntum = transformToAssignmentBryntum(
          event._id,
          resource.resource,
          resource.joined,
          false
        );
        if (event.join && resource.joined) {
          joinedAssignmentBryntumByResource.set(resource.resource, assignmentBryntum);
        } else {
          resourceAssignments.push(assignmentBryntum);
        }
      });

      if (event.join) {
        let joinedEvent: JoinedEventBryntum;
        if (!joinedEventsRender.has(event.join)) {
          joinedEvent = transformToJoinedEventBryntum(event);
          joinedEventsRender.set(event.join, joinedEvent);

          event.resources
            ?.filter((resource) => resource.joined)
            .forEach((resource) => {
              const assignmentBryntum = transformToAssignmentBryntum(
                joinedEvent.id,
                resource.resource,
                true,
                true
              );
              resourceAssignments.push(assignmentBryntum);
              joinedEvent?.joinedAssignments?.set(resource.resource, assignmentBryntum);
            });
        } else {
          joinedEvent = joinedEventsRender.get(event.join) as JoinedEventBryntum;
          if (joinedEvent.job) joinedEvent.job = [...joinedEvent.job, event.job];
        }
        joinedEvent['events'].push(eventBryntum);

        for (const [resourceId, assignmentBryntum] of joinedAssignmentBryntumByResource.entries()) {
          joinedEvent?.joinedAssignments
            ?.get(resourceId)
            ?.joinedAssignments?.push(assignmentBryntum);
        }
      }

      joinedAssignmentBryntumByResource.clear();
    });

    if (joinedEventsRender.size > 0) {
      for (const joinedEventRender of joinedEventsRender.values()) {
        const names = new Set<string>();
        const colors = new Set<string>();
        for (const event of joinedEventRender['events']) {
          names.add(event.name);
          colors.add(event.eventColor);
        }
        if (names.size == 1) {
          joinedEventRender.name = `${joinedEventRender.name}${t('text.joinedSuffix', {
            count: joinedEventRender['events'].length
          })}`;
        } else {
          joinedEventRender.name = t('text.joinedName', {
            count: joinedEventRender['events'].length
          });
        }
        if (colors.size == 1) {
          joinedEventRender.eventColor = colors.values().next().value as string;
        }

        delete joinedEventRender['events'];

        resourceEventsRender.push(joinedEventRender);
      }
    }
  }

  return {
    resourceEventsRender,
    resourceAssignments
  };
};

export const filterDataResourcesProperties = {
  url: PROPERTIES_BY_ENTITY.replace(':entity_name', Entities.RESOURCES)
};

type DropActionFunction = (
  dropType: 'row' | 'event',
  sourceIds: string[],
  dropElement: { id: string; data: Model }
) => void;
export class Drag extends DragHelper {
  private sourceGrid: Scheduler;
  private targetGrid: Scheduler;
  private targetResourceStore: Store | undefined;
  protected dropAction: DropActionFunction;

  constructor({
    sourceGrid,
    targetGrid,
    dropAction
  }: {
    sourceGrid: Scheduler;
    targetGrid: Scheduler;
    dropAction: DropActionFunction;
  }) {
    super({
      callOnFunctions: true,
      cloneTarget: true,
      autoSizeClonedTarget: false,
      removeProxyAfterDrop: true,
      unifiedProxy: true,
      dropTargetSelector:
        '.dropTargetScheduler .b-grid-row:not(.b-group-row) , .dropTargetScheduler .b-sch-event',
      targetSelector: '.targetScheduler:not(.b-grid-subgrid-normal) .b-grid-row:not(.b-group-row)',
      scrollManager: targetGrid.scrollManager as ScrollManager
    });
    this.sourceGrid = sourceGrid;
    this.targetGrid = targetGrid;
    this.dropAction = dropAction;
  }

  createProxy(element) {
    const proxy = document.createElement('div');
    if (this.sourceGrid) {
      proxy.innerHTML = renderToString(
        <div
          id="dropResourcesTooltip"
          style={{
            display: 'grid',
            padding: '0.25rem',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '5px',
            backgroundColor: hexToRGB(theme.palette.background.default, 0.7),
            placeContent: 'center'
          }}>
          <Typography>Moving : {this.sourceGrid.selectedRecords?.length || 1} Resources</Typography>
        </div>
      );
    }
    return proxy;
  }

  onDragStart: (event: {
    source: DragHelper;
    context: { element: HTMLElement; grabbed: HTMLElement; relatedElements: HTMLElement[] };
    event: MouseEvent | TouchEvent;
  }) => void = function (this: Drag, { context }) {
    if (this.targetGrid && this.targetGrid.selectedRecords?.length > 0) {
      this.targetGrid.deselectAll();
    }
    context['selectedIds'] = (this.sourceGrid.selectedRecords as Model[]).reduce(
      (prev, curr) => [...prev, curr.getData('id') as string],
      [] as string[]
    );
    this.targetGrid.features.eventTooltip.disabled = true;
    this.targetGrid.enableScrollingCloseToEdges(this.targetGrid.subGrids['locked']);
  };

  //Function that adds selected cls to row or event.
  private actionRow(
    type: 'row' | 'event',
    action: 'add' | 'remove',
    element: { id: string; data: Model }
  ) {
    function pickAction(classList: DOMTokenList, cls: string) {
      if (action === 'add') {
        classList.add(cls);
      } else {
        classList.remove(cls);
      }
    }
    if (type === 'row') {
      const rowElement = this.targetGrid.element.querySelector(
        `.b-grid-subgrid-locked .b-grid-row[data-id="${element.id}"]`
      );
      const timeAxisElement = this.targetGrid.element.querySelector(
        `.b-grid-subgrid-normal .b-grid-row[data-id="${element.id}"]`
      );
      if (rowElement) pickAction(rowElement.classList, 'b-custom-row-selected');
      if (timeAxisElement) pickAction(timeAxisElement.classList, 'b-custom-row-selected');
    } else {
      const eventElement = this.targetGrid.element.querySelector(
        `.b-grid-subgrid-normal .b-sch-event-wrap[data-event-id="${element.id}"]`
      );
      if (eventElement) pickAction(eventElement.classList, 'b-custom-event-selected');
    }
  }

  onDrag: (event: {
    source: DragHelper;
    context: {
      element: HTMLElement;
      target: HTMLElement;
      grabbed: HTMLElement;
      relatedElements: HTMLElement[];
      valid: boolean;
    };
    event: MouseEvent;
  }) => void = function (this: Drag, { event, context }) {
    if (context.valid && context.target.parentElement?.getAttribute('data-index')) {
      const target: HTMLElement = context.target;
      const index = context.target.parentElement.getAttribute('data-index') as unknown as number;
      const data = this.targetGrid.resourceStore.allRecords[index];
      const targetId =
        target.parentElement?.getAttribute('data-id') ||
        target.parentElement?.parentElement?.getAttribute('data-event-id');
      if (
        [StructureStatus.CANCELED, StructureStatus.NOT_SCHEDULED].includes(
          data.getData('job').status
        )
      ) {
        target.parentElement?.classList.add('not-allowed');
      } else {
        if (target.parentElement && targetId && targetId !== context['actualDropId']?.id) {
          if (context['actualDropId'])
            this.actionRow(context['actualDropId'].type, 'remove', {
              id: context['actualDropId'].id,
              data: data
            });
          if (target.parentElement.classList.contains('b-grid-row')) {
            this.actionRow('row', 'add', {
              id: targetId,
              data: data
            });
            context['actualDropId'] = {
              type: 'row',
              data: data,
              id: data.id
            };
          } else {
            this.actionRow('event', 'add', {
              id: targetId,
              data: data
            });
            context['actualDropId'] = {
              type: 'event',
              id: data.id,
              data: data
            };
          }
        }
      }
    }
  };

  onDrop: (event: {
    source: DragHelper;
    context: {
      element: HTMLElement;
      target: HTMLElement;
      grabbed: HTMLElement;
      relatedElements: HTMLElement[];
      valid: boolean;
    };
  }) => void = function (this: Drag, { context }) {
    if (context.target.parentElement?.getAttribute('data-index')) {
      if (context.valid) {
        this.dropAction(
          context['actualDropId']?.type,
          context['selectedIds'],
          context['actualDropId']
        );
      }
      if (context['actualDropId']) {
        this.actionRow(context['actualDropId'].type, 'remove', {
          id: context['actualDropId'].id,
          data: context['actualDropId'].data
        });
      }
      this.targetGrid.features.eventTooltip.disabled = false;
    }
  };

  onDragAbort() {
    this.targetGrid.features.eventTooltip.disabled = false;
  }
}
