export enum LABELS {
  JOBS = 'classes'
}
export enum Entities {
  RESOURCES = 'resources',
  JOBS = 'jobs',
  DOCUMENTS = 'documents',
  EVENTS = 'events',
  RESOURCERECORDS = 'resourcerecords',
  CURRICULUMS = 'curriculums',
  MISSIONS = 'missions',
  WORK_RULES = 'work-rules'
}

export const ENTITIES_WITH_PROPS: Entity[] = [
  Entities.RESOURCES,
  Entities.JOBS,
  Entities.RESOURCERECORDS,
  Entities.CURRICULUMS,
  Entities.DOCUMENTS,
  Entities.EVENTS
];

export type Entity =
  | Entities.RESOURCES
  | Entities.JOBS
  | Entities.RESOURCERECORDS
  | Entities.CURRICULUMS
  | Entities.DOCUMENTS
  | Entities.EVENTS
  | Entities.MISSIONS
  | Entities.WORK_RULES;

export enum EntityRoutes {
  RESOURCES = 'resources',
  JOBS = 'jobs',
  DOCUMENTS = 'documents',
  RESOURCE_RECORDS = 'resource-records',
  CURRICULUMS = 'curriculums',
  EVENTS = 'events',
  MISSIONS = 'missions',
  WORK_RULES = 'work-rules'
}
