import { theme } from '@/theme';
import { Theme } from '@emotion/react';
import { SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link, MenuLink, NavMenuItem } from './SafeLink.styles';

export type SafeLinkProps = {
  to: string;
  children?: ReactNode;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  isOnMenu?: boolean;
  end?: boolean;
  disabledText?: string;
  onClick?: (e) => void;
};

const SafeLink = ({
  to,
  children,
  disabled,
  onClick,
  isOnMenu,
  disabledText,
  end,
  ...props
}: SafeLinkProps) => {
  if (isOnMenu) {
    if (disabled && disabledText) {
      return (
        <NavMenuItem disabled={disabled} onClick={onClick}>
          {disabledText}
        </NavMenuItem>
      );
    }
    return (
      <MenuLink to={to} {...props} end={end}>
        <NavMenuItem disabled={disabled} onClick={onClick}>
          {disabledText}
        </NavMenuItem>
      </MenuLink>
    );
  } else {
    if (disabled) {
      if (disabledText) {
        return (
          <Typography sx={{ cursor: 'pointer', color: theme.palette.grey[300] }}>
            {disabledText}
          </Typography>
        );
      } else {
        return <>{children}</>;
      }
    }
    return (
      <Link to={to} {...props} onClick={onClick} end={end}>
        {children}
      </Link>
    );
  }
};

export default SafeLink;
