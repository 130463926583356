import { Session, TimeStrategy } from '@/types';
import { useDragLayer } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { DragNDropComponents } from '../../../CurriculumSetup.const';
import { DROP_SIDES, IconHeader } from '../SessionWrapper.const';
import { HEADER_TITLE_VARIANT, HeaderContainer, HeaderTitle } from '../SessionWrapper.styles';

export type DropGhostType = {
  dropSide: DROP_SIDES.top | DROP_SIDES.bot;
};

const DropGhost = ({ dropSide }: DropGhostType) => {
  const { t } = useTranslation('organisms/curriculumSetup');
  const { item: draggedItem, type } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    type: monitor.getItemType() as DragNDropComponents
  }));

  if (
    [
      DragNDropComponents.NEW_SESION,
      DragNDropComponents.NEW_TIME_SESSION,
      DragNDropComponents.SESSION
    ].includes(type)
  ) {
    const session: Session | undefined = draggedItem?.session as Session;
    return (
      <HeaderContainer
        headerStyle="shadow"
        sx={{
          position: 'absolute',
          top: dropSide === DROP_SIDES.top ? '-3.2rem' : undefined,
          bottom: dropSide === DROP_SIDES.bot ? '-3.2rem' : undefined,
          width: '100%'
        }}>
        <IconHeader
          timeStrategy={
            session?.timeStrategy
              ? session.timeStrategy
              : type === DragNDropComponents.NEW_TIME_SESSION
              ? TimeStrategy.START_TIMES
              : TimeStrategy.RESOURCE_MISSIONS
          }
        />
        <HeaderTitle variant={HEADER_TITLE_VARIANT}>
          {type === DragNDropComponents.SESSION
            ? session?.name || ''
            : t('canvas.newElement', {
                element:
                  type === DragNDropComponents.NEW_SESION
                    ? t('elements.sessions_one')
                    : t('elements.time_sessions_one')
              })}
        </HeaderTitle>
      </HeaderContainer>
    );
  }
  return null;
};

export default DropGhost;
