import { RenderIf } from '@/components/atoms';
import { TextFilter } from '@/components/molecules';
import { AccordionSummary } from '@/components/molecules/Card/Card.styles';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import {
  COLLECTIONS_FIND_ALL,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  SETS_FIND_BY_NAME,
  Setup
} from '@/const';
import { SystemSets } from '@/const/sets';
import { useToastContext } from '@/context/ToastContext';
import { useFetch, useScrollToError } from '@/hooks';
import { ButtonsContainer, Container, ListRow, TextField, theme } from '@/theme';
import { SetDtoResponse } from '@/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Typography,
  styled
} from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import { RefObject, useEffect, useMemo, useRef } from 'react';
import { Controller, Resolver, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';
import { zones } from 'tzdata';
import { ItemType, SetSchema, SetType, Tags } from './SetsPanelTemplate.const';

export type SetPanelTemplateProps = {
  mode: CrudModes.CREATE | CrudModes.EDIT;
  setId?: string;
  onSuccess: (oldName: string, newName?: string) => unknown;
  onCancel: (isDirty?: boolean) => unknown;
  onDirtyFields: (isDirty: boolean) => unknown;
  onLoading?: (loading: boolean) => unknown;
};

type FormValues = {
  newItem: string;
  name: string;
  system: boolean;
  items: ItemType[];
};

const SetPanelTemplate = ({
  mode,
  setId,
  onSuccess,
  onCancel,
  onDirtyFields,
  onLoading
}: SetPanelTemplateProps) => {
  const { data: setData, fetch, loading, error } = useFetch<SetType>();
  const listRef = useRef<HTMLDivElement>(null);
  const lastInputRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('templates/setPanelTemplate');
  const { t: tCommon } = useTranslation('common');
  const { setToastNotifications } = useToastContext();

  const luxonValidTimezones = useMemo(
    () =>
      [
        ...new Set<string>(
          Object.keys(zones).filter(
            (tz) => tz.includes('/') && DateTime.local().setZone(tz).isValid
          )
        )
      ].sort((a, b) => (a < b ? -1 : 1)),
    []
  );

  useEffect(() => {
    if (error) {
      if (patchError?.response?.data?.code == 'set_not_found') {
        setToastNotifications([
          { message: tCommon('errors.entityNotFound', { entity: tCommon('entities.sets_one') }) }
        ]);
      } else {
        setToastNotifications([
          { message: tCommon('errors.loadingEntity', { entity: tCommon('entities.sets_one') }) }
        ]);
      }
      onCancel(false);
    }
  }, [error]);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    setValue,
    watch,
    getValues,
    setError,
    clearErrors
  } = useForm<FormValues>({
    resolver: yupResolver(SetSchema) as unknown as Resolver<FormValues, any>,
    mode: mode === CrudModes.EDIT ? 'onSubmit' : 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { name: '', items: [], newItem: '' }
  });

  const { toScroll } = useScrollToError();
  // toScroll is not working propertly

  const isSystem = setData?.system ?? false;

  useEffect(() => {
    onDirtyFields(isDirty);
  }, [isDirty]);

  const { fields, update, remove, prepend } = useFieldArray({
    control,
    name: 'items'
  });

  const deleteItem = (index: number, tag: string) => {
    const currentValue = getValues(`items.${index}`);
    if (tag !== 'null') {
      update(index, { ...currentValue, tag });
    } else {
      remove(index);
    }
    clearErrors(`items`);
  };

  const createItem = () => {
    const item: ItemType = {
      _id: crypto.randomUUID(),
      label: '',
      data: undefined,
      setName: setId || '',
      tag: Tags.CREATED,
      index: fields.length
    };
    prepend(item);
    setTimeout(() => {
      scrollTop(listRef);
      lastInputRef.current?.focus({ preventScroll: true });
    }, 50);
  };

  useEffect(() => {
    if (mode === CrudModes.EDIT && setId) fetchSet(setId);
  }, []);

  const fetchSet = async (id: string) => {
    const set = (await fetch({
      url: SETS_FIND_BY_NAME.replace(':setName', encodeURIComponent(id)),
      method: 'GET'
    })) as SetType;
    setValue('items', set.items);
    setValue('system', !!set.system);
    setValue('name', id);
  };

  const submit = async (form: SetType) => {
    const setName = getValues('name');
    let query: AxiosRequestConfig | undefined = undefined;
    const itemsToCreate = form.items
      ?.filter((item) => item.tag === Tags.CREATED)
      .map((item) => ({ label: item.label, data: item.data }));
    const itemsToDelete = form.items
      ?.filter((item) => item.tag === Tags.DELETED)
      .map((item) => item._id);
    const itemsToUpdate = form.items
      ?.filter((item) => item.tag === Tags.UPDATED)
      .map((item) => {
        return { _id: item._id, label: item.label, data: item.data };
      });
    if (mode === CrudModes.CREATE) {
      query = {
        url: COLLECTIONS_FIND_ALL.replace(':collection_name', Setup.SETS),
        method: 'POST',
        data: {
          name: setName,
          ...(itemsToCreate?.length && { items: itemsToCreate })
        }
      };
    }
    if (mode === CrudModes.EDIT) {
      query = {
        url: COLLECTIONS_FIND_ONE.replace(':collection_name', Setup.SETS).replace(
          ':id',
          encodeURIComponent(setId || '')
        ),
        method: 'PATCH',
        data: {
          ...(setId !== setName && { setName }),
          ...(itemsToCreate?.length && { itemsToCreate }),
          ...(itemsToDelete?.length && { itemsToDelete }),
          ...(itemsToUpdate?.length && { itemsToUpdate })
        }
      };
    }
    if (query) fetchPatchOfEntity(query);
  };

  const {
    data: patchData,
    loading: loadingPatch,
    fetch: fetchPatchOfEntity,
    error: patchError
  } = useFetch<SetDtoResponse>();

  useEffect(() => {
    onLoading && onLoading(loadingPatch);
  }, [loadingPatch]);

  useEffect(() => {
    if (patchError) {
      if (JSON.stringify(patchError).includes('409')) {
        const message = `${tCommon('errors.actionError', {
          action:
            mode === CrudModes.CREATE ? tCommon('actions.creation') : tCommon('actions.edition'),
          entity: tCommon('entities.sets_one')
        })}. ${tCommon('errors.propertyAlreadyInUse', { property: t('setName') })}`;
        setError('name', { message });

        setToastNotifications([{ message }]);
      } else {
        setToastNotifications([
          {
            message: `${tCommon('errors.actionError', {
              action:
                mode === CrudModes.CREATE
                  ? tCommon('actions.creation')
                  : tCommon('actions.edition'),
              entity: tCommon('entities.sets_one')
            })}
            `
          }
        ]);
      }
    } else if (patchData) {
      onDirtyFields(false);
      onSuccess(setId || getValues('name'), patchData.newSetName || getValues('name'));
    }
  }, [patchData, patchError]);

  useEffect(() => {
    if (patchError) {
      if (patchError?.response?.data?.code == 'invalid_set_empty') {
        setToastNotifications([{ message: t('errors.noItems') }]);
      } else if (patchError?.response?.data?.code == 'invalid_set_existent') {
        setError(`name`, {
          message: t('errors.alreadyExists'),
          type: 'manual'
        });
      } else if (patchError?.response?.data?.code == 'invalid_set_name_used') {
        setError(`name`, {
          message: t('errors.alreadyExists'),
          type: 'manual'
        });
      } else if (patchError?.response?.data?.code == 'set_not_found') {
        setToastNotifications([
          { message: tCommon('errors.entityNotFound', { entity: tCommon('entities.sets_one') }) }
        ]);
      } else if (patchError?.response?.data?.code == 'invalid_set_duplicate_items') {
        setToastNotifications([
          { message: tCommon('errors.entityNotFound', { entity: tCommon('entities.sets_one') }) }
        ]);
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.actionError', {
              entity: tCommon('entities.sets_one'),
              action:
                mode === CrudModes.EDIT ? tCommon('actions.edition') : tCommon('actions.creation')
            })
          }
        ]);
      }
      onDirtyFields(false);
    } else if (patchData) {
      onDirtyFields(false);
      onSuccess(setId || getValues('name'), patchData.newSetName || getValues('name'));
    }
  }, [patchData, patchError]);

  const scrollTop = (ref: RefObject<HTMLDivElement>) => {
    ref.current?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  findErrors(errors);

  return (
    <Container>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {loading ? <Skeleton width={'50%'} sx={{ bgcolor: 'grey.700' }} /> : t(`title.${mode}`)}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '2rem 2rem 0rem 2rem' }}>
        <RenderIf condition={loading}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress />
          </Box>
        </RenderIf>
        <RenderIf condition={!loading}>
          <Box display={'flex'} flexDirection="column" height="100%" width={'100%'} gap="1rem">
            <Controller
              key={'name'}
              defaultValue={setId}
              name={'name'}
              control={control}
              render={({ field }) => (
                <TextFilter
                  id="name"
                  label={
                    <>
                      {t('setName')}
                      <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                    </>
                  }
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors();
                  }}
                  error={!!errors['name']}
                />
              )}
            />

            <AccordionSummary
              sx={{
                marginTop: '1rem',
                flexShrink: '0',
                cursor: 'auto !important'
              }}>
              <Grid
                my="auto"
                display={'flex'}
                gap={'8px'}
                justifyContent="space-between"
                alignItems="baseline">
                <Typography mr="auto" ml="0.5rem">
                  {t('elements')}
                </Typography>
                <Typography variant="body2" fontSize={'12px'}>
                  {tCommon('text.counter', {
                    quantity: fields.filter((el) => el.tag != Tags.DELETED).length
                  })}
                </Typography>
              </Grid>

              <Button
                onClick={createItem}
                sx={{
                  marginLeft: 'auto',
                  borderRadius: '50%',
                  minWidth: '30px',
                  minHeight: '30px',
                  padding: '0'
                }}
                variant="contained">
                <i className="b-fa-solid b-fa-plus"></i>
              </Button>
            </AccordionSummary>
            <ListContainer sx={{ padding: '0.5rem' }} ref={listRef}>
              {fields.filter((el) => el.tag != Tags.DELETED).length === 0 && (
                <Typography sx={{ color: theme.palette.grey[500] }} mr="auto">
                  {t('emptyList')}
                </Typography>
              )}
              {fields.map(({ _id, tag }, index: number) => (
                <RenderIf key={_id} condition={tag != Tags.DELETED}>
                  <Box key={_id} display="flex" alignItems="center" width="100%" gap="0.8rem">
                    <Controller
                      control={control}
                      name={`items.${index}.label` as const}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <TextFilter
                            sx={{ flex: '2' }}
                            name={name}
                            id={`items.${index}.label` as const}
                            inputRef={index === 0 ? lastInputRef : null}
                            value={value}
                            onChange={(e) => {
                              setValue(`items.${index}.tag` as const, onUpdate(tag));
                              onChange(e);
                              if (errors?.items) clearErrors('items');
                            }}
                            error={!!errors.items?.[index]}
                          />
                        );
                      }}
                    />
                    <RenderIf condition={isSystem === SystemSets.Locations}>
                      <Controller
                        control={control}
                        name={`items.${index}.data` as const}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Autocomplete
                              options={luxonValidTimezones}
                              sx={{ flex: '2' }}
                              getOptionLabel={(option) => option || ''}
                              value={(value as any)?.tz || null}
                              onChange={(_, newValue) => {
                                setValue(`items.${index}.tag` as const, onUpdate(tag));
                                onChange(newValue ? { tz: newValue } : null);
                                clearErrors(`items.${index}`);
                              }}
                              renderOption={(props, option) => (
                                <ListRow {...props} key={option}>
                                  {option}
                                </ListRow>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id={`items.${index}.data` as const}
                                  size="small"
                                  label={
                                    <>
                                      {t('timeZone')}
                                      <FaAsterisk
                                        size={12}
                                        style={{ marginLeft: '5px' }}></FaAsterisk>
                                    </>
                                  }
                                  error={!!errors?.['items']?.[index]?.['data']}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    id: `items.${index}.data`
                                  }}
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </RenderIf>
                    <i
                      onClick={() => {
                        deleteItem(index, onDelete(tag));
                      }}
                      style={{ cursor: 'pointer' }}
                      className="b-fa-solid b-fa-trash"></i>
                  </Box>
                </RenderIf>
              ))}
            </ListContainer>
            <Box mt="auto" pb="1rem">
              <Divider />
              <Box display={'flex'} alignItems="baseline" width="100%" gap="0.5rem" mt="0.5rem">
                <Typography color="error" variant="caption">
                  {findErrors(errors).map((err: string) => t(err as any))}
                </Typography>
              </Box>
            </Box>
          </Box>
        </RenderIf>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button disabled={loading || loadingPatch} onClick={(e) => onCancel(isDirty)}>
            {tCommon('buttons.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loadingPatch}
            disabled={
              loading ||
              Object.keys(errors).length > 0 ||
              (mode === CrudModes.EDIT && Object.keys(dirtyFields).length === 0)
            }
            onClick={handleSubmit(submit, toScroll)}>
            {tCommon('buttons.save')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </Container>
  );
};

const findErrors = (errors) => {
  const errorNames: string[] = [];
  if (Array.isArray(errors.items)) {
    errors.items.map((err) => {
      errorNames.push(err?.label?.message);
    });
  } else {
    errorNames.push(errors?.items?.message);
  }
  errorNames.push(errors?.setName?.message);
  return [...new Set(errorNames)];
};

function onUpdate(tag?: string) {
  if (tag == Tags.CREATED) {
    return Tags.CREATED;
  }
  if (tag == Tags.UPDATED) {
    return Tags.UPDATED;
  }
  return Tags.UPDATED;
}

function onDelete(tag?: string) {
  if (tag == Tags.CREATED) {
    return 'null';
  }
  if (tag == Tags.UPDATED) {
    return Tags.DELETED;
  }
  return Tags.DELETED;
}
const ListContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
  overflow-y: auto;
`;

export default SetPanelTemplate;
