export enum GanttNames {
  RESOURCES = 'gantt-resources',
  JOBS = 'gantt-jobs',
  CALENDAR = 'gantt-calendar',
  QUALIFIED_RESOURCES = 'gantt-qualified-resources'
}

export enum GANTT_HIERARCHY {
  CLASSES,
  DAYS,
  TOPICS
}
