import { theme } from '@/theme';
import { StructureStatus } from '@/types';
import { BiMinusCircle } from 'react-icons/bi';
import { GrStatusGoodSmall } from 'react-icons/gr';

export type StatusIconProps = {
  size?: number;
  status: StructureStatus;
};

const StatusIcon = ({ status, size = 0.7 }: StatusIconProps) => {
  const strSize = `${size}rem`;
  let iconColor: string;

  switch (status) {
    case StructureStatus.COMPLETE:
      iconColor = theme.palette.success.main;
      break;
    case StructureStatus.PARTIALLY_SCHEDULED:
      iconColor = theme.palette.warning.main;
      break;
    case StructureStatus.NOT_SCHEDULED:
      iconColor = theme.palette.grey[400];
      break;
    case StructureStatus.CANCELED:
      iconColor = theme.palette.error.main;
      break;
    default:
      return null;
  }

  if (status === StructureStatus.CANCELED)
    return (
      <BiMinusCircle
        style={{
          minWidth: strSize,
          minHeight: strSize,
          maxWidth: strSize,
          maxHeight: strSize
        }}
        color={iconColor}
      />
    );
  return (
    <GrStatusGoodSmall
      style={{
        minWidth: strSize,
        minHeight: strSize,
        maxWidth: strSize,
        maxHeight: strSize
      }}
      color={iconColor}
    />
  );
};

export default StatusIcon;
