import { Box, Skeleton } from '@mui/material';
import { CardContainer } from './CardSkeleton.style';

const CardSkeleton = () => {
  return (
    <CardContainer>
      <Skeleton variant="rectangular" height="30px" width={60} sx={{ borderRadius: '30px' }} />
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column">
        <Skeleton variant="text" width="65%" />
        <Skeleton variant="text" width="35%" />
      </Box>
      <Skeleton variant="circular" width="20px" />
    </CardContainer>
  );
};

export default CardSkeleton;
