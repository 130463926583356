import { styled } from '@mui/material';

export const SessionContainer = styled('div')<Record<string, any>>`
  position: relative;
  width: 100%;
  min-height: 2.6rem;
  height: fit-content;
  display: flex;
  overflow: auto;
  align-items: center;
  padding: 0.5rem 0.2rem;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

// background-color: ${(props) =>
//     props.snapshot.isDraggingOver ? theme.palette.divider : theme.palette.common.white};
