import { PropertyTypes } from '@/const';
import { removeEmptyFields } from '@/services/utils/utils';
import { PropertyDto, PropertySwitchDto, PropertyValue } from '@/types';

export const getDefaultPropertiesAsFormValues = (
  properties: PropertyDto[],
  entityData: { properties: PropertyValue[] }
) => {
  const propertiesIds = properties.map((prop) => prop._id);
  const newValues = entityData.properties
    .filter((prop) => propertiesIds.includes(prop._id))
    .reduce<Record<string, unknown>>(
      (acc, { _id, value, label }) => ({
        ...acc,
        [_id]: label ? { _id: value, label } : value
      }),
      {}
    );
  return newValues;
};

export const filterPropsBySwitches = (properties: PropertyDto[], switches: PropertySwitchDto[]) => {
  const validPropsArr = switches.map(({ property }) => property);
  return properties.filter((properties) => !validPropsArr.includes(properties._id));
};

export const filterPropsByEntityData = (
  properties: PropertyDto[],
  entityData: { properties: PropertyValue[] },
  switches: PropertySwitchDto[]
) => {
  const dataProps = entityData.properties.map(({ _id }: PropertyValue) => _id);
  const entityProps = switches
    .filter((switchProp) => {
      !dataProps.includes(switchProp.property);
    })
    .map(({ property }) => property);

  return properties.filter((value: PropertyDto) => !entityProps.includes(value._id));
};

export const formatForm = (properties: PropertyDto[], form: Record<string, any>) => {
  removeEmptyFields(form);
  const formattedForm = properties.map((row) => {
    return {
      _id: row._id,
      value:
        (row.type === PropertyTypes.SET || row.type === PropertyTypes.OBJECTID) &&
        typeof form[row._id] !== 'string'
          ? form[row._id]?._id
          : form[row._id]
    };
  });

  return formattedForm.filter((prop) => prop.value !== undefined && prop.value !== null);
};
