import { InferType, object, ref, string } from 'yup';

export const changePasswordSchema = object({
  currentPassword: string().required(),
  newPassword: string().required().min(6),
  confirmNewPassword: string()
    .required('Confirm Password is required')
    .oneOf([ref('newPassword')], 'Passwords must match')
});

export type ChangePasswordSchemaType = InferType<typeof changePasswordSchema>;
