import { Entities, PropertyTypes, SystemFilters } from '@/const';
import { FieldFilterType, PropertyDto, RecordDto, ResourceBryntum } from '@/types';
import { sortingByFetch, transformPropertyValue } from '@/utils';
import { BryntumGrid } from '@bryntum/grid-react-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { Skeleton } from '@mui/material';
import { TFunction } from 'i18next';
import { ForwardedRef, RefObject } from 'react';

export const extraFields: FieldFilterType[] = [
  {
    name: 'Document',
    _id: SystemFilters.RESOURCE_RECORDS_DOCUMENTS,
    type: PropertyTypes.OBJECTID,
    order: 0,
    referencedCollection: Entities.DOCUMENTS
  }
];

export const getColumns = (
  ref: ForwardedRef<BryntumGrid>,
  t: TFunction,
  properties?: PropertyDto[]
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumGrid>).current?.instance
    .columns as ColumnStore;
  columnStore.removeAll();
  if (properties) {
    columnStore.add([
      {
        id: 'counter',
        type: 'rownumber',
        cls: 'counter-header',
        cellCls: 'b-border-bottom',
        align: 'center',
        htmlEncodeHeaderText: false,
        hideable: false
      },
      {
        text: 'Document type',
        id: 'Type',
        field: 'documentType',
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record: { data } }) => {
          return !data.record ? (
            <Skeleton height={30} />
          ) : data?.record?.document.type?.label ? (
            data.record.document.type.label
          ) : (
            ''
          );
        }
      },
      {
        text: 'Document name',
        id: 'Name',
        field: 'documentName',
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record: { data } }) => {
          return !data.record ? (
            <Skeleton height={30} />
          ) : data?.record?.document?.label ? (
            data.record.document.label
          ) : (
            ''
          );
        }
      },
      ...(properties as PropertyDto[])
        .sort((p1, p2) => p1._id.localeCompare(p2._id))
        .map(({ _id: id, name: text, type }) => ({
          id,
          field: id,
          text,
          align: type == PropertyTypes.NUMBER ? 'right' : 'left',
          sortable: sortingByFetch,
          groupable: false,
          renderer: ({ record: { data } }) => {
            return !data.record ? (
              <Skeleton height={30} />
            ) : (
              transformPropertyValue(type, t, data[id])
            );
          }
        }))
    ]);
  } else {
    columnStore.add([
      ...new Array(5).fill('').map((element) => ({
        text: '...',
        width: 220,
        renderer: () => {
          return <Skeleton height={30} />;
        }
      }))
    ]);
  }
};

export const transformRecords = (records?: RecordDto[]) => {
  let localRecords: ResourceBryntum[];

  if (records)
    localRecords = records.map((record) => {
      const localResource: ResourceBryntum = {
        id: record._id,
        record
      };
      record.properties.forEach(
        (property) => (localResource[property._id] = property.label ?? property.value)
      );
      return localResource;
    });
  else localRecords = new Array(5).fill('').map((_, idx) => ({ id: `${idx}` }));
  return localRecords;
};
