import { RenderIf } from '@/components/atoms';
import { MultipleInputRow } from '@/components/molecules';
import {
  COMPONENTS_BY_TYPE,
  validateByType,
  validateRequired
} from '@/components/organisms/PropertiesForm/PropertiesForm.const';
import { AutoCompleteField } from '@/const';
import { useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { theme } from '@/theme';
import { CurriculumDto, MultipleInputValue, TimeStrategy, Topic } from '@/types';
import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';
import { FORM_TYPES, makeForm } from '../Curriculum/CurriculumForm.const';
import { shouldIgnore } from './TopicForm.const';
import FormTopicSlots from './TopicSlotForm/TopicSlotForm';

const TopicForm = () => {
  const { readOnly, selectedTopics, selectedSessions } = useCurriculumSetupContext();

  if (!selectedTopics.length) return null;

  const topicIndex = selectedTopics[0].index;
  const sessionIndex = selectedTopics[0].sourceSession.index;
  const multiple = selectedTopics.length > 1 && !readOnly;

  const {
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext<CurriculumDto>();
  const { t: tTopic } = useTranslation('organisms/topic');
  const { t: tCurr } = useTranslation('templates/curriculumsTemplate');

  const formProperties = useMemo(
    () => makeForm(FORM_TYPES.TOPICS),
    [selectedSessions, selectedTopics]
  );
  const getValueAndPlaceholder = useCallback(
    (name: keyof Topic) => {
      const values: Array<Topic[keyof Topic]> = [];
      const sessions = getValues(`structure.sessions`);
      for (const slctedTopic of selectedTopics) {
        const session = sessions[slctedTopic.sourceSession.index];
        if (shouldIgnore(session, selectedTopics, name)) continue;

        const topic = sessions[slctedTopic.sourceSession.index].topics[slctedTopic.index];
        if (topic) values.push(topic[name]);
      }
      const emptyValues = ['', null];
      const uniqueValues = [
        ...new Set(
          values.map((v) => (emptyValues.some((emptyValue) => emptyValue === v) ? undefined : v))
        )
      ];
      if (uniqueValues.length === 1) {
        return { value: uniqueValues[0] };
      }
      return {
        value: { multiple: true } as MultipleInputValue
      };
    },
    [selectedTopics]
  );
  const onChange = useCallback(
    (name: keyof Topic) => {
      return (value) => {
        const sessions = getValues(`structure.sessions`);
        for (const slctedTopic of selectedTopics) {
          const session = sessions[slctedTopic.sourceSession.index];
          if (shouldIgnore(session, selectedTopics, name)) continue;
          setValue(
            `structure.sessions.${slctedTopic.sourceSession.index}.topics.${slctedTopic.index}.${name}`,
            value,
            { shouldDirty: true }
          );
        }
      };
    },
    [selectedTopics]
  );
  const isParentFixed = useMemo(() => {
    return selectedTopics.some(
      (topic) =>
        getValues(`structure.sessions.${topic.sourceSession.index}`).timeStrategy ===
        TimeStrategy.START_TIMES
    );
  }, [selectedTopics]);
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height={selectedTopics.length > 1 ? 'calc(100% - 2.5rem)' : '100%'}
        overflow={'auto'}
        flexGrow="1">
        <Grid display={'flex'} width={'100%'} flexDirection="column" gap={'1rem'} p="1.5rem 1rem">
          <React.Fragment key={`${sessionIndex}_${topicIndex}`}>
            {formProperties.map(({ name, required, type, componentProps = {}, ...rest }) => {
              const Component = COMPONENTS_BY_TYPE[type];
              const validations = rest.validations || {};
              if (!isParentFixed && name === 'startTime') return null;
              return (
                <React.Fragment key={`${sessionIndex}_${topicIndex}_${name}`}>
                  <Controller
                    name={`structure.sessions.${sessionIndex}.topics.${topicIndex}.${name}` as any}
                    control={control}
                    rules={{
                      validate: {
                        type: validateByType(type),
                        required: validateRequired(required, type),
                        ...validations
                      }
                    }}
                    render={({ field }) => {
                      const { value } = getValueAndPlaceholder(name);
                      return (
                        <MultipleInputRow
                          value={field.value}
                          fitInput={multiple}
                          additionalClearRenderConditions={multiple}
                          onChange={(value) => {
                            field.onChange(value);
                            onChange(name)(value);
                          }}>
                          <Component
                            disabled={readOnly}
                            {...componentProps}
                            label={
                              <>
                                {tTopic(`properties.${name}` as any)}
                                {required && (
                                  <FaAsterisk size={12} style={{ marginLeft: '5px' }}></FaAsterisk>
                                )}
                              </>
                            }
                            includeMultipleObj
                            name={`topic_${name}`}
                            autoComplete={AutoCompleteField}
                            value={value}
                            onChange={(value) => {
                              if (!!value) {
                                field.onChange(value);
                                onChange(name)(value);
                              }
                            }}
                            error={
                              !!errors.structure?.sessions?.[sessionIndex]?.topics?.[topicIndex]?.[
                                name
                              ]
                            }
                          />
                        </MultipleInputRow>
                      );
                    }}
                  />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        </Grid>

        <FormTopicSlots />
      </Box>
      <RenderIf condition={selectedTopics.length > 1}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            height: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            position: 'fixed',
            bottom: '0px'
          }}>
          <Typography pl="1rem" variant="body3" component="h6">
            {tCurr('informationNote', {
              quantity: selectedTopics.length,
              element: tCurr('elements.topics', {
                count: selectedTopics.length
              })
            })}
          </Typography>
        </Box>
      </RenderIf>
    </>
  );
};

export default TopicForm;
