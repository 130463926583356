import { ContextMenuBase, Model } from '@bryntum/core-thin';
import {
  TaskMenu, 
  TaskModelConfig
} from '@bryntum/gantt-thin';
import { Column, Grid } from '@bryntum/grid-thin';
import { EventDto, JobDto, PropertyValue } from '../dto';
import {EventMenu} from "@bryntum/scheduler-thin"; 
import { EventModelConfig  } from '@bryntum/schedulerpro-thin';

export type BryntumCellMenuContext = {
  record: Model;
  column: Column;
} & ContextMenuBase['menuContext'];

export type BryntumCellMenuContextGantt = {
  record: Model;
  column: Column;
} & ContextMenuBase['menuContext'];

export type BryntumEventMenuContextGantt = Partial<TaskMenu> & ContextMenuBase['menuContext'];

export type BryntumEventMenuContext = EventMenu['menuContext'] & ContextMenuBase['menuContext'];

export type BryntumEventMenuContextPro = EventMenu['menuContext'] &
  ContextMenuBase['menuContext'];

export type ResourceBryntum<T = unknown> = Record<string, T> & { id: string };

export type AssignmentBryntum = {
  id: string;
  resourceId: string;
  eventId: string;
  joined: boolean;
  joinedAssignments: AssignmentBryntum[] | undefined;
  showAsJoined: boolean;
  event?: number;
  resource?: number;
};

export type AssignmentBryntumPro = {
  id: string;
  resourceId: string;
  eventId: string;
  joined: boolean;
  joinedAssignments: AssignmentBryntumPro[] | undefined;
  showAsJoined: boolean;
  event?: number;
  resource?: number;
};

export type AssignmentBryntumGantt = {
  id: string;
  event?: string;
  resource?: string;
};

export type DbClickEvent = {
  grid: Grid ;
  record: Model ;
  column: Column;
  cellElement: HTMLElement;
  target: HTMLElement;
  event: MouseEvent;
};
type EventModel = Omit<Partial<EventModelConfig>, 'resources'>;
type EventModelPro = Omit<Partial<EventModelConfig>, 'resources'>;

export type EventBryntum<T = unknown> = EventModel & {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  eventColor: string;
  eventStyle: string;
  join?: string;
  job?: string[];
  series?: string;
  cls?: string;
  iconCls?: string;
} & T;

export type EventProBryntum<T = unknown> = EventModelPro & {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  eventColor: string;
  eventStyle: string;
  join?: string;
  job?: string[];
  series?: string;
  cls?: string;
  iconCls?: string;
} & T;

type TaskModel = Omit<Partial<TaskModelConfig>, 'resources'>;

export type EventTaskBryntum<T = unknown> = TaskModel & {
  id: string;
  name: string;
  startDate: string | Date;
  endDate: string | Date;
  eventColor?: string;
  style?: string;
  join?: string;
  seeToolTip?: boolean;
  job?: string[];
  duration?: number;
  cls?: string;
  eventSession?: EventDto;
  taskIconCls?: string;
  children: TaskModel[];
} & T;

export type JobtoTask<T = unknown> = JobDto & EventTaskBryntum;

export type JoinedEventBryntum<T = unknown> = {
  job?: string[];
  join?: string;
  joinedAssignments?: Map<string, AssignmentBryntum>;
} & EventBryntum<T>;

export type JoinedEventProBryntum<T = unknown> = {
  job?: string[];
  join?: string;
  joinedAssignments?: Map<string, AssignmentBryntumPro>;
} & EventProBryntum<T>;

export type OnItemEvent<T = unknown> = {
  selection: {
    data: T;
  }[];
};

export type OnItem<T = unknown> = {
  (event: OnItemEvent<T>): void;
};

export type EventTooltipFeatureData<T> = {
  eventRecord: {
    originalData: T;
  };
};

export type TaskTooltipFeatureData<T> = {
  taskRecord: {
    originalData: T;
  };
};

export type EventTooltipFeature<T = unknown> = {
  (data: EventTooltipFeatureData<T>): void;
};

export type BryntumSorter = {
  ascending: boolean;
  field: string;
};

export const TEMPORAL_CHILD_ID = 'temporal_child';
