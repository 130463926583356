import { Box, List, styled } from '@mui/material';

export const ResourceList = styled(List)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-block: 0.5rem;
  margin-left: 2.5rem;
  gap: 0.5rem;
  min-height: 0;
`;

export const ResourceContainer = styled(Box)`
  :hover {
    filter: brightness(98%);
  }
  background-color: ${({ theme }) => theme.palette.common.white};
  transition: background-color 0.2s ease-in-out;
  padding-left: 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  flex: 1;
  height: 2.396rem;
  width: 100%;
  border-radius: 5px;
`;
