import { Grid, AppBar as MuiAppBar, Toolbar as MuiToolbar, styled } from '@mui/material';
import { FiLock, FiLogOut } from 'react-icons/fi';

export const AppBar = styled(MuiAppBar)`
  // background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

export const Toolbar = styled(MuiToolbar)`
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  height: 3rem;
  min-height: auto;

  @media (min-width: 600px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
`;

export const Brand = styled('img')`
  width: 3.559rem;
  height: 100%;
  object-fit: contain;
`;

export const GridElements = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  align-items: center;
  flex: 1 1 auto;
  height: 3rem;
  & > * {
    height: 100%;
    & > * {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
`;

export const ScheduleIcon = styled('img')`
  height: 1.6rem;
`;

export const FiLogOutStyled = styled(FiLogOut)`
  margin-right: 1rem;
`;
export const FiLockStyled = styled(FiLock)`
  margin-right: 1rem;
`;
