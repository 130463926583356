import { styled } from '@mui/material';

export const ColorIconBox = styled('div')<{ value: string; boxSize: number }>`
  background-color: ${(props) => props.value};

  width: ${(props) => props.boxSize}px;
  height: ${(props) => props.boxSize}px;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.46);
`;
