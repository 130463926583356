import { PropertyTypes } from '@/const';
import { findMatchingObjectsByProp, sortAlphaArray } from '@/services/utils/utils';
import { ResourceBryntum, UserDto, UserRoleDto } from '@/types';
import { transformPropertyValue } from '@/utils';
import { Model } from '@bryntum/core-thin';
import { BryntumGrid, BryntumGridProps } from '@bryntum/grid-react-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { Skeleton } from '@mui/material';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { ForwardedRef, RefObject } from 'react';

export const userAttributes = [
  { label: 'email', type: PropertyTypes.STRING },
  { label: 'roles', type: PropertyTypes.STRING },
  { label: 'resource', type: PropertyTypes.SET },
  { label: 'status', type: PropertyTypes.STRING }
] as const;

export const roleAttributes = [{ label: 'name', type: PropertyTypes.STRING }] as const;

export type AttributeDto = {
  label: string;
  type: string;
};

export const getGridConfig: BryntumGridProps = {
  animateRemovingRows: false,
  columns: [
    ...Array(4)
      .fill('')
      .map(() => ({
        text: '...',
        width: 220
      }))
  ],
  filterBarFeature: false,
  stripeFeature: true,
  selectionMode: {
    row: true,
    cell: false,
    deselectOnClick: false
  },
  cellEditFeature: false,
  rowHeight: 32
};

export const transformUsers = (users?: UserDto[]) => {
  let localUsers: ResourceBryntum[];

  if (users)
    localUsers = users.map((user) => {
      const localResource: ResourceBryntum = {
        id: user._id,
        user
      };
      return localResource;
    });
  else localUsers = new Array(5).fill('').map((_, idx) => ({ id: `${idx}` }));
  return localUsers;
};

export const transformRoles = (roles?: UserRoleDto[]) => {
  let localRoles: ResourceBryntum[];
  if (roles)
    localRoles = roles.map((role) => {
      return {
        id: role._id,
        role
      } as ResourceBryntum;
    });
  else {
    localRoles = new Array(5).fill('').map((_, idx) => ({ id: `${idx}` }));
  }
  return localRoles;
};

export const getColumnsUser = (
  ref: ForwardedRef<BryntumGrid>,
  t: TFunction,
  tCommon: TFunction,
  isLoadingUsers: boolean,
  rolesData: UserRoleDto[]
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumGrid>).current?.instance
    .columns as ColumnStore;
  const currentCount = columnStore?.getById('counter')?.text ?? '#';
  columnStore.removeAll();

  columnStore.add([
    {
      id: 'counter',
      type: 'rownumber',
      field: 'counter',
      cls: 'counter-header',
      cellCls: 'b-border-bottom',
      align: 'center',
      htmlEncodeHeaderText: false,
      hideable: false,
      text: currentCount
    },
    ...userAttributes.map(({ label, type }) => ({
      id: label,
      groupable: false,
      resizable: true,
      text: t(`attributes.${label}`),
      width: 'auto',
      minWidth: '6rem',
      flex: '1 1 6rem',
      align: 'left',
      sortable: (record1: Model, record2: Model) => {
        const user1: UserDto = record1.getData('user');
        const user2: UserDto = record2.getData('user');
        switch (label) {
          case 'email': {
            const name1 = user1.email.toLowerCase();
            const name2 = user2.email.toLowerCase();
            if (name1 < name2) return -1;
            if (name1 > name2) return 1;
            return 0;
          }
          case 'resource': {
            const name1 = user1.resource.label.toLowerCase();
            const name2 = user2.resource.label.toLowerCase();
            if (name1 < name2) return -1;
            if (name1 > name2) return 1;
            return 0;
          }
          case 'roles': {
            const name1 = sortAlphaArray(
              findMatchingObjectsByProp(user1?.[label], rolesData, '_id').map((role) => role.name)
            ).map((role, index) => {
              if (index == 0) {
                return role;
              }
              return `, ${role}`;
            });
            const name2 = sortAlphaArray(
              findMatchingObjectsByProp(user2?.[label], rolesData, '_id').map((role) => role.name)
            ).map((role, index) => {
              if (index == 0) {
                return role;
              }
              return `, ${role}`;
            });
            if (name1 < name2) return -1;
            if (name1 > name2) return 1;
            return 0;
          }
          case 'status': {
            let record1Status;
            let record2Status;
            if (user1?.userDbId) {
              record1Status = t('statusOptions.active');
            } else if (
              DateTime.fromISO(user1?.expiryDate || '', { zone: 'utc' }) < DateTime.utc()
            ) {
              record1Status = t('statusOptions.inviteExpired');
            } else {
              record1Status = t('statusOptions.invited');
            }
            if (user2?.userDbId) {
              record2Status = t('statusOptions.active');
            } else if (
              DateTime.fromISO(user2?.expiryDate || '', { zone: 'utc' }) < DateTime.utc()
            ) {
              record2Status = t('statusOptions.inviteExpired');
            } else {
              record2Status = t('statusOptions.invited');
            }
            if (record1Status < record2Status) return -1;
            if (record1Status > record2Status) return 1;
            return 0;
          }
        }
      },
      renderer: ({ record: { data } }) => {
        if (label == 'status' && !isLoadingUsers) {
          if (data.user?.userDbId) {
            return t('statusOptions.active');
          } else if (DateTime.fromISO(data.user?.expiryDate, { zone: 'utc' }) < DateTime.utc()) {
            return t('statusOptions.inviteExpired');
          } else {
            return t('statusOptions.invited');
          }
        }
        if (isLoadingUsers) {
          return <Skeleton height={30} />;
        } else {
          if (label == 'roles') {
            return sortAlphaArray(
              findMatchingObjectsByProp(data.user?.[label], rolesData, '_id').map(
                (role) => role.name
              )
            ).map((role, index) => {
              if (index == 0) {
                return role;
              }
              return `, ${role}`;
            });
          }
          if (label == 'resource') {
            if (data.user?.[label]) {
              return data.user[label].label;
            }
            return '';
          }
          return transformPropertyValue(type, tCommon, data.user[label]);
        }
      }
    }))
  ]);
};
export const getColumnsRoles = (
  ref: ForwardedRef<BryntumGrid>,
  t: TFunction,
  tCommon: TFunction,
  isLoadingRoles: boolean
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumGrid>).current?.instance
    .columns as ColumnStore;
  columnStore.removeAll();

  columnStore.add([
    // {
    //   id: 'counter',
    //   type: 'rownumber',
    //   field: 'counter',
    //   cls: 'counter-header',
    //   cellCls: 'b-border-bottom',
    //   align: 'center',
    //   htmlEncodeHeaderText: false,
    //   hideable: false
    // },
    ...roleAttributes.map(({ label, type }) => ({
      id: label,
      groupable: false,
      resizable: true,
      text: t(`attributes.${label}`),
      cls: 'header-line-height',
      width: 'auto',
      minWidth: '6rem',
      flex: '1 1 6rem',
      align: 'left',
      sortable: false,
      renderer: ({ record: { data } }) => {
        return data.role?.[label] == undefined || isLoadingRoles ? (
          <Skeleton height={30} />
        ) : (
          transformPropertyValue(type, tCommon, data.role[label])
        );
      }
    }))
  ]);
};
