import { isArray, isEmpty, isObject } from 'lodash';
import { FieldErrors } from 'react-hook-form';

const deepSearch = (rootErrors: any, rootElements: any[] = []) => {
  if (isArray(rootErrors)) {
    rootErrors.forEach((element) => {
      if (element?.ref) rootElements.push(element.ref);
      return deepSearch(element, rootElements);
    });
  }
  if (isObject(rootErrors)) {
    if ((rootErrors as any).ref) rootElements.push((rootErrors as any).ref);
    Object.keys(rootErrors).forEach((name) => {
      const element = rootErrors[name];
      if (element?.ref) rootElements.push(element.ref);
      return deepSearch(element, rootElements);
    });
  }
  return rootElements;
};

export const useScrollToError = () => {
  const toScroll = async (errors: FieldErrors) => {
    if (!isEmpty(errors)) {
      const output: { name: string }[] = deepSearch(errors);
      const elements = (
        output
          .map(({ name }) => document.getElementById(name))
          .filter((element) => !!element) as HTMLElement[]
      ).toSorted((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);

      if (elements.length > 0) {
        const errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        errorElement.focus({ preventScroll: true });
      }
    }
  };
  return {
    toScroll
  };
};
