import { useReducer } from 'react';

export type ResourcePosition = {
  slotIndex: number;
  resourceIndex: number;
};
type ResourceDictionary = Record<string, ResourcePosition[]>;

type State = {
  resourceDictionary: ResourceDictionary;
};

export type ActionResource =
  | { type: 'ADD_RESOURCE'; position: ResourcePosition; resourceId: string }
  | { type: 'ADD_RESOURCES'; positions: ResourcePosition[]; resourceIds: string[] }
  | { type: 'REMOVE_RESOURCE'; position: ResourcePosition; resourceId: string };

const reducer = (state: State, action: ActionResource): State => {
  switch (action.type) {
    case 'ADD_RESOURCE':
      if (!state.resourceDictionary[action.resourceId])
        state.resourceDictionary[action.resourceId] = [];
      state.resourceDictionary[action.resourceId].push(action.position);
      return { ...state };
    case 'ADD_RESOURCES':
      action.resourceIds.forEach((resourceId, index) => {
        if (!state.resourceDictionary[resourceId]) state.resourceDictionary[resourceId] = [];
        state.resourceDictionary[resourceId].push(action.positions[index]);
      });
      return { ...state };
    case 'REMOVE_RESOURCE':
      state.resourceDictionary[action.resourceId] = state.resourceDictionary[
        action.resourceId
      ].filter((position) => {
        if (
          position.slotIndex === action.position.slotIndex &&
          position.resourceIndex === action.position.resourceIndex
        )
          return false;
        return true;
      });
      return { ...state };

    default:
      return state;
  }
};
export const useResourceReducer = (initialState: State) => {
  return useReducer(reducer, initialState);
};
