import { Box, CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }}>
        <CircularProgress size={20} style={{ marginRight: 8 }} color="primary" /> Loading ...
      </Box>
    </>
  );
};

export default Loading;
