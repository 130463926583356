import isologoAnimated from '@/assets/imgs/animacion-logo-xo.gif';
import isologoStopped from '@/assets/imgs/xo-sheduler-1.png';
import { RenderIf } from '@/components/atoms';
import { BoxProgress } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import ScheduleSummary from '@/components/organisms/ScheduleSummary';
import {
  DATETIME_FORMAT_PICKER,
  FinishedErrorStates,
  FinishedOKStates,
  FinishedStates,
  RunningDetailedStates,
  RunningStates,
  SCHEDULING_PROCESS_ACTION,
  ScheduleModes,
  ScheduleProcessStatus,
  locale
} from '@/const';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useScheduleConfigContext } from '@/context/ScheduleConfigContext';
import { useScheduleContext } from '@/context/SchedulerContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { truncate } from '@/services/utils/utils';
import { ButtonsContainer } from '@/theme';
import { StatsDto } from '@/types';
import { dateDiffInSeconds } from '@/utils';
import { LoadingButton } from '@mui/lab';
import { Button, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useEffect, useState } from 'react';
import { default as Chart } from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { PeriodChart, ValueChart, chartOptions } from '../../ScheduleResultTemplate.schema';
import {
  BigTitle,
  CardsContainer,
  CardsInnerContainer,
  CardsOuterContainer
} from '../../ScheduleResultTemplate.styles';

export const ScheduleResultComplete = () => {
  const [dataChart, setDataChart] = useState<[number, ValueChart][]>([
    [0, { value: undefined, slotsToFill: undefined, slotsFilled: undefined }]
  ]);
  const [phase, setPhase] = useState<number>(1);
  const [status, setStatus] = useState<string>('');
  const [detailedStatus, setDetailedStatus] = useState<string | undefined>();
  const [startTime, setStartTime] = useState<string>();
  const [runningTime, setRunningTime] = useState<number>();
  const [periods, setPeriods] = useState<PeriodChart[]>([]);
  const [finished, setFinished] = useState<boolean>(false);
  const [resultStats, setResultStats] = useState<StatsDto>();
  const { schedulableJobs } = useScheduleConfigContext();
  const [error, setError] = useState<string | undefined>(undefined);
  const { t } = useTranslation('templates/scheduleResultTemplate');
  const { t: tCommon } = useTranslation();
  const { currentProcess } = useScheduleContext();
  const { setToastNotifications } = useToastContext();
  const { fetch, loading: loadingActions, error: errorActions } = useFetch();
  const { handleClose } = useGenericModalContext();

  useEffect(() => {
    if (errorActions) {
      setToastNotifications([{ message: t('errors.actions') }]);
    }
  }, [errorActions]);

  const series: ApexAxisChartSeries = [
    {
      name: t('progress'),
      data: dataChart.map((data) => [data[0], data[1].value ?? 0])
    }
  ];

  const byPeriods = currentProcess?.config?.byPeriods ?? false;

  useEffect(() => {
    if (currentProcess) {
      setStartTime(
        DateTime.fromISO(currentProcess.startTime)
          .setLocale(locale)
          .toFormat(DATETIME_FORMAT_PICKER)
      );
      setFinished(FinishedStates.includes(currentProcess.status.toUpperCase()));
      if (currentProcess.endTime) {
        setRunningTime(dateDiffInSeconds(currentProcess.startTime, currentProcess.endTime));
      }

      const details = currentProcess.details
        ? currentProcess.details[ScheduleModes.COMPLETE]
        : null;
      const phaseInfo = details?.phase_infos?.length > 0 ? details.phase_infos[0] : null;

      let newStatus;

      if (currentProcess.status == ScheduleProcessStatus.FAULTED.name) {
        const endTime = DateTime.fromISO(currentProcess.endTime ?? '');
        const startTime = DateTime.fromISO(currentProcess.startTime);
        const runningTime = endTime.diff(startTime).toMillis() / 1000;
        setResultStats({
          jobs_to_process: 0,
          jobs_processed: 0,
          running_time: runningTime,
          slots_filled: -1,
          slots_to_fill: -1
        });
        newStatus = phaseInfo?.inner_status?.toUpperCase();
      }
      newStatus = newStatus ?? currentProcess.status?.toUpperCase() ?? '';
      setStatus(newStatus);

      let newDetailedStatus = RunningDetailedStates.includes(newStatus) ? newStatus : undefined;

      if (details) {
        if (details.errorMsg) {
          setError(details.errorMsg);
          if (byPeriods) {
            setPeriods([
              {
                percentage: tCommon('utils.noValue'),
                state: newStatus,
                jobs_processed: 0,
                jobs_to_process: 0,
                running_time: 0,
                slots_filled: -1,
                slots_to_fill: -1
              }
            ]);
          }
        } else if (byPeriods) {
          if (phaseInfo) {
            setPhase(phaseInfo.phase);
            setDataChart([
              [
                0,
                {
                  value: 0,
                  slotsToFill: undefined,
                  slotsFilled: undefined
                }
              ],
              ...(phaseInfo.percentage_history ?? []).map((item) => [
                item.time,
                {
                  value: item.pct,
                  slotsToFill: phaseInfo.stats.slots_to_fill,
                  slotsFilled: item.slots
                }
              ])
            ]);
          }
          setPeriods(() => {
            const totalPeriods: PeriodChart[] = [];
            for (let i = 0; i < details.total_phases; i++) {
              const phaseInfoI = details.phase_infos.find((phase) => phase.phase == i + 1);
              const phaseState = phaseInfoI?.inner_status?.toUpperCase();
              const phaseStats = phaseInfoI?.stats;
              const slotsToFill = phaseStats?.slots_to_fill;
              totalPeriods.push({
                percentage:
                  (isUndefined(slotsToFill) || slotsToFill == 0) &&
                  phaseState === ScheduleProcessStatus.FINISHED.name
                    ? tCommon('utils.n/a')
                    : phaseInfoI?.percentage ?? tCommon('utils.noValue'),
                state: phaseState ?? '',
                detailedState:
                  !isUndefined(phaseState) &&
                  !FinishedOKStates.includes(phaseState) &&
                  !FinishedErrorStates.includes(phaseState)
                    ? RunningDetailedStates.includes(phaseState)
                      ? phaseState
                      : ScheduleProcessStatus.RUNNING.name
                    : undefined,
                jobs_processed: phaseStats?.jobs_processed ?? 0,
                jobs_to_process: currentProcess?.config.jobs.length ?? 0,
                running_time: phaseStats?.running_time ?? 0,
                slots_filled: phaseStats?.slots_filled ?? -1,
                slots_to_fill: slotsToFill ?? -1
              });
            }
            return totalPeriods;
          });
        } else {
          if (phaseInfo) {
            if (phaseInfo.optimization_time > 0) {
              setDataChart([
                [
                  0,
                  {
                    value: 0,
                    slotsToFill: undefined,
                    slotsFilled: undefined
                  }
                ],
                ...(phaseInfo.percentage_history ?? []).map((item) => [
                  item.time,
                  {
                    value: item.pct,
                    slotsToFill: phaseInfo.stats.slots_to_fill,
                    slotsFilled: item.slots
                  }
                ])
              ]);
            }

            const innerStatus = phaseInfo.inner_status?.toUpperCase();
            if (!!innerStatus && RunningDetailedStates.includes(innerStatus)) {
              newDetailedStatus = innerStatus;
            }
          }

          if (currentProcess.status != ScheduleProcessStatus.FAULTED.name) {
            setResultStats({
              ...details?.stats,
              jobs_to_process: schedulableJobs
                ? schedulableJobs.length
                : details?.stats.jobs_processed ?? 0
            });
          }
        }
      }

      setDetailedStatus(newDetailedStatus);
    }
  }, [currentProcess]);

  const stopProcess = () => {
    if (currentProcess?._id) {
      fetch({
        url: SCHEDULING_PROCESS_ACTION,
        method: 'PATCH',
        data: {
          processId: currentProcess._id,
          action: 'stop'
        }
      });
    }
  };

  const nextProcess = () => {
    if (currentProcess?._id) {
      fetch({
        url: SCHEDULING_PROCESS_ACTION,
        method: 'PATCH',
        data: {
          processId: currentProcess._id,
          action: 'nextPeriod',
          argument: phase
        }
      });
    }
  };

  const options = {
    ...chartOptions(
      t,
      tCommon,
      dataChart.map((data) => data[1].slotsFilled),
      dataChart.map((data) => data[1].slotsToFill)
    ),
    chart: {
      id: `basic-bar${Math.random()}`,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`
    }
  };

  const getPercentage = (dataChart: [number, ValueChart][]) => {
    const { slotsToFill, value } = dataChart[dataChart.length - 1][1];
    if (
      (isUndefined(slotsToFill) || slotsToFill === 0) &&
      status === ScheduleProcessStatus.FINISHED.name
    ) {
      return tCommon('utils.n/a');
    }
    if (isUndefined(value)) {
      return tCommon('utils.noValue');
    }
    return truncate(value, 2);
  };

  const getTime = (status: string, updated_time?: string, running_time = 0) => {
    if (!updated_time) return;

    if (!RunningStates.includes(status)) {
      return Duration.fromObject({ seconds: running_time });
    }
    return DateTime.now().diff(DateTime.fromISO(updated_time)).plus({ seconds: running_time });
  };

  return (
    <Grid
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      minWidth={'100%'}
      height={'100%'}>
      <HeaderModalContainer textAlign={'center'} display="flex" justifyContent="center" gap="1rem">
        <img
          style={{ height: '1.6rem' }}
          src={RunningStates.includes(status) ? isologoAnimated : isologoStopped}
        />
        <BigTitle variant="h5">{finished ? t('titleResult') : t('title')}</BigTitle>
      </HeaderModalContainer>
      <BodyModalContainer>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: 'wrap'
          }}>
          <Typography variant="h6">
            {startTime
              ? t('startDate', {
                  date: startTime
                })
              : t('initializing')}
          </Typography>
          {!!currentProcess?.startedBy?.label && (
            <Typography variant="h6" sx={{ textAlign: 'end' }}>
              {t('by', { name: currentProcess.startedBy.label })}
            </Typography>
          )}
        </Grid>
        <RenderIf condition={byPeriods}>
          <CardsOuterContainer>
            <CardsInnerContainer>
              <CardsContainer>
                {periods.map(
                  (
                    {
                      percentage,
                      state,
                      detailedState,
                      slots_to_fill,
                      slots_filled,
                      running_time
                    }: PeriodChart,
                    idx: number
                  ) => (
                    <Grid sx={{ height: 'min-content' }} key={idx}>
                      <BoxProgress
                        initialTime={getTime(state, currentProcess?.updatedAt, running_time)}
                        percentage={percentage}
                        state={state}
                        detailedState={detailedState}
                        period={idx + 1}
                        assigned={{ x: slots_filled, y: slots_to_fill }}
                      />
                    </Grid>
                  )
                )}
              </CardsContainer>
            </CardsInnerContainer>
          </CardsOuterContainer>
        </RenderIf>
        <RenderIf condition={dataChart.length >= 1 && !byPeriods}>
          <Grid flex={'0 0 min-content'}>
            <BoxProgress
              initialTime={getTime(status, currentProcess?.updatedAt, resultStats?.running_time)}
              percentage={getPercentage(dataChart)}
              state={status}
              detailedState={detailedStatus}
              assigned={{
                x: resultStats?.slots_filled ?? -1,
                y: resultStats?.slots_to_fill ?? -1
              }}></BoxProgress>
          </Grid>
        </RenderIf>
        <Grid display={'flex'} justifyContent={'center'}>
          <Grid minHeight={300} minWidth="20vw" width="60%" xs={12} marginBottom="1rem">
            <Chart type="line" options={options} series={series} height="100%" width={'100%'} />
          </Grid>
        </Grid>
        <CSSTransition timeout={500} in={finished} classNames="fade">
          <RenderIf condition={finished}>
            <Grid width={'100%'}>
              <ScheduleSummary
                scheduleMode={ScheduleModes.COMPLETE}
                resultStats={{
                  running_time: runningTime ?? 0,
                  jobs_to_process: currentProcess?.config.jobs.length ?? 0,
                  jobs_processed:
                    currentProcess?.details?.[ScheduleModes.COMPLETE]?.stats?.jobs_processed ?? 0,
                  slots_to_fill:
                    currentProcess?.details?.[ScheduleModes.COMPLETE]?.stats?.slots_to_fill ?? 0,
                  slots_filled:
                    currentProcess?.details?.[ScheduleModes.COMPLETE]?.stats?.slots_filled ?? 0
                }}
                status={
                  byPeriods && periods.length > 0 ? periods[periods.length - 1].state : status
                }
                error={error}
              />
            </Grid>
          </RenderIf>
        </CSSTransition>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <RenderIf condition={!finished}>
            <RenderIf condition={byPeriods}>
              <LoadingButton variant="contained" loading={loadingActions} onClick={nextProcess}>
                {t('periods.nextPeriod')}
              </LoadingButton>
            </RenderIf>
            <LoadingButton variant="contained" loading={loadingActions} onClick={stopProcess}>
              {t('terminate')}
            </LoadingButton>
          </RenderIf>
          <RenderIf condition={finished}>
            <Button onClick={() => handleClose(false)} variant="contained">
              {tCommon('buttons.close')}
            </Button>
          </RenderIf>
        </ButtonsContainer>
      </FooterModalContainer>
    </Grid>
  );
};
