import { Navbar } from '@/components/organisms';
import FilterContextProvider from '@/context/FilterContext';
import GenericModalContextProvider from '@/context/GenericModalContext';
import ModalConfirmationContextProvider from '@/context/ModalConfirmationContext';
import ScheduleConfigContextProvider from '@/context/ScheduleConfigContext';
import SchedulerContextProvider from '@/context/SchedulerContext';
import { Outlet } from 'react-router-dom';
import { Body, Container, Content, Drawer, Root } from './BasicLayout.styles';

const BasicLayout = () => {
  return (
    <FilterContextProvider>
      <ScheduleConfigContextProvider>
        <SchedulerContextProvider>
          <ModalConfirmationContextProvider>
            <GenericModalContextProvider>
              <Root>
                <Navbar />
                <Body container>
                  <Drawer id="basic-layout-drawer" />
                  <Content xs>
                    <Container>
                      <Outlet />
                    </Container>
                  </Content>
                </Body>
              </Root>
            </GenericModalContextProvider>
          </ModalConfirmationContextProvider>
        </SchedulerContextProvider>
      </ScheduleConfigContextProvider>
    </FilterContextProvider>
  );
};

export default BasicLayout;
