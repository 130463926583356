import { JobDto } from '@/types';
import { createContext } from 'react';

export type JobType = Record<JobDto['_id'], JobDto>;

export type JobContextType = {
  jobs: JobType;
  handleJobs: (newJobs: JobType) => void;
  addJobs: (newJobs: JobDto[]) => void;
  deleteJob: (idJob: string) => void;
};

export default createContext<JobContextType>({} as JobContextType);
