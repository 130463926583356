import { RenderIf } from '@/components/atoms';
import { Card, DateInput, NumberInput, WeekdayInput } from '@/components/molecules';
import { FormHelperTextError } from '@/components/molecules/Card/Card.styles';
import { DaysValuesType, Frequency, SystemProperties } from '@/const';
import { TextField, theme } from '@/theme';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const RecurrentEventForm = () => {
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  const { t: tCommon } = useTranslation('common');
  const { t: tRecurrentEvent } = useTranslation('organisms/recurrentEvent');
  const frequency = useWatch({
    control,
    name: `recurrence.frequency`,
    defaultValue: Frequency.NEVER
  });
  const startDate = useWatch({
    control,
    name: `properties.${SystemProperties.EVENTS_START_TIMESTAMP}`
  });

  const customDays = useWatch({
    control,
    defaultValue: [],
    name: `recurrence.days`
  });

  const visible = useMemo(
    () => frequency === Frequency.DAILY || frequency == Frequency.WEEKLY,
    [frequency]
  );

  useEffect(() => {
    frequency !== Frequency.WEEKLY && clearErrors('recurrence.days');
  }, [frequency]);

  const recurrent = useMemo(() => frequency !== Frequency.NEVER, [frequency]);

  const onChange = (days: DaysValuesType[] | null) => {
    setValue('recurrence.days', days, { shouldValidate: true });
  };

  return (
    <Card
      title={tRecurrentEvent('title')}
      sx={{
        marginBlock: '1rem !important',
        cursor: 'auto !important'
      }}>
      <Grid container spacing={1}>
        <Grid display={'flex'} gap={'1rem'} alignItems="center" width={'100%'} margin={'0.5rem 0'}>
          <Typography whiteSpace={'nowrap'}>{tRecurrentEvent('repeatEvent')}</Typography>
          <Controller
            name={'recurrence.frequency'}
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  select
                  sx={{ minWidth: 'fit-content' }}
                  {...field}
                  size="small"
                  variant="outlined"
                  value={field.value}
                  defaultValue={Frequency.NEVER}
                  onChange={field.onChange}
                  InputLabelProps={{ shrink: true }}>
                  <MenuItem value={Frequency.NEVER}>{tCommon('frequency.never')}</MenuItem>
                  <MenuItem value={Frequency.DAILY}>{tCommon('frequency.daily')}</MenuItem>
                  <MenuItem value={Frequency.WEEKLY}>{tCommon('frequency.weekly')}</MenuItem>
                  <MenuItem value={Frequency.MONTHLY}>{tCommon('frequency.monthly')}</MenuItem>
                  <MenuItem value={Frequency.YEARLY}>{tCommon('frequency.yearly')}</MenuItem>
                </TextField>
              );
            }}
          />
          <Typography color={recurrent ? '' : theme.palette.divider} whiteSpace={'nowrap'}>
            {tRecurrentEvent('until')}
          </Typography>
          <Controller
            control={control}
            name="recurrence.until"
            rules={{
              required: recurrent
            }}
            render={({ field }) => (
              <DateInput
                includeHours={false}
                minDate={startDate ? DateTime.fromISO(startDate) : undefined}
                disabled={!recurrent}
                onChange={(e) => {
                  field.onChange(isString(e) ? e : (e as DateTime).toISODate());
                }}
                value={DateTime.fromISO(field.value)}
                error={!!errors.recurrence?.['until']}
              />
            )}
          />
        </Grid>
        <Grid
          width={'100%'}
          margin={'0.5rem 0'}
          display="flex"
          alignItems="baseline"
          gap="1rem"
          flexWrap="wrap">
          <RenderIf condition={visible}>
            <Typography whiteSpace={'nowrap'}>{tRecurrentEvent('every')}</Typography>
            <Controller
              name={'recurrence.interval'}
              control={control}
              rules={{
                required: visible
              }}
              render={({ field }) => {
                return (
                  <NumberInput
                    {...field}
                    defaultValue={1}
                    sx={{ width: '4rem' }}
                    variant="outlined"
                    value={field.value}
                    onChange={field.onChange}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.recurrence?.['interval']}
                  />
                );
              }}
            />
            <Typography whiteSpace={'nowrap'}>
              {frequency == Frequency.DAILY ? tCommon('time.day(s)') : tCommon('time.weeksOn')}
            </Typography>
            <RenderIf condition={frequency === Frequency.WEEKLY && visible}>
              <Box display="flex" gap="0.3rem">
                <Controller
                  name={'recurrence.days'}
                  control={control}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (visible && value.length > 0) return true;
                        return false;
                      }
                    }
                  }}
                  render={({ field }) => {
                    return (
                      <WeekdayInput
                        asInput={false}
                        value={customDays}
                        onChange={onChange}></WeekdayInput>
                    );
                  }}
                />
              </Box>
            </RenderIf>
          </RenderIf>
        </Grid>
        <RenderIf condition={!!errors.recurrence?.['days']}>
          <FormHelperTextError>{tRecurrentEvent('noDaysSelectedError')}</FormHelperTextError>
        </RenderIf>
      </Grid>
    </Card>
  );
};

export default RecurrentEventForm;
