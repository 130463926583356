import { RenderIf } from '@/components/atoms';
import { FormControlProps, FormHelperText, TextFieldProps } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IntervalNumber } from 'types';
import { Container, InputEnd, InputStart } from './IntervalFilter.styles';

export type IntervalFilterProps = {
  value?: IntervalNumber;
  onChange: (value: IntervalNumber) => void;
  label?: string;
  helperText?: string;
  minFieldProps?: TextFieldProps;
  maxFieldProps?: TextFieldProps;
} & Omit<FormControlProps, 'onChange'>;

const IntervalFilter = ({
  value,
  label,
  onChange,
  minFieldProps = {},
  maxFieldProps = {},
  helperText,
  error,
  ...props
}: IntervalFilterProps) => {
  const handleChange = (property: keyof IntervalNumber) => (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, [property]: e.target.value.replace(/\D/g, '') });
  };

  const { t } = useTranslation();

  return (
    <>
      <Container {...props}>
        <InputStart
          label={`${label} - min`}
          fullWidth
          size="small"
          // type="number"
          value={value?.$gte ? t('number', { value: value.$gte }) : ''}
          error={error}
          onChange={handleChange('$gte')}
          InputLabelProps={{
            shrink: true
          }}
          {...minFieldProps}
        />
        <InputEnd
          label="max"
          fullWidth
          size="small"
          // type="number"
          onChange={handleChange('$lte')}
          value={value?.$lte ? t('number', { value: value.$lte }) : ''}
          error={error}
          InputLabelProps={{
            shrink: true
          }}
          {...maxFieldProps}
        />
        <RenderIf condition={!!helperText}>
          <FormHelperText error={error}>{helperText}</FormHelperText>
        </RenderIf>
      </Container>
    </>
  );
};

export default IntervalFilter;
