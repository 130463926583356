import { RenderIf } from '@/components/atoms';
import { PresetButton } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer,
  RecurrentEventForm
} from '@/components/organisms';
import PropertiesForm from '@/components/organisms/PropertiesForm/PropertiesForm';
import {
  COLLECTIONS_FIND_ALL,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  DAYS,
  DaysValue,
  Entities,
  Frequency,
  PropertyTypes,
  SystemProperties
} from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch, useScrollToError } from '@/hooks';
import useGetDefaultPresetByEntity from '@/hooks/useGetDefaultPresetByEntity';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import { removeEmptyFields } from '@/services/utils/utils';
import { ButtonsContainer, theme } from '@/theme';
import { EventDto, Properties, ResourceDto } from '@/types';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PresetModes } from '../../PropertyPresetPanelTemplate/PropertyPresetPanelTemplate.const';
import { getDefaultPropertiesAsFormValues } from '../ResourcePanelTemplate/ResourcePanelTemplate.const';

type EventResourceDto = {
  recurrence?: EventRecurrenceDto;
} & Properties;

type EventRecurrenceDto = {
  days: `${DaysValue}`[];
  frequency: Frequency;
  interval: number;
  until: string | null;
};
export type EventPanelTemplateProps = {
  mode: CrudModes;
  eventId?: string;
  resourceId: string;
  startDate?: Date;
  onSuccess: (events?: EventDto | EventDto[]) => unknown;
  onCancel: (isDirty?: boolean) => unknown;
  onDirtyFields: (isDirty: boolean) => unknown;
  onLoading?: (loading: boolean) => unknown;
};

const EventPanelTemplate = ({
  mode,
  eventId,
  resourceId,
  startDate,
  onSuccess,
  onDirtyFields,
  onCancel,
  onLoading
}: EventPanelTemplateProps) => {
  const { t: tCommon } = useTranslation();
  const { t: tMainTemplate } = useTranslation('templates/mainTemplate');
  const { setToastNotifications } = useToastContext();
  const { properties, entityData, loading, error } = useGetPropertiesByEntity<EventDto>(
    Entities.EVENTS,
    eventId
  );

  const {
    data: resourceData,
    fetch: fetchData,
    loading: loadingResource,
    error: errorResource
  } = useFetch<ResourceDto>();

  useEffect(() => {
    if (resourceId) {
      fetchData({
        url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.RESOURCES).replace(
          ':id',
          resourceId
        )
      });
    }
  }, []);

  useEffect(() => {
    if (error) {
      if (error.response?.data?.code == 'event_not_found') {
        setToastNotifications([
          { message: tCommon('errors.entityNotFound', { entity: tCommon('entities.events_one') }) }
        ]);
      } else {
        setToastNotifications([
          { message: tCommon('errors.loadingEntity', { entity: tCommon('entities.events_one') }) }
        ]);
      }

      onCancel();
    }
  }, [error]);

  useEffect(() => {
    if (errorResource) {
      setToastNotifications([
        { message: tCommon('errors.loadingEntity', { entity: tCommon('entities.resources_one') }) }
      ]);
      onCancel();
    }
  }, [errorResource]);

  const getDefaultProperties = useCallback(
    (startDate?: Date) => {
      if (startDate) {
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(startDate.getTime());
        endDate.setDate(endDate.getDate() + 1);

        return {
          [SystemProperties.EVENTS_START_TIMESTAMP]: DateTime.fromJSDate(startDate),
          [SystemProperties.EVENTS_END_TIMESTAMP]: DateTime.fromJSDate(endDate)
        };
      }
      return {};
    },
    [resourceData]
  );

  useEffect(() => {
    if (resourceData) {
      const location = {
        _id: resourceData?.properties.find(
          (property) => property._id == SystemProperties.RESOURCES_LOCATION
        )?.value,
        label: resourceData?.properties.find(
          (property) => property._id == SystemProperties.RESOURCES_LOCATION
        )?.label
      };
      setValue(`properties.${SystemProperties.EVENTS_LOCATION}`, location);
    }
  }, [resourceData]);
  const defaultValues: EventResourceDto = useMemo(() => {
    return {
      recurrence: {
        days: [],
        frequency: Frequency.NEVER,
        interval: 1,
        until: DateTime.fromJSDate(startDate || new Date())
          .plus({ months: 1 })
          .toISODate()
      },
      properties: getDefaultProperties(startDate)
    };
    // }
  }, [getDefaultProperties, resourceData]);

  const methods = useForm<EventResourceDto>({
    mode: mode === CrudModes.CREATE ? 'onSubmit' : 'onChange',
    defaultValues: defaultValues
  });
  const { control, handleSubmit, reset, formState, setValue, watch, trigger, setError } = methods;
  const { toScroll } = useScrollToError();
  const {
    defaultValues: defaultValuesPreset,
    changeDefaultValues,
    loadingDefaultValues,
    errorDefaultValues
  } = useGetDefaultPresetByEntity(Entities.EVENTS, mode == CrudModes.CREATE);

  useEffect(() => {
    if (defaultValuesPreset?.data?.properties && properties) {
      defaultValuesPreset?.data?.properties.forEach((property) => {
        setValue(
          `properties.${property._id}`,
          property.label ? { label: property.label, _id: property.value } : property.value,
          { shouldDirty: defaultValuesPreset.type === PresetModes.CUSTOM }
        );
      });
    }
  }, [defaultValuesPreset, properties]);

  useEffect(() => {
    onDirtyFields(formState.isDirty);
  }, [formState.isDirty]);

  const getDay = (date: DateTime) => {
    const key = date.setLocale('en').toLocaleString({ weekday: 'long' });
    const day = DAYS.find((day) => day.key === key);
    return day?.value;
  };

  useEffect(() => {
    if (startDate && mode === CrudModes.CREATE) {
      const day = getDay(DateTime.fromJSDate(startDate));
      if (day) setValue('recurrence.days', [day]);
    }
  }, []);

  const { fetch, loading: loadingFetch, error: errorFetch, data } = useFetch<EventDto>();

  useEffect(() => {
    if (onLoading) onLoading(loadingFetch);
  }, [loadingFetch]);

  useEffect(() => {
    if (properties && entityData && mode === CrudModes.EDIT) {
      const newValues = getDefaultPropertiesAsFormValues(properties, entityData);
      reset({
        properties: newValues
      });
    }
  }, [entityData]);

  const submit = (form: EventResourceDto) => {
    removeEmptyFields(form.properties);
    const formattedProperties = properties
      ?.map((row) => {
        return {
          _id: row._id,
          value:
            (row.type === PropertyTypes.SET || row.type === PropertyTypes.OBJECTID) &&
            typeof form.properties[row._id] !== 'string'
              ? form.properties[row._id]?._id
              : form.properties[row._id]
        };
      })
      .filter((prop) => prop.value !== undefined && prop.value !== null);

    const recurrence = formatRecurrence(form.recurrence);
    let query: AxiosRequestConfig | undefined = undefined;
    switch (mode) {
      case CrudModes.CREATE:
        query = {
          url: COLLECTIONS_FIND_ALL.replace(':collection_name', 'events'),
          method: 'POST',
          data: {
            properties: formattedProperties,
            recurrence: recurrence,
            resources: { resource: resourceId, roleName: 'Owner' }
          }
        };
        break;
      case CrudModes.EDIT:
        if (eventId) {
          query = {
            url: COLLECTIONS_FIND_ONE.replace(':collection_name', 'events').replace(':id', eventId),
            method: 'PATCH',
            data: {
              properties: formattedProperties
            }
          };
        }
        break;
    }
    if (query) fetch(query);
  };

  const formatRecurrence = (recurrence?: EventRecurrenceDto) => {
    if (!recurrence) return undefined;
    if (recurrence.frequency === Frequency.NEVER) {
      return undefined;
    }
    if (recurrence.frequency === Frequency.WEEKLY) {
      return recurrence;
    }
    return { ...recurrence, days: [] };
  };

  useEffect(() => {
    if (errorFetch) {
      if (errorFetch?.response?.data?.code == 'event_not_found') {
        setToastNotifications([
          { message: tCommon('errors.entityNotFound', { entity: tCommon('entities.events_one') }) }
        ]);
      } else if (
        errorFetch?.response?.data?.params &&
        errorFetch.response.data.code == 'duplicate_unique_property'
      ) {
        for (const param of errorFetch.response.data.params) {
          setError(`properties.${param}`, {
            message: tCommon('errors.duplicateProperty'),
            type: 'manual'
          });
        }
      } else if (
        errorFetch?.response?.data?.params &&
        errorFetch.response.data.code == 'invalid_property_value'
      ) {
        for (const param of errorFetch.response.data.params) {
          setError(`properties.${param}`, {
            message: tCommon('errors.invalidValue'),
            type: 'manual'
          });
        }
      }
      setToastNotifications([
        {
          message: tCommon('errors.actionError', {
            action:
              mode === CrudModes.CREATE ? tCommon('actions.creation') : tCommon('actions.edition'),
            entity: tCommon('entities.events_one')
          })
        }
      ]);
    } else if (data) {
      onDirtyFields(false);
      onSuccess(data);
    }
  }, [errorFetch, data]);

  const extraValidations = useMemo(() => {
    return {
      [SystemProperties.EVENTS_START_TIMESTAMP]: {
        triggerEndDate: (value) => {
          trigger(`properties.${SystemProperties.EVENTS_END_TIMESTAMP}`);
          return true;
        }
      },
      [SystemProperties.EVENTS_END_TIMESTAMP]: {
        moreThan: (value: string | Date, form: EventResourceDto) => {
          const startTimeStamp = form.properties[SystemProperties.EVENTS_START_TIMESTAMP];
          const validation = new Date(value).getTime() > new Date(startTimeStamp).getTime();
          return validation
            ? validation
            : tCommon('formRules.afterThat', {
                prop1: properties?.find(
                  (value) => value._id === SystemProperties.EVENTS_END_TIMESTAMP
                )?.name,
                prop2: properties?.find(
                  (value) => value._id === SystemProperties.EVENTS_START_TIMESTAMP
                )?.name
              });
        }
      }
    };
  }, [properties]);

  return (
    <Fragment>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {loading ? (
            <Skeleton width={'50%'} sx={{ bgcolor: 'grey.700' }} />
          ) : mode === CrudModes.CREATE ? (
            tMainTemplate('actions.createEventHeader')
          ) : (
            tMainTemplate('actions.editEventHeader')
          )}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer>
        <RenderIf condition={loading || loadingResource}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress />
          </Box>
        </RenderIf>
        <FormProvider {...methods}>
          <RenderIf condition={!loading && !loadingResource}>
            <PropertiesForm
              properties={properties ?? []}
              control={control}
              formState={formState}
              extraValidations={extraValidations}
            />
            <RenderIf condition={mode === CrudModes.CREATE}>
              <RecurrentEventForm />
            </RenderIf>
          </RenderIf>
        </FormProvider>
      </BodyModalContainer>
      <FooterModalContainer
        sx={mode == CrudModes.CREATE ? { justifyContent: 'space-between' } : {}}>
        <RenderIf condition={CrudModes.CREATE == mode}>
          <PresetButton
            disabled={loadingFetch || !!errorDefaultValues}
            loading={loadingDefaultValues || loading || loadingResource}
            entity={Entities.EVENTS}
            onSelect={changeDefaultValues}></PresetButton>
        </RenderIf>
        <ButtonsContainer>
          <Button disabled={loading || loadingFetch} onClick={(e) => onCancel(formState.isDirty)}>
            {tCommon('buttons.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loadingFetch}
            disabled={
              loading ||
              (mode === CrudModes.EDIT && Object.keys(formState.dirtyFields).length === 0) ||
              Object.keys(formState.errors).length > 0
            }
            onClick={handleSubmit(submit, (errors) => toScroll(errors))}>
            {tCommon('buttons.save')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </Fragment>
  );
};

export default EventPanelTemplate;
