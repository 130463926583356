import { CurriculumDto, Session, TimeStrategy } from '@/types';
import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { useFormContext } from 'react-hook-form';
import { DragNDropComponents } from '../../CurriculumSetup.const';
import { IconHeader } from '../SessionWrapper/SessionWrapper.const';
import {
  HEADER_TITLE_VARIANT,
  HeaderContainer,
  HeaderTitle
} from '../SessionWrapper/SessionWrapper.styles';

export type SessionDropLayerProps = {
  moveSession: (sourceIndex: number, destinyIndex: number) => unknown;
  addNewSession: (
    type: DragNDropComponents.NEW_SESION | DragNDropComponents.NEW_TIME_SESSION,
    destinyIndex?: number
  ) => unknown;
};

const SessionDropLayer = ({ moveSession, addNewSession }: SessionDropLayerProps) => {
  const { getValues } = useFormContext<CurriculumDto>();
  const [{ isOver, item: draggedItem, type }, drop] = useDrop({
    accept: [
      DragNDropComponents.SESSION,
      DragNDropComponents.NEW_SESION,
      DragNDropComponents.NEW_TIME_SESSION
    ],
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver(),
        item: monitor.getItem() as any,
        type: monitor.getItemType() as DragNDropComponents
      };
    },
    drop: (item: any, monitor) => {
      const length = getValues('structure.sessions').length;
      if (type === DragNDropComponents.SESSION) {
        const dragIndex = item?.index || 0;
        const dropIndex = length - 1;
        if (dragIndex === dropIndex) {
          return;
        }
        moveSession(dragIndex, length);
      } else if (
        type === DragNDropComponents.NEW_TIME_SESSION ||
        type === DragNDropComponents.NEW_SESION
      ) {
        addNewSession(type);
      }
    }
  });

  const getDropRender = useCallback(() => {
    if (
      [
        DragNDropComponents.NEW_SESION,
        DragNDropComponents.NEW_TIME_SESSION,
        DragNDropComponents.SESSION
      ].includes(type) &&
      isOver
    ) {
      const session: Session | undefined = draggedItem?.session as Session;
      return (
        <HeaderContainer
          headerStyle="shadow"
          sx={{
            width: 'calc(100% - 2.5rem)',
            margin: '0 auto'
          }}>
          <IconHeader
            timeStrategy={
              session?.timeStrategy
                ? session.timeStrategy
                : type === DragNDropComponents.NEW_TIME_SESSION
                  ? TimeStrategy.START_TIMES
                  : TimeStrategy.RESOURCE_MISSIONS
            }
          />
          <HeaderTitle variant={HEADER_TITLE_VARIANT}>
            {type === DragNDropComponents.SESSION
              ? session?.name || ''
              : `New ${type === DragNDropComponents.NEW_TIME_SESSION ? 'time' : ''} session`}
          </HeaderTitle>
        </HeaderContainer>
      );
    }
    return null;
  }, [isOver, draggedItem, type]);
  return (
    <div ref={drop} style={{ width: '100%', minHeight: '4rem', flex: 1 }}>
      {getDropRender()}
    </div>
  );
};

export default SessionDropLayer;
