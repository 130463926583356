import { RenderIf } from '@/components/atoms';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { CrudModes } from '@/const';
import { ButtonsContainer, Container, theme } from '@/theme';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CONTINUITIES_BY_TYPE, Continuity, ContinuityPanelProps } from './ContinuityPanel.const';

const ContinuityPanel = <T extends Continuity>({
  mode,
  defaultValues,
  values,
  sessions,
  resourceSlots,
  onCancel,
  onDirtyFields,
  onSuccess,
  continuityClass,
  ...rest
}: ContinuityPanelProps<T>) => {
  const { t: tCurriculum } = useTranslation('templates/curriculumsTemplate');
  // TODO: translations should be in a different file
  const { t: tCommon } = useTranslation('common');

  const methods = useForm<T>({
    mode: mode === CrudModes.CREATE ? 'onSubmit' : 'onChange',
    defaultValues,
    values
  });
  const { handleSubmit, formState } = methods;

  useEffect(() => {
    onDirtyFields(formState.isDirty);
  }, [formState.isDirty]);

  const submit = (form: T) => {
    onDirtyFields(false);
    switch (mode) {
      case CrudModes.CREATE:
        onSuccess({ ...form });
        break;
      case CrudModes.EDIT:
        onSuccess(form);
        break;
    }
  };

  const Component = useMemo(() => CONTINUITIES_BY_TYPE[continuityClass], []);

  return (
    <Container>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {tCurriculum(`title.${mode}`, {
            name: tCurriculum('elements.continuities_one')
          })}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '0rem 2rem 0rem 2rem' }}>
        <FormProvider {...methods}>
          <Component mode={mode} sessions={sessions} resourceSlots={resourceSlots} {...rest} />
        </FormProvider>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button
            variant={mode == CrudModes.READ ? 'contained' : 'text'}
            onClick={(e) => {
              onCancel(formState.isDirty);
            }}>
            {mode == CrudModes.READ ? tCommon('buttons.close') : tCommon('buttons.cancel')}
          </Button>
          <RenderIf condition={mode !== CrudModes.READ}>
            <LoadingButton
              variant="contained"
              disabled={
                Object.keys(methods.formState.errors).length > 0 ||
                (mode === CrudModes.EDIT && !methods.formState.isDirty)
              }
              loading={false}
              onClick={handleSubmit(submit)}>
              {tCommon('buttons.save')}
            </LoadingButton>
          </RenderIf>
        </ButtonsContainer>
      </FooterModalContainer>
    </Container>
  );
};

export default ContinuityPanel;
