import { createContext, Dispatch, SetStateAction } from 'react';
import { Socket } from 'socket.io-client';
import { ToastType } from '../ToastContext';

export type SocketContextType = {
  socket?: Socket;
  connectSocket: (url: string, tenant: string, token: string) => void;
  disconnectSocket: () => void;
  online: boolean;
  setOnline?: Dispatch<SetStateAction<boolean>>;
  tenant?: string;
  errors: ToastType[];
  setErrors: Dispatch<SetStateAction<ToastType[]>>;
};

export default createContext<SocketContextType>({} as SocketContextType);
