export enum Filters {
  RESOURCES = 'filter-resources-by-properties',
  DOCUMENTS = 'filter-documents-by-properties',
  JOBS = 'filter-jobs-by-properties',
  TOPIC = 'filter-topic-by-properties',
  RESOURCERECORDS = 'filter-resourcerecords-by-properties',
  RESOURCES_BY_RECORDS = 'filter-resources-by-records',
  CURRICULUMS = 'filter-curriculums-by-properties',
  QUALIFIED_RESOURCES = 'filter-qualified-resource-by-properties'
}

export enum FiltersSections {
  PROPERTIES = '-by-properties',
  RECORDS = '-by-records'
}

export enum SystemFilters {
  MISSIONS = 'missions',
  WORK_RULES = 'workRules',
  ENTITY_TYPE = 'type',
  JOBS_CURRICULUM = 'curriculum',
  JOBS_STATUS = 'status',
  TOPIC_STATUS = 'topic-status',
  RESOURCE_RECORDS_DOCUMENTS = 'documents',
  FULLTEXT = 'full-text',
  FULLTEXT_ENTITY = 'full-text-entity',
  MASTER_DETAIL = 'master-detail',
  ENTITY_SELECTED = 'entity-selected',
  SIZE = 'size',
  SETTINGS = 'selected-filter-properties',
  EXPANDED_SECTIONS = 'expanded-filter-sections'
}
