import { TextField } from '@/theme';
import { PossibleValues, isMultipleInputValue } from '@/types';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';

export type NumberInputProps = {
  onChange: (value: number | null) => void;
  readOnly?: boolean;
  allowDecimals?: boolean;
} & Omit<NumericFormatProps, 'value'> &
  PossibleValues<number | null> &
  Omit<TextFieldProps, 'onChange' | 'value'>;

const NumberInput = ({
  value,
  includeMultipleObj = false,
  onChange,
  readOnly = false,
  helperText,
  allowDecimals = true,
  error,
  ...props
}: NumberInputProps) => {
  const { t: tCommon } = useTranslation();

  const numberToThousandSeparator = (): string => {
    const thousandSeparator = tCommon('number', { value: 10000 });
    const newThousand = thousandSeparator.replaceAll(new RegExp('[0-9]', 'g'), '');
    return newThousand;
  };

  const numberToDecimalSeparator = (): string => {
    const decimalSeparator = tCommon('number', { value: 0.001 });
    const newDecimal = decimalSeparator.replaceAll(new RegExp('[0-9]', 'g'), '');
    return newDecimal;
  };

  return (
    <>
      <NumericFormat
        InputProps={{ readOnly }}
        error={error}
        placeholder={
          includeMultipleObj && value && isMultipleInputValue(value)
            ? tCommon('text.multipleValues')
            : ''
        }
        helperText={helperText}
        {...props}
        value={isNumber(value) ? value : ''}
        customInput={TextField as typeof MuiTextField}
        fullWidth
        size="small"
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{ maxLength: props.maxLength || 19 }}
        allowLeadingZeros={true}
        type="text"
        thousandSeparator={numberToThousandSeparator()}
        decimalSeparator={numberToDecimalSeparator()}
        onValueChange={(value: NumberFormatValues) => {
          onChange(value.floatValue ?? null);
        }}
        isAllowed={({ floatValue }) => {
          return floatValue === undefined || allowDecimals || Number.isInteger(floatValue);
        }}
      />
    </>
  );
};

export default NumberInput;
