import { RenderIf } from '@/components/atoms';
import { AutoCompleteField, PropertyTypes } from '@/const';
import { Alert } from '@mui/material';
import { Controller } from 'react-hook-form';
import { component, getPropertiesByType, regExp } from '../GlobalFilter.const';
import { Container } from '../GlobalFilter.styles';
import { GlobalFilterBodyProps } from '../GlobalFilter.types';

const GlobalFilterBody = ({
  fields,
  control,
  errors,
  selected,
  error,
  helperText,
  ...props
}: GlobalFilterBodyProps) => {
  return (
    <Container {...props}>
      <RenderIf condition={!!helperText}>
        <Alert sx={{ m: '0 !important' }} severity={error ? 'error' : 'info'}>
          {helperText}
        </Alert>
      </RenderIf>

      <RenderIf condition={!error}>
        {fields
          .filter(({ _id, type }) => (selected || []).includes(_id) && type !== PropertyTypes.IMAGE)
          .map(({ type, name, _id, ...optionals }) => {
            const Component = component[type];
            const others = getPropertiesByType(type, optionals);

            return (
              <Controller
                key={name}
                name={_id}
                control={control}
                rules={{
                  pattern: {
                    value: regExp[type],
                    message: ''
                  }
                }}
                render={({ field }) => {
                  return (
                    <>
                      <Component
                        name={_id}
                        autoComplete={AutoCompleteField}
                        label={name as string}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors[_id]}
                        helperText={errors[_id] && (errors[_id] as { message: string }).message}
                        {...others}
                      />
                    </>
                  );
                }}
              />
            );
          })}
      </RenderIf>
    </Container>
  );
};

export default GlobalFilterBody;
