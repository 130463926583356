import { RenderIf, SwitchTag } from '@/components/atoms';
import { useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { ResourceSlot, SetItemDto } from '@/types';
import { Box, IconButton } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { MdEventSeat } from 'react-icons/md';
import { Card, CustomTypography } from '../../../common/ResourceSlotCard/ResourceSlotCard.style';

type ResourceSlotCardProps = {
  resourceSlot: ResourceSlot;
  roles: SetItemDto[];
  onContextMenu: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onEdit: () => void;
  onRemove: () => void;
  status?: boolean;
  multiple?: boolean;
};
const TopicResourceSlotCard = ({
  resourceSlot,
  roles,
  onContextMenu,
  onEdit,
  onRemove,
  status,
  multiple = false
}: ResourceSlotCardProps) => {
  const { t: tResourceSlot } = useTranslation('organisms/resourceSlot');
  const { readOnly } = useCurriculumSetupContext();
  const documentsCounter = useMemo(() => resourceSlot?.documents?.length || 0, [resourceSlot]);
  const getLabel = () => {
    if (status || multiple) {
      return tResourceSlot('joinableOptions.joinable');
    } else {
      return tResourceSlot('joinableOptions.notJoinable');
    }
  };
  return (
    <Card
      onDoubleClick={onEdit}
      isFaded={false}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onContextMenu(e);
      }}
      isSelected={false}
      key={resourceSlot.id}>
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column">
        <CustomTypography>{resourceSlot.name}</CustomTypography>
        <CustomTypography variant={'caption'} sx={{ lineHeight: 'normal' }}>
          <MdEventSeat style={{ verticalAlign: 'top', marginRight: '0.1rem' }} />
          {resourceSlot.quantity} -{' '}
          {roles?.find((role) => role._id == resourceSlot.role._id)?.label}{' '}
          <RenderIf condition={documentsCounter > 0}>
            <span style={{ marginInline: '0.1rem' }}>|</span>{' '}
            <IoDocumentAttachOutline style={{ verticalAlign: 'top', marginRight: '0.1rem' }} />
            {resourceSlot?.documents?.map((document) => document.label).join(', ')}
          </RenderIf>
        </CustomTypography>
      </Box>
      <Box>
        <RenderIf condition={status || multiple}>
          <SwitchTag
            multiple={multiple}
            tooltipLabel={multiple ? 'Multiple' : ''}
            label={getLabel()}
          />
        </RenderIf>
      </Box>
      <IconButton sx={{ padding: 0 }} disabled={readOnly} onClick={onRemove}>
        <FaTrash fontSize="small" />
      </IconButton>
    </Card>
  );
};

export default TopicResourceSlotCard;
