export const showColorPicker = (taskRecord, event) => {
  let colorInput = document.getElementById('colorInputTask') as HTMLInputElement;
  if (!colorInput) {
    colorInput = document.createElement('input') as HTMLInputElement;
    colorInput.setAttribute('type', 'color');
    colorInput.setAttribute('id', 'colorInputTask');
    colorInput.style.position = 'absolute';
   // colorInput.style.opacity = '0';
    document.body.appendChild(colorInput);
  }

  const rect = event.target.getBoundingClientRect();
  colorInput.style.left = `${rect.left + window.scrollX}px`;
  colorInput.style.top = `${rect.top + window.scrollY}px`;
  colorInput.style.display = 'block';
 // colorInput.click();

  const savedColor = localStorage.getItem(`taskColor_${taskRecord.id}`);
  if (savedColor) {
    colorInput.value = savedColor;
  }

  colorInput.oninput = function () {
    const selectedColor = colorInput.value;
    localStorage.setItem(`taskColor_${taskRecord.id}`, selectedColor);

    event.target.style.backgroundColor = selectedColor;
  };

  function handleOutsideClick(e) {
    if (e.target !== colorInput) {
      colorInput.style.display = 'none';
      document.removeEventListener('click', handleOutsideClick);
    }
  }

  document.addEventListener('click', handleOutsideClick);
};
