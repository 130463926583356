import { QualifiedResourceAssignment, ResourceSlot } from '@/types';
import { isEmpty } from 'lodash';
import { JobEventForm } from './JobEventPanelTemplate';

export const transformResourceSlots = (
  resourceSlots: ResourceSlot[] = [],
  resources: QualifiedResourceAssignment[] = []
) => {
  return resourceSlots.map((resourceSlot) => {
    const assigmentsBySlot = resources.filter(
      (resource) => resource.resourceSlotId === resourceSlot.id
    );
    const resourcesBySlot = resources
      .filter((newResource) =>
        assigmentsBySlot.some((assignment) => assignment.resource._id === newResource.resource._id)
      )
      .map((resource) => {
        const { _id, label, picture } = resource.resource;
        const { qualified } = resource;
        return { _id, label, qualified, picture };
      });

    const emptySlots = resourceSlot.quantity - resourcesBySlot.length;
    return {
      ...resourceSlot,
      resources: resourcesBySlot.concat(Array(emptySlots).fill({}))
    };
  });
};

export const mapToDto = (resourceSlots: JobEventForm['resourceSlots']) => {
  return resourceSlots
    .map((resourceSlot) => {
      return resourceSlot.resources
        .filter((resource) => resource._id)
        .map((resource) => {
          return {
            roleName: resourceSlot.name,
            resource: resource._id,
            resourceSlotId: resourceSlot.id
          };
        });
    })
    .flat();
};

export const checkQualifiedAndQuantity = (resourceSlots: JobEventForm['resourceSlots']) => {
  return resourceSlots.every((resourceSlot) => {
    const resources = resourceSlot.resources.filter((rs) => !isEmpty(rs));
    const allQualified = resources.every((resource) => resource.qualified);
    if (resourceSlot.documents) return allQualified && resources.length === resourceSlot.quantity;
    if (resources.length === 0) return true;
    return allQualified;
  });
};
