import { Grid, styled } from '@mui/material';

export const AuthContainer = styled('div')`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.secondary.light};
`;

export const BrandingContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  margin-bottom: 0.7rem;
  align-items: center;
`;

export const Logo = styled('img')`
  height: 4.5rem;
  width: 4.5rem;
`;

export const Brand = styled('img')`
  width: 14rem;
  margin: 2rem;
`;

export const CardAuth = styled('form')`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 23rem;
  padding: 3rem;
`;
