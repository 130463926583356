import { RenderIf } from '@/components/atoms';
import { Typography } from '@mui/material';
import { CSSProperties, DOMAttributes } from 'react';
import { ColorIconBox } from './ColorBox.styles';

export type ColorBoxProps = {
  containerProps?: CSSProperties;
  boxSize?: number;
  includeTextValue?: boolean;
  value: string;
} & Omit<DOMAttributes<HTMLDivElement>, 'children'>;

const ColorBox = ({
  containerProps = { width: '100%', height: '100%', display: 'flex', alignItems: 'center' },
  value,
  includeTextValue = false,
  boxSize = 20,
  ...DivProps
}: ColorBoxProps) => {
  return (
    <div style={containerProps} {...DivProps}>
      <ColorIconBox value={value} boxSize={boxSize} />
      <RenderIf condition={!!includeTextValue}>
        <Typography variant="inherit" sx={{ marginLeft: '4px' }}>
          {value as string}
        </Typography>
      </RenderIf>
    </div>
  );
};

export default ColorBox;
