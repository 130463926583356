import { styled } from '@mui/material';

export const Toolbar = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.grey[500]};
  justify-content: space-between;
  display: flex;
  padding: 0.5em;
  align-items: center;
  height: 3.2rem;
`;

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
`;
