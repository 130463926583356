import { RenderIf } from '@/components/atoms';
import { Card, ObjectidAutocomplete } from '@/components/molecules';
import {
  AutoCompleteField,
  Entities,
  EntityRoutes,
  Filters,
  FiltersSections,
  SystemProperties
} from '@/const';
import { useGetDocuments } from '@/hooks/useGetDocuments';
import { theme } from '@/theme';
import { DocumentCriterium, ResourceFilterByRecords } from '@/types';
import { getPropertyValueDef, saveState } from '@/utils';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { FC, Ref, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GlobalFilterHeader } from '../GlobalFilter/components';
import { Container as ListContainer, MenuStyles, Select } from './FilterByRecord.styles';
import CardSkeleton from './components/CardSkeleton';
import { DocumentCard } from './components/DocumentCard';

type FilterByRecordProps = {
  title: string;
  onSuccess: (records: ResourceFilterByRecords) => void;
  onCancel?: () => void;
  documentsRef: Ref<HTMLButtonElement>;
  defaultValues: ResourceFilterByRecords;
};
const FilterByRecord: FC<FilterByRecordProps> = ({
  onSuccess,
  onCancel,
  documentsRef,
  title,
  defaultValues
}) => {
  const { t } = useTranslation('organisms/globalFilter');
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<ResourceFilterByRecords>({
    mode: 'onBlur',
    defaultValues: { operation: defaultValues.operation }
  });
  const { fields, insert, remove } = useFieldArray({
    name: Entities.DOCUMENTS,
    control
  });
  const listRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    addDefaultDocuments(defaultValues.documents);
  }, []);

  const [assigned, setAssigned] = useState(true);
  const { fetch: fetchDocuments, loading } = useGetDocuments();

  const handleAssigned = () => {
    setAssigned(!assigned);
  };
  const addDefaultDocuments = async (documents: DocumentCriterium[]) => {
    if (documents?.length == 0) return;
    const data = await fetchDocuments(documents.map((doc) => doc._id));
    const formattedDocuments = data.documents.map((document) => ({
      _id: document._id,
      name: getPropertyValueDef(document.properties, SystemProperties.DOCUMENTS_NAME, ''),
      type: document.type.label,
      assigned: documents.find((doc) => doc._id === document._id)?.assigned
    }));
    saveState(Filters.RESOURCES_BY_RECORDS, {
      documents: formattedDocuments,
      operation: defaultValues.operation
    });
    setValue(Entities.DOCUMENTS, formattedDocuments);
  };

  const scrollToTop = (ref) => {
    ref.current?.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop(listRef);
  }, [loading]);

  const addNewDocument = async (id: string) => {
    if (!id) return;
    if (isRepeated(id)) return;
    if (loading) return;
    const documentsQuery = await fetchDocuments([id]);
    const document = documentsQuery.documents[0];
    insert(0, {
      _id: document._id,
      name: getPropertyValueDef(document.properties, SystemProperties.DOCUMENTS_NAME, ''),
      type: document.type.label,
      assigned
    });
    setValue('selected', undefined);
  };

  const isRepeated = (id: string) => {
    const records = getValues(Entities.DOCUMENTS);
    const repeatedRecord = records.find((record) => record._id === id);
    return !!repeatedRecord;
  };

  const updateDocument = (index: number, assigned: boolean) => {
    setValue(`documents.${index}.assigned`, assigned);
  };

  const clearAll = () => {
    reset({ documents: [], operation: 'or', selected: undefined });
  };
  const onSubmit = (data: ResourceFilterByRecords) => {
    onSuccess(data);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          persistKey={Entities.RESOURCES + FiltersSections.RECORDS}
          TransitionProps={{ unmountOnExit: false }}
          title={''}
          actions={
            <GlobalFilterHeader
              title={title}
              fields={[]}
              onClear={clearAll}
              onSelected={() => ''}
              filterRef={documentsRef}
            />
          }>
          <Box display="flex" flexDirection="column" gap="1rem">
            <Box display="flex" alignItems="center" width="100%" gap="0.5rem">
              <Select
                sx={{ flexShrink: '1' }}
                MenuProps={{ sx: MenuStyles }}
                size="small"
                value={assigned}
                label=""
                onChange={handleAssigned}>
                <MenuItem value={'true'}>{t('assignOptions.having')}</MenuItem>
                <MenuItem value={'false'}>{t('assignOptions.notHaving')}</MenuItem>
              </Select>
              <Controller
                key={'selected'}
                name={'selected'}
                control={control}
                render={({ field }) => {
                  return (
                    <ObjectidAutocomplete
                      referencedCollection={EntityRoutes.DOCUMENTS}
                      value={field.value}
                      name={`${Entities.DOCUMENTS}_name`}
                      autoComplete={AutoCompleteField}
                      onChange={field.onChange}
                      sx={{ flexGrow: '1' }}
                      onKeyDown={async (event) => {
                        if (event.key === 'Enter') {
                          const selected = getValues('selected');
                          if (!selected) return;
                          field.onChange(event);
                          addNewDocument(selected._id);
                        }
                      }}
                    />
                  );
                }}
              />

              <Button
                variant="contained"
                sx={{ minWidth: '30px', minHeight: '30px', borderRadius: '50%', padding: '0' }}
                onClick={() => {
                  const selected = getValues('selected');
                  if (!selected) return;
                  addNewDocument(selected._id);
                }}>
                <i className="b-fa-solid b-fa-plus"></i>
              </Button>
            </Box>
            <ListContainer ref={listRef}>
              <RenderIf condition={loading}>
                <CardSkeleton />
              </RenderIf>
              {fields.map((document, index) => (
                <DocumentCard
                  key={document.id}
                  {...document}
                  remove={remove}
                  index={index}
                  updateDocument={updateDocument}
                />
              ))}
              {fields.length === 0 && !loading && (
                <Typography sx={{ color: theme.palette.grey[500] }} mr="auto">
                  {t('noDocuments')}
                </Typography>
              )}
            </ListContainer>
            <Box
              mt="-0.7rem"
              fontSize="1rem"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="baseline">
              <Typography variant={'body2'}>{t('matchAnyAllBefore')}</Typography>
              <Controller
                key={'logicalOperator'}
                name={'operation'}
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      sx={{ marginInline: '0.3rem', border: 'none' }}
                      MenuProps={{ sx: MenuStyles }}
                      size="small"
                      value={field.value}
                      onChange={field.onChange}>
                      <MenuItem value={'or'}> {t('operatorOptions.or')}</MenuItem>
                      <MenuItem value={'and'}>{t('operatorOptions.and')}</MenuItem>
                    </Select>
                  );
                }}
              />
              <Typography variant={'body2'}>{t('matchAnyAllAfter')}</Typography>
            </Box>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default FilterByRecord;
