import { InferType, object, string } from 'yup';

export const RoleFormSchema = object({
  name: string().required()
});

export enum Permission {
  MainFullAccess = 'MainFullAccess',
  MyCalendarFullAccess = 'MyCalendarFullAccess',
  DocumentsFullAccess = 'DocumentsFullAccess',
  CurriculumsFullAccess = 'CurriculumsFullAccess',
  SetsFullAccess = 'SetsFullAccess',
  PropertiesFullAccess = 'PropertiesFullAccess',
  MissionsAndWorkRulesFullAccess = 'MissionsAndWorkRulesFullAccess',
  UsersFullAccess = 'UsersFullAccess',
  GeneralFullAccess = 'GeneralFullAccess'
}

export const SetupPermissions = [
  Permission.DocumentsFullAccess,
  Permission.CurriculumsFullAccess,
  Permission.SetsFullAccess,
  Permission.PropertiesFullAccess,
  Permission.MissionsAndWorkRulesFullAccess,
  Permission.UsersFullAccess,
  Permission.GeneralFullAccess
];

export type PermissionType = keyof typeof Permission;

export type RoleFormSchemaType = InferType<typeof RoleFormSchema>;
