import { BryntumToolbar } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { CrudModes, DelayTimes, SystemProperties } from '@/const';
import { useCalendarContext } from '@/context/CalendarContext';
import JobContextProvider from '@/context/JobsContext';
import { useModalConfirmationContext } from '@/context/ModalConfirmationContext';
import ResourceContextProvider from '@/context/ResourceContext';
import { ButtonsContainer, theme } from '@/theme';
import { EventDto, ResourceDto } from '@/types';
import { getPropertyValueDef, getResourceFullName } from '@/utils';
import { Button, Portal, Slide, Typography } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventPanelTemplate } from '../EntityPanels';
import { MyCalendarTemplate, OpenEventPanelProps } from '../MyCalendarTemplate';
import { Container } from './ResourceCalendarPanelTemplate.styles';

export type ResourceCalendarPanelTemplateProps = {
  resource: ResourceDto;
  onCancel: () => unknown;
  date?: Date;
  dirtyEvent?: boolean;
  handleDirtyEvent?: (dirty: boolean) => void;
  onChangeEvent?: (event: EventDto[] | string, action: CrudModes) => void;
};

const ResourceCalendarPanelTemplate = ({
  resource,
  onCancel,
  date,
  onChangeEvent,
  dirtyEvent,
  handleDirtyEvent
}: ResourceCalendarPanelTemplateProps) => {
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const { t: tResourceCalendar } = useTranslation('templates/resourceCalendarPanelTemplate');
  const [openEventTemplate, setOpenEventTemplate] = useState<OpenEventPanelProps>();

  const { showConfirmation, handleCancel } = useModalConfirmationContext();
  const { addEvents, updateEvent } = useCalendarContext();

  const handleOpen = useCallback(
    async (open: OpenEventPanelProps | undefined) => {
      if (open) setOpenEventTemplate(open);
      if (dirtyEvent && !open) {
        const continueOnClose = await showConfirmation({
          title: t('modalConfirmation.title'),
          message: (
            <>
              <Typography component={'span'}>
                {t('modalConfirmation.messageWEntity', {
                  entityName: t('entities.events_one')
                })}
              </Typography>
              <br />
              <br />
              <Typography component={'span'} marginTop={'1rem'}>
                {t('modalConfirmation.leave_simple')}
              </Typography>
            </>
          ),
          isReactNode: false,
          confirmButton: t('buttons.leave'),
          cancelButton: t('buttons.cancel')
        });
        if (continueOnClose) {
          setOpenEventTemplate(undefined);
        }
      }
      if (!dirtyEvent && !open) setOpenEventTemplate(undefined);
    },
    [dirtyEvent]
  );

  return (
    <Container ref={containerRef}>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {tResourceCalendar('resourceCalendar')}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer padding={'0px !important'}>
        <BryntumToolbar sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          <Typography>
            <strong>{getResourceFullName(resource.properties)}</strong>
            {` (${getPropertyValueDef(resource.properties, SystemProperties.RESOURCES_ID, '')})`}
          </Typography>
        </BryntumToolbar>
        <ResourceContextProvider>
          <JobContextProvider>
            <MyCalendarTemplate
              date={date}
              onChangeEvent={onChangeEvent}
              resourceId={resource._id}
              openEventPanel={handleOpen}
            />
          </JobContextProvider>
        </ResourceContextProvider>
        <Portal container={containerRef.current}>
          <Slide
            direction="left"
            in={!!openEventTemplate}
            container={containerRef.current}
            easing={'linear'}
            timeout={DelayTimes.SLIDE_TRANSITION}
            mountOnEnter
            unmountOnExit>
            <Container sx={{ position: 'absolute', backgroundColor: theme.palette.common.white }}>
              {openEventTemplate && (
                <EventPanelTemplate
                  mode={openEventTemplate.mode}
                  eventId={openEventTemplate.eventId}
                  resourceId={resource?._id}
                  startDate={openEventTemplate.startDate}
                  onSuccess={(newEvents) => {
                    if (newEvents) {
                      if (openEventTemplate.mode === CrudModes.CREATE) {
                        addEvents(Array.isArray(newEvents) ? newEvents : [newEvents]);
                        // setNewEvents(Array.isArray(newEvents) ? newEvents : [newEvents]);
                      }
                      if (openEventTemplate.mode === CrudModes.EDIT) {
                        if (!Array.isArray(newEvents) && openEventTemplate.eventId) {
                          updateEvent(newEvents, openEventTemplate.eventId);
                        }
                      }
                      if (onChangeEvent) {
                        onChangeEvent(
                          Array.isArray(newEvents) ? newEvents : [newEvents],
                          openEventTemplate.mode
                        );
                      }
                      setOpenEventTemplate(undefined);
                    }
                  }}
                  onDirtyFields={(isDirty) => {
                    if (handleDirtyEvent) handleDirtyEvent(isDirty);
                  }}
                  onCancel={() => handleOpen(undefined)}
                  // onLoading={(loading) => preventFromClose(loading)}
                />
              )}
            </Container>
          </Slide>
        </Portal>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button variant="contained" onClick={onCancel}>
            {t('buttons.close')}
          </Button>
        </ButtonsContainer>
      </FooterModalContainer>
    </Container>
  );
};

export default ResourceCalendarPanelTemplate;
