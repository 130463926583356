import brand from '@/assets/imgs/xo-sheduler-1.png';
import { auth } from '@/config/firebase.config';
import { useToastContext } from '@/context/ToastContext';
import { TextField, theme } from '@/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, Typography } from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoginSchemaType, loginSchema } from './LoginTemplate.schema';
import { Brand, BrandingContainer, CardLogin, LoginContainer } from './LoginTemplate.styles';

const LoginTemplate = () => {
  const { t: tLogin } = useTranslation('templates/loginTemplate');
  const { t: tCommon } = useTranslation();
  const { setToastNotifications } = useToastContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const intendedDestination = sessionStorage.getItem('intended-destination');
    if (intendedDestination == 'null') {
      sessionStorage.removeItem('intended-destination');
    }
    localStorage.clear();
  }, []);

  const onSubmit = useCallback(async (form: LoginSchemaType) => {
    setLoading(true);
    //save token
    const { email, password } = form;

    signInWithEmailAndPassword(auth, email as string, password as string).catch((error) => {
      if (
        error.code == 'auth/user-not-found' ||
        error.code == 'auth/wrong-password' ||
        error.code == 'auth/user-disabled' ||
        error.code == 'auth/invalid-email'
      ) {
        setToastNotifications([{ message: tLogin('errorAuth') }]);
      } else if (error.code == 'auth/too-many-requests') {
        setToastNotifications([{ message: tLogin('errorManyRequests') }]);
      } else {
        setToastNotifications([{ message: tCommon('errors.defaultError') }]);
      }
      setLoading(false);
    });
  }, []);

  const goToForgot = () => {
    navigate('/reset');
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<LoginSchemaType>({
    resolver: yupResolver(loginSchema),
    mode: 'onBlur'
  });

  return (
    <LoginContainer>
      <Card>
        <CardLogin onSubmit={handleSubmit(onSubmit)}>
          <BrandingContainer>
            <Brand src={brand} />
          </BrandingContainer>

          <TextField
            fullWidth
            size="small"
            {...register('email')}
            variant="outlined"
            label={tLogin('email')}
            error={!!errors.email}
            inputProps={{
              autoComplete: 'username'
            }}
          />
          <TextField
            fullWidth
            size="small"
            {...register('password')}
            variant="outlined"
            label={tLogin('password')}
            error={!!errors.password}
            type="password"
            inputProps={{
              autoComplete: 'current-password'
            }}
          />
          <LoadingButton
            loading={loading}
            fullWidth
            disabled={!isValid}
            type="submit"
            variant="contained">
            {tLogin('login')}
          </LoadingButton>
          <Typography
            sx={{ cursor: 'pointer', userSelect: 'none' }}
            onClick={goToForgot}
            variant="body2"
            color={theme.palette.secondary.light}>
            {tLogin('forgotPassword')}
          </Typography>
        </CardLogin>
      </Card>
    </LoginContainer>
  );
};

export default LoginTemplate;
