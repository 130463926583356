import { Topic } from '@/types';

export enum DragNDropComponents {
  SESSION = 'Sess',
  TOPIC = 'Topic',
  NEW_TOPIC = 'New_topic',
  NEW_TIME_SESSION = 'New_Time_Session',
  NEW_SESION = 'New_Session'
}

export enum TabOptions {
  SESSION = 'session',
  TOPIC = 'topic',
  CURRICULUM = 'curriculum'
}

export enum ToolbarButtons {
  CREATE_TOPIC = 'create_topic',
  CREATE_SESSION = 'create_Session',
  CREATE_TIME_SESSION = 'create_TimeSession'
}

export interface SessionColumn {
  //TODO: Temporal Interfaces.
  id: string;
  title: string;
  topics: Topic[];
}

export interface TopicItem {
  //TODO: Temporal Interfaces.
  id: string | number;
  text: string;
  color: string;
}

export const TimeInterval = 15;

export const generateDragNDropId = (componentType: DragNDropComponents, id: string) => {
  return `${componentType}_${id}`;
};
