import axios, { AxiosRequestConfig } from 'axios';
import { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';
import FetchContext from './FetchContext';

const fetcher = async ({ url, ...args }: AxiosRequestConfig) => {
  const { method = 'GET', data, transformResponse } = args || {};
  const { data: result } = await axios({ url, method, data, transformResponse });
  return result;
};

const FetchContextProvider = ({ children }: PropsWithChildren) => {
  const value = { fetcher };

  return (
    <SWRConfig value={value}>
      <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
    </SWRConfig>
  );
};

export default FetchContextProvider;
