import { Entities, PropertyTypes } from '@/const';
import { FieldFilterType, PresetDto, PropertyDto, ResourceBryntum } from '@/types';
import { transformPropertyValue } from '@/utils';
import { Model, ModelConfig } from '@bryntum/core-thin';
import { BryntumGrid, BryntumGridProps } from '@bryntum/grid-react-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { Skeleton } from '@mui/material';
import { TFunction } from 'i18next';
import { ForwardedRef, RefObject } from 'react';

export const extraFields: FieldFilterType[] = [
  {
    name: 'Name',
    _id: 'name',
    type: PropertyTypes.STRING,
    order: 0
  }
];

export const getGridConfig = (extraItems: BryntumGridProps): BryntumGridProps => {
  return {
    animateRemovingRows: false,
    columns: [
      ...Array(4)
        .fill('')
        .map(() => ({
          text: '...',
          width: 220
        }))
    ],
    filterBarFeature: false,
    stripeFeature: true,
    selectionMode: {
      row: true,
      cell: false,
      multiSelect: true
    },
    cellEditFeature: false,
    ...extraItems
  };
};

export const getColumns = (
  ref: ForwardedRef<BryntumGrid>,
  t: TFunction,
  tPreset: TFunction,
  properties?: PropertyDto[]
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumGrid>).current?.instance
    .columns as ColumnStore;
  columnStore.removeAll();
  columnStore.setConfig({ rowHeight: 230 });
  if (properties) {
    columnStore.add([
      {
        id: 'counter',
        type: 'rownumber',
        cls: 'counter-header',
        cellCls: 'b-border-bottom',
        align: 'center',
        htmlEncodeHeaderText: false,
        hideable: false
      },
      {
        text: tPreset('name'),
        id: 'name',
        field: 'name',
        width: 120,
        align: 'left',
        sortable: (record1: Model, record2: Model) => {
          const name1 = (record1.getData('preset') as PresetDto)?.name.toLowerCase() ?? '';
          const name2 = (record2.getData('preset') as PresetDto)?.name.toLowerCase() ?? '';
          if (name1 < name2) return -1;
          if (name1 > name2) return 1;
          return 0;
        },
        groupable: false,
        renderer: ({ record: { data } }) => {
          return !data.preset ? (
            <Skeleton height={30} />
          ) : data?.preset?.name ? (
            data.preset.name
          ) : (
            ''
          );
        }
      },
      {
        text: tPreset('type'),
        id: 'type',
        field: 'type',
        width: 120,
        align: 'left',
        sortable: (record1: Model, record2: Model) => {
          const name1 =
            (record1.getData('preset') as PresetDto)?.reference?.type?.label.toLowerCase() ?? '';
          const name2 =
            (record2.getData('preset') as PresetDto)?.reference?.type?.label.toLowerCase() ?? '';
          if (name1 < name2) return -1;
          if (name1 > name2) return 1;
          return 0;
        },
        groupable: false,
        renderer: ({ record: { data } }) => {
          return !data.preset ? (
            <Skeleton height={30} />
          ) : data?.preset?.reference?.type?.label ? (
            data.preset.reference?.type?.label
          ) : (
            ''
          );
        }
      },
      {
        text: tPreset('defaultValues'),
        id: 'defaultValues',
        field: 'defaultValues',
        align: 'left',
        width: 220,
        autoHeight: true,
        htmlEncode: false,
        sortable: false,
        groupable: false,
        renderer: ({ record: { data }, row }) => {
          const filteredProperties = (properties as PropertyDto[])
            .sort((p1, p2) => p1._id.localeCompare(p2._id))
            .filter((property) => data[property._id] !== undefined);

          if (data?.curriculum) {
            filteredProperties.unshift({
              _id: 'curriculum',
              name: t('entities.curriculums_one'),
              type: PropertyTypes.SET,
              entity: Entities.JOBS
            });
          }
          if (data?.missions) {
            filteredProperties.push({
              _id: 'missions',
              name: t('entities.missions_other'),
              type: PropertyTypes.SET,
              entity: Entities.RESOURCES
            });
          }
          if (data?.workRules) {
            filteredProperties.push({
              _id: 'workRules',
              name: t('entities.work-rules_other'),
              type: PropertyTypes.SET,
              entity: Entities.RESOURCES
            });
          }

          const propertiesToRender = filteredProperties
            .slice(0, 3)
            .map(({ _id: id, name: text, type }) => {
              return {
                type: 'widget',
                html: tPreset('text', {
                  name: text,
                  value:
                    type == PropertyTypes.COLOR
                      ? data[id]
                      : type == PropertyTypes.IMAGE
                      ? t('booleanValues.yes')
                      : transformPropertyValue(type, t, data[id])
                })
              };
            });
          if (filteredProperties.length === 4) {
            propertiesToRender.push({
              type: 'widget',
              html: tPreset('text', {
                name: filteredProperties[3].name,
                value:
                  filteredProperties[3].type == PropertyTypes.COLOR
                    ? data[filteredProperties[3]._id]
                    : filteredProperties[3].type == PropertyTypes.IMAGE
                    ? t('booleanValues.yes')
                    : transformPropertyValue(
                        filteredProperties[3].type,
                        t,
                        data[filteredProperties[3]._id]
                      )
              })
            });
          } else if (filteredProperties.length > 4) {
            propertiesToRender.push({
              type: 'widget',
              html: tPreset('propertiesOverflow', { properties: filteredProperties.length - 3 })
            });
          }
          return !data.preset ? (
            <Skeleton height={30} />
          ) : (
            [
              {
                style: { padding: '0.5rem 0' },
                children: propertiesToRender
              }
            ]
          );
        }
      }
    ] as unknown as ModelConfig[]);
  } else {
    columnStore.add([
      ...new Array(5).fill('').map((element) => ({
        text: '...',
        width: 220,
        renderer: () => {
          return <Skeleton height={30} />;
        }
      }))
    ]);
  }
};

export const transformPresets = (presets?: PresetDto[]) => {
  let localPresets: ResourceBryntum[];

  if (presets)
    localPresets = presets.map((preset) => {
      const localPreset: ResourceBryntum = {
        id: preset._id,
        preset
      };
      preset.payload.properties?.forEach(
        (property) => (localPreset[property._id] = property.label ?? property.value)
      );
      if (preset.payload.curriculum) localPreset['curriculum'] = preset.payload.curriculum.label;
      if (preset.payload.workRules) localPreset['workRules'] = preset.payload.workRules.label;
      if (preset.payload.missions) localPreset['missions'] = preset.payload.missions.label;
      return localPreset;
    });
  else localPresets = new Array(5).fill('').map((_, idx) => ({ id: `${idx}` }));
  return localPresets;
};
