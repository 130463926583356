import { EntityRoutes, LIGHT_SEARCH } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { ListRow, TextField } from '@/theme';
import { LabeledReference, PossibleValues, isMultipleInputValue } from '@/types';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  Chip,
  CircularProgress,
  SxProps,
  TextFieldProps,
  Typography
} from '@mui/material';
import { CanceledError } from 'axios';
import { RefObject, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ReferenceCollectionTypes =
  | EntityRoutes.RESOURCES
  | EntityRoutes.JOBS
  | EntityRoutes.DOCUMENTS
  | EntityRoutes.RESOURCE_RECORDS
  | EntityRoutes.CURRICULUMS;

export type ObjectidAutocompleteProps = {
  multiple?: boolean;
  referencedCollection: ReferenceCollectionTypes;
  onChange: (value: LabeledReference | LabeledReference[] | null) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  sx?: SxProps;
  listSize?: number;
  inputRef?: RefObject<HTMLInputElement>;
  inputProps?: Partial<TextFieldProps>;
  error?: boolean;
  helperText?: string | boolean;
  readOnly?: boolean;
  additionalProps?: Record<string, any>;
  placeholder?: string;
} & (OneObjectisProps | MultiObjectisProps) &
  Pick<TextFieldProps, 'id' | 'label' | 'name' | 'autoComplete' | 'disabled'>;

type OneObjectisProps = {
  multiple?: false;
} & PossibleValues<LabeledReference | null>;

type MultiObjectisProps = {
  multiple: true;
} & PossibleValues<LabeledReference[] | null>;

const ObjectidAutocomplete = ({
  multiple,
  value,
  includeMultipleObj = false,
  label,
  id,
  name,
  autoComplete,
  referencedCollection,
  listSize = 20,
  onChange,
  onKeyDown,
  inputRef,
  sx = [],
  error,
  disabled = false,
  readOnly = false,
  helperText,
  placeholder = '',
  additionalProps
}: ObjectidAutocompleteProps) => {
  const {
    fetch,
    data,
    loading: loadingOptions,
    error: errorOptions
  } = useFetch<LabeledReference[]>();

  const { t } = useTranslation('common');
  const entityNames: Record<ReferenceCollectionTypes, string> = {
    [EntityRoutes.RESOURCES]: t('entities.resources_other').toLowerCase(),
    [EntityRoutes.JOBS]: t('entities.jobs_other').toLowerCase(),
    [EntityRoutes.DOCUMENTS]: t('entities.documents_other').toLowerCase(),
    [EntityRoutes.RESOURCE_RECORDS]: t('entities.resourcerecords_other').toLowerCase(),
    [EntityRoutes.CURRICULUMS]: t('entities.curriculums_other').toLowerCase()
  };
  const { setToastNotifications } = useToastContext();

  const [options, setOptions] = useState<LabeledReference[]>([]);

  const addNewOption = useCallback(
    (newOptions: LabeledReference[]) => {
      setOptions(
        value
          ? getUniqueValues(
              isMultipleInputValue(value) ? [] : multiple ? value : [value],
              newOptions
            )
          : newOptions
      );
    },
    [options, value]
  );

  const getUniqueValues = (
    arrayA: LabeledReference[],
    arrayB: LabeledReference[]
  ): LabeledReference[] => {
    const stringifiedOptions = new Set(
      [...arrayA, ...arrayB].map((option) => JSON.stringify(option))
    );
    return (
      Array.from(stringifiedOptions).map((option) => JSON.parse(option)) as LabeledReference[]
    ).filter((val) => val?._id && val?.label);
  };

  const [debounceTimeoutId, setDebounceTimeoutId] = useState<NodeJS.Timeout>();
  const [loadingInputChange, setLoadingInputChange] = useState<boolean>(false);
  const [actualInputValue, setActualInputValue] = useState<string>('');

  useEffect(() => {
    setOptions([]);
  }, [additionalProps]);

  const handleInputChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      inputValue: string,
      reason: AutocompleteInputChangeReason
    ) => {
      switch (reason) {
        case 'input':
          setActualInputValue(inputValue);
          if (debounceTimeoutId) clearTimeout(debounceTimeoutId);
          setLoadingInputChange(true);
          if (inputValue === '') {
            if (includeMultipleObj) onChange(null);
            addNewOption([]);
            setLoadingInputChange(false);
          } else {
            const timeoutId = setTimeout(() => {
              fetch({
                url: `${LIGHT_SEARCH.replace(':entity', referencedCollection)}?size=${listSize}`,
                method: 'POST',
                data: { query: inputValue, ...additionalProps }
              });
            }, 300);
            setDebounceTimeoutId(timeoutId);
          }
          break;
      }
    },
    [debounceTimeoutId, additionalProps]
  );

  useEffect(() => {
    if (value) {
      addNewOption([]);
    }
  }, [value]);

  useEffect(() => {
    if (data) {
      addNewOption(data);
      setLoadingInputChange(false);
    }
    if (errorOptions && !(errorOptions instanceof CanceledError)) {
      setToastNotifications([
        {
          message: t('errors.loadingEntityList', { entity: entityNames[referencedCollection] })
        }
      ]);
      setLoadingInputChange(false);
    }
  }, [errorOptions, data]);

  return (
    <Autocomplete
      readOnly={readOnly}
      fullWidth
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      multiple={multiple}
      disableClearable={includeMultipleObj}
      options={options}
      disabled={disabled}
      filterSelectedOptions={false}
      filterOptions={(options, state) => options}
      onKeyDown={onKeyDown}
      isOptionEqualToValue={(option, value) =>
        option._id === value._id && option.label === value.label
      }
      loading={loadingOptions || loadingInputChange}
      noOptionsText={
        <Typography sx={{ lineHeight: '1rem' }}>{`${
          actualInputValue.length > 0 ? t('text.emptyOptions') : t('text.search')
        }`}</Typography>
      }
      renderOption={(props, option) => (
        <ListRow {...props} key={option._id}>
          {option.label}
        </ListRow>
      )}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option._id}
            style={{ margin: '1px 3px !important' }}
            label={option.label}
            size="small"
          />
        ));
      }}
      loadingText={
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center'
          }}>
          <CircularProgress size="1rem" />
        </Box>
      }
      getOptionLabel={(option) => option?.label ?? ''}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            includeMultipleObj
              ? isMultipleInputValue(value)
                ? t('text.multipleValues')
                : ''
              : placeholder
          }
          size="small"
          label={label ?? ''}
          error={error}
          inputRef={inputRef}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            ...params.inputProps,
            id,
            name,
            autoComplete
          }}
          helperText={helperText}
        />
      )}
      value={value && !isMultipleInputValue(value) ? value : multiple ? [] : null}
      inputValue={multiple ? actualInputValue : undefined}
      onChange={(_, newValue) => {
        setActualInputValue('');
        onChange(newValue);
      }}
      onInputChange={handleInputChange}
    />
  );
};

export default ObjectidAutocomplete;
