import { AccordionSummary } from '@/components/molecules/Card/Card.styles';

import { MultipleInputRow } from '@/components/molecules';
import {
  COMPONENTS_BY_TYPE,
  validateByType,
  validateRequired
} from '@/components/organisms/PropertiesForm/PropertiesForm.const';
import { CrudModes } from '@/const';
import { Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';
import { FORM_TYPES, makeForm } from '../../../Forms/Curriculum/CurriculumForm.const';
import { FormType } from '../ResourceSlotPanel';

const TopicResourceSlotForm = ({
  mode,
  isMultiple = false
}: {
  mode: CrudModes;
  isMultiple?: boolean;
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormType>();
  const formProperties = useMemo(() => makeForm(FORM_TYPES.TOPIC_RESOURCE_SLOT), []);
  const { t: tResourceSlot } = useTranslation('organisms/resourceSlot');
  const readOnly = mode === CrudModes.READ;
  return (
    <>
      <AccordionSummary
        sx={{
          height: '2.5rem',
          cursor: 'auto !important',
          marginBotton: '1rem'
        }}>
        <Typography variant="body3" ml="0.4rem">
          {tResourceSlot('propertiesInsideTopics')}
        </Typography>
      </AccordionSummary>
      <Grid container display={'grid'} gridTemplateColumns={'1fr'} width={'100%'}>
        {formProperties.map(({ name, required, type, componentProps = {}, ...rest }) => {
          const Component = COMPONENTS_BY_TYPE[type];
          return (
            <Grid width={'100%'} margin={'0.5rem 0'} key={name}>
              <Controller
                name={`topicSlot.${name}` as any}
                control={control}
                rules={{
                  validate: {
                    type: validateByType(type),
                    required: validateRequired(required, type)
                  }
                }}
                render={({ field }) => {
                  return (
                    <MultipleInputRow
                      value={field.value}
                      onChange={field.onChange}
                      fitInput={!readOnly && isMultiple}
                      additionalClearRenderConditions={!readOnly && isMultiple}>
                      <Component
                        includeMultipleObj={isMultiple}
                        disabled={readOnly}
                        {...rest}
                        {...componentProps}
                        label={
                          <>
                            {tResourceSlot(`${name}` as any)}
                            {required && (
                              <FaAsterisk size={12} style={{ marginLeft: '5px' }}></FaAsterisk>
                            )}
                          </>
                        }
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors.topicSlot?.[name]}
                      />
                    </MultipleInputRow>
                  );
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default TopicResourceSlotForm;
