import { useLayoutEffect, useState } from 'react';
import { useDebouncyFn } from 'use-debouncy';

export const useWindowSize = (delay = 100) => {
  const [size, setSize] = useState([0, 0]);
  const updateSize = useDebouncyFn(() => setSize([window.innerWidth, window.innerHeight]), delay);
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
