import {
  COMPONENTS_BY_TYPE,
  validateByType,
  validateRequired
} from '@/components/organisms/PropertiesForm/PropertiesForm.const';
import { AutoCompleteField, CrudModes } from '@/const';
import { TextField } from '@/theme';
import { AssignmentType } from '@/types';
import { Grid, MenuItem } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';
import { FORM_TYPES, makeForm } from '../../../Forms/Curriculum/CurriculumForm.const';
import { FormType } from '../ResourceSlotPanel';

const ResourceSlotForm = ({ mode }: { mode: CrudModes }) => {
  const { t: tResourceSlot } = useTranslation('organisms/resourceSlot');
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext<FormType>();

  const readOnly = mode === CrudModes.READ;
  const formProperties = useMemo(() => makeForm(FORM_TYPES.RESOURCE_SLOT), []);

  const documents = useWatch({ control, name: 'resourceSlot.documents' });

  const quantity = documents?.length || 0;

  useEffect(() => {
    quantity >= 1 && setValue('resourceSlot.quantity', 1);
  }, [documents]);

  return (
    <>
      <Grid container display={'grid'} gridTemplateColumns={'1fr'} width={'100%'}>
        {formProperties.map(({ name, required, type, componentProps = {}, ...rest }) => {
          const Component = COMPONENTS_BY_TYPE[type];
          return (
            <Grid width={'100%'} margin={'0.5rem 0'} key={name}>
              <Controller
                name={`resourceSlot.${name}` as any}
                control={control}
                rules={{
                  validate: {
                    type: validateByType(type),
                    required: validateRequired(required, type)
                  }
                }}
                render={({ field }) => {
                  return (
                    <Component
                      name={`resourceSlot_${name}`}
                      autoComplete={AutoCompleteField}
                      readOnly={readOnly || (name === 'quantity' && quantity >= 1)}
                      disabled={readOnly}
                      {...rest}
                      {...componentProps}
                      label={
                        <>
                          {tResourceSlot(`properties.${name}` as any)}
                          {required && (
                            <FaAsterisk size={12} style={{ marginLeft: '5px' }}></FaAsterisk>
                          )}
                        </>
                      }
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.resourceSlot?.[name]}
                    />
                  );
                }}
              />
            </Grid>
          );
        })}
        {/* TODO: if needed a custom static autocomplete, modify and improve SelectFilter */}
        <Grid width={'100%'} margin={'0.5rem 0'}>
          <Controller
            name={'resourceSlot.assignmentType'}
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  inputProps={{ readOnly }}
                  select
                  disabled={readOnly}
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={field.value ?? ''}
                  label={<>{tResourceSlot(`properties.assigmentType`)}</>}
                  onChange={field.onChange}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.resourceSlot?.assignmentType}>
                  <MenuItem value={AssignmentType.BALANCED}>
                    {tResourceSlot('assigmentType.balanced')}
                  </MenuItem>
                  <MenuItem value={AssignmentType.LESS_FRAGMENTED}>
                    {tResourceSlot('assigmentType.lessFragmented')}
                  </MenuItem>
                </TextField>
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ResourceSlotForm;
