import { useToastContext } from '@/context/ToastContext';
import { Alert, Portal, Snackbar } from '@mui/material';

const ToastNotification = () => {
  const { toastNotifications, setToastNotifications } = useToastContext();

  const handleCloseAlert = (_?: React.SyntheticEvent | Event) => {
    setToastNotifications([]);
  };

  return (
    <>
      <Portal>
        {toastNotifications &&
          toastNotifications.map((alert, idx) => {
            return (
              <Snackbar
                key={idx}
                open={!!alert}
                autoHideDuration={4000}
                onClose={handleCloseAlert}
                sx={{ maxWidth: '90vw' }}>
                <Alert onClose={handleCloseAlert} severity={alert.type ?? 'error'}>
                  {alert.component ?? alert.message}
                </Alert>
              </Snackbar>
            );
          })}
      </Portal>
    </>
  );
};

export default ToastNotification;
