import { RenderIf } from '@/components/atoms';
import { MultipleInputRow, PresetButton, SetAutocomplete } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import PropertiesForm from '@/components/organisms/PropertiesForm/PropertiesForm';
import {
  AutoCompleteField,
  COLLECTIONS_BATCH_UPDATE,
  COLLECTIONS_BATCH_UPDATE_PREPARE,
  COLLECTIONS_FIND_ALL,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  DelayTimes,
  Entities,
  EntityRoutes,
  FILES,
  FILES_BY_ID,
  LABELS_SEARCH,
  PROPERTY_SWITCHES_BY_ENTITY_TYPE,
  PropertyTypes
} from '@/const';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch, useScrollToError } from '@/hooks';
import useGetDefaultPresetByEntity from '@/hooks/useGetDefaultPresetByEntity';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import { ButtonsContainer, Container, theme } from '@/theme';
import {
  FileDto,
  LabeledReference,
  MultiResourceDto,
  MultipleValue,
  Properties,
  PropertySwitchDto,
  ResourceDto
} from '@/types';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Unstable_Grid2 as Grid,
  Skeleton,
  Tab,
  Typography
} from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { isArray, isEqual } from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPaperclip, FaTags } from 'react-icons/fa';
import { PresetModes } from '../../PropertyPresetPanelTemplate/PropertyPresetPanelTemplate.const';
import {
  filterPropsByEntityData,
  filterPropsBySwitches,
  formatForm,
  getDefaultPropertiesAsFormValues
} from './ResourcePanelTemplate.const';
import DocumentSelectionView from './components/DocumentSelectionView/DocumentSelectionView';
import RecordEditionView from './components/RecordEditionView/RecordEditionView';
import RecordListView from './components/RecordListView/RecordListView';

export type ResourcePanelTemplateProps = {
  mode: Exclude<CrudModes, 'delete' | 'read'>;
  resourceId?: string | string[];
  resourceType?: LabeledReference;
  onLoading: (loading: boolean) => unknown;
  onSuccess: (entity?: ResourceDto) => unknown;
  onCancel: (isDirty?: boolean) => unknown;
  onDirtyFields: (isDirty: boolean, modalName: string) => unknown;
};

const ResourcePanelTemplate = ({
  mode,
  resourceId,
  resourceType,
  onLoading,
  onSuccess,
  onCancel,
  onDirtyFields
}: ResourcePanelTemplateProps) => {
  const { t: tResourcePanel } = useTranslation('templates/resourcePanelTemplate');
  const { t: tCommon } = useTranslation();
  const { setToastNotifications } = useToastContext();
  const { slide, removeSlide } = useGenericModalContext();
  const [initialValues, setInitialValues] = useState<
    | (Properties & {
        missions?: LabeledReference | MultipleValue | null;
        workRules?: LabeledReference | MultipleValue | null;
      })
    | undefined
  >(undefined);
  const isMultiple = isArray(resourceId);

  const {
    data: multipleData,
    fetch,
    loading: loadingMultiple,
    error: multipleError
  } = useFetch<MultiResourceDto>();
  useEffect(() => {
    if (isMultiple) {
      fetch({
        url: COLLECTIONS_BATCH_UPDATE_PREPARE.replace(':collection_name', EntityRoutes.RESOURCES),
        data: { ids: resourceId },
        method: 'POST'
      });
    }
  }, [isMultiple]);

  useEffect(() => {
    if (multipleError) {
      if (multipleError?.response?.data?.code == 'resource_not_found') {
        setToastNotifications([
          {
            message: tCommon('errors.entitiesNotFound', {
              entity: tCommon('entities.resources_other')
            })
          }
        ]);
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.loadingEntity', {
              entity: tCommon('entities.resources_other')
            })
          }
        ]);
      }
      onCancel(true);
    }
  }, [multipleError]);

  const { properties, entityData, loading, error } = useGetPropertiesByEntity<ResourceDto>(
    Entities.RESOURCES,
    isMultiple ? undefined : (resourceId as string)
  );

  const { defaultValues, changeDefaultValues, loadingDefaultValues, errorDefaultValues } =
    useGetDefaultPresetByEntity(Entities.RESOURCES, mode == CrudModes.CREATE, resourceType?._id);

  useEffect(() => {
    if (defaultValues?.data?.properties && properties) {
      defaultValues?.data?.properties.forEach((property) => {
        setValues(
          `properties.${property._id}`,
          property.label ? { label: property.label, _id: property.value } : property.value,
          { shouldDirty: defaultValues.type === PresetModes.CUSTOM }
        );
      });
      if (defaultValues.data?.missions) {
        setValues('missions', defaultValues.data?.missions, {
          shouldDirty: defaultValues.type === PresetModes.CUSTOM
        });
      }
      if (defaultValues.data?.workRules) {
        setValues('workRules', defaultValues.data?.workRules, {
          shouldDirty: defaultValues.type === PresetModes.CUSTOM
        });
      }
    }
  }, [defaultValues, properties]);

  const filteredMultiProperties = useMemo(() => {
    const propIds = multipleData?.properties
      .map((prop) => prop._id)
      .filter((prop) => typeof prop === 'string');
    return properties?.filter((prop) => propIds?.includes(prop._id));
  }, [multipleData, properties]);

  const {
    data: propertySwitch,
    fetch: fetchPropertySwitches,
    error: switchErrors,
    loading: loadingSwitches
  } = useFetch<PropertySwitchDto[]>(undefined, undefined, undefined, undefined, 3, 500);

  const [transitionLoading, setTransitionLoading] = useState(false);

  useEffect(() => {
    setTransitionLoading(!(loadingSwitches === loading));
  }, [loading, loadingSwitches]);

  useEffect(() => {
    if (mode === CrudModes.CREATE && resourceType) {
      fetchPropertySwitches({
        url: PROPERTY_SWITCHES_BY_ENTITY_TYPE.replace(':entity', EntityRoutes.RESOURCES).replace(
          ':entityType',
          resourceType._id
        ),
        method: 'GET'
      });
    } else if (mode === CrudModes.EDIT && entityData && entityData.type) {
      fetchPropertySwitches({
        url: PROPERTY_SWITCHES_BY_ENTITY_TYPE.replace(':entity', EntityRoutes.RESOURCES).replace(
          ':entityType',
          entityData.type._id
        ),
        method: 'GET'
      });
    }
  }, [resourceType, entityData]);

  const {
    control,
    handleSubmit,
    reset,
    formState,
    setError,
    watch,
    setValue: setValues
  } = useForm<
    Properties & {
      missions?: LabeledReference | MultipleValue | null;
      workRules?: LabeledReference | MultipleValue | null;
    }
  >({
    mode: mode === CrudModes.CREATE ? 'onSubmit' : 'onChange',
    defaultValues: undefined
  });
  const { toScroll } = useScrollToError();

  useEffect(() => {
    if (error || switchErrors) {
      if (error?.response?.data?.code == 'resource_not_found') {
        setToastNotifications([
          {
            message: tCommon('errors.entityNotFound', { entity: tCommon('entities.resources_one') })
          }
        ]);
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.loadingEntity', { entity: tCommon('entities.resources_one') })
          }
        ]);
      }
      onCancel(false);
    }
  }, [error, switchErrors]);

  useEffect(() => {
    if (properties) {
      if (isMultiple) {
        if (mode === CrudModes.EDIT && multipleData) {
          const formattedProps: Record<string, any> = multipleData.properties.reduce(
            (sum, { _id, label, value, multiple }) => {
              return {
                [_id]: !multiple && label ? { _id: value, label } : multiple ? { multiple } : value,
                ...sum
              };
            },
            {}
          );
          reset({
            properties: formattedProps,
            missions: multipleData.missions,
            workRules: multipleData.workRules
          });
          setInitialValues({
            properties: formattedProps,
            missions: multipleData.missions,
            workRules: multipleData.workRules
          });
        }
      } else {
        if (mode === CrudModes.EDIT && entityData && propertySwitch) {
          const newValues = getDefaultPropertiesAsFormValues(
            filterPropsByEntityData(properties, entityData, propertySwitch),
            entityData
          );
          reset({
            properties: newValues,
            missions: entityData.missions,
            workRules: entityData.workRules
          });
        } else if (mode === CrudModes.EDIT && !entityData && propertySwitch) {
          setToastNotifications([
            {
              message: tCommon('errors.resourceNotFound', {
                resource: tCommon('entities.resources_one')
              })
            }
          ]);
          onCancel(false);
        }
      }
    }
  }, [properties, entityData, propertySwitch, isMultiple, multipleData]);

  const submit = useCallback(
    async (
      form: Properties & {
        missions?: LabeledReference | MultipleValue | null;
        workRules?: LabeledReference | MultipleValue | null;
      }
    ) => {
      setLoadingPatch(true);
      let query: AxiosRequestConfig | undefined = undefined;

      switch (mode) {
        case CrudModes.CREATE:
          if ((entityData || resourceType) && properties && propertySwitch) {
            const formattedForm = formatForm(
              filterPropsBySwitches(properties, propertySwitch),
              form.properties
            );

            const propertiesAux = formattedForm.filter(
              (property) => !(property.value instanceof File)
            );
            const type = entityData ? entityData.type : resourceType;
            const missions = form.missions;
            const workRules = form.workRules;

            const data = { type, missions, workRules, properties: propertiesAux };
            query = {
              url: COLLECTIONS_FIND_ALL.replace(':collection_name', Entities.RESOURCES),
              method: 'POST',
              data: data
            };
            const files = formattedForm.filter((property) => property.value instanceof File);
            if (files.length > 0) {
              const filePropertiesPromise = Promise.all(
                files.map(async (file) => {
                  if (query) {
                    const formData = new FormData();
                    formData.append('file', file.value);
                    formData.append('documentType', '1');
                    formData.append('isPublic', '0');
                    const filesInfo = (await fetchFile({
                      url: FILES,
                      method: 'POST',
                      data: formData,
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    })) as FileDto;
                    if (filesInfo instanceof Error) {
                      return Promise.reject('error');
                    }
                    query.data.properties.push({ _id: file._id, value: filesInfo.fileId });
                  }
                })
              );
              filePropertiesPromise
                .then((data) => {
                  if (query) {
                    fetchPatchOfEntity(query);
                  }
                })
                .catch(() => {
                  setToastNotifications([{ message: tResourcePanel('errors.savingFile') }]);
                });
            } else {
              if (query) {
                fetchPatchOfEntity(query);
              }
            }
          }
          break;
        case CrudModes.EDIT:
          if (isArray(resourceId)) {
            const queryData: Record<string, any> = { properties: [] };
            multipleData?.properties.forEach(({ _id, label, value, multiple }) => {
              const formattedProp =
                !multiple && label ? { _id: value, label } : multiple ? { multiple } : value;
              if (!isEqual(formattedProp, form.properties[_id])) {
                queryData.properties.push({
                  _id,
                  ...(form.properties[_id] !== undefined && form.properties[_id] !== null
                    ? {
                        value: form.properties[_id]?._id
                          ? form.properties[_id]._id
                          : form.properties[_id]
                      }
                    : { delete: true })
                });
              }
            });

            if (!isEqual(form.missions, multipleData?.missions)) {
              queryData['missions'] = form.missions
                ? { _id: (form.missions as LabeledReference)._id }
                : { delete: true };
            }
            if (!isEqual(form.workRules, multipleData?.workRules)) {
              queryData['workRules'] = form.workRules
                ? { _id: (form.workRules as LabeledReference)._id }
                : { delete: true };
            }
            query = {
              url: COLLECTIONS_BATCH_UPDATE.replace(':collection_name', EntityRoutes.RESOURCES),
              method: 'PATCH',
              data: { ids: resourceId, ...queryData }
            };
            fetchPatchOfEntity(query);
          } else if (resourceId && properties && entityData && propertySwitch) {
            const formattedForm = formatForm(
              filterPropsBySwitches(properties, propertySwitch),
              form.properties
            );

            const propertiesAux = formattedForm.filter(
              (property) => !(property.value instanceof File)
            );
            const type = entityData ? entityData.type : resourceType;
            const missions = form.missions;
            const workRules = form.workRules;

            const data = { type, missions, workRules, properties: propertiesAux };
            query = {
              url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.RESOURCES).replace(
                ':id',
                resourceId as string
              ),
              method: 'PATCH',
              data: data
            };
            const fileProperties = properties.filter(
              (property) => property.type == PropertyTypes.IMAGE
            );
            const filePropertiesPromise = Promise.all(
              fileProperties.map(async (fileProperty) => {
                if (
                  entityData.properties.find((property) => property._id == fileProperty._id)
                    ?.value !== form.properties[fileProperty._id] &&
                  query
                ) {
                  if (
                    !entityData.properties.find((property) => property._id == fileProperty._id)
                      ?.value &&
                    form.properties[fileProperty._id]
                  ) {
                    const formData = new FormData();
                    formData.append('file', form.properties[fileProperty._id]);
                    formData.append('documentType', '1');
                    formData.append('isPublic', '0');

                    const filesInfo = (await fetchFile({
                      url: FILES,
                      method: 'POST',
                      data: formData,
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    })) as FileDto;
                    if (filesInfo instanceof Error) {
                      return Promise.reject('error');
                    }
                    query.data.properties.push({
                      _id: fileProperty._id,
                      value: filesInfo.fileId
                    });
                  }
                  if (
                    entityData.properties.find((property) => property._id == fileProperty._id)
                      ?.value &&
                    !form.properties[fileProperty._id]
                  ) {
                    const formData = new FormData();
                    formData.append('file', form.properties[fileProperty._id]);
                    formData.append('documentType', '1');
                    formData.append('isPublic', '0');
                    const filesInfo = (await fetchDeleteFile({
                      url: FILES_BY_ID.replace(
                        ':id',
                        entityData.properties.find((property) => property._id == fileProperty._id)
                          ?.value as string
                      ),
                      method: 'DELETE'
                    })) as FileDto;
                    // If delete fail
                    // if (filesInfo instanceof Error) {
                    //   return Promise.reject('error');
                    // }
                    // query.data.properties.push({
                    //   _id: fileProperty._id,
                    //   value: undefined
                    // });
                  }
                  if (
                    entityData.properties.find((property) => property._id == fileProperty._id)
                      ?.value &&
                    form.properties[fileProperty._id]
                  ) {
                    const formDataDelete = new FormData();
                    formDataDelete.append('file', form.properties[fileProperty._id]);
                    formDataDelete.append('documentType', '1');
                    formDataDelete.append('isPublic', '0');
                    const fileDeleteInfo = (await fetchDeleteFile({
                      url: FILES_BY_ID.replace(
                        ':id',
                        entityData.properties.find((property) => property._id == fileProperty._id)
                          ?.value as string
                      ),
                      method: 'DELETE'
                    })) as FileDto;
                    // if (fileDeleteInfo instanceof Error) {
                    //   return Promise.reject('error');
                    // }
                    const formData = new FormData();
                    formData.append('file', form.properties[fileProperty._id]);
                    formData.append('documentType', '1');
                    formData.append('isPublic', '0');
                    const filesInfo = (await fetchFile({
                      url: FILES,
                      method: 'POST',
                      data: formData,
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    })) as FileDto;
                    if (fileDeleteInfo instanceof Error) {
                      return Promise.reject('error');
                    }
                    query.data.properties.push({
                      _id: fileProperty._id,
                      value: filesInfo.fileId
                    });
                  }
                }
              })
            );
            filePropertiesPromise
              .then((data) => {
                if (query) {
                  fetchPatchOfEntity(query);
                }
              })
              .catch(() => {
                setToastNotifications([{ message: tResourcePanel('errors.savingFile') }]);
              });
          }
          break;
      }
    },
    [entityData, resourceId, properties, propertySwitch, multipleData]
  );

  const { fetch: fetchFile, error: errorImage } = useFetch<FileDto>();
  const { fetch: fetchDeleteFile } = useFetch<any>();

  useEffect(() => {
    if (errorImage) {
      setToastNotifications([{ message: tCommon('errors.uploadingImage') }]);
      setLoadingPatch(false);
    }
  }, [errorImage]);

  const { data: patchData, fetch: fetchPatchOfEntity, error: patchError } = useFetch<ResourceDto>();

  const [loadingPatch, setLoadingPatch] = useState<boolean>(false);

  useEffect(() => {
    onLoading(loadingPatch);
  }, [loadingPatch]);

  useEffect(() => {
    if (patchError) {
      if (patchError?.response?.data?.code == 'resource_not_found') {
        isMultiple
          ? setToastNotifications([
              {
                message: tCommon('errors.entitiesNotFound', {
                  entity: tCommon('entities.resources_other')
                })
              }
            ])
          : setToastNotifications([
              {
                message: tCommon('errors.entityNotFound', {
                  entity: tCommon('entities.resources_one')
                })
              }
            ]);
      } else if (patchError?.response?.data?.code == 'invalid_missions') {
        setToastNotifications([
          {
            message: tCommon('errors.entitiesNotFound', {
              entity: tCommon('entities.missions_one')
            })
          }
        ]);
      } else if (patchError?.response?.data?.code == 'invalid_work_rules') {
        setToastNotifications([
          {
            message: tCommon('errors.entitiesNotFound', {
              entity: tCommon('entities.work-rules_one')
            })
          }
        ]);
      } else if (patchError?.response?.data?.code == 'invalid_property') {
        setToastNotifications([{ message: tCommon('errors.invalidProperty') }]);
      } else if (patchError?.response?.data?.code == 'unique_property') {
        setToastNotifications([{ message: tCommon('errors.uniqueProperty') }]);
      } else if (
        patchError?.response?.data?.params &&
        patchError.response.data.code == 'required_property'
      ) {
        for (const param of patchError.response.data.params) {
          setError(`properties.${param}`, {
            message: tCommon('errors.propertyRequired'),
            type: 'manual'
          });
        }
      } else if (
        patchError?.response?.data?.params &&
        patchError.response.data.code == 'switched_off_property'
      ) {
        for (const param of patchError.response.data.params) {
          setError(`properties.${param}`, {
            message: tCommon('errors.switchOffProperty'),
            type: 'manual'
          });
        }
      } else if (
        patchError?.response?.data?.params &&
        patchError.response.data.code == 'duplicate_unique_property'
      ) {
        for (const param of patchError.response.data.params) {
          setError(`properties.${param}`, {
            message: tCommon('errors.duplicateProperty'),
            type: 'manual'
          });
        }
      } else if (
        patchError?.response?.data?.params &&
        patchError.response.data.code == 'invalid_property_value'
      ) {
        for (const param of patchError.response.data.params) {
          setError(`properties.${param}`, {
            message: tCommon('errors.invalidValue'),
            type: 'manual'
          });
        }
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.actionError', {
              action:
                mode === CrudModes.CREATE
                  ? tCommon('actions.creation')
                  : tCommon('actions.edition'),
              entity: isMultiple
                ? tCommon('entities.resources_other')
                : tCommon('entities.resources_one')
            })
          }
        ]);
      }
      setLoadingPatch(false);
    } else if (patchData) {
      setLoadingPatch(false);
      onSuccess(patchData as ResourceDto);
    }
  }, [patchData, patchError]);

  const [value, setValue] = useState('1');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const containerRef = useRef(null);

  useEffect(() => {
    onDirtyFields(Object.keys(formState.dirtyFields).length > 0, tCommon('entities.resources_one'));
  }, [formState]);

  return (
    <Container sx={{ overflow: 'hidden' }} ref={containerRef}>
      <Container>
        <HeaderModalContainer>
          <Typography
            color={theme.palette.common.white}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            gap={'0.2em'}
            variant="h5">
            {loading || loadingSwitches || transitionLoading ? (
              <Skeleton width={'50%'} sx={{ bgcolor: 'grey.700' }} />
            ) : isMultiple ? (
              tResourcePanel('recordTab.title.editMulti', { n: resourceId?.length || 0 })
            ) : (
              tResourcePanel(`title.${mode}`, {
                type: entityData ? entityData.type.label : resourceType?.label
              })
            )}
          </Typography>
        </HeaderModalContainer>
        <BodyModalContainer sx={{ padding: '0rem 2rem', overflow: 'hidden' }}>
          <RenderIf condition={loading || loadingSwitches || transitionLoading || loadingMultiple}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress />
            </Box>
          </RenderIf>
          <RenderIf
            condition={!(loading || loadingSwitches || transitionLoading || loadingMultiple)}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  variant="fullWidth"
                  sx={{ height: '4em !important' }}>
                  <Tab
                    label={tCommon('entities.properties_other')}
                    iconPosition="start"
                    icon={<FaTags size={16}></FaTags>}
                    value="1"
                  />
                  <Tab
                    label={tCommon('entities.resourcerecords_other')}
                    iconPosition="start"
                    disabled={mode === CrudModes.CREATE && !resourceId}
                    icon={<FaPaperclip size={16}></FaPaperclip>}
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ padding: '24px 0 24px 0', overflow: 'auto' }}>
                {filteredMultiProperties && isMultiple && (
                  <>
                    <PropertiesForm
                      initialValues={initialValues}
                      isMultipleEntity={isMultiple}
                      properties={filteredMultiProperties}
                      control={control}
                      formState={formState}
                    />
                    <div
                      style={{
                        paddingTop: '0.5rem',
                        width: '100%',
                        display: 'grid',
                        flexDirection: 'column',
                        gap: '1rem'
                      }}>
                      <Controller
                        control={control}
                        name={'missions'}
                        render={({ field }) => (
                          <MultipleInputRow
                            fitInput
                            initialValue={initialValues?.missions}
                            value={field.value}
                            onChange={field.onChange}>
                            <SetAutocomplete
                              includeMultipleObj
                              label={tCommon('entities.missions_other')}
                              URIReplace={(referencedSet) =>
                                LABELS_SEARCH.replace(':entity', referencedSet)
                              }
                              getOptions={(response: LabeledReference[]) => response}
                              referencedSet={Entities.MISSIONS}
                              id="missions"
                              name={`${Entities.MISSIONS}_name`}
                              autoComplete={AutoCompleteField}
                              value={field.value as LabeledReference | null | undefined}
                              onChange={field.onChange}
                            />
                          </MultipleInputRow>
                        )}
                      />
                      <Controller
                        control={control}
                        name={'workRules'}
                        render={({ field }) => (
                          <MultipleInputRow
                            fitInput
                            initialValue={initialValues?.workRules}
                            value={field.value}
                            onChange={field.onChange}>
                            <SetAutocomplete
                              includeMultipleObj
                              label={tCommon('entities.work-rules_other')}
                              URIReplace={(referencedSet) =>
                                LABELS_SEARCH.replace(':entity', referencedSet)
                              }
                              name={`${Entities.WORK_RULES}_name`}
                              id="workRules"
                              autoComplete={AutoCompleteField}
                              getOptions={(response: LabeledReference[]) => response}
                              referencedSet={Entities.WORK_RULES}
                              value={field.value as LabeledReference | null | undefined}
                              onChange={field.onChange}
                            />
                          </MultipleInputRow>
                        )}
                      />
                    </div>
                  </>
                )}
                {properties && !isMultiple && propertySwitch && (
                  <>
                    <PropertiesForm
                      properties={filterPropsBySwitches(properties, propertySwitch)}
                      control={control}
                      formState={formState}
                      propertySwitches={propertySwitch}
                    />
                    <Grid container display={'grid'} gridTemplateColumns={'1fr'} width={'100%'}>
                      <Controller
                        control={control}
                        name={'missions'}
                        render={({ field }) => (
                          <Grid width={'100%'} margin={'0.5rem 0'}>
                            <SetAutocomplete
                              label={tCommon('entities.missions_other')}
                              URIReplace={(referencedSet) =>
                                LABELS_SEARCH.replace(':entity', referencedSet)
                              }
                              name={`${Entities.MISSIONS}_name`}
                              autoComplete={AutoCompleteField}
                              getOptions={(response: LabeledReference[]) => response}
                              referencedSet={Entities.MISSIONS}
                              value={field.value as LabeledReference | null | undefined}
                              onChange={field.onChange}
                            />
                          </Grid>
                        )}
                      />
                      <Controller
                        control={control}
                        name={'workRules'}
                        render={({ field }) => (
                          <Grid width={'100%'} margin={'0.5rem 0'}>
                            <SetAutocomplete
                              label={tCommon('entities.work-rules_other')}
                              URIReplace={(referencedSet) =>
                                LABELS_SEARCH.replace(':entity', referencedSet)
                              }
                              name={`${Entities.WORK_RULES}_name`}
                              autoComplete={AutoCompleteField}
                              getOptions={(response: LabeledReference[]) => response}
                              referencedSet={Entities.WORK_RULES}
                              value={field.value as LabeledReference | undefined}
                              onChange={field.onChange}
                            />
                          </Grid>
                        )}
                      />
                    </Grid>
                  </>
                )}
              </TabPanel>
              <TabPanel
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  padding: '24px 0 0 0'
                }}
                value="2">
                {entityData && (
                  <RecordListView
                    isActive={value === '2'}
                    resource={entityData}
                    openRecord={(mode, document, recordId, onSuccess) => {
                      if (mode === CrudModes.CREATE) {
                        slide(
                          <DocumentSelectionView
                            title={tResourcePanel('recordTab.title.create')}
                            onCancel={() => {
                              removeSlide(false, '', 1);
                            }}
                            onSuccess={(newDocument) => {
                              slide(
                                <RecordEditionView
                                  mode={mode}
                                  document={newDocument}
                                  recordId={recordId}
                                  resource={entityData}
                                  closeAction={() => {
                                    removeSlide(false, tCommon('entities.resourcerecords_one'));
                                  }}
                                  onDirtyFields={(isDirty) =>
                                    onDirtyFields(isDirty, tCommon('entities.resourcerecords_one'))
                                  }
                                  sucessAction={(record) => onSuccess(record)}
                                />,
                                'right',
                                { modalName: tCommon('entities.resourcerecords_one') }
                              );
                              setTimeout(() => {
                                removeSlide(false, '', 1);
                              }, DelayTimes.SLIDE_TRANSITION);
                            }}
                          />,
                          'left'
                        );
                      }
                      if (mode === CrudModes.EDIT && document) {
                        slide(
                          <RecordEditionView
                            mode={mode}
                            document={document}
                            recordId={recordId}
                            resource={entityData}
                            closeAction={() => {
                              removeSlide(false, tCommon('entities.resourcerecords_one'));
                            }}
                            onDirtyFields={(isDirty) =>
                              onDirtyFields(isDirty, tCommon('entities.resourcerecords_one'))
                            }
                            sucessAction={(record) => onSuccess(record)}
                          />,
                          'left',
                          { modalName: tCommon('entities.resourcerecords_one') }
                        );
                      }
                    }}
                  />
                )}
                {isMultiple && (
                  <Button
                    sx={{ position: 'absolute', right: '0', bottom: '1rem' }}
                    variant="contained"
                    onClick={() => {
                      slide(
                        <DocumentSelectionView
                          title={tResourcePanel('recordTab.title.createMulti', {
                            quantity: resourceId.length
                          })}
                          onCancel={() => removeSlide()}
                          onSuccess={(newDocument) => {
                            setTimeout(() => {
                              slide(
                                <RecordEditionView
                                  mode={CrudModes.CREATE}
                                  document={newDocument}
                                  recordId={undefined}
                                  resourceIds={resourceId as string[]}
                                  closeAction={() => {
                                    removeSlide(false, tCommon('entities.resourcerecords_one'));
                                  }}
                                  onDirtyFields={(isDirty) =>
                                    onDirtyFields(isDirty, tCommon('entities.resourcerecords_one'))
                                  }
                                  sucessAction={() => {
                                    return;
                                  }}
                                />,
                                'right',
                                { modalName: tCommon('entities.resourcerecords_one') }
                              );
                            }, DelayTimes.SLIDE_TRANSITION);
                          }}
                        />,
                        'left'
                      );
                    }}>
                    {tResourcePanel('recordTab.buttons.createMulti')}
                  </Button>
                )}
              </TabPanel>
            </TabContext>
          </RenderIf>
        </BodyModalContainer>
        <FooterModalContainer
          sx={value == '1' && CrudModes.CREATE == mode ? { justifyContent: 'space-between' } : {}}>
          <RenderIf condition={value == '1' && CrudModes.CREATE == mode}>
            <PresetButton
              disabled={loadingPatch || !!errorDefaultValues}
              loading={
                loadingDefaultValues ||
                loading ||
                loadingSwitches ||
                transitionLoading ||
                loadingMultiple
              }
              entity={Entities.RESOURCES}
              onSelect={changeDefaultValues}></PresetButton>
          </RenderIf>

          <ButtonsContainer>
            <Button
              disabled={loadingPatch || loading || loadingSwitches || transitionLoading}
              onClick={(e) => onCancel(Object.keys(formState.dirtyFields).length > 0)}>
              {tCommon('buttons.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loadingPatch}
              disabled={
                loading ||
                loadingSwitches ||
                transitionLoading ||
                Object.keys(formState.errors).length > 0 ||
                (mode === CrudModes.EDIT && Object.keys(formState.dirtyFields).length === 0)
              }
              onClick={handleSubmit(submit, (errors) => toScroll(errors))}>
              {tCommon('buttons.save')}
            </LoadingButton>
          </ButtonsContainer>
        </FooterModalContainer>
      </Container>
    </Container>
  );
};

export default ResourcePanelTemplate;
