import { SETS_FIND_BY_NAME } from '@/const';
import { SystemSets } from '@/const/sets';
import { useGet, useResourceReducer } from '@/hooks';
import { SetDto } from '@/types';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { JobEventForm } from '../../JobEventPanelTemplate';
import ResourceSlot from '../ResourceSlot/ResourceSlot';
import { makeInitialState } from './Resources.const';

export type ResourcesProps = {
  isDisabled?: boolean;
};

const Resources = ({ isDisabled = false }: ResourcesProps) => {
  const { control, getValues, setError, clearErrors } = useFormContext<JobEventForm>();
  const [state, dispatch] = useResourceReducer(makeInitialState(getValues('resourceSlots')));
  useEffect(() => {
    const resourceIds = Object.keys(state.resourceDictionary);
    resourceIds.map((resourceId) => {
      const positions = state.resourceDictionary[resourceId];
      if (positions.length > 1) {
        positions.forEach((position) => {
          setError(`resourceSlots.${position.slotIndex}.resources.${position.resourceIndex}`, {
            type: 'duplicated',
            message: 'Duplicated resource ' + position.slotIndex + ' ' + position.resourceIndex
          });
        });
      } else {
        positions.forEach((position) => {
          clearErrors(`resourceSlots.${position.slotIndex}.resources.${position.resourceIndex}`);
        });
      }
    });
  }, [state]);

  const { data: roles } = useGet<SetDto>({
    url: SETS_FIND_BY_NAME.replace(':setName', SystemSets.Roles),
    method: 'GET'
  });

  const resourceSlots = useWatch({
    control,
    name: `resourceSlots`
  });

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="0.5rem" width="100%">
      {resourceSlots?.map((resourceSlot, index) => {
        return (
          <ResourceSlot
            key={resourceSlot.id}
            resourceSlot={resourceSlot}
            disabled={isDisabled}
            roles={roles?.items || []}
            dispatch={dispatch}
            index={index}
          />
        );
      })}
    </Box>
  );
};

export default Resources;
