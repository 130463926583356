import { styled } from '@mui/material';

export const CardContainer = styled('div')`
  width: 100%;
  min-height: 3.125rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding-inline: 0.7rem;
`;
