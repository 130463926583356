import { useModalConfirmationContext } from '@/context/ModalConfirmationContext';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type UseConfirmDeletionProps = {
  confirmButtonText?: string;
};

export const useConfirmDeletion = (props?: UseConfirmDeletionProps) => {
  const { showConfirmation } = useModalConfirmationContext();
  const { t: tCommon } = useTranslation('common');

  const handleConfirmation = async (body: string, note?: string | ReactNode) => {
    const result: boolean = await showConfirmation({
      title: tCommon('modalConfirmation.title'),
      message: (
        <>
          <Typography>{body}</Typography>
          <Typography mt="1rem">{note}</Typography>
        </>
      ),
      confirmButton: props?.confirmButtonText || tCommon('buttons.delete'),
      cancelButton: tCommon('buttons.cancel')
    });
    return result;
  };
  return { handleConfirmation };
};
