import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
  Typography
} from '@mui/material';

export const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 !important;
  min-height: 0 !important;
  flex-direction: row-reverse;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.grey[100]};
  & > * {
    margin: 0.5rem 0.6rem 0.5rem 0 !important;
  }
  & > .MuiAccordionSummary-expandIconWrapper {
    margin: 0.5rem 0.6rem 0.5rem ${({ theme }) => theme.spacing(1)} !important;
  }
`;

export const Accordion = styled(MuiAccordion)`
  width: 100%;
  flex: 1;
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const FormHelperTextError = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main} !important;
`;
