import { LabeledReference, MultipleInputValue, Properties } from '@/types';

export enum SelectedSeries {
  none = 'none',
  distributed = 'distributed',
  dated = 'dated'
}

export type JobFormDto = Properties & {
  curriculum: LabeledReference | MultipleInputValue | null;
  selectedSeries?: SelectedSeries;
  distributed: { count: number };
  dated: { date: string; count: number }[];
};
