import { InputBox, RenderIf } from '@/components/atoms';
import { FILES_URL_BY_ID } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { theme } from '@/theme';
import { Avatar, Badge, FormHelperText, Grid, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFileImage, FaTrash } from 'react-icons/fa';

type ImageInputProps = {
  size?: number;
  value?: string;
  disabled?: boolean;
  label: string;
  error?: boolean;
  helperText: string;
  onChange: (newImage?: string | File) => unknown;
};

const ImageInput = ({
  size = 12,
  label,
  onChange,
  value,
  disabled,
  error,
  helperText
}: ImageInputProps) => {
  const { t: tCommon } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | string | Error>();
  const [dirty, setDirty] = useState<boolean>(false);
  const { fetch: fetchImage, data: dataImage, error: errorImage } = useFetch<string | Error>();

  const { setToastNotifications } = useToastContext();

  useEffect(() => {
    if (errorImage) {
      setToastNotifications([{ message: tCommon('errors.loadingImage') }]);
    }
  }, [errorImage]);

  useEffect(() => {
    if (value) {
      fetchImage({
        url: FILES_URL_BY_ID.replace(':id', value)
      });
    }
  }, []);

  useEffect(() => {
    if (dataImage) {
      setImage(dataImage);
    } else if (errorImage) {
      setImage(errorImage as Error);
    }
  }, [dataImage, errorImage]);

  useEffect(() => {
    if (dirty && typeof image != 'string' && !(image instanceof Error)) {
      onChange(image);
    }
  }, [image, dirty]);

  const uploadImage = (e) => {
    inputRef.current?.click();
  };

  const handleImageFile = (e) => {
    setImage(e.target.files[0]);
    setDirty(true);
  };

  const removeImage = (e) => {
    setImage(undefined);
    setDirty(true);
  };

  return (
    <InputBox sx={{ height: 'fit-content' }} disabled={disabled} label={label} error={error}>
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Badge
          sx={{}}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            image ? (
              <IconButton
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': { backgroundColor: theme.palette.black[100] },
                  fontSize: (size * 3) / 2
                }}
                onClick={removeImage}>
                <FaTrash color={theme.palette.common.white}></FaTrash>
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': { backgroundColor: theme.palette.black[100] },
                  fontSize: (size * 3) / 2
                }}
                onClick={uploadImage}>
                <FaRegFileImage color={theme.palette.common.white}></FaRegFileImage>
              </IconButton>
            )
          }>
          <Avatar
            src={
              image && !(image instanceof Error)
                ? typeof image == 'string'
                  ? image
                  : URL.createObjectURL(image)
                : ''
            }
            sx={{
              width: `${size / 2}rem`,
              height: `${size / 2}rem`,
              border: `solid ${theme.palette.grey[400]} 1px`
            }}
          />
        </Badge>
        <input
          accept="image/png, image/jpeg"
          ref={inputRef}
          onChange={handleImageFile}
          type="file"
          hidden></input>
        <RenderIf condition={!!helperText}>
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {tCommon(`errors.${helperText}` as any, { size: 2 })}
          </FormHelperText>
        </RenderIf>
      </Grid>
    </InputBox>
  );
};

export default ImageInput;
