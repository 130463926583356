import '@/const/locales';
import { GlobalStyles } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider, enUS } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import { ToastNotification } from './components/molecules';
import './config/axios';
import { locale } from './const';
import { AuthContextProvider } from './context/AuthContext';
import FetchContextProvider from './context/FetchContext';
import SocketContextProvider from './context/SocketContext';
import ToastContextProvider from './context/ToastContext';
import i18n from './i18n';
import { GlobalStyle, theme } from './theme';
const root = document.getElementById('root');

// LocaleHelper.publishLocale('EnGB');

// Sentry.init({
//   dsn: 'https://d7b21874ad9d55e236e122cef4df14a1@o4508287021285376.ingest.us.sentry.io/4508287024037888',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.createRoot(root as HTMLElement).render(
  // <React.StrictMode>

  <SocketContextProvider>
    <AuthContextProvider>
      <DndProvider backend={HTML5Backend}>
        <I18nextProvider i18n={i18n}>
          <FetchContextProvider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  dateAdapter={AdapterLuxon}
                  adapterLocale={locale}
                  localeText={enUS.components.MuiLocalizationProvider.defaultProps.localeText}>
                  <ToastContextProvider>
                    <ToastNotification />
                    <GlobalStyles styles={GlobalStyle} />
                    <App />
                  </ToastContextProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </FetchContextProvider>
        </I18nextProvider>
      </DndProvider>
    </AuthContextProvider>
  </SocketContextProvider>
  // </React.StrictMode>
);
