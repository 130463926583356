import { Entity } from '@/const';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import { PropertyDto } from '@/types';
import { Ref, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalFilter, { GlobalFilterProps } from '../GlobalFilter';

export type EntityFilterProps = {
  entity: Entity;
  propertiesRef: Ref<HTMLButtonElement>;
  extraFields?: GlobalFilterProps['fields'];
} & Omit<GlobalFilterProps, 'loading' | 'error' | 'fields' | 'filterRef'>;

const EntityFilter = ({ entity, extraFields, propertiesRef, ...props }: EntityFilterProps) => {
  const { loading, properties: data, error } = useGetPropertiesByEntity<PropertyDto[]>(entity);
  const { t } = useTranslation();

  const fields = useMemo(() => {
    let copy: GlobalFilterProps['fields'] = [];
    if (data) {
      copy = [...copy, ...data];
      if (extraFields && extraFields.length > 0) {
        copy = [...copy];
        extraFields.forEach((field) => {
          if (field.order !== undefined && field.order >= 0) {
            copy.splice(field.order, 0, field);
          } else {
            copy.push(field);
          }
        });
      }
      return copy;
    }
    return [];
  }, [extraFields, data]);

  return (
    <GlobalFilter
      entity={entity}
      propertiesRef={propertiesRef}
      filterRef={propertiesRef}
      loading={loading}
      error={!!error}
      helperText={
        error
          ? t('errors.defaultError')
          : fields.length === 0
          ? t('errors.noRegisteredProperties')
          : undefined
      }
      fields={fields}
      {...props}
    />
  );
};

export default EntityFilter;
