import { DefaultRowType } from 'types';

export const addElementWithId = <T extends DefaultRowType>(list: T[], element: T, id?: T['id']) => {
  const copyList = [...list];
  const idx = copyList.findIndex(({ id: idITem }) => id === idITem);
  copyList.splice(idx || list.length, 0, element);
  return list;
};

export const includeValues = <T>(A: T[], B: T[]): boolean => {
  const setB = new Set(B);
  for (const value of A) {
    if (!setB.has(value)) {
      return false;
    }
  }

  return true;
};

export const objectToArray = (obj: Object) => {
  return Object.keys(obj).map((id) => obj[id]);
};

export const arrayToObjectId = (
  array: { [key: string]: any }[],
  id: any,
  aditional?: { [key: string]: any }
) => {
  return array.reduce((_obj: { [key: string]: any }, item: { [key: string]: any }) => {
    if (!_obj[item[id]]) {
      _obj[item[id]] = { ...item, ...aditional };
    }
    return _obj;
  }, {});
};

export const arrayToObjectChildren = (
  Array: { [key: string]: any }[],
  id: any,
  nameChildren: string
) => {
  return Array.reduce((_obj: { [key: string]: any }, item: { [key: string]: any }) => {
    if (!_obj[item[id]]) {
      _obj[item[id]] = { ...item, [nameChildren]: [] };
    }
    _obj[item[id]][nameChildren].push(item);
    return _obj;
  }, {});
};

export const arrayToObjectChildrenV2 = (
  Array: { [key: string]: any }[],
  id: any,
  nameChildren: string
) => {
  return Array.reduce((_obj: { [key: string]: any }, item: { [key: string]: any }) => {
    if (!_obj[item[id]]) {
      _obj[item[id]] = { [nameChildren]: [] };
    }
    _obj[item[id]][nameChildren].push(item);
    return _obj;
  }, {});
};

export const editElementWithId = <T extends DefaultRowType>(
  list: T[],
  newElement: Partial<T>,
  id: T['id']
) => {
  const copyList = [...list];
  const idx = copyList.findIndex(({ id: idITem }) => id === idITem);
  copyList[idx] = { ...copyList[idx], ...newElement };
  return copyList;
};

export const removeElementWithId = <T extends DefaultRowType>(list: T[], id: T['id']) => {
  const copyList = [...list];
  const idx = copyList.findIndex(({ id: idITem }) => id === idITem);
  copyList.splice(idx, 1);
  return copyList;
};

export const orderByProperties = <T>(arr: T[], properties: (keyof T)[]): T[] => {
  return [...arr].sort((a, b) => {
    let comparison = 0;
    for (const property of properties) {
      if (comparison === 0) {
        const aValue = a[property];
        const bValue = b[property];

        if (aValue < bValue) {
          comparison = -1;
        } else if (aValue > bValue) {
          comparison = 1;
        }
      } else {
        break;
      }
    }
    return comparison;
  });
};
