import { useCallback, useContext, useMemo } from 'react';
import FilterContext, { settingsType, ValueOf } from './FilterContext';

export const useFilterContext = (key: string) => {
  const {
    fullText,
    masterDetail,
    handleMasterDetail,
    getSetting,
    handleSettings,
    handleFullText,
    size,
    setSize
  } = useContext(FilterContext);

  const settings = useMemo(() => getSetting(key), [getSetting, key]);

  const setSettings = useCallback(
    (
      newSettings:
        | ValueOf<settingsType>
        | ((settings?: ValueOf<settingsType>) => ValueOf<settingsType>)
    ) => {
      handleSettings(key, newSettings);
    },
    [key]
  );

  return {
    settings,
    fullText,
    masterDetail,
    setMasterDetail: handleMasterDetail,
    setFullText: handleFullText,
    setSettings,
    size,
    setSize
  };
};
