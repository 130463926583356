import { Transition } from '@/components/atoms';
import { DelayTimes } from '@/const';
import useWindowUnloadEffect from '@/hooks/useWindowOnLoad';
import { Container, theme } from '@/theme';
import { Dialog, Grid, Portal, Slide } from '@mui/material';
import { isArray } from 'lodash';
import {
  CSSProperties,
  ComponentType,
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useModalConfirmationContext } from '../ModalConfirmationContext';
import GenericModalContext from './GenericModalContext';

export type GenericModalContextProviderProps = PropsWithChildren<unknown>;

export type PropertiesGenericModal = {
  content: JSX.Element | ComponentType<any>;
  modalStyle: {
    style: CSSProperties;
  };
  direction: 'right' | 'left';
  visible: boolean;
  modalName?: string;
  deprecated?: boolean;
  isDirty?: boolean;
};

const defaultModalStyle = {
  height: '90vh',
  width: '55vw',
  minWidth: '28rem',
  minHeight: '26rem',
  maxWidth: '88rem',
  maxHeight: '90rem',
  borderRadius: '16px'
};

const GenericModalContextProvider = ({ children }: GenericModalContextProviderProps) => {
  const refDialog = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const [isLocalDirty, setIsLocalDirty] = useState<boolean>(false);

  const { showConfirmation } = useModalConfirmationContext();
  const [preventFromClose, setPreventFromClose] = useState<boolean>(false);
  const [properties, setProperties] = useState<PropertiesGenericModal[]>([]);

  const getClosePreventionModal = (tempModalName: string | string[]) =>
    showConfirmation({
      title: t('modalConfirmation.title'),
      message:
        (isArray(tempModalName) && tempModalName.length > 1
          ? t('modalConfirmation.messageWMultiModals', { entities: tempModalName })
          : t('modalConfirmation.messageWEntity', {
              entityName: isArray(tempModalName) ? tempModalName[0] : tempModalName
            })) +
        '\n\n' +
        t('modalConfirmation.leave_simple'),
      isReactNode: false,
      confirmButton: t('buttons.leave'),
      cancelButton: t('buttons.cancel')
    });

  const initialize = (
    content: JSX.Element | ComponentType<any>,
    modalProps?: {
      style?: CSSProperties;
      modalName?: string;
    }
  ) => {
    setIsLocalDirty(false);
    setPreventFromClose(false);
    setProperties((last) => {
      return [
        {
          content,
          modalStyle: { style: modalProps?.style ?? defaultModalStyle },
          direction: 'right',
          visible: true,
          isDirty: false,
          modalName: modalProps?.modalName
        }
      ];
    });
    setIsOpen(true);
  };

  const removeSlide = useCallback(
    async (isDirty?: boolean, modalName?: string, idx?: number) => {
      const updatePropertyVisibility = () => {
        setProperties((oldSlides) => {
          const lastItemIndex = idx ?? oldSlides.length - 1;
          const updatedProperty = [...oldSlides];
          updatedProperty[lastItemIndex] = {
            ...updatedProperty[lastItemIndex],
            visible: false,
            isDirty: false,
            deprecated: true
          };
          return updatedProperty;
        });
      };

      if (isDirty === true) {
        const tempModalName =
          modalName ||
          properties
            .filter((modal) => !!modal?.isDirty && !!modal?.modalName)
            .map((slide) => slide.modalName as string);
        const shouldContinue = await getClosePreventionModal(tempModalName);
        if (shouldContinue) {
          updatePropertyVisibility();
          setIsLocalDirty(false);
        }
      } else {
        updatePropertyVisibility();
      }
    },
    [properties]
  );

  const slide = (
    content: JSX.Element | ComponentType<any>,
    direction: 'left' | 'right',
    modalProps?: {
      style?: CSSProperties;
      modalName?: string;
    }
  ) => {
    setProperties((lastSlides) =>
      lastSlides
        .filter((modal) => !modal?.deprecated)
        .concat({
          content,
          modalStyle: modalProps?.style
            ? { style: modalProps.style }
            : { style: defaultModalStyle },
          direction,
          modalName: modalProps?.modalName,
          isDirty: false,
          visible: true
        })
    );
    setIsOpen(true);
  };

  const handleClose = useCallback(
    async (isDirty?: boolean, modalName?: string) => {
      if (!preventFromClose) {
        const tempModalName =
          modalName ||
          properties
            .filter((modal) => !!modal?.isDirty && !!modal?.modalName)
            .map((slide) => slide.modalName as string);
        if (isDirty === true) {
          const continueOnClose = await getClosePreventionModal(tempModalName);
          if (continueOnClose) {
            setIsOpen(false);
          }
          return;
        } else {
          setIsOpen(false);
        }
      }
    },
    [preventFromClose, properties]
  );

  useEffect(() => {
    if (isOpen === false) setIsLocalDirty(false);
  }, [isOpen]);

  const handleDirtyModal = (isDirty: boolean, modalName?: string) => {
    setProperties((oldSlides) => {
      const activeSlideIndex = oldSlides
        .slice()
        .reverse()
        .findIndex((slide) => {
          if (modalName) return slide.modalName === modalName && !slide.deprecated;
          return slide.visible && !slide?.deprecated;
        });
      if (activeSlideIndex !== -1) {
        const originalIndex = oldSlides.length - 1 - activeSlideIndex;
        if (oldSlides[originalIndex].isDirty !== isDirty) {
          oldSlides[originalIndex].isDirty = isDirty;
          return [...oldSlides];
        }
      }

      return oldSlides;
    });
  };

  const isSomeSlideDirty: boolean = useMemo(
    () =>
      properties.length > 0 &&
      properties.some((slide) => !!slide.isDirty && !slide.deprecated) &&
      isOpen,
    [properties, isOpen]
  );

  useWindowUnloadEffect((e?: BeforeUnloadEvent) => {
    if (isSomeSlideDirty) {
      e?.preventDefault();
      const message = t('modalConfirmation.leave');
      if (e) e.returnValue = message;
      return message;
    }
  }, isSomeSlideDirty);

  return (
    <GenericModalContext.Provider
      value={{
        isOpen,
        handleClose,
        handleDirtyModal,
        preventFromClose: setPreventFromClose,
        initialize,
        removeSlide,
        slide
      }}>
      {children}
      {properties.length > 0 ? (
        <Dialog
          TransitionComponent={Transition}
          open={isOpen}
          onClose={(
            event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement, MouseEvent>
          ) => {
            event.preventDefault();
            handleClose(isSomeSlideDirty);
          }}
          disableEnforceFocus
          sx={{ zIndex: '1100' }}
          PaperProps={{
            ...properties[properties.length - 1].modalStyle,
            sx: { overflow: 'hidden' }
          }}>
          <Grid ref={refDialog} height={'100%'} display={'flex'} flexDirection={'row'}>
            <Grid container sx={{ padding: '0', flexDirection: 'column', height: '100%' }}>
              {properties[0].content as ReactNode}
            </Grid>
            <Portal container={refDialog.current}>
              {properties.map((property, index) => {
                if (index > 0) {
                  return (
                    <Slide
                      container={refDialog.current}
                      key={index}
                      direction={property.direction}
                      in={property.visible}
                      easing={'linear'}
                      timeout={DelayTimes.SLIDE_TRANSITION}
                      mountOnEnter
                      unmountOnExit>
                      <Container
                        sx={{ position: 'absolute', backgroundColor: theme.palette.common.white }}>
                        {property.content as ReactNode}
                      </Container>
                    </Slide>
                  );
                }
              })}
            </Portal>
          </Grid>
        </Dialog>
      ) : (
        <></>
      )}
    </GenericModalContext.Provider>
  );
};

export default GenericModalContextProvider;
