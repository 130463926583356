import { ButtonsContainer, theme } from '@/theme';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { FC } from 'react';
import { FilterProps } from './Filter.types';

const Filter: FC<FilterProps> = ({ open, toggleFilter, refs, children, title }) => {
  return (
    <Drawer
      disableEnforceFocus
      open={open}
      sx={{
        width: '100%',
        zIndex: '1100',
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: '400px' }
      }}
      anchor={'right'}
      onClose={toggleFilter}
      // keepMounted={true}
    >
      <Box
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          paddingInline: '0.6rem',
          minHeight: '48px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: '0',
          zIndex: '1203'
        }}>
        <Typography>{title}</Typography>
        <ButtonsContainer>
          <Button sx={{ color: theme.palette.common.white }} onClick={toggleFilter}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              refs.forEach((ref) => {
                setTimeout(() => {
                  ref?.current?.click();
                }, 10);
              });
            }}
            variant="contained">
            Apply
          </Button>
        </ButtonsContainer>
      </Box>
      <Box sx={{ overflowY: 'auto' }}>{children}</Box>
    </Drawer>
  );
};

export default Filter;
