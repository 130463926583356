import { SystemProperties } from '@/const';
import { ResourceDto } from '@/types';

export const transformResource = ({ properties, _id }: ResourceDto) => {
  const firstName =
    (properties.find((property) => property._id === SystemProperties.RESOURCES_FIRST_NAME)
      ?.value as string) || '';
  const name =
    (properties.find((property) => property._id === SystemProperties.RESOURCES_NAME)
      ?.value as string) || '';
  const id =
    (properties.find((property) => property._id === SystemProperties.RESOURCES_ID)
      ?.value as string) || '';
  const picture = properties.find((property) => property._id === SystemProperties.RESOURCES_PICTURE)
    ?.value as string;
  const label = `${firstName} ${name} (${id})`;

  return { _id, label, picture };
};
