import { Alert as MuiAlert } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Alert = styled(MuiAlert)`
  padding: ${({ theme }) => theme.spacing(1)};

  & > .MuiAlert-message {
    padding: 1px 0;
  }

  & > .MuiAlert-icon {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    padding: 0;
  }
`;
