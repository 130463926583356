import { theme } from '@/theme';
import { Typography, styled } from '@mui/material';

export const ToolbarMenu = styled('div')`
  position: relative;
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border: 1px solid ${theme.palette.divider};
  border-top: 0px;
  background-color: ${theme.palette.grey[100]};
  z-index: 500;
  align-items: center;
`;

export const TitleSection = styled(Typography)`
  padding: 0px 5px;
  width: 100%;
  border-block: 1px ${theme.palette.divider} solid;
  text-align: center;
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;
