import { RenderIf } from '@/components/atoms';
import {
  FinishedErrorStates,
  FinishedOKStates,
  RunningStates,
  ScheduleProcessStatus
} from '@/const';
import { truncate } from '@/services/utils/utils';
import { theme } from '@/theme';
import { Tooltip, Typography } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillStepForwardStyled, Result } from './BoxProgress.styles';

export type BoxProgressProps = {
  percentage: number | string;
  initialTime?: Duration;
  state: string;
  detailedState?: string;
  period?: number;
  assigned: { x: number; y: number };
  totalPeriods?: number;
  title?: string
};

const BoxProgress = ({
  initialTime,
  percentage,
  state,
  detailedState,
  period,
  assigned,
  totalPeriods,
  title
}: BoxProgressProps) => {
  const { t } = useTranslation('templates/scheduleResultTemplate');
  const { t: tMenu } = useTranslation('organisms/scheduleProcessMenuItem');
  const [elapsedTime, setElapsedTime] = useState(initialTime);
  const stateRef = useRef(state);
  const initialTimeRef = useRef(initialTime);
  const currentDateRef = useRef(DateTime.now());

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    initialTimeRef.current = initialTime;
    currentDateRef.current = DateTime.now();
    setElapsedTime(initialTime);
  }, [initialTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (RunningStates.includes(stateRef.current) && initialTimeRef.current) {
        const diff = DateTime.now().diff(currentDateRef.current);
        setElapsedTime(diff.plus(initialTimeRef.current.toMillis()));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getBackgroundcolor = (state: string) => {
    state = state?.toUpperCase() ?? '';
    if (RunningStates.includes(state)) {
      return theme.palette.warning.light;
    }
    if (FinishedOKStates.includes(state)) {
      return theme.palette.success.light;
    }
    if (FinishedErrorStates.includes(state)) {
      return theme.palette.error.light;
    }
    return theme.palette.grey[100];
  };

  return (
    <Result backgroundcolor={getBackgroundcolor(state)}>
      <Tooltip title={title}>
        <Typography variant='h6' align='center' alignItems={'center'}>
            {title?.substring(0,10)}
        </Typography>
      </Tooltip>
      <RenderIf condition={state == 'INTERRUPTED' || state == ScheduleProcessStatus.STOPPED.name}>
        <AiFillStepForwardStyled />
      </RenderIf>
      {!!detailedState && (
        <Typography textAlign={'center'} fontSize="0.7rem">
          {tMenu(`status.${detailedState.toLowerCase()}` as any)}
        </Typography>
      )}
      <Typography variant="h4" align="center">
        {typeof percentage == 'number' &&
          t('periods.item.title', {
            percentage: truncate(percentage, 2) + '%'
          })}
        {typeof percentage == 'string' &&
          t('periods.item.title', {
            percentage: percentage
          })}
      </Typography>
      {!!period && (
        <Typography variant="body2" align="center">
          {!totalPeriods && t('periods.item.description', { item: period ?? 0 + 1 })}
          {totalPeriods &&
            t('periods.item.totalDescription', { item: period ?? 0 + 1, totalItems: totalPeriods })}
        </Typography>
      )}
      {assigned && assigned.y != -1 && (
        <Typography variant="body1" align="center" lineHeight="inherit">
          {t('periods.item.assigned', { x: assigned?.x, y: assigned?.y })}
        </Typography>
      )}
      <RenderIf condition={!!initialTime && !!state}>
        <Typography mt="0.4rem" fontSize="0.7rem" align="center" lineHeight="inherit">
          {elapsedTime &&
            tMenu('elapsedTime', {
              time: elapsedTime.toFormat('hh:mm:ss')
            })}
        </Typography>
      </RenderIf>
    </Result>
  );
};

export default BoxProgress;
