import { IconVariant, SessionIcon, TimeSessionIcon, TopicIcon } from '@/components/atoms';
import {
  ActionTypes,
  ScaleConstraints,
  useCurriculumSetupContext
} from '@/context/CurriculmSetupContext';
import { Topic } from '@/types';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { DragEventHandler, useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { FaCopy, FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { DragNDropComponents } from '../../CurriculumSetup.const';
import { Section, TitleSection, ToolbarMenu } from './Toolbar.styles';

export type ToolBarProps = {
  onDuplicate: () => unknown;
  onDelete: () => unknown;
  handleScale: (addition: number) => void;
  scale: number;
  sessionsCount: number;
};

const ToolBar = ({ onDuplicate, onDelete, handleScale, scale, sessionsCount }: ToolBarProps) => {
  const {
    selectedTopics,
    selectedSessions,
    readOnly,
    handleSelectedSessions,
    handleSelectedTopics,
    createNewElement
  } = useCurriculumSetupContext();

  const onDragStart: DragEventHandler<HTMLButtonElement> = useCallback(() => {
    if (selectedTopics.length > 0) {
      handleSelectedTopics({ type: ActionTypes.DELETE_ALL });
    }
    if (selectedSessions.length > 0) {
      handleSelectedSessions({ type: ActionTypes.DELETE_ALL });
    }
  }, [selectedTopics, selectedSessions]);

  const { t: tCommon } = useTranslation();
  const { t: tCurr } = useTranslation('organisms/curriculumSetup');

  const getName = useCallback(() => {
    if (selectedTopics.length > 0) {
      return tCurr('elements.topics', { count: selectedTopics.length });
    }
    if (selectedSessions.length > 0) {
      return tCurr('elements.sessions', { count: selectedSessions.length });
    }
    return '';
  }, [selectedTopics, selectedSessions]);

  const [, dragNewSession, previewSession] = useDrag(
    {
      item: { type: DragNDropComponents.NEW_SESION },
      type: DragNDropComponents.NEW_SESION,
      canDrag: (monitor) => !readOnly
    },
    [readOnly]
  );

  previewSession(getEmptyImage());

  const [, dragNewTimeSession, previewTimeSession] = useDrag(
    {
      item: { type: DragNDropComponents.NEW_TIME_SESSION },
      type: DragNDropComponents.NEW_TIME_SESSION,
      canDrag: (monitor) => !readOnly
    },
    [readOnly]
  );

  previewTimeSession(getEmptyImage());

  const newT = useCallback(
    () => createNewElement(DragNDropComponents.NEW_TOPIC) as Topic,
    [createNewElement]
  );

  const [, dragNewTopic, previewTopic] = useDrag(
    {
      item: {
        type: DragNDropComponents.NEW_TOPIC,
        topic: newT()
      },
      type: DragNDropComponents.NEW_TOPIC,
      canDrag: (monitor) => !readOnly
    },
    [readOnly, newT]
  );

  previewTopic(getEmptyImage());

  return (
    <ToolbarMenu onClick={(e) => e.stopPropagation()}>
      <Section>
        <TitleSection sx={{ borderTop: '0 !important' }} variant="subtitle2">
          {tCurr('insert')}
        </TitleSection>
        <Tooltip
          disableInteractive
          title={tCommon('actions.add', {
            element: tCurr('elements.days_one_without')
          })}
          placement="right">
          <span>
            <IconButton
              sx={{ padding: '0.2rem', opacity: readOnly ? 0.5 : 1 }}
              onDragStart={onDragStart}
              size="medium"
              disabled={readOnly}
              ref={dragNewSession}>
              <SessionIcon size={20} variant={IconVariant.ADD} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          disableInteractive
          title={tCommon('actions.add', {
            element: tCurr('elements.days_one_with')
          })}
          placement="right">
          <span>
            <IconButton
              sx={{ padding: '0.2rem', opacity: readOnly ? 0.5 : 1 }}
              size="medium"
              disabled={readOnly}
              onDragStart={onDragStart}
              ref={dragNewTimeSession}>
              <TimeSessionIcon size={20} variant={IconVariant.ADD} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          disableInteractive
          title={tCommon('actions.add', {
            element: tCurr('elements.topics_one')
          })}
          placement="right">
          <span>
            <IconButton
              sx={{ padding: '0.2rem', opacity: readOnly ? 0.5 : 1 }}
              size="medium"
              disabled={readOnly}
              onDragStart={onDragStart}
              ref={dragNewTopic}>
              <TopicIcon size={20} variant={IconVariant.ADD} />
            </IconButton>
          </span>
        </Tooltip>
      </Section>
      <Section>
        <TitleSection variant="subtitle2">{tCurr('actions')}</TitleSection>
        <Tooltip
          disableInteractive
          title={tCommon('actions.duplicate', {
            element: getName()
          })}
          placement="right">
          <span>
            <IconButton
              sx={{ padding: '0.2rem' }}
              disabled={!(selectedTopics.length > 0 || selectedSessions.length > 0) || readOnly}
              onClick={onDuplicate}
              size="medium">
              <FaCopy size={18} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={tCommon('actions.delete', {
            element: getName()
          })}
          placement="right">
          <span>
            <IconButton
              sx={{ padding: '0.2rem' }}
              disabled={!(selectedTopics.length > 0 || selectedSessions.length > 0) || readOnly}
              onClick={onDelete}
              size="medium">
              <FaTrash size={18} />
            </IconButton>
          </span>
        </Tooltip>
      </Section>
      <Section>
        <TitleSection variant="subtitle2">{tCurr('scale')}</TitleSection>
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          <IconButton
            sx={{ padding: '0.2rem' }}
            size="medium"
            disabled={scale === ScaleConstraints.MIN}
            onClick={(e) => {
              e.stopPropagation();
              handleScale(-1);
            }}
            onMouseUp={(e) => e.stopPropagation()}>
            <FaMinus size={15} />
          </IconButton>
          <IconButton
            size="medium"
            sx={{ padding: '0.2rem' }}
            disabled={scale === ScaleConstraints.MAX}
            onClick={(e) => {
              e.stopPropagation();
              handleScale(1);
            }}
            onMouseUp={(e) => e.stopPropagation()}>
            <FaPlus size={15} />
          </IconButton>
          <Typography textAlign="center" width="100%" variant="caption">
            {tCurr('scaleCounter', { value: scale })}
          </Typography>
        </Box>
      </Section>
    </ToolbarMenu>
  );
};

export default ToolBar;
