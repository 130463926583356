import AppRouter from 'router/App-Router';
import { AuthContextProvider } from './context/AuthContext';
import './index.scss';

function App() {
  return (
    <AuthContextProvider>
      <AppRouter />
    </AuthContextProvider>
  );
}

export default App;
