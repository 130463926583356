import { ObjectidAutocomplete } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import {
  AutoCompleteField,
  COLLECTIONS_FIND_ONE,
  Entities,
  EntityRoutes,
  SystemProperties
} from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { ButtonsContainer, theme } from '@/theme';
import { DocumentDto, RecordDocumentDto } from '@/types';
import { getPropertyValueDef } from '@/utils';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type DocumentSelectionViewProps = {
  onCancel: () => unknown;
  onSuccess: (newDocument: RecordDocumentDto) => unknown;
  title: string;
};

const DocumentSelectionView = ({ onCancel, onSuccess, title }: DocumentSelectionViewProps) => {
  const { t: tResourcePanel } = useTranslation('templates/resourcePanelTemplate');
  const { t: tCommon } = useTranslation();
  const { setToastNotifications } = useToastContext();
  const selectDocumentInputRef = useRef<HTMLInputElement>(null);

  const [actualDocumentOnForm, setActualDocumentOnForm] = useState<{
    _id: string;
    label: string;
  }>();

  const {
    data: newDocument,
    error,
    loading: loadingNewDocument,
    fetch: fetchNewDocument
  } = useFetch<DocumentDto>();

  useEffect(() => {
    if (newDocument) {
      const tempDocument: DocumentDto = newDocument;
      onSuccess({
        _id: tempDocument._id,
        label: getPropertyValueDef(tempDocument.properties, SystemProperties.DOCUMENTS_NAME, ''),
        type: tempDocument.type
      });
    }
  }, [newDocument]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.code == 'document_not_found') {
        setToastNotifications([
          {
            message: tCommon('errors.entitiesNotFound', {
              entity: tCommon('entities.documents_other')
            })
          }
        ]);
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.loadingEntity', { entity: tCommon('entities.documents_one') })
          }
        ]);
      }
      onCancel();
    }
  }, [error]);

  const submitNewDoc = () => {
    if (actualDocumentOnForm) {
      fetchNewDocument({
        url: COLLECTIONS_FIND_ONE.replace(':collection_name', EntityRoutes.DOCUMENTS).replace(
          ':id',
          actualDocumentOnForm._id
        )
      });
    }
  };

  useEffect(() => {
    if (selectDocumentInputRef)
      setTimeout(() => {
        selectDocumentInputRef.current?.focus();
      }, 300);
  }, [selectDocumentInputRef]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && actualDocumentOnForm) {
        submitNewDoc();
      }
    },
    [actualDocumentOnForm]
  );

  return (
    <>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {title}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '2rem 2rem' }}>
        <Grid
          sx={{
            margin: 'auto'
          }}>
          <Typography marginBottom={'0.5rem'}>
            {tResourcePanel('recordTab.documentSelection.desc')}
          </Typography>
          <ObjectidAutocomplete
            name={`${Entities.DOCUMENTS}_name`}
            autoComplete={AutoCompleteField}
            inputRef={selectDocumentInputRef}
            referencedCollection={EntityRoutes.DOCUMENTS}
            value={actualDocumentOnForm}
            onKeyDown={onKeyDown}
            onChange={(value) => {
              setActualDocumentOnForm(value as { _id: string; label: string });
            }}
          />
        </Grid>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button
            disabled={loadingNewDocument}
            onClick={(e) => {
              onCancel();
            }}>
            {tCommon('buttons.cancel')}
          </Button>
          <LoadingButton
            loading={loadingNewDocument}
            variant="contained"
            disabled={!actualDocumentOnForm}
            onClick={(e) => {
              submitNewDoc();
            }}>
            {tCommon('buttons.select')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </>
  );
};

export default DocumentSelectionView;
