import { RenderIf } from '@/components/atoms';
import { Loading } from '@/components/molecules';
import { ScheduleConfigTemplate } from '@/components/templates/ScheduleConfigTemplate';
import { ScheduleConfigSchemaType } from '@/components/templates/ScheduleConfigTemplate/ScheduleConfigTemplate.schema';
import {
  ScheduleResultComplete,
  ScheduleResultJobByJob
} from '@/components/templates/ScheduleResultTemplate/components';
import { SCHEDULING_PROCESS_CREATE, ScheduleModes } from '@/const';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useScheduleConfigContext } from '@/context/ScheduleConfigContext';
import { useScheduleContext } from '@/context/SchedulerContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { ScheduleProcessDto } from '@/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ScheduleConfigModalProps = {
  mode?: 'config' | 'result';
};

const ScheduleConfigModal = ({ mode = 'config' }: ScheduleConfigModalProps) => {
  const { setIsFinished } = useScheduleConfigContext();
  const { setToastNotifications } = useToastContext();
  const [modeSchedule, setModeSchedule] = useState(mode);
  const {
    setCurrentProcessId,
    currentProcess,
    currentProcessId,
    currentProcessLoading,
    schedulingError,
    setSchedulingError
  } = useScheduleContext();
  const {
    fetch: fetchCreateScheduling,
    data: dataCreateScheduling,
    loading: loadingCreateScheduling,
    error: errorCreateScheduling
  } = useFetch<ScheduleProcessDto>();
  const { isOpen, handleClose } = useGenericModalContext();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { t: tCommon } = useTranslation();

  useEffect(() => {
    if (!isOpen) {
      setIsFinished(false);
      //setCurrentProcessId(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (errorCreateScheduling || schedulingError) {
      if (errorCreateScheduling?.response?.data?.code == 'scheduling_process_running') {
        setToastNotifications([{ message: tCommon('errors.uniqueRunningProcess') }]);
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.actionError', {
              action: tCommon('actions.creation'),
              entity: tCommon('entities.scheduling_processes_one')
            })
          }
        ]);
        handleClose(false);
        setSchedulingError(undefined);
      }
    }
  }, [schedulingError, errorCreateScheduling]);

  const onSuccess = (form: ScheduleConfigSchemaType) => {
    if (form) {
      const data = { ...form, endDate: form.endDate?.toISOString() };
      if (!data.byPeriods && typeof data.periodSize == 'number') {
        delete data.periodSize;
      }
      fetchCreateScheduling({
        url: SCHEDULING_PROCESS_CREATE,
        method: 'POST',
        data: data
      });
    }
  };

  useEffect(() => {
    if (dataCreateScheduling && dataCreateScheduling._id) {
      setCurrentProcessId(dataCreateScheduling._id);
      setModeSchedule('result');
    }
  }, [dataCreateScheduling]);

  useEffect(() => {
    setIsLoading(currentProcessLoading && currentProcess == null);
  }, [currentProcess, currentProcessLoading]);

  return (
    <>
      <RenderIf condition={isLoading}>
        <Loading />
      </RenderIf>
      <RenderIf condition={modeSchedule == 'config' && currentProcessId == null}>
        <ScheduleConfigTemplate
          count={36}
          onSuccess={onSuccess}
          loading={loadingCreateScheduling}
          onCancel={() => {
            handleClose(false);
          }}
        />
      </RenderIf>
      <RenderIf
        condition={
          modeSchedule == 'result' &&
          currentProcessId !== null &&
          currentProcess?.config.schedulingType === ScheduleModes.COMPLETE
        }>
        <ScheduleResultComplete />
      </RenderIf>
      <RenderIf
        condition={
          modeSchedule == 'result' &&
          currentProcessId !== null &&
          currentProcess?.config.schedulingType === ScheduleModes.JOBBYJOB
        }>
        <ScheduleResultJobByJob byPeriods={currentProcess?.config.byPeriods !== null} />
      </RenderIf>
    </>
  );
};

export default ScheduleConfigModal;
