import {
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  TextField as MuiTextField
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    bryntum: { selected: string; text: string; tree: { stripe: { odd: string; even: string } } };
    black: Palette['grey'];
  }

  interface PaletteOptions {
    bryntum?: { selected: string; text: string; tree: { stripe: { odd: string; even: string } } };
    black?: PaletteOptions['grey'];
  }
  interface TypographyVariants {
    details: React.CSSProperties;
    body3: React.CSSProperties;
    label: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    details?: React.CSSProperties;
    body3?: React.CSSProperties;
    label: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    details: true;
    body3: true;
    label: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      //main: '#2d3431'
      main: '#43514c'
    },
    bryntum: {
      selected: 'rgba(254, 172, 49, 0.25)',
      tree: {
        stripe: {
          odd: 'rgba(230, 244, 249, 0.8)',
          even: 'rgba(240, 250, 255, 0.8)'
        }
      },
      text: '#606263'
    },
    secondary: {
      main: '#1976d2',
      light: '#3dbdef'
    },
    background: {
      default: '#f3f4f5'
    },
    divider: '#d8d9da',
    common: {
      white: '#fff'
    },
    warning: {
      main: '#F6E54B',
      light: '#fff173'
    },
    success: {
      main: '#387E15',
      light: '#AFED91'
    },
    error: {
      main: '#d12424',
      light: '#e57373'
    },
    grey: {
      100: '#F7F7F7',
      200: '#F3F4F5',
      300: '#F1F1F1',
      400: '#C4C4C4',
      500: '#4F5964',
      600: '#3a3b3c',
      700: '#7878834d',
      800: '#787883',
      900: '#7878830d'
    },
    black: {
      100: 'rgba(0, 0, 0, 0.6)'
    }
  },
  shape: {
    borderRadius: 2
  },
  typography: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    details: {
      cursor: 'pointer',
      fontStyle: 'italic',
      textDecoration: 'underline',
      fontSize: 15
    },
    body3: {
      fontWeight: 400,
      fontSize: 15,
      lineHeight: 1.43,
      letterSpacing: '0.01071em'
    },
    label: {
      fontWeight: 400,
      fontSize: '0.7rem',
      lineHeight: '1.4375rem'
    }
  }
});

export const TextField = styled(MuiTextField)(({ theme }) => ({
  borderRadius: '4px',
  // fontSize: '0.8rem',
  '& .MuiOutlinedInput-root': {
    borderRadius: 'inherit'
  },
  fieldset: {
    borderRadius: 'inherit'
  }
}));

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
`;

export const Select = styled(MuiSelect)(({ theme }) => ({
  fontSize: '0.8rem',
  borderRadius: '4px'
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  fontSize: '0.7rem'
}));

export const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  fontSize: '0.7rem'
}));

export const ListRow = styled('li')(({ theme }) => ({
  lineHeight: '1rem'
}));

export const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1)
}));
