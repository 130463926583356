import { theme } from '@/theme';
import { CustomIconsProps } from './CustomIcons.const';

const Clock = ({
  size = 17,
  color = theme.palette.common.black,
  style = { backgroundColor: 'transparent' },
  variant
}: CustomIconsProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_54_18)">
        <path
          d="M50 93.75C74.1625 93.75 93.75 74.1625 93.75 50C93.75 25.8375 74.1625 6.25 50 6.25C25.8375 6.25 6.25 25.8375 6.25 50C6.25 74.1625 25.8375 93.75 50 93.75Z"
          stroke={color}
          strokeWidth="7"
          strokeMiterlimit="10"
        />
        <path
          d="M50 93.75C74.1625 93.75 93.75 74.1625 93.75 50C93.75 25.8375 74.1625 6.25 50 6.25C25.8375 6.25 6.25 25.8375 6.25 50C6.25 74.1625 25.8375 93.75 50 93.75Z"
          fill={style.backgroundColor}
          stroke={color}
          strokeWidth="5"
          strokeMiterlimit="10"
        />
        <path
          d="M50 53.9583C52.1861 53.9583 53.9583 52.1861 53.9583 50C53.9583 47.8139 52.1861 46.0417 50 46.0417C47.8139 46.0417 46.0417 47.8139 46.0417 50C46.0417 52.1861 47.8139 53.9583 50 53.9583Z"
          fill="#DADADA"
          stroke={color}
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path d="M50 18.1667V46.0417" stroke={color} strokeWidth="3" strokeMiterlimit="10" />
        <path
          d="M69.875 69.875L53.9583 53.9583"
          stroke={color}
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_54_18">
          <rect width="100" height="100" fill={style.backgroundColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Clock;
