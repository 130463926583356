import { RenderIf } from '@/components/atoms';
import { ActionTypes, useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { theme } from '@/theme';
import { CurriculumDto, Session, TimeStrategy } from '@/types';
import { Divider, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import React, { DragEventHandler, memo, useCallback, useMemo, useRef, useState } from 'react';
import { ConnectDragSource, useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DragNDropComponents, TabOptions } from '../../CurriculumSetup.const';
import { DropGhost } from './DropGhost';
import { SessionRow } from './SessionRow';
import { DROP_SIDES, IconHeader, getKeyDay } from './SessionWrapper.const';
import {
  FOOTER_VARIANT,
  HEADER_TITLE_VARIANT,
  HeaderContainer,
  HeaderTitle,
  Wrapper
} from './SessionWrapper.styles';
import { TimeSessionRow } from './TimeSessionRow';

export type SessionWrapperProps = {
  index: number;
  scale: number;
  moveSession: (sourceIndex: number, destinyIndex: number) => unknown;
  addNewSession: (
    type: DragNDropComponents.NEW_SESION | DragNDropComponents.NEW_TIME_SESSION,
    destinyIndex: number
  ) => unknown;
  onSelect: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const SessionWrapper = ({
  index,
  moveSession,
  addNewSession,
  onSelect,
  scale
}: SessionWrapperProps) => {
  const { control, getValues } = useFormContext<CurriculumDto>();
  const {
    selectedSessions,
    handleSelectedSessions,
    handleSelectedTopics,
    setSelectedType,
    readOnly,
    setPosition
  } = useCurriculumSetupContext();

  const timeStrategy: Session['timeStrategy'] = useWatch({
    control,
    name: `structure.sessions.${index}.timeStrategy`,
    exact: true
  });
  const sessionId = useMemo(() => getValues(`structure.sessions.${index}.id`), [index]);

  const isSelected = useMemo(
    () => selectedSessions.some((selectedSession) => selectedSession.id === sessionId),
    [selectedSessions, sessionId, index]
  );

  const [{ isDragging }, drag, preview] = useDrag(
    {
      item: { id: sessionId, index, session: getValues(`structure.sessions.${index}`) },
      type: DragNDropComponents.SESSION,
      canDrag: (monitor) => !readOnly,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    },
    [readOnly]
  );

  const [dropSide, setDropSide] = useState<DROP_SIDES.top | DROP_SIDES.bot | undefined>();

  const [{ isOver }, drop] = useDrop(
    {
      accept: [
        DragNDropComponents.SESSION,
        DragNDropComponents.NEW_SESION,
        DragNDropComponents.NEW_TIME_SESSION
      ],
      hover(item: { id: string; index: number }, monitor) {
        const itemType = monitor.getItemType() as DragNDropComponents | null;
        if (itemType === DragNDropComponents.SESSION) {
          const dragIndex = item.index;
          const hoverIndex = index;
          if (dragIndex === hoverIndex) {
            setDropSide(undefined);
            return;
          }
        }
        const hoverBoundingRect = refContainer.current?.getBoundingClientRect() || {
          top: 0,
          bottom: 0
        };

        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset() || { x: 0, y: 0 };

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        setDropSide(hoverClientY < hoverMiddleY ? DROP_SIDES.top : DROP_SIDES.bot);
      },
      drop: (item, monitor) => {
        const itemType = monitor.getItemType() as DragNDropComponents | null;
        if (itemType === DragNDropComponents.SESSION) {
          const dragIndex = item.index;
          const hoverIndex = index;
          if (dragIndex === hoverIndex) {
            return;
          }
          if (dragIndex < hoverIndex) {
            if (dropSide === DROP_SIDES.top) {
              moveSession(dragIndex, hoverIndex - 1 >= 0 ? hoverIndex - 1 : 0);
            } else if (dropSide === DROP_SIDES.bot) {
              moveSession(dragIndex, hoverIndex);
            } else {
              return;
            }
          } else {
            if (dropSide === DROP_SIDES.top) {
              moveSession(dragIndex, hoverIndex);
            } else if (dropSide === DROP_SIDES.bot) {
              moveSession(dragIndex, hoverIndex + 1);
            } else {
              return;
            }
          }
        } else if (
          itemType === DragNDropComponents.NEW_TIME_SESSION ||
          itemType === DragNDropComponents.NEW_SESION
        ) {
          addNewSession(itemType, dropSide === DROP_SIDES.top ? index : index + 1);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver()
      })
    },
    [dropSide, index]
  );

  const refContainer = useRef<HTMLDivElement>();

  const combinedRef = (node: any) => {
    drop(node);
    refContainer.current = node;
  };

  const onDragStart: DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      handleSelectedTopics({ type: ActionTypes.DELETE_ALL });
      if (!selectedSessions.find((selectedTopic) => selectedTopic.id === sessionId)) {
        handleSelectedSessions({
          selection: [{ id: sessionId, index }],
          type: ActionTypes.REPLACE
        });
      }
      setSelectedType(TabOptions.SESSION);
    },
    [index, sessionId, selectedSessions]
  );

  if (isDragging) {
    return <div ref={drag} style={{ position: 'absolute' }}></div>;
  }

  preview(getEmptyImage());
  return (
    <>
      <Wrapper
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSelect(e);
        }}
        onMouseDown={(e) => e.stopPropagation()}
        isSelected={isSelected}
        isDragging={isDragging}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (selectedSessions.length === 1 || !isSelected) onSelect(e);
          setPosition({ x: e.clientX, y: e.clientY });
        }}
        ref={combinedRef}
        sx={{
          marginTop: isOver && dropSide === DROP_SIDES.top ? '3rem' : 0,
          marginBottom: isOver && dropSide === DROP_SIDES.bot ? '3rem' : 0
        }}>
        {isOver && dropSide !== undefined && <DropGhost dropSide={dropSide} />}
        <RenderIf condition={isOver}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '3rem',
              top: dropSide === DROP_SIDES.top ? '-3rem' : undefined,
              bottom: dropSide === DROP_SIDES.bot ? '-3rem' : undefined
            }}></div>
        </RenderIf>
        <Header refDrag={drag} onDragStart={onDragStart} index={index} />
        {timeStrategy === TimeStrategy.RESOURCE_MISSIONS || timeStrategy === undefined ? (
          <SessionRow index={index} sessionId={sessionId} scale={scale} />
        ) : (
          <TimeSessionRow index={index} sessionId={sessionId} scale={scale} />
        )}
      </Wrapper>
    </>
  );
};
const Header = ({
  index,
  refDrag,
  onDragStart
}: {
  index: number;
  refDrag: ConnectDragSource;
  onDragStart: React.DragEventHandler<HTMLDivElement>;
}) => {
  const { t: tCommon } = useTranslation();
  const { t } = useTranslation('organisms/curriculumSetup');
  const { control, getValues } = useFormContext<CurriculumDto>();

  const session: Session = useWatch({
    control,
    name: `structure.sessions.${index}`
  });
  const isLastOne = useMemo(
    () => index === getValues('structure.sessions').length - 1,
    [session, index]
  );
  const isFirstOne = useMemo(() => index === 0, [session, index]);

  const totalDuration = useMemo(
    () =>
      session.topics.reduce((count, currTopic, idx) => {
        return count + (currTopic?.duration | 0);
      }, 0),
    [session.topics]
  );
  const key = useMemo(
    () => getKeyDay(session.minDaysBefore, session.maxDaysBefore),
    [session.minDaysBefore, session.maxDaysBefore]
  );
  return (
    <HeaderContainer ref={refDrag} onDragStart={onDragStart}>
      {session.timeStrategy && <IconHeader timeStrategy={session.timeStrategy} />}
      <HeaderTitle variant={HEADER_TITLE_VARIANT}>{session.name}</HeaderTitle>
      <Divider orientation="vertical" sx={{ borderColor: theme.palette.divider }} />
      <Typography
        variant={FOOTER_VARIANT}
        flexShrink={0}
        sx={{ alignSelf: 'center', marginX: '0.5rem' }}>
        {t('canvas.topics', { quantity: session.topics.length || 0 })}
      </Typography>
      <Divider orientation="vertical" sx={{ borderColor: theme.palette.divider }} />
      <Typography
        variant={FOOTER_VARIANT}
        sx={{
          flex: '1 0',
          flexBasis: 'fit-content',
          alignSelf: 'center',
          marginX: '0.5rem'
        }}>
        {t('canvas.duration', { time: totalDuration, timeUnit: tCommon('time.minutes_short') })}
      </Typography>
      <RenderIf condition={!isLastOne}>
        <Divider orientation="vertical" sx={{ borderColor: theme.palette.divider }} />
        <Typography
          flexShrink={0}
          variant={FOOTER_VARIANT}
          sx={{ textAlign: 'right', marginX: '0.5rem' }}>
          {session?.turnMinutes
            ? t('canvas.turn_time', {
                time:
                  session.turnMinutes % 30 === 0 ? session.turnMinutes / 60 : session.turnMinutes,
                timeUnit:
                  session.turnMinutes % 30 === 0
                    ? tCommon('time.hours_short')
                    : tCommon('time.minutes_short')
              })
            : t('canvas.no_turn_time')}
        </Typography>
      </RenderIf>
      <RenderIf condition={!isFirstOne}>
        <Divider orientation="vertical" sx={{ borderColor: theme.palette.divider }} />
        <RenderIf
          condition={
            isNumber(session.minDaysBefore) ||
            isNumber(session.maxDaysBefore) ||
            !(session.minDaysBefore === 0 && session.maxDaysBefore === 1)
          }>
          <Typography
            variant="caption"
            sx={{
              flex: '0 0',
              flexBasis: 'fit-content',
              alignSelf: 'center',
              marginX: '0.5rem'
            }}>
            {t(`canvas.days.${key}`, {
              count: session.minDaysBefore,
              min: session.minDaysBefore,
              max: session.maxDaysBefore
            })}
          </Typography>
        </RenderIf>
      </RenderIf>
    </HeaderContainer>
  );
};

export default memo(SessionWrapper);
