import { StatusIcon } from '@/components/atoms';
import { BryntumHint, Loading } from '@/components/molecules';
import { EntityFilter } from '@/components/organisms';
import Filter from '@/components/organisms/Filter/Filter';
import ScheduleConfigModal from '@/components/templates/ScheduleConfigModal';
import { JobEventPanelTemplate, JobPanelTemplate } from '@/components/templates/EntityPanels';
import { UnscheduleJobsPanelTemplate } from '@/components/templates/UnscheduleJobsPanelTemplate';
import {
  ADVANCED_SEARCH,
  COLLECTIONS_BATCH_DELETE,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  EVENTS_BY_JOBS,
  EVENTS_GET_BY_JOBS,
  Entities,
  EntityRoutes,
  FILES_URL_BY_ID,
  Filters,
  FinishedOKStates,
  FinishedErrorStates,
  JOBS_CANCEL,
  JOBS_FIRST_EVENT,
  JOBS_STATUS_DETAILS,
  JOBS_STATUS_DETAILS_BATCH,
  RESOURCE_LABEL_PROP_IDS,
  RunningStates,
  SystemProperties,
  ScheduleProcessStatus
} from '@/const';

import { useFilterContext } from '@/context/FilterContext';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useJobContext } from '@/context/JobsContext';
import { useModalConfirmationContext } from '@/context/ModalConfirmationContext';
import { useResourceContext } from '@/context/ResourceContext';
import { useScheduleConfigContext } from '@/context/ScheduleConfigContext';
import { useScheduleContext } from '@/context/SchedulerContext';
import { useSocketContext } from '@/context/SocketContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch, useGetFilteredData } from '@/hooks';
import useCustomBryntumSorter from '@/hooks/useCustomBryntumSorter';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import useWindowUnloadEffect from '@/hooks/useWindowOnLoad';
import {
  equalResources,
  isLoadingTimeAxis,
  loadStateConfigBryntum,
  saveStateConfigBryntum,
  setLoading,
  setLoadingTimeAxis
} from '@/services/utils/utils';
import { theme } from '@/theme';
import {
  AssignmentBryntum,
  BryntumCellMenuContextGantt,
  DbClickEvent,
  EventDto,
  EventTaskBryntum,
  FieldFilterType,
  GANTT_HIERARCHY,
  GanttNames,
  JobDetailsDto,
  JobDto,
  JobtoTask,
  PropertyDto,
  ResourceAssignment,
  ResourceBryntum,
  ResourceDto,
  ResourceQueryResult,
  SchedulerNames,
  StructureStatus,
  TEMPORAL_CHILD_ID,
  ZoomLevels
} from '@/types';
import {
  defaultEventMenuContextOptionsGantt,
  defaultMenuContextOptions,
  getCommonGanttConfig,
  getCommonSchedulerConfig,
  getEventLocation,
  getPropertyValueDef,
  loadState,
  replaceTo,
  saveState
} from '@/utils';
import { BryntumGantt, BryntumGanttProps } from '@bryntum/gantt-react-thin';
import { Grid as GridMUI, Typography, Tooltip } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { isArray } from 'lodash';
import { DateTime } from 'luxon';
import {
  ForwardedRef,
  MutableRefObject,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { GanttContainer } from '../../MainTemplate.styles';
import { Position } from '../../../../../context/CurriculmSetupContext/CurriculumSetupContext';
import {
  RowTypes,
  formatStructure,
  getAllEventJob,
  getDetailsFromJobEvents,
  getColumnsGantt,
  getExtraFields,
  getStatus,
  transformJobsToGantt,
  UpdateGantt,
  TemporalSchedulingJobs,
  verifySessionExist,
  getPeriodDateEventsGeneral
} from './GanttJobs.consts';

import { StringHelper, Model, Store, Menu, ContainerItemConfig } from '@bryntum/core-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { Gantt, TaskModel, TaskStore } from '@bryntum/gantt-thin';
import { showColorPicker } from './ShowColorPicker';

export type GanttJobsProps = {
  open: boolean;
  resourceFilter?: string[];
  jobsFilterSetter: (newJobs) => unknown;
  jobsFetchTrigger?: Date;
  fullText: string | undefined;
  masterDetail: number;
  // deleteCommonEvent: (eventId: string) => unknown;
  toggleFilter: () => void;
  updateEvents: (
    scheduler: GanttNames,
    events: EventTaskBryntum[],
    assignments: AssignmentBryntum[],
    silent?: boolean
  ) => void;
  triggerScheduler: (scheduler: GanttNames) => void;
  onEditEvents: (editedEvents: EventDto[], silent?: boolean) => unknown;
};

const GanttJobs = (
  {
    open,
    resourceFilter,
    jobsFilterSetter,
    jobsFetchTrigger,
    // deleteCommonEvent,
    fullText,
    masterDetail,
    toggleFilter,
    triggerScheduler,
    onEditEvents
  }: GanttJobsProps,
  ref: ForwardedRef<BryntumGantt>
) => {
  const { t: tMainTemplate } = useTranslation('templates/mainTemplate');
  const propertiesRef = useRef<HTMLButtonElement>(null);
  const { t: tCommon } = useTranslation();
  const { isProcessingScheduling, processes, currentProcess, setCurrentProcessId } =
    useScheduleContext();
  const { showConfirmation, handleCancel } = useModalConfirmationContext();
  type ProcessInfoDayByDay = {
    _id: string | null;
    currentJob: string | null;
    jobQueue: string[] | null;
    status: string | null;
    lastJobFinished: string | null;
  };

  const [processInfoSchedulingDayByDay, setInfoSchedulingDaybyDay] = useState<ProcessInfoDayByDay>({
    _id: null,
    currentJob: null,
    jobQueue: null,
    status: null,
    lastJobFinished: null
  });
  const [proccessScheduling, setProccessScheduling] = useState<string>(''); //Maybe not necessary
  const [deletedResources, setDeletedResources] = useState<string[]>([]);

  //Capture Processes Events Different FINISHED / STOPPED
  useEffect(() => {
    const stateProcces = processes.find((_process) =>
      RunningStates.includes(_process.status.toUpperCase())
    );
    if (!!stateProcces && stateProcces?._id) {
      if (!proccessScheduling.includes(stateProcces?._id)) {
        setProccessScheduling(stateProcces?._id);
        setCurrentProcessId(stateProcces?._id);
      }
    }
  }, [processes, currentProcess]);

  //Capture Event  FINISHED / STOPPED And Delete from Process Scheduling

  useEffect(() => {
    const UpdateProccesFinished = async () => {
      const UpdateFinishJob = async (listJobs: string[]) => {
        const jobsUpdates = (
          await axios.post(ADVANCED_SEARCH.replace(':entity', 'jobs'), {
            jobIds: listJobs
          })
        ).data;

        const jobsEventsUpdates = (await axios.post(EVENTS_GET_BY_JOBS, listJobs))
          .data as EventDto[];

        const renderNewJobs = transformJobsToGantt(
          false,
          jobsUpdates?.jobs as JobDto[],
          jobsEventsUpdates
        ) as EventTaskBryntum[];
        const store = (ref as MutableRefObject<BryntumGantt>).current?.instance
          ?.taskStore as TaskStore;

        const { JobEventsDetails } = getDetailsFromJobEvents(
          jobsEventsUpdates,
          jobsUpdates?.jobs,
          tMainTemplate
        );
        UpdateGantt(
          store,
          renderNewJobs as EventTaskBryntum[],
          JobEventsDetails as EventTaskBryntum[],
          true,
          getStructure
        );
      };
      if (Array.isArray(processes) && processes.length > 0) {
        const stateProccess = processes.filter(
          (_process) =>
            FinishedOKStates.includes(_process.status.toUpperCase()) ||
            FinishedErrorStates.includes(_process.status.toUpperCase())
        );
        if (stateProccess.length > 0) {
          const isFinished =
            stateProccess.filter((process) => process._id === proccessScheduling).length > 0;
          if (isFinished && !!currentProcess) {
            setInfoSchedulingDaybyDay({
              _id: null,
              currentJob: null,
              jobQueue: null,
              status: null,
              lastJobFinished: null
            });
            setProccessScheduling('');
            //Update Info Job Finished
            UpdateFinishJob(currentProcess?.config?.jobs);
           
          }
        }
        if (
          !!processInfoSchedulingDayByDay.lastJobFinished &&
          processInfoSchedulingDayByDay.lastJobFinished !== currentProcess?.currentJob
        ) {
          UpdateFinishJob([processInfoSchedulingDayByDay.lastJobFinished]);
     
        }
      }
    };
    UpdateProccesFinished();
  }, [processes, processInfoSchedulingDayByDay.lastJobFinished]);

  //Modify Gantt Graphic loading
  useEffect(() => {
    const UpdateJobsState = async () => {
      if (isProcessingScheduling()) {
        const intervalUpdateTemporalId = setInterval(() => {
          if (!isLoadingTimeAxis(ref)) {
            const store = (ref as MutableRefObject<BryntumGantt>).current?.instance
              ?.taskStore as TaskStore;

            if (currentProcess?._id && currentProcess?.status && currentProcess?.config) {
              if (currentProcess?.currentJob && !!currentProcess?.jobQueue) {
                TemporalSchedulingJobs([currentProcess?.currentJob], currentProcess?.status, store);
                setInfoSchedulingDaybyDay((data) => ({
                  _id: currentProcess?._id,
                  currentJob: currentProcess?.currentJob,
                  jobQueue: currentProcess.jobQueue,
                  status: currentProcess?.status,
                  lastJobFinished:
                    data?.currentJob !== currentProcess?.currentJob
                      ? data?.currentJob
                      : data?.lastJobFinished
                }));
              } else {
                TemporalSchedulingJobs(currentProcess?.config?.jobs, currentProcess?.status, store);
              }
            }
            clearInterval(intervalUpdateTemporalId);
          }
        }, 400);
      }
    };

    UpdateJobsState();
  }, [currentProcess]);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const { settings: jobSettings, setSettings: setJobSettings } = useFilterContext(Entities.JOBS);

  const localJobsFilters = loadState<FieldFilterType[]>(Filters.JOBS) || [];

  const [jobFilters, setJobFilters] = useState<FieldFilterType[]>(localJobsFilters);
  const [jobDetailsEvent, setJobDetailsEvent] = useState<EventTaskBryntum[]>([]);

  const handleJobFilters = (form: FieldFilterType[]) => {
    setJobFilters(form);
    saveState(Filters.JOBS, form);
  };
  const { initialize, handleClose, handleDirtyModal, preventFromClose } = useGenericModalContext();
  const { selectedJobs, setSelectedJobs } = useScheduleConfigContext();
  const { resources: localResources, addResources } = useResourceContext();
  const { jobs: localJobs, addJobs, deleteJob } = useJobContext();
  const { setToastNotifications } = useToastContext();
  const { loading: globalLoading, data: globalData, fetchByJobs } = useGetFilteredData();

  const newSorter = useCustomBryntumSorter({
    gridRef: ref as MutableRefObject<BryntumGantt>,
    refName: SchedulerNames.JOBS
  });

  const fetchData = () => {
    if (masterDetail == 1) {
      fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
    }
    if (masterDetail == 2 && Array.isArray(resourceFilter)) {
      fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
    }
    if (masterDetail == 0) {
      fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
    }
  };

  useEffect(() => {
    properties && fetchData();
  }, [jobFilters, fullText, resourceFilter, newSorter, jobsFetchTrigger]);

  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    const columnStore: ColumnStore = (ref as RefObject<BryntumGantt>).current?.instance
      ?.columns as ColumnStore;
    if (columnStore && columnStore.getById('counter')) {
      columnStore.getById('counter').text = renderToString(
        <GridMUI display={'flex'} flexWrap="wrap">
          {tCommon('text.count')}
          <Typography>{tCommon('number', { value: counter })}</Typography>
        </GridMUI>
      );
    }
  }, [ref, counter]);

  //First Fetch Jobs total
  const jobs = useMemo(() => {
    if (globalData) {
      setCounter(globalData?.count || 0);
      return globalData?.jobs;
    }
  }, [globalData]);

  useEffect(() => {
    if (jobs) {
      jobsFilterSetter(jobs.map((job) => job._id));
    }
  }, [jobs]);

  const [currentStartDate, setCurrentStartDate] = useState<Date>(
    DateTime.now().startOf('month').minus({ months: 1 }).toJSDate()
  );
  const [currentEndDate, setCurrentEndDate] = useState<Date>(
    DateTime.now().startOf('month').plus({ months: 1 }).endOf('month').toJSDate()
  );

  const filterDataJobsEvents = useMemo(() => {
    if (jobs) {
      const store = ((ref as MutableRefObject<BryntumGantt>).current?.instance?.subGrids as any)
        ?.locked.store as Store;

      if (store?.allRecords?.length > 0) {
        const a = {
          url: replaceTo(
            replaceTo(EVENTS_BY_JOBS, ':startDate', currentStartDate.toISOString()),
            ':endDate',
            currentEndDate.toISOString()
          ),
          method: 'POST',
          data:
            store.allRecords[0].id === 0
              ? jobs.map(({ _id }) => _id)
              : store.allRecords
                  .filter((jobRecord) => jobRecord.id == jobRecord.getData('job')?._id)
                  .map(({ id }) => id)
        };

        return a;
      } else {
        const taskStore = (ref as MutableRefObject<BryntumGantt>).current?.instance
          ?.taskStore as TaskStore;
        taskStore?.removeAll();
      }
    }
  }, [jobs, currentStartDate, currentEndDate]);

  const {
    data: jobEvents,
    loading: loadingEvents,
    error: errorJobEvents
  } = useFetch<EventDto[]>(filterDataJobsEvents);

  const renderJobs = useMemo(
    () => transformJobsToGantt(globalLoading, jobs, jobEvents),
    [jobEvents, globalLoading]
  );

  useEffect(() => {
    setLoadingTimeAxis(ref, loadingEvents);
  }, [loadingEvents]);

  useEffect(() => {
    if (errorJobEvents) {
      setToastNotifications([{ message: tMainTemplate('errors.loadingJobEvents') }]);
    }
  }, [errorJobEvents]);

  const { loading: loadingProperties, properties } = useGetPropertiesByEntity<PropertyDto[]>(
    Entities.JOBS,
    undefined,
    (data) => setJobFilters(data)
  );

  const { fetch: eventsRemover, error: errorEventsRemover } = useFetch(undefined, () => {
    fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
  }, [jobFilters, fullText, resourceFilter, newSorter]);

  const { fetch: fetchFirstEvent, error: errorFirstEvent } = useFetch<EventDto, AxiosError>();

  const { fetch: fetchJobForHint, error: errorJobHint } = useFetch<JobDto>();
  const { fetch: fetchResourcesForHint, error: errorResourcesForHint } =
    useFetch<ResourceQueryResult>();
  const { fetch: fetchFile, error: errorImage } = useFetch<string>();

  useEffect(() => {
    if (errorImage) {
      setToastNotifications([{ message: tCommon('errors.loadingImage') }]);
    }
  }, [errorImage]);

  useEffect(() => {
    if (errorJobHint || errorResourcesForHint) {
      setToastNotifications([{ message: tMainTemplate('errors.loadingHint') }]);
    }
  }, [errorJobHint, errorResourcesForHint]);

  const extraItems = {
    scheduleMenuFeature: false,
    projects: {
      calendar: 'general',

      hoursPerDay: 24,
      daysPerWeek: 7,
      daysPerMonth: 31
    },
    taskRenderer: ({ taskRecord, renderData }) => {
      const color = taskRecord.getData('job')?.curriculum?.color;
      if (color) {
        renderData.style = `background-color: ${color}`;
      }
      return '';
    },
    labelsFeature: {
      labelCls: 'b-custom-label',
      top: {
        field: 'name',
        editor: {
          type: 'textfield'
        },
        renderer({ taskRecord }) {
          return taskRecord?.startDate != null &&
            !taskRecord?.getData('stateUpdate') &&
            taskRecord?.status !== StructureStatus.NOT_SCHEDULED
            ? taskRecord.name
            : '';
        }
      }
    }
  } as Partial<BryntumGanttProps>;

  const headerMenuFeature = useMemo(() => {
    return {
      processItems({ items }) {
        if (!globalLoading) {
          items.groupAsc = false;
          items.groupDesc = false;
        } else {
          items.columnPicker = false;
          items.groupAsc = false;
          items.groupDesc = false;
          items.hideColumn = false;
          items.multiSort = false;
          items.sortAsc = false;
          items.sortDesc = false;
        }
      }
    };
  }, [globalLoading]);

  const updateJob = useCallback(
    async (jobs: JobDto | JobDto[]) => {
      const store = ((ref as MutableRefObject<BryntumGantt>).current?.instance?.subGrids as any)
        .locked.store as Store;
      const gantRef = (ref as RefObject<BryntumGantt>)?.current?.instance as Gantt;
      const jobsArr: JobDto[] = isArray(jobs) ? jobs : [jobs];

      for (const row of jobsArr) {
        deleteJob(row._id);
        addJobs([row]);

        const job: ResourceBryntum = transformJobsToGantt(false, [row])[0];
        const data: Model = store.getById(row._id);
        properties?.forEach((prop) => {
          data.set(prop._id, job[prop._id]);
        });

        data.set('job', row);
        data.set('name', job.name);
        gantRef.selectRow({ record: data, scrollIntoView: false });
      }
    },
    [properties]
  );

  const addNewJobs = async (job: JobDto | JobDto[]) => {
    const store = ((ref as MutableRefObject<BryntumGantt>).current?.instance?.subGrids as any)
      .locked.store as Store;
    const gridRef = (ref as RefObject<BryntumGantt>)?.current?.instance as Gantt;
    const jobArr = isArray(job) ? job : [job];
    addJobs(jobArr);
    const newJobArr: ResourceBryntum[] = transformJobsToGantt(false, jobArr);

    const newRows: Model[] = store.insert(0, newJobArr);
    setCounter((last) => last + newJobArr.length);
    if (gridRef) {
      gridRef.selectedRecords = newRows;
      gridRef.scrollToTop();
    }
  };

  const onCellDblClick = useCallback(
    (ev: DbClickEvent) => {
      if (
        !loadingProperties &&
        ev.column.getData('type') !== 'timeAxis' &&
        ev.record.getData('job')?._id
      ) {
        initialize(
          <JobPanelTemplate
            mode={CrudModes.EDIT}
            jobId={ev.record.getData('job')._id}
            onSuccess={(e) => {
              if (e) {
                updateJob(e);
                handleClose(false);
              }
            }}
            onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.jobs_one'))}
            onLoading={preventFromClose}
            onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.jobs_one'))}
          />,
          { modalName: tCommon('entities.jobs_one') }
        );
      }
    },
    [loadingProperties, resourceFilter]
  );

  const handleError = (message: string) => {
    setToastNotifications([{ message }]);
  };

  const cellMenuFeature = useMemo(() => {
    return {
      processItems(context: BryntumCellMenuContextGantt) {
        const { items, selection, record } = context;
        const selectedJobs: JobDto[] = selection
          .map((jobModel: Model) => jobModel.getData('job') as JobDto)
          .filter((job) => !!job) as JobDto[];

        const selectedJobsWithoutChildren: JobDto[] = selection
          .filter((jobModel: Model) => jobModel.id === jobModel.getData('job')?._id)
          .map((jobModel: Model) => jobModel.getData('job') as JobDto);

        const uniqueJobIds: string[] = Array.from(
          new Set(
            selection
              .filter((jobModel: Model) => jobModel.getData('job')?._id)
              .map((jobModel: Model) => jobModel.getData('job')._id as string)
          )
        );
        const cancelableJobs = selectedJobs.filter(
          (job) =>
            (job?.status == StructureStatus.PARTIALLY_SCHEDULED ||
              job?.status == StructureStatus.COMPLETE) &&
            !job?.beingScheduled
        );
        const expandedJobRows = selection.filter(
          (rowModel: Model) =>
            rowModel.firstChild?.id !== rowModel.id + TEMPORAL_CHILD_ID &&
            rowModel.getData('type') === RowTypes.JOB
        );

        if (!globalLoading) {
          defaultEventMenuContextOptionsGantt(items);
          items.createJob = {
            text: `${tMainTemplate('actions.createJob')}`,
            icon: 'b-icon b-fa-circle-plus',
            onItem: async (_ev) => {
              initialize(
                <JobPanelTemplate
                  mode={CrudModes.CREATE}
                  onSuccess={(e) => {
                    if (e) {
                      addNewJobs(e);
                      handleClose(false);
                    }
                  }}
                  onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.jobs_one'))}
                  onLoading={preventFromClose}
                  onDirtyFields={(isDirty) =>
                    handleDirtyModal(isDirty, tCommon('entities.jobs_one'))
                  }
                />,
                { modalName: tCommon('entities.jobs_one') }
              );
            }
          };
          items.editJobs = {
            text: tCommon('actions.editElement', {
              element: tCommon(`entities.${selection.length > 1 ? 'jobs_other' : 'jobs_one'}`)
            }),
            icon: 'b-icon b-fa-pen-to-square',
            disabled:
              uniqueJobIds.length === 0 ||
              selectedJobsWithoutChildren.filter((job) => job.beingScheduled).length > 0,
            onItem: (ev) => {
              if (ev.record != undefined) {
                const jobId: string | string[] =
                  uniqueJobIds.length > 1 ? uniqueJobIds : (ev.record.getData('job')._id as string);
                initialize(
                  <JobPanelTemplate
                    mode={CrudModes.EDIT}
                    jobId={jobId}
                    onSuccess={(e) => {
                      if (e) {
                        updateJob(e);
                        handleClose(false);
                      }
                    }}
                    onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.jobs_one'))}
                    onLoading={preventFromClose}
                    onDirtyFields={(isDirty) =>
                      handleDirtyModal(isDirty, tCommon('entities.jobs_one'))
                    }
                  />,
                  { modalName: tCommon('entities.jobs_one') }
                );
              }
            }
          };
          items.deleteJobs = {
            text: tCommon('actions.deleteElement', {
              element: tCommon('entities.jobs', { count: selection.length })
            }),
            icon: 'b-icon b-fa-trash-can',
            onItem: async (ev) => {
              const result: Record<string, unknown> = await showConfirmation({
                title: tCommon('modalConfirmationDelete.title'),
                message: (
                  <>
                    {tCommon('modalConfirmationDelete.message', {
                      count: uniqueJobIds.length,
                      number: uniqueJobIds.length,
                      element: tCommon('entities.jobs', {
                        count: uniqueJobIds.length
                      })
                    })}
                    <Typography variant="body2" marginTop="1rem">
                      <strong>{tCommon('utils.note') + ' '}</strong>
                      {tMainTemplate('noteDeleteJob')}
                    </Typography>
                  </>
                ),
                confirmButton: tCommon('buttons.delete'),
                cancelButton: tCommon('buttons.cancel'),
                fetchData:
                  ev.record != undefined
                    ? uniqueJobIds.length > 1
                      ? {
                          url: COLLECTIONS_BATCH_DELETE.replace(
                            ':collection_name',
                            EntityRoutes.JOBS
                          ),
                          method: 'POST',
                          data: {
                            ids: uniqueJobIds
                          }
                        }
                      : {
                          url: COLLECTIONS_FIND_ONE.replace(
                            ':collection_name',
                            EntityRoutes.JOBS
                          ).replace(':id', ev.record.getData('job')._id),
                          method: 'DELETE'
                        }
                    : undefined,
                handleError: (error) => {
                  setToastNotifications([{ message: tCommon('errors.deletionError') }]);
                }
              });
              if (result && ev.record != undefined) {
                fetchByJobs(jobFilters, fullText, resourceFilter, newSorter);
                selection.length > 1
                  ? (result?.validJobs as string[] | undefined)?.forEach((job) => {
                      deleteJob(job as string);
                    })
                  : deleteJob(ev.record.id as string);
              }
            }
          };
          items.runSchedule = {
            text: tMainTemplate('actions.runSchedule'),
            disabled:
              globalLoading ||
              !(
                selectedJobsWithoutChildren.filter(
                  (job) => job?.status !== StructureStatus.CANCELED
                ).length > 0
              ) ||
              selectedJobsWithoutChildren.length < 1,
            icon: 'b-icon b-fa-play',
            cls: 'b-separator',
            onItem: async (ev) => {

              if (isProcessingScheduling()) {
                const result = await showConfirmation({
                  title: tMainTemplate('noteScheduleJob.title'),
                  confirmButton: tCommon('buttons.continue'),
                  cancelButton: tCommon('buttons.cancel'),
                  message: tMainTemplate('noteScheduleJob.message')
                });

                if (!result) return;
              }
              else {
                setCurrentProcessId(null);
              }
              setSelectedJobs(
                selectedJobsWithoutChildren.filter(
                  (job) => job?.status !== StructureStatus.CANCELED
                )
              );
              initialize(<ScheduleConfigModal />);
            }
          };
          items.goToFirstEvent = {
            text:
              selection.filter((rowModel: Model) =>
                [RowTypes.JOB, RowTypes.SESSION].includes(rowModel.getData('type'))
              ).length > 0
                ? tMainTemplate('actions.goToFirstEvent')
                : tMainTemplate('actions.goToEvent'),
            icon: 'b-icon b-fa-calendar',
            disabled:
              globalLoading ||
              selection.length > 1 ||
              selection.filter((rowModel: Model) => {
                return [StructureStatus.NOT_SCHEDULED as string, undefined].includes(
                  getStatus(rowModel)
                );
              }).length > 0,
            onItem: async (ev) => {
              const params = {
                sessionId: selection[0]?.getData('sessionId'),
                topicId: selection[0]?.getData('topicId')
              };
              const firstEvent = (await fetchFirstEvent({
                url: JOBS_FIRST_EVENT.replace(':id', selection[0].getData('job')._id),
                method: 'GET',
                params
              })) as EventDto;

              if (firstEvent && firstEvent.properties) {
                const firstEventDate = new Date(
                  getPropertyValueDef(
                    firstEvent.properties,
                    SystemProperties.EVENTS_START_TIMESTAMP,
                    ''
                  )
                );
                if (!isNaN(firstEventDate.getTime())) {
                  setTimeout(() => {
                    (ref as RefObject<BryntumGantt>)?.current?.instance?.scrollToDate(
                      firstEventDate,
                      {
                        animate: {
                          easing: 'easeFromTo',
                          duration: 600
                        },
                        block: 'center'
                      }
                    );
                  }, 400);
                }
              }
            }
          };
          items.unschedule = {
            disabled:
              globalLoading ||
              selectedJobs.filter(
                (job) =>
                  !job.beingScheduled &&
                  job.status &&
                  [StructureStatus.COMPLETE, StructureStatus.PARTIALLY_SCHEDULED].includes(
                    job.status
                  )
              ).length === 0 ||
              selectedJobsWithoutChildren.length < 1,
            text: tMainTemplate('actions.unschedule'),
            icon: 'b-icon b-fa-times',
            onItem: async (ev) => {
              initialize(
                <UnscheduleJobsPanelTemplate
                  eventInfo={ev}
                  onSuccess={() => fetchByJobs(jobFilters, fullText, resourceFilter, newSorter)}
                />
              );
            }
          };
          items.cancel = {
            text: tCommon('buttons.cancel'),
            disabled: cancelableJobs.length === 0,
            icon: 'b-icon b-fa-circle-minus',
            onItem: async (ev) => {
              const jobs = cancelableJobs.map((job) => job._id);
              const result: JobDto[] = await showConfirmation({
                title: tCommon('modalConfirmationCancel.title'),
                message: (
                  <>
                    {tCommon('modalConfirmationCancel.message', {
                      element: tCommon('entities.jobs', { count: jobs.length }),
                      count: jobs.length
                    })}
                    <Typography variant="body2" marginTop="1rem">
                      <strong>{tCommon('utils.note') + ' '}</strong>
                      {tMainTemplate('noteCancelJob')}
                    </Typography>
                  </>
                ),
                confirmButton: tCommon('buttons.yes'),
                cancelButton: tCommon('buttons.no'),
                fetchData: {
                  url: JOBS_CANCEL,
                  data: {
                    jobs
                  },
                  method: 'POST'
                }
              });
              if (result) {
                const jobStore = (ref as MutableRefObject<BryntumGantt>).current?.instance
                  ?.taskStore;
                jobStore.beginBatch();
                result.forEach((job) => {
                  updateJob(job);
                });
                const jobIds = expandedJobRows.map((rowModel: Model) => rowModel.id);
                const details = await axios
                  .post<JobDetailsDto[]>(JOBS_STATUS_DETAILS_BATCH, {
                    ids: jobIds
                  })
                  .catch(() => handleError(tCommon('errors.defaultError')));
                if (!details) return;

                details.data.map((job) => {
                  job.sessions.map((session) => {
                    const rowSession = jobStore.getById(job._id + '_' + session.id);
                    rowSession.set('status', session.status);
                    session.topics.map((topic) => {
                      const rowTopic = jobStore.getById(
                        job._id + '_' + session.id + '_' + topic.id
                      );
                      rowTopic.set('status', topic.status);
                    });
                  });
                });

                const jobEvents = (
                  await axios.post(
                    EVENTS_BY_JOBS.replace(':startDate', currentStartDate.toISOString()).replace(
                      ':endDate',
                      currentEndDate.toISOString()
                    ),
                    result.map((job) => job._id)
                  )
                ).data as EventDto[];
                onEditEvents(jobEvents);
                jobStore.endBatch();
              }
            }
          };
          items.selectAllResources = {
            text: `${tCommon('actions.selectAll')}`,
            icon: 'b-icon b-fa-list-check',
            cls: 'b-separator',
            onItem: async () => {
              const instanceRef = (ref as RefObject<BryntumGantt>)?.current?.instance;
              instanceRef?.selectAll();
            }
          };
        } else {
          defaultMenuContextOptions(items);
        }
      }
    };
  }, [globalLoading, isProcessingScheduling, ref]);

  const menu = useMemo(() => {
    return new Menu({
      autoShow: false,
      items: [
        {
          text: `${tMainTemplate('actions.createJob')}`,
          icon: 'b-icon b-fa-circle-plus',
          onItem: async (_ev) => {
            initialize(
              <JobPanelTemplate
                mode={CrudModes.CREATE}
                onSuccess={(e) => {
                  if (e) {
                    addNewJobs(e);
                    handleClose(false);
                  }
                }}
                onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.jobs_one'))}
                onLoading={preventFromClose}
                onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.jobs_one'))}
              />,
              { modalName: tCommon('entities.jobs_one') }
            );
          }
        },
        {
          text: `${tCommon('actions.selectAll')}`,
          icon: 'b-icon b-fa-list-check',
          cls: 'b-separator',
          onItem: async (_ev) => {
            const instanceRef = (ref as RefObject<BryntumGantt>)?.current?.instance;
            instanceRef?.selectAll();
          }
        } as Partial<ContainerItemConfig>
      ] as Partial<ContainerItemConfig>[]
    });
  }, []);

  useEffect(() => {
    const gridRef = ref as RefObject<BryntumGantt>;
    if (gridRef && gridRef.current) {
      gridRef.current.element.onkeydown = (ev) => {
        if (ev.ctrlKey && ev.key == 'a') {
          gridRef.current?.instance?.selectAll();
          ev.stopPropagation();
          ev.preventDefault();
        }
      };
      (gridRef.current?.instance?.subGrids as any)['locked'].currentElement.addEventListener(
        'contextmenu',
        (ev: any) => {
          if (gridRef && gridRef.current && ev.target.$refOwnerId) {
            ev.preventDefault();
            menu.showBy([ev.clientX, ev.clientY]);
          }
        }
      );
    }
  }, []);

  const configGantt = useMemo(() => getCommonGanttConfig(extraItems), []);
  useEffect(() => getColumnsGantt(ref, tCommon, tMainTemplate, properties), [properties]);

  useEffect(() => {
    const store = (ref as MutableRefObject<BryntumGantt>).current?.instance?.taskStore as TaskStore;
    const isLoading =
      store.records?.length == 0 ||
      (store.records?.length > 0 && Object.keys(renderJobs?.[0] || {}).length == 2);
    if (Array.isArray(renderJobs) && !isLoading) {
      const { JobEventsDetails } = getDetailsFromJobEvents(jobEvents, jobs, tMainTemplate);
      setJobDetailsEvent(JobEventsDetails);
      setTimeout(() => {
        UpdateGantt(
          store,
          renderJobs as EventTaskBryntum[],
          JobEventsDetails as EventTaskBryntum[]
        );
      }, 0);

      (ref as MutableRefObject<BryntumGantt>).current.instance.scrollable.y = scrollTop;
    }
  }, [renderJobs]);

  useEffect(() => {
    if (properties) {
      loadStateConfigBryntum(ref, GanttNames.JOBS);
    }
  }, [properties]);

  useEffect(() => {
    if (globalLoading) {
      setLoading(ref);
    }
  }, [globalLoading]);

  useEffect(() => {
    setLoadingTimeAxis(ref, true);
  }, []);

  useWindowUnloadEffect(() => {
    const jobGantt = (ref as RefObject<BryntumGantt>)?.current?.instance;
    jobGantt && saveStateConfigBryntum(jobGantt, GanttNames.JOBS);
  }, true);

  useEffect(() => {
    if (errorFirstEvent) {
      setToastNotifications([{ message: tCommon('errors.goToFirstEventError') }]);
    }
  }, [errorFirstEvent]);

  useEffect(() => {
    if (errorEventsRemover) {
      setToastNotifications([{ message: tCommon('errors.unscheduleJobError') }]);
    }
  }, [errorEventsRemover]);

  const taskDblClick = (ev: { source: Gantt; taskRecord: TaskModel; event: MouseEvent }) => {
    if (ev.taskRecord.getData('seeTooltip') && !ev.taskRecord.getData('eventSession').join) {
      initialize(
        <JobEventPanelTemplate
          mode={CrudModes.EDIT}
          eventId={ev.taskRecord.getData('eventId')}
          resourceId={ev.taskRecord.getData('resources')[0]['_id']}
          onSuccess={(editedEvent, oldEvent) => {
            const newDeletedResources = oldEvent.resources
              .filter(
                ({ resource }) => !editedEvent.resources.some((rs) => rs.resource === resource._id)
              )
              .map(({ resource }) => resource._id);
            setDeletedResources((prev) => [...prev, ...newDeletedResources]);
            const silent = equalResources(editedEvent, oldEvent);
            onEditEvents([editedEvent], silent);
            handleClose(false);
          }}
          onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.events_one'))}
          onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
          onLoading={(loading) => preventFromClose(loading)}
        />,

        { modalName: tCommon('entities.events_one') }
      );
    } else if (ev.taskRecord.getData('lvl') !== GANTT_HIERARCHY.TOPICS) {
      showColorPicker(ev.taskRecord, ev.event);
    }
  };

  const getStructure = async (jobId: string) => {
    try {
      const data = (await axios.get(JOBS_STATUS_DETAILS.replace(':id', jobId))).data as any;
      const jobData = globalData.jobs.find((job: any) => job._id === jobId);
      if (jobData && jobData.jobstatusdetails) {
        data.sessions = data.sessions
          .map((session: any) => {
            // Find the matching session in the specific job's jobstatusdetails
            const matchingSession = jobData.jobstatusdetails[0].sessions.find(
              (s: any) => s.id === session.id
            );

            if (matchingSession) {
              // Filter topics in the session to match only those in globalData
              session.topics = session.topics.filter((topic: any) =>
                matchingSession.topics.some(
                  (t: any) => t.id === topic.id && t.status === topic.status
                )
              );
            } else {
              // If there's no matching session in jobData, remove all topics
              session.topics = [];
            }

            return session;
          })
          .filter((session: any) => session.topics.length > 0);
      }
      return data;
    } catch (e) {
      setToastNotifications([{ message: tMainTemplate('errors.loadingJobStructure') }]);
    }
  };

  const GoDateFirst = async (record: Model) => {
    const params = {
      sessionId: record.getData('sessionId'),
      topicId: record.getData('topicId')
    };
    const firstEvent = (await fetchFirstEvent({
      url: JOBS_FIRST_EVENT.replace(':id', record.getData('job')._id),
      method: 'GET',
      params
    })) as EventDto;
    if (firstEvent && firstEvent.properties) {
      const firstEventDate = new Date(
        getPropertyValueDef(firstEvent.properties, SystemProperties.EVENTS_START_TIMESTAMP, '')
      );
      if (!isNaN(firstEventDate.getTime())) {
        setTimeout(() => {
          (ref as RefObject<BryntumGantt>)?.current?.instance?.scrollToDate(firstEventDate, {
            animate: {
              easing: 'easeFromTo',
              duration: 600
            },
            block: 'center'
          });
        }, 200);
      }
    }
  };
  const onExpandNode = async (record: Model) => {
    const isFirsTimeExpanded = record.firstChild.id === record.id + TEMPORAL_CHILD_ID;
    const isRootNode = record.parentId === null;
    // Avoid load structure on expand second time or expand a session row
    if (!isFirsTimeExpanded || !isRootNode) {
      return;
    }
    const jobId = record.id as string;
    const structure = await getStructure(jobId);
    const allEventsOfJob = getAllEventJob(jobDetailsEvent, jobId);
    if (structure) {
      let listEventsOfJob = allEventsOfJob;
      if (!verifySessionExist(structure, allEventsOfJob)) {
        const jobsEventsUpdates = (await axios.post(EVENTS_GET_BY_JOBS, [jobId]))
          .data as EventDto[];

        const { JobEventsDetails } = getDetailsFromJobEvents(
          jobsEventsUpdates,
          jobs,
          tMainTemplate
        );
        listEventsOfJob = JobEventsDetails;
      }
      const formattedStructure = formatStructure(listEventsOfJob, structure, record);
      if (formattedStructure) {
        record.clearChildren();
        record.appendChild(formattedStructure);
      }
    }
  };

  const taskTooltipFeature = useMemo(() => {
    return {
      allowOver: true,
      onBeforeShow({ source: tooltip }) {
        tooltip['html'] = renderToString(<Loading />);
        tooltip['title'] = StringHelper.encodeHtml(tooltip.eventRecord?.getData('name'));
      },

      template: async ({ taskRecord }) => {
        let job: JobDto;
        let resources: Record<string, ResourceDto> = {};
        const fill: ResourceDto['_id'][] = [];

        if (taskRecord.get('job')['_id'] in localJobs) job = localJobs[taskRecord.get('job')];
        else {
          job = (await fetchJobForHint({
            url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.JOBS).replace(
              ':id',
              taskRecord.get('job')['_id']
            )
          })) as JobDto;
          addJobs([job]);
        }
        taskRecord.get('resources')?.forEach(({ resource }) => {
          if (resource in localResources)
            resources = { ...resources, [resource]: localResources[resource] };
          else fill.push(resource);
        });

        if (fill.length > 0) {
          const result = (await fetchResourcesForHint({
            url: ADVANCED_SEARCH.replace(':entity', Entities.RESOURCES),
            method: 'POST',
            data: { resourceIds: fill }
          })) as ResourceQueryResult;
          addResources(result.resources);
          const newResult = result.resources.reduce<Record<string, ResourceDto>>(
            (acc, curr) => ({
              ...acc,
              [curr._id]: curr
            }),
            {}
          );
          resources = { ...resources, ...newResult };
        }

        const resourceProperties =
          (taskRecord.getData('resources') as ResourceAssignment[])
            ?.filter(({ resource }) => !deletedResources.some((rs) => rs === resource))
            .reduce<Record<string, Record<string, string>[]>>((acc, { roleName, resource }) => {
              const resourceWithProperties = { _id: resource };
              resources[resource]?.properties
                .filter((prop) => RESOURCE_LABEL_PROP_IDS.has(prop._id))
                .forEach((prop) => {
                  resourceWithProperties[prop._id] = prop.value;
                });

              return {
                ...acc,
                [roleName]:
                  roleName in acc
                    ? [...acc[roleName], resourceWithProperties]
                    : [resourceWithProperties]
              };
            }, {}) || {};

        const imagesUrls = await Promise.all(
          Object.keys(resourceProperties)
            .map((roleName) => resourceProperties[roleName])
            .reduce((acc, current) => acc.concat(current), [])
            .map((resource) => {
              if (resource[SystemProperties.RESOURCES_PICTURE]) {
                return fetchFile({
                  url: FILES_URL_BY_ID.replace(':id', resource[SystemProperties.RESOURCES_PICTURE])
                });
              }
            })
        );
        const images = Object.keys(resourceProperties)
          .map((roleName) => resourceProperties[roleName])
          .reduce((acc, current) => acc.concat(current), [])
          .reduce(
            (acc, current, idx) => ({ ...acc, [current._id]: imagesUrls[idx] as string }),
            {}
          );

        return renderToString(
          <BryntumHint
            key={taskRecord.getData('_id')}
            startDate={new Date(taskRecord.getData('startDate'))}
            endDate={new Date(taskRecord.getData('endDate'))}
            location={getEventLocation(taskRecord.getData('eventSession')) as string}
            job={[
              getPropertyValueDef(taskRecord.getData('properties'), SystemProperties.JOBS_NAME, '')
            ]}
            additionalHeaderRender={
              <>
                {taskRecord.getData('status') === StructureStatus.CANCELED && (
                  <div className="b-hint-resource">
                    <StatusIcon size={1} status={taskRecord.getData('status')} />
                    <Typography>
                      {
                        tCommon(
                          `jobStatus.${taskRecord.getData('status') as StructureStatus}`
                        ) as string
                      }
                    </Typography>
                  </div>
                )}
                {taskRecord.getData('join') && (
                  <div className="b-hint-resource">
                    <Typography>
                      {tMainTemplate('text.joinedCount', { count: 'n' as unknown as number })}
                    </Typography>
                  </div>
                )}
              </>
            }
            resources={Object.keys(resourceProperties).map((roleName) => (
              <>
                <Typography key={roleName} variant="body1" className="b-resource-title">
                  {roleName}
                </Typography>
                {resourceProperties[roleName].map((resource, idx) => (
                  <div
                    key={resource._id}
                    style={{ marginTop: idx === 0 ? '0' : '4px' }}
                    className="b-hint-resource">
                    <GridMUI
                      sx={{
                        width: '24px',
                        height: '24px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      {images[resource._id] && !((images[resource._id] as any) instanceof Error) ? (
                        <img
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '20px',
                            border: `solid ${theme.palette.grey[400]} 1px`
                          }}
                          src={images[resource._id]}
                        />
                      ) : (
                        <FaUserCircle color={theme.palette.grey[400]} size={24} />
                      )}
                    </GridMUI>

                    <Typography component="div" variant="body2">
                      {`${
                        resource[SystemProperties.RESOURCES_FIRST_NAME]
                          ? `${resource[SystemProperties.RESOURCES_FIRST_NAME]} `
                          : ''
                      }${resource[SystemProperties.RESOURCES_NAME] ?? tCommon('unknown')} (${
                        resource[SystemProperties.RESOURCES_ID] ?? tCommon('unknown')
                      })`}
                    </Typography>
                  </div>
                ))}
              </>
            ))}
          />
        );
      }
    };
  }, [deletedResources]);

  return (
    <GanttContainer>
      <Filter
        open={open}
        toggleFilter={toggleFilter}
        refs={[propertiesRef]}
        title={tCommon('filters.title', { entity: tCommon('entities.jobs_other') })}>
        <EntityFilter
          entity={Entities.JOBS}
          title={tCommon('filters.byProperties')}
          onSuccess={handleJobFilters}
          defaultValues={jobFilters}
          extraFields={getExtraFields(tCommon)}
          selected={jobSettings}
          setSelected={setJobSettings}
          propertiesRef={propertiesRef}
        />
      </Filter>
      <BryntumGantt
        treeFeature={true}
        rowCopyPasteFeature={false}
        {...configGantt}
        taskTooltipFeature={taskTooltipFeature}
        emptyText={tMainTemplate('text.emptyRows', {
          row: tCommon('entities.jobs_other')
        })}
        onBeforeToggleNode={async ({ record, collapse }) => {
          if (!collapse) {
            await GoDateFirst(record);

            onExpandNode(record);
          }
        }}
        onBeforeDestroy={(e) => {
          const source: Gantt = e.source as Gantt;
          saveStateConfigBryntum(source, GanttNames.JOBS);
        }}
        ref={ref}
        bodyCls={'dropTargetScheduler'}
        hideHeaders={false}
        onCellDblClick={onCellDblClick}
        onTaskDblClick={taskDblClick}
        taskDragCreateFeature={false}
        taskEditFeature={false}
        projectLinesFeature={false}
        dependenciesFeature={false}
        taskMenuFeature={cellMenuFeature}
        taskSegmentResizeFeature={false}
        taskSegmentDragFeature={false}
        taskResizeFeature={false}
        percentBarFeature={false}
        taskDragFeature={false}
        enableDeleteKey={false}
        zoomOnMouseWheel={true}
        minZoomLevel={ZoomLevels.MIN}
        maxZoomLevel={ZoomLevels.MAX}
        onScroll={({ scrollTop }) => {
          setScrollTop(scrollTop);
        }}
        headerMenuFeature={headerMenuFeature}
        flex="1 1 0px"
        onDateRangeChange={(e) => {
          if (e.new.startDate < currentStartDate) {
            setCurrentStartDate(e.new.startDate);
          }
          if (e.new.endDate > currentEndDate) {
            setCurrentEndDate(e.new.endDate);
          }
        }}
      />
    </GanttContainer>
  );
};

export default forwardRef<BryntumGantt, GanttJobsProps>(GanttJobs);
