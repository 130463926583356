import { DocumentDto } from '@/types';
import { createContext } from 'react';

export type DocumentType = Record<DocumentDto['_id'], DocumentDto>;

export type DocumentContextType = {
  documents: DocumentType;
  handleDocuments: (newDocuments: DocumentType) => void;
  addDocuments: (newDocuments: DocumentDto[]) => void;
  deleteDocument: (idDocument: string) => void;
};

export default createContext<DocumentContextType>({} as DocumentContextType);
