import { Select as SelectMUI, styled, SxProps } from '@mui/material';

export const CardContainer = styled('div')`
  width: 100%;
  min-height: 3.125rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding-inline: 0.7rem;
`;

export const Name = styled('div')`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
`;

export const IconButton = styled('button')`
  cursor: pointer;
  display: grid;
  place-content: center;
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 0.8rem;
  border: none;
  background-color: transparent;
  flex-shrink: 0;
  padding: 0;
`;
export const Select = styled(SelectMUI)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '0px 0px 0px 10px',
    fontSize: '0.9rem',
    fontWeight: 'bold'
  },
  '& .MuiSelect-icon': {
    position: 'absolute',
    right: '-5px'
  },
  '& .MuiOutlinedInput-input': {
    paddingInline: '0px 15px !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const MenuStyles: SxProps = {
  '& .MuiMenu-paper': {
    '& .MuiList-padding': {
      paddingBlock: '0'
    },
    '& .MuiMenuItem-root': {
      fontSize: '0.9rem'
    }
  }
};

export const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  max-height: 15.2rem;
  height: 15.2rem;
  flex-grow: 1;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 3px;
  padding: 8px;
`;
