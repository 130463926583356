import { CSSProperties } from '@mui/styled-engine';
import { IconBaseProps } from 'react-icons/lib';

export enum IconVariant {
  ADD = 'add'
}

export type CustomIconsProps = {
  variant?: IconVariant;
  svgProperties?: CSSProperties;
} & IconBaseProps;
