import { TabOptions } from '@/components/organisms/CurriculumSetup/CurriculumSetup.const';
import CurriculumForm from './components/Forms/Curriculum';
import SessionForm from './components/Forms/Session';
import TopicForm from './components/Forms/Topic';
import { CrudModes } from '@/const';

export const Components = {
  [TabOptions.CURRICULUM]: CurriculumForm as (props: any) => JSX.Element,
  [TabOptions.SESSION]: SessionForm as (props: any) => JSX.Element,
  [TabOptions.TOPIC]: TopicForm as (props: any) => JSX.Element
};

export interface FormMasterProps {
  type: TabOptions;
  mode?: CrudModes;
}
