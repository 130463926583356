import { theme } from '@/theme';
import { PropsWithChildren } from 'react';
import { CircleContainer } from './Circle.styles';

export type CircleProps = PropsWithChildren<{
  size?: number;
  color?: string;
  label?: string;
  textColor?: string;
}>;

const LogoIcon = ({
  size = 50,
  color = theme.palette.secondary.main,
  textColor = theme.palette.text.primary,
  label = ''
}: CircleProps) => {
  return (
    <CircleContainer color={color} size={size} textColor={textColor}>
      {label}
    </CircleContainer>
  );
};

export default LogoIcon;
