import {
  CheckFilter,
  ColorInput,
  DateInput,
  ImageInput,
  NumberInput,
  ObjectidAutocomplete,
  SetAutocomplete,
  TextFilter,
  TimeInput,
  WeekdayInput
} from '@/components/molecules';
import { ObjectidAutocompleteProps } from '@/components/molecules/Autocomplete/ObjectidAutocomplete/ObjectidAutocomplete';
import { SelectFilter } from '@/components/molecules/Autocomplete/SelectFilter';
import { SetAutocompleteProps } from '@/components/molecules/Autocomplete/SetAutocomplete/SetAutocomplete';
import { PropertyTypes } from '@/const';
import { FieldFilterType, SpecialFilterTypes } from '@/types';
import { DateTime } from 'luxon';
import { ComponentType } from 'react';

export const validateRequired = (required?: boolean, type?: FieldFilterType['type']) => {
  if (required) {
    switch (type) {
      case PropertyTypes.STRING:
      case PropertyTypes.COLOR:
        return (value) => (typeof value === 'string' && value.trim() !== '') || !!value?.multiple;
      case PropertyTypes.WEEKDAYS:
        return (value) => (Array.isArray(value) && value.length > 0) || !!value?.multiple;
      default:
        return (value) => (value !== undefined && value !== null) || !!value?.multiple;
    }
  }
  return (value) => true;
};

export const validateByType = (type: FieldFilterType['type']) => {
  switch (type) {
    case [PropertyTypes.DATE, PropertyTypes.DATETIME].find((typeAr) => typeAr === type):
      return (value) => {
        if (value && value instanceof DateTime) {
          const date = value as DateTime;
          return isFinite(date.valueOf());
        }
        if (value && value instanceof Date) {
          return isFinite(+value);
        }
        return true;
      };
    case PropertyTypes.IMAGE:
      return (value) => {
        if (value && value.size >= 2 * 1024 * 1024) return 'maximumSizeFile';
        return true;
      };
    default:
      return (value) => true;
  }
};

export const COMPONENTS_BY_TYPE: Record<
  Exclude<FieldFilterType['type'], SpecialFilterTypes.OPTIONS>,
  ComponentType<any>
> = {
  [PropertyTypes.STRING]: TextFilter as (props: any) => JSX.Element,
  [PropertyTypes.BOOLEAN]: CheckFilter as (props: any) => JSX.Element,
  [SpecialFilterTypes.CUSTOM_BOOLEAN]: CheckFilter as (props: any) => JSX.Element,
  [PropertyTypes.NUMBER]: NumberInput as (props: any) => JSX.Element,
  [PropertyTypes.DATE]: DateInput as (props: any) => JSX.Element,
  [PropertyTypes.DATETIME]: DateInput as (props: any) => JSX.Element,
  [PropertyTypes.OBJECTID]: ObjectidAutocomplete as (props: any) => JSX.Element,
  [PropertyTypes.SET]: SetAutocomplete as (props: any) => JSX.Element,
  [SpecialFilterTypes.CUSTOM_SET]: SelectFilter as (props: any) => JSX.Element,
  [PropertyTypes.COLOR]: ColorInput as (props: any) => JSX.Element,
  [PropertyTypes.TIME]: TimeInput as (props: any) => JSX.Element,
  [PropertyTypes.IMAGE]: ImageInput as (props: any) => JSX.Element, //Pending Multiple Input.
  [PropertyTypes.WEEKDAYS]: WeekdayInput as (props: any) => JSX.Element
};

export const getPropertiesByType = (
  type: FieldFilterType['type'],
  properties: Record<string, unknown>
) => {
  if (type === PropertyTypes.OBJECTID) {
    return {
      referencedCollection: properties.referencedCollection
    } as Partial<ObjectidAutocompleteProps>;
  }
  if (type === PropertyTypes.BOOLEAN || type === SpecialFilterTypes.CUSTOM_BOOLEAN)
    return {
      sx: { margin: '0 !important', width: '100%' }
    };
  if (type === PropertyTypes.DATE) {
    return {
      fieldProps: { sx: { width: '100%' } }
    };
  }
  if (type === PropertyTypes.DATETIME) {
    return {
      fieldProps: { sx: { width: '100%' } },
      includeHours: true
    };
  }
  if (type === PropertyTypes.SET)
    return {
      referencedSet: properties.referencedSet
    } as Partial<SetAutocompleteProps>;
  if (type === SpecialFilterTypes.CUSTOM_SET) {
    if (properties.referencedSet === 'resourceTypes') {
      return {
        referencedCollection: 'entity-types/by-entity/resources',
        multiple: false,
        selectProperty: 'name'
      };
    }
    if (properties.referencedSet === 'documentTypes') {
      return {
        referencedCollection: 'entity-types/by-entity/documents',
        multiple: false,
        selectProperty: 'name'
      };
    }
  }
  return {};
};
