import { Arguments, Fetcher, Key, SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';

export const useGet = <Data = unknown, Error = unknown, SWRKey extends Key = Arguments>(
  key: SWRKey,
  config?: SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>>
) => {
  const response = useSWRImmutable(key, config);

  return {
    loading: !response.error && !response.data,
    ...response
  };
};
