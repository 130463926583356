import { styled } from '@mui/system';

export const ScheduleContainer = styled('div')`
  flex: 1;
  display: flex;
  min-height: 100px;
  flex-direction: row;
  align-items: stretch;
  min-height: 200px;
  height: 100%;
`;

export const OuterContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const GridHeader = styled('div')`
  width: 100%;
  display: flex;
  height: 2.875rem;
  background-color: ${({ theme }) => theme.palette.background.default};
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  border-bottom: none;
  border-radius: 5px 5px 0 0;
`;

export const BordererGridContainer = styled('div')`
  position: relative;
  width: 100%;
  flex: 1;
  margin: 0 0 0 0;
  border: 1px solid lightgray; //TODO
  border-radius: 0px 0px 5px 5px;
`;
