import { Entities, PROPERTIES_BY_ENTITY, PropertyTypes, SystemProperties } from '@/const';
import { EventDto, PropertyDto, ResourceDto } from '@/types';
import {
  AssignmentBryntum,
  EventBryntum,
  JoinedEventBryntum,
  ResourceBryntum
} from '@/types/ui/bryntum';
import {
  sortingByFetch,
  transformPropertyValue,
  transformToAssignmentBryntum,
  transformToEventBryntum,
  transformToJoinedEventBryntum
} from '@/utils';
import { ColumnStore } from '@bryntum/grid-thin';
import { BryntumSchedulerPro, BryntumSchedulerProProps } from '@bryntum/schedulerpro-react-thin';
import { Skeleton } from '@mui/material';
import { TFunction } from 'i18next';
import { ForwardedRef, RefObject } from 'react';

export const getSchedulerConfig = (
  extraItems: Partial<BryntumSchedulerProProps>
): BryntumSchedulerProProps => {
  return {
    columns: [
      ...Array(7)
        .fill('')
        .map(() => ({
          text: '...',
          width: 40
        }))
    ],
    eventStyle: undefined,
    eventColor: undefined,
    filterBarFeature: false,
    stripeFeature: true,
    infiniteScroll: true,
    multiEventSelect: true,
    selectionMode: {
      row: true,
      cell: false,
      multiSelect: true
    },
    eventDragSelectFeature: true,
    eventDragCreateFeature: false,
    stickyEventsFeature: false,
    timeRangesFeature: {
      showCurrentTimeLine: true
    },
    eventStore: {
      removeUnassignedEvent: false
    },
    cellEditFeature: false,
    cellMenuFeature: false,
    displayDateFormat: 'HH:mm',
    barMargin: 3,
    resourceMargin: 3,
    rowHeight: 32,
    viewPreset: 'hourAndDay-100by40',
    // zoomLevel: 13,
    maxZoomLevel: 13,
    dateFormat: 'DD mm',
    ...extraItems
  };
};

export const transformResourcesToScheduler = (loading?: boolean, resources?: ResourceDto[]) => {
  let localResources: ResourceBryntum[];
  if (resources) {
    localResources = resources.map((resource) => {
      const localResource: ResourceBryntum = {
        id: resource._id,
        resource
      };
      resource.properties.forEach(
        (property) => (localResource[property._id] = property.label ?? property.value)
      );
      return localResource;
    });
  } else {
    localResources = new Array(5).fill('').map((_, idx) => ({ id: `${idx}` }));
  }
  return localResources;
};

export const getColumns = (
  ref: ForwardedRef<BryntumSchedulerPro>,
  t: TFunction,
  properties?: PropertyDto[]
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumSchedulerPro>).current?.instance
    .columns as ColumnStore;
  columnStore.remove(columnStore.records.filter((r: any) => !r.isTimeAxisColumn));
  if (properties) {
    columnStore.add([
      {
        id: 'counter',
        field: 'counter',
        type: 'rownumber',
        cls: 'counter-header',
        cellCls: 'b-border-bottom',
        align: 'center',
        htmlEncodeHeaderText: false,
        hideable: false
      },
      {
        text: 'Type',
        field: 'type',
        id: 'Type',
        width: 80,
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record: { data } }) => {
          if (!data.resource) {
            return <Skeleton height={30} />;
          }
          return data?.resource?.type?.label ?? '';
        }
      },
      ...(properties as PropertyDto[])
        .sort((p1, p2) => p1._id.localeCompare(p2._id))
        .map(({ _id: id, name: text, type }) => ({
          id,
          ...(Object.values(SystemProperties).includes(id as SystemProperties)
            ? {}
            : { hidden: true }),
          field: id,
          text,
          width: 80,
          align: type == PropertyTypes.NUMBER ? 'right' : 'left',
          sortable: sortingByFetch,
          groupable: false,
          renderer: ({ record: { data } }) => {
            return !data.resource ? (
              <Skeleton height={30} />
            ) : (
              transformPropertyValue(type, t, data[id])
            );
          }
        }))
    ]);
  } else {
    columnStore.add([
      ...new Array(7).fill('').map(() => ({
        text: '...',
        width: 40,
        renderer: () => {
          return <Skeleton height={30} />;
        }
      }))
    ]);
  }
};

export const getAssignmentsFromEvents = (
  events?: EventDto[],
  resources?: ResourceDto[],
  t?: TFunction
) => {
  const resourceEventsRender: EventBryntum[] = [];
  const resourceAssignments: AssignmentBryntum[] = [];

  if (events && resources && t) {
    const joinedEventsRender = new Map<string, JoinedEventBryntum>();
    const joinedAssignmentBryntumByResource = new Map<string, AssignmentBryntum>();

    events.forEach((event) => {
      const eventBryntum = transformToEventBryntum(event);
      resourceEventsRender.push(eventBryntum);

      event.resources.forEach((resource) => {
        const assignmentBryntum = transformToAssignmentBryntum(
          event._id,
          resource.resource,
          resource.joined,
          false
        );
        if (event.join && resource.joined) {
          joinedAssignmentBryntumByResource.set(resource.resource, assignmentBryntum);
        } else {
          resourceAssignments.push(assignmentBryntum);
        }
      });

      if (event.join) {
        let joinedEvent: JoinedEventBryntum;
        if (!joinedEventsRender.has(event.join)) {
          joinedEvent = transformToJoinedEventBryntum(event);
          joinedEventsRender.set(event.join, joinedEvent);

          event.resources
            .filter((resource) => resource.joined)
            .forEach((resource) => {
              const assignmentBryntum = transformToAssignmentBryntum(
                joinedEvent.id,
                resource.resource,
                true,
                true
              );
              resourceAssignments.push(assignmentBryntum);
              joinedEvent?.joinedAssignments?.set(resource.resource, assignmentBryntum);
            });
        } else {
          joinedEvent = joinedEventsRender.get(event.join) as JoinedEventBryntum;
          if (joinedEvent.job) joinedEvent.job = [...joinedEvent.job, event.job];
        }
        joinedEvent['events'].push(eventBryntum);

        for (const [resourceId, assignmentBryntum] of joinedAssignmentBryntumByResource.entries()) {
          joinedEvent?.joinedAssignments
            ?.get(resourceId)
            ?.joinedAssignments?.push(assignmentBryntum);
        }
      }

      joinedAssignmentBryntumByResource.clear();
    });

    if (joinedEventsRender.size > 0) {
      for (const joinedEventRender of joinedEventsRender.values()) {
        const names = new Set<string>();
        const colors = new Set<string>();
        for (const event of joinedEventRender['events']) {
          names.add(event.name);
          colors.add(event.eventColor);
        }
        if (names.size == 1) {
          joinedEventRender.name = `${joinedEventRender.name}${t('text.joinedSuffix', {
            count: joinedEventRender['events'].length
          })}`;
        } else {
          joinedEventRender.name = t('text.joinedName', {
            count: joinedEventRender['events'].length
          });
        }
        if (colors.size == 1) {
          joinedEventRender.eventColor = colors.values().next().value as string;
        }

        delete joinedEventRender['events'];

        resourceEventsRender.push(joinedEventRender);
      }
    }
  }

  return {
    resourceEventsRender,
    resourceAssignments
  };
};

export const filterDataResourcesProperties = {
  url: PROPERTIES_BY_ENTITY.replace(':entity_name', Entities.RESOURCES)
};
