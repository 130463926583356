import { theme } from '@/theme';
import { CSSProperties } from 'react';
import { RenderIf } from '../RenderIf';
import { CustomIconsProps, IconVariant } from './CustomIcons.const';

const SessionIcon = ({
  size = 17,
  color = theme.palette.common.black,
  style = { backgroundColor: theme.palette.divider },
  svgProperties: cssProperties = {},
  variant
}: CustomIconsProps) => {
  return (
    <svg
      style={cssProperties as CSSProperties}
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_16_20" fill={theme.palette.common.white}>
        <path d="M0 26.6863H95V86C95 89.866 91.866 93 88 93H7C3.13401 93 0 89.866 0 86V26.6863Z" />
      </mask>
      <path
        d="M-4 24.6863H99L91 28.6863H4L-4 24.6863ZM99 86C99 92.0751 94.0751 97 88 97H7C0.924868 97 -4 92.0751 -4 86H4C4 87.6569 5.34315 89 7 89H88C89.6569 89 91 87.6569 91 86H99ZM7 97C0.924868 97 -4 92.0751 -4 86V24.6863L4 28.6863V86C4 87.6569 5.34315 89 7 89V97ZM99 24.6863V86C99 92.0751 94.0751 97 88 97V89C89.6569 89 91 87.6569 91 86V28.6863L99 24.6863Z"
        fill={color}
        mask="url(#path-1-inside-1_16_20)"
      />
      <mask id="path-3-inside-2_16_20" fill={theme.palette.common.white}>
        <path d="M0 11C0 7.13401 3.13401 4 7 4H88C91.866 4 95 7.13401 95 11V26.6863H0V11Z" />
      </mask>
      <path
        d="M0 11C0 7.13401 3.13401 4 7 4H88C91.866 4 95 7.13401 95 11V26.6863H0V11Z"
        fill={style.backgroundColor}
      />
      <path
        d="M-4 11C-4 4.92487 0.924868 0 7 0H88C94.0751 0 99 4.92487 99 11H91C91 9.34315 89.6569 8 88 8H7C5.34315 8 4 9.34315 4 11H-4ZM99 28.6863H-4L4 24.6863H91L99 28.6863ZM-4 28.6863V11C-4 4.92487 0.924868 0 7 0V8C5.34315 8 4 9.34315 4 11V24.6863L-4 28.6863ZM88 0C94.0751 0 99 4.92487 99 11V28.6863L91 24.6863V11C91 9.34315 89.6569 8 88 8V0Z"
        fill={color}
        mask="url(#path-3-inside-2_16_20)"
      />
      <RenderIf condition={variant === IconVariant.ADD}>
        <circle cx="75.5" cy="75.5" r="22.5" fill={color} stroke={color} strokeWidth="4" />
        <path
          d="M63.25 75.5H87.75"
          stroke={theme.palette.common.white}
          strokeWidth="7"
          strokeLinecap="round"
        />
        <path
          d="M75.5 63.25V87.75"
          stroke={theme.palette.common.white}
          strokeWidth="7"
          strokeLinecap="round"
        />
      </RenderIf>
    </svg>
  );
};

export default SessionIcon;
