import { theme } from '@/theme';
import { styled } from '@mui/material';

interface CircleProps {
  size: number;
  color: string;
  textColor: string;
}

const getColor = (color: string) => {
  let resultColor = color;
  if (color.toUpperCase() === 'SUCCESS') {
    resultColor = theme.palette.success.light;
  } else if (color.toUpperCase() === 'ERROR') {
    resultColor = theme.palette.error.main;
  } else if (color.toUpperCase() === 'SECONDARY') {
    resultColor = theme.palette.grey[500];
  } else if (color.toUpperCase() === 'INFO') {
    resultColor = theme.palette.info.main;
  } else if (color.toUpperCase() === 'WARNING') {
    resultColor = theme.palette.warning.main;
  }
  return resultColor;
};

export const CircleContainer = styled('div')<CircleProps>`
  min-width: ${({ size }) => size}px;
  padding: 0 10px;
  min-height: ${({ size }) => size}px;
  border-radius: 16px;
  background-color: ${({ color }) => getColor(color)};
  color: ${({ textColor }) => textColor};
  font-size: ${({ size }) => size}px;
  font-weight: 500;
`;
