import { RenderIf } from '@/components/atoms';
import { FormHelperTextError } from '@/components/molecules/Card/Card.styles';
import { CrudModes } from '@/const';
import {
  ActionTypes,
  ScaleConstraints,
  SelectedSession,
  ZOOM_VALUES,
  useCurriculumSetupContext
} from '@/context/CurriculmSetupContext';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useConfirmDeletion } from '@/hooks/useConfirmDeletion';
import {
  ContinuityClasses,
  CurriculumForm,
  GeneralContinuity,
  Session,
  SessionContinuity,
  Topic,
  Type
} from '@/types';
import { Menu, Widget } from '@bryntum/core-thin';
import { cloneDeep } from 'lodash';
import {
  KeyboardEvent,
  MouseEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ContinuityPanel from '../CurriculumSidePanel/components/Continuities/ContinuityPanel/ContinuityPanel';
import { DragNDropComponents, TabOptions } from './CurriculumSetup.const';
import { BoardWrapper, Container, SmallModalStyle } from './CurriculumSetup.styles';
import { DragLayer } from './components/DragLayer';
import { SessionDropLayer } from './components/SessionDropLayer';
import { SessionWrapper } from './components/SessionWrapper';
import { ToolBar } from './components/ToolBar';

export type CurriculumSetupProps = {
  title?: string;
};

const CurriculumSetup = () => {
  const { t: tCommon } = useTranslation('common');
  const { t: tCurr } = useTranslation('organisms/curriculumSetup');
  const { t: tCurrTemplate } = useTranslation('templates/curriculumsTemplate');
  const {
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext<CurriculumForm>();
  const { initialize, handleClose, handleDirtyModal } = useGenericModalContext();
  const {
    fields: sessions,
    insert,
    append
  } = useFieldArray({
    control,
    name: 'structure.sessions',
    keyName: 'customId'
  });

  const {
    stopScrolling,
    onMouseMove,
    selectedTopics,
    selectedSessions,
    setSelectedContinuities,
    handleSelectedSessions,
    handleSelectedTopics,
    setCurrentTab,
    getUniqueKey,
    setSelectedType,
    readOnly,
    selectAllSessions,
    position,
    createNewElement,
    resetSelectedElements,
    setPosition
  } = useCurriculumSetupContext();
  const { handleConfirmation } = useConfirmDeletion();

  const scale = useWatch({
    control,
    name: 'viewSettings.scale',
    defaultValue: ScaleConstraints.MIN
  });

  const handleScale = (addition: number) => {
    const currentIndex = ZOOM_VALUES.findIndex((value) => value === scale);
    if (currentIndex !== -1) {
      const newValue = ZOOM_VALUES[currentIndex + addition];
      setValue('viewSettings.scale', newValue, { shouldDirty: true });
    } else setValue('viewSettings.scale', ScaleConstraints.MIN, { shouldDirty: true });
  };

  const validateContinuities = (sessions: Session[]) => {
    const continuities = getValues('structure.continuities') || [];
    const validContinuities = continuities.reduce(
      (acc, continuity) => {
        if (continuity.continuityClass === ContinuityClasses.TOP_LEVEL) return [...acc, continuity];

        if (continuity.continuityClass === ContinuityClasses.SESSION) {
          const referencedSessionId = (continuity as SessionContinuity).sessionId;
          if (sessions.some((session) => session.id === referencedSessionId)) {
            return [...acc, continuity];
          }
          return acc;
        }

        const resourceSlotIds = (continuity as GeneralContinuity).topicResourceSlotIds;
        const topicIds = Object.keys(resourceSlotIds);
        const remainingTopicIds = topicIds
          .filter((topicId) => {
            return sessions.some((session) => session.topics.some((topic) => topic.id == topicId));
          })
          .reduce(
            (acc, topicId) => ({
              ...acc,
              [topicId]: resourceSlotIds[topicId]
            }),
            {} as Record<string, string>
          );
        const updatedContinuity = { ...continuity, topicResourceSlotIds: remainingTopicIds };
        if (Object.keys(updatedContinuity.topicResourceSlotIds).length >= 2) {
          return [...acc, updatedContinuity];
        } else {
          return acc;
        }
      },
      [] as typeof continuities
    );
    return {
      validContinuities,
      invalidContinuities: continuities.length - validContinuities.length
    };
  };

  const setSessions = (sessions: Session[]) => {
    setValue('structure.sessions', sessions, { shouldDirty: true });
  };

  const handleSessionSelection = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, column: Session, actualIndex: number) => {
      event.preventDefault();
      event.stopPropagation();
      if (event && column) {
        handleSelectedTopics({ type: ActionTypes.DELETE_ALL });
        if (navigator.userAgent.includes('Mac') ? event.metaKey : event.ctrlKey) {
          if (selectedSessions.map(({ id }) => id).includes(column.id)) {
            handleSelectedSessions({
              selection: { id: column.id, index: actualIndex },
              type: ActionTypes.DELETE
            });
          } else {
            handleSelectedSessions({
              selection: { id: column.id, index: actualIndex },
              type: ActionTypes.ADD
            });
          }
        } else {
          handleSelectedSessions({
            selection: [{ id: column.id, index: actualIndex }],
            type: ActionTypes.REPLACE
          });
        }
      }
    },
    [selectedSessions]
  );

  //TOOLBAR actions :

  const duplicateSelection = useCallback(() => {
    if (selectedTopics && selectedTopics.length > 0) {
      const newSessions = cloneDeep(getValues('structure.sessions'));
      const uniqueIdxs = Array.from(
        new Set(selectedTopics.map((selected) => selected.sourceSession.index))
      );
      handleSelectedTopics({
        type: ActionTypes.DELETE_ALL
      });
      selectedTopics.forEach(({ id, sourceSession }) => {
        const sessionIndex = newSessions.findIndex((column) => column.id === sourceSession.id);
        if (sessionIndex !== -1) {
          const topicIndex = newSessions[sessionIndex].topics.findIndex(
            (sourceItem) => sourceItem.id === id
          );
          if (topicIndex !== -1) {
            const session = newSessions[sessionIndex];
            const clonItem: Topic = {
              ...newSessions[sessionIndex].topics[topicIndex],
              id: 'T' + getUniqueKey().toString(),
              name: tCommon('text.copyOf', {
                name: newSessions[sessionIndex].topics[topicIndex].name
              })
            };
            handleSelectedTopics({
              type: ActionTypes.ADD,
              selection: {
                id: clonItem.id,
                index: topicIndex + 1,
                sourceSession: {
                  id: session.id,
                  index: sessionIndex
                }
              }
            });
            newSessions[sessionIndex].topics.splice(topicIndex + 1, 0, clonItem);
          }
        }
      });
      uniqueIdxs.forEach((idx) => {
        setValue(`structure.sessions.${idx}.topics`, newSessions[idx].topics, {
          shouldDirty: true
        });
      });
    }
    if (selectedSessions && selectedSessions.length > 0) {
      const newSessions = getValues('structure.sessions');
      handleSelectedSessions({
        type: ActionTypes.DELETE_ALL
      });
      selectedSessions
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .forEach((session, idx) => {
          const newSession = newSessions.find((newSession) => newSession.id === session.id);
          const newSessionId = 'S' + getUniqueKey().toString();
          const index = session.index + 1 + idx;
          const continuities = getValues('structure.continuities')
            .filter(
              (continuity) =>
                continuity.continuityClass === ContinuityClasses.SESSION &&
                (continuity as SessionContinuity).sessionId === session.id
            )
            .map((continuity) => ({
              ...continuity,
              sessionId: newSessionId,
              id: 'SC' + getUniqueKey().toString()
            }));

          const topics =
            newSession?.topics.map((topic) => ({
              ...topic,
              id: 'T' + getUniqueKey().toString()
            })) || [];
          setValue(
            'structure.continuities',
            [...continuities, ...getValues('structure.continuities')],
            { shouldDirty: true }
          );
          if (newSession)
            insert(index, {
              ...newSession,
              id: newSessionId,
              name: tCommon('text.copyOf', { name: newSession.name }),
              topics
            });
          handleSelectedSessions({
            selection: { id: newSessionId, index },
            type: ActionTypes.ADD
          });
        });
    }
  }, [selectedTopics, selectedSessions]);

  const deleteSelection = useCallback(async () => {
    if (selectedTopics && selectedTopics.length > 0) {
      const newSessions = cloneDeep(getValues('structure.sessions'));

      const uniqueIdxs = Array.from(
        new Set(selectedTopics.map((selected) => selected.sourceSession.index))
      );
      selectedTopics
        .sort((a, b) => a.sourceSession.index - b.sourceSession.index)
        .forEach(({ id, sourceSession }) => {
          const sessionIndex = newSessions.findIndex((column) => column.id === sourceSession.id);
          if (sessionIndex !== -1) {
            const topicIndex = newSessions[sessionIndex].topics.findIndex(
              (sourceItem) => sourceItem.id === id
            );
            if (topicIndex !== -1) {
              newSessions[sessionIndex].topics.splice(topicIndex, 1);
            }
          }
        });
      const { validContinuities, invalidContinuities } = validateContinuities(newSessions);

      const body = tCommon('modalConfirmationDelete.message', {
        count: selectedTopics.length,
        element: tCurr('elements.topics', {
          count: selectedTopics.length
        })
      });
      const note =
        invalidContinuities > 0
          ? tCommon('modalConfirmationDelete.warningDeletion', {
              counter: invalidContinuities,
              name: tCurrTemplate('elements.continuities', { count: invalidContinuities })
            })
          : '';
      const result = await handleConfirmation(body, note);
      if (!result) return;
      setValue('structure.continuities', validContinuities);
      uniqueIdxs.forEach((idx) => {
        setValue(`structure.sessions.${idx}.topics`, newSessions[idx].topics, {
          shouldDirty: true
        });
        if (getValues(`structure.sessions.${idx}.topics`).length === 0)
          setError(`structure.sessions.${idx}.id`, { type: 'noTopics' });
      });
      setSelectedType(TabOptions.CURRICULUM);
    }
    if (selectedSessions && selectedSessions.length > 0) {
      const newSessions = cloneDeep(getValues('structure.sessions'));
      selectedSessions.forEach((selectedSession) => {
        const sessionIndex = newSessions.findIndex((column) => column.id === selectedSession.id);
        if (sessionIndex !== -1) {
          newSessions.splice(sessionIndex, 1);
        }
      });
      const { validContinuities, invalidContinuities } = validateContinuities(newSessions);

      const body = tCommon('modalConfirmationDelete.message', {
        count: selectedSessions.length,
        element: tCurr('elements.sessions', { count: selectedSessions.length }),
        number: selectedSessions.length
      });

      const note =
        invalidContinuities > 0
          ? tCommon('modalConfirmationDelete.warningDeletion', {
              counter: invalidContinuities,
              name: tCurrTemplate('elements.continuities', { count: invalidContinuities })
            })
          : '';
      const result = await handleConfirmation(body, note);
      if (!result) return;
      selectedSessions.forEach((selectedSession) => {
        const sessionIndex = getValues('structure.sessions').findIndex(
          (column) => column.id === selectedSession.id
        );
        clearErrors(`structure.sessions.${sessionIndex}`);
      });
      setValue('structure.continuities', validContinuities, { shouldDirty: true });
      setSessions(newSessions);
      setSelectedType(TabOptions.CURRICULUM);
    }
  }, [selectedTopics, selectedSessions]);

  const menu = useMemo(() => {
    const elementName =
      selectedTopics.length > 0 || selectedSessions.length > 0
        ? selectedTopics.length > 0
          ? tCurr('elements.topics', { count: selectedTopics.length })
          : tCurr('elements.sessions', { count: selectedSessions.length })
        : '';

    const sessionId = selectedSessions[0]?.id;
    const topicResourceSlots = getValues('structure.sessions')
      .filter((session) => session.id === sessionId)
      .map((session) =>
        session.topics.map((topic) => topic.resourceSlots.map((resourceSlot) => resourceSlot.id))
      )
      .flat(2);
    const resourceSlots = getValues('structure.resourceSlots')?.filter((resourceSlot) =>
      topicResourceSlots.includes(resourceSlot.id)
    );

    return new Menu({
      autoShow: false,
      x: position.x,
      y: position.y,
      items: [
        {
          text: tCommon('actions.duplicate', {
            element: elementName
          }),
          hidden: (selectedTopics.length === 0 && selectedSessions.length === 0) || readOnly,
          icon: 'b-icon b-fa-copy',
          onItem: (event) => {
            duplicateSelection();
          }
        },
        {
          text: `${tCommon('actions.deleteElement', { element: elementName })} `,
          hidden: (selectedTopics.length === 0 && selectedSessions.length === 0) || readOnly,
          icon: 'b-icon b-fa-trash',
          onItem: (event) => {
            deleteSelection();
          }
        },

        {
          text: tCurrTemplate('expandOptions.createContinuity'),
          hidden: selectedSessions.length !== 1 || readOnly,
          icon: 'b-icon b-fa-link',
          menu: resourceSlots
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((resourceSlot) => ({
              text: tCommon('actions.menu', { text: resourceSlot.name }),
              icon: 'b-icon b-fa-link',
              onItem: (event) => {
                initialize(
                  <ContinuityPanel<SessionContinuity>
                    mode={CrudModes.CREATE}
                    continuityClass={ContinuityClasses.SESSION}
                    resourceSlots={getValues('structure.resourceSlots') || []}
                    sessions={getValues('structure.sessions')}
                    onCancel={(isDirty) => {
                      handleClose(isDirty, tCurrTemplate('elements.continuities_one'));
                    }}
                    onDirtyFields={(isDirty) =>
                      handleDirtyModal(isDirty, tCurrTemplate('elements.continuities_one'))
                    }
                    onSuccess={(continuity) => {
                      setValue(
                        'structure.continuities',
                        [continuity, ...getValues('structure.continuities')],
                        {
                          shouldDirty: true
                        }
                      );
                      setSelectedContinuities([continuity.id]);
                      setCurrentTab('3');
                      handleClose(false);
                    }}
                    defaultValues={{
                      id: 'SC' + getUniqueKey().toString(),
                      sessionId: selectedSessions[0].id,
                      resourceSlotId: resourceSlot.id,
                      type: Type.R,
                      continuityClass: ContinuityClasses.SESSION
                    }}
                  />,
                  {
                    style: SmallModalStyle,
                    modalName: tCurrTemplate('elements.continuities_one')
                  }
                );
              }
            }))
        },
        {
          text: `${tCommon('actions.clearSelection')}`,
          icon: 'b-icon b-fa-eraser',
          cls: 'b-separator',
          onItem: async (ev) => {
            resetSelectedElements();
            setSelectedType(TabOptions.CURRICULUM);
          }
        }
      ] as unknown as Widget[] //TODO: Fix the Widget problem
    });
  }, [selectedTopics, selectedSessions, position]);

  useEffect(() => {
    position.x !== -1 && position.y !== -1 && menu.showBy([position.x, position.y]);
  }, [position]);

  const moveSession = useCallback(
    (sourceIndex: number, destinyIndex: number) => {
      const sessions = getValues('structure.sessions');
      const notSelectedSessions = sessions.filter(
        (session) => !selectedSessions.some((selectedSession) => selectedSession.id === session.id)
      );
      const mappedSessions = selectedSessions.map((selectedSession) => {
        const session = sessions.find((session) => session.id === selectedSession.id);
        return session || ({} as Session);
      });
      const first = notSelectedSessions.slice(0, destinyIndex);
      const last = notSelectedSessions.slice(destinyIndex);
      const newOrderedSessions = [...first, ...mappedSessions, ...last];
      const newOrderedSelection = mappedSessions.map(({ id }) => {
        const sessionIndex = newOrderedSessions.findIndex((newSession) => newSession?.id === id);
        return { id, index: sessionIndex } as SelectedSession;
      });
      setSessions(newOrderedSessions);
      handleSelectedTopics({ type: ActionTypes.DELETE_ALL });
      handleSelectedSessions({ type: ActionTypes.REPLACE, selection: newOrderedSelection });
      setSelectedType(TabOptions.SESSION);
    },
    [selectedSessions]
  );

  const addNewSession = useCallback(
    (
      type: DragNDropComponents.NEW_SESION | DragNDropComponents.NEW_TIME_SESSION,
      destinyIndex?: number
    ) => {
      const newSessions = getValues('structure.sessions');
      const newSession: Session = createNewElement(type) as Session;
      newSession.id = 'S' + getUniqueKey();
      if (destinyIndex !== undefined) {
        insert(destinyIndex, newSession);
        // setError(`structure.sessions.${destinyIndex}.id`, { type: 'noTopics' });
      } else {
        append(newSession);
        // setError(`structure.sessions.${newSessions.length}.id`, { type: 'noTopics' });
      }

      handleSelectedTopics({ type: ActionTypes.DELETE_ALL });
      handleSelectedSessions({
        selection: [
          {
            id: newSession.id,
            index: destinyIndex !== undefined ? destinyIndex : newSessions.length
          }
        ],
        type: ActionTypes.REPLACE
      });
      setSelectedType(TabOptions.SESSION);
    },
    []
  );
  const board = useRef(null);

  const handleBoardClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (navigator.userAgent.includes('Mac') ? event.metaKey : event.ctrlKey) return;
    setSelectedType(TabOptions.CURRICULUM);
    resetSelectedElements();
  };
  return (
    <Container
      onMouseMove={onMouseMove}
      onMouseUp={() => {
        stopScrolling(setValue);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        resetSelectedElements();
        setSelectedType(TabOptions.CURRICULUM);
        setPosition({ x: e.clientX, y: e.clientY });
      }}
      tabIndex={0}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key == 'Delete' || event.key == 'Backspace') {
          if (selectedTopics.length > 0 || selectedSessions.length > 0) deleteSelection();
        }
        if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
          event.preventDefault();
          setSelectedType(TabOptions.SESSION);
          selectAllSessions(getValues('structure.sessions'));
        }
      }}>
      <ToolBar
        scale={scale || ScaleConstraints.MIN}
        handleScale={handleScale}
        onDuplicate={duplicateSelection}
        onDelete={deleteSelection}
        sessionsCount={sessions.length}
      />
      <BoardWrapper onMouseDown={handleBoardClick} ref={board}>
        <RenderIf condition={sessions.length === 0}>
          <FormHelperTextError sx={{ position: 'absolute', top: '1rem', left: '1rem' }}>
            {tCurr('errors.noSessions')}
          </FormHelperTextError>
        </RenderIf>
        <DragLayer scale={scale || ScaleConstraints.MIN} />
        {sessions?.map((column, columnIdx) => {
          return (
            <SessionWrapper
              onSelect={(event) => {
                event.stopPropagation();
                if (menu.isVisible) menu.hide();
                handleSessionSelection(event, column, columnIdx);
                setSelectedType(TabOptions.SESSION);
              }}
              scale={scale || ScaleConstraints.MIN}
              key={column.customId}
              index={columnIdx}
              moveSession={moveSession}
              addNewSession={addNewSession}
            />
          );
        })}
        <SessionDropLayer moveSession={moveSession} addNewSession={addNewSession} />
      </BoardWrapper>
    </Container>
  );
};

export default memo(CurriculumSetup);
