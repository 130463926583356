import { DATETIME_FORMAT_PICKER, TIME_FORMAT_PICKER, locale } from '@/const';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';

export const formatDateSimple = (inputDateStr: string, t: TFunction): string => {
  const inputDate = DateTime.fromJSDate(new Date(inputDateStr)).setLocale(locale);
  const currentDate = DateTime.now();
  const diffInDays = currentDate.startOf('day').diff(inputDate.startOf('day'), 'days').days;
  if (diffInDays === 0) {
    return `${t('at')} ${inputDate.toFormat(TIME_FORMAT_PICKER as string)}`;
  }
  if (diffInDays === 1) {
    return `${t('yesterday')} ${t('at')} ${inputDate.toFormat(TIME_FORMAT_PICKER as string)}`;
  }
  return `${t('at')} ${inputDate.toFormat(DATETIME_FORMAT_PICKER)}`;
};

export const dateDiffInSeconds = (date1Str: string, date2Str: string): number => {
  const date1 = new Date(date1Str).getTime();
  const date2 = new Date(date2Str).getTime();

  return Math.abs(date2 - date1) / 1000;
};

export const dateDiffInDays = (_startDate: string, _endDate: string): number => {
  const startDate = DateTime.fromISO(_startDate);
  const endDate = DateTime.fromISO(_endDate);

  const diff = endDate.diff(startDate, 'days').days;
  return Math.abs(diff);
};

export const calculateNewHour = (
  startTime: string | DateTime,
  endTime: string | DateTime,
  newTime: string | DateTime,
  format = 'HH:mm'
): string => {
  // Parse time strings into Luxon DateTime objects if neccesary
  const startDatetime =
    typeof startTime !== 'string' ? startTime : DateTime.fromFormat(startTime, format);
  const endDatetime = typeof endTime !== 'string' ? endTime : DateTime.fromFormat(endTime, format);
  const newDatetime = typeof newTime !== 'string' ? newTime : DateTime.fromFormat(newTime, format);

  const timeDiff = endDatetime.diff(startDatetime);
  const resultDatetime = newDatetime.plus(timeDiff);
  // Format the result as format
  const result_time = resultDatetime.toFormat(format);

  return result_time;
};
/**
 * Returns true if timeA is greater than timeB
 * @param timeA - Time in format HH:MM
 * @param timeB - Time in format HH:MM
 * @returns boolean
 * @throws Error if timeA or timeB are not in format HH:MM
 * @example
 * isTimeGreater('10:00', '09:00') // true
 * isTimeGreater('10:00', '10:00') // false
 */
export const isTimeGreater = (timeA: string, timeB: string): boolean => {
  const timePattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  if (!timePattern.test(timeA) || !timePattern.test(timeB)) {
    throw new Error('Bad format HH:MM');
  }

  const [hoursA, minutesA] = timeA.split(':').map(Number);
  const [hoursB, minutesB] = timeB.split(':').map(Number);

  if (hoursA > hoursB) {
    return true;
  } else if (hoursA === hoursB) {
    if (minutesA > minutesB) {
      return true;
    }
  }

  return false;
};
