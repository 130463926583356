import { EventDto } from '@/types';
import { createContext } from 'react';

export type CalendarContextType = {
  events: EventDto[] | undefined;
  addEvents: (newEvents: EventDto[]) => void;
  updateEvent: (newEvent: EventDto, id: string) => void;
  removeEvent: (ids: string) => void;
  handleEvents: (newEvent) => void;
};

export default createContext<CalendarContextType>({} as CalendarContextType);
