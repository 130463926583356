import { theme } from '@/theme';
import { SxProps, Theme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { RenderIf } from '../RenderIf';
import { Fieldset, Label, Legend, Root } from './InputBox.styles';

export type InputBoxProps = PropsWithChildren<{
  error?: boolean;
  label?: string | React.ReactNode;
  disabled?: boolean;
  name?: string;
  sx?: SxProps<Theme>;
  id?: string;
}>;

const InputBox = ({ children, error, label, disabled = false, sx, id, name }: InputBoxProps) => {
  return (
    <Root className="root" sx={sx}>
      <RenderIf condition={!!label}>
        <Label disabled={disabled} error={error}>
          {label}
        </Label>
      </RenderIf>
      {children}
      <Fieldset
        id={id}
        name={name}
        disabled={disabled}
        label={label}
        sx={{ borderColor: error ? `${theme.palette.error.light} !important` : '' }}>
        <RenderIf condition={!!label}>
          <Legend>{label}</Legend>
        </RenderIf>
      </Fieldset>
    </Root>
  );
};

export default InputBox;
