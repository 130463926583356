import { PresetModes } from '@/components/templates/PropertyPresetPanelTemplate/PropertyPresetPanelTemplate.const';
import { Entities, SYSTEM_SETTINGS_BY_ID, SYSTEM_SETTINGS_SEARCH } from '@/const';
import { objectHasValues } from '@/services/utils/utils';
import { PresetDto, PresetPayload } from '@/types';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useFetch } from './useFetch';

const useGetDefaultPresetByEntity = (entity: Entities, condition: boolean, entityType?: string) => {
  const [defaultValues, setDefaultValues] = useState<{ data: PresetPayload; type: PresetModes }>();
  const { data, fetch, error } = useFetch<PresetDto | PresetDto[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    data: dataEntityType,
    fetch: fetchEntityType,
    error: errorEntityType
  } = useFetch<PresetDto | PresetDto[]>();
  // const [defaultApplicated, setDefaultApplicated] = useState<boolean>(false);
  const [entityTypeFetched, setEntityTypeFetched] = useState<boolean>(false);

  useEffect(() => {
    if (entity && condition) {
      fetch({
        url: SYSTEM_SETTINGS_SEARCH,
        method: 'POST',
        data: {
          type: 'preset_entity_values',
          name: 'default',
          'reference.entity': entity
        }
      });
    }
  }, []);

  const changeDefaultValues = (presetId: string) => {
    fetch({
      url: SYSTEM_SETTINGS_BY_ID.replace(':id', presetId)
    });
  };

  const getPresetType = (data: PresetDto) => {
    if (data.name === PresetModes.DEFAULT) {
      return PresetModes.DEFAULT;
    } else if (data.reference.type) {
      return PresetModes.ENTITY;
    }
    return PresetModes.CUSTOM;
  };

  useEffect(() => {
    if (data) {
      if (entityType && !entityTypeFetched) {
        fetchEntityType({
          url: SYSTEM_SETTINGS_SEARCH,
          method: 'POST',
          data: {
            type: 'preset_entity_values',
            'reference.entity': entity,
            'reference.type._id': entityType
          }
        });
        setEntityTypeFetched(true);
      }
      if (isArray(data) && data.length > 0)
        setDefaultValues({ type: getPresetType(data[0]), data: data[0].payload });
      if (!isArray(data) && objectHasValues(data)) {
        setDefaultValues({ type: getPresetType(data), data: data.payload });
      }
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (dataEntityType) {
      if (isArray(dataEntityType) && dataEntityType.length > 0)
        setDefaultValues({ type: PresetModes.ENTITY, data: dataEntityType[0].payload });
      if (!isArray(dataEntityType)) {
        setDefaultValues({ type: PresetModes.ENTITY, data: dataEntityType.payload });
      }
      setLoading(false);
    }
  }, [dataEntityType]);

  return {
    defaultValues,
    changeDefaultValues,
    loadingDefaultValues: loading,
    errorDefaultValues: error || errorEntityType
  };
};

export default useGetDefaultPresetByEntity;
