import { SETS_FIND_BY_NAME } from '@/const';
import { useGet } from '@/hooks';
import { ListRow, TextField } from '@/theme';
import { PossibleValues, SetDto, SetItemDto, isMultipleInputValue } from '@/types';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  TextFieldProps,
  Typography
} from '@mui/material';
import { isArray } from 'lodash';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SetAutocompleteProps = {
  multiple?: boolean;
  referencedSet: string;
  URIReplace?: (referencedSet: string) => string;
  onChange: (value: SetVal | SetVal[] | null) => void;
  inputRef?: RefObject<HTMLInputElement>;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
} & CustomSet &
  (OneSetProps | MultiSetProps) &
  Partial<Omit<TextFieldProps, 'value'>>;

export type SetVal = Pick<SetItemDto, '_id' | 'label'>;

type OneSetProps = {
  multiple?: false;
} & PossibleValues<SetVal | null>;

type CustomSet = {
  URIReplace: (referencedSet: string) => string;
  getOptions: (response: any) => SetVal[];
};

type MultiSetProps = {
  multiple: true;
} & PossibleValues<SetVal[] | null>;

const SetAutocomplete = ({
  multiple = false,
  value,
  id,
  name,
  autoComplete,
  includeMultipleObj = false,
  label,
  referencedSet,
  URIReplace,
  getOptions = (response: SetDto) => response.items || [],
  onChange,
  inputRef,
  error,
  disabled = false,
  helperText,
  readOnly = false
}: SetAutocompleteProps) => {
  const [selectedValue, setSelectedValue] = useState<SetVal | SetVal[] | null>(null);
  useEffect(() => {
    if (value && !isMultipleInputValue(value)) {
      setSelectedValue(value);
    } else {
      setSelectedValue(null);
    }
  }, [value, multiple]);
  const { t } = useTranslation('common');
  const {
    data: fetchedOptions,
    loading: loadingOptions,
    error: errorOptions
  } = useGet<SetDto>(
    {
      url: URIReplace
        ? URIReplace(referencedSet)
        : SETS_FIND_BY_NAME.replace(':setName', encodeURIComponent(referencedSet))
    },
    { revalidateOnMount: true }
  );

  const renderOptions = useMemo(() => {
    return fetchedOptions ? getOptions(fetchedOptions) : [];
  }, [fetchedOptions]);

  return (
    <Autocomplete
      fullWidth
      readOnly={readOnly}
      isOptionEqualToValue={(option, value) => {
        if (option && value) {
          if (isArray(option) && isArray(value)) {
            const multiOpts = (option as SetVal[]).map((opt) => opt._id);
            const multiVal = value as SetVal[];
            return multiVal?.every((val) => multiOpts.includes(val._id));
          } else {
            const singleOpts = option as SetVal;
            const singleVal = value as SetVal;
            return singleVal._id === singleOpts._id;
          }
        }
        return false;
      }}
      disableClearable={includeMultipleObj}
      multiple={multiple}
      disabled={!!errorOptions || disabled}
      options={renderOptions}
      loading={loadingOptions}
      onInputChange={(event, textValue, reason) => {
        if (
          includeMultipleObj &&
          textValue === '' &&
          !isMultipleInputValue(value) &&
          value !== null &&
          value !== undefined
        ) {
          onChange(null);
        }
      }}
      noOptionsText={<Typography sx={{ lineHeight: '1rem' }}>{t('text.emptyOptions')}</Typography>}
      renderOption={(props, option) => {
        if (isArray(option)) {
          return option.map((option) => {
            return (
              <ListRow {...props} key={option._id}>
                {option.label}
              </ListRow>
            );
          });
        } else {
          return (
            <ListRow {...props} key={option._id}>
              {option.label}
            </ListRow>
          );
        }
      }}
      renderTags={(value, getTagProps) =>
        value?.map((option, index) => {
          if (isArray(option)) {
            return option.map((opt) => (
              <Chip {...getTagProps({ index })} key={opt._id} label={opt.label} size="small" />
            ));
          } else {
            return (
              <Chip
                {...getTagProps({ index })}
                key={option._id}
                label={option.label}
                size="small"
              />
            );
          }
        })
      }
      loadingText={
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center'
          }}>
          <CircularProgress size="1rem" />
        </Box>
      }
      getOptionLabel={(option) => (option as SetVal).label ?? ''}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            errorOptions
              ? t('errors.setNameError', {
                  setName: referencedSet
                })
              : includeMultipleObj && isMultipleInputValue(value)
              ? t('text.multipleValues')
              : ''
          }
          size="small"
          label={label ?? ''}
          error={error}
          inputRef={inputRef}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            ...params.inputProps,
            id,
            name,
            autoComplete
          }}
          helperText={helperText}
        />
      )}
      value={multiple ? selectedValue ?? [] : selectedValue}
      onChange={(_, newValue) => {
        onChange(newValue as SetVal | SetVal[] | null);
      }}
    />
  );
};

export default SetAutocomplete;
