import { PropsWithChildren, useState } from 'react';
import ToastContext, { ToastType } from './ToastContext';

const ToastContextProvider = ({ children }: PropsWithChildren) => {
  const [toastNotifications, setToastNotifications] = useState<ToastType[]>([]);

  return (
    <ToastContext.Provider value={{ toastNotifications, setToastNotifications }}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
