import { Box, styled } from '@mui/material';

export const SummaryContainer = styled('div')`
  flex: 2 0 auto;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-around;
`;

export const Summary = styled(Box)`
  padding: 1rem 0.5rem;
  ul {
    margin: 0;
    li {
      margin-bottom: 12px;
    }
  }

  td {
    padding: 0.6em;
  }
  td.value {
    text-align: right;
  }
`;
