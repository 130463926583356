import { Clock } from '@/components/atoms';
import { TimeStrategy } from '@/types';
import { DateTime } from 'luxon';
import { BsViewList } from 'react-icons/bs';
import { IconHeaderContainer } from './SessionWrapper.styles';

export const formatDate = (date: string | DateTime) => {
  if (typeof date === 'string') {
    return date;
  }
  return date.toFormat('HH:mm');
};

export const getKeyDay = (minDaysBefore?: number, maxDaysBefore?: number) => {
  if (minDaysBefore && maxDaysBefore) {
    if (minDaysBefore === maxDaysBefore) {
      return 'minDaysBefore';
    }
    return `minToMax`;
  }
  if (minDaysBefore) {
    return `minDaysBefore`;
  }
  if (maxDaysBefore) {
    return `maxDaysBefore`;
  }
  return 'empty';
};

export enum DROP_SIDES {
  top,
  bot,
  left,
  right
}

export const IconHeader = ({ timeStrategy }: { timeStrategy: TimeStrategy }) => {
  return (
    <IconHeaderContainer>
      {timeStrategy === TimeStrategy.START_TIMES ? (
        <Clock
          size={20}
          color={'#6d6d6d'}
          style={{
            backgroundColor: 'white'
          }}></Clock>
      ) : (
        <BsViewList size={20} color={'#6d6d6d'} />
      )}
    </IconHeaderContainer>
  );
};
