import { theme } from '@/theme';
import { Box, Button, CircularProgress, Tooltip, Typography } from '@mui/material';

type SwitchProps = {
  trueLabel: string;
  falseLabel: string;
  status: boolean;
  trueTooltip?: string;
  falseTooltip?: string;
  size?: 'big' | 'small';
  variantColor?: 'white' | 'grey';
  loading?: boolean;
  handleChange: (status: boolean) => void;
  readOnly?: boolean;
};

const Switch = ({
  trueLabel,
  falseLabel,
  status,
  trueTooltip,
  falseTooltip,
  handleChange,
  loading,
  size = 'big',
  variantColor = 'white'
}: SwitchProps) => {
  return (
    <>
      {!loading && (
        <Box
          onClick={() => handleChange(!status)}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            height: size == 'big' ? '30px' : '20px',
            borderRadius: '35px',
            backgroundColor:
              variantColor == 'white' ? theme.palette.common.white : theme.palette.grey[300],
            width: '60px',
            flexShrink: 0
          }}>
          <Box
            sx={{
              borderRadius: '35px',
              backgroundColor: theme.palette.primary.main,
              position: 'absolute',
              transition: 'all 0.2s ease-in-out',
              width: '50%',
              height: '100%'
            }}
            style={{ ...(status ? { left: '0px' } : { left: '50%' }) }}
          />
          <Tooltip title={trueTooltip} placement="bottom">
            <Button
              sx={{
                color: theme.palette.common.white,
                border: 'none',
                marginRight: ' auto',
                minWidth: '50%',
                borderRadius: '50%',
                ...(!status && {
                  color: theme.palette.primary.main,
                  backgroundColor:
                    variantColor == 'white' ? theme.palette.common.white : theme.palette.grey[300]
                })
              }}>
              <Typography variant={size == 'big' ? 'body2' : 'caption'}>{trueLabel}</Typography>
            </Button>
          </Tooltip>
          <Tooltip title={falseTooltip} placement="bottom">
            <Button
              sx={{
                color: theme.palette.common.white,
                border: 'none',
                marginLeft: 'auto',
                minWidth: '50%',
                borderRadius: '50%',
                ...(status && {
                  color: theme.palette.primary.main,
                  backgroundColor:
                    variantColor == 'white' ? theme.palette.common.white : theme.palette.grey[300]
                })
              }}>
              <Typography variant={size == 'big' ? 'body2' : 'caption'}>{falseLabel}</Typography>
            </Button>
          </Tooltip>
        </Box>
      )}
      {loading && <CircularProgress size={20} style={{ marginRight: 8 }} color="primary" />}
    </>
  );
};

export default Switch;
