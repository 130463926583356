import { RenderIf } from '@/components/atoms';
import { useMenu } from '@/hooks';
import { TextField } from '@/theme';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Popover,
  Tooltip
} from '@mui/material';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSettings, ButtonSkeleton, Header, Title } from '../GlobalFilter.styles';
import { GlobalFilterHeaderProps } from '../GlobalFilter.types';

const GlobalFilterHeader = ({
  title,
  error,
  fields,
  loading,
  onClear,
  selected,
  onSelected,
  filterRef,
  ...props
}: GlobalFilterHeaderProps) => {
  const { t } = useTranslation('organisms/globalFilter');
  const [filter, setFilter] = useState<string>('');
  const [anchorEl, openSettings, closeSettings] = useMenu();

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFilter(e.target.value);
  };

  return (
    <>
      <Header {...props}>
        <Title>{title}</Title>
        <RenderIf condition={loading}>
          <ButtonSkeleton variant="circular" />
          <ButtonSkeleton variant="circular" />
          <ButtonSkeleton variant="circular" />
        </RenderIf>
        <RenderIf condition={!loading && !error}>
          <Tooltip title={t('button-success')}>
            <ButtonSettings
              style={{ visibility: 'hidden' }}
              ref={filterRef}
              type="submit"
              onClick={(e) => e.stopPropagation()}>
              <i className="b-icon b-fa-paper-plane" />
            </ButtonSettings>
          </Tooltip>
          <Tooltip title={t('button-clean')}>
            <ButtonSettings
              onClick={(e) => {
                e.stopPropagation();
                onClear(e);
              }}
              type="button">
              <i className="b-icon b-fa-eraser" />
            </ButtonSettings>
          </Tooltip>
          {fields.length != 0 && (
            <Tooltip title={t('button-settings')}>
              <ButtonSettings
                onClick={(e) => {
                  e.stopPropagation();
                  openSettings(e);
                }}
                type="button">
                <i className="b-icon b-fa-gear" />
              </ButtonSettings>
            </Tooltip>
          )}
        </RenderIf>
      </Header>
      <Popover
        // PaperProps={{ elevation: 0 }}
        open={!!anchorEl && !loading && !error}
        anchorEl={anchorEl}
        onClose={(e: SyntheticEvent) => {
          e.stopPropagation();
          closeSettings();
        }}
        PaperProps={{
          sx: { maxWidth: 300, maxHeight: 300 }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
          <TextField autoFocus fullWidth size="small" onChange={handleFilter} />
          <FormControl>
            <FormGroup>
              {fields
                .filter(({ name }) => name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
                .map((field) => (
                  <FormControlLabel
                    componentsProps={{ typography: { noWrap: true } }}
                    key={field._id}
                    label={field.name}
                    control={
                      <Checkbox
                        size="small"
                        name={field._id}
                        value={field._id}
                        checked={selected?.includes(field._id)}
                        onChange={(e) => {
                          e.stopPropagation();
                          onSelected(e);
                        }}
                      />
                    }
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
};

export default GlobalFilterHeader;
