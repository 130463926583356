import { RenderIf } from '@/components/atoms';
import { IconButton, Tooltip } from '@mui/material';
import { isEqual } from 'lodash';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { RxReset } from 'react-icons/rx';

export type MultipleInputRowProps = PropsWithChildren<{
  initialValue?: any;
  value?: any;
  fitInput?: boolean;
  additionalRestoureRenderConditions?: boolean;
  additionalClearRenderConditions?: boolean;
  onChange: (newValue: any) => unknown;
}>;

const MultipleInputRow = ({
  children,
  initialValue,
  fitInput = false,
  additionalRestoureRenderConditions = true,
  additionalClearRenderConditions = true,
  value,
  onChange
}: MultipleInputRowProps) => {
  const { t: tCommon } = useTranslation();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {children}
      <div
        style={{
          minWidth: !fitInput ? 'fit-content' : '2rem'
        }}>
        {initialValue !== null &&
        initialValue !== undefined &&
        !isEqual(initialValue, value) &&
        additionalRestoureRenderConditions ? (
          <Tooltip title={tCommon('buttons.restore')}>
            <IconButton
              size={'small'}
              sx={{ padding: '0.3rem', ml: '0.25rem' }}
              onClick={() => onChange(initialValue)}>
              <RxReset />
            </IconButton>
          </Tooltip>
        ) : (
          <RenderIf
            condition={value !== undefined && value !== null && additionalClearRenderConditions}>
            <Tooltip title={tCommon('buttons.clear')}>
              <IconButton
                size={'small'}
                sx={{
                  padding: '0.3rem',
                  ml: '0.25rem'
                }}
                onClick={() => {
                  onChange(null);
                }}>
                <MdClose />
              </IconButton>
            </Tooltip>
          </RenderIf>
        )}
      </div>
    </div>
  );
};

export default MultipleInputRow;
