import { Button, styled } from '@mui/material';

export const Circle = styled(Button)`
  height: 2rem;
  min-height: 2rem;
  max-height: 2rem;
  width: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
