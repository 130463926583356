import { isNumber } from 'lodash';
import { TFunction } from 'react-i18next';

export const getErrorCodeOrMessage = (data: any, t?: TFunction, def?: any) => {
  let message = data['message'];
  if (t) {
    const code = data['code'];
    if (code) message = t(code) ?? message;
  }
  if (!message && def) {
    message = def;
  }
  return message;
};

export const greaterThanZero = (value?: number) => {
  return isNumber(value) ? value > 0 : true;
};
