import { EntityRoutes, PropertyTypes } from '@/const';
import { SystemSets } from '@/const/sets';
import { PropertyDto, PropertyValue } from '@/types';
export enum FORM_TYPES {
  SESSION,
  TOPICS,
  RESOURCE_SLOT,
  TOPIC_RESOURCE_SLOT
}

export const makeForm = (formType: FORM_TYPES) => {
  if (formType == FORM_TYPES.TOPIC_RESOURCE_SLOT)
    return [
      {
        name: `joinable`,
        type: PropertyTypes.BOOLEAN,
        required: false
      },
      {
        name: `contentId`,
        type: PropertyTypes.STRING,
        required: false
      }
    ];

  if (formType == FORM_TYPES.TOPICS)
    return [
      {
        name: `name`,
        defaultValue: 'New Topic',
        type: PropertyTypes.STRING,
        required: true
      },

      {
        name: `startTime`,
        type: PropertyTypes.TIME,
        required: false
      },
      {
        name: `duration`,
        type: PropertyTypes.NUMBER,
        defaultValue: 60,
        validations: {
          min: (value: number) => value > 0
        },
        required: true,
        componentProps: {
          decimalScale: 0,
          allowNegative: false
        }
      },
      {
        name: `color`,
        type: PropertyTypes.COLOR,
        required: true
      },
      {
        name: `postSeparation`,
        type: PropertyTypes.NUMBER,
        required: false,
        componentProps: {
          decimalScale: 0,
          allowNegative: false
        }
      }
    ];
  if (formType == FORM_TYPES.RESOURCE_SLOT)
    return [
      {
        name: `name`,
        type: PropertyTypes.STRING,
        required: true
      },
      {
        name: 'quantity',
        type: PropertyTypes.NUMBER,
        required: true,
        default: 1,
        componentProps: {
          decimalScale: 0,
          allowNegative: false
        }
      },
      {
        name: 'role',
        type: PropertyTypes.SET,
        required: true,
        referencedSet: SystemSets.Roles
      },
      {
        name: 'documents',
        type: PropertyTypes.OBJECTID,
        required: false,
        referencedCollection: EntityRoutes.DOCUMENTS,
        componentProps: {
          multiple: true
        }
      },
      {
        name: 'restrictLocation',
        type: PropertyTypes.BOOLEAN,
        required: false
      },
      {
        name: 'occupyCapacity',
        type: PropertyTypes.BOOLEAN,
        required: false
      }
    ];
  return [];
};

export const formatProperties = (properties: PropertyDto[], values: PropertyValue[]) => {
  return values.map((prop) => getValue(prop));
};

export const getValue = (propertyValue: PropertyValue) => {
  if (propertyValue.label)
    return {
      _id: propertyValue._id,
      value: { _id: propertyValue.value, label: propertyValue.label }
    };
  return { _id: propertyValue._id, value: propertyValue.value };
};

export const findIndex = (
  properties: PropertyDto[],
  values: PropertyValue[],
  currentIndex: number
) => {
  const currentProperty = properties[currentIndex];
  const index = values.findIndex((prop) => prop._id === currentProperty._id);
  if (index !== -1) {
    return index;
  } else {
    let counter = 0;
    for (let i = 0; i < properties.length; i++) {
      if (properties[i]._id !== currentProperty._id) {
        counter++;
      }
      if (i === currentIndex) {
        counter++;
        break;
      }
    }
    return counter;
  }
};
