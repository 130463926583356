import { FormLabel, FormLabelProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')`
  position: relative;
  width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 8.5px 9px;
  height: 2.397rem;
  display: flex;
`;

export const Label = styled(FormLabel)<FormLabelProps>`
  left: 0;
  top: 0;
  transform: translate(14px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  user-select: none;
  position: absolute;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
`;

export const Legend = styled('legend')`
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
`;

export const Fieldset = styled('fieldset')<{ label?: string | React.ReactNode }>`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;

  /* border: 1px solid ${({ theme }) => theme.palette.grey[400]}; */
  border-radius: 4px;

  border: 1px solid
    ${(props) =>
      props.disabled
        ? ({ theme }) => theme.palette.action.disabled
        : ({ theme }) => theme.palette.grey[400]};

  .root:hover & {
    border: 1px solid
      ${(props) =>
        props.disabled
          ? ({ theme }) => theme.palette.action.disabled
          : ({ theme }) => theme.palette.primary.main};
  }

  top: ${(props) => (props.label ? '-5px' : '0px')};
`;
