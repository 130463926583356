import { theme } from '@/theme';
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Collapse,
  CollapseProps,
  IconButton
} from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

export type CollapsableCardProps = PropsWithChildren<{
  title?: React.ReactNode;
  startsOpen?: boolean;
  cardProps?: CardProps;
  transitionProps?: CollapseProps;
}>;

const CollapsableCard = ({
  title,
  children,
  cardProps = { sx: { backgroundColor: theme.palette.background.default } },
  startsOpen,
  transitionProps = { timeout: 'auto', unmountOnExit: true }
}: CollapsableCardProps) => {
  const [open, setOpen] = useState<boolean>(!!startsOpen);
  if (!children) {
    return (
      <Card {...cardProps}>
        <CardHeader
          sx={{ padding: '16px' }}
          titleTypographyProps={{ lineHeight: 0 }}
          title={title}
        />
      </Card>
    );
  }
  return (
    <Card {...cardProps}>
      <CardHeader
        sx={{ padding: '16px' }}
        titleTypographyProps={{ lineHeight: 0 }}
        title={title}
        action={
          <IconButton onClick={() => setOpen(!open)} aria-label="expand" size="small">
            {open ? <FaArrowUp /> : <FaArrowDown />}
          </IconButton>
        }></CardHeader>
      <Collapse in={open} {...transitionProps}>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

export default CollapsableCard;
