import { RenderIf } from '@/components/atoms';
import { CustomTypography } from '@/components/organisms/CurriculumSidePanel/components/common/ResourceSlotCard/ResourceSlotCard.style';
import { theme } from '@/theme';
import { GeneralContinuity, ResourceSlot, Topic, Type } from '@/types';
import { Box } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { MdEventSeat } from 'react-icons/md';
import {
  TbSquareRoundedLetterD,
  TbSquareRoundedLetterP,
  TbSquareRoundedLetterR
} from 'react-icons/tb';

const TopicList = ({
  topic,
  onSelect,
  isSelected,
  getResourceSlot,
  onRightClick
}: {
  topic: Topic;
  onSelect: (topicId: string, resourceSlotId: string) => void;
  isSelected: (topicId: string, resourceSlotId: string) => boolean;
  getResourceSlot: (resourceSlotId: string) => ResourceSlot;
  onRightClick: (topicId: string, resourceSlotId: string) => void;
}) => {
  const { control } = useFormContext<GeneralContinuity>();
  const continuityType = useWatch({
    control,
    name: 'type'
  });
  const discontinuity = useWatch({
    control,
    name: 'discontinueItem'
  });

  const getIcon = (type: Type, discontinuity: boolean) => {
    switch (type) {
      case Type.R:
        return (
          <TbSquareRoundedLetterR
            style={{ flexShrink: 0, color: discontinuity ? theme.palette.secondary.main : '' }}
            size={22}
          />
        );
      case Type.P:
        return (
          <TbSquareRoundedLetterP
            style={{ flexShrink: 0, color: discontinuity ? theme.palette.secondary.main : '' }}
            size={22}
          />
        );
      case Type.D:
        return (
          <TbSquareRoundedLetterD
            style={{ flexShrink: 0, color: discontinuity ? theme.palette.secondary.main : '' }}
            size={22}
          />
        );
      default:
        return '';
    }
  };

  const isDiscontinuity = (topicId: string, resourceSlotId: string) => {
    return discontinuity?.resourceSlotId === resourceSlotId && discontinuity?.topicId === topicId;
  };

  return (
    <>
      {topic.resourceSlots.map((resourceSlot) => {
        const rs = getResourceSlot(resourceSlot.id);
        const isDiscontinuityResource = isDiscontinuity(topic.id, resourceSlot.id);
        const isSelectedResource = isSelected(topic.id, resourceSlot.id);
        return (
          <Box
            onContextMenu={(e) => {
              e.preventDefault();
              onRightClick(topic.id, resourceSlot.id);
            }}
            onClick={() => {
              onSelect(topic.id, resourceSlot.id);
            }}
            display="flex"
            height="2rem"
            alignItems="center"
            justifyContent={'space-between'}
            gap="0.4rem"
            key={topic.id + '_' + resourceSlot.id}
            px="0.4rem"
            sx={{
              cursor: 'pointer',
              borderRadius: '5px',
              marginTop: '0.4rem',
              backgroundColor:
                isSelectedResource || isDiscontinuityResource
                  ? theme.palette.divider
                  : theme.palette.background.default
            }}>
            <Box display="flex" alignItems="center">
              <CustomTypography>
                {/* Move all this to a component */}
                {rs?.name}
              </CustomTypography>
              <CustomTypography variant="subtitle2" alignItems={'baseline'} display="flex">
                <MdEventSeat style={{ verticalAlign: 'top', marginRight: '0.1rem' }} />
                {rs?.quantity} - {rs?.role?.label}{' '}
              </CustomTypography>
            </Box>

            <RenderIf condition={isSelectedResource || isDiscontinuityResource}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0.2rem'
                }}>
                {getIcon(continuityType, isDiscontinuityResource)}
              </Box>
            </RenderIf>
          </Box>
        );
      })}
    </>
  );
};

export default TopicList;
