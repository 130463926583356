import { RenderIf } from '@/components/atoms';
import {
  COMPONENTS_BY_TYPE,
  getPropertiesByType,
  validateByType,
  validateRequired
} from '@/components/organisms/PropertiesForm/PropertiesForm.const';
import { AutoCompleteField, CrudModes, Entities, PROPERTIES_BY_ENTITY } from '@/const';
import { useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { useGet } from '@/hooks';

import { MultipleInputRow, PresetButton } from '@/components/molecules';
import { PresetModes } from '@/components/templates/PropertyPresetPanelTemplate/PropertyPresetPanelTemplate.const';
import useGetDefaultPresetByEntity from '@/hooks/useGetDefaultPresetByEntity';
import { CurriculumForm as CurriclumFormType, PropertyDto } from '@/types';
import { Grid } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FaAsterisk } from 'react-icons/fa';
import { List, Options } from './CurriculumForm.styles';

const CurriculumForm = ({ mode }: { mode: CrudModes }) => {
  const ComponentColor = COMPONENTS_BY_TYPE['color'];
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext<CurriclumFormType>();
  const { readOnly } = useCurriculumSetupContext();
  const { loading, data: properties } = useGet<PropertyDto[]>(
    {
      url: PROPERTIES_BY_ENTITY.replace(':entity_name', Entities.CURRICULUMS)
    },
    { revalidateOnMount: true }
  );

  const { defaultValues, changeDefaultValues, loadingDefaultValues, errorDefaultValues } =
    useGetDefaultPresetByEntity(Entities.CURRICULUMS, false);

  useEffect(() => {
    if (defaultValues?.data?.properties && properties) {
      defaultValues?.data?.properties.forEach((property) => {
        setValue(
          `properties.${property._id}`,
          property.label ? { label: property.label, _id: property.value } : property.value,
          { shouldDirty: defaultValues.type === PresetModes.CUSTOM }
        );
      });
    }
  }, [defaultValues, properties]);

  return (
    <Grid
      container
      display={'flex'}
      width={'100%'}
      p="1rem"
      flexDirection={'column'}
      height={'100%'}>
      <List>
        <RenderIf condition={!loading && properties?.length !== 0}>
          {properties?.map(({ type, name, _id, ...optionals }) => {
            const Component = COMPONENTS_BY_TYPE[type];
            const others = getPropertiesByType(type, optionals);
            return (
              <Fragment key={_id}>
                <Grid width={'100%'} margin={'0.5rem 0'}>
                  <Controller
                    key={name}
                    name={`properties.${_id}`}
                    control={control}
                    rules={{
                      validate: {
                        type: validateByType(type),
                        required: validateRequired(optionals?.required, type)
                      }
                    }}
                    render={({ field }) => {
                      return (
                        <Component
                          name={_id}
                          autoComplete={AutoCompleteField}
                          disabled={readOnly}
                          label={
                            <>
                              {name}
                              {optionals.required && (
                                <FaAsterisk size={12} style={{ marginLeft: '5px' }}></FaAsterisk>
                              )}
                            </>
                          }
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          error={!!errors.properties?.[_id]}
                          helperText={
                            errors.properties?.[_id] &&
                            (errors.properties?.[_id] as { message: string }).message
                          }
                          {...others}
                        />
                      );
                    }}
                  />
                </Grid>
              </Fragment>
            );
          })}
        </RenderIf>
        <Grid width={'100%'} margin={'0.5rem 0'} height="fit-content">
          <Controller
            key={'color'}
            name={'color'}
            control={control}
            render={({ field }) => {
              return (
                <MultipleInputRow
                  value={field.value}
                  fitInput={false}
                  additionalClearRenderConditions={false}
                  onChange={(value) => {
                    field.onChange(value);
                  }}>
                  <ComponentColor
                    name={'color'}
                    autoComplete={AutoCompleteField}
                    disabled={false}
                    label={<>Color</>}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    error={false}
                  />
                </MultipleInputRow>
              );
            }}
          />
        </Grid>
      </List>
      <RenderIf condition={mode == CrudModes.CREATE}>
        <Options>
          <PresetButton
            disabled={!!errorDefaultValues}
            loading={loadingDefaultValues || loading}
            entity={Entities.CURRICULUMS}
            onSelect={changeDefaultValues}></PresetButton>
        </Options>
      </RenderIf>
    </Grid>
  );
};

export default CurriculumForm;
