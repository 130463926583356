import { ToastType } from '@/context/ToastContext';
import { getErrorCodeOrMessage } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Socket, io } from 'socket.io-client';

export const useSocket = () => {
  const [socket, setSocket] = useState<Socket>();
  const [online, setOnline] = useState<boolean>(false);
  const [tenant, setTenant] = useState<string>();
  const [errors, setErrors] = useState<ToastType[]>([]);
  const { t } = useTranslation('common', { keyPrefix: 'errors' });

  const connectSocket = (url: string, tenant: string, token: string) => {
    disconnectSocket();

    const socketOptions = {
      transports: ['websocket', 'polling', 'flashsocket'],
      timeout: 300000,
      query: {
        tenant
      },
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`
          }
        }
      }
    };
    const socketTemp: Socket | undefined = io(url, socketOptions);
    setTenant(tenant);
    setSocket(socketTemp);

    socketTemp.on('connect', () => {
      console.log('socket connected ', new Date().toISOString());
      setOnline(true);
    });

    socketTemp.on('disconnect', () => {
      console.log('socket disconnected', new Date().toISOString());
      setOnline(false);
    });

    socketTemp.on('error', (data) => {
      console.log('socket error', new Date().toISOString(), data);
    });

    socketTemp.on('connect_error', (error) => {
      console.log('socket connect_error', new Date().toISOString(), error);
    });

    socketTemp.on('connect_failed', (data) => {
      console.log('socket connect_failed', new Date().toISOString(), data);
    });

    socketTemp.on('connect_timeout', (data) => {
      console.log('socket connect_timeout', new Date().toISOString(), data);
    });

    socketTemp.on('reconnect', (data) => {
      console.log('socket reconnect', new Date().toISOString(), data);
    });

    socketTemp.on('reconnecting', (data) => {
      console.log('socket reconnecting', new Date().toISOString(), data);
    });

    socketTemp.on('reconnect_error', (data) => {
      console.log('socket reconnect_error', new Date().toISOString(), data);
    });

    socketTemp.on('reconnect_failed', (data) => {
      console.log('socket reconnect_failed', new Date().toISOString(), data);
    });

    socketTemp.on('exception', (data) => {
      console.log('socket exception', new Date().toISOString(), data);
      setErrors((oldErrors) =>
        changeErrorState({ message: getErrorCodeOrMessage(data, t) }, oldErrors)
      );
    });
  };

  const disconnectSocket = useCallback(() => {
    socket?.disconnect();
  }, [socket]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      disconnectSocket();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      disconnectSocket();
    };
  }, [disconnectSocket]);

  const changeErrorState = (data: ToastType, oldErrors: ToastType[]) => {
    if (oldErrors.length > 0) {
      return [data];
    }
    return [...oldErrors, data];
  };

  return { socket, online, tenant, errors, connectSocket, disconnectSocket, setErrors };
};
