import axios, { InternalAxiosRequestConfig } from 'axios';
import { auth } from './firebase.config';

// interface AdaptAxiosRequestConfig extends RawAxiosRequestConfig {
//   headers: AxiosRequestHeaders;
// }
axios.interceptors.request.use(
  (config): Promise<InternalAxiosRequestConfig<any>> | InternalAxiosRequestConfig<any> => {
    config.baseURL = process.env.REACT_APP_API_URL;
    config.headers = config.headers ?? {};

    if (config.data instanceof FormData) {
      Object.assign(config.headers, {
        'Content-Type': 'multipart/form-data'
      });
    }

    if (auth.currentUser) {
      return auth?.currentUser?.getIdToken().then((token: string) => {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error axios:', error);
    }
    return Promise.reject(error);
  }
);
