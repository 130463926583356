import { withLazy } from '@/HOCs';
import { BasicLayout } from '@/components/layouts';
import { DateInput, ProtectedRoute } from '@/components/molecules';
import MyErrorBoundary from '@/components/organisms/ErrorBoundary/ErrorBoundary';
import { Permission } from '@/components/templates/EntityPanels/RolePanelTemplate/RolePanelTemplate.schema';
import { Signup, WarningRole } from '@/pages';
import Login from '@/pages/Login';
import { PeriodDateValue } from '@/types';
import { IconButton, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { lazy, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import {
  Link,
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
const Dashboard = withLazy(lazy(() => import('@/pages/Dashboard')));
const Main = withLazy(lazy(() => import('@/pages/Main')));
const MyCalendar = withLazy(lazy(() => import('@/pages/MyCalendar')));
const Documents = withLazy(lazy(() => import('@/pages/Setup/Documents')));
const Curriculums = withLazy(lazy(() => import('@/pages/Setup/Curriculums')));
const CurriculumsEditor = withLazy(lazy(() => import('@/pages/Setup/CurriculumsEditor')));
const Sets = withLazy(lazy(() => import('@/pages/Setup/Sets')));
const Properties = withLazy(lazy(() => import('@/pages/Setup/Properties')));
const MissionsWorkRules = withLazy(lazy(() => import('@/pages/Setup/MissionsWorkRules')));
const Users = withLazy(lazy(() => import('@/pages/Setup/Users')));
const General = withLazy(lazy(() => import('@/pages/Setup/General')));
const ResetPassword = withLazy(lazy(() => import('@/pages/ResetPassword')));
const Auth = withLazy(lazy(() => import('@/pages/Auth')));

const Test = () => {
  const [dateTest, setDateTest] = useState<string | PeriodDateValue>(DateTime.now());
  return (
    <>
      <h4>hi</h4>
      <DateInput
        includeHours
        includeMultipleObj={false}
        value={dateTest}
        onChange={(date) => {
          setDateTest(date);
        }}
      />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/login"
        errorElement={<MyErrorBoundary />}
        element={
          <ProtectedRoute reverse>
            <Login />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reset"
        errorElement={<MyErrorBoundary />}
        element={
          <ProtectedRoute reverse>
            <ResetPassword />
          </ProtectedRoute>
        }
      />
      <Route
        path="/signup/:tenant/:inviteId"
        errorElement={<MyErrorBoundary />}
        element={
          <ProtectedRoute reverse>
            <Signup />
          </ProtectedRoute>
        }
      />
      <Route
        path="/auth"
        errorElement={<MyErrorBoundary />}
        element={
          <ProtectedRoute reverse>
            <Auth />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<BasicLayout />} errorElement={<MyErrorBoundary />}>
        {/* Adding default route to redirect. */}
        <Route index element={<Navigate to={'dashboard'} />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unauthorized"
          element={
            <ProtectedRoute>
              <WarningRole />
            </ProtectedRoute>
          }
        />
        <Route
          path="/main"
          element={
            <ProtectedRoute necessaryPermission={Permission.MainFullAccess}>
              <Main />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-calendar"
          element={
            <ProtectedRoute necessaryPermission={Permission.MyCalendarFullAccess}>
              <MyCalendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup/documents"
          element={
            <ProtectedRoute necessaryPermission={Permission.DocumentsFullAccess}>
              <Documents />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup/curriculums"
          element={
            <ProtectedRoute necessaryPermission={Permission.CurriculumsFullAccess}>
              <Curriculums />
            </ProtectedRoute>
          }
        />
        <Route
          path="setup/curriculums/edit"
          element={
            <ProtectedRoute necessaryPermission={Permission.CurriculumsFullAccess}>
              <CurriculumsEditor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup/sets"
          element={
            <ProtectedRoute necessaryPermission={Permission.SetsFullAccess}>
              <Sets />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup/properties"
          element={
            <ProtectedRoute necessaryPermission={Permission.PropertiesFullAccess}>
              <Properties />{' '}
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup/missions-work-rules"
          element={
            <ProtectedRoute necessaryPermission={Permission.MissionsAndWorkRulesFullAccess}>
              <MissionsWorkRules />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup/users"
          element={
            <ProtectedRoute necessaryPermission={Permission.UsersFullAccess}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup/general"
          element={
            <ProtectedRoute necessaryPermission={Permission.GeneralFullAccess}>
              <General />
            </ProtectedRoute>
          }
        />
        <Route path="/test" element={<Test />} />
        <Route
          path="*"
          element={
            <div
              style={{
                display: 'grid',
                height: '100%',
                placeContent: 'center',
                placeItems: 'center'
              }}>
              <Typography variant="h6">😳 Whoops! Page not found.</Typography>
              <Link to={'/'}>
                <IconButton color="primary">
                  <FaHome />
                </IconButton>
              </Link>
            </div>
          }
        />
      </Route>
    </>
  ),
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  }
);

const AppRouter = () => {
  return (
    <RouterProvider
      future={{
        v7_startTransition: true
      }}
      router={router}
    />
  );
};

export default AppRouter;
