import { RenderIf, StatusIcon } from '@/components/atoms';
import { MultipleInputRow, ObjectidAutocomplete, PresetButton } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import PropertiesForm from '@/components/organisms/PropertiesForm/PropertiesForm';
import {
  AutoCompleteField,
  COLLECTIONS_BATCH_UPDATE,
  COLLECTIONS_BATCH_UPDATE_PREPARE,
  COLLECTIONS_FIND_ALL,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  Entities,
  EntityRoutes,
  PropertyTypes,
  SystemProperties
} from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch, useScrollToError } from '@/hooks';
import useGetDefaultPresetByEntity from '@/hooks/useGetDefaultPresetByEntity';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import { removeEmptyFields } from '@/services/utils/utils';
import { ButtonsContainer, Container, theme } from '@/theme';
import {
  JobDto,
  LabeledReference,
  MultiJobDto,
  MultipleInputValue,
  MultipleValue,
  Properties,
  PropertyDto,
  PropertySwitchDto,
  StructureStatus
} from '@/types';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { isArray, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';
import { PresetModes } from '../../PropertyPresetPanelTemplate/PropertyPresetPanelTemplate.const';
import { getDefaultPropertiesAsFormValues } from '../ResourcePanelTemplate/ResourcePanelTemplate.const';
import { JobFormDto, SelectedSeries } from './JobPanelTemplate.const';
import { RecurrentJobForm } from './components';

export type JobPanelTemplateProps = {
  mode: Exclude<CrudModes, 'delete' | 'read'>;
  jobId?: string | string[];
  onSuccess: (entity?: JobDto | JobDto[]) => unknown;
  onCancel: (isDirty?: boolean) => unknown;
  onDirtyFields: (isDirty: boolean) => unknown;
  onLoading?: (loading: boolean) => unknown;
};

const JobPanelTemplate = ({
  mode,
  jobId,
  onSuccess,
  onDirtyFields,
  onLoading,
  onCancel
}: JobPanelTemplateProps) => {
  const { t } = useTranslation('templates/jobPanelTemplate');
  const { t: tCommon } = useTranslation('common');
  const { setToastNotifications } = useToastContext();

  const isMultiple = useMemo(() => isArray(jobId), [jobId]);
  const [initialValues, setInitialValues] = useState<
    | (Properties & {
        curriculum?: LabeledReference | MultipleValue | null;
      })
    | undefined
  >(undefined);

  const {
    data: multipleData,
    fetch,
    loading: loadingMultiple,
    error: multipleError
  } = useFetch<MultiJobDto>();
  useEffect(() => {
    if (isMultiple) {
      fetch({
        url: COLLECTIONS_BATCH_UPDATE_PREPARE.replace(':collection_name', EntityRoutes.JOBS),
        data: { ids: jobId },
        method: 'POST'
      });
    }
  }, [isMultiple]);

  useEffect(() => {
    if (multipleError) {
      if (multipleError.response?.data?.code == 'job_not_found') {
        setToastNotifications([
          {
            message: tCommon('errors.entitiesNotFound', { entity: tCommon('entities.jobs_other') })
          }
        ]);
      } else {
        setToastNotifications([
          { message: tCommon('errors.loadingEntity', { entity: tCommon('entities.jobs_other') }) }
        ]);
      }
      onCancel(false);
      setLoadingReset(false);
    }
  }, [multipleError]);

  const { properties, entityData, loading, error } = useGetPropertiesByEntity<JobDto>(
    Entities.JOBS,
    isMultiple ? undefined : (jobId as string)
  );
  const [loadingReset, setLoadingReset] = useState<boolean>(mode === CrudModes.EDIT);

  const filteredMultiProperties: PropertyDto[] | undefined = useMemo(() => {
    if (properties && multipleData) {
      const disabledProps = multipleData.properties.reduce(
        (prev, curr) => ({ ...prev, [curr._id]: !!curr?.readOnly }),
        {}
      );
      return properties.map((property) => ({
        ...property,
        disabled: !!disabledProps?.[property._id]
      }));
    }
    return undefined;
  }, [properties, multipleData]);

  const jobStatus = useMemo(
    () =>
      isMultiple
        ? multipleData?.status.multiple
          ? StructureStatus.MULTIPLE_STATUSES
          : (multipleData?.status.value as StructureStatus)
        : entityData?.status || StructureStatus.NOT_SCHEDULED,
    [entityData, isMultiple, multipleData]
  );

  const { defaultValues, changeDefaultValues, loadingDefaultValues, errorDefaultValues } =
    useGetDefaultPresetByEntity(Entities.JOBS, mode === CrudModes.CREATE);

  useEffect(() => {
    if (defaultValues?.data?.properties && properties) {
      if (defaultValues?.data?.properties && properties) {
        defaultValues?.data?.properties.forEach((property) => {
          setValue(
            `properties.${property._id}`,
            property.label ? { label: property.label, _id: property.value } : property.value,
            { shouldDirty: defaultValues.type === PresetModes.CUSTOM }
          );
        });
        if (defaultValues.data?.curriculum) {
          setValue('curriculum', defaultValues.data?.curriculum, {
            shouldDirty: defaultValues.type === PresetModes.CUSTOM
          });
        }
      }
    }
  }, [defaultValues, properties]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.code == 'job_not_found') {
        setToastNotifications([
          { message: tCommon('errors.entityNotFound', { entity: tCommon('entities.jobs_one') }) }
        ]);
      } else {
        setToastNotifications([
          { message: tCommon('errors.loadingEntity', { entity: tCommon('entities.jobs_one') }) }
        ]);
      }
      onCancel(false);
      setLoadingReset(false);
    }
  }, [error]);

  useEffect(() => {
    if (isMultiple) {
      if (mode === CrudModes.EDIT && multipleData) {
        const formattedProps: Record<string, any> = multipleData.properties.reduce(
          (sum, { _id, label, value, multiple }) => {
            return {
              [_id]: !multiple && label ? { _id: value, label } : multiple ? { multiple } : value,
              ...sum
            };
          },
          {}
        );
        reset({
          properties: formattedProps,
          curriculum: multipleData.curriculum
        });
        setInitialValues({
          properties: formattedProps,
          curriculum: multipleData.curriculum as LabeledReference | MultipleInputValue
        });
        setLoadingReset(false);
      }
    } else {
      if (properties && entityData) {
        const newValues = getDefaultPropertiesAsFormValues(properties, entityData);
        reset({
          properties: newValues,
          curriculum: entityData.curriculum as unknown as LabeledReference
        });
        setLoadingReset(false);
      }
    }
  }, [entityData, multipleData, isMultiple, properties]);

  const submit = useCallback(
    async ({
      properties: propertiesForm,
      curriculum,
      selectedSeries,
      distributed,
      dated
    }: JobFormDto) => {
      let query: AxiosRequestConfig | undefined = undefined;
      switch (mode) {
        case CrudModes.CREATE: {
          removeEmptyFields(propertiesForm);
          let formattedForm = properties
            ?.map((row) => {
              return {
                _id: row._id,
                value:
                  (row.type === PropertyTypes.SET || row.type === PropertyTypes.OBJECTID) &&
                  typeof propertiesForm[row._id] !== 'string'
                    ? propertiesForm[row._id]?._id
                    : propertiesForm[row._id]
              };
            })
            .filter((prop) => prop.value !== undefined && prop.value !== null);

          let finalSeries: undefined | { dated } | { distributed } = undefined;

          if (selectedSeries === SelectedSeries.dated) {
            if (formattedForm)
              formattedForm = formattedForm.filter(
                ({ _id }) =>
                  !(
                    [
                      SystemProperties.JOBS_START_PERIOD_END,
                      SystemProperties.JOBS_START_PERIOD_START
                    ] as string[]
                  ).includes(_id)
              );
            finalSeries = { dated };
          } else if (selectedSeries === SelectedSeries.distributed) {
            finalSeries = { distributed };
          }

          query = {
            url: COLLECTIONS_FIND_ALL.replace(':collection_name', Entities.JOBS),
            method: 'POST',
            data: {
              curriculum: curriculum,
              properties: formattedForm,
              series: finalSeries
            }
          };
          break;
        }
        case CrudModes.EDIT:
          if (isArray(jobId)) {
            const queryData: Record<string, any> = { properties: [] };
            multipleData?.properties.forEach(({ _id, label, value, multiple }) => {
              const formattedProp =
                !multiple && label ? { _id: value, label } : multiple ? { multiple } : value;
              if (!isEqual(formattedProp, propertiesForm[_id])) {
                queryData.properties.push({
                  _id,
                  ...(propertiesForm[_id] !== undefined && propertiesForm[_id] !== null
                    ? {
                        value: propertiesForm[_id]?._id
                          ? propertiesForm[_id]._id
                          : propertiesForm[_id]
                      }
                    : { delete: true })
                });
              }
            });

            if (!isEqual(curriculum, multipleData?.curriculum) && curriculum) {
              queryData['curriculum'] = { _id: (curriculum as LabeledReference)._id };
            }

            query = {
              url: COLLECTIONS_BATCH_UPDATE.replace(':collection_name', EntityRoutes.JOBS),
              method: 'PATCH',
              data: { ids: jobId, ...queryData }
            };
          } else if (jobId) {
            removeEmptyFields(propertiesForm);
            const formattedForm = properties
              ?.map((row) => {
                return {
                  _id: row._id,
                  value:
                    (row.type === PropertyTypes.SET || row.type === PropertyTypes.OBJECTID) &&
                    typeof propertiesForm[row._id] !== 'string'
                      ? propertiesForm[row._id]?._id
                      : propertiesForm[row._id]
                };
              })
              .filter((prop) => prop.value !== undefined && prop.value !== null);
            query = {
              url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.JOBS).replace(
                ':id',
                jobId
              ),
              method: 'PATCH',
              data: {
                curriculum: curriculum,
                properties: formattedForm
              }
            };
          }
          break;
      }
      if (query) fetchPatchOfEntity(query);
    },
    [properties, multipleData, jobId]
  );

  const methods = useForm<JobFormDto>({
    mode: mode === CrudModes.CREATE ? 'onSubmit' : 'onChange',
    defaultValues: undefined,
    shouldFocusError: false
  });

  const {
    control,
    handleSubmit,
    reset,
    formState,
    trigger,
    watch,
    setError,
    clearErrors,
    setValue
  } = methods;
  const { toScroll } = useScrollToError();

  const selectedSeries = watch('selectedSeries');

  const renderProperties = useMemo(() => {
    if (selectedSeries === SelectedSeries.dated) {
      clearErrors(`properties.${SystemProperties.JOBS_START_PERIOD_START}`);
      clearErrors(`properties.${SystemProperties.JOBS_START_PERIOD_END}`);
      return properties
        ? properties.map((property: PropertyDto) => {
            if (
              (
                [
                  SystemProperties.JOBS_START_PERIOD_END,
                  SystemProperties.JOBS_START_PERIOD_START
                ] as string[]
              ).includes(property._id as string)
            ) {
              return { ...property, required: false };
            }
            return property;
          })
        : [];
    } else {
      return properties || [];
    }
  }, [selectedSeries, properties]);

  const readOnlyProps = useMemo(
    () =>
      !!(
        entityData?.status &&
        [
          StructureStatus.PARTIALLY_SCHEDULED,
          StructureStatus.COMPLETE,
          StructureStatus.CANCELED
        ].includes(entityData.status)
      ),
    [entityData]
  );

  const propertiesSwitches = useMemo(() => {
    let switches: PropertySwitchDto[] = [];
    if (mode === CrudModes.EDIT && readOnlyProps) {
      if (properties && entityData?.status === StructureStatus.CANCELED) {
        switches = properties.map((property) => {
          return {
            _id: '',
            entity: Entities.JOBS,
            entityType: '',
            property: `properties.${property._id}`
          };
        });
      } else {
        switches = [
          {
            _id: '',
            entity: Entities.JOBS,
            entityType: '',
            property: `properties.${SystemProperties.JOBS_START_PERIOD_END}`
          },
          {
            _id: '',
            entity: Entities.JOBS,
            entityType: '',
            property: `properties.${SystemProperties.JOBS_START_PERIOD_START}`
          },
          {
            _id: '',
            entity: Entities.JOBS,
            entityType: '',
            property: `properties.${SystemProperties.JOBS_LOCATION}`
          },
          {
            _id: '',
            entity: Entities.JOBS,
            entityType: '',
            property: `properties.${SystemProperties.JOBS_INITIAL_WEEKDAYS}`
          },
          {
            _id: '',
            entity: Entities.JOBS,
            entityType: '',
            property: `properties.${SystemProperties.JOBS_TRAINING_WEEKDAYS}`
          }
        ];
      }
    } else if (selectedSeries === SelectedSeries.dated) {
      switches = [
        {
          _id: '',
          entity: Entities.JOBS,
          entityType: '',
          property: `properties.${SystemProperties.JOBS_START_PERIOD_END}`
        },
        {
          _id: '',
          entity: Entities.JOBS,
          entityType: '',
          property: `properties.${SystemProperties.JOBS_START_PERIOD_START}`
        }
      ];
    }
    return switches;
  }, [selectedSeries, properties, readOnlyProps]);

  useEffect(() => {
    onDirtyFields(
      (Object.keys(formState.dirtyFields).length > 0 || formState.isDirty) &&
        entityData?.status !== StructureStatus.CANCELED
    );
  }, [formState.dirtyFields, formState.isDirty, entityData]);

  const {
    data: patchData,
    loading: loadingPatch,
    fetch: fetchPatchOfEntity,
    error: patchError
  } = useFetch<JobDto>();

  const extraValidations = useMemo(() => {
    return {
      [SystemProperties.JOBS_START_PERIOD_START]: {
        triggerEndDate: (value: string | Date, form: JobFormDto) => {
          if (form.selectedSeries !== SelectedSeries.dated)
            trigger(`properties.${SystemProperties.JOBS_START_PERIOD_END}`);
          return true;
        }
      },
      [SystemProperties.JOBS_START_PERIOD_END]: {
        moreThan: (value: string | Date, form: JobFormDto) => {
          if (form.selectedSeries !== SelectedSeries.dated) {
            const startTimeStamp = form.properties[SystemProperties.JOBS_START_PERIOD_START];
            const validation = new Date(value).getTime() >= new Date(startTimeStamp).getTime();
            return validation
              ? validation
              : tCommon('formRules.afterThatOrEqual', {
                  prop1: properties?.find(
                    (value) => value._id === SystemProperties.JOBS_START_PERIOD_END
                  )?.name,
                  prop2: properties?.find(
                    (value) => value._id === SystemProperties.JOBS_START_PERIOD_START
                  )?.name
                });
          }
          return true;
        }
      }
    };
  }, [properties]);

  useEffect(() => {
    if (onLoading) onLoading(loadingPatch);
  }, [loadingPatch]);

  useEffect(() => {
    if (patchError) {
      if (patchError?.response?.data?.code == 'job_not_found') {
        setToastNotifications([
          { message: tCommon('errors.entityNotFound', { entity: tCommon('entities.jobs_one') }) }
        ]);
      } else if (patchError.response?.data?.code == 'job_being_scheduled') {
        setToastNotifications([{ message: t('errors.beingScheduled') }]);
      } else if (patchError?.response?.data?.code == 'job_already_scheduled') {
        setToastNotifications([{ message: t('errors.alreadyScheduled') }]);
      } else if (
        patchError?.response?.data?.params &&
        patchError.response.data.code == 'duplicate_unique_property'
      ) {
        for (const param of patchError.response.data.params) {
          setError(`properties.${param}`, {
            message: tCommon('errors.duplicateProperty'),
            type: 'manual'
          });
        }
      } else if (
        patchError?.response?.data?.params &&
        patchError.response.data.code == 'invalid_property_value'
      ) {
        for (const param of patchError.response.data.params) {
          setError(`properties.${param}`, {
            message: tCommon('errors.invalidValue'),
            type: 'manual'
          });
        }
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.actionError', {
              entity: tCommon('entities.jobs_one'),
              action:
                mode === CrudModes.EDIT ? tCommon('actions.edition') : tCommon('actions.creation')
            })
          }
        ]);
      }
    } else if (patchData) {
      onDirtyFields(false);
      onSuccess(patchData as JobDto | JobDto[]);
    }
  }, [patchData, patchError]);

  return (
    <Container>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {loading ? (
            <Skeleton width={'50%'} sx={{ bgcolor: 'grey.700' }} />
          ) : mode == CrudModes.CREATE ? (
            t('createJob')
          ) : (
            t('editJob', { count: isMultiple ? jobId?.length : 1 })
          )}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '2rem 2rem 0rem 2rem' }}>
        <RenderIf condition={loading || loadingMultiple || loadingReset}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress />
          </Box>
        </RenderIf>
        <RenderIf condition={!(loading || loadingReset || loadingMultiple)}>
          <Grid container justifyContent={'end'}>
            <Grid display="flex" alignItems="center" gap="0.3rem">
              <StatusIcon size={1} status={jobStatus} />
              <Typography>{tCommon(`jobStatus.${jobStatus}`) as string}</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ margin: '0.5rem 0rem' }} />
          {filteredMultiProperties && isMultiple && (
            <>
              <Grid container display={'grid'} gridTemplateColumns={'1fr'} width={'100%'}>
                <Controller
                  control={control}
                  name={'curriculum'}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Grid width={'100%'} margin={'0.5rem 0'}>
                      <MultipleInputRow
                        fitInput
                        initialValue={initialValues?.curriculum}
                        additionalClearRenderConditions={!multipleData?.curriculum.readOnly}
                        value={field.value}
                        onChange={field.onChange}>
                        <ObjectidAutocomplete
                          includeMultipleObj
                          id={'curriculum'}
                          name={`${Entities.CURRICULUMS}_name`}
                          disabled={multipleData?.curriculum.readOnly}
                          autoComplete={AutoCompleteField}
                          error={!!formState.errors.curriculum}
                          label={
                            <>
                              {tCommon('entities.curriculums_one')}
                              <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                            </>
                          }
                          referencedCollection={EntityRoutes.CURRICULUMS}
                          value={field.value as LabeledReference | null | undefined}
                          onChange={field.onChange}
                        />
                      </MultipleInputRow>
                    </Grid>
                  )}
                />
              </Grid>
              <PropertiesForm
                initialValues={initialValues}
                isMultipleEntity={isMultiple}
                properties={filteredMultiProperties}
                control={control}
                formState={formState}
              />
            </>
          )}
          {!isMultiple && (
            <>
              <Grid container display={'grid'} gridTemplateColumns={'1fr'} width={'100%'}>
                <Controller
                  control={control}
                  name={'curriculum'}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Grid width={'100%'} margin={'0.5rem 0'}>
                      <ObjectidAutocomplete
                        id="curriculum"
                        name={`${Entities.CURRICULUMS}_name`}
                        disabled={readOnlyProps}
                        autoComplete={AutoCompleteField}
                        error={!!formState.errors.curriculum}
                        label={
                          <>
                            {tCommon('entities.curriculums_one')}
                            <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                          </>
                        }
                        referencedCollection={EntityRoutes.CURRICULUMS}
                        value={field.value as LabeledReference | undefined}
                        onChange={field.onChange}
                      />
                    </Grid>
                  )}
                />
              </Grid>
              <FormProvider {...methods}>
                <PropertiesForm
                  properties={renderProperties}
                  propertySwitches={propertiesSwitches}
                  control={control}
                  formState={formState}
                  extraValidations={extraValidations}
                />
                <RenderIf condition={mode === CrudModes.CREATE}>
                  <RecurrentJobForm />
                </RenderIf>
              </FormProvider>
            </>
          )}
        </RenderIf>
      </BodyModalContainer>
      <FooterModalContainer
        sx={mode == CrudModes.CREATE ? { justifyContent: 'space-between' } : {}}>
        <RenderIf condition={CrudModes.CREATE == mode}>
          <PresetButton
            disabled={loadingPatch || !!errorDefaultValues}
            loading={loadingDefaultValues || loading}
            entity={Entities.JOBS}
            onSelect={changeDefaultValues}></PresetButton>
        </RenderIf>
        <ButtonsContainer>
          <Button
            disabled={loading || loadingPatch}
            onClick={(e) => onCancel(Object.keys(formState.dirtyFields).length > 0)}>
            {tCommon('buttons.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loadingPatch}
            disabled={
              loading ||
              Object.keys(formState.errors).length > 0 ||
              (mode === CrudModes.EDIT && Object.keys(formState.dirtyFields).length === 0)
            }
            onClick={handleSubmit(submit, (errors) => toScroll(errors))}>
            {tCommon('buttons.save')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </Container>
  );
};

export default JobPanelTemplate;
