import { InputBox, RenderIf } from '@/components/atoms';
import { PossibleValues, isMultipleInputValue } from '@/types';
import { FormControlProps, FormGroup, FormHelperText, OutlinedInput } from '@mui/material';
import { isBoolean } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from './CheckFilter.styles';

export type CheckFilterProps = {
  label?: string | React.ReactNode;
  placeholder?: string;
  helperText?: string;
  onChange: (value: boolean | null) => void;
  disabled: boolean;
  readOnly?: boolean;
  name?: string;
} & Omit<FormControlProps, 'onChange' | 'children'> &
  PossibleValues<boolean | null>;

const CheckFilter = ({
  label,
  value,
  onChange,
  helperText,
  error,
  disabled = false,
  readOnly = false,
  includeMultipleObj = false,
  placeholder = '',
  name,
  id
}: CheckFilterProps) => {
  const { t: tCommon } = useTranslation();

  return (
    <InputBox id={id} name={name} disabled={disabled} label={label} error={error}>
      <FormGroup row>
        <FormControlLabel
          disabled={disabled}
          label={tCommon('booleanValues.yes')}
          value={true}
          control={
            <Checkbox
              size="small"
              checked={isBoolean(value) && value === true}
              onChange={() => {
                !readOnly && onChange(isBoolean(value) && value === true ? null : true);
              }}
            />
          }
        />
        <FormControlLabel
          disabled={disabled}
          label={tCommon('booleanValues.no')}
          value={false}
          control={
            <Checkbox
              size="small"
              checked={isBoolean(value) && value === false}
              onChange={() => {
                !readOnly && onChange(isBoolean(value) && value === false ? null : false);
              }}
            />
          }
        />
      </FormGroup>
      {includeMultipleObj && isMultipleInputValue(value) && (
        <OutlinedInput
          readOnly
          name={name}
          placeholder={tCommon('text.multipleValues')}
          sx={{
            '.MuiOutlinedInput-notchedOutline': { border: 'none' }
          }}
        />
      )}
      <RenderIf condition={!!helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </RenderIf>
    </InputBox>
  );
};

export default CheckFilter;
