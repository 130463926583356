import { Circle } from '@/components/atoms';
import { NavMenuItem } from '@/components/atoms/SafeLink/SafeLink.styles';
import ScheduleConfigModal from '@/components/templates/ScheduleConfigModal';
import { ScheduleModes, getScheduleProcessStatus } from '@/const';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useScheduleContext } from '@/context/SchedulerContext';
import { capitalizeFirstLetter, secondsToText, truncate } from '@/services/utils/utils';
import { ScheduleProcessDto } from '@/types';
import { dateDiffInSeconds, formatDateSimple } from '@/utils';
import { Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScheduleProcessContainer } from './ScheduleProcessMenuItem.styles';

export type ScheduleProcessMenuItemProps = {
  scheduleProcess: ScheduleProcessDto;
  key: string;
  index: number;
  onClick?: () => void;
};

const ScheduleProcessMenuItem = ({
  scheduleProcess,
  index,
  onClick
}: ScheduleProcessMenuItemProps) => {
  const { setCurrentProcessId } = useScheduleContext();
  const { initialize } = useGenericModalContext();
  const { t: tTime } = useTranslation('common', { keyPrefix: 'time' });
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('organisms/scheduleProcessMenuItem');
  const scheduleProcessStatus = getScheduleProcessStatus(scheduleProcess.status, t);
  const tookTime =
    scheduleProcess.endTime != null
      ? dateDiffInSeconds(scheduleProcess.startTime, scheduleProcess.endTime)
      : null;
  return (
    <>
      {index > 0 && <Divider sx={{ marginTop: '0.4rem' }} />}
      <NavMenuItem
        onClick={() => {
          setCurrentProcessId(scheduleProcess._id);
          initialize(<ScheduleConfigModal mode="result" />);
          if (onClick) {
            onClick();
          }
        }}>
        <Grid container alignItems="center">
          <ScheduleProcessContainer>
            <Grid container justifyContent="space-between" direction="row">
              <Grid item sx={{ textAlign: 'left' }}>
                {scheduleProcess.jobsCount}{' '}
                {tCommon('entities.jobs', {
                  count: scheduleProcess.jobsCount
                })}
                {' - '}
                {t(
                  scheduleProcess.type == ScheduleModes.JOBBYJOB
                    ? 'types.jobByJob'
                    : 'types.complete'
                )}
              </Grid>
              <Grid>
                <Circle
                  label={scheduleProcessStatus.text}
                  size={12}
                  color={scheduleProcessStatus.color}
                  textColor={scheduleProcessStatus.textColor}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ textAlign: 'left', fontSize: 'small' }}>
              {t('started.time', { time: formatDateSimple(scheduleProcess.startTime, tTime) })}
            </Grid>
            {tookTime != null ? (
              <Grid item sx={{ textAlign: 'left', fontSize: 'small' }}>
                {`${capitalizeFirstLetter(tCommon('time.took'))} ${secondsToText(
                  truncate(tookTime, 2),
                  tTime
                )}`}
              </Grid>
            ) : null}
            <Grid item sx={{ textAlign: 'left', fontSize: 'small' }}>
              {t('started.by', { user: scheduleProcess.startedBy.label })}
            </Grid>
          </ScheduleProcessContainer>
        </Grid>
      </NavMenuItem>
    </>
  );
};

export default ScheduleProcessMenuItem;
