import { RenderIf } from '@/components/atoms';
import { SuccessCard } from '@/components/molecules';
import { FinishedErrorStates, ScheduleModes } from '@/const';
import { secondsToText, truncate } from '@/services/utils/utils';
import { theme } from '@/theme';
import { StatsDto } from '@/types';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Summary, SummaryContainer } from './ScheduleSummary.styles';

export type ScheduleSummaryProps = {
  scheduleMode: ScheduleModes;
  resultStats?: StatsDto;
  status?: string;
  jobIds?: string[];
  error?: string;
};

const ScheduleSummary = ({
  scheduleMode,
  resultStats,
  status,
  jobIds,
  error
}: ScheduleSummaryProps) => {
  const { t } = useTranslation('organisms/scheduleSummary');
  const { t: tTime } = useTranslation('common', { keyPrefix: 'time' });

  return (
    <SummaryContainer>
      <Typography marginBottom={'0.5rem'} variant="h5">
        {t('title')}
      </Typography>
      <SuccessCard
        color={
          FinishedErrorStates.includes(status as string)
            ? theme.palette.error.light
            : theme.palette.success.light
        }>
        <Summary>
          <ul>
            <RenderIf condition={FinishedErrorStates.includes(status as string)}>
              <li>Fail Reason: {error ?? status}</li>
              <li>
                {t('runningTime', {
                  time: secondsToText(
                    truncate(resultStats ? resultStats.running_time : 0, 2),
                    tTime
                  )
                })}
              </li>
            </RenderIf>
            <RenderIf condition={!FinishedErrorStates.includes(status as string)}>
              <li>
                {t('resourceSlotsFilled', {
                  slots_filled: resultStats?.slots_filled,
                  slots_to_fill: resultStats?.slots_to_fill
                })}
              </li>
              <RenderIf condition={scheduleMode !== ScheduleModes.JOBBYJOB}>
                <li>
                  {t('jobsProcessed', {
                    jobs_processed: resultStats?.jobs_processed,
                    jobs_to_process: resultStats?.jobs_to_process
                  })}
                </li>
              </RenderIf>

              <li>
                {t('runningTime', {
                  time: secondsToText(
                    truncate(resultStats ? resultStats.running_time : 0, 2),
                    tTime
                  )
                })}
              </li>
            </RenderIf>
          </ul>
        </Summary>
      </SuccessCard>
    </SummaryContainer>
  );
};

export default ScheduleSummary;
