import { Unstable_Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const Body = styled(Grid)`
  height: calc(100% - 3rem);
  margin-top: 3rem;
  overflow: auto;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const Drawer = styled(Grid)`
  height: 100%;
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const Content = styled(Grid)`
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  height: 100%;
  box-sizing: border-box;
  background-color: inherit;
`;

export const Container = styled('div')`
  height: 100%;
  width: 100%;
  // padding: ${({ theme }) => theme.spacing(3)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    // padding: ${({ theme }) => theme.spacing(1.5)} ;
  }
`;
