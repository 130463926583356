import { LocaleHelper, LocaleManager } from '@bryntum/core-thin';
import '@bryntum/core-thin/lib/localization/EnGb.js';

export const locale = navigator.language == 'en-US' ? 'en-US' : 'en-GB';
LocaleManager.applyLocale(locale);

export interface DateParsers {
  L: string;
  LT: string;
}
const formatParser = LocaleHelper?.locale?.DateHelper?.parsers as DateParsers | undefined;
export const DATE_FORMAT_PICKER = formatParser?.L.replace('DD', 'dd').replace('YYYY', 'yyyy');
export const DATETIME_FORMAT_PICKER = `${formatParser?.L} ${formatParser?.LT}`
  .replace('DD', 'dd')
  .replace('YYYY', 'yyyy')
  .replace('A', 'a')
  .replace('HH', 'hh');
export const TIME_FORMAT_PICKER = formatParser?.LT.includes('A')
  ? formatParser?.LT?.replace('A', 'a').replace('HH', 'hh')
  : formatParser?.LT;
// export const optionsDatetimeFormat: Intl.DateTimeFormatOptions = {
//   hour12: false,
//   month: 'long',
//   day: '2-digit',
//   hour: '2-digit',
//   minute: '2-digit'
// };

// export const optionsTimeFormat: Intl.DateTimeFormatOptions = {
//   hour12: false,
//   hour: '2-digit',
//   minute: '2-digit'
// };

// export const optionsDateFormat: Intl.DateTimeFormatOptions = {
//   day: '2-digit',
//   month: '2-digit',
//   year: 'numeric'
// };

import { DateTime } from 'luxon';

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  NEVER = 'NEVER'
}
export enum DaysValue {
  MONDAY = 'MO',
  TUESDAY = 'TU',
  WEDNESDAY = 'WE',
  THURSDAY = 'TH',
  FRIDAY = 'FR',
  SATURDAY = 'SA',
  SUNDAY = 'SU'
}
export enum DaysNames {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday'
}

type DaysNamesType = `${DaysNames}`;
export type DaysValuesType = `${DaysValue}`;
export const DAYS: Day[] = [
  { value: DaysValue.MONDAY, name: DaysNames.MONDAY, key: 'Monday' },
  { value: DaysValue.TUESDAY, name: DaysNames.TUESDAY, key: 'Tuesday' },
  { value: DaysValue.WEDNESDAY, name: DaysNames.WEDNESDAY, key: 'Wednesday' },
  { value: DaysValue.THURSDAY, name: DaysNames.THURSDAY, key: 'Thursday' },
  { value: DaysValue.FRIDAY, name: DaysNames.FRIDAY, key: 'Friday' },
  { value: DaysValue.SATURDAY, name: DaysNames.SATURDAY, key: 'Saturday' },
  { value: DaysValue.SUNDAY, name: DaysNames.SUNDAY, key: 'Sunday' }
];

export type Day = {
  name: DaysNamesType;
  key: string;
  value: DaysValuesType;
};

export const getDaysOfWeek = () => {
  const countryTimeZone = DateTime.local().zoneName || 'America/New_York';
  const firstDayOfWeek = DateTime.local().setZone(countryTimeZone).startOf('week');

  const daysOfWeek: Day[] = [];
  for (let i = 0; i < 7; i++) {
    const key = firstDayOfWeek
      .plus({ days: i })
      .setLocale('en-US')
      .toLocaleString({ weekday: 'long' });
    const day = DAYS.find((day) => day.key === key);
    day && daysOfWeek.push(day);
  }
  return daysOfWeek;
};
