import { theme } from '@/theme';
import { Typography, TypographyVariant, styled } from '@mui/material';

export const Wrapper = styled('div')<
  { isSelected: boolean; isDragging: boolean } & Record<string, any>
>`
  position: relative;
  width: calc(100% - 2rem);
  margin: 0 auto;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  opacity: ${(props) => (props.isDragging ? '0.5' : '1')};
  border: 1px ${(props) => (props.isDragging ? 'dashed' : 'solid')}
    ${(props) => (props.isSelected ? theme.palette.grey[500] : theme.palette.divider)};
  background: ${theme.palette.common.white};
  border-radius: 5px;
  box-shadow: ${(props) =>
    props.isSelected ? `0px 0px 3px 0.5px ${theme.palette.grey[500]}` : 'none'};
`;

export const ScrollButton = styled('button')<{ direction: 'left' | 'right' }>`
  position: absolute;
  height: 100%;
  width: 1.2rem;
  top: 0;
  border-radius: 5px;
  display: grid;
  place-content: center;
  background-color: transparent;
  border-radius: ${(props) => (props.direction === 'left' ? '5px 0 0 5px' : '0 5px 5px 0')};
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const HeaderContainer = styled('div')<{ headerStyle?: 'default' | 'shadow' }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 1.8rem;
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  ${({ headerStyle }) => {
    switch (headerStyle) {
      case 'shadow':
        return `
        border: 0.1rem dotted ${theme.palette.grey[500]};
        border-radius: 5px;
        opacity:0.6;
        background-color:${theme.palette.common.white};
        `;
      default:
        return `
        border-bottom: 1px solid ${theme.palette.divider};
        border-radius: 5px 5px 0 0;        
        `;
    }
  }}
`;

export const IconHeaderContainer = styled('div')`
  height: 100%;
  align-self: center;
  display: grid;
  place-items: center;
  margin-right: 0.7rem;
`;

export const HEADER_TITLE_VARIANT: TypographyVariant = 'body2';
export const HeaderTitle = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 2rem;
`;

export const FOOTER_VARIANT: TypographyVariant = 'caption';
export const Footer = styled('div')`
  width: 100%;
  padding: 0 0.7rem;
  height: 1.125rem;
  z-index: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.palette.divider};
`;

export const HeaderRightSide = styled('div')`
  display: flex;
  height: 1rem;
  position: relative;
  width: fit-content;
  margin-left: auto;
`;
