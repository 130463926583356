import { COLLECTIONS_FIND_ONE, EVENTS_DELETE_SERIES, SeriesDeletionModes } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type DeletionSeriesProps = {
  eventInfo: any;
  handleCancel: (selection?: string, eventInfo?: any) => void;
};

const DeletionSeries = ({ eventInfo, handleCancel }: DeletionSeriesProps) => {
  const [deletionSeriesMode, setDeletionSeriesMode] = useState<SeriesDeletionModes>(
    SeriesDeletionModes.THIS
  );
  const onChangeDeletionSeriesMode = (event: ChangeEvent<HTMLInputElement>) => {
    setDeletionSeriesMode(event.target.value as SeriesDeletionModes);
  };
  const { t: tDeletionSeries } = useTranslation('organisms/deletionSeries');
  const { t: tCommon } = useTranslation();

  const deleteOptions = tDeletionSeries('deleteEventSeries.options', {
    returnObjects: true
  });

  const { fetch, loading, data, error } = useFetch<any>();

  const { setToastNotifications } = useToastContext();

  const submit = useCallback(async () => {
    await fetch({
      url:
        deletionSeriesMode == SeriesDeletionModes.THIS
          ? COLLECTIONS_FIND_ONE.replace(':collection_name', 'events').replace(
              ':id',
              eventInfo.eventRecord.data._id
            )
          : EVENTS_DELETE_SERIES,
      data:
        deletionSeriesMode == SeriesDeletionModes.THIS
          ? undefined
          : deletionSeriesMode == SeriesDeletionModes.ALL
          ? { seriesId: eventInfo.eventRecord.data.series }
          : {
              seriesId: eventInfo.eventRecord.data.series,
              eventId: eventInfo.eventRecord.data._id
            },
      method: deletionSeriesMode == SeriesDeletionModes.THIS ? 'DELETE' : 'POST'
    });
  }, [deletionSeriesMode]);

  useEffect(() => {
    if (data) {
      const eventInfoToRemove =
        deletionSeriesMode == SeriesDeletionModes.THIS ? eventInfo : data.ids;
      handleCancel(deletionSeriesMode, eventInfoToRemove);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.code == 'event_series_not_found') {
        setToastNotifications([{ message: tDeletionSeries('errors.seriesNotFound') }]);
      } else if (error?.response?.data?.code == 'event_not_found_in_series') {
        setToastNotifications([{ message: tDeletionSeries('errors.eventNotFound') }]);
      } else {
        setToastNotifications([{ message: tDeletionSeries('errors.default') }]);
      }
    }
  }, [error]);

  return (
    <>
      <DialogContent sx={{ padding: '1.3rem 1.5rem 1rem 1.5rem !important' }}>
        <Typography>
          {tDeletionSeries('deleteEventSeries.message1', {
            event: eventInfo.eventRecord.data.name
          })}
        </Typography>
        <br />
        <Typography>{tDeletionSeries('deleteEventSeries.message2')}</Typography>
        <FormControl component="fieldset" variant="standard" sx={{ paddingTop: '0.3rem' }}>
          <RadioGroup
            value={deletionSeriesMode}
            onChange={onChangeDeletionSeriesMode}
            name="deleteSeriesType">
            {deleteOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem 1.5rem' }}>
        <Button disabled={loading} onClick={() => handleCancel()}>
          {tCommon('buttons.cancel')}
        </Button>
        <LoadingButton variant="contained" onClick={submit} loading={loading}>
          {tCommon('buttons.delete')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DeletionSeries;
