import { RunningStates, SCHEDULING_PROCESS_GET, SCHEDULING_PROCESS_SUMMARY } from '@/const';
import { useFetch } from '@/hooks';
import { ScheduleProcessDto, ScheduleProcessProgressDto, ScheduleResultDto } from '@/types';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useSocketContext } from '../SocketContext';
import SchedulerContext, { LoadingState, SchedulerContextType } from './SchedulerContext';

const SchedulerContextProvider = ({ children }: PropsWithChildren) => {
  const [currentProcessId, setCurrentProcessId] = useState<string | null>(null);
  const [currentProcess, setCurrentProcess] = useState<ScheduleResultDto | null>(null);
  const [processes, setProcesses] = useState<ScheduleProcessDto[]>([]);
  const [processProgress, setProcessProgress] = useState<ScheduleProcessProgressDto[]>([]);
  const [loadingState, setLoadingState] = useState<LoadingState>('preload');
  const [schedulingError, setSchedulingError] = useState<unknown>();
  const { socket, tenant } = useSocketContext();

  const {
    data: processData,
    fetch: processFetch,
    loading: currentProcessLoading,
    error: currentProcessError
  } = useFetch<ScheduleResultDto>();
  const {
    data: processesData,
    fetch: processesFetch,
    error: errorProcesses
  } = useFetch<ScheduleProcessDto[]>();

  const [isFetchingProcess, setIsFetchingProcess] = useState(false);
  const [isFetchingProcesses, setIsFetchingProcesses] = useState(false);
  const [messagesQueue, setMessagesQueue] = useState<ScheduleProcessProgressDto[][]>([]);

  useEffect(() => {
    const error = {};
    if (currentProcessError) {
      error['processError'] = currentProcessError;
    }
    if (errorProcesses && errorProcesses['code'] !== 'ERR_CANCELED') {
      error['processesError'] = errorProcesses;
    }
    setSchedulingError(Object.keys(error).length > 0 ? error : undefined);
  }, [currentProcessError, errorProcesses]);

  const fetchMessage = async () => {
    try {
      setIsFetchingProcess(true);
      if (currentProcessId) {
        await processFetch({
          method: 'GET',
          url: SCHEDULING_PROCESS_GET.replace(':id', currentProcessId)
        });
      }
    } catch (error: any) {
      console.error('Error fetching messages:', error);
      throw new Error('Error fetching messages:', error);
    } finally {
      setIsFetchingProcess(false);
    }
  };

  const isProcessingScheduling = useCallback(() => {
    return !!processes.find((_process) => RunningStates.includes(_process.status.toUpperCase()));
  }, [processes]);

  const fetchMessages = async () => {
    try {
      setIsFetchingProcesses(true);
      await processesFetch({ url: SCHEDULING_PROCESS_SUMMARY, method: 'GET' });
    } catch (error: any) {
      console.error('Error fetching messages:', error);
      throw new Error('Error fetching messages:', error);
    } finally {
      setIsFetchingProcesses(false);
    }
  };

  const processMessagesQueue = useCallback(async () => {
    if (!isFetchingProcesses) {
      const queueLength = messagesQueue.length;
      if (queueLength > 0) {
        await fetchMessages();
        setMessagesQueue((prevQueue) => prevQueue.slice(queueLength));
      }
    }
  }, [isFetchingProcesses, messagesQueue]);

  const handleNewMessage = (message: ScheduleProcessProgressDto[]) => {
    if (currentProcessId != null) {
      const curProcessNotified =
        message && currentProcessId in message ? message[currentProcessId] : null;
      if (curProcessNotified) {
        const curProcess =
          processProgress && currentProcessId in processProgress
            ? processProgress[currentProcessId]
            : null;
        // update the process if not in previous notified data, or if lastUpdate is diferent
        if (!curProcess || curProcess.lastUpdate !== curProcessNotified.lastUpdate) {
          fetchMessage();
        }
      }
    }
    setProcessProgress(message);
    setMessagesQueue((prevQueue) => [...prevQueue, message]);
  };

  useEffect(() => {
    if (processData) {
      setCurrentProcess(processData);
    }
  }, [processData]);

  useEffect(() => {
    setCurrentProcess(null);
    if (currentProcessId != null) {
      fetchMessage();
    }
  }, [currentProcessId]);

  useEffect(() => {
    if (socket && tenant) {
      socket.on(`scheduling.${tenant}`, (data: ScheduleProcessProgressDto[]) => {
        handleNewMessage(data);
      });
    }

    return () => {
      if (socket && tenant) {
        socket.removeAllListeners(`scheduling.${tenant}`);
      }
    };
  }, [socket, tenant, currentProcessId]);

  useEffect(() => {
    processMessagesQueue();
  }, [messagesQueue]);

  useEffect(() => {
    if (processesData != null) {
      setProcesses(processesData);
    }
  }, [processesData]);

  const value: SchedulerContextType = {
    currentProcessId,
    currentProcess,
    currentProcessLoading,
    loadingState,
    processProgress,
    processes,
    fetchMessages,
    schedulingError,
    setSchedulingError,
    setCurrentProcessId,
    setCurrentProcess,
    setLoadingState,
    setProcessProgress,
    setProcesses,
    isProcessingScheduling
  };

  return <SchedulerContext.Provider value={value}>{children}</SchedulerContext.Provider>;
};

export default SchedulerContextProvider;
