import { SystemProperties } from '@/const';
import { theme } from '@/theme';
import {
  AssignmentBryntum,
  AssignmentBryntumGantt,
  AssignmentBryntumPro,
  EventBryntum,
  EventDto,
  EventProBryntum,
  EventTaskBryntum,
  JobDto,
  JobtoTask,
  JoinedEventBryntum,
  JoinedEventProBryntum
} from '@/types';
import { BryntumScheduler, BryntumSchedulerProps } from '@bryntum/scheduler-react-thin';
import { ForwardedRef, RefObject } from 'react';
import { dateDiffInDays, getPropertyValueDef, isTextColorDark } from '.';
import { BryntumGanttProps } from '@bryntum/gantt-react-thin';
import { BryntumSchedulerPro, BryntumSchedulerProProps } from '@bryntum/schedulerpro-react-thin';
import { AssignmentModelConfig } from '@bryntum/gantt-thin';

export const getCommonGanttConfig: (extraItems: Partial<BryntumGanttProps>) => BryntumGanttProps = (
  extraItems
) => {
  return {
    columns: [
      ...Array(7)
        .fill('')
        .map(() => ({
          text: '...',
          width: 40
        }))
    ],
    calendars: [
      {
        id: 'general',
        name: 'General',
        intervals: [
          {
            recurrentStartDate: 'on Sat at 0:00',
            recurrentEndDate: 'on Mon at 0:00',
            isWorking: false
          }
        ],
        expanded: true,
        children: [
          {
            id: 'business',
            name: 'Business',
            intervals: [
              {
                recurrentStartDate: 'every weekday at 12:00',
                recurrentEndDate: 'every weekday at 13:00',
                isWorking: false
              },
              {
                recurrentStartDate: 'every weekday at 17:00',
                recurrentEndDate: 'every weekday at 08:00',
                isWorking: false
              }
            ]
          },
          {
            id: 'night',
            name: 'Night shift',
            intervals: [
              {
                recurrentStartDate: 'every weekday at 6:00',
                recurrentEndDate: 'every weekday at 22:00',
                isWorking: false
              }
            ]
          }
        ]
      }
    ],

    eventStyle: undefined,
    eventColor: undefined,
    filterBarFeature: false,
    stripeFeature: true,
    infiniteScroll: true,
    selectionMode: {
      rowNumber: true,
      cell: false,
      multiSelect: true
    },
    timeRangesFeature: {
      showCurrentTimeLine: true
    },
    zoomKeepsOriginalTimespan: true,
    cellEditFeature: false,
    displayDateFormat: 'HH:mm',
    zoomLevel: 17,
    barMargin: 3,
    rowHeight: 40,
    ...extraItems
  };
};

export const getCommonSchedulerConfig: (
  extraItems: Partial<BryntumSchedulerProps>
) => BryntumSchedulerProps = (extraItems) => {
  return {
    columns: [
      ...Array(7)
        .fill('')
        .map(() => ({
          text: '...',
          width: 40
        }))
    ],
    eventStyle: undefined,
    eventColor: undefined,
    filterBarFeature: false,
    stripeFeature: true,
    infiniteScroll: true,
    multiEventSelect: true,
    selectionMode: {
      row: true,
      cell: false,
      multiSelect: true
    },
    eventDragSelectFeature: true,
    eventDragCreateFeature: false,
    stickyEventsFeature: false,
    timeRangesFeature: {
      showCurrentTimeLine: true
    },
    eventStore: {
      removeUnassignedEvent: false
    },
    cellEditFeature: false,
    displayDateFormat: 'HH:mm',
    barMargin: 3,
    resourceMargin: 3,
    rowHeight: 32,
    viewPreset: 'hourAndDay-100by40',
    zoomLevel: 13,
    dateFormat: 'DD mm',
    ...extraItems
  };
};

export const getCommonSchedulerProConfig: (
  extraItems: Partial<BryntumSchedulerProProps>
) => BryntumSchedulerProProps = (extraItems) => {
  return {
    columns: [
      ...Array(7)
        .fill('')
        .map(() => ({
          text: '...',
          width: 40
        }))
    ],
    eventStyle: undefined,
    eventColor: undefined,
    filterBarFeature: false,
    stripeFeature: true,
    infiniteScroll: true,
    multiEventSelect: true,
    selectionMode: {
      row: true,
      cell: false,
      multiSelect: true
    },
    eventDragSelectFeature: true,
    eventDragCreateFeature: false,
    stickyEventsFeature: false,
    timeRangesFeature: {
      showCurrentTimeLine: true
    },
    eventStore: {
      removeUnassignedEvent: false
    },
    cellEditFeature: false,
    displayDateFormat: 'HH:mm',
    barMargin: 3,
    resourceMargin: 3,
    rowHeight: 32,
    zoomKeepsOriginalTimespan: true,
    zoomLevel: 17,
    dateFormat: 'DD mm',
    ...extraItems
  };
};

export function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export const sortingByFetch = (record1: any, record2: any) => {
  return 0;
};

export const transformToEventBryntum = (event: EventDto): EventBryntum => {
  const eventColor = getPropertyValueDef(
    event.properties,
    SystemProperties.EVENTS_COLOR,
    theme.palette.grey[100]
  );
  const isCanceled = !!event?.canceled;

  const eventData: EventBryntum<Omit<EventDto, 'job'>> = {
    id: event._id,
    name: getPropertyValueDef(event.properties, SystemProperties.EVENTS_NAME, ''),
    startDate: new Date(
      getPropertyValueDef(event.properties, SystemProperties.EVENTS_START_TIMESTAMP, '')
    ),
    endDate: new Date(
      getPropertyValueDef(event.properties, SystemProperties.EVENTS_END_TIMESTAMP, '')
    ),
    eventStyle: 'border',
    eventColor: eventColor,
    cls: [
      isTextColorDark(eventColor) ? 'event-dark-text' : 'event-light-text',
      isCanceled && 'event-canceled'
    ]
      .filter(Boolean)
      .join(' '),
    ...event,
    job: event.job ? [event.job] : undefined
  };
  return eventData;
};

export const transformToEventProBryntum = (event: EventDto): EventProBryntum => {
  const eventColor = getPropertyValueDef(
    event.properties,
    SystemProperties.EVENTS_COLOR,
    theme.palette.grey[100]
  );
  const isCanceled = !!event?.canceled;

  const eventData: EventProBryntum<Omit<EventDto, 'job'>> = {
    id: event._id,
    name: getPropertyValueDef(event.properties, SystemProperties.EVENTS_NAME, ''),
    startDate: new Date(
      getPropertyValueDef(event.properties, SystemProperties.EVENTS_START_TIMESTAMP, '')
    ),
    endDate: new Date(
      getPropertyValueDef(event.properties, SystemProperties.EVENTS_END_TIMESTAMP, '')
    ),
    eventStyle: 'border',
    eventColor: eventColor,
    cls: [
      isTextColorDark(eventColor) ? 'event-dark-text' : 'event-light-text',
      isCanceled && 'event-canceled'
    ]
      .filter(Boolean)
      .join(' '),
    ...event,
    job: event.job ? [event.job] : undefined
  };
  return eventData;
};

export const transformModelTaskBryntum = (jobTask: EventTaskBryntum<JobDto>): EventTaskBryntum => {
  const objectTask: EventTaskBryntum = {
    id: jobTask._id ?? '',
    name: getPropertyValueDef(jobTask.properties, SystemProperties.JOBS_NAME, ''),
    startDate: getPropertyValueDef(
      jobTask.properties,
      SystemProperties.JOBS_START_PERIOD_START,
      ''
    ),
    endDate: getPropertyValueDef(jobTask.properties, SystemProperties.JOBS_START_PERIOD_END, ''),
    expanded: jobTask.children.length > 0,
    children: jobTask.children,
    duration: dateDiffInDays(
      getPropertyValueDef(jobTask.properties, SystemProperties.JOBS_START_PERIOD_END, ''),
      getPropertyValueDef(jobTask.properties, SystemProperties.JOBS_START_PERIOD_END, '')
    )
  };

  return objectTask;
};

export const transformToTaskEventBryntum = (event: EventDto): EventTaskBryntum => {
  const eventColor = getPropertyValueDef(
    event.properties,
    SystemProperties.EVENTS_COLOR,
    theme.palette.grey[100]
  );
  const isCanceled = !!event?.canceled;
  const eventData: EventTaskBryntum<Omit<EventDto, 'job'>> = {
    id: event._id,
    name: getPropertyValueDef(event.properties, SystemProperties.EVENTS_NAME, ''),
    startDate: new Date(
      getPropertyValueDef(event.properties, SystemProperties.EVENTS_START_TIMESTAMP, '')
    ),
    endDate: new Date(
      getPropertyValueDef(event.properties, SystemProperties.EVENTS_END_TIMESTAMP, '')
    ),
    style: 'border',
    eventColor: eventColor,
    cls: [
      isTextColorDark(eventColor) ? 'event-dark-text' : 'event-light-text',
      isCanceled && 'event-canceled'
    ]
      .filter(Boolean)
      .join(' '),
    ...event,
    job: event.job ? [event.job] : undefined,
    children: [],
    expanded: false
  };
  return eventData;
};

export const transformToJoinedEventBryntum = (event: EventDto): JoinedEventBryntum => {
  const joinedEventBryntum = transformToEventBryntum(event) as JoinedEventBryntum;
  joinedEventBryntum.id = event.join ?? '';
  joinedEventBryntum.eventStyle = 'line';
  joinedEventBryntum.cls = 'event-dark-text';
  joinedEventBryntum.joinedAssignments = new Map();
  joinedEventBryntum['events'] = [];
  joinedEventBryntum['job'] = [event.job];
  delete joinedEventBryntum['sessionId'];
  delete joinedEventBryntum['topicId'];
  return joinedEventBryntum;
};

export const transformToJoinedEventProBryntum = (event: EventDto): JoinedEventProBryntum => {
  const joinedEventBryntum = transformToEventBryntum(event) as JoinedEventProBryntum;
  joinedEventBryntum.id = event.join ?? '';
  joinedEventBryntum.eventStyle = 'line';
  joinedEventBryntum.cls = 'event-dark-text';
  joinedEventBryntum.joinedAssignments = new Map();
  joinedEventBryntum['events'] = [];
  joinedEventBryntum['job'] = [event.job];
  delete joinedEventBryntum['sessionId'];
  delete joinedEventBryntum['topicId'];
  return joinedEventBryntum;
};

export const defaultMenuContextOptions = (items) => {
  items.copy = false;
  items.paste = false;
  items.cut = false;
  items.removeRow = false;
};

export const defaultEventMenuContextOptions = (items) => {
  items.copyEvent = false;
  items.cutEvent = false;
  items.unassignEvent = false;
  items.editEvent = false;
  items.deleteEvent = false;
  items.duplicate = false;
};

export const defaultEventMenuContextOptionsGantt = (items) => {
  items.editTask = false;
  items.cut = false;
  items.copy = false;
  items.paste = false;
  items.add = false;
  items.addTaskAbove = false;
  items.outdent = false;
  items.indent = false;
  items.convertToMilestone = false;
  items.deleteTask = false;
  items.linkTasks = false;
  items.unlinkTasks = false;
};

export const transformToAssignmentBryntum = (
  eventId: string,
  resourceId: string,
  joined = false,
  showAsJoined = false
): AssignmentBryntum => {
  const effectiveJoin = joined && showAsJoined;
  return {
    id: eventId + '@' + resourceId + (effectiveJoin ? '@J' : ''),
    resourceId,
    eventId,
    joined,
    joinedAssignments: [],
    showAsJoined
  };
};

export const transformToAssignmentBryntumPro = (
  eventId: string,
  resourceId: string,
  joined = false,
  showAsJoined = false
): AssignmentBryntumPro => {
  const effectiveJoin = joined && showAsJoined;
  return {
    id: eventId + '@' + resourceId + (effectiveJoin ? '@J' : ''),
    resourceId,
    eventId,
    joined,
    joinedAssignments: [],
    showAsJoined
  };
};

export const transformToAssignmentBryntumGantt = (
  eventId: string,
  resourceId: string,
  joined = false,
  showAsJoined = false
): AssignmentModelConfig => {
  const effectiveJoin = joined && showAsJoined;
  return {
    id: eventId + '@' + resourceId + (effectiveJoin ? '@J' : ''),
    resource: resourceId,
    event: eventId
  };
};

export const goToToday = (ref: ForwardedRef<BryntumSchedulerPro>) => {
  const today = new Date();
  today.setSeconds(0, 0);

  (ref as RefObject<BryntumSchedulerPro>).current?.instance.scrollToDate(today, {
    highlight: true,
    animate: {
      easing: 'easeFromTo',
      duration: 800
    },
    block: 'center'
  });
};

export const zoomIn = (ref: ForwardedRef<BryntumSchedulerPro>) => {
  const scheduler = (ref as RefObject<BryntumSchedulerPro>).current?.instance;
  if (scheduler) scheduler?.zoomIn();
};

export const zoomOut = (ref: ForwardedRef<BryntumSchedulerPro>) => {
  const scheduler = (ref as RefObject<BryntumSchedulerPro>).current?.instance;
  if (scheduler) scheduler?.zoomOut();
};
