//import brand from '@/assets/imgs/tillent-brand-white.svg';
//import isologoAnimated from '@/assets/imgs/tillent-isologo-animated.gif';
import isologoAnimated from '@/assets/imgs/animacion-logo-xo.gif';
import isologoStopped from '@/assets/imgs/bell_icon.png';
import brand from '@/assets/imgs/logo1.svg';
import { RenderIf, SafeLink } from '@/components/atoms';
import { NavMenuItem } from '@/components/atoms/SafeLink/SafeLink.styles';
import { ChangePasswordPanelTemplate } from '@/components/templates';
import {
  Permission,
  SetupPermissions
} from '@/components/templates/EntityPanels/RolePanelTemplate/RolePanelTemplate.schema';
import { auth } from '@/config/firebase.config';
import { FILES_URL_BY_ID, SystemProperties } from '@/const';
import { useAuthContext } from '@/context/AuthContext';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useScheduleContext } from '@/context/SchedulerContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { theme } from '@/theme';
import { getPropertyValue, getResourceFullName } from '@/utils';
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Menu,
  Toolbar,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ScheduleProcessMenuItem from '../ScheduleProcessMenuItem';
import {
  AppBar,
  Brand,
  FiLockStyled,
  FiLogOutStyled,
  GridElements,
  ScheduleIcon
} from './Navbar.styles';

const Navbar = () => {
  const { t } = useTranslation('organisms/navbar');
  const { t: tSchedulingProcess } = useTranslation('organisms/scheduleProcessMenuItem');
  const { user, userTenant, userResource, userPermissions } = useAuthContext();
  const location = useLocation();
  const [anchorSetup, setAnchorSetup] = useState<null | HTMLElement>(null);
  const openSetup = Boolean(anchorSetup);
  const [anchorUser, setAnchorUser] = useState<null | HTMLElement>(null);
  const openUser = Boolean(anchorUser);
  const [anchorScheduler, setAnchorScheduler] = useState<null | HTMLElement>(null);
  const openScheduler = Boolean(anchorScheduler);
  const { processes, fetchMessages, schedulingError, isProcessingScheduling } =
    useScheduleContext();
  const { initialize, handleClose } = useGenericModalContext();

  useEffect(() => {
    if (userPermissions?.includes(Permission.MainFullAccess)) {
      fetchMessages();
    }
  }, [userPermissions]);

  const handleClickSetup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSetup(event.currentTarget);
  };
  const handleCloseSetup = () => {
    setAnchorSetup(null);
  };
  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUser(event.currentTarget);
  };
  const handleCloseUser = () => {
    setAnchorUser(null);
  };
  const handleClickScheduler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorScheduler(event.currentTarget);
  };
  const handleCloseScheduler = () => {
    setAnchorScheduler(null);
  };

  const setupLabel = useMemo((): string => {
    const setupText = t('setup');
    if (location.pathname === '/setup/documents') {
      return `${setupText} - ${t('documents')}`;
    }
    if (
      location.pathname === '/setup/curriculums' ||
      location.pathname === '/setup/curriculums/edit'
    ) {
      return `${setupText} - ${t('curriculums')}`;
    }
    if (location.pathname === '/setup/sets') {
      return `${setupText} - ${t('sets')}`;
    }
    if (location.pathname === '/setup/properties') {
      return `${setupText} - ${t('properties')}`;
    }
    if (location.pathname === '/setup/missions-work-rules') {
      return `${setupText} - ${t('missionsWorkRules')}`;
    }
    if (location.pathname === '/setup/users') {
      return `${setupText} - ${t('users')}`;
    }
    if (location.pathname === '/setup/general') {
      return `${setupText} - ${t('general')}`;
    }
    return setupText;
  }, [location]);

  const blockNavigation = (e) => {
    e.preventDefault();
  };

  const { setToastNotifications } = useToastContext();
  const { t: tCommon } = useTranslation();

  const { data: image, fetch: fetchImage, error: errorImage } = useFetch<string>();

  useEffect(() => {
    if (errorImage) {
      setToastNotifications([{ message: tCommon('errors.loadingImage') }]);
    }
  }, [errorImage]);

  useEffect(() => {
    if (userResource) {
      const userImage = getPropertyValue(
        userResource?.properties,
        SystemProperties.RESOURCES_PICTURE
      ) as string;
      if (userImage) fetchImage({ url: FILES_URL_BY_ID.replace(':id', userImage) });
    }
  }, [userResource]);

  const logout = () => {
    auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem('intended-destination', 'null');
  };

  const openCredentials = () => {
    handleCloseUser();
    initialize(<ChangePasswordPanelTemplate onCancel={() => handleClose(false)} />, {
      style: {
        height: '50vh',
        width: '35vw',
        minWidth: '28rem',
        minHeight: '26rem',
        maxWidth: '88rem',
        maxHeight: '90rem',
        borderRadius: '16px'
      }
    });
  };

  const getAvatarLabel = () => {
    const firstName = getPropertyValue(
      userResource?.properties,
      SystemProperties.RESOURCES_FIRST_NAME
    ) as string;
    const name = getPropertyValue(
      userResource?.properties,
      SystemProperties.RESOURCES_NAME
    ) as string;
    if (firstName && name) {
      return `${firstName.charAt(0)}${name.charAt(0)}`.toUpperCase();
    }
    if (name) {
      return name.charAt(0).toUpperCase();
    }
    if (user?.email) {
      const matches = user.email.match('(\\w+)\\W(\\w+).*@.+');
      if (matches?.length == 3) {
        return `${matches[1].charAt(0)}${matches[2].charAt(0)}`.toUpperCase();
      }
      return user.email.charAt(0).toUpperCase();
    }
    return undefined;
  };

  return (
    <AppBar position="fixed">
      <Toolbar variant="dense">
        <GridElements>
          <SafeLink
            disabled={!userPermissions || !userPermissions.includes(Permission.MainFullAccess)}
            to={'/main'}
            sx={{ mr: 1.5 }}>
            <Grid
              sx={
                !userPermissions || !userPermissions.includes(Permission.MainFullAccess)
                  ? { paddingX: '0.5rem', marginRight: '12px' }
                  : {}
              }>
              <Brand src={isProcessingScheduling() ? isologoAnimated : brand} />
            </Grid>
          </SafeLink>
          <RenderIf condition={!!userResource}>
            <SafeLink
              to={'/my-calendar'}
              disabled={
                !userPermissions || !userPermissions.includes(Permission.MyCalendarFullAccess)
              }>
              <Typography
                sx={
                  userPermissions && userPermissions.includes(Permission.MyCalendarFullAccess)
                    ? { cursor: 'pointer' }
                    : { paddingX: '0.5rem', height: 'auto', opacity: 0.38 }
                }>
                {t('myCalendar')}
              </Typography>
            </SafeLink>
          </RenderIf>
          <RenderIf
            condition={
              userPermissions &&
              SetupPermissions.some((setupPermission) => userPermissions.includes(setupPermission))
            }>
            <SafeLink to={'/setup'} sx={{ cursor: 'pointer' }} onClick={blockNavigation}>
              <Typography onClick={handleClickSetup} sx={{ cursor: 'pointer' }}>
                {setupLabel}
              </Typography>
            </SafeLink>
          </RenderIf>
          <Menu
            sx={{
              '& .MuiList-root': {
                padding: '0.3rem 0'
              },
              '& .MuiPaper-root': {
                backgroundColor: theme.palette.primary.main
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            id="basic-menu"
            anchorEl={anchorSetup}
            open={openSetup}
            onClose={handleCloseSetup}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}>
            <SafeLink
              onClick={handleCloseSetup}
              isOnMenu
              to={'/setup/documents'}
              disabled={
                !userPermissions || !userPermissions.includes(Permission.DocumentsFullAccess)
              }
              disabledText={t('documents')}
              end></SafeLink>
            <SafeLink
              onClick={handleCloseSetup}
              isOnMenu
              to={'/setup/curriculums'}
              disabled={
                !userPermissions || !userPermissions.includes(Permission.CurriculumsFullAccess)
              }
              disabledText={t('curriculums')}
              end></SafeLink>
            <Divider sx={{ marginY: '0.3rem', borderColor: theme.palette.grey[500] }} />
            <SafeLink
              onClick={handleCloseSetup}
              isOnMenu
              to={'/setup/sets'}
              disabled={!userPermissions || !userPermissions.includes(Permission.SetsFullAccess)}
              disabledText={t('sets')}
              end></SafeLink>
            <SafeLink
              onClick={handleCloseSetup}
              isOnMenu
              to={'/setup/properties'}
              disabled={
                !userPermissions || !userPermissions.includes(Permission.PropertiesFullAccess)
              }
              disabledText={t('properties')}
              end></SafeLink>
            <SafeLink
              onClick={handleCloseSetup}
              isOnMenu
              to={'/setup/missions-work-rules'}
              disabled={
                !userPermissions ||
                !userPermissions.includes(Permission.MissionsAndWorkRulesFullAccess)
              }
              disabledText={t('missionsWorkRules')}
              end></SafeLink>
            <Divider sx={{ marginY: '0.3rem', borderColor: theme.palette.grey[500] }} />
            <SafeLink
              onClick={handleCloseSetup}
              isOnMenu
              to={'/setup/users'}
              disabled={!userPermissions || !userPermissions.includes(Permission.UsersFullAccess)}
              disabledText={t('users')}
              end></SafeLink>
            <Divider sx={{ marginY: '0.3rem', borderColor: theme.palette.grey[500] }} />
            <SafeLink
              onClick={handleCloseSetup}
              isOnMenu
              to={'/setup/general'}
              disabled={!userPermissions || !userPermissions.includes(Permission.GeneralFullAccess)}
              disabledText={t('general')}
              end></SafeLink>
          </Menu>
        </GridElements>
        <RenderIf
          condition={!userPermissions || !userPermissions.includes(Permission.MainFullAccess)}>
          <IconButton sx={{ cursor: 'auto' }}>
            <ScheduleIcon src={isologoStopped} />
          </IconButton>
        </RenderIf>
        <RenderIf condition={!!userPermissions?.includes(Permission.MainFullAccess)}>
          <IconButton aria-label="cart" onClick={handleClickScheduler}>
            <ScheduleIcon src={isologoStopped} />
          </IconButton>
          <Menu
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: theme.palette.primary.main,
                maxHeight: '90vh'
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 0,
              horizontal: 'center'
            }}
            id="basic-menu"
            anchorEl={anchorScheduler}
            open={openScheduler}
            onClose={handleCloseScheduler}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}>
            {(schedulingError as any)?.processesError ? (
              <Card>
                <CardContent sx={{ color: theme.palette.common.white }}>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    {tSchedulingProcess('schedulingFail')}
                  </Typography>
                </CardContent>
              </Card>
            ) : !processes.length ? (
              <Card>
                <CardContent sx={{ color: theme.palette.common.white }}>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    {tSchedulingProcess('empty')}
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              processes.map((_process, index) => (
                <ScheduleProcessMenuItem
                  key={_process._id}
                  scheduleProcess={_process}
                  index={index}
                  onClick={handleCloseScheduler}
                />
              ))
            )}
          </Menu>
        </RenderIf>
        <IconButton onClick={handleClickUser}>
          <Avatar
            src={image}
            sx={{
              width: '2rem',
              height: '2rem',
              fontSize: '0.8rem',
              color: theme.palette.primary.main
            }}>
            {getAvatarLabel()}
          </Avatar>
        </IconButton>
        <Menu
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: theme.palette.primary.main,
              maxWidth: '30rem'
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 0,
            horizontal: 'center'
          }}
          id="basic-menu"
          anchorEl={anchorUser}
          open={openUser}
          onClose={handleCloseUser}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}>
          <Grid
            container
            padding={'0.5rem 1rem 1rem'}
            gap={'1rem'}
            alignItems={'center'}
            flexWrap={'nowrap'}>
            <Avatar
              src={image}
              sx={{
                width: '3.5rem',
                height: '3.5rem',
                fontSize: '1.4rem',
                color: theme.palette.primary.main
              }}>
              {getAvatarLabel()}
            </Avatar>
            <Grid sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {userResource && (
                <Typography
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  color={theme.palette.common.white}>
                  {getResourceFullName(userResource?.properties)}
                </Typography>
              )}
              <Typography
                variant={userResource ? 'subtitle2' : 'body1'}
                textOverflow={'ellipsis'}
                overflow={'hidden'}
                color={theme.palette.common.white}>
                {user?.email}
              </Typography>
              <Typography
                variant="subtitle2"
                textOverflow={'ellipsis'}
                overflow={'hidden'}
                color={theme.palette.common.white}>
                {userTenant?.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ marginY: '0.3rem', borderColor: theme.palette.grey[500] }} />
          <NavMenuItem onClick={openCredentials}>
            <FiLockStyled />
            {t('changePassword')}
          </NavMenuItem>
          <Divider sx={{ borderColor: theme.palette.grey[500], marginY: '0.3rem' }} />
          <NavMenuItem onClick={logout}>
            <FiLogOutStyled />
            {t('logout')}
          </NavMenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
