import { MenuItem, Typography, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const DisabledLink = styled(Typography)`
  transition: opacity 0.4s ease;
  padding-inline: 0;
  .css-1gp21f7-MuiTypography-root {
    margin: 0;
  }
  color: ${({ theme }) => theme.palette.grey[300]} !important;
  &.active {
    background-color: ${({ theme }) => theme.palette.grey[600]};
    border-radius: 5px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const NavMenuItem = styled(MenuItem)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const Link = styled(NavLink)`
  padding-inline: 0.5rem;
  transition: opacity 0.4s ease;
  color: ${({ theme }) => theme.palette.common.white} !important;
  .css-1gp21f7-MuiTypography-root {
    margin: 0;
  }

  &.active {
    /* background-color: ${({ theme }) => theme.palette.grey[100]}; */
    background-color: transparent;
    border-radius: 5px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const MenuLink = styled(NavLink)`
  transition: opacity 0.4s ease;
  padding-inline: 0;
  .css-1gp21f7-MuiTypography-root {
    margin: 0;
  }
  color: ${({ theme }) => theme.palette.common.white} !important;
  &.active {
    background-color: ${({ theme }) => theme.palette.grey[600]};
    border-radius: 5px;
  }
  &:hover {
    opacity: 0.8;
  }
`;
