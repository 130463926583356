import { SystemFilters } from '@/const';
import { loadState, saveState } from '@/utils';
import { AccordionActions, AccordionDetailsProps, AccordionProps, Typography } from '@mui/material';
import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { Accordion, AccordionDetails, AccordionSummary } from './Card.styles';

export type CardAccordionProps = PropsWithChildren<
  {
    title: string;
    onHeader?: (event: MouseEvent) => void;
    actions?: React.ReactNode;
    contentProps?: Omit<AccordionDetailsProps, 'children'>;
    scrollOnClick?: boolean;
    persistKey?: string;
  } & AccordionProps
>;

const CardAccordion = ({
  title,
  children,
  onHeader,
  actions,
  contentProps,
  defaultExpanded = true,
  scrollOnClick = true,
  persistKey: key,
  ...others
}: CardAccordionProps) => {
  const defaultValue = useMemo(() => {
    if (key) {
      const expanded = loadState<Record<string, boolean>>(SystemFilters.EXPANDED_SECTIONS) || {};
      const value = expanded[key];
      if (value !== undefined) {
        return value;
      } else {
        saveState(SystemFilters.EXPANDED_SECTIONS, { ...expanded, [key]: defaultExpanded });
        return defaultExpanded;
      }
    }
    return defaultExpanded;
  }, []);

  const [expanded, setExpanded] = useState(defaultValue);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Accordion
      ref={ref}
      expanded={expanded}
      onChange={(e) => {
        if (e.type == 'click') {
          setExpanded(!expanded);
          if (key) {
            const before =
              loadState<Record<string, boolean>>(SystemFilters.EXPANDED_SECTIONS) || {};
            const newSettings = {
              ...before,
              [key]: !expanded
            };
            saveState(SystemFilters.EXPANDED_SECTIONS, newSettings);
          }
        }
      }}
      TransitionProps={{ unmountOnExit: true }}
      elevation={0}
      {...others}>
      <AccordionSummary
        onClick={(event) => {
          if (scrollOnClick)
            setTimeout(() => {
              ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 300);
          if (onHeader) onHeader(event as unknown as MouseEvent);
        }}
        expandIcon={<FaAngleDown />}
        sx={{ margin: '0px !important' }}>
        <Typography>{title}</Typography>
        <AccordionActions sx={{ padding: '0', flex: '1 1 auto' }}>{actions}</AccordionActions>
      </AccordionSummary>
      <AccordionDetails {...contentProps}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CardAccordion;
