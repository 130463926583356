import { RenderIf } from '@/components/atoms';
import { DATETIME_FORMAT_PICKER, DATE_FORMAT_PICKER } from '@/const';
import { PeriodDateValue, PossibleValues, isMultipleInputValue } from '@/types';
import { Box, FormControl, FormControlProps, FormHelperText, TextFieldProps } from '@mui/material';
import { DateTimePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type DateInputProps = {
  label?: string | ReactNode;
  onChange: (value: PeriodDateValue | string) => void;
  helperText?: string;
  preventInvalidDate?: boolean;
  fieldProps?: TextFieldProps;
  includeHours: boolean;
  readOnly?: boolean;
  disablePast?: boolean;
  minDate?: DateTime;
} & Omit<FormControlProps, 'onChange'> &
  Pick<TextFieldProps, 'name' | 'autoComplete'> &
  PossibleValues<PeriodDateValue | string>;

const DateInput = ({
  label,
  value,
  onChange,
  helperText,
  includeMultipleObj: isMultiple,
  id,
  name,
  autoComplete,
  includeHours = false,
  fieldProps = {},
  preventInvalidDate = false,
  error,
  disablePast = false,
  disabled = false,
  readOnly = false,
  minDate,
  ...props
}: DateInputProps) => {
  const { t: tCommon } = useTranslation();
  const handleChange = (newValue: PeriodDateValue) => {
    if (preventInvalidDate && newValue) {
      onChange(newValue.isValid ? newValue.toISO() : null);
    } else {
      onChange(newValue?.isValid ? newValue.toISO() : newValue);
    }
  };
  const handleChangeDate = (newValue: DateTime | null) => {
    if (preventInvalidDate && newValue) {
      onChange(newValue.isValid ? newValue.toISODate() : null);
    } else {
      onChange(newValue?.isValid ? newValue.toISODate() : newValue);
    }
  };

  return (
    <FormControl
      sx={{ borderColor: 'red', '& .MuiOutlinedInput-notchedOutline': { borderRadius: '4px' } }}
      fullWidth
      error={error}
      {...props}>
      <Box>
        {includeHours ? (
          <DateTimePicker
            disablePast={disablePast}
            minDate={minDate}
            readOnly={readOnly}
            label={label}
            format={DATETIME_FORMAT_PICKER}
            value={
              isString(value)
                ? DateTime.fromISO(value)
                : !isMultipleInputValue(value) && value
                ? value
                : null
            }
            disabled={disabled}
            onChange={handleChange}
            slotProps={{
              openPickerButton: { size: 'small', sx: { padding: '0.2rem', left: '0.35rem' } },
              actionBar: {},
              textField: {
                error,
                inputProps: {
                  placeholder:
                    isMultiple && isMultipleInputValue(value) ? tCommon('text.multipleValues') : ''
                },
                onBlur: () => value === '' && onChange(null),
                variant: 'outlined',
                size: 'small',
                InputLabelProps: {
                  shrink: true
                },
                id,
                name,
                autoComplete,
                ...fieldProps
              }
            }}
          />
        ) : (
          <DesktopDatePicker
            disablePast={disablePast}
            minDate={minDate}
            label={label}
            format={DATE_FORMAT_PICKER}
            value={
              isString(value)
                ? DateTime.fromISO(value)
                : !isMultipleInputValue(value) && value
                ? value
                : null
            }
            disabled={disabled}
            onChange={handleChangeDate}
            slotProps={{
              openPickerButton: { size: 'small', sx: { padding: '0.2rem', left: '0.35rem' } },
              textField: {
                error,
                inputProps: {
                  placeholder:
                    isMultiple && isMultipleInputValue(value) ? tCommon('text.multipleValues') : ''
                },
                onBlur: () => value === '' && onChange(null),
                variant: 'outlined',
                size: 'small',
                InputLabelProps: {
                  shrink: true
                },
                id,
                name,
                autoComplete,
                ...fieldProps
              }
            }}
          />
        )}
      </Box>
      <RenderIf condition={!!helperText}>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </RenderIf>
    </FormControl>
  );
};

export default DateInput;
