import { theme } from '@/theme';
import { ResourceSlot, Session } from '@/types';
import { hexToRGB, pickTextColorBasedOnBgColor } from '@/utils';
import { Box } from '@mui/material';
import { MdExpandMore } from 'react-icons/md';

import { RenderIf } from '@/components/atoms';
import { CustomTypography } from '@/components/organisms/CurriculumSidePanel/components/common/ResourceSlotCard/ResourceSlotCard.style';
import TopicList from './Topic';

const SessionList = ({
  session,
  onSelect,
  isSelected,
  getResourceSlot,
  onRightClick
}: {
  session: Session;
  onSelect: (topicId: string, resourceSlotId: string) => void;
  isSelected: (topicId: string, resourceSlotId: string) => boolean;
  getResourceSlot: (resourceSlotId: string) => ResourceSlot;
  onRightClick: (topicId: string, resourceSlotId: string) => void;
}) => {
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <MdExpandMore size={20} />
        <CustomTypography>{session.name}</CustomTypography>
      </Box>
      {session?.topics?.map((topic, index) => (
        <Box key={topic.id} pl="1rem" py="0.4rem">
          <Box display="flex" alignItems="center">
            <RenderIf condition={topic.resourceSlots.length != 0}>
              <MdExpandMore size={20} />
            </RenderIf>
            <Box
              sx={{
                backgroundColor: hexToRGB(topic.color, 0.8),
                color: pickTextColorBasedOnBgColor(
                  topic.color,
                  theme.palette.common.white,
                  theme.palette.primary.main
                ),
                borderRadius: '5px',
                paddingInline: '0.5rem',
                width: '100%',
                height: '2rem',
                display: 'flex',
                alignItems: 'center'
              }}>
              <CustomTypography>{topic.name}</CustomTypography>
            </Box>
          </Box>

          <Box pl="2.5rem" display="flex" flexDirection="column">
            <TopicList
              onRightClick={onRightClick}
              getResourceSlot={getResourceSlot}
              isSelected={isSelected}
              topic={topic}
              onSelect={onSelect}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SessionList;
