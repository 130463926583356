import { Grid, styled } from '@mui/material';

export const HeaderModalContainer = styled(Grid)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  flex: 0 0 min-content;
  padding: 1rem 0;
`;

export const BodyModalContainer = styled(Grid)`
  width: 100%;
  flex: 1 1;
  overflow: hidden auto;
  flex-direction: column;
  display: flex;
  padding: 2rem 2rem 2rem;
  position: relative;
  scroll-behavior: smooth;
`;

export const FooterModalContainer = styled(Grid)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey['200']};
  flex: 0 0 min-content;
  display: flex;
  justify-content: flex-end;
  padding: 1.3rem 2rem;
`;
