import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { CardContainer, IconButton, Name } from '../FilterByRecord.styles';
import { TabOptions } from './TabOptions';

type DocumentCardProps = {
  name: string;
  assigned: boolean;
  type: string;
  _id?: string;
  remove: (index: number) => void;
  index: number;
  updateDocument: (index: number, assigned: boolean) => void;
};
export const DocumentCard: FC<DocumentCardProps> = ({
  assigned,
  name,
  type,
  remove,
  index,
  updateDocument
}) => {
  return (
    <CardContainer>
      <TabOptions assigned={assigned} updateDocument={updateDocument} index={index} />
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column">
        <Name>{name}</Name>
        <Typography variant={'caption'} sx={{ lineHeight: 'normal' }}>
          {type}
        </Typography>
      </Box>
      <IconButton onClick={() => remove(index)}>
        <i className="b-fa-solid b-fa-trash" />
      </IconButton>
    </CardContainer>
  );
};
