import { BryntumToolbar, Loading, MainContainer } from '@/components/molecules';
import { DeletionSeries } from '@/components/organisms';
import {
  ADVANCED_SEARCH,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  EVENTS_BY_RESOURCE,
  Entities,
  FILES_URL_BY_ID,
  RESOURCE_LABEL_PROP_IDS,
  SystemProperties
} from '@/const';
import { useAuthContext } from '@/context/AuthContext';
import { useCalendarContext } from '@/context/CalendarContext';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useJobContext } from '@/context/JobsContext';
import { useModalConfirmationContext } from '@/context/ModalConfirmationContext';
import { useResourceContext } from '@/context/ResourceContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import useWindowUnloadEffect from '@/hooks/useWindowOnLoad';
import {
  checkIfAllValuesAreRepeated,
  loadStateConfigBryntum,
  saveStateConfigBryntum
} from '@/services/utils/utils';
import { theme } from '@/theme';
import {
  EventBryntum,
  EventDto,
  EventTooltipFeatureData,
  JobDto,
  JoinedEventBryntum,
  ResourceDto,
  ResourceQueryResult,
  SchedulerNames
} from '@/types';
import { CalendarNames } from '@/types/ui/calendar';
import {
  defaultEventMenuContextOptions,
  getEventLocation,
  getPropertyValueDef,
  loadState,
  pickTextColorBasedOnBgColor,
  transformToEventBryntum
} from '@/utils';
import { BryntumCalendar, BryntumCalendarProps } from '@bryntum/calendar-react-thin';
import { Calendar } from '@bryntum/calendar-thin';
import { StringHelper } from '@bryntum/core-thin';
import { EventModelConfig, EventStore } from '@bryntum/scheduler-thin';
import { Grid, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDebouncyFn } from 'use-debouncy';
import { EventPanelTemplate } from '../EntityPanels';
import { BryntumHint } from '../MainTemplate/components';
import { getAssignmentsFromEvents } from './MyCalendarTemplate.const';
import { ButtonSettings } from './MyCalendarTemplate.styles';

type ViewType = 'day' | 'week' | 'month' | 'year' | 'agenda';

export type OpenEventPanelProps = {
  eventId?: string;
  mode: CrudModes;
  startDate: Date;
};

export type MyCalendarTemplateProps = {
  resourceId?: string;
  date?: Date;
  onChangeEvent?: (event: EventDto[] | string, action: CrudModes) => void;
  openEventPanel?: (configEvent: OpenEventPanelProps) => void;
};

const MyCalendarTemplate = ({
  resourceId,
  onChangeEvent,
  date,
  openEventPanel
}: MyCalendarTemplateProps) => {
  const ref = useRef<BryntumCalendar>(null);
  const { userResource } = useAuthContext();
  const [events, setEvents] = useState<JoinedEventBryntum[]>([]);
  const [joinedEvents, setJoinedEvents] = useState<string[]>();
  const [sidebarState, setSidebarState] = useState<boolean>(false);
  const { t } = useTranslation('templates/myCalendarTemplate');
  const { t: tCommon } = useTranslation();
  const { jobs: localJobs, addJobs } = useJobContext();
  const { resources: localResources, addResources } = useResourceContext();
  const { showConfirmation, handleCancel } = useModalConfirmationContext();
  const {
    data: eventsData,
    fetch: fetchEventsData,
    loading: loadingEvents,
    error: errorEvents
  } = useFetch<EventDto[]>();
  const id = resourceId ?? userResource?._id;
  const { fetch: fetchJobForHint, error: errorJobForHint } = useFetch<any>();
  const { data: resource, fetch: fetchResource, error: errorResource } = useFetch<ResourceDto>();
  const { fetch: fetchResourcesForHint, error: errorResourceForHint } =
    useFetch<ResourceQueryResult>();
  const localState = loadState<Record<string, any>>(SchedulerNames.CALENDAR);

  const { setToastNotifications } = useToastContext();

  useEffect(() => {
    if (errorResource) {
      setToastNotifications([{ message: t('errors.resourceInfo') }]);
    }
  }, [errorResource]);

  useEffect(() => {
    if (errorEvents) {
      setToastNotifications([{ message: t('errors.events') }]);
    }
  }, [errorEvents]);

  useEffect(() => {
    if (errorResourceForHint || errorJobForHint) {
      setToastNotifications([{ message: t('errors.loadingHint') }]);
    }
  }, [errorResourceForHint, errorJobForHint]);

  const [view, setView] = useState<ViewType>(localState?.mode ?? 'month');
  const [viewDescription, setViewDescription] = useState<string>();
  const { initialize, handleClose, preventFromClose, handleDirtyModal } = useGenericModalContext();
  const { events: globalEvents, handleEvents } = useCalendarContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchResource({
        url: COLLECTIONS_FIND_ONE.replace(':collection_name', 'resources').replace(':id', id)
      });
      loadStateConfigBryntum(ref, SchedulerNames.CALENDAR);

      if (ref?.current) {
        setViewDescription(ref.current?.instance.tbar.widgetMap.viewDescription.element.innerText);
        if (date) ref.current.instance.date = date;
      }
    } else if (userResource === null) {
      navigate('/unauthorized');
    }
  }, [userResource]);

  useEffect(() => {
    if (globalEvents && ref.current?.instance.eventStore && resource) {
      const { resourceEventsRender, joinedEventsArray } = getAssignmentsFromEvents(
        globalEvents,
        resource,
        t
      );
      setEvents(resourceEventsRender);
      setJoinedEvents(joinedEventsArray);
    }
  }, [globalEvents, resource]);

  const filterEvents = useCallback(
    (events: JoinedEventBryntum[], joinedEventsArray: string[]) => {
      if (ref.current?.instance.eventStore && !!events) {
        ref.current.instance.eventStore.filter({
          id: 'joinFilter',
          filterBy: (event) => {
            return (
              joinedEventsArray?.includes(event.data.id) ||
              (event.data.join &&
                !joinedEventsArray?.includes(event.data.join) &&
                !event.data.joinedAssignments) ||
              !event.data.join
            );
          }
        });
        const eventStore: EventStore = ref.current.instance.eventStore;
        eventStore.removeAll();
        eventStore.insert(0, events);
      }
    },
    [ref]
  );

  useEffect(() => {
    if (ref && ref.current && joinedEvents && events) filterEvents(events, joinedEvents);
  }, [joinedEvents, events, ref]);

  const addNewEvents = async (events: EventDto[]) => {
    const eventStore: EventStore | undefined = (ref as RefObject<BryntumCalendar>).current?.instance
      .eventStore;
    if (eventStore && ref.current) {
      const eventModels = await eventStore.addAsync(
        events.map((event) => transformToEventBryntum(event) as Partial<EventModelConfig>)
      );
      if (onChangeEvent) {
        onChangeEvent(events, CrudModes.CREATE);
      }
      ref.current.instance.date = eventModels[0].getData('startDate');
    }
  };

  const removeEvent = (eventId: string) => {
    const eventStore: EventStore | undefined = (ref as RefObject<BryntumCalendar>).current?.instance
      .eventStore;
    if (onChangeEvent && eventStore) {
      onChangeEvent(eventId, CrudModes.DELETE);
    }
    return eventStore?.remove(eventId);
  };

  const updateEvent = (event: EventDto) => {
    if (removeEvent(event._id)) {
      addNewEvents([event]);
    }
  };

  const expandJoinedEvents = (event: JoinedEventBryntum) => {
    if (event.join && event.joinedAssignments) {
      setJoinedEvents((prevJoinedEvents) =>
        prevJoinedEvents?.filter((prevJoinedEvent) => prevJoinedEvent != event.join)
      );
    }
  };

  const collapseJoinedEvents = (event: EventBryntum) => {
    setJoinedEvents((prevJoinedEvents) => {
      if (prevJoinedEvents && event.join) {
        return [...prevJoinedEvents, event.join];
      }
    });
  };

  const scheduleMenuFeature = useMemo(
    () => ({
      processItems({ items }) {
        items.addEvent = false;
        items.createEvent = {
          text: t('actions.createEvent'),
          icon: 'b-icon b-fa-circle-plus',
          disabled: loadingEvents || !resource,
          onItem: async (ev) => {
            if (resource) {
              if (resourceId && openEventPanel) {
                openEventPanel({ mode: CrudModes.CREATE, startDate: ev.date });
              } else {
                initialize(
                  <EventPanelTemplate
                    mode={CrudModes.CREATE}
                    resourceId={resource?._id}
                    startDate={ev.date}
                    onSuccess={(newEvents) => {
                      if (newEvents) {
                        addNewEvents(Array.isArray(newEvents) ? newEvents : [newEvents]);
                        handleClose(false);
                      }
                    }}
                    onDirtyFields={(isDirty) =>
                      handleDirtyModal(isDirty, tCommon('entities.events_one'))
                    }
                    onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
                    onLoading={(loading) => preventFromClose(loading)}
                  />,
                  { modalName: tCommon('entities.events_one') }
                );
              }
            }
          }
        };
      }
    }),
    [loadingEvents, resource]
  );

  const handleCloseDeletionSeries = (selection?: string, eventInfo?: any) => {
    if (selection) {
      if (selection == 'thisAndFollowing' || selection == 'all') {
        eventInfo.forEach((event) => {
          removeEvent(event);
        });
      } else {
        removeEvent(eventInfo.eventRecord.data.id);
      }
    }
    handleCancel();
  };

  const eventMenuFeature = useMemo(
    () => ({
      processItems({ items, eventRecord }) {
        defaultEventMenuContextOptions(items);
        items.customCreateEvent = {
          _text: t('actions.createEvent'),
          get text() {
            return this._text;
          },
          set text(value) {
            this._text = value;
          },
          icon: 'b-icon b-fa-circle-plus',
          disabled: loadingEvents,
          onItem: async (ev) => {
            if (resource) {
              if (resourceId && openEventPanel) {
                openEventPanel({
                  mode: CrudModes.CREATE,
                  startDate: ev.eventRecord.data.startDate
                });
              } else {
                initialize(
                  <EventPanelTemplate
                    mode={CrudModes.CREATE}
                    resourceId={resource?._id}
                    startDate={ev.eventRecord.data.startDate}
                    onSuccess={(newEvents) => {
                      if (newEvents) {
                        addNewEvents(Array.isArray(newEvents) ? newEvents : [newEvents]);
                        handleClose(false);
                      }
                    }}
                    onDirtyFields={(isDirty) =>
                      handleDirtyModal(isDirty, tCommon('entities.events_one'))
                    }
                    onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
                    onLoading={(loading) => preventFromClose(loading)}
                  />,
                  { modalName: tCommon('entities.events_one') }
                );
              }
            }
          }
        };
        items.customEditEvent = {
          text: t('actions.editEvent'),
          disabled: !!eventRecord.data.job || loadingEvents || !resource,
          icon: 'b-icon b-fa-pen-to-square',
          onItem: async (ev) => {
            if (resource)
              if (resourceId && openEventPanel) {
                openEventPanel({
                  mode: CrudModes.EDIT,
                  eventId: ev.eventRecord.data._id,
                  startDate: ev.eventRecord.data.startDate
                });
              } else {
                initialize(
                  <EventPanelTemplate
                    mode={CrudModes.EDIT}
                    eventId={ev.eventRecord.data._id}
                    resourceId={resource?._id}
                    onSuccess={(editedEvent) => {
                      if (editedEvent) {
                        updateEvent(editedEvent as EventDto);
                        handleClose(false);
                      }
                    }}
                    onDirtyFields={(isDirty) =>
                      handleDirtyModal(isDirty, tCommon('entities.events_one'))
                    }
                    onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
                    onLoading={(loading) => preventFromClose(loading)}
                  />,
                  { modalName: tCommon('entities.events_one') }
                );
              }
          }
        };
        items.customDeleteEvent = {
          text: t('actions.deleteEvent'),
          disabled: loadingEvents || !resource,
          icon: 'b-icon b-fa-trash-can',
          onItem: async (ev) => {
            if (eventRecord.data.series) {
              await showConfirmation({
                title: tCommon('modalConfirmationDelete.title'),
                message: <DeletionSeries eventInfo={ev} handleCancel={handleCloseDeletionSeries} />,
                isReactNode: true
              });
            } else {
              // TODO: Use this when batch delete up.
              // const refInstance = (ref as RefObject<BryntumScheduler>).current?.instance;
              // if (refInstance){
              //   const ids = refInstance.selectedEvents
              //     .map((event) => event?.id?.toString())
              //     .filter((ev) => !!ev);
              //   const result: Record<string, unknown> = await showConfirmation({
              //     title: tCommon('modalConfirmationDelete.title'),
              //     message: tCommon('modalConfirmationDelete.message', {
              //       element: tCommon('entity.events_lowercase')
              //     }),
              //     confirmButton: tCommon('buttons.delete'),
              //     cancelButton: tCommon('buttons.cancel'),
              //     fetchData: {
              //       url: COLLECTIONS_BATCH_DELETE.replace(':collection_name', Entities.EVENTS).replace(
              //         ':id',
              //         ev.assignmentRecord.data.eventId
              //       ),
              //       data:{ids},
              //       method: 'POST'
              //     }
              //   });
              //   if (result) {
              //     removeEvent(ev.assignmentRecord.data.eventId);
              //   }
              // }

              const result: Record<string, unknown> = await showConfirmation({
                title: tCommon('modalConfirmationDelete.title'),
                message: tCommon('modalConfirmationDelete.message_one', {
                  element: tCommon('entities.events_one')
                }),
                confirmButton: tCommon('buttons.delete'),
                cancelButton: tCommon('buttons.cancel'),
                fetchData: {
                  url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.EVENTS).replace(
                    ':id',
                    ev.eventRecord.data._id
                  ),
                  method: 'DELETE'
                },
                handleError: (error) => {
                  setToastNotifications([{ message: tCommon('errors.deletionError') }]);
                }
              });
              if (result) {
                removeEvent(ev.eventRecord.data._id);
              }
            }
          }
        };
        if (eventRecord.data.join) {
          if (eventRecord.data.joinedAssignments) {
            items.customExpandJoined = {
              text: t('actions.expandJoined'),
              icon: 'b-icon b-fa-angles-down',
              disabled: loadingEvents,
              cls: 'b-separator',
              onItem: async (ev) => {
                expandJoinedEvents(ev.eventRecord.data);
              }
            };
          } else {
            items.customCollapseJoined = {
              text: t('actions.collapseJoined'),
              icon: 'b-icon b-fa-angles-up',
              disabled: loadingEvents,
              cls: 'b-separator',
              onItem: async (ev) => {
                collapseJoinedEvents(ev.eventRecord.data);
              }
            };
          }
        }
      }
    }),
    [loadingEvents, resource]
  );

  const goToToday = () => {
    ref.current?.instance.shiftToNow();
  };

  const nextPeriod = () => {
    if (ref && ref.current) {
      ref.current.instance.shiftNext();
    }
  };
  const previousPeriod = () => {
    if (ref && ref.current) {
      ref.current.instance.shiftPrevious();
    }
  };
  const toogleMenuSidebar = () => {
    setSidebarState((sidebar) => !sidebar);
  };

  useEffect(() => {
    if (ref && ref.current) {
      if (sidebarState) {
        ref.current.instance.sidebar.hide();
      } else {
        ref.current.instance.sidebar.show();
      }
    }
  }, [sidebarState]);

  const handleView = (event: React.MouseEvent<HTMLElement>, newView: ViewType) => {
    setView(newView);
  };

  useEffect(() => {
    if (ref && ref.current && ref.current.instance.mode != view) {
      ref.current.instance.mode = view;
    }
  }, [view]);

  const eventDblClick = (event) => {
    if (resource)
      if (resourceId && openEventPanel) {
        openEventPanel({
          mode: CrudModes.EDIT,
          eventId: event.eventRecord.data._id,
          startDate: event.date
        });
      } else {
        initialize(
          <EventPanelTemplate
            mode={CrudModes.EDIT}
            eventId={event.eventRecord.data._id}
            resourceId={resource?._id}
            onSuccess={(editedEvent) => {
              if (editedEvent) {
                updateEvent(editedEvent as EventDto);
                handleClose(false);
              }
            }}
            onDirtyFields={(isDirty) => handleDirtyModal(isDirty, tCommon('entities.events_one'))}
            onCancel={(isDirty) => handleClose(isDirty, tCommon('entities.events_one'))}
            onLoading={(loading) => preventFromClose(loading)}
          />,
          { modalName: tCommon('entities.events_one') }
        );
      }
  };

  useEffect(() => {
    if (eventsData) handleEvents(eventsData);
  }, [eventsData]);

  const debouncy = useDebouncyFn((e: any) => {
    if (e.new.startDate && e.new.endDate && id && !_.isEmpty(e.old)) {
      fetchEventsData({
        url: EVENTS_BY_RESOURCE.replace(':id', id)
          .replace(':startDate', DateTime.fromJSDate(e.new.startDate).toUTC().toISO() as string)
          .replace(':endDate', DateTime.fromJSDate(e.new.endDate).toUTC().toISO() as string)
      });
    }
  }, 200);

  const onDateChange = (e) => {
    e.changed && debouncy(e);
  };

  const { fetch: fetchFile, error: errorImage } = useFetch<string>();

  useEffect(() => {
    if (errorImage) {
      setToastNotifications([{ message: tCommon('errors.loadingImage') }]);
    }
  }, [errorImage]);

  const calendarConfig: BryntumCalendarProps = useMemo(() => {
    return {
      crudManager: {
        //There are the statement for the calendar
        autoLoad: false,
      },
      onDateChange: async (e) => {
        if (ref)
          setViewDescription(
            ref?.current?.instance.tbar.widgetMap.viewDescription.element.innerText
          );
      },
      onDateRangeRequested: (e) => {
        onDateChange(e);
      },
      stateful: ['date'],
      onBeforeDestroy: (e) => {
        const source: Calendar = e.source as Calendar;
        saveStateConfigBryntum(source, CalendarNames.MY_CALENDAR);
      },
      onActiveItemChange: (e) => {
        setView(e.activeItem.ref as ViewType);
        if (ref)
          setViewDescription(
            ref?.current?.instance.tbar.widgetMap.viewDescription.element.innerText
          );
      },
      multiEventSelect: true,
      timeRangesFeature: {
        showCurrentTimeLine: true
      },
      dateFormat: 'DD MMM',
      eventEditFeature: false,
      autoCreate: false,
      dragFeature: false,
      draggable: false,
      listeners: {
        // Useful funtions for navigation between YearView and DayView
        // scheduleDblClick: (event) => {
        //   console.log(event);
        // },
        // dayNumberClick: (eventData) => {
        //   console.log('dayNumberClick', eventData);
        //   return false;
        // }
      },
      modes: {
        agenda: {
          emptyText: t('text.agendaEmptyText'),
          listRangeMenu: {
            items: {
              listRangeYearItem: null,
              listRangeDecadeItem: null
            }
          }
        },
        month: {
          eventRenderer: ({ eventRecord, renderData }) => {
            renderData.style = {
              color: pickTextColorBasedOnBgColor(
                eventRecord?.eventColor as string,
                theme.palette.common.white,
                theme.palette.primary.main
              )
            };
          },
          overflowPopupTrigger: 'click'
        },
        year: {
          eventRenderer: ({ eventRecord, renderData }) => {
            renderData.style = {
              color: pickTextColorBasedOnBgColor(
                eventRecord?.eventColor as string,
                theme.palette.common.white,
                theme.palette.primary.main
              )
            };
          }
        }
      },
      //TODO: Review this
      loadOnDemand: {
        clearOnNewRange: true
      },
      tbar: {
        hidden: true
      },
      // sidebar: {
      //   items: {
      //     eventFilter: false
      //   }
      // },
      eventTooltipFeature: {
        showOn: 'hover',
        align: 'l-r',
        minWidth: null,
        closable: false,
        tools: {
          delete: false,
          edit: false
        },

        renderer: async ({
          eventRecord: { originalData }
        }: EventTooltipFeatureData<EventBryntum<EventDto>>) => {
          let jobs: JobDto[] | undefined;
          let resources: Record<string, ResourceDto> = {};
          const fill: ResourceDto['_id'][] = [];
          if (
            originalData.job &&
            checkIfAllValuesAreRepeated(originalData.job, Object.keys(localJobs))
          ) {
            jobs = originalData.job.map((originalJob: string) => localJobs[originalJob]);
          } else {
            if (originalData.job) {
              jobs = (
                await fetchJobForHint({
                  url: ADVANCED_SEARCH.replace(':entity', 'jobs'),
                  method: 'POST',
                  data: {
                    jobIds: originalData.job
                  }
                })
              ).jobs as JobDto[];
              addJobs(jobs);
            } else {
              jobs = undefined;
            }
          }

          originalData.resources.forEach(({ resource }) => {
            if (resource in localResources)
              resources = { ...resources, [resource]: localResources[resource] };
            else fill.push(resource);
          });

          if (fill.length > 0) {
            const result = (await fetchResourcesForHint({
              url: ADVANCED_SEARCH.replace(':entity', Entities.RESOURCES),
              method: 'POST',
              data: { resourceIds: fill }
            })) as ResourceQueryResult;

            addResources(result.resources);
            const newResult = result.resources.reduce<Record<string, ResourceDto>>(
              (acc, curr) => ({
                ...acc,
                [curr._id]: curr
              }),
              {}
            );
            resources = { ...resources, ...newResult };
          }

          const resourceProperties = originalData.resources.reduce<
            Record<string, Record<string, string>[]>
          >((acc, { roleName, resource }) => {
            const resourceWithProperties = { _id: resource };
            resources[resource]?.properties
              .filter((prop) => RESOURCE_LABEL_PROP_IDS.has(prop._id))
              .forEach((prop) => {
                resourceWithProperties[prop._id] = prop.value;
              });

            return {
              ...acc,
              [roleName]:
                roleName in acc
                  ? [...acc[roleName], resourceWithProperties]
                  : [resourceWithProperties]
            };
          }, {});

          const imagesUrls = await Promise.all(
            Object.keys(resourceProperties)
              .map((roleName) => resourceProperties[roleName])
              .reduce((acc, current) => acc.concat(current))
              .map((resource) => {
                if (resource[SystemProperties.RESOURCES_PICTURE]) {
                  return fetchFile({
                    url: FILES_URL_BY_ID.replace(
                      ':id',
                      resource[SystemProperties.RESOURCES_PICTURE]
                    )
                  });
                }
              })
          );
          const images = Object.keys(resourceProperties)
            .map((roleName) => resourceProperties[roleName])
            .reduce((acc, current) => acc.concat(current))
            .reduce(
              (acc, current, idx) => ({ ...acc, [current._id]: imagesUrls[idx] as string }),
              {}
            );

          return renderToString(
            <BryntumHint
              startDate={originalData.startDate}
              endDate={originalData.endDate}
              location={getEventLocation(originalData) as string}
              job={
                jobs?.map((job) =>
                  getPropertyValueDef(job.properties, SystemProperties.JOBS_NAME, '')
                ) ?? []
              }
              resources={Object.keys(resourceProperties).map((roleName) => (
                <>
                  <Typography key={roleName} variant="body1" className="b-resource-title">
                    {roleName}
                  </Typography>
                  {resourceProperties[roleName].map((resource, idx) => (
                    <div
                      key={resource._id}
                      style={{ marginTop: idx === 0 ? '0' : '4px' }}
                      className="b-hint-resource">
                      <Grid
                        sx={{
                          width: '24px',
                          height: '24px',
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                        {images[resource._id] &&
                        !((images[resource._id] as any) instanceof Error) ? (
                          <img
                            style={{
                              width: '24px',
                              height: '24px',
                              borderRadius: '20px',
                              border: `solid ${theme.palette.grey[400]} 1px`
                            }}
                            src={images[resource._id]}
                          />
                        ) : (
                          <FaUserCircle color={theme.palette.grey[400]} size={24} />
                        )}
                      </Grid>
                      {/* <ResourceAvatar key={idx} src={resource[SystemProperties.RESOURCES_PICTURE]} /> */}

                      <Typography component="div" variant="body2">
                        {`${
                          resource[SystemProperties.RESOURCES_FIRST_NAME]
                            ? `${resource[SystemProperties.RESOURCES_FIRST_NAME]} `
                            : ''
                        }${resource[SystemProperties.RESOURCES_NAME] ?? tCommon('unknown')} (${
                          resource[SystemProperties.RESOURCES_ID] ?? tCommon('unknown')
                        })`}
                      </Typography>
                    </div>
                  ))}
                </>
              ))}
            />
          );
        },
        onBeforeShow: ({ source: tooltip }) => {
          tooltip.html = renderToString(<Loading />);
          tooltip.title = StringHelper.encodeHtml(tooltip.eventRecord.name);
        }
      }
    };
  }, []);

  useWindowUnloadEffect(() => {
    const calendarScheduler = (ref as RefObject<BryntumCalendar>)?.current?.instance;
    calendarScheduler && saveStateConfigBryntum(calendarScheduler, SchedulerNames.CALENDAR);
  }, true);

  return (
    <MainContainer>
      <BryntumToolbar sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        <Grid container gap={'0.5rem'} alignItems={'center'}>
          <Tooltip title={t('buttons.openMenu')}>
            <ButtonSettings onClick={toogleMenuSidebar}>
              <i className="b-icon b-fa-ellipsis-vertical" />
            </ButtonSettings>
          </Tooltip>
          <Tooltip title={t('buttons.goToToday')}>
            <ButtonSettings onClick={goToToday}>
              <i className="b-icon b-fa-calendar-day" />
            </ButtonSettings>
          </Tooltip>
          <Tooltip title={t('buttons.previous')}>
            <ButtonSettings onClick={previousPeriod}>
              <i className="b-icon b-fa-angle-left" />
            </ButtonSettings>
          </Tooltip>
          <Tooltip title={t('buttons.next')}>
            <ButtonSettings onClick={nextPeriod}>
              <i className="b-icon b-fa-angle-right" />
            </ButtonSettings>
          </Tooltip>
          <Typography variant="body1" marginTop={'2px'}>
            {viewDescription}
          </Typography>
        </Grid>
        <ToggleButtonGroup
          size="small"
          value={view}
          exclusive
          onChange={handleView}
          aria-label="text alignment">
          <ToggleButton value="day">
            <Typography variant="caption">{t('views.day')}</Typography>
          </ToggleButton>
          <ToggleButton value="week">
            <Typography variant="caption">{t('views.week')}</Typography>
          </ToggleButton>
          <ToggleButton value="month">
            <Typography variant="caption">{t('views.month')}</Typography>
          </ToggleButton>
          <ToggleButton value="year">
            <Typography variant="caption">{t('views.year')}</Typography>
          </ToggleButton>
          <ToggleButton value="agenda">
            <Typography variant="caption">{t('views.agenda')}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </BryntumToolbar>
      <BryntumCalendar
        onBeforeDestroy={(e) => {
          const source: Calendar = e.source as Calendar;
          saveStateConfigBryntum(source, SchedulerNames.CALENDAR);
        }}
        onEventDblClick={eventDblClick}
        scheduleMenuFeature={scheduleMenuFeature}
        eventMenuFeature={eventMenuFeature}
        ref={ref}
        {...calendarConfig}
      />
    </MainContainer>
  );
};

export default MyCalendarTemplate;
