import { LabeledReference, PropertyValue, Traceability } from '.';

export type JobQueryResult = {
  count: number;
  jobs: JobDto[];
};

export type MultiJobDto = {
  properties: (PropertyValue & { multiple?: boolean; readOnly?: boolean })[];
  curriculum: Partial<LabeledReference> & { multiple?: boolean; readOnly?: boolean };
  status: { value?: StructureStatus; multiple?: boolean };
};

export type JobDto = {
  _id: string;
  curriculum: LabeledReference;
  status?: StructureStatus;
  beingScheduled: boolean;
  properties: PropertyValue[];
} & Traceability;

export type DatedJobSerie = { date: string; count: number };

type JobSeriesDto =
  | {
      distributed: { count: number };
    }
  | {
      dated: DatedJobSerie[];
    };

export type CreateJobDto = {
  series?: JobSeriesDto;
} & JobDto;

export enum StructureStatus {
  MULTIPLE_STATUSES = 'MULTIPLE_STATUSES',
  NOT_SCHEDULED = 'NOT_SCHEDULED',
  PARTIALLY_SCHEDULED = 'PARTIALLY_SCHEDULED',
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
  UNKNOWN = 'UNKNOWN'
}

export type JobDetailsDto = {
  _id: string;
  sessions: {
    id: string;
    name: string;
    status: StructureStatus;
    topics: { id: string; name: string; status: StructureStatus; color?: string }[];
    color?: string;
  }[];
} & Traceability;
