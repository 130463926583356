import { styled } from '@mui/material/styles';

export const ComponentWrapper = styled('div')`
  width: 100%;
  height: fit-content;
`;

export const InputWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;

export const PresetHeader = styled('div')`
  position: relative;
  display: flex;
  margin: 0.5rem 0;
  height: 1.5rem;
  justify-content: space-between;
  align-items: start;
`;

export const PresetColorsContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 5.7px;
  width: 100%;
`;
