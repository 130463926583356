import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const WarningRole = () => {
  const { t: tCommon } = useTranslation();
  return (
    <div
      style={{
        display: 'grid',
        height: '100%',
        placeContent: 'center',
        placeItems: 'center'
      }}>
      <Typography variant="h6">{tCommon('text.noPermissions')} </Typography>
    </div>
  );
};

export default WarningRole;
