import { Permission } from '@/components/templates/EntityPanels/RolePanelTemplate/RolePanelTemplate.schema';
import { ResourceDto } from '@/types';
import { User } from 'firebase/auth';
import { Dispatch, SetStateAction, createContext } from 'react';

export type AuthContextType = {
  user: User | null;
  userTenant: string | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  setTokenInfo: Dispatch<any>;
  userResource?: ResourceDto | null;
  userPermissions?: Permission[];
  errorPermissions: any;
  errorUserResource: any;
  loading: boolean;
};

export default createContext<AuthContextType>({} as AuthContextType);
