import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SuccessCardContainer = styled(Grid)`
  width: 100%;
  position: relative;
  border-radius: 10px;
  height: 100%;
`;

export const SuccessCardInnerContainer = styled('div')`
  width: calc(100% - 4px);
  height: 100%;
  position: relative;
  left: 4px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 10px;
`;
