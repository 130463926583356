import { RenderIf } from '@/components/atoms';
import { CollapsableCard, PresetButton } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import PropertiesForm from '@/components/organisms/PropertiesForm/PropertiesForm';
import { PresetModes } from '@/components/templates/PropertyPresetPanelTemplate/PropertyPresetPanelTemplate.const';
import {
  COLLECTIONS_BATCH_CREATE,
  COLLECTIONS_FIND_ALL,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  Entities,
  EntityRoutes,
  PROPERTIES_BY_ENTITY,
  PROPERTY_SWITCHES_BY_ENTITY_TYPE,
  SystemProperties
} from '@/const';
import { useModalConfirmationContext } from '@/context/ModalConfirmationContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch, useGet, useScrollToError } from '@/hooks';
import useGetDefaultPresetByEntity from '@/hooks/useGetDefaultPresetByEntity';
import { ButtonsContainer, theme } from '@/theme';
import {
  Properties,
  PropertyDto,
  PropertySwitchDto,
  RecordDocumentDto,
  RecordDto,
  ResourceDto
} from '@/types';
import { getPropertyValue, getResourceFullName } from '@/utils';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  filterPropsByEntityData,
  filterPropsBySwitches,
  formatForm,
  getDefaultPropertiesAsFormValues
} from '../../ResourcePanelTemplate.const';
import { TruncatedTypography, defaultStyles } from './RecordEditionView.styles';

//TODO Create an enum to define the mode. cause' it's used in more than one location.

export type RecordEditionViewProps = {
  resource?: ResourceDto;
  resourceIds?: string[];
  mode: Exclude<CrudModes, 'read'>;
  document: RecordDocumentDto;
  recordId?: string;
  onDirtyFields: (isDirty: boolean) => unknown;
  closeAction: () => unknown;
  sucessAction: (record: any) => unknown;
};

const RecordEditionView = ({
  closeAction,
  sucessAction,
  onDirtyFields,
  mode,
  resource,
  document,
  recordId,
  resourceIds
}: RecordEditionViewProps) => {
  const { t: tResourcePanel } = useTranslation('templates/resourcePanelTemplate');
  const { t: tCommon } = useTranslation();
  const { setToastNotifications } = useToastContext();
  const { showConfirmation } = useModalConfirmationContext();
  const {
    data: recordProperties,
    error: errorProperties,
    loading: loadingProperties
  } = useGet<PropertyDto[]>(
    {
      url: PROPERTIES_BY_ENTITY.replace(':entity_name', 'resourcerecords')
    },
    { revalidateOnMount: true }
  );

  const isMultiple = useMemo(() => resourceIds && resourceIds?.length > 1, [resourceIds]);

  const {
    data: propertySwitch,
    fetch: fetchPropertySwitches,
    error: switchErrors,
    loading: loadingSwitches
  } = useFetch<PropertySwitchDto[]>();

  const [resourceProperties, setResorceProperties] = useState<{ fullName: string; id: string }>();

  useEffect(() => {
    if (resource) {
      const fullName = getResourceFullName(resource.properties) ?? tCommon('missing.name');
      const id =
        (getPropertyValue(resource.properties, SystemProperties.RESOURCES_ID) as string) ??
        tCommon('missing.id');
      setResorceProperties({ fullName, id });
    }
  }, [resource]);

  const { control, handleSubmit, reset, formState, setError, setValue } = useForm<Properties>({
    mode: mode === CrudModes.CREATE ? 'onSubmit' : 'onChange',
    defaultValues: undefined
  });
  const { toScroll } = useScrollToError();

  const { defaultValues, changeDefaultValues, loadingDefaultValues, errorDefaultValues } =
    useGetDefaultPresetByEntity(
      Entities.RESOURCERECORDS,
      mode == CrudModes.CREATE,
      document.type._id
    );

  useEffect(() => {
    if (defaultValues?.data?.properties && properties) {
      defaultValues?.data?.properties.forEach((property) => {
        setValue(
          `properties.${property._id}`,
          property.label ? { label: property.label, _id: property.value } : property.value,
          { shouldDirty: defaultValues.type === PresetModes.CUSTOM }
        );
      });
    }
  }, [defaultValues, recordProperties]);

  const {
    loading: loadingRecord,
    data: recordData,
    error: errorRecord,
    fetch: fetchRecords
  } = useFetch<RecordDto>();

  useEffect(() => {
    if (recordProperties && recordId && mode == CrudModes.EDIT) {
      const query: AxiosRequestConfig = {
        url: COLLECTIONS_FIND_ONE.replace(
          ':collection_name',
          EntityRoutes.RESOURCE_RECORDS
        ).replace(':id', recordId)
      };
      fetchRecords(query);
    }
  }, [recordProperties]);

  useEffect(() => {
    if (mode === CrudModes.EDIT && recordProperties && propertySwitch) {
      if (recordData) {
        const newValues = getDefaultPropertiesAsFormValues(
          filterPropsByEntityData(recordProperties, recordData, propertySwitch),
          recordData
        );
        reset({ properties: newValues } as Properties);
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.resourceNotFound', {
              resource: tCommon('entities.resourcerecords_one')
            })
          }
        ]);
        closeAction();
      }
    }
  }, [recordData, recordProperties, propertySwitch]);

  useEffect(() => {
    if (mode === CrudModes.CREATE && document && document.type._id) {
      fetchPropertySwitches({
        url: PROPERTY_SWITCHES_BY_ENTITY_TYPE.replace(':entity', Entities.RESOURCERECORDS).replace(
          ':entityType',
          document.type._id
        )
      });
    } else if (mode === CrudModes.EDIT && recordData && recordData.document.type._id) {
      fetchPropertySwitches({
        url: PROPERTY_SWITCHES_BY_ENTITY_TYPE.replace(':entity', Entities.RESOURCERECORDS).replace(
          ':entityType',
          recordData.document.type._id
        )
      });
    }
  }, [document, recordData]);

  const {
    data: submitedData,
    loading: loadingSubmitOfData,
    error: errorSubmit,
    fetch: fetchSubmit
  } = useFetch<any>();

  useEffect(() => {
    if (errorProperties || errorRecord || errorSubmit || switchErrors) {
      if (errorRecord?.response?.data?.code == 'resource_record_not_found') {
        setToastNotifications([
          {
            message: tCommon('errors.entitiesNotFound', {
              entity: tCommon('entities.resourcerecords_other')
            })
          }
        ]);
      } else if (errorSubmit) {
        if (errorSubmit?.response?.data?.code == 'resource_record_not_found') {
          setToastNotifications([
            {
              message: tCommon('errors.entitiesNotFound', {
                entity: tCommon('entities.resourcerecords_other')
              })
            }
          ]);
        } else if (errorSubmit?.response?.data?.code == 'invalid_document') {
          setToastNotifications([
            {
              message: tCommon('errors.entitiesNotFound', {
                entity: tCommon('entities.documents_one')
              })
            }
          ]);
        } else if (errorSubmit?.response?.data?.code == 'invalid_resource') {
          setToastNotifications([
            {
              message: tCommon('errors.entitiesNotFound', {
                entity: tCommon('entities.resources_one')
              })
            }
          ]);
        } else if (errorSubmit?.response?.data?.code == 'invalid_resources') {
          setToastNotifications([{ message: tResourcePanel('errors.resources') }]);
        } else if (
          errorSubmit?.response?.data?.params &&
          errorSubmit.response.data.code == 'duplicate_unique_property'
        ) {
          for (const param of errorSubmit.response.data.params) {
            setError(`properties.${param}`, {
              message: tCommon('errors.duplicateProperty'),
              type: 'manual'
            });
          }
        } else if (
          errorSubmit?.response?.data?.params &&
          errorSubmit.response.data.code == 'invalid_property_value'
        ) {
          for (const param of errorSubmit.response.data.params) {
            setError(`properties.${param}`, {
              message: tCommon('errors.invalidValue'),
              type: 'manual'
            });
          }
        } else {
          setToastNotifications([
            {
              message: tCommon('errors.actionError', {
                action:
                  mode === CrudModes.CREATE
                    ? tCommon('actions.creation')
                    : tCommon('actions.edition'),
                entity: isMultiple
                  ? tCommon('entities.resourcerecords_other')
                  : tCommon('entities.resourcerecords_one')
              })
            }
          ]);
        }
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.loadingEntity', {
              entity: isMultiple
                ? tCommon('entities.resourcerecords_other')
                : tCommon('entities.resourcerecords_one')
            })
          }
        ]);
        closeAction();
      }
    }
  }, [errorProperties, errorRecord, errorSubmit, switchErrors]);

  const submit = ({ properties }: Properties) => {
    let query: AxiosRequestConfig | undefined = undefined;
    switch (mode) {
      case CrudModes.CREATE:
        if (document && resource && recordProperties && propertySwitch) {
          const formattedForm = formatForm(
            filterPropsBySwitches(recordProperties, propertySwitch),
            properties
          );
          query = {
            url: COLLECTIONS_FIND_ALL.replace(':collection_name', EntityRoutes.RESOURCE_RECORDS),
            method: 'POST',
            data: {
              document: { _id: document._id },
              resource: { _id: resource._id },
              properties: formattedForm
            }
          };
        }
        if (document && isMultiple && recordProperties && propertySwitch) {
          const formattedForm = formatForm(
            filterPropsBySwitches(recordProperties, propertySwitch),
            properties
          );
          query = {
            url: COLLECTIONS_BATCH_CREATE.replace(
              ':collection_name',
              EntityRoutes.RESOURCE_RECORDS
            ),
            method: 'POST',
            data: {
              document: { _id: document._id },
              resources: resourceIds,
              properties: formattedForm
            }
          };
        }
        break;
      case CrudModes.EDIT:
        if (recordId && recordProperties && recordData && propertySwitch) {
          const formattedform = formatForm(
            filterPropsByEntityData(recordProperties, recordData, propertySwitch),
            properties
          );
          query = {
            url: COLLECTIONS_FIND_ONE.replace(
              ':collection_name',
              EntityRoutes.RESOURCE_RECORDS
            ).replace(':id', recordId),
            method: 'PATCH',
            data: {
              properties: formattedform
            }
          };
        }
        break;
    }
    if (query) fetchSubmit(query);
  };
  useEffect(() => {
    if (submitedData) {
      sucessAction(submitedData);
      closeAction();
    }
  }, [submitedData]);

  useEffect(() => {
    onDirtyFields(Object.keys(formState.dirtyFields).length > 0);
  }, [formState]);

  const properties = useMemo(() => {
    if (!recordProperties || !propertySwitch) {
      return [];
    }
    const filteredProperties = filterPropsBySwitches(recordProperties, propertySwitch);
    if (isMultiple) {
      return filteredProperties.map((prop) => (prop.unique ? { ...prop, disabled: true } : prop));
    }
    return filteredProperties;
  }, [recordProperties, propertySwitch, isMultiple]);

  return (
    <>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {isMultiple
            ? tResourcePanel(`recordTab.title.createMulti`, {
                quantity: resourceIds?.length ?? 0
              })
            : tResourcePanel(`recordTab.title.${mode}`)}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '2rem 2rem' }}>
        <RenderIf condition={!!mode && !!document}>
          <Grid sx={{ paddingBottom: '2rem', flexWrap: 'wrap' }} container spacing={2}>
            <Grid height="7.5rem" position="relative" xs={12} sm={12} md={6}>
              <CollapsableCard
                title={
                  <>
                    <Typography variant="overline" lineHeight={0.8} color="text.secondary">
                      {isMultiple
                        ? tCommon('entities.resources_other')
                        : tCommon('entities.resources_one')}
                    </Typography>
                    {isMultiple ? (
                      <TruncatedTypography variant="h6" style={{ marginTop: '0.6rem' }}>
                        {tCommon('text.countAndItem', {
                          n: resourceIds?.length ?? 0,
                          item: tCommon('entities.resources_other')
                        })}
                      </TruncatedTypography>
                    ) : (
                      <>
                        <TruncatedTypography variant="h6" style={{ marginTop: '0.6rem' }}>
                          {resourceProperties?.fullName}
                        </TruncatedTypography>
                        <Typography
                          lineHeight={1}
                          style={{ marginTop: '0.5rem', marginBottom: -0.2 }}>
                          {resourceProperties?.id}
                        </Typography>{' '}
                      </>
                    )}
                  </>
                }
                cardProps={
                  isMultiple
                    ? {
                        sx: {
                          ...defaultStyles,
                          top: '0.5rem',
                          left: '1.5rem',
                          zIndex: 10
                        }
                      }
                    : undefined
                }
              />
              {isMultiple && (
                <>
                  <CollapsableCard
                    cardProps={{
                      sx: {
                        ...defaultStyles,
                        opacity: 0.7,
                        left: '1.1rem',
                        top: '0.9rem',
                        zIndex: 5
                      }
                    }}
                  />
                  <CollapsableCard
                    cardProps={{
                      sx: { ...defaultStyles, opacity: 0.5, left: '0.7rem', top: '1.3rem' }
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid height="7.5rem" position="relative" xs={12} sm={12} md={6}>
              <CollapsableCard
                cardProps={{
                  sx: {
                    ...defaultStyles
                  }
                }}
                title={
                  <>
                    <Typography variant="overline" lineHeight={0.8} color="text.secondary">
                      {tCommon('entities.documents_one')}
                    </Typography>
                    <TruncatedTypography variant="h6" style={{ marginTop: '0.6rem' }}>
                      {document.label ?? tCommon('missing.name')}
                    </TruncatedTypography>
                    <Typography lineHeight={1} style={{ marginTop: '0.5rem', marginBottom: -0.2 }}>
                      {document.type.label ?? tCommon('missing.type')}
                    </Typography>
                  </>
                }></CollapsableCard>
            </Grid>
          </Grid>
          <RenderIf condition={loadingProperties || loadingRecord || loadingSwitches}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress />
            </Box>
          </RenderIf>
          <RenderIf condition={!loadingRecord && !loadingProperties && !loadingSwitches}>
            {recordProperties && propertySwitch && properties && (
              <PropertiesForm
                properties={properties}
                control={control}
                formState={formState}
                propertySwitches={propertySwitch}
              />
            )}
          </RenderIf>
        </RenderIf>
      </BodyModalContainer>
      <FooterModalContainer
        sx={mode == CrudModes.CREATE ? { justifyContent: 'space-between' } : {}}>
        <RenderIf condition={CrudModes.CREATE == mode}>
          <PresetButton
            disabled={loadingSubmitOfData || !!errorDefaultValues}
            loading={loadingDefaultValues || loadingProperties || loadingRecord || loadingSwitches}
            entity={Entities.RESOURCERECORDS}
            onSelect={changeDefaultValues}></PresetButton>
        </RenderIf>
        <ButtonsContainer>
          <Button
            disabled={loadingSubmitOfData}
            onClick={async () => {
              if (Object.keys(formState.dirtyFields).length > 0) {
                const continueOnClose = await showConfirmation({
                  title: tCommon('modalConfirmation.title'),
                  message: (
                    <>
                      <Typography component={'span'}>
                        {tCommon('modalConfirmation.messageWEntity', {
                          entityName: tCommon('entities.resourcerecords_one')
                        })}
                      </Typography>
                      <br />
                      <br />
                      <Typography component={'span'} marginTop={'1rem'}>
                        {tCommon('modalConfirmation.leave_simple')}
                      </Typography>
                    </>
                  ),
                  isReactNode: false,
                  confirmButton: tCommon('buttons.leave'),
                  cancelButton: tCommon('buttons.cancel')
                });
                continueOnClose && closeAction();
              } else {
                closeAction();
              }
            }}>
            {tCommon('buttons.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loadingSubmitOfData}
            disabled={
              Object.keys(formState.errors).length > 0 ||
              (Object.keys(formState.dirtyFields).length === 0 && mode === CrudModes.EDIT) ||
              loadingSwitches ||
              loadingProperties ||
              loadingRecord ||
              (mode === CrudModes.EDIT &&
                recordProperties &&
                propertySwitch &&
                filterPropsBySwitches(recordProperties, propertySwitch).length === 0)
            }
            onClick={handleSubmit(submit, toScroll)}>
            {tCommon('buttons.save')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </>
  );
};
export default RecordEditionView;
