import { ADVANCED_SEARCH, Entities } from '@/const';
import { DocumentQueryResult } from '@/types';
import axios from 'axios';
import { useCallback, useState } from 'react';

export const useGetDocuments = () => {
  const [data, setData] = useState<DocumentQueryResult>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = useCallback(async (documentIds: string[]) => {
    try {
      setLoading(true);
      if (documentIds) {
        let result;
        if (documentIds.length > 0) {
          const docsResponse = await axios.post(
            ADVANCED_SEARCH.replace(':entity', Entities.DOCUMENTS),
            { documentIds }
          );
          result = docsResponse.data;
        } else {
          result = { documents: [], count: 0 };
        }
        setData(result);
        return result;
      }
      return [];
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    loading,
    error,
    fetch
  };
};
