import { styled } from '@mui/material';

export const SessionContainer = styled('div')<{ height: string; scale: number }>`
  position: relative;
  min-height: 2.6rem;
  height: ${(props) => props.height};
  width: ${(props) => props.scale * 100}%;
  transition: width 0.5s ease;
  z-index: 10;
`;

export const OuterContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
