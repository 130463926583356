import { EntityFilter } from '@/components/organisms';
import { Slider, styled } from '@mui/material';

export const SliderStyled = styled(Slider)`
  width: 200px;
  margin-right: 12px;
`;

export const FiltersContainer = styled('div')<{ $width: number }>`
  flex-direction: column;
  height: 100%;
  width: ${({ $width }) => $width}px;
  opacity: ${({ $width }) => ($width ? 1 : 0)};
  background-color: ${({ theme }) => theme.palette.common.white};
  position: relative;
  overflow: hidden;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  @media (max-width: 576px) {
    width: calc(100vw);
  }
`;

export const CalendarContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
`;

export const Filter = styled(EntityFilter)<{ $width: number }>`
  width: ${({ $width }) => $width}px;
  opacity: ${({ $width }) => ($width ? 1 : 0)};
  overflow: hidden;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

export const ScheduleContainer = styled('div')`
  flex: 1;
  display: flex;
  min-height: 100px;
  flex-direction: row;
  align-items: stretch;
  min-height: 200px;
`;
export const GanttContainer = styled('div')`
  flex: 1;
  display: flex;
  min-height: 100px;
  flex-direction: row;
  align-items: stretch;
  min-height: 200px;
`;
