import { RenderIf } from '@/components/atoms';
import CardAccordion from '@/components/molecules/Card/Card';
import { FiltersSections, PropertyTypes } from '@/const';
import { ValueOf, settingsType } from '@/context/FilterContext';
import { ChangeEvent, MouseEventHandler, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { GlobalFilterProps } from './GlobalFilter.types';
import { GlobalFilterBody, GlobalFilterHeader, GlobalFilterLoading } from './components';

const GlobalFilter = ({
  title,
  error,
  fields,
  loading,
  onSuccess,
  helperText,
  selected,
  setSelected,
  defaultValues: defaultValuesProp = [],
  propertiesRef,
  entity,
  ...props
}: GlobalFilterProps) => {
  const defaultValues = defaultValuesProp.reduce<Record<string, unknown>>(
    (acc, { _id, value }) => ({
      ...acc,
      [_id]: value
    }),
    {}
  );

  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues: defaultValues as Record<string, string>
  });

  const onFilter = (form: Record<string, unknown>) => {
    const filters = fields
      .filter(
        ({ _id }) =>
          (selected as NonNullable<ValueOf<settingsType>>).includes(_id) &&
          form[_id] !== undefined &&
          form[_id] !== null &&
          form[_id] !== '' &&
          !(typeof form[_id] === 'object' && Object.keys(form[_id] as unknown[]).length === 0)
      )
      .map((filter) => {
        return { ...filter, value: form[filter._id] };
      });
    onSuccess(filters);
    return filters;
  };

  const handleSelected = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setSelected((before) => {
      const copyList = [...(before as NonNullable<ValueOf<settingsType>>)];
      if (copyList.includes(value)) {
        const idx = copyList.findIndex((item) => item === value);
        copyList.splice(idx, 1);
      } else copyList.push(value);
      return copyList;
    });
  };

  const onClear: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const resetValues = fields.reduce((acc, { _id, type }) => {
      return {
        ...acc,
        [_id]: [PropertyTypes.OBJECTID, PropertyTypes.SET].includes(type as any) ? [] : ''
      }; //TODO: includes sets too.
    }, {});
    reset(resetValues);
  };

  useEffect(() => {
    if ((selected === undefined || selected.length == 0) && fields && !loading) {
      setSelected(fields.map(({ _id }) => _id));
    }
  }, [fields, selected, loading]);

  return (
    <>
      <form onSubmit={handleSubmit(onFilter)} noValidate>
        <CardAccordion
          persistKey={entity + FiltersSections.PROPERTIES}
          title={''}
          actions={
            <GlobalFilterHeader
              filterRef={propertiesRef}
              error={error}
              title={title}
              fields={fields}
              loading={loading}
              onClear={onClear}
              selected={selected}
              onSelected={handleSelected}
            />
          }>
          <RenderIf condition={loading}>
            <GlobalFilterLoading />
          </RenderIf>

          <RenderIf condition={!loading}>
            <GlobalFilterBody
              fields={fields}
              control={control as Control<Record<string, unknown>, unknown>}
              errors={formState.errors}
              selected={selected}
              error={error}
              helperText={helperText}
            />
          </RenderIf>
        </CardAccordion>
      </form>
    </>
  );
};

export default GlobalFilter;
