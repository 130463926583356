import { RenderIf } from '@/components/atoms';
import { TextFilter } from '@/components/molecules';
import {
  BodyModalContainer,
  DropFile,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { DATE_FORMAT_PICKER, DelayTimes } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { ButtonsContainer, Container, theme } from '@/theme';
import { GridColumnConfig } from '@bryntum/core-thin';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, MenuItem, Slide, Typography } from '@mui/material';
import { cloneDeep, isArray } from 'lodash';
import { DateTime } from 'luxon';
import { ReactElement, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { WorkBook, read } from 'xlsx';
import { FileSubmit, ImportPreview } from './components';

export type ResourceImportPanelTemplateProps = {
  onSuccess: (data: any) => unknown;
  onCancel: () => unknown;
  onLoadedData: (isLoaded: boolean) => unknown;
  onLoading?: (loading: boolean) => unknown;
};

const ResourceImportPanelTemplate = ({
  onSuccess,
  onCancel,
  onLoadedData,
  onLoading
}: ResourceImportPanelTemplateProps) => {
  const { t } = useTranslation('templates/resourceImportPanelTemplate');
  const { t: tCommon } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [workbook, setWorkBook] = useState<WorkBook>();
  const [sheetList, setSheetList] = useState<string[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<string>('');
  const [firstHeader, setFirstHeader] = useState<boolean>(false);
  const { setToastNotifications } = useToastContext();
  const [previewComponent, setPreviewComponent] = useState<ReactElement<any, any> | undefined>(
    undefined
  );
  useEffect(() => {
    onLoadedData(!!previewComponent);
  }, [previewComponent]);
  const [submitComponent, setSubmitComponent] = useState<ReactElement<any, any> | undefined>(
    undefined
  );
  const handleFileChange = (file?: File) => {
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const workbook = read(e.target?.result, {
            type: file.type === 'text/csv' ? 'string' : 'buffer',
            sheetRows: 5,
            dense: true,
            raw: true,
            cellDates: true
          });
          setWorkBook(workbook);
          setSheetList(workbook.SheetNames);
          setSelectedSheet(workbook.SheetNames[0]);
        } catch (error) {
          setToastNotifications([{ message: tCommon('errors.readingFile') }]);

          setSelectedFile(undefined);
        }
      };
      file.type === 'text/csv' ? reader.readAsText(file, 'utf8') : reader.readAsArrayBuffer(file);
    } else {
      setSelectedFile(undefined);
    }
  };

  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);

  const previewData = useCallback(() => {
    if (selectedFile && workbook) {
      setLoadingPreview(true);
      const worksheet = workbook.Sheets[selectedSheet];
      const jsonData = cloneDeep(worksheet['!data'])?.filter((row) => isArray(row));
      if (jsonData && jsonData.length > 0) {
        const lengths = jsonData.map((value) => value.length);
        const maxLength = Math.max(...lengths);
        const columnNames: string[] = new Array(maxLength)
          .fill('')
          .map((val, idx) =>
            firstHeader ? jsonData[0][idx]?.w || `Column_${idx}` : `Column_${idx}`
          );
        if (firstHeader) {
          jsonData.splice(0, 1);
        }

        const columns: GridColumnConfig[] = columnNames.map((columnName, idx) => ({
          id: `field_${idx}`,
          text: columnName,
          field: `field_${idx}`,
          sortable: false,
          groupable: false,
          renderer: ({ record: { data } }) => {
            return data[`field_${idx}`] || '';
          }
        }));

        const data: Record<string, any>[] = jsonData.map((value) => {
          return value.reduce((prevValue, currValue, currIdx) => {
            prevValue[`field_${currIdx}`] =
              currValue?.t === 'd'
                ? DateTime.fromJSDate(currValue.v as Date).toFormat(DATE_FORMAT_PICKER as string)
                : currValue?.w || '';
            return prevValue;
          }, {});
        });
        setPreviewComponent(
          <ImportPreview
            firstHeader={firstHeader}
            selectedFile={selectedFile}
            selectedSheet={selectedSheet}
            gridProperties={{
              filterBarFeature: false,
              stripeFeature: true,
              selectionMode: {
                row: true,
                cell: false,
                multiSelect: true
              },
              headerMenuFeature: false,
              cellMenuFeature: false,
              cellEditFeature: false,
              rowHeight: 32,
              data,
              columns
            }}
            onCancel={() => {
              setPreviewComponent(undefined);
            }}
            onSubmit={(data) => {
              setPreviewComponent(undefined);
              onLoading && onLoading(true);
              setSubmitComponent(
                <FileSubmit
                  file={selectedFile}
                  config={data}
                  onLoading={(loading) => {
                    onLoading && onLoading(loading);
                  }}
                  onClose={(data) => {
                    onLoading && onLoading(false);
                    onSuccess(data);
                  }}
                />
              );
            }}
          />
        );
      } else {
        setToastNotifications([{ message: tCommon('errors.emptyFile') }]);
      }
      setLoadingPreview(false);
    }
  }, [workbook, firstHeader, selectedSheet, selectedFile]);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Container ref={containerRef}>
      <>
        <HeaderModalContainer>
          <Typography
            color={theme.palette.common.white}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            gap={'0.2em'}
            variant="h5">
            {`${tCommon('actions.import')} ${tCommon('entities.resources_other')}`}
          </Typography>
        </HeaderModalContainer>
        <BodyModalContainer sx={{ padding: '2rem 2rem 0rem 2rem' }}>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem'
            }}>
            <DropFile
              style={{ width: '100%' }}
              onFileDrop={(data) => {
                handleFileChange(data);
              }}
              accept={['.csv', '.xlsx', '.xls']}
              label={Trans({ t, i18nKey: 'fileImport.text.dropHint' })}
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                minHeight: '4rem',
                padding: '0 1rem',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between'
              }}>
              <RenderIf condition={!!selectedFile}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ userSelect: 'none', pointerEvents: 'none' }}>
                    {t('fileImport.text.sheetName')}
                  </Typography>
                  <TextFilter
                    select
                    fullWidth={false}
                    value={selectedSheet}
                    sx={{ ml: '0.8rem' }}
                    label=""
                    size="small"
                    onChange={(ev) => {
                      setSelectedSheet(ev as SetStateAction<string>);
                    }}>
                    {sheetList.map((sheetName) => (
                      <MenuItem key={sheetName} value={sheetName}>
                        {sheetName}
                      </MenuItem>
                    ))}
                  </TextFilter>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    label={t('fileImport.text.firstRow')}
                    checked={firstHeader || false}
                    control={
                      <Checkbox size="small" onChange={(e) => setFirstHeader(e.target.checked)} />
                    }
                  />
                </div>
              </RenderIf>
            </div>
          </div>
        </BodyModalContainer>
        <FooterModalContainer>
          <ButtonsContainer>
            <Button onClick={onCancel}>{tCommon('buttons.cancel')}</Button>
            <LoadingButton
              variant="contained"
              loading={loadingPreview}
              disabled={!selectedFile || !selectedSheet}
              onClick={() => {
                previewData();
              }}>
              {tCommon('buttons.next')}
            </LoadingButton>
          </ButtonsContainer>
        </FooterModalContainer>
      </>
      <Slide
        direction="left"
        container={containerRef.current}
        in={!!previewComponent}
        easing={'linear'}
        timeout={DelayTimes.SLIDE_TRANSITION}
        mountOnEnter
        unmountOnExit>
        <Container
          sx={{
            position: 'absolute',
            display: 'flex',
            backgroundColor: theme.palette.common.white
          }}>
          {previewComponent}
        </Container>
      </Slide>
      <Slide
        direction="right"
        container={containerRef.current}
        in={!!submitComponent}
        easing={'linear'}
        timeout={DelayTimes.SLIDE_TRANSITION}
        mountOnEnter
        unmountOnExit>
        <Container
          sx={{
            position: 'absolute',
            display: 'flex',
            backgroundColor: theme.palette.common.white
          }}>
          {submitComponent}
        </Container>
      </Slide>
    </Container>
  );
};

export default ResourceImportPanelTemplate;
