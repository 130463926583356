import { EventDto } from '@/types';
import { PropsWithChildren, useCallback, useState } from 'react';
import CalendarContext from './CalendarContext';

export type AuthContextProviderProps = PropsWithChildren<unknown>;

const CalendarContextProvider = ({ children }: AuthContextProviderProps) => {
  const [events, setEvents] = useState<EventDto[]>();

  const addEvents = useCallback(
    (newEvents: EventDto[]) => {
      setEvents((oldEvents) => {
        if (oldEvents) {
          return [...oldEvents, ...newEvents];
        }
        return newEvents;
      });
    },
    [events]
  );

  const updateEvent = useCallback(
    (newEvent: EventDto) => {
      setEvents((oldEvents) =>
        oldEvents?.map((oldEvent) => {
          if (oldEvent._id === newEvent._id) {
            return newEvent;
          }
          return oldEvent;
        })
      );
    },
    [events]
  );

  const removeEvent = useCallback(
    (id: string) => {
      setEvents((oldEvents) => oldEvents?.filter((oldEvent) => oldEvent._id !== id) ?? []);
    },
    [events]
  );

  const handleEvents = useCallback((newEvent) => {
    setEvents(newEvent);
  }, []);

  return (
    <CalendarContext.Provider value={{ events, removeEvent, updateEvent, addEvents, handleEvents }}>
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarContextProvider;
