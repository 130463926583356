import { Loading } from '@/components/molecules';
import { ComponentType, ReactNode, Suspense } from 'react';

const withLazy = <T extends JSX.IntrinsicAttributes>(
  Component: ComponentType<T>,
  loading?: ReactNode
) => {
  return function wrapper(props: T) {
    return (
      <Suspense fallback={loading || <Loading />}>
        <Component {...(props as T)} />
      </Suspense>
    );
  };
};

export default withLazy;
