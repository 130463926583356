import { RenderIf } from '@/components/atoms';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { ButtonsContainer, Container, theme } from '@/theme';
import { RequestQualifiedResources, ResourceDto, ResourceSlot } from '@/types';
import { Model } from '@bryntum/core-thin';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QualifiedResourcesListView } from './components/QualifiedResourceListView';

export type ResourcePanelTemplateProps = {
  onClose: () => void;
  onSuccess: (resources: ResourceDto[]) => void;
  resourceSlot: ResourceSlot;
  availableQuantity?: number;
  readOnly?: boolean;
  qualifiedQuery: RequestQualifiedResources;
};

const QualifiedResourcePanelTemplate = ({
  onClose,
  onSuccess,
  resourceSlot,
  availableQuantity,
  readOnly = false,
  qualifiedQuery
}: ResourcePanelTemplateProps) => {
  const { t: tCommon } = useTranslation();
  const { t: tQualified } = useTranslation('templates/qualifiedResourcesPanelTemplate');
  const resourcesSchedulerRef = useRef<BryntumSchedulerPro>(null);
  const [isValidQuantity, setIsValidQuantity] = useState(true);

  const onChoose = () => {
    const scheduler = resourcesSchedulerRef.current?.instance;
    if (scheduler) {
      const resources = scheduler.selectedRecords.map(
        (record) => record.getData('resource') as ResourceDto
      );
      onSuccess(resources);
    }
  };

  const onSelectionChange = (records: Model[]) => {
    if (availableQuantity === undefined) return;
    if (records.length <= availableQuantity) {
      setIsValidQuantity(true);
    } else {
      setIsValidQuantity(false);
    }
  };

  return (
    <Container sx={{ overflow: 'hidden' }}>
      <Container>
        <HeaderModalContainer>
          <Typography
            color={theme.palette.common.white}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            gap={'0.2em'}
            variant="h5">
            {tQualified('title', {
              name: resourceSlot.name
            })}
          </Typography>
        </HeaderModalContainer>
        <BodyModalContainer sx={{ padding: '0', overflow: 'hidden' }}>
          <QualifiedResourcesListView
            ref={resourcesSchedulerRef}
            resourceSlot={resourceSlot}
            onSelectionChange={onSelectionChange}
            readOnly={readOnly}
            qualifiedQuery={qualifiedQuery}
            availableQuantity={availableQuantity}
            isValidQuantity={isValidQuantity}
          />
        </BodyModalContainer>
        <FooterModalContainer>
          <ButtonsContainer>
            <LoadingButton variant={readOnly ? 'contained' : 'text'} onClick={onClose}>
              {readOnly ? tCommon('buttons.close') : tCommon('buttons.back')}
            </LoadingButton>
            <RenderIf condition={!readOnly}>
              <LoadingButton
                disabled={!isValidQuantity || availableQuantity === 0}
                variant="contained"
                onClick={onChoose}>
                {tQualified('assign')}
              </LoadingButton>
            </RenderIf>
          </ButtonsContainer>
        </FooterModalContainer>
      </Container>
    </Container>
  );
};

export default QualifiedResourcePanelTemplate;
