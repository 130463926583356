import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { CrudModes } from '@/const';
import { ButtonsContainer, Container, theme } from '@/theme';
import { MultipleInputValue, ResourceSlot, TopicResourceSlot, isMultipleInputValue } from '@/types';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RenderIf } from '@/components/atoms';

import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ResourceSlotForm } from './ResourceSlotForm';
import TopicResourceSlotForm from './TopicResourceSlotForm/TopicResourceSlotForm';

export type ResourcePanelTemplateProps = {
  mode: CrudModes;
  isMultiple?: boolean;
  resourceSlot?: ResourceSlot;
  topicSlot?: {
    id: string;
    joinable?: boolean | MultipleInputValue;
    contentId?: string | MultipleInputValue;
  };
  onSuccess: (resourceSlot: ResourceSlot, topicSlot?: TopicResourceSlot) => unknown;
  onCancel: (isDirty?: boolean) => unknown;
  onDirtyFields: (isDirty: boolean) => unknown;
};

export type FormType = {
  resourceSlot: ResourceSlot;
  topicSlot?: {
    id: string;
    joinable?: boolean | MultipleInputValue;
    contentId?: string | MultipleInputValue;
  };
};

const ResourcePanelTemplate = ({
  mode,
  isMultiple,
  resourceSlot,
  topicSlot,
  onSuccess,
  onDirtyFields,
  onCancel
}: ResourcePanelTemplateProps) => {
  const methods = useForm<FormType>({
    mode: 'onSubmit',
    defaultValues: {
      resourceSlot,
      topicSlot
    }
  });

  const { formState } = methods;

  useEffect(() => {
    onDirtyFields(formState.isDirty);
  }, [formState.isDirty]);

  const { t: tResourceSlot } = useTranslation('organisms/resourceSlot');
  const { t: tCommon } = useTranslation('common');

  const getTopicSlot = (topicSlot: FormType['topicSlot']) => {
    if (topicSlot) {
      const formattedTopicSlot: TopicResourceSlot = { id: topicSlot.id };
      if (!isMultipleInputValue(topicSlot.joinable))
        formattedTopicSlot.joinable = topicSlot.joinable;
      if (!isMultipleInputValue(topicSlot.contentId))
        formattedTopicSlot.contentId = topicSlot.contentId;
      return formattedTopicSlot;
    }
  };
  const submit = async (form: FormType) => {
    onDirtyFields(false);
    const topicSlot = getTopicSlot(form.topicSlot);
    switch (mode) {
      case CrudModes.CREATE:
        onSuccess({ ...form.resourceSlot }, topicSlot);
        break;
      case CrudModes.EDIT:
        onSuccess(form.resourceSlot, topicSlot);
        break;
    }
  };

  return (
    <Container>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {tResourceSlot(`actions.${mode}`)}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '2rem 2rem 0rem 2rem', gap: '1rem' }}>
        <FormProvider {...methods}>
          <ResourceSlotForm mode={mode} />
          <RenderIf condition={!!topicSlot}>
            <TopicResourceSlotForm mode={mode} isMultiple={isMultiple} />
          </RenderIf>
        </FormProvider>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button
            variant={mode == CrudModes.READ ? 'contained' : 'text'}
            onClick={(e) => {
              onCancel(formState.isDirty);
            }}>
            {mode == CrudModes.READ ? tCommon('buttons.close') : tCommon('buttons.cancel')}
          </Button>
          <RenderIf condition={mode !== CrudModes.READ}>
            <LoadingButton
              variant="contained"
              disabled={
                Object.keys(methods.formState.errors).length > 0 ||
                (mode === CrudModes.EDIT && !methods.formState.isDirty)
              }
              onClick={methods.handleSubmit(submit)}>
              {tCommon('buttons.save')}
            </LoadingButton>
          </RenderIf>
        </ButtonsContainer>
      </FooterModalContainer>
    </Container>
  );
};

export default ResourcePanelTemplate;
