import { styled } from '@mui/material';

export const List = styled('div')`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  align-items: center;
  gap: 0.6rem;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 1rem;
`;

export const Options = styled('div')`
  width: 100%;
  position: sticky;
  overflow-y: hidden;
  min-height: 4rem;
  max-height: 4rem;
  top: 0;
  scrollbar-gutter: stable;
  padding: 1rem;
  right: 1rem;
  background-color: #fff;
  z-index: 10;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

export const SmallModalStyle = {
  height: '50vh',
  width: '65vw',
  minWidth: '28rem',
  minHeight: '26rem',
  maxWidth: '88rem',
  maxHeight: '90rem',
  borderRadius: '16px'
};

export const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
