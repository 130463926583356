import React, { useState } from 'react';

export const useMenu = <T extends HTMLElement = HTMLElement>(
  anchor?: T
): [T | undefined, (event: React.MouseEvent<T>) => void, () => void] => {
  const [anchorEl, setAnchorEl] = useState<T | undefined>(anchor);

  const handleClick = (event: React.MouseEvent<T>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return [anchorEl, handleClick, handleClose];
};
