import { AssignmentBryntum, EventDto, JoinedEventBryntum, ResourceDto } from '@/types';
import {
  transformToAssignmentBryntum,
  transformToEventBryntum,
  transformToJoinedEventBryntum
} from '@/utils';
import { TFunction } from 'i18next';

export const getAssignmentsFromEvents = (
  events?: EventDto[],
  resource?: ResourceDto,
  t?: TFunction
) => {
  const resourceEventsRender: JoinedEventBryntum[] = [];

  if (events && resource && t) {
    const joinedEventsRender = new Map<string, JoinedEventBryntum>();
    const joinedAssignmentBryntumByResource = new Map<string, AssignmentBryntum>();

    events.forEach((event) => {
      const eventBryntum = transformToEventBryntum(event);
      resourceEventsRender.push(eventBryntum);

      if (event.join) {
        let joinedEvent: JoinedEventBryntum;
        if (!joinedEventsRender.has(event.join)) {
          joinedEvent = transformToJoinedEventBryntum(event);
          joinedEventsRender.set(event.join, joinedEvent);

          event.resources
            .filter((resource) => resource.joined)
            .forEach((resource) => {
              const assignmentBryntum = transformToAssignmentBryntum(
                joinedEvent.id,
                resource.resource,
                true,
                true
              );
              joinedEvent?.joinedAssignments?.set(resource.resource, assignmentBryntum);
            });
        } else {
          joinedEvent = joinedEventsRender.get(event.join) as JoinedEventBryntum;
          if (joinedEvent.job) joinedEvent.job = [...joinedEvent.job, event.job];
        }
        joinedEvent['events'].push(eventBryntum);

        for (const [resourceId, assignmentBryntum] of joinedAssignmentBryntumByResource.entries()) {
          joinedEvent?.joinedAssignments
            ?.get(resourceId)
            ?.joinedAssignments?.push(assignmentBryntum);
        }
      }

      joinedAssignmentBryntumByResource.clear();
    });

    if (joinedEventsRender.size > 0) {
      for (const joinedEventRender of joinedEventsRender.values()) {
        const names = new Set<string>();
        const colors = new Set<string>();
        for (const event of joinedEventRender['events']) {
          names.add(event.name);
          colors.add(event.eventColor);
        }
        if (names.size == 1) {
          joinedEventRender.name = `${joinedEventRender.name}${t('text.joinedSuffix', {
            count: joinedEventRender['events'].length
          })}`;
        } else {
          joinedEventRender.name = t('text.joinedName', {
            count: joinedEventRender['events'].length
          });
        }
        if (colors.size == 1) {
          joinedEventRender.eventColor = colors.values().next().value as string;
        }
        (joinedEventRender.iconCls = 'b-fa b-fa-diamond'), delete joinedEventRender['events'];

        resourceEventsRender.push(joinedEventRender);
      }
    }
  }

  const joinedEventsArray = resourceEventsRender
    .filter((resourceEvent) => !!resourceEvent.joinedAssignments)
    .map((resourceEvent) => resourceEvent.id);
  return {
    resourceEventsRender,
    joinedEventsArray
  };
};
