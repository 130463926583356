import { RenderIf } from '@/components/atoms';
import { MultipleInputRow, NumberInput, TextFilter } from '@/components/molecules';
import FixedSelectFilter from '@/components/molecules/Autocomplete/FixedSelectFilter/FixedSelectFilter';
import { AutoCompleteField } from '@/const';
import { useCurriculumSetupContext } from '@/context/CurriculmSetupContext';
import { theme } from '@/theme';
import { CurriculumForm, MultipleInputValue, Session, TimeStrategy } from '@/types';
import { Box, Grid, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import React, { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';
import { getSessionIndex, shouldIgnore } from './SessionForm.const';
import { COMPONENTS_BY_TYPE } from '@/components/organisms/PropertiesForm/PropertiesForm.const';

const SessionForm = () => {
  const {
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext<CurriculumForm>();
  const { readOnly, selectedSessions } = useCurriculumSetupContext();

  const multiple = selectedSessions.length > 1 && !readOnly;

  if (!selectedSessions.length) return null;

  const isFirstOne = selectedSessions.length === 1 && selectedSessions[0].index === 0;

  const isLastOne =
    selectedSessions.length === 1 &&
    getValues('structure.sessions').length === selectedSessions[0].index + 1;

  const sessionIndex = getSessionIndex(selectedSessions);

  const { t: tSession } = useTranslation('organisms/session');
  const { t: tCurr } = useTranslation('templates/curriculumsTemplate');
  const sessions = useWatch({
    control,
    name: `structure.sessions`
  });

  const getValueAndPlaceholder = useCallback(
    <T extends keyof Session>(name: T) => {
      const sessions = getValues('structure.sessions');
      const values: Array<Session[T]> = [];
      for (const selectedSession of selectedSessions) {
        if (shouldIgnore(selectedSession, selectedSessions, sessions, name)) continue;
        const session = sessions[selectedSession.index];
        if (session) values.push(session[name]);
      }
      const emptyValues = ['', null];

      const uniqueValues = [
        ...new Set(
          values.map((v) => (emptyValues.some((emptyValue) => emptyValue === v) ? undefined : v))
        )
      ];
      if (uniqueValues.length === 1) {
        return { value: uniqueValues[0] };
      }
      return { value: { multiple: true } as MultipleInputValue };
    },
    [selectedSessions]
  );
  const onChange = useCallback(
    (name: keyof Session) => {
      return (value) => {
        for (const session of selectedSessions) {
          if (shouldIgnore(session, selectedSessions, sessions, name)) continue;
          setValue(`structure.sessions.${session.index}.${name}`, value, {
            shouldDirty: true
          });
        }
      };
    },
    [selectedSessions, sessions, setValue]
  );

  const clearSessionErrors = () => {
    selectedSessions.forEach((session) => {
      clearErrors(`structure.sessions.${session.index}.minDaysBefore`);
      clearErrors(`structure.sessions.${session.index}.maxDaysBefore`);
    });
  };

  const setSessionErrors = () => {
    selectedSessions.forEach((session) => {
      setError(`structure.sessions.${session.index}.minDaysBefore`, { type: 'min' });
      setError(`structure.sessions.${session.index}.maxDaysBefore`, { type: 'min' });
    });
  };

  const checkMinMax = () => {
    const minDaysBefore = getValues(`structure.sessions.${sessionIndex}.minDaysBefore`);
    const maxDaysBefore = getValues(`structure.sessions.${sessionIndex}.maxDaysBefore`);
    if (!minDaysBefore || !maxDaysBefore) {
      clearSessionErrors();
    } else if (minDaysBefore > maxDaysBefore) {
      setSessionErrors();
    } else {
      clearSessionErrors();
    }
  };

  const Component = COMPONENTS_BY_TYPE['color'];

  return (
    <Box display="flex" flexDirection="column" height="100%" p="1rem">
      <Grid display={'flex'} width={'100%'} flexGrow={1} flexDirection="column" overflow={'auto'}>
        <React.Fragment key={sessionIndex}>
          <Grid width={'100%'} margin={'0.5rem 0'} height="fit-content">
            <Controller
              name={`structure.sessions.${sessionIndex}.name`}
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => {
                const { value } = getValueAndPlaceholder('name');
                return (
                  <MultipleInputRow
                    value={field.value}
                    fitInput={multiple}
                    additionalClearRenderConditions={multiple}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange('name')(value);
                    }}>
                    <TextFilter
                      {...field}
                      disabled={readOnly}
                      name={`session_name`}
                      autoComplete={AutoCompleteField}
                      fullWidth
                      size="small"
                      variant="outlined"
                      label={
                        <>
                          {tSession(`properties.name`)}
                          <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                        </>
                      }
                      includeMultipleObj
                      value={value}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange('name')(value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors?.structure?.sessions?.[sessionIndex]?.name}
                    />
                  </MultipleInputRow>
                );
              }}
            />
          </Grid>
          <Grid width={'100%'} margin={'0.5rem 0'} height="fit-content">
            <Controller
              name={`structure.sessions.${sessionIndex}.timeStrategy`}
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => {
                const { value } = getValueAndPlaceholder('timeStrategy');
                return (
                  <MultipleInputRow
                    value={field.value}
                    fitInput={multiple}
                    additionalClearRenderConditions={false}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange('timeStrategy')(value);
                    }}>
                    <FixedSelectFilter
                      label={
                        <>
                          {tSession(`properties.timeStrategy`)}
                          <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                        </>
                      }
                      options={[
                        {
                          _id: TimeStrategy.START_TIMES,
                          label: tSession(`timeStrategy.start_times`)
                        },
                        {
                          _id: TimeStrategy.RESOURCE_MISSIONS,
                          label: tSession(`timeStrategy.resource_missions`)
                        }
                      ]}
                      value={value}
                      includeMultipleObj
                      onChange={(value) => {
                        field.onChange(value);
                        onChange('timeStrategy')(value);
                      }}
                    />
                  </MultipleInputRow>
                );
              }}
            />
          </Grid>
          <RenderIf condition={!isFirstOne}>
            <Grid width={'100%'} margin={'0.5rem 0'} height="fit-content">
              <Controller
                name={`structure.sessions.${sessionIndex}.minDaysBefore`}
                control={control}
                rules={{
                  validate: {
                    minValue: (minDaysBefore) => {
                      const maxDaysBefore = getValues(
                        `structure.sessions.${sessionIndex}.maxDaysBefore`
                      );
                      if (isNumber(minDaysBefore) && isNumber(maxDaysBefore)) {
                        return minDaysBefore <= maxDaysBefore;
                      }
                      return true;
                    }
                  }
                }}
                render={({ field }) => {
                  const { value } = getValueAndPlaceholder('minDaysBefore');
                  return (
                    <MultipleInputRow
                      value={field.value}
                      fitInput={multiple}
                      additionalClearRenderConditions={multiple}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange('minDaysBefore')(value);
                        checkMinMax();
                      }}>
                      <NumberInput
                        {...field}
                        name={`session_minDaysBefore`}
                        autoComplete={AutoCompleteField}
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label={<>{tSession(`properties.minDaysBefore`)}</>}
                        includeMultipleObj
                        value={value}
                        onChange={(value) => {
                          field.onChange(value);
                          onChange('minDaysBefore')(value);
                          checkMinMax();
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors?.structure?.sessions?.[sessionIndex]?.minDaysBefore}
                        FormHelperTextProps={{
                          sx: { color: `${theme.palette.black[100]} !important`, marginTop: 0 }
                        }}
                      />
                    </MultipleInputRow>
                  );
                }}
              />
            </Grid>

            <Grid width={'100%'} margin={'0.5rem 0'} height="fit-content">
              <Controller
                name={`structure.sessions.${sessionIndex}.maxDaysBefore`}
                control={control}
                rules={{
                  validate: {
                    minValue: (maxDaysBefore) => {
                      const minDaysBefore = getValues(
                        `structure.sessions.${sessionIndex}.minDaysBefore`
                      );
                      if (isNumber(minDaysBefore) && isNumber(maxDaysBefore)) {
                        return minDaysBefore <= maxDaysBefore;
                      }
                      return true;
                    }
                  }
                }}
                render={({ field }) => {
                  const { value } = getValueAndPlaceholder('maxDaysBefore');
                  return (
                    <MultipleInputRow
                      value={field.value}
                      fitInput={multiple}
                      additionalClearRenderConditions={multiple}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange('maxDaysBefore')(value);
                        checkMinMax();
                      }}>
                      <NumberInput
                        {...field}
                        name={`session_maxDaysBefore`}
                        autoComplete={AutoCompleteField}
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label={<>{tSession(`properties.maxDaysBefore`)}</>}
                        includeMultipleObj
                        value={value}
                        onChange={(value) => {
                          field.onChange(value);
                          onChange('maxDaysBefore')(value);
                          checkMinMax();
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors?.structure?.sessions?.[sessionIndex]?.maxDaysBefore}
                        FormHelperTextProps={{
                          sx: { color: `${theme.palette.black[100]} !important`, marginTop: 0 }
                        }}
                      />
                    </MultipleInputRow>
                  );
                }}
              />
            </Grid>
          </RenderIf>
          <RenderIf condition={!isLastOne}>
            <Grid width={'100%'} margin={'0.5rem 0'} height="fit-content">
              <Controller
                name={`structure.sessions.${sessionIndex}.turnMinutes`}
                control={control}
                render={({ field }) => {
                  const { value } = getValueAndPlaceholder('turnMinutes');
                  return (
                    <MultipleInputRow
                      value={field.value}
                      fitInput={multiple}
                      additionalClearRenderConditions={multiple}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange('turnMinutes')(value);
                      }}>
                      <NumberInput
                        {...field}
                        disabled={readOnly}
                        name={`session_turnMinutes`}
                        autoComplete={AutoCompleteField}
                        fullWidth
                        variant="outlined"
                        label={<>{tSession(`properties.turnMinutes`)}</>}
                        includeMultipleObj
                        value={value}
                        onChange={(value) => {
                          field.onChange(value);
                          onChange('turnMinutes')(value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors?.structure?.sessions?.[sessionIndex]?.turnMinutes}
                        FormHelperTextProps={{
                          sx: {
                            marginTop: 0
                          }
                        }}
                      />
                    </MultipleInputRow>
                  );
                }}
              />
            </Grid>
          </RenderIf>
          <Grid width={'100%'} margin={'0.5rem 0'} height="fit-content">
            <Controller
              name={`structure.sessions.${sessionIndex}.color`}
              control={control}
              render={({ field }) => {
                const { value } = getValueAndPlaceholder('color');
                return (
                  <MultipleInputRow
                    value={field.value}
                    fitInput={multiple}
                    additionalClearRenderConditions={multiple}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange('color')(value);
                    }}>
                    <Component
                      name={'session_color'}
                      autoComplete={AutoCompleteField}
                      disabled={false}
                      label={<>Color</>}
                      value={value}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange('color')(value);
                      }}
                      error={false}
                    />
                  </MultipleInputRow>
                );
              }}
            />
          </Grid>
        </React.Fragment>
      </Grid>

      <RenderIf condition={selectedSessions.length > 1}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            height: '2rem',
            position: 'fixed',
            bottom: '0px'
          }}>
          <Typography variant="body3" component="h6">
            {tCurr('informationNote', {
              quantity: selectedSessions.length,
              element: tCurr('elements.sessions', {
                count: selectedSessions.length
              })
            })}
          </Typography>
        </Box>
      </RenderIf>
    </Box>
  );
};

export default SessionForm;
