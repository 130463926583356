import { CrudModes } from '@/const';
import {
  ContinuityClasses,
  GeneralContinuity,
  ResourceSlot,
  Session,
  SessionContinuity,
  TopLevelContinuity
} from '@/types';
import { DefaultValues } from 'react-hook-form';
import General from './ContinuityForms/General/General';
import Sessions from './ContinuityForms/Session/Session';
import TopContinuity from './ContinuityForms/Top/TopContinuity';

export type ContinuityPanelProps<T extends Continuity> = {
  mode: CrudModes;
  onCancel: (isDirty?: boolean) => unknown;
  onDirtyFields: (isDirty: boolean) => unknown;
  onSuccess: (continuity: T) => void;
  sessions: Session[];
  resourceSlots: ResourceSlot[];
  continuityClass: ContinuityClasses;
  defaultValues?: DefaultValues<T>;
  values?: T;
};

export type Continuity = TopLevelContinuity | SessionContinuity | GeneralContinuity;

export const CONTINUITIES_BY_TYPE = {
  [ContinuityClasses.GENERAL]: General as (props: any) => JSX.Element,
  [ContinuityClasses.SESSION]: Sessions as (props: any) => JSX.Element,
  [ContinuityClasses.TOP_LEVEL]: TopContinuity as (props: any) => JSX.Element
};
