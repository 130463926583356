import { RenderIf } from '@/components/atoms';
import { PresetModes } from '@/components/templates/PropertyPresetPanelTemplate/PropertyPresetPanelTemplate.const';
import { Entities, SYSTEM_SETTINGS_SEARCH } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { PresetDto } from '@/types';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PresetButtonProps = {
  label?: string;
  entity: Entities;
  onSelect: (presetId: string) => void;
  loading: boolean;
  disabled?: boolean;
};

const PresetButton = ({ label, entity, onSelect, loading, disabled }: PresetButtonProps) => {
  const {
    fetch: fetchPresets,
    data: presetsData,
    loading: loadingPresets,
    error: presetsError
  } = useFetch<PresetDto[]>();

  useEffect(() => {
    fetchPresets({
      url: SYSTEM_SETTINGS_SEARCH,
      method: 'POST',
      data: {
        type: 'preset_entity_values',
        'reference.entity': entity,
        'reference.type': null
      }
    });
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t: tPresets } = useTranslation('templates/propertyPresetPanelTemplate');
  const { t: tCommon } = useTranslation();
  const { setToastNotifications } = useToastContext();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelection = (presetId: string) => {
    onSelect(presetId);
    setAnchorEl(null);
  };

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (presetsError) {
      setToastNotifications([
        {
          message: tPresets('errors.loadingEntityPresets', {
            entity: tCommon(`entities.${entity}_other`)
          })
        }
      ]);
    }
  }, [presetsError]);

  return (
    <>
      <LoadingButton
        ref={buttonRef}
        disabled={disabled}
        loading={loadingPresets || loading}
        variant="contained"
        onClick={handleClick}>
        {label ?? tPresets('actions.applyPreset')}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        slotProps={{
          paper: {
            style: {
              minWidth: buttonRef?.current?.offsetWidth,
              maxHeight: '24rem',
              maxWidth: '32rem'
            }
          }
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <RenderIf condition={loadingPresets}>
          <CircularProgress size="1rem" />
        </RenderIf>
        <RenderIf
          condition={
            !loadingPresets &&
            !!presetsData &&
            !presetsData.some((preset) => preset.name !== PresetModes.DEFAULT)
          }>
          <MenuItem disabled sx={{ whiteSpace: 'normal' }}>
            <Typography variant="body2">
              {tPresets('errors.notPresets', { entity: tCommon(`entities.${entity}_other`) })}
            </Typography>
          </MenuItem>
        </RenderIf>
        <RenderIf
          condition={
            !loadingPresets &&
            !!presetsData &&
            presetsData.filter((preset) => preset.name !== PresetModes.DEFAULT).length > 0
          }>
          {presetsData
            ?.filter((preset) => preset.name !== PresetModes.DEFAULT)
            .map((preset) => {
              return (
                <MenuItem onClick={() => handleSelection(preset._id)} key={preset._id}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{preset.name}</div>
                </MenuItem>
              );
            })}
        </RenderIf>
      </Menu>
    </>
  );
};

export default PresetButton;
