import { DEFAULT_TOPIC_COLOR } from '@/context/CurriculmSetupContext';
import { theme } from '@/theme';
import { TimeStrategy } from '@/types';
import { hexToRGB, isValidHexColor, pickTextColorBasedOnBgColor } from '@/utils';
import { Typography, styled } from '@mui/material';
import { DROP_SIDES } from '../SessionWrapper/SessionWrapper.const';
import { ResourceMissionTopic, TimeSessionTopic } from './TopicCard';

type TopicContainerProps = {
  index: number;
  duration: number;
  separation: number;
  startTime?: number;
  scale: number;
  color: string;
  isSelected: boolean;
  isOver: boolean;
  dropSide: DROP_SIDES.left | DROP_SIDES.right | null;
  dropWidth?: number;
  isTransparent: boolean;
} & (
  | Pick<TimeSessionTopic, 'sessionStrategy' | 'overlapCount'>
  | Pick<ResourceMissionTopic, 'sessionStrategy' | 'overlapCount'>
);

export const TopicContainer = styled('div')<TopicContainerProps>`
  //DEFAULT PROPS :
  opacity: ${(props) => (props.isTransparent ? 0.5 : 1)};
  width: ${(props) =>
    ((props.duration * 1) / 1440) *
    100 *
    (props.sessionStrategy === TimeStrategy.RESOURCE_MISSIONS ? props.scale : 1)}%;
  position: relative;
  display: flex;
  align-items: center;
  transition: width 0.5s ease;
  height: 1.625rem;
  border-radius: 3px;
  border: 1px solid
    ${(props) =>
      isValidHexColor(props?.color)
        ? hexToRGB(props.color, 0.5)
        : hexToRGB(DEFAULT_TOPIC_COLOR, 0.5)};
  box-shadow: ${(props) =>
    props.isSelected ? `0px 0px 3px 0.5px ${theme.palette.grey[500]}` : 'none'};
  background-color: ${(props) =>
    hexToRGB(
      isValidHexColor(props?.color) ? props.color : DEFAULT_TOPIC_COLOR,
      props.isSelected ? 1 : 0.65
    )};
  ${(props) =>
    props.sessionStrategy === TimeStrategy.RESOURCE_MISSIONS
      ? `
    //resource_missions props:
    flex-shrink: 0;
    margin-left:${
      props.isOver && props.dropSide === DROP_SIDES.left
        ? props.dropWidth
          ? `${props.dropWidth}px`
          : `${
              ((60 * 1) / 1440) *
              100 *
              (props.sessionStrategy === TimeStrategy.RESOURCE_MISSIONS ? props.scale : 1)
            }%`
        : '0'
    };
    margin-right:${
      props.isOver && props.dropSide === DROP_SIDES.right
        ? props.dropWidth
          ? `${props.dropWidth}px`
          : `${
              ((60 * 1) / 1440) *
              100 *
              (props.sessionStrategy === TimeStrategy.RESOURCE_MISSIONS ? props.scale : 1)
            }%`
        : `${((props.separation * 1) / 1440) * 100 * props.scale}%`
    };    
    `
      : `
    //start_time props:
    position:absolute;
    left:${((props.startTime || 1) * 100) / 1440}%;
    top:calc(${props.overlapCount * 2}rem + 0.5rem);
    
    `}
`;

export const TopicText = styled(Typography)<{ color: string }>`
  margin-left: 0.313rem;
  color: ${({ color }) =>
    pickTextColorBasedOnBgColor(
      isValidHexColor(color) ? color : DEFAULT_TOPIC_COLOR,
      theme.palette.common.white,
      theme.palette.primary.main
    )};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
