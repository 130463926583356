export enum SystemProperties {
  RESOURCES_ID = '000000000000000000010001',
  RESOURCES_FIRST_NAME = '000000000000000000010002',
  RESOURCES_NAME = '000000000000000000010003',
  RESOURCES_COST = '000000000000000000010004',
  RESOURCES_CAPACITY = '000000000000000000010005',
  RESOURCES_LOCATION = '000000000000000000010006',
  RESOURCES_EMAIL = '000000000000000000010007',
  RESOURCES_PICTURE = '000000000000000000010008',

  JOBS_NAME = '000000000000000000020001',
  JOBS_REVENUE = '000000000000000000020002',
  JOBS_LOCATION = '000000000000000000020003',
  JOBS_START_PERIOD_START = '000000000000000000020004',
  JOBS_START_PERIOD_END = '000000000000000000020005',
  JOBS_INITIAL_WEEKDAYS = '000000000000000000020006',
  JOBS_TRAINING_WEEKDAYS = '000000000000000000020007',

  DOCUMENTS_NAME = '000000000000000000030001',

  EVENTS_NAME = '000000000000000000040001',
  EVENTS_START_TIMESTAMP = '000000000000000000040002',
  EVENTS_END_TIMESTAMP = '000000000000000000040003',
  EVENTS_COLOR = '000000000000000000040004',
  EVENTS_LOCATION = '000000000000000000040005',
  EVENTS_COUNT_FOR_WORKLOAD = '000000000000000000040006',

  CURRICULUMS_NAME = '000000000000000000050001',

  RECORDS_ISSUE_DATE = '000000000000000000060001',
  RECORDS_EXPIRATION_DATE = '000000000000000000060002'
}

export enum DelayTimes {
  SLIDE_TRANSITION = 250,
  DEBOUNCE_ACTION = 500
}

export const AutoCompleteField = 'on';

export enum SettingsProperties {
  COLOR_PRESET = 'colors-preset',
  SCALE = 'curriculums-scale'
}

export const RESOURCE_LABEL_PROP_IDS = new Set<string>([
  SystemProperties.RESOURCES_ID.toString(),
  SystemProperties.RESOURCES_NAME.toString(),
  SystemProperties.RESOURCES_FIRST_NAME.toString(),
  SystemProperties.RESOURCES_PICTURE.toString()
]);

export enum PropertyTypes {
  IMAGE = 'image',
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'datetime',
  BOOLEAN = 'boolean',
  OBJECTID = 'objectid',
  SET = 'set',
  COLOR = 'color',
  TIME = 'time',
  WEEKDAYS = 'weekdays'
}
