import { ScheduleProcessDto, ScheduleProcessProgressDto, ScheduleResultDto } from '@/types';
import { Dispatch, SetStateAction, createContext } from 'react';

export type SchedulerContextType = {
  currentProcessId: string | null;
  currentProcess: ScheduleResultDto | null;
  currentProcessLoading: boolean;
  schedulingError: unknown;
  loadingState: LoadingState;
  processProgress: ScheduleProcessProgressDto[];
  processes: ScheduleProcessDto[];
  fetchMessages: () => void;
  setSchedulingError: Dispatch<SetStateAction<unknown>>;
  setCurrentProcessId: Dispatch<SetStateAction<string | null>>;
  setCurrentProcess: Dispatch<SetStateAction<ScheduleResultDto | null>>;
  setLoadingState: Dispatch<SetStateAction<LoadingState>>;
  setProcessProgress: Dispatch<SetStateAction<ScheduleProcessProgressDto[]>>;
  setProcesses: Dispatch<SetStateAction<ScheduleProcessDto[]>>;
  isProcessingScheduling: () => boolean;
};

export type LoadingState = 'preload' | 'validating' | 'loading' | 'loaded';

export default createContext<SchedulerContextType>({} as SchedulerContextType);
