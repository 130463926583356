import { SystemProperties } from '@/const';
import { EventDto, PropertyValue } from '@/types';

export const getProperty = (
  properties: PropertyValue[] | undefined,
  propertyId: string
): PropertyValue | undefined => {
  return properties?.find((property) => property._id == propertyId);
};

export const getPropertyValue = <T>(
  properties: PropertyValue[] | undefined,
  propertyId: string
): T | undefined => {
  return properties?.find((property) => property._id == propertyId)?.value as T;
};

export const getPropertyValueDef = <T>(
  properties: PropertyValue[] | undefined,
  propertyId: string,
  defaultValue: T
): T => {
  return (properties?.find((property) => property._id == propertyId)?.value as T) ?? defaultValue;
};

export const getEventLocation = (event?: EventDto | undefined): string | undefined => {
  if (event != undefined) {
    const eventLocation = getProperty(event?.properties, SystemProperties.EVENTS_LOCATION);
    return eventLocation?.label ?? (eventLocation?.value as string);
  }
  return '';
};

export const getResourceFullName = (
  properties: PropertyValue[] | undefined
): string | undefined => {
  const firstName = getPropertyValue(properties, SystemProperties.RESOURCES_FIRST_NAME) as string;
  let name = getPropertyValue(properties, SystemProperties.RESOURCES_NAME) as string;
  if (firstName) {
    name = name ? `${firstName} ${name}` : firstName;
  }
  return name;
};
