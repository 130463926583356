import { alpha, darken, lighten } from '@mui/material';
import { theme } from './theme';

const GlobalStyle = `
  :root {    
    --bs-blue: ${theme.palette.primary.main};
    --bs-blue-focus-shadow: ${alpha(theme.palette.primary.main, 0.5)};
    --bs-blue-focus-border: ${theme.palette.primary.main};
    --bs-blue-active: ${lighten(theme.palette.primary.main, 0.1)};
    --bs-blue-hover: ${lighten(theme.palette.primary.main, 0.2)};
  }
  body{
    white-space: pre-line;
  }
  p {
    margin: 0;
  }
  legend {
    width: auto
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.white};
  }

  *::-webkit-scrollbar {
    width: ${theme.spacing(1.3)};
    height: ${theme.spacing(1.3)};    
  }

  ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 2px solid transparent;
    background-color: ${darken(theme.palette.divider, 0.3)};
    border-radius: ${theme.shape.borderRadius}px;
  }

  *::-webkit-scrollbar-track {
    background-color: ${lighten(theme.palette.background.default, 0.5)};    
    border-radius: ${theme.shape.borderRadius}px;
  }

  * {
    scrollbar-width: thin;
    scrollbar-gutter: unset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }

  .Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: ${theme.palette.error.main} !important;
  }

  .not-allowed {
    border-color: red;
    background:red;
    cursor:not-allowed;
  }

  // =====================================================
  // BRYNTUM SECTION
  // =====================================================

  .b-grid-border {
    border: 1px solid ${({ theme }) => theme.palette.divider};
  }
  
  .demo-toolbar {
    border-top: 1px solid ${theme.palette.divider};
  }

  .b-popup.b-sch-event-tooltip {
    border-radius: 8px !important;
  }

  .b-panel-header.b-dock-top {
    color: ${theme.palette.common.white} !important;
    background-color: ${theme.palette.primary.main} !important;
    border-bottom: 1px solid ${theme.palette.divider} !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .b-hbox.b-box-center.b-panel-body-wrap.b-tooltip-body-wrap {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .b-box-center {
    // border: 2px solid black;
    // background-color: ${theme.palette.background.default} !important;
  }

  .b-resource-title {
    margin-top: ${theme.spacing(1)};
  }

  .b-sch-clock-hour .b-sch-clock {
    border: 2px solid ${theme.palette.divider} !important;
  }

  .b-hint-resource {
    display: flex;
    align-items:center;
    gap: ${theme.spacing(1)};
    
  }

  .b-border-bottom {
    border-bottom: 1px solid ${theme.palette.common.white};
  }

  .b-react-scheduler-container {
    flex: 1;
  }

  .b-sch-label.custom {
    position: absolute;
    top: calc(100% + 10px);
    background: #05f57d;
    padding: 0.5em;
    color: ${theme.palette.common.white};
  }

   .b-custom-label.b-custom-label-top {
    width: 50rem;
    font-size: 0.8rem;
    position: relative;
    font-weight: bold;

  }

  

  .b-sch-label.custom::before {
    content: '';
    height: 12px;
    border-inline-end: 1px solid #05f57d
    position: absolute;
    bottom: 100%;
    left: 50%;
  }
 

  .b-sch-event-wrap .thinevent.b-sch-event {
    padding-inline-start: 0.3em;
    font-size: 0.2em;
  }

  // Hide occurrence labels
  .hidelabel.b-occurrence ~ label {
    opacity: 0;
  }

  .event-light-text.b-sch-event:not(.b-milestone) .b-sch-event-content,
  .event-light-text.b-sch-event:not(.b-milestone).b-sch-event-selected .b-sch-event-content {
    color: ${theme.palette.common.white} !important;
  }

  .event-dark-text.b-sch-event:not(.b-milestone) .b-sch-event-content,
  .event-dark-text.b-sch-event:not(.b-milestone).b-sch-event-selected .b-sch-event-content {
    color: ${theme.palette.primary.main} !important;
    overflow: visible  !important;
    
  }
 
  .b-sch-event-wrap.b-sch-style-line > .b-sch-event .b-sch-event-content, .b-sch-event-wrap.b-sch-style-line > .b-sch-event > .b-sch-event-segments > .b-sch-event .b-sch-event-content{
    justify-content: center !important;
    position:relative !important;
    top: 2px;
  }

  .event-canceled.b-sch-event:not(.b-milestone){
    opacity:0.5;
    .b-sch-event-content{
      justify-content: center;
      position:relative;
      &:before {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          width: 100%;
          right: 0;
          border-top: 1px solid;
          border-color: inherit;  
          transform: rotate(-5deg);
      }
    }
  }
  

  //Margin in events
  .b-sch-event:not(.b-milestone) .b-sch-event-content {
    margin: 0.1em 0.4em 0 0.4em !important;
  }

  //Font size for bryntum
  .b-widget {
    font-size: 0.9em !important;
  }

  //Not uppercase column header
  .b-grid-header {
    text-transform: none !important;
  }

  //Column header height
  .b-grid-header-text {
    padding: 0.6em 0 !important;
  }

  .b-react-portal-container {
    flex: 1;
    align-items: center;

    .bar-container {
      padding-bottom: 0.4em;
      flex: 0 0 auto;
      color: ${({ theme }) => theme.palette.grey[500]};
      border-bottom: 1px solid ${theme.palette.divider};
    }
  }

  .b-custom-top-border { 
    border-top: 1px solid ${theme.palette.divider};
  }  
  
  .b-custom-border { 
    border: 1px solid ${theme.palette.divider};
  }  

  .b-custom-full-border {
    border: 1px solid ${theme.palette.divider};
    border-radius: 0px 0px 5px 5px;
  }

  .b-custom-header-resized {
    & .b-grid-headers{
      min-height:2rem;
    }
    & .b-grid-header-container{
      align-items:center;
    }
  }

  .drag-element {
    &:hover {
      cursor: move !important;
    }
  }

  .not-resizable-grid {
    flex: 0 auto !important;
  }

  .switch-cell {
    &>* {
      width: min-content;
    }
  }

  // Delete icon in Chip Component
  .MuiAutocomplete-inputRoot {
    gap: 6px 3px;
  }
  .MuiAutocomplete-tag {
    margin: 0 !important;
  }
  .MuiChip-deleteIcon {
    font-size: 18px !important;
  }

  .b-counter-cell {
    background-color : ${theme.palette.grey[300]} !important;
  }

  //Grid width in properties
  .grid-width-80 {
    flex: 0 0 calc(80% - 1rem) !important;
  }

  .grid-width-20 {
    flex: 0 0 calc(20% - 1rem) !important;
  }

  //Color picker
  .react-colorful__saturation {
    border-radius: 0px !important;
    border-bottom: 12px solid ${theme.palette.common.white} !important;
  }

  .react-colorful__pointer {
    width: 15px !important;
    height: 15px !important;
  }

  .react-colorful__last-control {
    border-radius: 0px !important;
  }

  .react-colorful__alpha, .react-colorful__hue {
    height: 8px !important;
    border-radius: 8px !important;
  }


  .header-line-height{
   line-height: 2.515;
  }

  .b-grid-headers{
    min-height:45px;
  }  

  .b-custom-row-selected{
    background-color:${theme.palette.bryntum.selected} !important;
  }
   
  .b-custom-event-selected{
    background-image: none;
    border-color: currentColor;
  }

  .b-react-portal-container{
    height:100%;
    width:100%;
  }

  .no-margin{
    margin:0 !important!
  }



  .MuiDialog-paper .b-grid-cell:last-child{
    flex:1 !important;
  }

  .b-grid-cell {
    color: ${theme.palette.bryntum.text} !important;
    font-weight:400 !important;
  }
  .b-grid-leaf-cell {
    font-size: 0.9em !important;
  }
  .b-stripe .b-grid-child-row.b-odd:not(.b-selected) {
    background-color: ${theme.palette.bryntum.tree.stripe.odd}; 
  }
  .b-stripe .b-grid-child-row.b-even:not(.b-selected) {
    background-color: ${theme.palette.bryntum.tree.stripe.even}; 
  }
  .b-grid-child-row.b-even > .b-timeaxis-cell {
    background-color: ${theme.palette.bryntum.tree.stripe.even} !important;
  }
  .b-grid-child-row.b-odd > .b-timeaxis-cell {
    background-color: ${theme.palette.bryntum.tree.stripe.odd} !important;
  }
 
  //===========================================
  //CALENDAR SECTION
  //===========================================

  .b-cal-event-bar-container .b-solid-bar .b-cal-event-desc,
  .b-cal-event-body,
  .b-cal-event-wrap.b-intraday:not(.b-solid-bar) .b-cal-event .b-cal-event-body,
  .b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-selected .b-cal-event .b-cal-event-body,
  .b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-selected .b-cal-event .b-cal-event-icon,
  .b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-active .b-cal-event .b-cal-event-body {
    color: inherit !important;
  }

  .b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-selected .b-cal-event,
  .b-cal-event-icon b-icon b-fw-icon b-icon-circle,
  .b-cal-event-bar-container .b-cal-event-wrap.b-allday .b-cal-event,
  .b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-active .b-cal-event {
    background: var(--cal-event-color) !important;
    color: inherit;
  }

  .b-cal-event-bar-container .b-cal-event-wrap:not(.b-allday) .b-cal-event .b-cal-event-body {
    color: ${theme.palette.primary.main} !important;
  }

  .hide-event {
    display: none !important;
  }


  // Chart

  .apexcharts-tooltip .apexcharts-tooltip-text-y-value{
    
    font-weight: 400;
  }
  
  .loading-timeaxis {
    background-color: rgba(0,0,0,0.4) !important;
    opacity: 0.3 !important;
  }
  
  .remove {
    display: none !important;
  }
  .hide {
    visibility: hidden !important;
  }

  .b-tree-cell {
    font-weight: 400 !important;
    color : ${theme.palette.bryntum.text} !important;
  }
  .b-tree-expander {
    margin-left: 0px !important;
  }

  .b-icon-tree-expand {
    margin-left: 0px !important;
  }

  .b-sch-event.b-grid-leaf-cell.b-grid-child-row .b-sch-event-content {
    font-size: 0.73rem !important;
  }

  .b-grid-cell.b-tree-cell.b-grid-cell-align-left.b-tree-leaf-cell {
    padding-inline-start : 0rem !important;
  }

   .b-gantt-task-content {
      .b-icon-calendar.b-icon {
        display: none;
      }
  }



`;

export default GlobalStyle;
