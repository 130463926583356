import { InputBox, RenderIf } from '@/components/atoms';
import { ColorBox } from '@/components/molecules';
import { ButtonSettings } from '@/components/organisms/GlobalFilter/GlobalFilter.styles';
import { SettingsProperties } from '@/const';
import { ButtonsContainer } from '@/theme';
import { PossibleValues, isMultipleInputValue } from '@/types';
import { isValidHexColor, loadState, saveState } from '@/utils';
import {
  FormControlProps,
  FormHelperText,
  IconButton,
  InputBase,
  InputBaseProps,
  Paper,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { isString } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { FaEraser, FaPlusCircle } from 'react-icons/fa';
import { GiEmptyChessboard } from 'react-icons/gi';
import { MdGradient, MdOutlinePalette } from 'react-icons/md';
import { useDebouncyFn } from 'use-debouncy';
import { AccordionSummary } from '../Card/Card.styles';
import { ComponentWrapper, InputWrapper, PresetColorsContainer } from './ColorInput.styles';

export type ColorInputProps = {
  label?: string | React.ReactNode;
  helperText?: string;
  readOnly: boolean;
  onChange: (newColor: string | null) => unknown;
  placeholder: string;
} & Omit<FormControlProps, 'onChange' | 'children' | 'onBlur'> &
  Pick<InputBaseProps, 'onBlur' | 'name' | 'autoComplete'> &
  PossibleValues<string | null>;

const ColorInput = ({
  value,
  onChange,
  label,
  id,
  name,
  includeMultipleObj = false,
  autoComplete,
  error,
  helperText,
  disabled = false,
  readOnly = false,
  placeholder = '',
  onBlur,
  ...otherProps
}: ColorInputProps) => {
  const { t } = useTranslation('common');
  const { t: tComp } = useTranslation('molecules/colorInput');
  const [presetColors, setPresetColors] = useState<string[]>([]);
  const [inputText, setInputText] = useState<string>(isString(value) ? value : '');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = () => {
    setAnchorEl(inputContainerRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  useEffect(() => {
    const localPreset = loadState<string[]>(SettingsProperties.COLOR_PRESET);
    if (localPreset && isOpen) {
      setPresetColors(localPreset);
    }
  }, [isOpen]);

  const handleInputBlur = useCallback(() => {
    if (!isValidHexColor(inputText)) {
      setInputText(isString(value) ? value : '');
    }
  }, [inputText, value]);

  const clearPreset = useCallback(() => {
    setPresetColors([]);
    saveState(SettingsProperties.COLOR_PRESET, []);
  }, []);
  const addPresetColor = useCallback(() => {
    if (isString(value) && isValidHexColor(value)) {
      if (presetColors.includes(value)) {
        setPresetColors((prev) => {
          const filteredColors = prev.filter((val) => val !== value);
          saveState(SettingsProperties.COLOR_PRESET, [value, ...filteredColors.slice(0, 18)]);
          return [value, ...filteredColors.slice(0, 18)];
        });
      } else {
        setPresetColors((prev) => {
          saveState(SettingsProperties.COLOR_PRESET, [value, ...prev.slice(0, 18)]);
          return [value, ...prev.slice(0, 18)];
        });
      }
    }
  }, [value, presetColors]);

  const selectColor = useDebouncyFn((newColor?: string) => {
    onChange(newColor ?? null);
  }, 100);

  useEffect(() => {
    setInputText(isString(value) ? value : '');
  }, [value]);

  const inputContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <ComponentWrapper ref={inputContainerRef}>
        <InputBox disabled={disabled} label={label} error={error}>
          <InputWrapper>
            <IconButton
              disabled={disabled}
              sx={{ alignSelf: 'center', width: 22, height: 22, marginLeft: '3px' }}
              onClick={handleClick}>
              {isString(value) && isValidHexColor(value) ? (
                <ColorBox
                  containerProps={{
                    display: 'grid',
                    alignItems: 'center'
                  }}
                  value={value}
                  boxSize={22}
                />
              ) : isMultipleInputValue(value) ? (
                <div
                  style={{
                    display: 'grid',
                    alignItems: 'center'
                  }}>
                  <MdGradient
                    size={28}
                    style={{
                      alignSelf: 'center',
                      opacity: '0.8',
                      border: '0px solid transparent',
                      borderRadius: '8px'
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'grid',
                    alignItems: 'center'
                  }}>
                  <GiEmptyChessboard
                    size={24}
                    style={{
                      alignSelf: 'center',
                      opacity: '0.8',
                      border: '0px solid transparent',
                      borderRadius: '8px'
                    }}
                  />
                </div>
              )}
            </IconButton>
            <InputBase
              type="string"
              id={id}
              name={name}
              autoComplete={autoComplete}
              placeholder={
                includeMultipleObj
                  ? isMultipleInputValue(value)
                    ? t('text.multipleValues')
                    : ''
                  : placeholder
              }
              readOnly={readOnly}
              disabled={disabled}
              sx={{
                flex: '1',
                marginX: '0.4rem',
                lineHeight: 1,
                '.MuiInputBase-input': {
                  paddingBlock: '0'
                }
              }}
              value={inputText}
              onBlur={(event) => {
                handleInputBlur();
                onBlur && onBlur(event);
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputText(e.target.value);
                if (e.target.value === '' || isValidHexColor(e.target.value)) {
                  selectColor(isValidHexColor(e.target.value) ? e.target.value : undefined);
                }
              }}
            />
            <IconButton
              disabled={disabled}
              size="small"
              sx={{
                padding: '0.2rem',
                left: '0.18rem',
                alignSelf: 'center'
              }}
              onClick={handleClick}>
              <MdOutlinePalette size={20.8} />
            </IconButton>
          </InputWrapper>
        </InputBox>
        <RenderIf condition={!!helperText}>
          <FormHelperText>{helperText}</FormHelperText>
        </RenderIf>
      </ComponentWrapper>
      <Popover
        id={isOpen ? `${SettingsProperties.COLOR_PRESET}-popover` : undefined}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Paper sx={{ padding: '1rem', width: '19rem' }} elevation={6}>
          <HexColorPicker
            style={{ width: '100%' }}
            color={
              value && isValidHexColor(value) && !isMultipleInputValue(value) ? value : undefined
            }
            onChange={selectColor}
          />
          <AccordionSummary
            sx={{
              marginBottom: '0.5rem !important',
              cursor: 'auto !important',
              paddingX: '1rem !important',
              marginX: '-1rem',
              marginTop: '1.5rem',
              '& > *': { margin: '0.5rem 0 !important' }
            }}>
            <Typography
              sx={{
                alignSelf: 'center',
                height: '100%',
                lineHeight: 1,
                flex: 1
              }}
              variant="h6">
              {tComp('presets')}
            </Typography>
            <ButtonsContainer style={{ height: '100%' }}>
              <Tooltip title={t('buttons.clear')}>
                <ButtonSettings
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    clearPreset();
                  }}
                  type="button">
                  <FaEraser />
                </ButtonSettings>
              </Tooltip>
            </ButtonsContainer>
          </AccordionSummary>
          <PresetColorsContainer>
            {presetColors.map((presetColor, idx) => (
              <IconButton
                key={idx}
                sx={{ width: 22, height: 22 }}
                onClick={(e) => selectColor(presetColor)}>
                <ColorBox containerProps={{}} boxSize={22} value={presetColor} />
              </IconButton>
            ))}
            <Tooltip title={t('buttons.add')}>
              <IconButton
                sx={{ padding: '4px', width: '22px !important', height: 22 }}
                color="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addPresetColor();
                }}>
                <FaPlusCircle />
              </IconButton>
            </Tooltip>
          </PresetColorsContainer>
        </Paper>
      </Popover>
    </>
  );
};

export default ColorInput;
