import { secondsToTime, truncate } from '@/services/utils/utils';
import { StatsDto } from '@/types';
import { Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { renderToString } from 'react-dom/server';

export type ChartOptions = {
  chart: ApexChart;
  stroke: ApexStroke;
  markers: ApexMarkers;
  grid: ApexGrid;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
};

export type ValueChart = {
  value: number | undefined;
  slotsFilled: number | undefined;
  slotsToFill: number | undefined;
};

export const chartOptions = (
  t: TFunction,
  tCommon: TFunction,
  slotsFilled: (number | undefined)[],
  slotsToFill: (number | undefined)[]
): Partial<ChartOptions> => {
  return {
    chart: {
      id: 'basic-bar',
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`
    },
    stroke: {
      // curve: 'stepline'
      width: 2.5,
      lineCap: 'round'
    },
    markers: {
      hover: {
        size: 3
      },
      strokeOpacity: 0.2
    },
    grid: {
      borderColor: '#00000020',
      position: 'back'
    },
    xaxis: {
      type: 'numeric',
      axisBorder: { show: false },
      labels: {
        style: { fontSize: '0.8rem' },
        formatter: (value: string) => {
          return secondsToTime(Number(value));
        }
      }
    },
    yaxis: {
      min: 0,
      max: 100,
      axisBorder: { show: false },
      labels: {
        style: { fontSize: '0.8rem' },
        formatter: (value: number) => {
          return value.toFixed(0).toString();
        }
      },
      title: {
        text: t('ylabel'),
        style: {
          fontSize: '0.9rem',
          fontWeight: 500
        }
      }
    },
    tooltip: {
      y: {
        title: {
          formatter: (_seriesName) => {
            return '';
          }
        },
        formatter: (value, { dataPointIndex }) => {
          return renderToString(
            <>
              <Typography variant="label">
                Progress: <strong>{`${truncate(value, 2)}%`}</strong>
              </Typography>
              <br />
              <div style={{ marginTop: '0.33rem' }}>
                <Typography variant="label">
                  Slots Filled:{' '}
                  <strong>
                    {slotsFilled[dataPointIndex] === undefined ||
                    slotsToFill[dataPointIndex] === undefined
                      ? (tCommon('utils.n/a') as string)
                      : `${slotsFilled[dataPointIndex]} / ${slotsToFill[dataPointIndex]}`}
                  </strong>
                </Typography>
              </div>
            </>
          );
        }
      }
    }
  };
};

export type PeriodChart = {
  percentage: number | string;
  state: string;
  detailedState?: string;
} & StatsDto;

export type JobPeriodChart = {
  jobId: string;
  actualPeriod?: number;
  totalPeriods?: number;
} & PeriodChart;
