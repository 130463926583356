import { JobEventForm } from '../../JobEventPanelTemplate';

type Position = {
  slotIndex: number;
  resourceIndex: number;
};
type ResourceDictionary = Record<string, Position[]>;

export const makeInitialState = (resourceSlots: JobEventForm['resourceSlots']) => {
  const ocurrences: ResourceDictionary = {};
  resourceSlots.forEach((resourceSlot, slotIndex) => {
    const resources = resourceSlot.resources;
    if (!resources) return;
    resources.forEach((resource, resourceIndex) => {
      if (!resource._id) return;
      if (!ocurrences[resource._id]) ocurrences[resource._id] = [];
      ocurrences[resource._id].push({ slotIndex, resourceIndex });
    });
  });
  return { resourceDictionary: ocurrences };
};
