import { styled } from '@mui/material';

const MainContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  min-height: 0;
  transform: translate(0, 0);
  padding: 0;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    max-height: 0;
    left: 0;
    border: none;
    box-shadow: none;
    pointer-events: none;
  }
`;

export default MainContainer;
