import { useSocket } from '@/hooks/useSocket';
import { PropsWithChildren } from 'react';
import SocketContext from './SocketContext';

export const SocketContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { socket, online, tenant, errors, connectSocket, disconnectSocket, setErrors } =
    useSocket();

  return (
    <SocketContext.Provider
      value={{
        socket,
        online,
        tenant,
        errors,
        connectSocket,
        disconnectSocket,
        setErrors
      }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
