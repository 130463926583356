import { ReactNode } from 'react';

export type RenderIfProps<T> = {
  condition?: boolean;
  children: T;
};

const RenderIf = <T extends ReactNode>({ condition, children }: RenderIfProps<T>) => {
  return (condition && children) as JSX.Element;
};

export default RenderIf;
