import {
  COLLECTIONS_FIND_ONE,
  Entities,
  Entity,
  Filters,
  PROPERTIES_BY_ENTITY,
  SystemFilters
} from '@/const';
import { getKeyFromValueEnum } from '@/services/utils/utils';
import { FieldFilterType, PropertyDto } from '@/types';
import { ServerError } from '@/types/data/serverError';
import { loadState, saveState } from '@/utils';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

export const useGetPropertiesByEntity = <T = unknown, E = AxiosError<ServerError>>(
  entity: Entity,
  entityId?: string,
  onSuccess?: (data: T) => void
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<E>();
  const [properties, setProperties] = useState<PropertyDto[]>();
  const [entityData, setEntityData] = useState<T>();

  const purgeProperties = (properties: PropertyDto[]) => {
    const key = getKeyFromValueEnum(Entities, entity);
    if (key) {
      const localResourceFilters = loadState<FieldFilterType[]>(Filters[key]) || [];
      const purgedProperties = localResourceFilters?.filter(
        (element) =>
          properties.find((property) => property._id == element._id) ||
          Object.values(SystemFilters).includes(element._id as SystemFilters)
      );
      saveState(Filters[entity], purgedProperties);
      onSuccess && onSuccess(purgedProperties as T);
    }
  };

  useEffect(() => {
    if (!loading && !error && properties) {
      purgeProperties(properties);
    }
  }, [properties]);

  const fetch = async () => {
    try {
      setLoading(true);
      const propertiesQueryResult = await axios.get<PropertyDto[]>(
        PROPERTIES_BY_ENTITY.replace(':entity_name', entity)
      );
      setProperties(propertiesQueryResult.data as PropertyDto[]);
      if (entityId) {
        const entityDataQueryResult = await axios.request<T>({
          method: 'GET',
          url: COLLECTIONS_FIND_ONE.replace(':collection_name', entity).replace(':id', entityId)
        });
        setEntityData(entityDataQueryResult.data as T);
      }
    } catch (error) {
      setError(error as E);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (entity) fetch();
    return () => {
      controller.abort();
    };
  }, [entity, entityId]);

  return {
    properties,
    entityData,
    loading,
    error
  };
};
