import { PropertyValue, Traceability } from '.';
export enum ContinuityClasses {
  GENERAL = 'general',
  SESSION = 'session',
  TOP_LEVEL = 'top-level',
  TOPICS = 'topics'
}
export interface ResourceSlot {
  id: string;
  name: string;
  quantity: number;
  entityType: string;
  role: {
    _id: string;
    label?: string;
  };
  documents?: {
    _id: string;
    label: string;
  }[];
  assignmentType: AssignmentType;
  restrictLocation?: boolean;
  occupyCapacity?: boolean;
  availableSlots?: number[];
}

export enum AssignmentType {
  BALANCED = 'balanced',
  LESS_FRAGMENTED = 'less_fragmented'
}

export interface TopicResourceSlot {
  id: string;
  joinable?: boolean;
  contentId?: string;
}

export interface Topic {
  startTime: string;
  id: string;
  name: string;
  duration: number;
  postSeparation?: number;
  color: string;
  resourceSlots: TopicResourceSlot[];
}

export interface TopicContinuity {
  id: string;
  type: Type;
  resourceSlotId: string;
  topicIds: string[];
}
export enum TimeStrategy {
  START_TIMES = 'start_times',
  RESOURCE_MISSIONS = 'resource_missions'
}
export interface Session {
  name?: string;
  id: string;
  topics: Topic[];
  continuities?: TopicContinuity[];
  timeStrategy: TimeStrategy;
  minDaysBefore?: number;
  maxDaysBefore?: number;
  turnMinutes?: number;
  scrollTime?: number;
  color?: string;
}
export interface ContinuityBase {
  id: string;
  type: Type;
  name: string;
  continuityClass?: ContinuityClasses;
}
export interface TopLevelContinuity extends ContinuityBase {
  resourceSlotId: string;
  perSession: boolean;
}

export interface SessionContinuity extends ContinuityBase {
  resourceSlotId: string;
  sessionId: string;
}

export interface GeneralContinuity extends ContinuityBase {
  topicResourceSlotIds: Record<string, string>;
  discontinueItem?: {
    topicId: string;
    resourceSlotId: string;
  };
}
// Just one resource slot per Topic

export interface ResourceContinuity {
  id: string;
  type: string;
  resourceSlotId: string;
  sessionId: string;
  sessionTopicId: string;
}

export interface SessionContinuityIds {
  sessionId: string;
  continuityId?: string;
}

export interface SessionTopicIds {
  sessionId: string;
  topicId: string;
}

export interface ExtendedPreferredContinuity {
  id: string;
  sessionResourceContinuityIds: SessionContinuityIds[];
  resourceSlotId: string;
  sessionTopicIds: SessionTopicIds[];
}

export interface MissionContinuities {
  id: string;
  type: Type;
  resourceSlotId: string;
  sessionTopicIds: unknown; // unknown type
}

export enum Type {
  R = 'R',
  P = 'P',
  D = 'D'
}

export interface Structure {
  resourceSlots?: ResourceSlot[];
  sessions: Session[];
  continuities?: {
    resourceSlotBased?: {
      topLevel?: TopLevelContinuity[];
      sessionLevel?: SessionContinuity[];
    };
    general?: GeneralContinuity[];
  };
}
export interface CurriculumDto extends Traceability {
  _id: string;
  readOnly: boolean;
  maxDuration: number;
  structure: Structure;
  properties: PropertyValue[];
  viewSettings?: {
    scale: number;
    scrollTimes: Record<string, number>;
  };
  color?: string;
}

export type CurriculumForm = Omit<CurriculumDto, 'continuities' | '_id' | 'properties'> & {
  structure: Omit<Structure, 'continuities'> & {
    continuities: Array<TopLevelContinuity | SessionContinuity | GeneralContinuity>;
  };
  properties: Record<string, any>;
  color?: string;
};
export interface CurriculumForm_2 extends Traceability {
  readOnly: boolean;
  maxDuration: number;
  structure: Omit<Structure, 'continuities'> & {
    continuities: Array<TopLevelContinuity | SessionContinuity | GeneralContinuity>;
  };
  properties: Record<string, any>;
}
