import { RenderIf } from '@/components/atoms';
import { Box, Skeleton } from '@mui/material';
import { Fragment } from 'react';
import { Container } from '../GlobalFilter.styles';
import { GlobalFilterLoadingProps } from '../GlobalFilter.types';

const GlobalFilterLoading = ({ ...props }: GlobalFilterLoadingProps) => {
  return (
    <Container {...props}>
      {Array(5)
        .fill('')
        .map((_, idx) => (
          <Fragment key={`loading.filed.${idx}`}>
            <RenderIf condition={idx % 3 === 0}>
              <Skeleton variant="rounded" height={40} />
            </RenderIf>
            <RenderIf condition={idx % 3 === 1}>
              <Box display="flex" justifyContent="space-between">
                <Skeleton variant="rounded" width="49%" height={40} />
                <Skeleton variant="rounded" width="49%" height={40} />
              </Box>
            </RenderIf>
            <RenderIf condition={idx % 3 === 2}>
              <Box display="flex" alignItems="center">
                <Skeleton variant="rectangular" width={20} height={20} />
                <Skeleton height={23} width="60%" style={{ marginLeft: 6 }} />
              </Box>
            </RenderIf>
          </Fragment>
        ))}
    </Container>
  );
};

export default GlobalFilterLoading;
