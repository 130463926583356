import { theme } from '@/theme';
import { Box, Tooltip, Typography } from '@mui/material';

type SwitchProps = {
  label: string;
  tooltipLabel?: string;
  size?: 'big' | 'small';
  multiple?: boolean;
};

const Switch = ({ label, tooltipLabel, size = 'big', multiple = false }: SwitchProps) => {
  return (
    <>
      <Tooltip title={tooltipLabel} placement="bottom">
        <Box
          sx={{
            color: theme.palette.common.white,
            border: 'none',
            minWidth: 'fit-content',
            borderRadius: '35px',
            backgroundColor: multiple ? theme.palette.grey[500] : theme.palette.primary.main
          }}>
          <Typography
            padding={'0 6px'}
            lineHeight={1.75}
            variant={size == 'big' ? 'body2' : 'caption'}>
            {label}
          </Typography>
        </Box>
      </Tooltip>
    </>
  );
};

export default Switch;
