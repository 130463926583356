import { TextFilter } from '@/components/molecules';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { auth } from '@/config/firebase.config';
import { useToastContext } from '@/context/ToastContext';
import { ButtonsContainer, Container, theme } from '@/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, Typography } from '@mui/material';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';
import {
  ChangePasswordSchemaType,
  changePasswordSchema
} from './ChangePasswordPanelTemplate.schema';

export type ChangePasswordPanelTemplateProps = {
  onCancel: () => void;
};

const ChangePasswordPanelTemplate = ({ onCancel }: ChangePasswordPanelTemplateProps) => {
  const { t: tCommon } = useTranslation();
  const { t: tChangePassword } = useTranslation('templates/changePasswordPanelTemplate');
  const [loading, setLoading] = useState<boolean>(false);

  const { setToastNotifications } = useToastContext();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError
  } = useForm<ChangePasswordSchemaType>({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onSubmit'
  });

  const submit = async (form: Record<string, string>) => {
    setLoading(true);
    if (auth?.currentUser?.email) {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, form.currentPassword);

      reauthenticateWithCredential(auth?.currentUser, credential)
        .then((user) => {
          if (auth?.currentUser)
            updatePassword(auth.currentUser, form.newPassword)
              .then(() => {
                setLoading(false);
                onCancel();
              })
              .catch((error) => {
                setLoading(false);
                setToastNotifications([{ message: tChangePassword('errorUpdate') }]);
              });
        })
        .catch((error) => {
          setLoading(false);
          setError('currentPassword', {
            type: 'manual',
            message: tChangePassword('invalidCurrentPassword')
          });
        });
    }
  };

  return (
    <Container>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {tChangePassword('changePassword')}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '2rem 2rem 0rem 2rem' }}>
        <Grid
          container
          display={'grid'}
          position={'relative'}
          gridTemplateColumns={'1fr'}
          width={'100%'}>
          <Grid width={'100%'} padding={'0 0.2rem 0 0'} margin={'0.5rem 0'}>
            <Controller
              name={'currentPassword'}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <TextFilter
                      autoComplete="off"
                      type="password"
                      inputProps={{
                        maxLength: 100,
                        autoComplete: 'current-password',
                        form: {
                          autocomplete: 'off'
                        }
                      }}
                      label={
                        <>
                          {tChangePassword('currentPassword')}
                          <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                        </>
                      }
                      value={value || ''}
                      onChange={onChange}
                      error={!!errors['currentPassword']}
                      helperText={
                        errors['currentPassword'] &&
                        errors['currentPassword'].type != 'nullable' &&
                        errors['currentPassword'].type != 'optionality' &&
                        (errors['currentPassword'] as { message: string }).message
                      }></TextFilter>
                  </>
                );
              }}
            />
          </Grid>
          <Grid width={'100%'} padding={'0 0.2rem 0 0'} margin={'0.5rem 0'}>
            <Controller
              name={'newPassword'}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <TextFilter
                      type="password"
                      inputProps={{
                        maxLength: 100,
                        autoComplete: 'new-password',
                        form: {
                          autocomplete: 'off'
                        }
                      }}
                      label={
                        <>
                          {tChangePassword('newPassword')}
                          <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                        </>
                      }
                      value={value || ''}
                      onChange={onChange}
                      error={!!errors['newPassword']}
                      helperText={
                        errors['newPassword'] &&
                        errors['newPassword'].type != 'nullable' &&
                        errors['newPassword'].type != 'optionality' &&
                        (errors['newPassword'] as { message: string }).message
                      }></TextFilter>
                  </>
                );
              }}
            />
          </Grid>
          <Grid width={'100%'} padding={'0 0.2rem 0 0'} margin={'0.5rem 0'}>
            <Controller
              name={'confirmNewPassword'}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <TextFilter
                      autoComplete="off"
                      type="password"
                      inputProps={{
                        maxLength: 100,
                        autoComplete: 'new-password',
                        form: {
                          autocomplete: 'off'
                        }
                      }}
                      label={
                        <>
                          {tChangePassword('confirmNewPassword')}
                          <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                        </>
                      }
                      value={value || ''}
                      onChange={onChange}
                      error={!!errors['confirmNewPassword']}
                      helperText={
                        errors['confirmNewPassword'] &&
                        errors['confirmNewPassword'].type != 'nullable' &&
                        errors['confirmNewPassword'].type != 'optionality' &&
                        (errors['confirmNewPassword'] as { message: string }).message
                      }></TextFilter>
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button onClick={onCancel}>{tCommon('buttons.cancel')}</Button>
          <LoadingButton
            variant="contained"
            disabled={Object.keys(errors).length > 0}
            loading={loading}
            onClick={handleSubmit(submit)}>
            {tCommon('buttons.save')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </Container>
  );
};

export default ChangePasswordPanelTemplate;
