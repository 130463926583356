import { RenderIf } from '@/components/atoms';
import { TextFilter } from '@/components/molecules';
import { FormHelperTextError } from '@/components/molecules/Card/Card.styles';
import { AutoCompleteField, CrudModes, SETS_FIND_BY_NAME } from '@/const';
import { SystemSets } from '@/const/sets';
import { useGet } from '@/hooks';
import { GeneralContinuity, ResourceSlot, Session, Set, SetDto, Topic, Type } from '@/types';
import {
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import { isString } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk, FaSearch } from 'react-icons/fa';
import {
  TbSquareRoundedLetterD,
  TbSquareRoundedLetterP,
  TbSquareRoundedLetterR
} from 'react-icons/tb';

import { useToastContext } from '@/context/ToastContext';
import { TransitionGroup } from 'react-transition-group';
import { filterByName, filterNulls, sortSessions } from './General.const';
import { CustomOutlinedInput } from './General.styles';
import SessionList from './components/Session';

export default function General({
  sessions,
  resourceSlots,
  mode
}: {
  resourceSlots: ResourceSlot[];
  sessions: Session[];
  mode: CrudModes;
}) {
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext<GeneralContinuity>();
  const recordTopicResourceSlot = useWatch({
    control,
    name: 'topicResourceSlotIds'
  });

  const readOnly = mode === CrudModes.READ;
  const type = useWatch({
    control,
    name: 'type'
  });

  useEffect(() => {
    if (type !== Type.D) clearDiscontinuity();
  }, [type]);

  const clearDiscontinuity = () => {
    setValue('discontinueItem', undefined, { shouldDirty: true });
  };

  const [showAll, setShowAll] = useState(Object.keys(recordTopicResourceSlot).length == 0);
  const [searchInputValue, setSearchInputValue] = useState('');
  const { t } = useTranslation('organisms/curriculumSidePanel');
  const { t: tCommon } = useTranslation('common');
  const { data: roles, error: errorRoles } = useGet<SetDto>(
    {
      url: SETS_FIND_BY_NAME.replace(':setName', SystemSets.Roles)
    },
    { revalidateOnMount: true }
  );
  const { setToastNotifications } = useToastContext();

  useEffect(() => {
    if (errorRoles) {
      setToastNotifications([{ message: t('errors.roles') }]);
    }
  }, [errorRoles]);

  const [selectedRoles, setSelectedRoles] = useState<Set[]>([]);

  const isSelected = (topicId: string, resourceSlotId: string) => {
    return recordTopicResourceSlot[topicId] === resourceSlotId;
  };
  const onSelect = (topicId: string, resourceSlotId: string) => {
    const discontinuity = getValues('discontinueItem');
    const recordTopicResourceSlot = getValues('topicResourceSlotIds');
    if (discontinuity?.topicId === topicId && discontinuity?.resourceSlotId === resourceSlotId) {
      return recordTopicResourceSlot;
    }
    if (discontinuity?.topicId === topicId && discontinuity?.resourceSlotId !== resourceSlotId) {
      clearDiscontinuity();
    }
    if (recordTopicResourceSlot[topicId] === resourceSlotId) {
      const { [topicId]: _, ...rest } = recordTopicResourceSlot;
      return rest;
    } else {
      return {
        ...recordTopicResourceSlot,
        [topicId]: resourceSlotId
      };
    }
  };

  const markAsDiscontinuity = (topicId: string, resourceSlotId: string) => {
    setValue('discontinueItem', { topicId, resourceSlotId });

    setValue(
      'topicResourceSlotIds',
      { ...recordTopicResourceSlot, [topicId]: resourceSlotId },
      {
        shouldDirty: true
      }
    );
    if (Object.keys(getValues('topicResourceSlotIds')).length >= 2)
      clearErrors('topicResourceSlotIds');
  };

  const onRightClick = (topicId: string, resourceSlotId: string) => {
    if (type !== Type.D || readOnly) return;
    const discontinuity = getValues('discontinueItem');
    markAsDiscontinuity(topicId, resourceSlotId);
    if (discontinuity?.resourceSlotId === resourceSlotId && discontinuity?.topicId === topicId) {
      clearDiscontinuity();
    }
  };

  const getResourceSlot = (id: ResourceSlot['id']) => {
    const resourceSlot = resourceSlots.find((rs) => rs.id === id);
    if (resourceSlot) return resourceSlot;
    else {
      throw new Error(`ResourceSlot with id ${id} not found`);
    }
  };

  const filterByRoles = (sessions: Session[], roles: Set[]): Session[] => {
    return sessions.reduce((acc, session) => {
      const topics = session.topics.reduce((acc, topic) => {
        const resourceSlots = topic.resourceSlots.filter((resourceSlot) => {
          const rs = getResourceSlot(resourceSlot.id);
          return roles.some((rol) => rol._id === rs?.role._id);
        });
        if (resourceSlots.length > 0) return acc.concat({ ...topic, resourceSlots });
        return acc;
      }, [] as Topic[]);
      if (topics.length > 0) return acc.concat({ ...session, topics });
      return acc;
    }, [] as Session[]);
  };

  const filterByStatus = (sessions: Session[]): Session[] => {
    const discontinuity = getValues('discontinueItem');
    return sessions.reduce((acc, session) => {
      const topics = session.topics.reduce((acc, topic) => {
        const resourceSlots = topic.resourceSlots.filter(
          (rs) =>
            recordTopicResourceSlot[topic.id] === rs.id ||
            (discontinuity?.resourceSlotId === rs.id && discontinuity?.topicId === topic.id)
        );
        if (resourceSlots.length > 0) return acc.concat({ ...topic, resourceSlots });
        return acc;
      }, [] as Topic[]);
      if (topics.length > 0) return acc.concat({ ...session, topics });
      return acc;
    }, [] as Session[]);
  };
  const sortedSessions = useMemo(() => sortSessions(sessions), []);

  const filteredByNulls = useMemo(() => filterNulls(sortedSessions), [sortedSessions]);

  const filteredByName = useMemo(
    () => filterByName(filteredByNulls, searchInputValue.toLocaleLowerCase(), getResourceSlot),
    [searchInputValue]
  );

  const filteredByRoles = useMemo(
    () =>
      selectedRoles.length > 0 ? filterByRoles(filteredByName, selectedRoles) : filteredByName,
    [selectedRoles, filteredByName]
  );

  const filteredByStatus = useMemo(
    () => (!showAll ? filterByStatus(filteredByRoles) : filteredByRoles),
    [showAll, filteredByRoles, recordTopicResourceSlot]
  );

  const handleChange = (event: SelectChangeEvent<Set[]>) => {
    const {
      target: { value }
    } = event;
    !isString(value) && setSelectedRoles(value);
  };

  const onRemove = (rolId: string) => {
    setSelectedRoles(selectedRoles.filter((rol) => rol._id !== rolId));
  };

  return (
    <Grid
      container
      display={'flex'}
      wrap="nowrap"
      flexDirection={'column'}
      sx={{ overflowY: 'auto' }}
      height={'100%'}
      mt={0}
      spacing={3}>
      <Grid
        width="100%"
        item
        display={'flex'}
        flexWrap="wrap"
        gap={'1.5rem'}
        alignItems="center"
        justifyContent="space-between">
        <Controller
          name={'type'}
          control={control}
          rules={{ required: true }}
          defaultValue={Type.R}
          render={({ field }) => {
            return (
              <>
                <ToggleButtonGroup
                  size="small"
                  value={field.value}
                  exclusive
                  disabled={readOnly}
                  onChange={(e, alignment) => {
                    if (alignment && !readOnly) field.onChange(alignment);
                  }}>
                  <ToggleButton
                    sx={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }}
                    value={Type.R}>
                    <TbSquareRoundedLetterR size="22" style={{ alignSelf: 'center' }} />
                    <Typography variant="subtitle2">{t('continuityTypes.required')}</Typography>
                  </ToggleButton>
                  <ToggleButton
                    sx={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }}
                    value={Type.P}>
                    <TbSquareRoundedLetterP size="22" style={{ alignSelf: 'center' }} />
                    <Typography variant="subtitle2">{t('continuityTypes.preferred')}</Typography>
                  </ToggleButton>
                  <ToggleButton
                    sx={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }}
                    value={Type.D}>
                    <TbSquareRoundedLetterD size="22" style={{ alignSelf: 'center' }} />
                    <Typography variant="subtitle2">
                      {t('continuityTypes.discontinuity')}
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </>
            );
          }}
        />
        <Controller
          name={'name'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextFilter
              sx={{ flexGrow: 1 }}
              fullWidth={false}
              disabled={readOnly}
              {...field}
              name={'continuity_name'}
              autoComplete={AutoCompleteField}
              size="small"
              variant="outlined"
              value={field.value}
              label={
                <>
                  {t('fields.name')}
                  <FaAsterisk size={12} style={{ marginLeft: '5px' }} />
                </>
              }
              onChange={field.onChange}
              InputLabelProps={{ shrink: true }}
              error={!!errors?.name}
            />
          )}
        />
      </Grid>

      <Grid item padding="0rem" sm={12} lg={12} display="flex" flexDirection={'column'} gap="1rem">
        <Box display="flex" alignItems="flex-start" gap="1rem">
          <Select
            sx={{
              width: '20rem',
              borderRadius: '4px',
              minHeight: 41,
              '	.MuiSelect-select': {
                display: 'flex'
              }
            }}
            multiple
            name={'role_name'}
            autoComplete={AutoCompleteField}
            displayEmpty
            size="small"
            value={selectedRoles}
            onChange={handleChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography lineHeight="inherit" color="darkgray" fontWeight={300}>
                    {t('placeholder.filterByRoles')}
                  </Typography>
                );
              }
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '6px 3px'
                  }}>
                  {selected.map((value) => (
                    <Chip
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onDelete={() => onRemove(value._id)}
                      key={value._id}
                      label={value.label}
                      size="small"
                    />
                  ))}
                </Box>
              );
            }}>
            {roles?.items?.map((role) => (
              <MenuItem key={role._id} value={role as any}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
          <Box display="flex" flexGrow={2} minHeight={41}>
            <CustomOutlinedInput
              size="small"
              sx={{ flexGrow: 3 }}
              placeholder={t('placeholder.filterByNames')}
              name={'filter_name'}
              autoComplete={AutoCompleteField}
              value={searchInputValue}
              onChange={(e) => {
                setSearchInputValue(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <FaSearch size={14}></FaSearch>
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Controller
          name={'topicResourceSlotIds'}
          control={control}
          rules={{
            validate: {
              minimumResourceSlots: (value) => {
                const final = Object.values(value).length >= 2;
                return final;
              }
            }
          }}
          render={({ field }) => (
            <Box
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '3px',
                height: '18rem',
                paddingInline: '0 1rem',
                paddingBlock: '0.5rem'
              }}>
              {filteredByStatus.map((session) => (
                <SessionList
                  onRightClick={onRightClick}
                  isSelected={isSelected}
                  session={session}
                  onSelect={(topicId, resourceSlotId) =>
                    !readOnly && field.onChange(onSelect(topicId, resourceSlotId))
                  }
                  getResourceSlot={getResourceSlot}
                  key={session.id}
                />
              ))}
            </Box>
          )}
        />
        <Box display="flex" justifyContent="space-between" marginTop="-0.8rem">
          <Box display="flex" alignItems="center">
            <FormControlLabel
              sx={{ marginLeft: '-6px' }}
              value={showAll}
              control={
                <Checkbox
                  checked={showAll}
                  size="small"
                  onChange={(e) => setShowAll(e.target.checked)}
                />
              }
              label={t('showAllResourceSlots')}
            />
          </Box>
          <Typography display="flex" alignItems="center" gap="1rem">
            <RenderIf condition={!!errors?.['topicResourceSlotIds']}>
              <FormHelperTextError display={'flex'} alignItems="center">
                {t(errors?.['topicResourceSlotIds']?.type as any)}
              </FormHelperTextError>
            </RenderIf>
            {t('nResourceSlotsSelected', {
              num: Object.values(recordTopicResourceSlot).length
            })}
          </Typography>
        </Box>
        <TransitionGroup>
          {type === Type.D && (
            <Collapse>
              <Typography>
                <strong>{tCommon('utils.note')}</strong> {t('hintDiscontinuity')}
              </Typography>
            </Collapse>
          )}
        </TransitionGroup>
      </Grid>
    </Grid>
  );
}
