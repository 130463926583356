import { theme } from '@/theme';
import { Typography, styled } from '@mui/material';
type CardProps = {
  isSelected: boolean;
  isFaded?: boolean;
};

export const Card = styled('div')<CardProps>`
  cursor: pointer;
  width: 100%;
  min-height: 3.125rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: ${(props) =>
    props.isSelected ? theme.palette.divider : theme.palette.background.default};
  opacity: ${(props) => (props.isFaded ? 0.5 : 1)};
  box-shadow: ${(props) =>
    props.isSelected ? `0px 0px 3px 0.5px ${theme.palette.grey[500]}` : 'none'};
  padding-inline: 0.7rem;
`;

export const CustomTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-box-align: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
`;
