import { theme } from '@/theme';
import { styled } from '@mui/material';

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;

export const BoardWrapper = styled('div')`
  position: relative;
  scrollbar-gutter: stable;
  background-color: rgba(234, 234, 247, 0.31);
  //background-color: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
  //padding: 0 1.2rem 4rem 1.2rem;
  border-right: 1px solid ${theme.palette.divider};
  gap: 1rem;
  flex: 1;
  //width: 100%;
  height: 100%;
  overflow: auto;
  // change color of scrollbar
  &::-webkit-scrollbar-track {
    background-color: ${theme.palette.common.white};
  }
  background: linear-gradient(to right, black 0px, transparent 0px),
    linear-gradient(to right, ${theme.palette.divider} 1px, transparent 1px);
  background-size: 50% 100%, calc(50% + 0.29rem) 100%;
  background-position: left center, right center;
  background-repeat: no-repeat;
`;

export const TopicGhost = styled('div')`
  position: relative;
  width: 8rem;
  height: 2rem;
  border: 1.5px dotted green;
  border-radius: 5px;
`;

export const HintNumber = styled('div')`
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 25px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  z-index: 3;
  right: -10px;
`;

export const SmallModalStyle = {
  height: '50vh',
  width: '65vw',
  minWidth: '28rem',
  minHeight: '26rem',
  maxWidth: '88rem',
  maxHeight: '90rem',
  borderRadius: '16px'
};
