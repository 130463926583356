import { RenderIf } from '@/components/atoms';
import { ObjectidAutocomplete } from '@/components/molecules';
import { AutoCompleteField, COLLECTIONS_FIND_ONE, Entities, EntityRoutes } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { LabeledReference, ResourceDto } from '@/types';
import { Box, Checkbox, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ResourceSearchProps = {
  onChange: (resource: ResourceDto) => void;
  qualifiedIds?: { ids?: string[] };
  isLoading: boolean;
};

const ResourceSearch = ({ onChange, qualifiedIds, isLoading }: ResourceSearchProps) => {
  const [qualified, setQualified] = useState(true);
  const { fetch, loading, error } = useFetch();
  const { t: tJobEvent } = useTranslation('templates/jobEventPanelTemplate');
  const { t: tCommon } = useTranslation();
  const { setToastNotifications } = useToastContext();

  useEffect(() => {
    if (error) {
      if (error.response?.data?.code == 'resource_not_found') {
        setToastNotifications([
          {
            message: tCommon('errors.entityNotFound', { entity: tCommon('entities.resources_one') })
          }
        ]);
      } else {
        setToastNotifications([{ message: tJobEvent('errors.resource') }]);
      }
    }
  }, [error]);

  const getResource = async (id: string) => {
    return fetch({
      url: COLLECTIONS_FIND_ONE.replace(':collection_name', EntityRoutes.RESOURCES).replace(
        ':id',
        id
      )
    }) as Promise<ResourceDto>;
  };

  return (
    <Box display="flex" alignItems="center" flex="1">
      <RenderIf condition={loading}>
        <Box display="flex" alignItems="center" gap="0.7rem" pl="0.3rem" height="2.396rem">
          <Skeleton variant="circular" width={19} height={19} />
          <Skeleton variant="circular" width={27} height={27} />
          <Skeleton variant="text" width={200} height={22} />
        </Box>
      </RenderIf>
      <RenderIf condition={!loading}>
        <ObjectidAutocomplete
          name={`${Entities.RESOURCES}_name`}
          autoComplete={AutoCompleteField}
          sx={{
            flex: 1,
            '.MuiInputBase-sizeSmall': {
              borderRadius: '5px 0px 0px 5px '
            }
          }}
          multiple={false}
          value={undefined}
          disabled={isLoading}
          placeholder={isLoading ? tCommon('text.loading') : tJobEvent('searchResource')}
          referencedCollection={EntityRoutes.RESOURCES}
          onChange={async (e: unknown) => {
            const resource = await getResource((e as LabeledReference)._id);
            onChange(resource);
          }}
          additionalProps={qualified ? qualifiedIds : {}}
        />
        <Box
          sx={{
            border: '1px solid lightgray',
            borderLeft: 'none',
            borderRadius: '1px 5px 5px 1px',
            paddingRight: '0.563rem'
          }}
          display="flex"
          alignItems="center">
          <Checkbox
            sx={{ paddingRight: '0.2rem' }}
            size="small"
            value={qualified}
            checked={qualified}
            onChange={(e) => setQualified(!qualified)}
          />
          <Typography variant="caption">{tJobEvent('onlyQualified')}</Typography>
        </Box>
      </RenderIf>
    </Box>
  );
};

export default ResourceSearch;
