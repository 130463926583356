import { ResourceDto } from '@/types';
import { PropsWithChildren } from 'react';
import ResourceContext, { ResourceType } from './ResourceContext';

export type ResourceContextProviderProps = PropsWithChildren<unknown>;

const ResourceContextProvider = ({ children }: ResourceContextProviderProps) => {
  const resources: ResourceType = {};

  const handleResources = (newResources: ResourceType) => {
    Object.assign(resources, newResources);
  };

  const addResources = (newResources: ResourceDto[]) => {
    Object.assign(
      resources,
      newResources.reduce((acc, curr) => ({ ...acc, [curr._id]: curr }), {})
    );
  };

  const deleteResource = (idResource: string) => {
    delete resources[idResource];
  };

  return (
    <ResourceContext.Provider
      value={{
        resources,
        addResources,
        handleResources,
        deleteResource
      }}>
      {children}
    </ResourceContext.Provider>
  );
};

export default ResourceContextProvider;
