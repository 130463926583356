import { Accordion, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GlobalFilterContainer = styled(Accordion)`
  /* display: flex;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-right: 1px solid ${({ theme }) => theme.palette.divider}; */
`;

export const Container = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.common.white};

  & > * {
    margin: ${({ theme }) => theme.spacing(1)} 0px !important;
  }
`;

export const Footer = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  bottom: 0;
  backdrop-filter: blur(20px);
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  padding: ${({ theme }) => theme.spacing(1)};

  & > * {
    margin-left: ${({ theme }) => theme.spacing(1)}!important;
  }
`;

export const Title = styled(Typography)`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  font-size: 0.85rem;
`;

export const Header = styled('div')`
  width: 100%;
  /* background-color: ${({ theme }) => theme.palette.background.default}; */
  flex-grow: 0;
  display: flex;
  gap: 0.2rem;
  align-items: center;
  /* border-bottom: 1px solid ${({ theme }) => theme.palette.divider}; */
  position: relative;
  z-index: 10;

  color: ${({ theme }) => theme.palette.grey[500]};
  display: flex;
`;

type ButtonSettingsProps = {
  width?: string;
  height?: string;
};
export const ButtonSettings = styled('button')<ButtonSettingsProps>`
  width: ${({ width }) => width || '30px'};
  height: ${({ height }) => height || '30px'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.grey[700]};
  transition: background-color 0.2s, color 0.2s, border 0.2s;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  user-select: none;
  color: ${({ theme }) => theme.palette.grey[800]};
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.palette.grey[700]};
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }

  & > i {
    font-size: 12px;
  }
`;

type MasterDetailButtonProps = {
  status: number;
};
export const MasterDetailButton = styled(ButtonSettings)<MasterDetailButtonProps>`
  color: ${({ status, theme }) =>
    status != 0 ? theme.palette.secondary.light : theme.palette.grey[800]};
`;

type ButtonFilterProps = {
  $isactive: boolean;
  letter: string;
};

export const ButtonFilter = styled(ButtonSettings, {
  shouldForwardProp: (prop) => prop !== '$isactive'
})<ButtonFilterProps>`
  color: ${({ $isactive, theme }) =>
    $isactive ? theme.palette.secondary.light : theme.palette.grey[800]};
  ::after {
    content: '${({ letter }) => letter}';
    position: absolute;
    bottom: 2px;
    left: 3px;
    font-size: 8px;
    border-radius: 50%;
    color: ${({ $isactive, theme }) =>
      $isactive ? theme.palette.secondary.light : theme.palette.grey[800]};
    background-color: transparent;
    width: 12px;
    height: 12px;
    display: grid;
    place-content: center;
  }
`;

export const ButtonSkeleton = styled(Skeleton)`
  width: 30px;
  height: 30px;
  margin-left: 0.6em;
`;
