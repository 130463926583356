import { Permission } from '@/components/templates/EntityPanels/RolePanelTemplate/RolePanelTemplate.schema';
import { auth } from '@/config/firebase.config';
import { AUTH_ROLES_PERMISSIONS, COLLECTIONS_FIND_ONE, Entities, SOCKET_URL } from '@/const';
import { useFetch } from '@/hooks';
import { ResourceDto } from '@/types';
import { IdTokenResult, User } from 'firebase/auth';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useSocketContext } from '../SocketContext';
import AuthContext from './AuthContext';

export type AuthContextProviderProps = PropsWithChildren<unknown>;

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [userTenant, setUserTenant] = useState<string | null>(null);
  const [tokenInfo, setTokenInfo] = useState<IdTokenResult | null>();
  const {
    data: userPermissions,
    fetch: fetchUserPermissions,
    error: errorPermissions
  } = useFetch<Permission[]>();
  const {
    data: userResource,
    fetch: fetchUserResource,
    mutate: setUserResource,
    error: errorUserResource
  } = useFetch<ResourceDto | null | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const { connectSocket, disconnectSocket, online } = useSocketContext();

  useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          if (token?.claims?.app_metadata?.tenant) {
            setTokenInfo(token);
            setUser(user);
          }
        });
      } else {
        setTokenInfo(null);
        setUser(user);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (tokenInfo) {
      setUserTenant(tokenInfo?.claims?.app_metadata?.tenant);

      if (tokenInfo?.claims?.app_metadata?.roles) {
        fetchUserPermissions({
          url: AUTH_ROLES_PERMISSIONS,
          data: { ids: tokenInfo.claims.app_metadata.roles },
          method: 'POST'
        });
      }

      if (tokenInfo?.claims?.app_metadata?.resourceId) {
        fetchUserResource({
          url: COLLECTIONS_FIND_ONE.replace(':collection_name', Entities.RESOURCES).replace(
            ':id',
            tokenInfo.claims.app_metadata.resourceId
          )
        });
      } else {
        setUserResource(null);
      }
    }
  }, [tokenInfo]);

  useEffect(() => {
    if (user && userTenant && userPermissions) {
      if (userPermissions.includes(Permission.MainFullAccess)) {
        user.getIdToken().then((token: string) => {
          connectSocket(SOCKET_URL, userTenant, token);
        });
      } else if (online) {
        disconnectSocket();
      }
      setLoading(false);
    } else if (online) {
      disconnectSocket();
    }
  }, [user, userTenant, userPermissions]);

  return (
    <AuthContext.Provider
      value={{
        user,
        userTenant,
        setUser,
        setTokenInfo,
        userResource,
        userPermissions,
        errorPermissions,
        errorUserResource,
        loading
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
