import { RenderIf } from '@/components/atoms';
import { FormHelperTextError } from '@/components/molecules/Card/Card.styles';
import {
  ActionTypes,
  DEFAULT_ID,
  ScaleConstraints,
  SelectedTopic,
  useCurriculumSetupContext
} from '@/context/CurriculmSetupContext';
import { useWindowSize } from '@/hooks';
import { CurriculumForm, TimeStrategy, Topic } from '@/types';
import { cloneDeep } from 'lodash';
import { PropsWithChildren, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { useDebouncyEffect } from 'use-debouncy';
import { DragNDropComponents, TabOptions } from '../../../CurriculumSetup.const';
import { TopicCard } from '../../TopicCard';
import { ScrollButton } from '../SessionWrapper.styles';
import { SessionContainer } from './SessionRow.styles';

export type SessionRowType = PropsWithChildren<{
  index: number;
  sessionId: string;
  scale: number;
}>;

const SessionRow = ({ index, sessionId, scale }: SessionRowType) => {
  const {
    selectedTopics,
    handleSelectedTopics,
    handleSelectedSessions,
    setSelectedType,
    stopScrolling,
    onDragToScroll,
    getUniqueKey
  } = useCurriculumSetupContext();
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext<CurriculumForm>();
  const [width] = useWindowSize(20);
  const { t } = useTranslation('organisms/curriculumSetup');
  const isPressing = useRef(false);
  const startX = useRef(0);
  const topics: Topic[] = useWatch({
    control,
    name: `structure.sessions.${index}.topics`,
    defaultValue: []
  });

  const moveItem = useCallback(
    (
      sourceSessionIdx: number,
      dragTopicIdx: number,
      destinySessionIdx: number,
      dropTopicIdx: number
    ) => {
      const destinationSessionTopics: Topic[] = cloneDeep(
        getValues(`structure.sessions.${index}.topics`)
      );
      const newSelection = cloneDeep(selectedTopics);
      selectedTopics.forEach((selectedTopic, selectionIdx) => {
        if (selectedTopic.sourceSession.index == index) {
          const selectedTopicIdx = destinationSessionTopics.findIndex(
            (findTopic) => findTopic.id === selectedTopic.id
          );
          if (selectedTopicIdx !== -1) {
            const [selectedTopicTemp] = destinationSessionTopics.splice(selectedTopicIdx, 1);
            destinationSessionTopics.splice(dropTopicIdx, 0, selectedTopicTemp);
            newSelection[selectionIdx].index = destinationSessionTopics.indexOf(selectedTopicTemp);
          }
        } else {
          const sourceSessionTopics: Topic[] = cloneDeep(
            getValues(`structure.sessions.${selectedTopic.sourceSession.index}.topics`)
          );
          const selectedTopicIdx = sourceSessionTopics.findIndex(
            (findTopic) => findTopic.id === selectedTopic.id
          );
          if (selectedTopicIdx !== -1) {
            const [selectedTopicTemp] = sourceSessionTopics.splice(selectedTopicIdx, 1);
            destinationSessionTopics.splice(dropTopicIdx, 0, selectedTopicTemp);
            newSelection[selectionIdx].index = destinationSessionTopics.indexOf(selectedTopicTemp);
            newSelection[selectionIdx].sourceSession = { index, id: sessionId };
            setValue(
              `structure.sessions.${selectedTopic.sourceSession.index}.topics`,
              sourceSessionTopics,
              { shouldDirty: true }
            );
          }
        }
      });
      setValue(`structure.sessions.${index}.topics`, destinationSessionTopics, {
        shouldDirty: true
      });
      clearErrors(`structure.sessions.${index}.id`);
      handleSelectedSessions({ type: ActionTypes.DELETE_ALL });
      handleSelectedTopics({ type: ActionTypes.REPLACE, selection: newSelection });
      setSelectedType(TabOptions.TOPIC);
    },
    [selectedTopics, index]
  );

  const checkAndDeleteIfExist = useCallback(
    (id: string) => {
      if (selectedTopics.length > 0) {
        const topicToDelete = selectedTopics.find((selectedTopic) => selectedTopic.id === id);
        if (topicToDelete) {
          const sourceSessionTopics: Topic[] = cloneDeep(
            getValues(`structure.sessions.${topicToDelete.sourceSession.index}.topics`)
          );
          const idx = sourceSessionTopics.findIndex((topic) => topic.id === id);
          if (idx >= 0) {
            sourceSessionTopics.splice(idx, 1);
            setValue(
              `structure.sessions.${topicToDelete.sourceSession.index}.topics`,
              sourceSessionTopics,
              { shouldDirty: true }
            );
          }
        }
      }
    },
    [selectedTopics] //TODO: Temporal Fix
  );

  const addItem = useCallback(
    (sessionIndex: number, dropTopicIdx: number, newTopic: Topic) => {
      if (newTopic.id === DEFAULT_ID) newTopic.id = 'T' + getUniqueKey();
      checkAndDeleteIfExist(newTopic.id);
      const sourceSessionTopics: Topic[] = cloneDeep(
        getValues(`structure.sessions.${sessionIndex}.topics`)
      );
      if (dropTopicIdx in sourceSessionTopics) {
        sourceSessionTopics.splice(dropTopicIdx, 0, newTopic);
        setValue(`structure.sessions.${sessionIndex}.topics`, sourceSessionTopics, {
          shouldDirty: true
        });
        clearErrors(`structure.sessions.${sessionIndex}.id`);
        handleSelectedSessions({ type: ActionTypes.DELETE_ALL });
        handleSelectedTopics({
          type: ActionTypes.REPLACE,
          selection: [
            {
              id: newTopic.id,
              index: dropTopicIdx,
              sourceSession: { index, id: sessionId }
            }
          ]
        });
        setSelectedType(TabOptions.TOPIC);
      }
    },
    [selectedTopics]
  );

  const [, drop] = useDrop(
    {
      accept: [DragNDropComponents.TOPIC, DragNDropComponents.NEW_TOPIC],
      drop(item: { index: number; sessionIndex: number; topic: Topic }, monitor) {
        if (monitor.isOver({ shallow: false })) {
          const itemType = monitor.getItemType() as DragNDropComponents | null;
          const destinationSessionTopics: Topic[] = cloneDeep(
            getValues(`structure.sessions.${index}.topics`)
          );
          if (itemType === DragNDropComponents.TOPIC) {
            const newSelection = cloneDeep(selectedTopics);
            selectedTopics.forEach((selectedTopic: SelectedTopic, selectionIdx) => {
              if (selectedTopic.sourceSession.index == index) {
                const selectedTopicIdx = destinationSessionTopics.findIndex(
                  (findTopic) => findTopic.id === selectedTopic.id
                );
                if (selectedTopicIdx !== -1) {
                  const [selectedTopicTemp] = destinationSessionTopics.splice(selectedTopicIdx, 1);
                  destinationSessionTopics.push(selectedTopicTemp);
                  newSelection[selectionIdx].index = destinationSessionTopics.length - 1;
                }
              } else {
                const sourceSessionTopics: Topic[] = cloneDeep(
                  getValues(`structure.sessions.${selectedTopic.sourceSession.index}.topics`)
                );
                const selectedTopicIdx = sourceSessionTopics.findIndex(
                  (findTopic) => findTopic.id === selectedTopic.id
                );
                if (selectedTopicIdx !== -1) {
                  const [selectedTopicTemp] = sourceSessionTopics.splice(selectedTopicIdx, 1);
                  destinationSessionTopics.push(selectedTopicTemp);
                  newSelection[selectionIdx].sourceSession = { index, id: sessionId };
                  newSelection[selectionIdx].index = destinationSessionTopics.length - 1;
                  setValue(
                    `structure.sessions.${selectedTopic.sourceSession.index}.topics`,
                    sourceSessionTopics,
                    { shouldDirty: true }
                  );
                }
              }
            });
            handleSelectedTopics({ type: ActionTypes.REPLACE, selection: newSelection });
            setSelectedType(TabOptions.TOPIC);
            setValue(`structure.sessions.${index}.topics`, destinationSessionTopics, {
              shouldDirty: true
            });
            clearErrors(`structure.sessions.${index}.id`);
          }
          if (itemType === DragNDropComponents.NEW_TOPIC) {
            const newTopic = item.topic as Topic;
            if (newTopic.id === DEFAULT_ID) {
              newTopic.id = 'T' + getUniqueKey();
            }
            checkAndDeleteIfExist(newTopic.id);
            destinationSessionTopics.push(newTopic);
            handleSelectedTopics({
              type: ActionTypes.REPLACE,
              selection: [
                {
                  id: newTopic.id,
                  index: destinationSessionTopics.length - 1,
                  sourceSession: { index, id: sessionId }
                }
              ]
            });
            setSelectedType(TabOptions.TOPIC);
            setValue(`structure.sessions.${index}.topics`, destinationSessionTopics, {
              shouldDirty: true
            });
            clearErrors(`structure.sessions.${index}.id`);
          }
        }
      }
    },
    [selectedTopics, index]
  );

  const [scrollElement, setScrollElement] = useState<HTMLDivElement>();

  const intervalId = useRef<NodeJS.Timer>();
  const getXOffset = useCallback(
    (direction: 'left' | 'right') => {
      if (scrollElement) {
        const { scrollWidth, scrollLeft } = scrollElement;
        const range = scrollWidth / 24;
        if (direction === 'left') return Math.floor((scrollLeft - 1) / range) * range;
        if (direction === 'right') return Math.ceil((scrollLeft + 1) / range) * range;
      }
      return 0;
    },
    [scrollElement, scale]
  );

  const handleScroll = useCallback(
    (direction: 'left' | 'right') => {
      if (scrollElement) {
        const xOffset = getXOffset(direction);
        const hours = (scrollElement?.scrollLeft * 24) / scrollElement.scrollWidth;
        setValue(`viewSettings.scrollTimes.${sessionId}`, hours, { shouldDirty: true });
        scrollToElement(xOffset);
        intervalId.current = setInterval(() => {
          const xOffset = getXOffset(direction);
          scrollToElement(xOffset);
        }, 300);
      }
    },
    [scrollElement, scale]
  );

  const scrollToElement = useCallback(
    (xOffset: number) => {
      if (scrollElement)
        scrollElement.scrollTo({
          left: xOffset,
          behavior: 'smooth'
        });
    },
    [scrollElement]
  );

  const handleStopScrolling = useCallback(
    (e) => {
      clearInterval(intervalId.current as NodeJS.Timeout);
      if (scrollElement && intervalId && isPressing.current) {
        const hours = (scrollElement?.scrollLeft * 24) / scrollElement.scrollWidth;
        setValue(`viewSettings.scrollTimes.${sessionId}`, hours, { shouldDirty: true });
        isPressing.current = false;
      }
      intervalId.current = undefined;
    },
    [intervalId.current, scrollElement]
  );

  useEffect(() => {
    window.addEventListener('mouseup', handleStopScrolling);

    return () => {
      window.removeEventListener('mouseup', handleStopScrolling);
    };
  }, [intervalId.current]);

  const [canScroll, setCanScroll] = useState<boolean>(false);

  useDebouncyEffect(
    () => {
      if (scrollElement) {
        const { scrollWidth, offsetWidth } = scrollElement;
        setCanScroll(() => scrollWidth > offsetWidth);
      } else setCanScroll(false);
    },
    505,
    [scrollElement, scale, topics]
  );
  useEffect(() => {
    if (scrollElement) {
      const xOffset = getScrollPosition();
      scrollToElement(xOffset);
    }
  }, [width]);

  useEffect(() => {
    if (scrollElement) {
      setTimeout(() => {
        const xOffset = getScrollPosition();
        scrollToElement(xOffset);
        if (scale === ScaleConstraints.MIN)
          setValue(`viewSettings.scrollTimes.${sessionId}`, 0, {
            shouldDirty: true
          });
      }, 500);
    }
  }, [scale, scrollElement]);

  const getScrollPosition = () => {
    const scrollTime = getValues(`viewSettings.scrollTimes.${sessionId}`);
    if (scrollTime && scrollElement) return (scrollTime * scrollElement.scrollWidth) / 24;
    return 0;
  };
  useEffect(() => {
    if (scrollElement) scrollElement.scrollLeft = getScrollPosition();
  }, [scrollElement]);

  return (
    <>
      <RenderIf condition={canScroll}>
        <ScrollButton
          direction="left"
          style={{
            left: '-1.25rem'
          }}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            isPressing.current = true;
            handleScroll('left');
          }}
          onMouseUp={() => (isPressing.current = false)}>
          <BsChevronCompactLeft size={16} />
        </ScrollButton>
        <ScrollButton
          onClick={(e) => e.stopPropagation()}
          direction="right"
          style={{
            right: '-1.25rem'
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            isPressing.current = true;
            handleScroll('right');
          }}
          onMouseUp={() => (isPressing.current = false)}>
          <BsChevronCompactRight size={16} />
        </ScrollButton>
      </RenderIf>
      <SessionContainer
        onMouseDown={(e) => {
          if (scrollElement) {
            onDragToScroll(scrollElement, e.pageX, sessionId);
            startX.current = e.pageX;
          }
        }}
        onClick={(e) => {
          if (Math.abs(startX.current - e.pageX) > 6) {
            e.stopPropagation();
          }
        }}
        onMouseUp={() => {
          stopScrolling(setValue);
        }}
        ref={(node) => {
          if (node) {
            setScrollElement(node);
          }
          drop(node);
        }}>
        <RenderIf condition={!!errors.structure?.sessions?.[index]?.id?.type}>
          <FormHelperTextError variant="subtitle2" position="absolute" top="0.5rem" left="1rem">
            {t(('errors.' + errors.structure?.sessions?.[index]?.id?.type) as any)}
          </FormHelperTextError>
        </RenderIf>
        {topics.map((topic, topicIdx) => {
          return (
            <TopicCard
              sessionStrategy={TimeStrategy.RESOURCE_MISSIONS}
              moveItem={moveItem}
              addItem={addItem}
              key={topic.id}
              index={topicIdx}
              sessionIndex={index}
              sessionId={sessionId}
              scale={scale}
            />
          );
        })}
      </SessionContainer>
    </>
  );
};

export default memo(SessionRow);
