import { theme } from '@/theme';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const TruncatedTypography = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
`;

export const defaultStyles = {
  position: 'absolute',
  backgroundColor: theme.palette.background.default,
  height: 'calc(100% - 1.46rem)',
  width: 'calc(100% - 2rem)'
};
