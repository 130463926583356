import { theme } from '@/theme';
import { TabPanel as TabPanelMUI } from '@mui/lab';
import { styled } from '@mui/material';
export const Canvas = styled('div')`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 3rem - 3.2rem);

  &::-webkit-scrollbar-track {
    background-color: ${theme.palette.common.white};
  }
`;

export const TabWrapper = styled('div')`
  width: 35%;
  height: calc(100vh - 3rem - 3.2rem);
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.common.white};
`;

export const TabPanel = styled(TabPanelMUI)`
  overflow: auto;
  padding: 0;
  position: relative;
  height: 100%;
`;
