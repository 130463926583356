import { RenderIf } from '@/components/atoms';
import { DATE_FORMAT_PICKER } from '@/const';
import { PeriodDate, PeriodDateValue } from '@/types';
import { FormControl, FormControlProps, FormHelperText, TextFieldProps } from '@mui/material';
import { DateTime } from 'luxon';
import { Container, DesktopDatePickerEnd, DesktopDatePickerStart } from './PeriodFilter.styles';

export type PeriodFilterProps = {
  label?: string;
  value?: PeriodDate;
  onChange: (value: PeriodDate) => void;
  helperText?: string;
  startFieldProps?: TextFieldProps;
  endFieldProps?: TextFieldProps;
} & Omit<FormControlProps, 'onChange'>;

const PeriodFilter = ({
  value,
  onChange,
  label,
  helperText,
  startFieldProps = {},
  endFieldProps = {},
  error,
  ...props
}: PeriodFilterProps) => {
  const handleChange = (property: keyof PeriodDate) => (newValue: PeriodDateValue) => {
    onChange({ ...value, [property]: newValue });
  };
  const formatDate = (date: string | DateTime) => {
    if (typeof date === 'string') {
      return DateTime.fromISO(date);
    }
    return date.toFormat('HH:mm');
  };
  const fechaInicial = typeof value?.$gte === 'string' ? formatDate(value?.$gte) : value?.$gte || null
  const fechaFinal = typeof value?.$lte === 'string' ? formatDate(value?.$lte) : value?.$lte || null
  return (
    <>
      <FormControl fullWidth error={error} {...props}>
        <Container>
          <DesktopDatePickerStart
            label={`${label} - from`}
            format={DATE_FORMAT_PICKER}
            value={fechaInicial}
            onChange={(value) => handleChange('$gte')(value as PeriodDateValue)}
            slotProps={{
              textField: {
                error,
                size: 'small',
                InputLabelProps: {
                  shrink: true
                },
                ...startFieldProps
              }
            }}
          />
          <DesktopDatePickerEnd
            label="to"
            format={DATE_FORMAT_PICKER}
            value={fechaFinal}
            onChange={(value) => handleChange('$lte')(value as PeriodDateValue)}
            slotProps={{
              textField: {
                error,
                size: 'small',

                InputLabelProps: {
                  shrink: true
                },
                ...endFieldProps
              }
            }}
          />
        </Container>
        <RenderIf condition={!!helperText}>
          <FormHelperText error={error}>{helperText}</FormHelperText>
        </RenderIf>
      </FormControl>
    </>
  );
};

export default PeriodFilter;

