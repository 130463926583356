import { FieldFilterType } from '@/types';
import { createContext } from 'react';

export type ValueOf<T> = T[keyof T];
export type filterType = Record<string, FieldFilterType[]>;
export type settingsType = Record<string, FieldFilterType['_id'][] | undefined>;

export type FilterContextType = {
  size: number;
  setSize: (newSize: number) => void;
  settings?: settingsType;
  fullText: Record<string, string>;
  masterDetail: number;
  handleSettings: (
    key: string,
    newSettings:
      | ValueOf<settingsType>
      | ((settings?: ValueOf<settingsType>) => ValueOf<settingsType>)
  ) => void;
  handleFullText: (key: string, newFullText: string) => void;
  handleMasterDetail: (newMasterDetail: number) => void;
  getSetting: (key: string) => ValueOf<settingsType>;
};

export default createContext<FilterContextType>({} as FilterContextType);
