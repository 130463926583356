import { ADVANCED_SEARCH, Entities, EntityRoutes, FULL_SEARCH, SystemFilters } from '@/const';
import { useFilterContext } from '@/context/FilterContext';
import {
  BryntumSorter,
  DocumentDto,
  FieldFilterType,
  JobDto,
  PropertySortingType,
  QualifiedResourcesDto,
  ResourceDto,
  ResourceFilterByRecords
} from '@/types';

import { transformFilter } from '@/utils';
import axios, { CancelTokenSource } from 'axios';
import { useState } from 'react';

export const useGetFilteredData = () => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { size } = useFilterContext('');
  const [cancelTokenSourceResource, setCancelTokenSourceResource] =
    useState<CancelTokenSource | null>(null);
  const [cancelTokenSourceJob, setCancelTokenSourceJob] = useState<CancelTokenSource | null>(null);

  const transformSortersToFieldSorters = (fieldSorters: BryntumSorter[]): PropertySortingType[] => {
    return fieldSorters.map((sortVal) => ({
      property: sortVal.field,
      ascending: sortVal.ascending
    }));
  };

  const formatRecordsForm = (data: ResourceFilterByRecords) => {
    return {
      documents: data?.documents?.map((document) => ({
        _id: document._id,
        assigned: document.assigned
      })),
      operation: data.operation
    };
  };

  const fullTextSearch = async (
    entity: Entities.RESOURCES | Entities.JOBS,
    queryData: object,
    fullText?: string,
    fieldSorters?: BryntumSorter[]
  ) => {
    if (fullText) {
      const fullTextData = await axios.post(FULL_SEARCH, {
        entity: entity,
        text: fullText
      });
      queryData[entity == Entities.RESOURCES ? 'resourceIds' : 'jobIds'] = (
        fullTextData.data as { ids: string[] } | undefined
      )?.ids;
      if (fieldSorters) {
        queryData['sorting'] = [
          ...transformSortersToFieldSorters(fieldSorters),
          { property: entity == Entities.RESOURCES ? 'resourceIds' : 'jobIds' }
        ];
      } else {
        queryData['sorting'] = [
          { property: entity == Entities.RESOURCES ? 'resourceIds' : 'jobIds' }
        ];
      }
    } else if (fieldSorters) queryData['sorting'] = transformSortersToFieldSorters(fieldSorters);
    return queryData;
  };

  const fetchByDocuments = async (
    fieldFilters: FieldFilterType[],
    fieldSorters?: BryntumSorter[]
  ) => {
    setLoading(true);
    try {
      const filtersCopy = [...fieldFilters];
      let queryData = {};
      const types = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.ENTITY_TYPE)?.value as
          | DocumentDto[]
          | undefined
      )?.map(({ _id }) => _id);

      const properties = filtersCopy
        .filter(({ _id }) => _id !== SystemFilters.ENTITY_TYPE)
        .map(transformFilter)
        .filter((filter) => filter !== null && filter !== undefined);

      queryData = {
        propertiesFilter: {
          properties,
          operation: 'and'
        }
      };
      if (types) queryData['types'] = types;
      if (fieldSorters) queryData['sorting'] = transformSortersToFieldSorters(fieldSorters);
      const filtersData = await axios.post(
        ADVANCED_SEARCH.replace(':entity', Entities.DOCUMENTS),
        queryData,
        {
          params: { size }
        }
      )
      setData(filtersData.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchByJobs = async (
    fieldFilters: FieldFilterType[],
    fullText?: string,
    resourceIds?: string[],
    fieldSorters?: BryntumSorter[]
  ) => {
    setLoading(true);
    if (cancelTokenSourceJob) {
      cancelTokenSourceJob.cancel('Request canceled');
    }
    // Create a new cancel token source
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSourceJob(newCancelTokenSource);
    try {
      const filtersCopy = [...fieldFilters];
      let queryData = {};
      const curriculumIds = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.JOBS_CURRICULUM)?.value as
          | JobDto[]
          | undefined
      )?.map(({ _id }) => _id);

      const statuses = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.JOBS_STATUS)?.value as
          | { _id: string; label: string }[]
          | undefined
      )?.map(({ _id }) => _id);

      const topicStatus = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.TOPIC_STATUS)?.value as
          | { _id: string; label: string }[]
          | undefined
      )?.map(({ _id }) => _id);

      const properties = filtersCopy
        .filter(
          ({ _id }) =>
            ![SystemFilters.JOBS_CURRICULUM, SystemFilters.JOBS_STATUS, SystemFilters.TOPIC_STATUS].includes(
              _id as SystemFilters
            )
        )
        .map(transformFilter)
        .filter((filter) => filter);

      queryData = {
        curriculumIds,
        resourceIds,
        statuses,
        topicStatus,
        propertiesFilter: {
          properties,
          operation: 'and'
        }
      };

      await fullTextSearch(Entities.JOBS, queryData, fullText, fieldSorters);
      const filtersData = await axios.post(
        ADVANCED_SEARCH.replace(':entity', Entities.JOBS),
        queryData,
        {
          params: { size },
          cancelToken: newCancelTokenSource.token
        }
      );
      setData(filtersData.data);
      setCancelTokenSourceJob(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchByResources = async (
    fieldFilters: FieldFilterType[],
    fullText?: string,
    jobIds?: string[],
    documentsFilter?: ResourceFilterByRecords,
    fieldSorters?: BryntumSorter[]
  ) => {
    setLoading(true);
    if (cancelTokenSourceResource) {
      cancelTokenSourceResource.cancel('Request canceled');
    }

    // Create a new cancel token source
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSourceResource(newCancelTokenSource);
    try {
      const filtersCopy = [...fieldFilters];
      let queryData = {};
      const formatedRecords = formatRecordsForm(
        documentsFilter || { documents: [], operation: 'and', selected: { _id: '', label: '' } }
      );

      const types = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.ENTITY_TYPE)?.value as
          | JobDto[]
          | ResourceDto[]
          | undefined
      )?.map(({ _id }) => _id);

      const missions = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.MISSIONS)?.value as
          | JobDto[]
          | ResourceDto[]
          | undefined
      )?.map(({ _id }) => _id);

      const workRules = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.WORK_RULES)?.value as
          | JobDto[]
          | ResourceDto[]
          | undefined
      )?.map(({ _id }) => _id);

      //Add Missions and WorkRules Filter Here.

      const properties = filtersCopy
        .filter(
          ({ _id }) =>
            ![SystemFilters.ENTITY_TYPE, SystemFilters.MISSIONS, SystemFilters.WORK_RULES].includes(
              _id as SystemFilters
            )
        )
        .map(transformFilter)
        .filter((filter) => filter);

      queryData = {
        types,
        jobIds,
        missions,
        workRules,
        propertiesFilter: {
          properties,
          operation: 'and'
        },
        documentsFilter: formatedRecords
      };

      await fullTextSearch(Entities.RESOURCES, queryData, fullText, fieldSorters);
      const filtersData = await axios.post(
        ADVANCED_SEARCH.replace(':entity', Entities.RESOURCES),
        queryData,
        {
          params: { size },
          cancelToken: newCancelTokenSource.token
        }
      );
      setData(filtersData.data);
      setCancelTokenSourceResource(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchByCurriculums = async (
    fieldFilters: FieldFilterType[],
    fieldSorters?: BryntumSorter[]
  ) => {
    setLoading(true);
    try {
      const filtersCopy = [...fieldFilters];
      let queryData = {};
      const properties = filtersCopy
        .map(transformFilter)
        .filter((filter) => filter !== null && filter !== undefined);

      queryData = {
        propertiesFilter: {
          properties,
          operation: 'and'
        }
      };
      if (fieldSorters) queryData['sorting'] = transformSortersToFieldSorters(fieldSorters);
      const filtersData = await axios.post(
        ADVANCED_SEARCH.replace(':entity', Entities.CURRICULUMS),
        queryData,
        {
          params: { size }
        }
      );
      setData(filtersData.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchByResourceRecords = async (
    fieldFilters: FieldFilterType[],
    resourceIds: string[],
    fieldSorters?: BryntumSorter[]
  ) => {
    setLoading(true);
    try {
      const filtersCopy = [...fieldFilters];
      let queryData = {};
      const documentIds = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.RESOURCE_RECORDS_DOCUMENTS)?.value as
          | DocumentDto[]
          | undefined
      )?.map(({ _id }) => _id);

      const properties = filtersCopy
        .filter(({ _id }) => _id !== SystemFilters.RESOURCE_RECORDS_DOCUMENTS)
        .map(transformFilter)
        .filter((filter) => filter !== null && filter !== undefined);

      queryData = {
        documentIds,
        resourceIds,
        propertiesFilter: {
          properties,
          operation: 'and'
        }
      };
      if (fieldSorters) queryData['sorting'] = transformSortersToFieldSorters(fieldSorters);
      const filtersData = await axios.post(
        ADVANCED_SEARCH.replace(':entity', EntityRoutes.RESOURCE_RECORDS),
        queryData,
        {
          params: { size }
        }
      );
      setData(filtersData.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchByQualification = async (
    fieldFilters: FieldFilterType[],
    qualifiedResources?: QualifiedResourcesDto,
    fieldSorters?: BryntumSorter[]
  ) => {
    setLoading(true);
    try {
      const filtersCopy = [...fieldFilters];
      let queryData = {};

      const types = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.ENTITY_TYPE)?.value as
          | JobDto[]
          | ResourceDto[]
          | undefined
      )?.map(({ _id }) => _id);

      const missions = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.MISSIONS)?.value as
          | JobDto[]
          | ResourceDto[]
          | undefined
      )?.map(({ _id }) => _id);

      const workRules = (
        filtersCopy.find(({ _id }) => _id === SystemFilters.WORK_RULES)?.value as
          | JobDto[]
          | ResourceDto[]
          | undefined
      )?.map(({ _id }) => _id);

      //Add Missions and WorkRules Filter Here.

      const properties = filtersCopy
        .filter(
          ({ _id }) =>
            ![SystemFilters.ENTITY_TYPE, SystemFilters.MISSIONS, SystemFilters.WORK_RULES].includes(
              _id as SystemFilters
            )
        )
        .map(transformFilter)
        .filter((filter) => filter);

      queryData = {
        ...(qualifiedResources ? { resourceIds: qualifiedResources.map((r) => r.resourceId) } : {}),
        types,
        missions,
        workRules,
        propertiesFilter: {
          properties,
          operation: 'and'
        }
      };

      if (fieldSorters) queryData['sorting'] = transformSortersToFieldSorters(fieldSorters);
      const filtersData = await axios.post(
        ADVANCED_SEARCH.replace(':entity', Entities.RESOURCES),
        queryData,
        {
          params: { size }
        }
      );
      setData(filtersData.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    fetch,
    fetchByJobs,
    fetchByResources,
    fetchByDocuments,
    fetchByCurriculums,
    fetchByResourceRecords,
    fetchByQualification,
    loading,
    error
  };
};
