export enum GridNames {
  DOCUMENTS = 'grid-documents',
  PROPERTIES = 'grid-properties',
  MISSIONS = 'grid-missions',
  WORK_RULES = 'grid-work-rules',
  RESOURCERECORDS = 'grid-resource-records',
  CURRICULUMS = 'grid-curriculums',
  ENTITY_TYPES = 'grid-entity-types',
  USERS = 'grid-users',
  ROLES = 'grid-roles',
  PRESETS = 'grid-presets'
}
