import { Switch } from '@/components/atoms';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TabOptions = ({ assigned, index, updateDocument }) => {
  const [selected, setSelected] = useState<boolean>(assigned);
  const { t } = useTranslation('organisms/globalFilter');

  const handleChange = (assigned: boolean) => {
    setSelected(assigned);
    updateDocument(index, assigned);
  };
  return (
    <>
      <Switch
        falseLabel="N"
        handleChange={handleChange}
        status={selected}
        trueLabel="H"
        falseTooltip={t('assignOptions.notHaving')}
        trueTooltip={t('assignOptions.having')}
      />
    </>
  );
};
