import { ServerError } from '@/types/data/serverError';
import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { useCallback, useEffect, useState } from 'react';

export const useFetch = <T = unknown, E = AxiosError<ServerError>>(
  config?: AxiosRequestConfig,
  onSuccess?: (data: T) => Promise<void> | void,
  deps?: any[],
  onError?: (error: E) => Promise<void> | void,
  retries: number = 0,
  delay: number = 400
) => {
  // const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<E>();
  const [loading, setLoading] = useState<boolean>(!!config);

  const fetch = useCallback(
    async <P = T>(currentConfig: AxiosRequestConfig<P>) => {
      const controller = new AbortController();

      const attemptFetch = async (retryCount: number): Promise<T | E> => {
        try {
          setLoading(true);
          setError(undefined);
          const result = await axios.request({
            ...currentConfig,
            signal: controller.signal
          });

          if (onSuccess) await onSuccess(result.data as T);
          setData(result.data as T);
          return result.data as T;
        } catch (error: any) {
          if (axios.isCancel(error)) {
            console.log('Canceled Request');
          }

          if (retryCount < retries) {
            await new Promise((resolve) => setTimeout(resolve, delay));
            return attemptFetch(retryCount + 1);
          } else {
            new Error('Error fetching messages:', error);
            if (onError) await onError(error as E);
            setError(error as E);
            return error as E;
          }
        } finally {
          setLoading(false);
        }
      };
      return attemptFetch(0);
    },
    [config, retries, delay, onSuccess, onError]
  );
  //  deps ? [...deps] : [];
  const mutate = useCallback(
    (newData: T | ((beforeData: T) => T)) => {
      if (typeof newData === 'function') setData((newData as (beforeData: T) => T)(data as T));
      else setData(newData);
    },
    [data]
  );

  useEffect(() => {
    const controller = new AbortController();
    if (config) fetch({ ...config, signal: controller.signal });

    return () => {
      controller.abort();
    };
  }, [config, ...(deps || [])]);

  return {
    data,
    loading,
    error,
    fetch,
    mutate
  };
};
