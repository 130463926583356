import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Item = styled(Box)`
  margin: 0 ${({ theme }) => theme.spacing(1)};
`;

export const DesktopDatePickerStart = styled(DesktopDatePicker)`
  // margin-right: -2px;
  & > .MuiOutlinedInput-root {
    border-radius: 4px 0px 0px 4px;
  }

  // & .MuiOutlinedInput-notchedOutline {
  //   border-right-width: 0px !important;
  // }

  // & fieldset {
  //   border-right-width: 0px;
  // }
`;

export const DesktopDatePickerEnd = styled(DesktopDatePicker)`
  // margin-left: -2px;
  & > .MuiOutlinedInput-root {
    border-radius: 0px 4px 4px 0px;
  }

  // & .MuiOutlinedInput-notchedOutline {
  //   border-left-width: 0px !important;
  // }

  & fieldset {
    border-left: 0px;
  }
`;
