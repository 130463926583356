import brand from '@/assets/imgs/xo-sheduler-1.png';
import { auth } from '@/config/firebase.config';
import { AUTH_ACCEPT_INVITE, AUTH_GET_INVITE } from '@/const';
import { useAuthContext } from '@/context/AuthContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { TextField } from '@/theme';
import { InviteDto } from '@/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card } from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LoginSchemaType } from '../LoginTemplate/LoginTemplate.schema';
import { SignupSchemaType, signupSchema } from './SignupTemplate.schema';
import { Brand, BrandingContainer, CardSignup, SignupContainer } from './SignupTemplate.styles';

const SignupTemplate = () => {
  const { t: tSignup } = useTranslation('templates/signupTemplate');

  const { setToastNotifications } = useToastContext();
  const { tenant, inviteId } = useParams();
  const { setUser, setTokenInfo } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const {
    fetch: fetchAcceptInvite,
    data: dataAcceptInvite,
    error: errorAcceptInvite
  } = useFetch<any>();

  useEffect(() => {
    if (errorAcceptInvite) {
      if (errorAcceptInvite?.response?.data?.code == 'invite_not_found') {
        setToastNotifications([{ message: tSignup('errors.inviteNotFound') }]);
      } else if (errorAcceptInvite?.response?.data?.code == 'invite_expired') {
        setToastNotifications([{ message: tSignup('errors.inviteExpired') }]);
      } else {
        setToastNotifications([{ message: tSignup('errors.acceptInvite') }]);
      }
    }
  }, [errorAcceptInvite]);

  const {
    data: dataGetInvite,
    fetch: fetchGetInvite,
    error: errorGetInvite
  } = useFetch<InviteDto>();

  useEffect(() => {
    if (errorGetInvite) {
      if (errorAcceptInvite?.response?.data?.code == 'invite_not_found') {
        setToastNotifications([{ message: tSignup('errors.inviteNotFound') }]);
      } else if (errorAcceptInvite?.response?.data?.code == 'invite_expired') {
        setToastNotifications([{ message: tSignup('errors.inviteExpired') }]);
      } else {
        setToastNotifications([{ message: tSignup('errors.getInvite') }]);
      }
    }
  }, [errorGetInvite]);

  useEffect(() => {
    if (tenant && inviteId)
      fetchGetInvite({
        url: AUTH_GET_INVITE,
        data: {
          tenantId: tenant,
          inviteId: inviteId
        },
        method: 'POST'
      });
  }, []);

  useEffect(() => {
    if (dataGetInvite) {
      setValue('email', dataGetInvite.email);
    }
  }, [dataGetInvite]);

  useEffect(() => {
    if (dataAcceptInvite?.ok) {
      if (auth.currentUser) {
        auth.currentUser.getIdTokenResult(true).then((token) => {
          setTokenInfo(token);
          setUser(auth.currentUser);
        });
      }
    }
  }, [dataAcceptInvite]);

  const onSubmit = useCallback(async (form: LoginSchemaType) => {
    setLoading(true);
    //save token
    const { email, password } = form;

    createUserWithEmailAndPassword(auth, email as string, password as string)
      .then(async (user) => {
        await fetchAcceptInvite({
          url: AUTH_ACCEPT_INVITE,
          data: {
            tenantId: tenant,
            inviteId: inviteId
          },
          method: 'POST'
        });
      })
      .catch((error) => {
        setToastNotifications([{ message: 'An error has been ocurred' }]);
        setLoading(false);
      });
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid }
  } = useForm<SignupSchemaType>({
    resolver: yupResolver(signupSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: ''
    }
  });

  return (
    <SignupContainer>
      <Card>
        <CardSignup onSubmit={handleSubmit(onSubmit)}>
          <BrandingContainer>
            <Brand src={brand} />
          </BrandingContainer>

          <TextField
            fullWidth
            size="small"
            {...register('email')}
            value={watch('email') ?? null}
            variant="outlined"
            label={tSignup('email')}
            error={!!errors.email}
            disabled={true}
            inputProps={{
              autoComplete: 'username'
            }}
          />
          <TextField
            fullWidth
            size="small"
            {...register('password')}
            variant="outlined"
            label={tSignup('password')}
            error={!!errors.password}
            type="password"
            inputProps={{
              autoComplete: 'new-password'
            }}
          />
          <TextField
            fullWidth
            size="small"
            {...register('confirmPassword')}
            variant="outlined"
            label={tSignup('confirmPassword')}
            error={!!errors.confirmPassword}
            type="password"
            inputProps={{
              autoComplete: 'new-password'
            }}
          />

          <LoadingButton
            fullWidth
            loading={loading}
            disabled={!watch('password') || !watch('confirmPassword')}
            type="submit"
            variant="contained">
            {tSignup('signup')}
          </LoadingButton>
        </CardSignup>
      </Card>
    </SignupContainer>
  );
};

export default SignupTemplate;
