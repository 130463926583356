import { ListRow, TextField } from '@/theme';
import { PossibleValues, isMultipleInputValue } from '@/types';
import {
  Autocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps,
  AutocompleteValue,
  Chip,
  InputBaseProps,
  Typography
} from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type FixedAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = {
  label?: string;
  options: T[];
  textIfFailFetch?: ReactNode;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  selectProperty?: string;
  getLabel?: (option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) => string;
  onChange: (value: unknown) => void;
} & Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'multiple' | 'options' | 'onChange'
> &
  Pick<InputBaseProps, 'name' | 'autoComplete'> &
  PossibleValues<T>;

const FixedAutocomplete = <
  T extends Record<string, string> & { name: string },
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  label,
  id,
  name,
  autoComplete,
  includeMultipleObj = false,
  value,
  options,
  onChange,
  getLabel = (option) => ((option as T)[selectProperty] ? (option as T)[selectProperty] : ''),
  disabled,
  helperText,
  selectProperty = 'label',
  readOnly = false,
  ...others
}: FixedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const handleChange = (
    _: React.SyntheticEvent,
    newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
  ) => {
    onChange(newValue);
  };

  return (
    <>
      <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
        readOnly={readOnly}
        fullWidth
        isOptionEqualToValue={(option, value) => option?._id === value?._id}
        value={
          value && !isMultipleInputValue(value)
            ? (options?.find((option) => option._id === value) as AutocompleteValue<
                T,
                Multiple,
                DisableClearable,
                FreeSolo
              >)
              ? (options?.find((option) => option._id === value) as AutocompleteValue<
                  T,
                  Multiple,
                  DisableClearable,
                  FreeSolo
                >)
              : value
            : ([] as unknown as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>)
        }
        onChange={handleChange}
        options={options || []}
        multiple={true as unknown as undefined}
        disabled={disabled}
        getOptionLabel={getLabel}
        noOptionsText={<Typography sx={{ lineHeight: '1rem' }}>{`There's no options`}</Typography>}
        renderOption={(props, option) => (
          <ListRow {...props} key={option._id}>
            {getLabel(option)}
          </ListRow>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option._id}
              label={getLabel(option)}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={label}
            placeholder={
              includeMultipleObj && !isMultipleInputValue(value) ? t('text.multipleValues') : ''
            }
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              ...params.inputProps,
              id,
              name,
              autoComplete
            }}
            helperText={helperText}
          />
        )}
        {...others}
      />
    </>
  );
};

export default FixedAutocomplete;
