import { theme } from '@/theme';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BiSolidError } from 'react-icons/bi';
import { ResourceContainer } from '../ResourceSlot/ResourceSlot.style';

const EmptyResource = () => {
  const { t } = useTranslation('templates/jobEventPanelTemplate');

  return (
    <ResourceContainer>
      <Tooltip title={''} placement="top">
        <Box display="flex" alignItems="center">
          <BiSolidError
            style={{ alignSelf: 'center' }}
            size={19.2}
            color={theme.palette.warning.main}
          />
        </Box>
      </Tooltip>
      <Typography
        fontStyle={'italic'}
        sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {t('availableResourceSlot')}&#8201;
      </Typography>
    </ResourceContainer>
  );
};

export default EmptyResource;
