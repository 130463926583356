import { RenderIf } from '@/components/atoms';
import { theme } from '@/theme';
import { IconButton, Paper, Typography } from '@mui/material';
import { CommonProps } from '@mui/material/OverridableComponent';
import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { MdFileDownload } from 'react-icons/md';
import { DropContainer } from './DropFile.styles';

type singleFile = {
  onFileDrop: (data?: File) => void | unknown;
  multiple?: never;
};

type multiFile = {
  onFileDrop: (data?: File[]) => void | unknown;
  multiple: true;
};

type DropEventType = {
  dataTransfer: DataTransfer;
  files: File[];
  items: DataTransferItemList;
};

export type DropFileProps = {
  accept?: string[];
  label?: string | React.ReactNode;
} & CommonProps &
  (singleFile | multiFile);
const DropFile = ({ onFileDrop, multiple, accept, style, label }: DropFileProps) => {
  const { t } = useTranslation('organisms/dropFile');

  const [selectedItems, setSelectedItems] = useState<File[]>();

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = !!multiple;
    if (accept) fileInput.accept = accept.join(',');
    fileInput.addEventListener('change', (e: any) => {
      const files: File[] = [];
      for (const file of e.target?.files as FileList) {
        files.push(file);
      }
      if (files.length > 0) {
        handleDrop(files);
      }
    });
    fileInput.click();
  };

  const handleDrop = useCallback(
    (files: File[]) => {
      if (files) {
        if (multiple) {
          setSelectedItems(files);
        } else {
          setSelectedItems([files[0]]);
        }
      }
    },
    [multiple]
  );

  const removeItem = useCallback(
    (idx: number) => {
      if (selectedItems && selectedItems.length > 1) {
        setSelectedItems((last) => {
          if (last) {
            const copy = [...last];
            copy.splice(idx, 1);
            return copy;
          }
          return last;
        });
      } else {
        setSelectedItems(undefined);
        onFileDrop(undefined);
      }
    },
    [selectedItems]
  );

  useEffect(() => {
    if (selectedItems) {
      if (multiple) {
        onFileDrop(selectedItems);
      } else {
        onFileDrop(selectedItems[0]);
      }
    }
  }, [selectedItems, multiple]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: (props: DropEventType) => {
      if (accept && accept.length > 0) {
        const acceptedFiles = (props.files as unknown as File[]).filter((file) => {
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
          return fileExtension && accept.includes('.' + fileExtension);
        });
        if (acceptedFiles.length > 0) {
          handleDrop(acceptedFiles);
        }
      } else {
        if (props.files.length > 0) {
          handleDrop(props.files);
        }
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver()
    })
  }));

  return (
    <DropContainer
      ref={drop}
      onClick={handleClick}
      multiple={!!multiple}
      isOver={isOver}
      sx={style}>
      <RenderIf condition={!!selectedItems}>
        {selectedItems?.map((file, idx) => (
          <Paper
            key={file.name}
            sx={{
              width: 'fit-content',
              maxWidth: '95%',
              height: 'fit-content',
              padding: '1rem',
              position: 'relative'
            }}>
            <IconButton
              sx={{ position: 'absolute', right: -13, top: -5 }}
              onClick={(e) => {
                removeItem(idx);
                e.stopPropagation();
              }}>
              <FaTrash fontSize="small" />
            </IconButton>
            <Typography
              variant="body2"
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {file.name}
            </Typography>
          </Paper>
        ))}
      </RenderIf>
      <RenderIf condition={!selectedItems}>
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            placeContent: 'center'
          }}>
          <MdFileDownload size={30} color={theme.palette.secondary.main} />
          <Typography variant="h5">
            {multiple ? t('text.dropFiles_other') : t('text.dropFiles_one')}
          </Typography>
          <Typography variant="h6">{t('text.or')}</Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {t('text.clickToUpload')}
          </Typography>
        </div>
      </RenderIf>
      {label && (
        <div
          style={{
            width: 'calc(100% - 1rem)',
            position: 'absolute',
            left: '0.5rem',
            bottom: '0.5rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
          <Typography variant="caption">{label}</Typography>
        </div>
      )}
    </DropContainer>
  );
};
export default DropFile;
