import { RenderIf, StatusIcon } from '@/components/atoms';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import PropertiesForm from '@/components/organisms/PropertiesForm/PropertiesForm';
import {
  COLLECTIONS_FIND_ALL,
  COLLECTIONS_FIND_ONE,
  CrudModes,
  EVENTS_GET_FOR_EDITION,
  Entities,
  PropertyTypes,
  SystemProperties
} from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import { removeEmptyFields } from '@/services/utils/utils';
import { ButtonsContainer, theme } from '@/theme';
import { EventDto, JobEventDto, Properties, ResourceSlot, StructureStatus } from '@/types';
import { orderByProperties } from '@/utils';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Tab,
  Tooltip,
  Typography
} from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';
import { Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiSolidError } from 'react-icons/bi';
import { FaPaperclip, FaTags } from 'react-icons/fa';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { TbRefreshAlert } from 'react-icons/tb';
import { getDefaultPropertiesAsFormValues } from '../ResourcePanelTemplate/ResourcePanelTemplate.const';
import {
  checkQualifiedAndQuantity,
  mapToDto,
  transformResourceSlots
} from './JobEventPanelTemplate.const';
import Resources from './components/Resources/Resources';

export type JobEventForm = {
  resourceSlots: Array<
    ResourceSlot & {
      resources: Array<
        { _id: string; label: string; qualified: boolean; picture?: string } | Record<string, never>
      >;
    }
  >;
} & Properties;

export type JobEventPanelTemplateProps = {
  eventId: string;
  resourceId?: string;
  startDate?: Date;
  onCancel: (isDirty?: boolean) => unknown;
  onDirtyFields: (isDirty: boolean) => unknown;
  onLoading?: (loading: boolean) => unknown;
} & (
  | {
      mode: CrudModes.CREATE;
      onSuccess: (events: EventDto) => unknown;
    }
  | {
      mode: CrudModes.EDIT;
      onSuccess: (events: EventDto, oldEvent: JobEventDto) => unknown;
    }
);

const JobEventPanelTemplate = ({
  mode,
  eventId,
  resourceId,
  startDate,
  onSuccess,
  onDirtyFields,
  onCancel,
  onLoading
}: JobEventPanelTemplateProps) => {
  const { t: tCommon } = useTranslation();
  const { t: jobEventPanelTemplate } = useTranslation('templates/jobEventPanelTemplate');
  const { setToastNotifications } = useToastContext();
  const {
    properties: rawProperties,
    entityData,
    loading,
    error
  } = useGetPropertiesByEntity<EventDto>(Entities.EVENTS, eventId);

  const {
    data: jobEvent,
    fetch: fetchJobEvent,
    loading: loadingJobEvent,
    error: errorJobEvent
  } = useFetch<JobEventDto>();

  const properties = useMemo(() => {
    if ((rawProperties && mode === CrudModes.CREATE) || (rawProperties && jobEvent)) {
      if (jobEvent?.editData?.job.status === StructureStatus.CANCELED) {
        return rawProperties.map((prop) => ({ ...prop, disabled: true }));
      }
      return rawProperties.map((property) => {
        if (property._id === SystemProperties.EVENTS_LOCATION) {
          return { ...property, disabled: true };
        }
        if (property._id === SystemProperties.EVENTS_COUNT_FOR_WORKLOAD) {
          return { ...property, disabled: true };
        }
        return property;
      });
    }
    return undefined;
  }, [rawProperties, jobEvent, mode]);

  useEffect(() => {
    if (eventId) {
      fetchJobEvent({
        url: EVENTS_GET_FOR_EDITION.replace(':id', eventId)
      });
    }
  }, []);

  useEffect(() => {
    if (error || errorJobEvent) {
      setToastNotifications([
        { message: tCommon('errors.loadingEntity', { entity: tCommon('entities.events_one') }) }
      ]);
      onCancel();
    }
  }, [error, errorJobEvent]);

  const getDefaultProperties = useCallback(
    (startDate?: Date) => {
      if (startDate) {
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(startDate.getTime());
        endDate.setDate(endDate.getDate() + 1);

        return {
          [SystemProperties.EVENTS_START_TIMESTAMP]: DateTime.fromJSDate(startDate),
          [SystemProperties.EVENTS_END_TIMESTAMP]: DateTime.fromJSDate(endDate)
        };
      }
      return {};
    },
    [jobEvent]
  );

  const defaultValues: Partial<JobEventForm> = useMemo(() => {
    return {
      properties: getDefaultProperties(startDate)
    };
  }, [getDefaultProperties, jobEvent]);

  const methods = useForm<JobEventForm>({
    mode: mode === CrudModes.CREATE ? 'onSubmit' : 'onChange',
    defaultValues: defaultValues
  });
  const { control, handleSubmit, reset, formState, setValue, watch, trigger } = methods;

  useEffect(() => {
    onDirtyFields(formState.isDirty);
  }, [formState.isDirty]);

  const { fetch, loading: loadingFetch, error: errorFetch, data } = useFetch<EventDto>();

  useEffect(() => {
    if (onLoading) onLoading(loadingFetch);
  }, [loadingFetch]);

  useEffect(() => {
    const initializeForm = async () => {
      if (properties && jobEvent && mode === CrudModes.EDIT) {
        const newValues = getDefaultPropertiesAsFormValues(properties, jobEvent);
        const newResourceSlots = transformResourceSlots(
          jobEvent.editData?.resourceSlots || [],
          jobEvent.resources
        );
        reset({
          properties: newValues,
          resourceSlots: orderByProperties(newResourceSlots, ['name', 'id'])
        });
      }
    };

    initializeForm();
  }, [jobEvent, properties]);

  const submit = (form: JobEventForm) => {
    removeEmptyFields(form.properties);
    const formattedProperties = properties
      ?.map((row) => {
        return {
          _id: row._id,
          value:
            (row.type === PropertyTypes.SET || row.type === PropertyTypes.OBJECTID) &&
            typeof form.properties[row._id] !== 'string'
              ? form.properties[row._id]?._id
              : form.properties[row._id]
        };
      })
      .filter((prop) => prop.value !== undefined && prop.value !== null);

    let query: AxiosRequestConfig | undefined = undefined;
    switch (mode) {
      case CrudModes.CREATE:
        query = {
          url: COLLECTIONS_FIND_ALL.replace(':collection_name', 'events'),
          method: 'POST',
          data: {
            properties: formattedProperties,
            resources: { resource: resourceId, roleName: 'Owner' }
          }
        };
        break;
      case CrudModes.EDIT:
        if (eventId) {
          query = {
            url: COLLECTIONS_FIND_ONE.replace(':collection_name', 'events').replace(':id', eventId),
            method: 'PATCH',
            data: {
              properties: formattedProperties,
              job: jobEvent?.job,
              sessionId: jobEvent?.sessionId,
              topicId: jobEvent?.topicId,
              resources: mapToDto(form.resourceSlots)
            }
          };
        }
        break;
    }

    if (query) fetch(query);
  };

  useEffect(() => {
    if (errorFetch) {
      if (error?.response?.data?.code == 'event_not_found') {
        setToastNotifications([
          { message: tCommon('errors.entityNotFound', { entity: tCommon('entities.events_one') }) }
        ]);
      } else {
        setToastNotifications([
          {
            message: tCommon('errors.actionError', {
              action:
                mode === CrudModes.CREATE
                  ? tCommon('actions.creation')
                  : tCommon('actions.edition'),
              entity: tCommon('entities.events_one')
            })
          }
        ]);
      }
    } else if (data) {
      onDirtyFields(false);
      if (mode === CrudModes.EDIT && jobEvent) onSuccess(data, jobEvent);
      if (mode === CrudModes.CREATE) onSuccess(data);
    }
  }, [errorFetch, data]);

  const extraValidations = useMemo(() => {
    return {
      [SystemProperties.EVENTS_START_TIMESTAMP]: {
        triggerEndDate: (value) => {
          trigger(`properties.${SystemProperties.EVENTS_END_TIMESTAMP}`); //I create this trigger Method to call the Rules of the END_TIMESTAMP.
          return true;
        }
      },
      [SystemProperties.EVENTS_END_TIMESTAMP]: {
        moreThan: (value: string | Date, form: JobEventForm) => {
          const startTimeStamp = form.properties[SystemProperties.EVENTS_START_TIMESTAMP];
          const validation = new Date(value).getTime() > new Date(startTimeStamp).getTime();
          return validation
            ? validation
            : tCommon('formRules.afterThat', {
                prop1: properties?.find(
                  (value) => value._id === SystemProperties.EVENTS_END_TIMESTAMP
                )?.name,
                prop2: properties?.find(
                  (value) => value._id === SystemProperties.EVENTS_START_TIMESTAMP
                )?.name
              });
        }
      }
    };
  }, [properties]);

  const [tabValue, setTabValue] = useState('1');
  const [needsRevalidation, setNeedsRevalidation] = useState(false);
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    if (newValue === '2' && needsRevalidation) setNeedsRevalidation(false);
    setTabValue(newValue);
  };

  const eventStart = useWatch({
    control,
    name: `properties.${SystemProperties.EVENTS_START_TIMESTAMP}`,
    exact: true
  });
  const eventEnd = useWatch({
    control,
    name: `properties.${SystemProperties.EVENTS_END_TIMESTAMP}`,
    exact: true
  });
  const resourceSlots = useWatch({
    control,
    name: 'resourceSlots'
  });

  useEffect(() => {
    formState.isDirty && setNeedsRevalidation(true);
  }, [eventStart, eventEnd]);

  const isComplete = useMemo(
    () => checkQualifiedAndQuantity(methods.getValues('resourceSlots') || []),
    [tabValue, resourceSlots]
  );

  return (
    <Fragment>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {loading ? (
            <Skeleton width={'50%'} sx={{ bgcolor: 'grey.700' }} />
          ) : (
            `${
              mode === CrudModes.CREATE ? tCommon('actions.create') : tCommon('actions.edit')
            } ${tCommon('entities.events_one')}`
          )}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '0rem 2rem', overflow: 'hidden' }}>
        <RenderIf condition={loading || loadingJobEvent}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress />
          </Box>
        </RenderIf>
        <RenderIf condition={!loading && !loadingJobEvent && !formState.isLoading}>
          <FormProvider {...methods}>
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              flexWrap="wrap"
              columnGap="1rem">
              <div>
                <Typography
                  sx={{ fontSize: '1.3rem', fontWeight: 600, marginTop: '1rem' }}
                  variant="h6">
                  {jobEvent?.editData?.job.label}
                </Typography>
                {jobEvent?.editData?.job.status && (
                  <Grid display="flex" alignItems="center" gap="0.3rem">
                    <StatusIcon status={jobEvent.editData.job.status} size={1} />
                    <Typography>
                      {tCommon(`jobStatus.${jobEvent.editData.job.status}`) as string}
                    </Typography>
                  </Grid>
                )}
              </div>
              <Typography variant="body1">
                {jobEvent?.editData?.curriculum.label} &gt; {jobEvent?.editData?.session.label} &gt;{' '}
                {jobEvent?.editData?.topic.label}
              </Typography>
            </Box>
            <Divider />
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  variant="fullWidth"
                  sx={{ height: '4em !important' }}>
                  <Tab
                    label={tCommon('entities.properties_other')}
                    iconPosition="start"
                    icon={<FaTags size={16}></FaTags>}
                    value="1"
                  />
                  <Tab
                    label={
                      <Box display="flex" alignItems="center" gap="0.5rem">
                        <Typography fontSize={'0.8125rem'}>
                          {tCommon('entities.resources_other')}
                        </Typography>
                        <RenderIf condition={!needsRevalidation}>
                          {isComplete ? (
                            <IoIosCheckmarkCircle
                              style={{ alignSelf: 'center' }}
                              size={19.2}
                              color={theme.palette.success.main}
                            />
                          ) : (
                            <BiSolidError
                              style={{ alignSelf: 'center' }}
                              size={19.2}
                              color={theme.palette.warning.main}
                            />
                          )}
                        </RenderIf>
                        {needsRevalidation && (
                          <Tooltip placement="top" title={jobEventPanelTemplate('statusAlert')}>
                            <Box>
                              <TbRefreshAlert
                                size={19.2}
                                style={{
                                  minWidth: '1.2rem',
                                  minHeight: '1.2rem',
                                  alignSelf: 'center'
                                }}
                              />
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                    }
                    iconPosition="start"
                    disabled={mode === CrudModes.CREATE && !resourceId}
                    icon={<FaPaperclip size={16} />}
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ padding: '24px 0 24px 0', overflow: 'auto' }}>
                <PropertiesForm
                  properties={properties ?? []}
                  control={control}
                  formState={formState}
                  extraValidations={extraValidations}
                />
              </TabPanel>
              <TabPanel
                value="2"
                sx={{
                  height: tabValue == '2' ? '100%' : '0',
                  display: 'flex',
                  padding: '24px 0 24px 0',
                  overflow: 'auto'
                }}>
                <Resources
                  isDisabled={jobEvent?.editData?.job.status == StructureStatus.CANCELED}
                />
              </TabPanel>
            </TabContext>
          </FormProvider>
        </RenderIf>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button disabled={loading || loadingFetch} onClick={(e) => onCancel(formState.isDirty)}>
            {tCommon('buttons.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loadingFetch}
            disabled={
              loading ||
              (mode === CrudModes.EDIT && Object.keys(formState.dirtyFields).length === 0) ||
              Object.keys(formState.errors).length > 0
            }
            onClick={handleSubmit(submit)}>
            {tCommon('buttons.save')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </Fragment>
  );
};

export default JobEventPanelTemplate;
