import { InferType, object, ref, string } from 'yup';

export const signupSchema = object({
  email: string().required().email(),
  password: string().required().min(6),
  confirmPassword: string()
    .required('Confirm Password is required')
    .oneOf([ref('password')], 'Passwords must match')
});

export type SignupSchemaType = InferType<typeof signupSchema>;
