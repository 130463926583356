import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Checkbox = styled(MuiCheckbox)`
  padding: ${({ theme }) => theme.spacing(0.3)};
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0px;
`;
