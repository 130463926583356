import { EventDto, GanttNames, JobEventDto, SchedulerNames, State } from '@/types';
import { CalendarNames } from '@/types/ui/calendar';
import { BryntumCalendar } from '@bryntum/calendar-react-thin';
import { Calendar } from '@bryntum/calendar-thin';
import { BryntumGantt } from '@bryntum/gantt-react-thin';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { Store } from '@bryntum/core-thin';
import { BryntumGrid } from '@bryntum/grid-react-thin';
import { Grid } from '@bryntum/grid-thin';
import { BryntumScheduler } from '@bryntum/scheduler-react-thin';
import { Scheduler } from '@bryntum/scheduler-thin';
import { TFunction } from 'i18next';
import { isObject } from 'lodash';
import { MutableRefObject, Ref, RefObject } from 'react';
import { Gantt } from '@bryntum/gantt-thin';
import { SchedulerPro } from '@bryntum/schedulerpro-thin';

export const sortAlpha = (objArray: any[], property: string) => {
  const newObj = [...objArray];
  return newObj.sort((a: any, b: any) =>
    a[property].toLowerCase().localeCompare(b[property].toLowerCase())
  );
};

export const sortAlphaArray = <T>(objArray: T[]): T[] => {
  const newObjArray = [...objArray];
  return newObjArray.sort((a: any, b: any) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const getLanguageCalendar = (): any => {
  if (navigator.language == 'en') {
    return 'en';
  } else {
    return 'en-gb';
  }
};

const syncSplitter = (state: Partial<State>) => {
  const resourcesStorage: State = JSON.parse(
    localStorage.getItem(SchedulerNames.RESOURCES) || '{}'
  );
  const jobsStorage: State = JSON.parse(localStorage.getItem(SchedulerNames.JOBS) || '{}');
  if (state.subGrids && resourcesStorage.subGrids && jobsStorage.subGrids) {
    resourcesStorage.subGrids.locked.width = state.subGrids.locked.width;
    jobsStorage.subGrids.locked.width = state.subGrids.locked.width;
  }
  if (!jobsStorage || !resourcesStorage) return;
  localStorage.setItem(SchedulerNames.RESOURCES, JSON.stringify(resourcesStorage));
  localStorage.setItem(SchedulerNames.JOBS, JSON.stringify(jobsStorage));
};

export const saveStateConfigBryntum = (
  scheduler: Scheduler | Calendar | Gantt | SchedulerPro,
  name: SchedulerNames | CalendarNames | GanttNames
) => {
  if (scheduler?.state) {
    localStorage.setItem(name, JSON.stringify(scheduler?.state));
    syncSplitter(scheduler.state as Partial<State>);
  }
};

export const equalResources = (event: EventDto, jobEvent: JobEventDto) => {
  return (
    event.resources.length === jobEvent.resources.length &&
    event.resources.every((rs) =>
      jobEvent.resources.map((rs) => rs.resource._id).includes(rs.resource)
    )
  );
};

export const loadStateConfigBryntum = (
  ref: Ref<BryntumScheduler | BryntumSchedulerPro | BryntumCalendar | BryntumGantt>,
  id: SchedulerNames | CalendarNames | GanttNames
) => {
  setTimeout(() => {
    const gridRef = (ref as RefObject<BryntumScheduler | BryntumGantt | BryntumSchedulerPro>)
      ?.current?.instance;
    const state = JSON.parse(localStorage.getItem(id) || '{}');
    if (state && gridRef) {
      gridRef.state = state;
    }
  }, 0);
};

export const setLoading = (
  ref: Ref<BryntumScheduler | BryntumSchedulerPro | BryntumGrid | BryntumGantt>
) => {
  setTimeout(() => {
    if (!ref) return;
    //if reference is bryntum scheduler
    if (
      (
        (
          ref as MutableRefObject<
            BryntumScheduler | BryntumSchedulerPro | BryntumGantt | BryntumGrid
          >
        ).current.instance.subGrids as any
      ).locked
    ) {
      const store = (
        (
          ref as MutableRefObject<
            BryntumScheduler | BryntumSchedulerPro | BryntumGantt | BryntumGrid
          >
        ).current.instance.subGrids as any
      ).locked.store as Store;
      store.removeAll();
      store.insert(0, [
        ...new Array(5)
          .fill('')
          .map((_, index) => ({ id: index, iconCls: 'remove', skeleton: true }))
      ]);
      return;
    }
    //if reference is bryntum grid
    // if ((ref as RefObject<BryntumGrid>)?.current?.instance) {
    //   const store = ((ref as MutableRefObject<BryntumGrid>).current?.instance as Grid)
    //     .store as Store;
    //   store.removeAll();
    //   store.insert(0, [...new Array(5).fill('').map((_, index) => ({ id: index }))]);
    //   return;
    // }
  }, 0);
};

export const setLoadingTimeAxis = (
  ref: Ref<BryntumScheduler | BryntumGantt | BryntumSchedulerPro>,
  state: boolean
) => {
  const element = (
    ref as RefObject<BryntumScheduler | BryntumGantt | BryntumSchedulerPro>
  )?.current?.instance?.timeAxisSubGridElement.getElementsByClassName('b-sch-foreground-canvas')[0];
  if (state) element?.classList.add('loading-timeaxis');
  else element?.classList.remove('loading-timeaxis');
};

export const isLoadingTimeAxis = (
  ref: Ref<BryntumScheduler | BryntumSchedulerPro | BryntumGantt>
): boolean => {
  const element = (
    ref as RefObject<BryntumScheduler | BryntumGantt>
  )?.current?.instance?.timeAxisSubGridElement.getElementsByClassName('b-sch-foreground-canvas')[0];

  if (element?.classList) {
    const hasArray = Array.from(element?.classList).some((className) =>
      className.includes('loading-timeaxis')
    );

    return hasArray ? true : false;
  }
  return false;
};

export const findMatchingObjectsByProp = <T>(
  sourceArray: string[],
  targetArray: T[],
  prop: string
): T[] => {
  const matchingObjects: T[] = [];

  for (const sourceId of sourceArray) {
    const matchingObject = targetArray.find((targetObject) => targetObject[prop] === sourceId);
    if (matchingObject) {
      matchingObjects.push(matchingObject);
    }
  }

  return matchingObjects;
};

export const secondsToTime = (value: number) => {
  const days = ~~(value / 86400);
  const hours = ~~((value % 86400) / 3600);
  const minutes = ~~((value % 3600) / 60);
  const seconds = ~~(value % 60);
  if (days > 0) {
    return `${days}:${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }

  if (hours > 0) {
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }

  if (minutes > 0) {
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  return `${truncate(value, 2)} s`;
};

export const secondsToText = (value: number, t: TFunction) => {
  const [integer, decimal] = value.toString().split('.');
  const integerInt = +integer;
  const days = ~~(integerInt / 86400);
  const hours = ~~((integerInt % 86400) / 3600);
  const minutes = ~~((integerInt % 3600) / 60);
  const seconds = integerInt % 60;
  const hoursPart = `${hours} ${t(hours == 1 ? 'hour' : 'hours')} `;
  const minutesPart = `${minutes} ${t(minutes == 1 ? 'minute' : 'minutes')} `;
  const secondsPart = `${seconds}.${decimal ?? 0} ${t('seconds')}`;
  if (days > 0) {
    return `${days} ${t(days == 1 ? 'day' : 'days')} ${hoursPart}${minutesPart}${secondsPart}`;
  }
  if (hours > 0) {
    return `${hoursPart}${minutesPart}${secondsPart}`;
  }
  if (minutes > 0) {
    return `${minutesPart}${secondsPart}`;
  }
  return `${secondsPart}`;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const truncate = (number: number, decimals: number): number => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
  if (number && number.toString() && number.toString().match(re)) {
    return parseFloat((number.toString().match(re) as RegExpMatchArray)[0]);
  }
  return 0;
};

export const objectMap = (object, func) => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value], index) => [key, func(value, key, index)])
  );
};

export const getKeyFromValueEnum = <T extends { [index: string]: U }, U extends string | number>(
  enumeration: T,
  value: U
): keyof T | undefined =>
  Object.keys(enumeration).find((key) => enumeration[key] === value) as keyof T | undefined;

export const checkIfAllValuesAreRepeated = (arr1: any[], arr2: any[]): boolean => {
  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      return false;
    }
  }
  return true;
};
export const removeEmptyFields = (data, isDeep?: boolean) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] === null || data[key] === undefined) {
      delete data[key];
    }
    if (isDeep && isObject(data[key])) {
      removeEmptyFields(data[key], isDeep);
    }
  });
};

export const objectHasValues = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && (obj[key] || obj[key] === false)) {
      return true;
    }
  }
  return false;
};
