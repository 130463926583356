import { RenderIf } from '@/components/atoms';
import { AccordionSummary } from '@/components/molecules/Card/Card.styles';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import { DATE_FORMAT_PICKER, Entities, PropertyTypes, SystemFilters } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useGetPropertiesByEntity } from '@/hooks/useGetPropertiesByEntity';
import { ButtonsContainer, Container, ListRow, TextField, theme } from '@/theme';
import { ImportConfigDto, PropertyDto } from '@/types';
import { includeValues } from '@/utils';
import { GridColumnConfig } from '@bryntum/core-thin';
import { BryntumGrid, BryntumGridProps } from '@bryntum/grid-react-thin';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { useDebouncyFn } from 'use-debouncy';

type ImportPreviewProps = {
  firstHeader: boolean;
  selectedFile: File;
  selectedSheet: string;
  gridProperties: BryntumGridProps;
  onCancel: () => unknown;
  onSubmit: (data: ImportConfigDto) => unknown;
};

const ImportPreview = ({
  firstHeader,
  selectedFile,
  selectedSheet,
  gridProperties,
  onCancel,
  onSubmit
}: ImportPreviewProps) => {
  const { t } = useTranslation('templates/resourceImportPanelTemplate');
  const { t: tCommon } = useTranslation();
  const { setToastNotifications } = useToastContext();
  const {
    properties,
    loading: loadingProperties,
    error: errorProperties
  } = useGetPropertiesByEntity(Entities.RESOURCES);

  useEffect(() => {
    if (errorProperties) {
      setToastNotifications([
        {
          message: tCommon('errors.loadingEntity', {
            entity: t('filePreview.text.resourceProperties')
          })
        }
      ]);
      onCancel();
    }
  }, [errorProperties]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    trigger,
    formState,
    formState: { errors, isDirty }
  } = useForm<ImportConfigDto>({ defaultValues: undefined });

  const idTypes: Record<string, PropertyDto> = useMemo(() => {
    if (properties) {
      return properties.reduce((prev, curr) => ({ ...prev, [curr._id]: { ...curr } }), {});
    }
    return {};
  }, [properties]);

  const { fields } = useFieldArray({ control, name: 'mappings' });

  const includeVals = useCallback(
    (propId: string) => {
      return [PropertyTypes.DATE, PropertyTypes.DATETIME].includes(idTypes[propId]?.type);
    },
    [idTypes]
  );

  const alreadySelected = (
    value: string | undefined,
    formValues: ImportConfigDto,
    sourceIdx: number
  ) => {
    if (value) {
      const properties = formValues.mappings
        .map(({ property }) => property)
        .filter((property, idx) => !!property && sourceIdx !== idx);
      return properties.includes(value) ? 'Repeated property' : true;
    } else {
      return true;
    }
  };

  const [requiredError, setRequiredError] = useState<string[]>([]);

  const [renderProperties, setRenderProperties] = useState<
    {
      _id: string;
      name: string;
      type: string;
      required?: boolean;
    }[]
  >([]);

  const formatOptions = ['dd/MM/yyyy', 'MM/dd/yyyy', 'yyyy-MM-dd', 'yyyy-dd-MM'];

  useEffect(() => {
    if (gridProperties && gridProperties.columns && properties) {
      const allProperties = [
        {
          _id: SystemFilters.ENTITY_TYPE,
          name: tCommon('entities.types'),
          required: true,
          type: SystemFilters.ENTITY_TYPE
        },
        ...(properties || []),
        {
          _id: Entities.MISSIONS,
          name: tCommon('entities.missions_other'),
          type: Entities.MISSIONS
        },
        {
          _id: SystemFilters.WORK_RULES,
          name: tCommon('entities.work-rules_other'),
          type: SystemFilters.WORK_RULES
        }
      ];
      setRenderProperties(allProperties);
      reset({
        rowHeader: firstHeader,
        sheetName: selectedSheet,
        mappings: (gridProperties.columns as Partial<GridColumnConfig>[]).map((column, idx) => {
          const relatedProperty = allProperties?.find(
            (property) => property.name.toLocaleLowerCase() === column.text?.toLocaleLowerCase()
          )?._id;
          return {
            column: idx,
            property: relatedProperty || undefined,
            valueReference: undefined,
            valueFormat:
              relatedProperty && includeVals(relatedProperty) ? DATE_FORMAT_PICKER : undefined
          };
        })
      });
    }
  }, [gridProperties, properties, idTypes]);

  const submit = useCallback(
    (form: ImportConfigDto) => {
      form.mappings = form.mappings.filter((val) => val.property && val.property.length > 0);

      const requiredProperties: string[] =
        renderProperties?.filter((prop) => prop.required)?.map((prop) => prop._id) || [];

      if (
        !includeValues(
          requiredProperties,
          form.mappings.map((map) => map.property)
        )
      ) {
        setRequiredError(
          renderProperties
            .filter((renderProperty) =>
              requiredProperties
                .filter(
                  (id) => !form.mappings.map((map) => map.property).some((item) => item === id)
                )
                .includes(renderProperty._id)
            )
            .map((property) => property.name)
        );
        return;
      }
      if (form) onSubmit(form);
    },
    [selectedFile, renderProperties, requiredError]
  );

  return (
    <Container>
      <HeaderModalContainer>
        <Typography
          color={theme.palette.common.white}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          gap={'0.2em'}
          variant="h5">
          {`${tCommon('actions.import')} ${tCommon('entities.resources_other')}`}
        </Typography>
      </HeaderModalContainer>
      <BodyModalContainer sx={{ padding: '2rem 2rem 0rem 2rem', flex: 1, display: 'flex' }}>
        <RenderIf condition={loadingProperties}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress />
          </Box>
        </RenderIf>
        <RenderIf condition={!loadingProperties}>
          <AccordionSummary
            sx={{
              flexShrink: '0',
              cursor: 'auto !important'
            }}>
            <Grid
              my="auto"
              display={'flex'}
              sx={{ flex: '1', marginLeft: '0.5rem' }}
              gap={'8px'}
              alignItems="baseline">
              <Typography>{t('filePreview.text.preview')}</Typography>
            </Grid>
          </AccordionSummary>
          <div style={{ width: '100%', height: '10rem' }}>
            <RenderIf condition={!!gridProperties}>
              <BryntumGrid {...gridProperties} />
            </RenderIf>
          </div>
          <AccordionSummary
            sx={{
              mt: '1rem',
              flexShrink: '0',
              cursor: 'auto !important'
            }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                padding: '0 0.5rem',
                justifyContent: 'space-between'
              }}>
              <Typography my="auto">{t('filePreview.text.mapping')}</Typography>
              <Typography my="auto" fontWeight={'bold'} variant={'caption'}>
                {t('filePreview.text.mappingHint')}
              </Typography>
            </div>
          </AccordionSummary>
          <div
            style={{
              flex: '1',
              overflow: 'auto',
              display: 'flex',
              flexFlow: 'column',
              gap: '1rem',
              padding: '0 0.6rem 0.5rem 0.6rem',
              minHeight: '5.5rem'
            }}>
            <div
              style={{
                width: '100%',
                padding: '0.5rem 0 0.2rem 0',
                position: 'sticky',
                top: 0,
                zIndex: 2,
                backgroundColor: theme.palette.background.paper,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '1rem'
              }}>
              <Typography variant={'h6'} sx={{ fontWeight: '500' }}>
                {t('filePreview.form.column')}
              </Typography>
              <Typography variant={'h6'} sx={{ fontWeight: '500' }}>
                {t('filePreview.form.Property')}
              </Typography>
            </div>
            {fields?.map((fieldVals, idx) => (
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gap: '1rem'
                }}
                key={idx}>
                <Typography
                  variant="body1"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    my: 'auto'
                  }}>
                  {gridProperties?.columns?.[idx]?.text}
                </Typography>
                <Controller
                  control={control}
                  name={`mappings.${idx}.property`}
                  rules={{
                    validate: {
                      alreadySelected: (value, formValues) =>
                        alreadySelected(value, formValues, idx)
                    }
                  }}
                  render={({ field }) => {
                    return (
                      <>
                        <Autocomplete
                          options={renderProperties}
                          getOptionLabel={(option) => option?.name || ''}
                          value={
                            renderProperties.find((option) => option._id === field.value) || null
                          }
                          onChange={(event, newValue) => {
                            field.onChange(newValue?._id || null);
                            trigger(`mappings.${idx}.property`);
                            if (newValue?._id && includeVals(newValue._id)) {
                              setValue(`mappings.${idx}.valueFormat`, DATE_FORMAT_PICKER);
                            } else {
                              setValue(`mappings.${idx}.valueFormat`, undefined);
                            }
                            if (requiredError.length > 0) {
                              setRequiredError([]);
                            }
                          }}
                          renderOption={(props, option) => (
                            <ListRow {...props} key={option._id}>
                              {option.name}
                              {option.required ? (
                                <FormHelperText sx={{ ml: '0.2rem' }}>{`(${tCommon(
                                  'text.required'
                                )})`}</FormHelperText>
                              ) : (
                                ''
                              )}
                            </ListRow>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={!!errors?.['mappings']?.[idx]?.['property']}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                ...params.inputProps
                              }}
                            />
                          )}
                        />
                      </>
                    );
                  }}
                />
                <RenderIf
                  condition={
                    !!watch(`mappings.${idx}.property`) &&
                    includeVals(watch(`mappings.${idx}.property`) || '')
                  }>
                  <div style={{ display: 'flex', gap: 0 }}>
                    <Controller
                      control={control}
                      name={`mappings.${idx}.valueFormat`}
                      render={({ field }) => {
                        const debouncedInputChange = useDebouncyFn((newValue: string | null) => {
                          field.onChange(newValue);
                        }, 100);
                        return (
                          <>
                            <Autocomplete
                              freeSolo
                              options={formatOptions}
                              sx={{ flex: '1' }}
                              getOptionLabel={(option) => option || ''}
                              value={field.value || null}
                              onInputChange={(e, value, reason) => {
                                debouncedInputChange(value?.length > 0 ? value : null);
                              }}
                              renderOption={(props, option) => (
                                <ListRow {...props} key={option}>
                                  {option}
                                </ListRow>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  label={t('filePreview.form.valueFormat')}
                                  error={!!errors?.['mappings']?.[idx]?.['valueFormat']}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                />
                              )}
                            />
                          </>
                        );
                      }}
                    />

                    <Tooltip title={t('filePreview.text.dateFormatHint')}>
                      <IconButton size="small" sx={{ alignSelf: 'center', padding: '0.4rem' }}>
                        <MdInfoOutline style={{ alignSelf: 'center' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </RenderIf>
              </div>
            ))}
          </div>
          <RenderIf condition={requiredError.length > 0}>
            <div style={{ display: 'flex', padding: '0.4rem 0', justifyContent: 'flex-end' }}>
              <FormHelperText error>
                {t('filePreview.text.requiredProperties', {
                  count: requiredError.length,
                  properties: requiredError.map((property) => `"${property}"`).join(', ')
                })}
              </FormHelperText>
            </div>
          </RenderIf>
        </RenderIf>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button
            onClick={() => {
              onCancel();
            }}>
            {tCommon('buttons.back')}
          </Button>
          <Button
            variant="contained"
            disabled={
              Object.keys(formState.errors).length > 0 ||
              requiredError.length > 0 ||
              loadingProperties
            }
            onClick={handleSubmit(submit)}>
            {tCommon('actions.import')}
          </Button>
        </ButtonsContainer>
      </FooterModalContainer>
    </Container>
  );
};

export default ImportPreview;
