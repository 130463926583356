import { PeriodDateValue } from './filter';

export type PeriodDate = {
  start?: PeriodDateValue;
  end?: PeriodDateValue;
};

export enum CalendarNames {
  MY_CALENDAR = 'my-calendar'
}
