import { styled } from '@mui/material';

export const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
