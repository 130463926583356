import { loadState } from '@/utils';
import { Store } from '@bryntum/core-thin';
import { BryntumGantt } from '@bryntum/gantt-react-thin';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { BryntumGrid } from '@bryntum/grid-react-thin';
import { Column, ColumnStore, Grid } from '@bryntum/grid-thin';
import { BryntumScheduler } from '@bryntum/scheduler-react-thin';
import { isEqual } from 'lodash';
import { RefObject, useEffect, useState } from 'react';

type BryntumSorter = {
  field: string;
  ascending: boolean;
};

type UseBryntumSorterParams = {
  gridRef: RefObject<BryntumGrid | BryntumScheduler | BryntumSchedulerPro | BryntumGantt>;
  refName: string;
};

const useCustomBryntumSorter = ({ gridRef, refName }: UseBryntumSorterParams) => {
  const [newSorter, setNewSorter] = useState<BryntumSorter[]>([]);

  useEffect(() => {
    const ref = gridRef.current;
    if (!ref) {
      return;
    }

    const store = ref?.instance.store as Store;
    const columnStore = ref?.instance.columns as ColumnStore;

    // let store: Store;   //TODO: Change if the store of the BryntumInstances(Grid,Schedulers) is retrieved different (per instance).
    // let columnStore: ColumnStore;

    // if (ref instanceof BryntumGrid) {
    //   store = ref?.instance.store as Store;
    //   columnStore = ref?.instance.columns as ColumnStore;
    // } else {
    //   store = ref?.instance.store as Store;
    //   columnStore = ref?.instance.columns as ColumnStore;
    // }

    const localState = loadState<Record<string, any>>(refName);
    const initialSorters = (localState?.store?.sorters as BryntumSorter[]) ?? [];

    setNewSorter(initialSorters);

    function fetchSortedData({ sorters }: { sorters: BryntumSorter[] }) {
      const newSorters: BryntumSorter[] = sorters.map(({ field, ascending }) => ({
        field,
        ascending
      }));
      setNewSorter((oldSorter) => {
        if (isEqual(oldSorter, newSorters)) {
          return oldSorter;
        }
        const grid = gridRef.current?.instance as Grid;
        localStorage.setItem(refName, JSON.stringify(grid.state));
        return newSorters;
      });
    }

    function onColumnHide({ column }: { column: Column }) {
      const field = column.getData('field');
      const fieldSorter = store.sorters.find((sorter) => sorter.field === field);
      if (fieldSorter) {
        store.removeSorter(fieldSorter.field as string);
        setNewSorter((oldSorter) => oldSorter.filter((sorter) => sorter.field !== field));
        const grid = gridRef.current?.instance as Grid;
        localStorage.setItem(refName, JSON.stringify(grid.state));
      }
    }

    store.addListener('sort', fetchSortedData);
    columnStore.addListener('columnHide', onColumnHide);

    return () => {
      store && store.removeListener && store.removeListener('sort', fetchSortedData, ref);
      columnStore &&
        columnStore.removeListener &&
        columnStore.removeListener('columnHide', onColumnHide, ref);
    };
  }, [gridRef, refName]);

  return newSorter;
};

export default useCustomBryntumSorter;
