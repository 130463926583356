import { RenderIf } from '@/components/atoms';
import { FormHelperTextError } from '@/components/molecules/Card/Card.styles';
import {
  BodyModalContainer,
  FooterModalContainer,
  HeaderModalContainer
} from '@/components/organisms';
import {
  Entities,
  JOBS_NOT_BEING_SCHEDULED,
  JOBS_SCHEDULABLE_ROLES,
  ScheduleModes,
  SystemProperties
} from '@/const';
import { useGenericModalContext } from '@/context/GenericModalContext';
import { useScheduleConfigContext } from '@/context/ScheduleConfigContext';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { ButtonsContainer, theme } from '@/theme';
import { JobDto } from '@/types';
import { getPropertyValue, getPropertyValueDef } from '@/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Unstable_Grid2 as Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses
} from '@mui/material';
import { Card, DateInput, NumberInput } from 'components/molecules';
import { DateTime } from 'luxon';
import { Fragment, MutableRefObject, createRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScheduleConfigSchemaType, scheduleConfigSchema } from './ScheduleConfigTemplate.schema';
import { AlertJobs, EntitiesSkeleton, TooltipGrid } from './ScheduleConfigTemplate.style';

export type ScheduleConfigProps = {
  count: number;
  onCancel: () => void;
  loading: boolean;
  onSuccess: (form: ScheduleConfigSchemaType) => void;
};

export type RefAccordion = {
  ref: MutableRefObject<HTMLDivElement | null>;
  open: boolean;
};

type SchedulableRole = {
  value: string;
  label: string;
  slots: number;
};

export type SchedulableRoleType = { _id: string; label: string; emptySlotsCount: number };

const ScheduleConfig = ({ onSuccess, onCancel, loading, ...props }: ScheduleConfigProps) => {
  const { handleClose } = useGenericModalContext();
  const { selectedJobs, setSchedulableJobs } = useScheduleConfigContext();
  const { t } = useTranslation('templates/scheduleConfigTemplate');
  const [schedulableRoles, setSchedulableRoles] = useState<SchedulableRole[]>();
  const { setToastNotifications } = useToastContext();
  const [verifiedJobs, setVerifiedJobs] = useState<JobDto[]>();
  const [unverifiedJobs, setUnverifiedJobs] = useState<JobDto[]>();

  const {
    fetch: fetchNotBeingScheduled,
    error: errorNotBeingScheduled,
    loading: loadingNotBeingScheduled,
    data: jobsNotBeingScheduled
  } = useFetch<any>();

  const {
    fetch: fetchSchedulableRoles,
    error: errorSchedulableRoles,
    loading: loadingSchedulableRoles,
    data: jobsSchedulableRoles
  } = useFetch<SchedulableRoleType[]>();

  useEffect(() => {
    fetchNotBeingScheduled({
      url: JOBS_NOT_BEING_SCHEDULED,
      data: {
        jobs: selectedJobs.map((job) => job._id)
      },
      method: 'POST'
    });
  }, []);

  useEffect(() => {
    if (jobsNotBeingScheduled) {
      const newVerifiedJobs = selectedJobs.filter((job) => jobsNotBeingScheduled.includes(job._id));
      const newUnverifiedJobs = selectedJobs.filter(
        (job) => !jobsNotBeingScheduled.includes(job._id)
      );
      if (newUnverifiedJobs.length >= 1) {
        const jobNameOrId = (job: JobDto) =>
          getPropertyValueDef(job.properties, SystemProperties.JOBS_NAME, job._id);
        newUnverifiedJobs.sort((job1, job2) => jobNameOrId(job1).localeCompare(jobNameOrId(job2)));
      }

      if (newVerifiedJobs.length == 0) {
        setSchedulableRoles([]);
      }
      setSchedulableJobs(newVerifiedJobs);
      setVerifiedJobs(newVerifiedJobs);
      setUnverifiedJobs(newUnverifiedJobs);
      setValue(
        Entities.JOBS,
        newVerifiedJobs.map((verifiedJob) => verifiedJob._id)
      );
    }
  }, [jobsNotBeingScheduled]);

  useEffect(() => {
    if (verifiedJobs && verifiedJobs.length > 0) {
      const jobs = verifiedJobs.map((job: JobDto) => job._id);
      fetchSchedulableRoles({
        url: JOBS_SCHEDULABLE_ROLES,
        data: { jobs },
        method: 'POST'
      });
    }
  }, [verifiedJobs]);

  useEffect(() => {
    if (jobsSchedulableRoles && jobsSchedulableRoles.length > 0) {
      setSchedulableRoles(
        jobsSchedulableRoles.map((role) => {
          return {
            value: role._id,
            label: role.label,
            slots: role.emptySlotsCount
          };
        })
      );
    } else if (jobsSchedulableRoles && jobsSchedulableRoles.length == 0) {
      setSchedulableRoles([]);
    }
  }, [jobsSchedulableRoles]);

  useEffect(() => {
    if (errorNotBeingScheduled) {
      setToastNotifications([{ message: t('errors.notSchedule') }]);
      handleClose();
    }
  }, [errorNotBeingScheduled]);

  const defaultScheduleConfig = {
    jobs: selectedJobs.map((job: JobDto) => job._id),
    roles: [],
    schedulingType: ScheduleModes.COMPLETE,
    endDate: null,
    byPeriods: false,
    periodSize: 10
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setValue
  } = useForm<ScheduleConfigSchemaType>({
    resolver: yupResolver(scheduleConfigSchema),
    defaultValues: defaultScheduleConfig
  });

  const onSubmit = (form: ScheduleConfigSchemaType) => {
    const newForm: any = {
      ...form,
      stopAfterMinutes: form?.stopProps?.stopAfterMinutes,
      stopMinPercentage: form?.stopProps?.stopMinPercentage
    };
    if (newForm?.stopProps) {
      delete newForm['stopProps'];
    }
    onSuccess(newForm);
  };

  const onErrors = () => {
    // setToastNotifications([{ message: 'Fill the required fields' }]);
  };

  const JobsTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(13),
      border: `1px solid ${theme.palette.divider}`
    }
  }));

  const options = t('options.items', { returnObjects: true });

  const roles = watch('roles');

  const handleRoleSelection =
    (func: any) =>
    ({ target }: any) => {
      const value = target.name;
      const copy = [...(roles as string[])];
      if (copy.includes(value)) {
        const idx = copy.findIndex((id) => id === value);
        copy.splice(idx, 1);
      } else copy.push(value);
      func(copy);
    };

  const [refAccordions, setRefAccordions] = useState<RefAccordion[]>([
    { ref: createRef<HTMLDivElement | null>(), open: true },
    { ref: createRef<HTMLDivElement | null>(), open: true },
    { ref: createRef<HTMLDivElement | null>(), open: false }
  ]);

  const executeScroll = (event: MouseEvent, position: number) => {
    if (refAccordions[position].open) {
      setRefAccordions((oldRefAccordions) => {
        return oldRefAccordions.map((oldRefAccordion, index) => {
          if (index == position) {
            return {
              ref: oldRefAccordion.ref,
              open: false
            };
          }
          return oldRefAccordion;
        });
      });
    } else {
      setRefAccordions((oldRefAccordions) => {
        return oldRefAccordions.map((oldRefAccordion, index) => {
          if (index == position) {
            return {
              ref: oldRefAccordion.ref,
              open: true
            };
          }
          return oldRefAccordion;
        });
      });
      setTimeout(() => {
        if (refAccordions[position]?.ref && refAccordions[position]?.ref.current) {
          refAccordions[position]?.ref?.current?.scrollIntoView({
            block: 'end',
            behavior: 'smooth'
          });
        }
      }, 300);
    }
  };

  return (
    <Grid
      height="100%"
      minWidth={'100%'}
      width={'100%'}
      display={'flex'}
      flexDirection="column"
      container
      {...props}
      onSubmit={handleSubmit(onSubmit, onErrors)}
      component="form"
      noValidate>
      <HeaderModalContainer>
        <Typography color={theme.palette.common.white} textAlign={'center'} variant="h5">
          {t('title')}
        </Typography>
      </HeaderModalContainer>

      <BodyModalContainer>
        <RenderIf condition={verifiedJobs && selectedJobs.length == verifiedJobs.length}>
          <Typography margin={'1rem 0'}>
            {t('subtitle', { count: verifiedJobs?.length })}
          </Typography>
        </RenderIf>
        <RenderIf condition={!verifiedJobs}>
          <Skeleton
            sx={{ margin: '8px 0 16px 8px' }}
            animation="wave"
            variant="rectangular"
            width={'8rem'}
            height={'1.2rem'}
          />
        </RenderIf>
        <RenderIf
          condition={
            verifiedJobs && selectedJobs.length > verifiedJobs.length && verifiedJobs.length > 0
          }>
          <Grid display={'flex'} flexWrap="wrap" alignItems={'center'}>
            <Typography margin={'0 8px 0 0'}>
              {t('subtitle', { count: verifiedJobs?.length })}
            </Typography>
            <AlertJobs severity="warning">
              <Typography>
                {t('jobsDiscarded', {
                  count: selectedJobs.length - (verifiedJobs?.length || 0)
                })}{' '}
                <JobsTooltip
                  arrow={true}
                  title={
                    <TooltipGrid>
                      <ul>
                        {unverifiedJobs?.map((unverifiedJob, _idx) => {
                          return (
                            <li key={_idx}>
                              {getPropertyValue(
                                unverifiedJob.properties,
                                SystemProperties.JOBS_NAME
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </TooltipGrid>
                  }>
                  <Typography variant="details">{t('seeMore')}</Typography>
                </JobsTooltip>
              </Typography>
            </AlertJobs>
          </Grid>
        </RenderIf>
        <RenderIf condition={verifiedJobs && verifiedJobs.length == 0}>
          <AlertJobs severity="error">
            <Typography>{t('allJobsDiscarded')}</Typography>
          </AlertJobs>
        </RenderIf>
        <Grid xs={12} ref={refAccordions[0].ref}>
          <Card
            title={t('roles.title', { item: 1 })}
            onHeader={(event) => {
              executeScroll(event as unknown as MouseEvent, 0);
            }}>
            <FormControl component="fieldset" variant="standard">
              <Typography variant="body1" color={theme.palette.grey[500]}>
                {t('roles.description')}
              </Typography>
              <FormGroup>
                <RenderIf condition={schedulableRoles && schedulableRoles.length > 0}>
                  <Controller
                    control={control}
                    name="roles"
                    render={({ field }) => (
                      <>
                        {!!schedulableRoles &&
                          schedulableRoles.map(({ value, label, slots }: SchedulableRole) => (
                            <FormControlLabel
                              key={value}
                              control={
                                <Checkbox
                                  style={{ padding: '4px 8px' }}
                                  name={value}
                                  checked={field.value?.includes(value)}
                                  onChange={handleRoleSelection(field.onChange)}
                                />
                              }
                              label={
                                <Grid display={'flex'} gap={'8px'} alignItems="center">
                                  <Typography>{`${label}`}</Typography>
                                  <Typography variant="body2" fontSize={'12px'}>
                                    {t('roles.openSlots', { slots: slots })}
                                  </Typography>
                                </Grid>
                              }
                            />
                          ))}
                      </>
                    )}
                  />
                </RenderIf>
                <RenderIf
                  condition={
                    schedulableRoles &&
                    schedulableRoles.length == 0 &&
                    verifiedJobs &&
                    verifiedJobs.length > 0
                  }>
                  <Grid padding={0}>
                    <Typography variant="body1">{t('roles.notSearched')}</Typography>
                  </Grid>
                </RenderIf>
                <RenderIf condition={!!errorSchedulableRoles}>
                  <Grid padding={0}>
                    <FormHelperTextError>{t('roles.error')}</FormHelperTextError>
                  </Grid>
                </RenderIf>
                <RenderIf condition={!schedulableRoles && !errorSchedulableRoles}>
                  <Grid padding={0}>
                    <EntitiesSkeleton>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={'1.2rem'}
                        height={'1.2rem'}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={'8rem'}
                        height={'1.2rem'}
                      />
                    </EntitiesSkeleton>

                    <EntitiesSkeleton>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={'1.2rem'}
                        height={'1.2rem'}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={'8rem'}
                        height={'1.2rem'}
                      />
                    </EntitiesSkeleton>
                  </Grid>
                </RenderIf>
              </FormGroup>
              <RenderIf condition={!!errors.roles}>
                <FormHelperTextError>{t('roles.atLeast')}</FormHelperTextError>
              </RenderIf>
            </FormControl>
          </Card>
        </Grid>
        <Grid xs={12} ref={refAccordions[1].ref}>
          <Card
            title={t('options.title', { item: 2 })}
            onHeader={(event) => {
              executeScroll(event as unknown as MouseEvent, 1);
            }}>
            <FormControl component="fieldset" variant="standard">
              <RadioGroup defaultValue={ScheduleModes.COMPLETE}>
                {options.map((option) => (
                  <Fragment key={option.value}>
                    <Controller
                      control={control}
                      name="schedulingType"
                      render={({ field }) => (
                        <FormControlLabel
                          value={option.value}
                          control={<Radio onChange={field.onChange} />}
                          label={option.label}
                          disabled={
                            option.value == ScheduleModes.JOBBYJOB &&
                            verifiedJobs &&
                            verifiedJobs?.length <= 1
                          }
                        />
                      )}
                    />
                    <Typography variant="body2" color={theme.palette.grey[500]} sx={{ pl: 4 }}>
                      {option.description}
                    </Typography>
                  </Fragment>
                ))}
              </RadioGroup>
              <Controller
                control={control}
                name="byPeriods"
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ marginTop: '1rem' }}
                    control={
                      <Checkbox
                        style={{ padding: '4px 8px' }}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    }
                    label={
                      <Grid display={'flex'} gap={'8px'} alignItems="center">
                        <Typography sx={{ flexShrink: 0 }}>{t('byPeriods.divide')}</Typography>
                        <FormControl
                          component="fieldset"
                          variant="standard"
                          sx={{ minWidth: '5rem' }}>
                          <Controller
                            control={control}
                            name="periodSize"
                            render={({ field }) => (
                              <NumberInput
                                disabled={!watch('byPeriods')}
                                placeholder={t('advanced.days')}
                                suffix={` ${t('advanced.days')}`}
                                decimalScale={0}
                                value={watch('periodSize') ?? undefined}
                                onChange={field.onChange}
                                onBlur={() => {
                                  if (!watch('periodSize')) setValue('periodSize', 10);
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <Typography variant="body2">{t('byPeriods.description')}</Typography>
                      </Grid>
                    }
                  />
                )}
              />
            </FormControl>
          </Card>
        </Grid>
        <Grid xs={12} ref={refAccordions[2].ref}>
          <Card
            onHeader={(event) => {
              executeScroll(event as unknown as MouseEvent, 2);
            }}
            defaultExpanded={false}
            title={t('advanced.title', { item: 3 })}>
            <FormControl error={!!errors.endDate} component="fieldset" variant="standard">
              <FormLabel component="legend" error={false}>
                {t('date.description')}
              </FormLabel>
              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <DateInput
                    includeMultipleObj={false}
                    includeHours={false}
                    disablePast
                    helperText={errors.endDate?.message as string}
                    onChange={field.onChange}
                    value={field.value ? DateTime.fromJSDate(field.value) : null}
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ marginTop: '1rem' }} component="fieldset" variant="standard">
              <FormLabel component="legend" error={false}>
                {t('advanced.stopAfterMinutes')}
              </FormLabel>
              <Grid display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                <Grid display={'flex'} flexDirection={'column'}>
                  <Controller
                    control={control}
                    name="stopProps.stopAfterMinutes"
                    render={({ field }) => (
                      <NumberInput
                        placeholder={t('advanced.minutes')}
                        suffix={` ${t('advanced.minutes')}`}
                        decimalScale={0}
                        value={field.value ?? undefined}
                        sx={{ width: '12rem' }}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid display={'flex'} flexDirection={'column'}>
                  <Controller
                    control={control}
                    name="stopProps.stopMinPercentage"
                    render={({ field }) => (
                      <NumberInput
                        placeholder={t('advanced.percentage')}
                        suffix="%"
                        value={field.value ?? undefined}
                        sx={{ width: '12rem' }}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <RenderIf
              condition={
                !!errors.stopProps &&
                (errors.stopProps?.['stopMinPercentage']?.type === 'required' ||
                  errors.stopProps?.['stopMinPercentage']?.type === 'required')
              }>
              <FormHelperTextError>{t('advanced.stopError')}</FormHelperTextError>
            </RenderIf>
            <RenderIf
              condition={
                !!errors.stopProps && errors.stopProps?.['stopMinPercentage']?.type === 'max'
              }>
              <FormHelperTextError>{t('advanced.percentageErrorMax')}</FormHelperTextError>
            </RenderIf>
            <RenderIf
              condition={
                !!errors.stopProps && errors.stopProps?.['stopMinPercentage']?.type === 'min'
              }>
              <FormHelperTextError>{t('advanced.percentageErrorMin')}</FormHelperTextError>
            </RenderIf>

            <RenderIf condition={!!errors.periodSize && errors.periodSize.type === 'max'}>
              <FormHelperTextError>{t('advanced.periodSizeErrorMax')}</FormHelperTextError>
            </RenderIf>
            <RenderIf condition={!!errors.periodSize && errors.periodSize.type === 'min'}>
              <FormHelperTextError>{t('advanced.periodSizeErrorMin')}</FormHelperTextError>
            </RenderIf>
          </Card>
        </Grid>
      </BodyModalContainer>
      <FooterModalContainer>
        <ButtonsContainer>
          <Button onClick={onCancel}>{t('cancel')}</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={
              !verifiedJobs ||
              (verifiedJobs && verifiedJobs.length < 1) ||
              !schedulableRoles ||
              (schedulableRoles && schedulableRoles.length < 1)
            }
            type="submit">
            {t('submit')}
          </LoadingButton>
        </ButtonsContainer>
      </FooterModalContainer>
    </Grid>
  );
};

export default ScheduleConfig;
