import { theme } from '@/theme';
import { TabPanel as TabPanelMUI } from '@mui/lab';
import { CircularProgress as CircularProgressMUI, styled, Tab as TabMUI } from '@mui/material';
export const TabWrapper = styled('div')`
  width: 35%;
  height: calc(100vh - 3rem - 3.2rem);
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.common.white};
`;

export const TabPanel = styled(TabPanelMUI)`
  overflow: auto;
  padding: 0;
  position: relative;
  height: 100%;
`;

export const Tab = styled(TabMUI)`
  height: 100%;
  min-height: auto;
`;

export const CircularProgress = styled(CircularProgressMUI)`
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
