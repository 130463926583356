import { SelectedSession } from '@/context/CurriculmSetupContext';
import { Session } from '@/types';

export const PROPERTIES_TO_IGNORE_FIRST_ONE = ['minDaysBefore', 'maxDaysBefore'];

export const PROPERTIES_TO_IGNORE_LAST_ONE = ['turnMinutes'];

export const shouldIgnore = (
  selectedSession: SelectedSession,
  selectedSessions: SelectedSession[],
  sessions: Session[],
  name: keyof Session
) => {
  // CASE 1, SINGLE SELECTION AND FIRST ONE
  if (
    selectedSession.index === 0 &&
    PROPERTIES_TO_IGNORE_FIRST_ONE.includes(name) &&
    selectedSessions.length > 1
  )
    return true;
  // CASE 2, LAST ONE
  if (
    selectedSession.index === sessions.length - 1 &&
    PROPERTIES_TO_IGNORE_LAST_ONE.includes(name) &&
    selectedSessions.length > 1
  )
    return true;
  return false;
};

export const getSessionIndex = (selectedSessions: SelectedSession[]) => {
  if (selectedSessions.length > 1) {
    const sessions = selectedSessions.filter((session) => session.index !== 0);
    if (sessions.length > 0) return sessions[0].index;
    else return 0;
  }
  return selectedSessions[0].index;
};
