import { Box, styled } from '@mui/material';

export const Carousel = styled('div')`
  display: flex;
  width: 100%;
  position: relative;
  // overflow: hidden;
`;

export const BoxModalConfig = styled(Box)`
  max-height: 100vw;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  width: 100%;
`;
