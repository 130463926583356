import { JobDto, ResourceDto } from '@/types';
import { createContext } from 'react';

export type ScheduleConfigContextType = {
  selectedJobs: JobDto[];
  schedulableJobs: JobDto[];
  setSchedulableJobs: (newSchedulableJobs: JobDto[]) => void;
  selectedResources: ResourceDto[];
  isFinished: boolean;
  setSelectedJobs: (newJobFilter: JobDto[]) => void;
  setSelectedResources: (newResourcesFilter: ResourceDto[]) => void;
  setIsFinished: (finished: boolean) => void;
};

export default createContext<ScheduleConfigContextType>({} as ScheduleConfigContextType);
