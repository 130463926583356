export const hexToRGB = (hex: string, alpha: number) => {
  hex = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex;
  const r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const isValidHexColor = (value) => /^#[0-9A-F]{6}$/i.test(value);

export const isTextColorDark = (bgColor: string) => {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const uicolors = [
    parseInt(color.substring(0, 2), 16) / 255, //r
    parseInt(color.substring(2, 4), 16) / 255, //g
    parseInt(color.substring(4, 6), 16) / 255 //b
  ];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179;
};

export const pickTextColorBasedOnBgColor = (
  bgColor: string,
  lightColor: string,
  darkColor: string
) => {
  return isTextColorDark(bgColor) ? darkColor : lightColor;
};
