import { RenderIf, SessionIcon, StatusIcon, TopicIcon } from '@/components/atoms';
import {
  Entities,
  PROPERTIES_BY_ENTITY,
  PropertyTypes,
  SystemFilters,
  SystemProperties
} from '@/const';
import {
  EventDto,
  FieldFilterType,
  JobDetailsDto,
  JobDto,
  PropertyDto,
  SpecialFilterTypes,
  StructureStatus
} from '@/types';
import {
  AssignmentBryntum,
  AssignmentBryntumPro,
  EventBryntum,
  EventProBryntum,
  ResourceBryntum,
  TEMPORAL_CHILD_ID
} from '@/types/ui/bryntum';
import {
  sortingByFetch,
  transformPropertyValue,
  transformToAssignmentBryntum,
  transformToEventBryntum,
  transformToEventProBryntum
} from '@/utils';
import { Model } from '@bryntum/core-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { BryntumScheduler } from '@bryntum/scheduler-react-thin';
import {
  AssignmentModel,
  EventModel,
  ResourceModel,
  ResourceModelConfig
} from '@bryntum/scheduler-thin';
import { Box, Grid, Skeleton } from '@mui/material';
import { TFunction } from 'i18next';
import { ForwardedRef, RefObject } from 'react';

export enum RowTypes {
  JOB = 'JOB',
  SESSION = 'SESSION',
  TOPIC = 'TOPIC'
}

const rowStatusMap = {
  [RowTypes.JOB]: (row: Model) => {
    return row.getData('job').status;
  },
  [RowTypes.SESSION]: (row: Model) => {
    return row.getData('status');
  },
  [RowTypes.TOPIC]: (row: Model) => {
    return row.getData('status');
  }
};

export const getExtraFields = (tCommon: TFunction) => {
  return [
    {
      name: 'Curriculum',
      _id: SystemFilters.JOBS_CURRICULUM,
      type: PropertyTypes.OBJECTID,
      order: 0,
      referencedCollection: Entities.CURRICULUMS,
      value: []
    },
    {
      name: 'Status',
      _id: SystemFilters.JOBS_STATUS,
      type: SpecialFilterTypes.OPTIONS,
      order: 1,
      options: [
        {
          _id: StructureStatus.COMPLETE,
          value: StructureStatus.COMPLETE,
          label: tCommon(`jobStatus.${StructureStatus.COMPLETE}`)
        },
        {
          _id: StructureStatus.PARTIALLY_SCHEDULED,
          value: StructureStatus.PARTIALLY_SCHEDULED,
          label: tCommon(`jobStatus.${StructureStatus.PARTIALLY_SCHEDULED}`)
        },
        {
          _id: StructureStatus.CANCELED,
          value: StructureStatus.CANCELED,
          label: tCommon(`jobStatus.${StructureStatus.CANCELED}`)
        },
        {
          _id: StructureStatus.NOT_SCHEDULED,
          value: StructureStatus.NOT_SCHEDULED,
          label: tCommon(`jobStatus.${StructureStatus.NOT_SCHEDULED}`)
        }
      ]
    }
  ] as FieldFilterType[];
};

export const transformJobsToScheduler = (loading?: boolean, jobs?: JobDto[]) => {
  let localResources: ResourceBryntum[];

  if (jobs)
    localResources = jobs.map((job) => {
      const isCollapsable = job.status !== StructureStatus.NOT_SCHEDULED;
      const children = isCollapsable
        ? [
            {
              id: job._id + TEMPORAL_CHILD_ID,
              iconCls: 'remove',
              cls: 'b-grid-leaf-cell b-grid-child-row',
              rowHeight: 28
            }
          ]
        : undefined;

      const localResource = {
        id: job._id,
        job,
        type: RowTypes.JOB,
        iconCls: isCollapsable ? '' : 'remove',
        children
      };
      job.properties.forEach(
        (property) => (localResource[property._id] = property.label ?? property.value)
      );
      return localResource;
    });
  else
    localResources = new Array(5).fill('').map((_, idx) => ({ id: `${idx}`, iconCls: 'remove' }));

  return localResources;
};

export const getColumns = (
  ref: ForwardedRef<BryntumScheduler>,
  t: TFunction,
  tMainTemplate: TFunction,
  properties?: PropertyDto[]
) => {
  const columnStore: ColumnStore = (ref as RefObject<BryntumScheduler>).current?.instance
    .columns as ColumnStore;

  columnStore.remove(columnStore.records.filter((r: any) => !r.isTimeAxisColumn));
  if (properties) {
    columnStore.add([
      {
        id: 'counter',
        field: 'counter',
        type: 'rownumber',
        cls: 'counter-header',
        cellCls: 'b-border-bottom',
        align: 'center',
        htmlEncodeHeaderText: false,
        hideable: false
      },
      {
        id: 'structure',
        field: 'structure',
        text: tMainTemplate('jobsColumns.structure', {
          jobName: properties.find((p) => p._id === SystemProperties.JOBS_NAME)?.name
        }),
        minWidth: 80,
        hideable: true,
        align: 'left',
        type: 'tree',
        sortable: false,
        renderer: ({ record: { data } }) => {
          return (
            <Box height="100%" width="100%" display="flex" alignItems="flex-start" gap="0.5rem">
              {data?.type === RowTypes.TOPIC && (
                <TopicIcon svgProperties={{ minWidth: '0.938rem' }} size={15} />
              )}
              {data?.type === RowTypes.SESSION && (
                <SessionIcon svgProperties={{ minWidth: '0.8rem' }} size={12} />
              )}

              {data?.name ? (
                data.name
              ) : data.job ? (
                transformPropertyValue(PropertyTypes.STRING, t, data[SystemProperties.JOBS_NAME])
              ) : (
                <Skeleton height={30} width={'100%'} />
              )}
            </Box>
          );
        }
      },
      {
        id: 'Curriculum',
        field: 'curriculum',
        text: tMainTemplate('jobsColumns.curriculum'),
        width: 80,
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record: { data } }) => {
          return !data.job ? (
            <Skeleton height={30} />
          ) : data?.job?.curriculum?.label ? (
            data.job.curriculum.label
          ) : (
            ''
          );
        }
      },
      {
        id: 'status',
        field: 'status',
        text: tMainTemplate('jobsColumns.status'),
        width: 80,
        align: 'left',
        sortable: sortingByFetch,
        groupable: false,
        renderer: ({ record }) => {
          const { data } = record;
          if (!data.job) return <Skeleton height={30} />;
          const status = getStatus(record);
          return (
            <Grid display="flex" alignItems="center" gap="0.3rem" height="100%">
              <RenderIf condition={!!status}>
                <StatusIcon status={status} />
                <span>{t(`jobStatus.${status}`) as string}</span>
              </RenderIf>
            </Grid>
          );
        }
      },
      ...(properties as PropertyDto[])
        .sort((p1, p2) => p1._id.localeCompare(p2._id))
        .map(({ _id: id, name: text, type }) => ({
          id,
          ...(Object.values(SystemProperties).includes(id as SystemProperties)
            ? {}
            : { hidden: true }),
          field: id,
          text,
          width: 80,
          align: type == PropertyTypes.NUMBER ? 'right' : 'left',
          sortable: sortingByFetch,
          groupable: false,
          renderer: ({ record: { data } }) => {
            return !data.job ? <Skeleton height={30} /> : transformPropertyValue(type, t, data[id]);
          }
        }))
    ]);
  } else {
    columnStore.add([
      ...new Array(7).fill('').map(() => ({
        text: '...',
        width: 40,
        renderer: () => {
          return <Skeleton height={30} />;
        }
      }))
    ]);
  }
};

export const getAssignmentsFromEvents = (events?: EventDto[], jobs?: JobDto[], t?: TFunction) => {
  const jobEventsRender: EventBryntum[] = [];
  const jobAssignments: AssignmentBryntum[] = [];

  if (events && jobs && t) {
    events.forEach((event) => {
      const render = transformToEventBryntum(event);
      const join = event.join && ('n' as unknown as number);
      if (join)
        render.name = `${render.name}${t('text.joinedSuffix', {
          count: join
        })}`;

      jobEventsRender.push(render);
      jobAssignments.push(transformToAssignmentBryntum(event._id, event.job));
    });
  }

  return {
    jobAssignments,
    jobEventsRender
  };
};

export const getAssignmentsFromEventsPro = (events?: EventDto[], jobs?: JobDto[], t?: TFunction) => {
  const jobEventsRender: EventProBryntum[] = [];
  const jobAssignments: AssignmentBryntumPro[] = [];

  if (events && jobs && t) {
    events.forEach((event) => {
      const render = transformToEventProBryntum(event);
      const join = event.join && ('n' as unknown as number);
      if (join)
        render.name = `${render.name}${t('text.joinedSuffix', {
          count: join
        })}`;

      jobEventsRender.push(render);
      jobAssignments.push(transformToAssignmentBryntum(event._id, event.job));
    });
  }

  return {
    jobAssignments,
    jobEventsRender
  };
};


export const formatStructure = (structure: JobDetailsDto, record: Model) => {
  const jobName = record.getData(SystemProperties.JOBS_NAME);
  const curriculumName = record.getData('job').curriculum.label;
  const jobId = record.id;
  
  return structure.sessions.map(
    (session) =>
      new ResourceModel({
        id: jobId + '_' + session.id,
        parentId: jobId,
        cls: 'b-grid-leaf-cell b-grid-child-row',
        rowHeight: 28,
        status: session.status,
        sessionId: session.id,
        type: RowTypes.SESSION,
        name: session.name,
        [SystemProperties.JOBS_NAME]: jobName,
        job: { _id: jobId, curriculum: { label: curriculumName } },
        children: session.topics.map((topic) => ({
          rowHeight: 28,
          topicId: topic.id,
          cls: 'b-grid-leaf-cell b-grid-child-row',
          type: RowTypes.TOPIC,
          iconCls: 'hide',
          status: topic.status,
          job: { _id: jobId, curriculum: { label: curriculumName } },
          [SystemProperties.JOBS_NAME]: jobName,
          id: jobId + '_' + session.id + '_' + topic.id,
          parentId: jobId + '_' + session.id,
          name: topic.name
        }))
      } as Partial<ResourceModelConfig>)
  );
};

export const createSecondaryAssignments = (assignments: AssignmentModel[], jobId: string) => {
  const newAssignments: { resourceId: string; eventId: string }[] = [];
  assignments.forEach((assignment) => {
    const event: EventModel = assignment.event as EventModel;
    newAssignments.push({
      eventId: event.getData('id'),
      resourceId: jobId + '_' + event.getData('sessionId')
    });
    newAssignments.push({
      eventId: event.getData('id'),
      resourceId: jobId + '_' + event.getData('sessionId') + '_' + event.getData('topicId')
    });
  });
  return newAssignments;
};

export const filterDataJobsProperties = {
  url: PROPERTIES_BY_ENTITY.replace(':entity_name', Entities.JOBS)
};

export const getStatus = (row: Model) => {
  const type = row.getData('type') as StructureStatus;
  const status = rowStatusMap[type](row);
  return status;
};
