import { FILES_URL_BY_ID } from '@/const';
import { useToastContext } from '@/context/ToastContext';
import { useFetch } from '@/hooks';
import { theme } from '@/theme';
import { Avatar, SxProps, Theme } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export type ResourceThumbnailProps = {
  size?: number;
  src: string;
  sx?: SxProps<Theme>;
};

const ResourceThumbnail = ({ size = 20, src, sx }: ResourceThumbnailProps) => {
  const { data: image, fetch: fetchImage, error: errorImage } = useFetch<string>();
  const ref = useRef<HTMLDivElement | null>(null);
  const { setToastNotifications } = useToastContext();
  const { t: tCommon } = useTranslation();

  useEffect(() => {
    if (errorImage) {
      setToastNotifications([{ message: tCommon('errors.loadingImage') }]);
    }
  }, [errorImage]);

  useEffect(() => {
    if (src) {
      fetchImage({ url: FILES_URL_BY_ID.replace(':id', src) });
    }
  }, []);

  return (
    <>
      <Avatar
        ref={ref}
        color={theme.palette.primary.main}
        src={image as string}
        imgProps={{ draggable: false }}
        sx={{ height: '28px', width: '28px', ...sx }}></Avatar>
    </>
  );
};

export default ResourceThumbnail;
