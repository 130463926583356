import { LocaleHelper } from '@bryntum/core-thin';

const localeENUS = {
  localeName: 'en-US',
  localeDesc: 'en-US',
  localeCode: 'en-US',

  Object: {
    Yes: 'Yes',
    No: 'No',
    Cancel: 'Cancel',
    Ok: 'OK',
    Week: 'Week',
    newEvent: 'New event'
  },

  Combo: {
    noResults: 'No results',
    recordNotCommitted: 'Record could not be added',
    addNewValue: (value) => `Add ${value}`
  },

  FilePicker: {
    file: 'File'
  },

  Field: {
    badInput: 'Invalid field value',
    patternMismatch: 'Value should match a specific pattern',
    rangeOverflow: (value) => `Value must be less than or equal to ${value.max}`,
    rangeUnderflow: (value) => `Value must be greater than or equal to ${value.min}`,
    stepMismatch: 'Value should fit the step',
    tooLong: 'Value should be shorter',
    tooShort: 'Value should be longer',
    typeMismatch: 'Value is required to be in a special format',
    valueMissing: 'This field is required',
    invalidValue: 'Invalid field value',
    minimumValueViolation: 'Minimum value violation',
    maximumValueViolation: 'Maximum value violation',
    fieldRequired: 'This field is required',
    validateFilter: 'Value must be selected from the list'
  },

  DateField: {
    invalidDate: 'Invalid date input'
  },

  DatePicker: {
    gotoPrevYear: 'Go to previous year',
    gotoPrevMonth: 'Go to previous month',
    gotoNextMonth: 'Go to next month',
    gotoNextYear: 'Go to next year'
  },

  NumberFormat: {
    locale: 'en-US',
    currency: 'USD'
  },

  DurationField: {
    invalidUnit: 'Invalid unit'
  },

  TimeField: {
    invalidTime: 'Invalid time input'
  },

  TimePicker: {
    hour: 'Hour',
    minute: 'Minute',
    second: 'Second'
  },

  List: {
    loading: 'Loading...'
  },

  GridBase: {
    loadMask: 'Loading...',
    syncMask: 'Saving changes, please wait...',
    loadFailedMessage: 'Data loading failed!',
    syncFailedMessage: 'Data synchronization failed!',
    unspecifiedFailure: 'Unspecified failure',
    networkFailure: 'Network error',
    parseFailure: 'Failed to parse server response',
    serverResponse: 'Server response:',
    noRows: 'No records to display',
    moveColumnLeft: 'Move to left section',
    moveColumnRight: 'Move to right section',
    moveColumnTo: (region) => `Move column to ${region}`
  },

  PagingToolbar: {
    firstPage: 'Go to first page',
    prevPage: 'Go to previous page',
    page: 'Page',
    nextPage: 'Go to next page',
    lastPage: 'Go to last page',
    reload: 'Reload current page',
    noRecords: 'No records to display',
    pageCountTemplate: (data) => `of ${data.lastPage}`,
    summaryTemplate: (data) => `Displaying records ${data.start} - ${data.end} of ${data.allCount}`
  },

  PanelCollapser: {
    Collapse: 'Collapse',
    Expand: 'Expand'
  },

  Popup: {
    close: 'Close'
  },

  UndoRedo: {
    Undo: 'Undo',
    Redo: 'Redo',
    UndoLastAction: 'Undo last action',
    RedoLastAction: 'Redo last undone action',
    NoActions: 'No items in the undo queue'
  },

  FieldFilterPicker: {
    equals: 'equals',
    doesNotEqual: 'does not equal',
    isEmpty: 'is empty',
    isNotEmpty: 'is not empty',
    contains: 'contains',
    doesNotContain: 'does not contain',
    startsWith: 'starts with',
    endsWith: 'ends with',
    isOneOf: 'is one of',
    isNotOneOf: 'is not one of',
    isGreaterThan: 'is greater than',
    isLessThan: 'is less than',
    isGreaterThanOrEqualTo: 'is greater than or equal to',
    isLessThanOrEqualTo: 'is less than or equal to',
    isBetween: 'is between',
    isNotBetween: 'is not between',
    isBefore: 'is before',
    isAfter: 'is after',
    isToday: 'is today',
    isTomorrow: 'is tomorrow',
    isYesterday: 'is yesterday',
    isThisWeek: 'is this week',
    isNextWeek: 'is next week',
    isLastWeek: 'is last week',
    isThisMonth: 'is this month',
    isNextMonth: 'is next month',
    isLastMonth: 'is last month',
    isThisYear: 'is this year',
    isNextYear: 'is next year',
    isLastYear: 'is last year',
    isYearToDate: 'is year to date',
    isTrue: 'is true',
    isFalse: 'is false',
    selectAProperty: 'Select a property',
    selectAnOperator: 'Select an operator',
    caseSensitive: 'Case-sensitive',
    and: 'and',
    dateFormat: 'D/M/YY',
    selectOneOrMoreValues: 'Select one or more values',
    enterAValue: 'Enter a value',
    enterANumber: 'Enter a number',
    selectADate: 'Select a date'
  },

  FieldFilterPickerGroup: {
    addFilter: 'Add filter'
  },

  DateHelper: {
    locale: 'en-US',
    weekStartDay: 0,
    nonWorkingDays: {
      0: true,
      6: true
    },
    weekends: {
      0: true,
      6: true
    },
    unitNames: [
      { single: 'millisecond', plural: 'ms', abbrev: 'ms' },
      { single: 'second', plural: 'seconds', abbrev: 's' },
      { single: 'minute', plural: 'minutes', abbrev: 'min' },
      { single: 'hour', plural: 'hours', abbrev: 'h' },
      { single: 'day', plural: 'days', abbrev: 'd' },
      { single: 'week', plural: 'weeks', abbrev: 'w' },
      { single: 'month', plural: 'months', abbrev: 'mon' },
      { single: 'quarter', plural: 'quarters', abbrev: 'q' },
      { single: 'year', plural: 'years', abbrev: 'yr' },
      { single: 'decade', plural: 'decades', abbrev: 'dec' }
    ],
    unitAbbreviations: [
      ['mil'],
      ['s', 'sec'],
      ['m', 'min'],
      ['h', 'hr'],
      ['d'],
      ['w', 'wk'],
      ['mo', 'mon', 'mnt'],
      ['q', 'quar', 'qrt'],
      ['y', 'yr'],
      ['dec']
    ],
    parsers: {
      L: 'MM/DD/YYYY',
      LT: 'HH:mm A',
      LTS: 'HH:mm:ss A'
    },
    ordinalSuffix: (number) => {
      const hasSpecialCase = ['11', '12', '13'].find((n) => number.endsWith(n));

      let suffix = 'th';

      if (!hasSpecialCase) {
        const lastDigit = number[number.length - 1];
        suffix = { 1: 'st', 2: 'nd', 3: 'rd' }[lastDigit] || 'th';
      }

      return number + suffix;
    }
  },

  ColumnPicker: {
    column: 'Column',
    columnsMenu: 'Columns',
    hideColumn: 'Hide column',
    hideColumnShort: 'Hide',
    newColumns: 'New columns'
  },

  Filter: {
    applyFilter: 'Apply filter',
    filter: 'Filter',
    editFilter: 'Edit filter',
    on: 'On',
    before: 'Before',
    after: 'After',
    equals: 'Equals',
    lessThan: 'Less than',
    moreThan: 'More than',
    removeFilter: 'Remove filter',
    disableFilter: 'Disable filter'
  },

  FilterBar: {
    enableFilterBar: 'Show filter bar',
    disableFilterBar: 'Hide filter bar'
  },

  Group: {
    group: 'Group',
    groupAscending: 'Group ascending',
    groupDescending: 'Group descending',
    groupAscendingShort: 'Ascending',
    groupDescendingShort: 'Descending',
    stopGrouping: 'Stop grouping',
    stopGroupingShort: 'Stop'
  },

  HeaderMenu: {
    moveBefore: (text) => `Move before "${text}"`,
    moveAfter: (text) => `Move after "${text}"`,
    collapseColumn: 'Collapse column',
    expandColumn: 'Expand column'
  },

  ColumnRename: {
    rename: 'Rename'
  },

  MergeCells: {
    mergeCells: 'Merge cells',
    menuTooltip: 'Merge cells with same value when sorted by this column'
  },

  Search: {
    searchForValue: 'Search for value'
  },

  Sort: {
    sort: 'Sort',
    sortAscending: 'Sort ascending',
    sortDescending: 'Sort descending',
    multiSort: 'Multi sort',
    removeSorter: 'Remove sorter',
    addSortAscending: 'Add ascending sorter',
    addSortDescending: 'Add descending sorter',
    toggleSortAscending: 'Change to ascending',
    toggleSortDescending: 'Change to descending',
    sortAscendingShort: 'Ascending',
    sortDescendingShort: 'Descending',
    removeSorterShort: 'Remove',
    addSortAscendingShort: '+ Ascending',
    addSortDescendingShort: '+ Descending'
  },

  Column: {
    columnLabel: (column) =>
      `${column.text ? `${column.text} column. ` : ''}SPACE for context menu${
        column.sortable ? ', ENTER to sort' : ''
      }`
  },

  Checkbox: {
    toggleRowSelect: 'Toggle row selection',
    toggleSelection: 'Toggle selection of entire dataset'
  },

  RatingColumn: {
    cellLabel: (column) =>
      `${column.text ? column.text : ''} ${
        column.location?.record ? `rating : ${column.location.record[column.field] || 0}` : ''
      }`
  },

  CellMenu: {
    removeRow: 'Delete'
  },

  RowCopyPaste: {
    copyRecord: 'Copy',
    cutRecord: 'Cut',
    pasteRecord: 'Paste',
    rows: 'rows',
    row: 'row'
  },

  CellCopyPaste: {
    copy: 'Copy',
    cut: 'Cut',
    paste: 'Paste'
  },

  PdfExport: {
    'Waiting for response from server': 'Waiting for response from server...',
    'Export failed': 'Export failed',
    'Server error': 'Server error',
    'Generating pages': 'Generating pages...',
    'Click to abort': 'Cancel'
  },

  ExportDialog: {
    width: '40em',
    labelWidth: '12em',
    exportSettings: 'Export settings',
    export: 'Export',
    exporterType: 'Control pagination',
    cancel: 'Cancel',
    fileFormat: 'File format',
    rows: 'Rows',
    alignRows: 'Align rows',
    columns: 'Columns',
    paperFormat: 'Paper format',
    orientation: 'Orientation',
    repeatHeader: 'Repeat header'
  },

  ExportRowsCombo: {
    all: 'All rows',
    visible: 'Visible rows'
  },

  ExportOrientationCombo: {
    portrait: 'Portrait',
    landscape: 'Landscape'
  },

  SinglePageExporter: {
    singlepage: 'Single page'
  },

  MultiPageExporter: {
    multipage: 'Multiple pages',
    exportingPage: ({ currentPage, totalPages }) => `Exporting page ${currentPage}/${totalPages}`
  },

  MultiPageVerticalExporter: {
    multipagevertical: 'Multiple pages (vertical)',
    exportingPage: ({ currentPage, totalPages }) => `Exporting page ${currentPage}/${totalPages}`
  },

  RowExpander: {
    loading: 'Loading',
    expand: 'Expand',
    collapse: 'Collapse'
  },

  ResourceInfoColumn: {
    eventCountText: (data) => data + ' event' + (data !== 1 ? 's' : '')
  },

  Dependencies: {
    from: 'From',
    to: 'To',
    valid: 'Valid',
    invalid: 'Invalid'
  },

  DependencyType: {
    SS: 'SS',
    SF: 'SF',
    FS: 'FS',
    FF: 'FF',
    StartToStart: 'Start-to-Start',
    StartToEnd: 'Start-to-Finish',
    EndToStart: 'Finish-to-Start',
    EndToEnd: 'Finish-to-Finish',
    short: ['SS', 'SF', 'FS', 'FF'],
    long: ['Start-to-Start', 'Start-to-Finish', 'Finish-to-Start', 'Finish-to-Finish']
  },

  DependencyEdit: {
    From: 'From',
    To: 'To',
    Type: 'Type',
    Lag: 'Lag',
    'Edit dependency': 'Edit dependency',
    Save: 'Save',
    Delete: 'Delete',
    Cancel: 'Cancel',
    StartToStart: 'Start to Start',
    StartToEnd: 'Start to End',
    EndToStart: 'End to Start',
    EndToEnd: 'End to End'
  },

  EventEdit: {
    Name: 'Name',
    Resource: 'Resource',
    Start: 'Start',
    End: 'End',
    Save: 'Save',
    Delete: 'Delete',
    Cancel: 'Cancel',
    'Edit event': 'Edit event',
    Repeat: 'Repeat',
    Calendar: 'Calendar',
    'All day': 'All day',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    decade: 'Decade'
  },

  EventDrag: {
    eventOverlapsExisting: 'Event overlaps existing event for this resource',
    noDropOutsideTimeline: 'Event may not be dropped completely outside the timeline'
  },

  SchedulerBase: {
    'Add event': 'Add event',
    'Delete event': 'Delete event',
    'Unassign event': 'Unassign event'
  },

  TimeAxisHeaderMenu: {
    pickZoomLevel: 'Zoom',
    activeDateRange: 'Date range',
    startText: 'Start date',
    endText: 'End date',
    todayText: 'Today'
  },

  EventCopyPaste: {
    copyEvent: 'Copy event',
    cutEvent: 'Cut event',
    pasteEvent: 'Paste event'
  },

  EventFilter: {
    filterEvents: 'Filter tasks',
    byName: 'By name'
  },

  TimeRanges: {
    showCurrentTimeLine: 'Show current timeline'
  },

  PresetManager: {
    secondAndMinute: {
      displayDateFormat: 'll LTS',
      name: 'Seconds'
    },
    minuteAndHour: {
      topDateFormat: 'ddd MM/DD, hA',
      displayDateFormat: 'll LST'
    },
    hourAndDay: {
      topDateFormat: 'MM/DD',
      middleDateFormat: 'LST',
      displayDateFormat: 'l LST',
      name: 'Day'
    },
    day: {
      name: 'Day/hours'
    },
    week: {
      name: 'Week/hours'
    },
    dayAndWeek: {
      displayDateFormat: 'll LST',
      name: 'Week/days'
    },
    dayAndMonth: {
      name: 'Month'
    },
    weekAndDay: {
      displayDateFormat: 'll LST',
      name: 'Week'
    },
    weekAndMonth: {
      name: 'Weeks'
    },
    weekAndDayLetter: {
      name: 'Weeks/weekdays'
    },
    weekDateAndMonth: {
      name: 'Months/weeks'
    },
    monthAndYear: {
      name: 'Months'
    },
    year: {
      name: 'Years'
    },
    manyYears: {
      name: 'Multiple years'
    }
  },

  RecurrenceConfirmationPopup: {
    'delete-title': 'You are deleting an event',
    'delete-all-message': 'Do you want to delete all occurrences of this event?',
    'delete-further-message':
      'Do you want to delete this and all future occurrences of this event, or only the selected occurrence?',
    'delete-further-btn-text': 'Delete All Future Events',
    'delete-only-this-btn-text': 'Delete Only This Event',
    'update-title': 'You are changing a repeating event',
    'update-all-message': 'Do you want to change all occurrences of this event?',
    'update-further-message':
      'Do you want to change only this occurrence of the event, or this and all future occurrences?',
    'update-further-btn-text': 'All Future Events',
    'update-only-this-btn-text': 'Only This Event',
    Yes: 'Yes',
    Cancel: 'Cancel',
    width: 600
  },

  RecurrenceLegend: {
    ' and ': ' and ',
    Daily: 'Daily',
    'Weekly on {1}': ({ days }) => `Weekly on ${days}`,
    'Monthly on {1}': ({ days }) => `Monthly on ${days}`,
    'Yearly on {1} of {2}': ({ days, months }) => `Yearly on ${days} of ${months}`,
    'Every {0} days': ({ interval }) => `Every ${interval} days`,
    'Every {0} weeks on {1}': ({ interval, days }) => `Every ${interval} weeks on ${days}`,
    'Every {0} months on {1}': ({ interval, days }) => `Every ${interval} months on ${days}`,
    'Every {0} years on {1} of {2}': ({ interval, days, months }) =>
      `Every ${interval} years on ${days} of ${months}`,
    position1: 'the first',
    position2: 'the second',
    position3: 'the third',
    position4: 'the fourth',
    position5: 'the fifth',
    'position-1': 'the last',
    day: 'day',
    weekday: 'weekday',
    'weekend day': 'weekend day',
    daysFormat: ({ position, days }) => `${position} ${days}`
  },

  RecurrenceEditor: {
    'Repeat event': 'Repeat event',
    Cancel: 'Cancel',
    Save: 'Save',
    Frequency: 'Frequency',
    Every: 'Every',
    DAILYintervalUnit: 'day(s)',
    WEEKLYintervalUnit: 'week(s)',
    MONTHLYintervalUnit: 'month(s)',
    YEARLYintervalUnit: 'year(s)',
    Each: 'Each',
    'On the': 'On the',
    'End repeat': 'End repeat',
    'time(s)': 'time(s)'
  },

  RecurrenceDaysCombo: {
    day: 'day',
    weekday: 'weekday',
    'weekend day': 'weekend day'
  },

  RecurrencePositionsCombo: {
    position1: 'first',
    position2: 'second',
    position3: 'third',
    position4: 'fourth',
    position5: 'fifth',
    'position-1': 'last'
  },

  RecurrenceStopConditionCombo: {
    Never: 'Never',
    After: 'After',
    'On date': 'On date'
  },

  RecurrenceFrequencyCombo: {
    None: 'No repeat',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
  },

  RecurrenceCombo: {
    None: 'None',
    Custom: 'Custom...'
  },

  Summary: {
    'Summary for': (date) => `Summary for ${date}`
  },

  ScheduleRangeCombo: {
    completeview: 'Complete schedule',
    currentview: 'Visible schedule',
    daterange: 'Date range',
    completedata: 'Complete schedule (for all events)'
  },

  SchedulerExportDialog: {
    'Schedule range': 'Schedule range',
    'Export from': 'From',
    'Export to': 'To'
  },

  ExcelExporter: {
    'No resource assigned': 'No resource assigned'
  },

  CrudManagerView: {
    serverResponseLabel: 'Server response:'
  },

  DurationColumn: {
    Duration: 'Duration'
  },

  EventMenu: {
    duplicateEvent: 'Duplicate event',
    copy: 'copy'
  },

  Calendar: {
    Today: 'Today',
    next: (range) => `Next ${range}`,
    previous: (range) => `Previous ${range}`,
    plusMore: (value) => `+${value} more`,
    allDay: 'All day',
    endsOn: (d) => `Ends ${d}`,
    weekOfYear: ([y, w]) => `Week ${w}, ${y}`,
    loadFail: 'Calendar data load failed. Please contact your system administrator'
  },

  CalendarDrag: {
    holdCtrlForRecurrence: 'Hold CTRL for a recurring event'
  },

  CalendarMixin: {
    eventCount: (count) => `${count || 'No'} event${count === 1 ? '' : 's'}`
  },

  EventTip: {
    'Edit event': 'Edit event',
    timeFormat: 'LST'
  },

  ModeSelector: {
    includeWeekends: 'Include weekends',
    weekends: 'Weekends'
  },

  AgendaView: {
    Agenda: 'Agenda'
  },

  MonthView: {
    Month: 'Month',
    monthUnit: 'month'
  },

  WeekView: {
    weekUnit: 'week'
  },

  YearView: {
    Year: 'Year',
    yearUnit: 'year'
  },

  EventList: {
    List: 'List',
    Start: 'Start',
    Finish: 'End'
  },

  DayView: {
    Day: 'Day',
    dayUnit: 'day',
    daysUnit: 'days',
    expandAllDayRow: 'Expand all-day section',
    collapseAllDayRow: 'Collapse all-day section',
    timeFormat: 'LST'
  },

  Sidebar: {
    'Filter events': 'Filter events'
  },

  WeekExpander: {
    expandTip: 'Click to expand row',
    collapseTip: 'Click to collapse row'
  }
};

export default LocaleHelper.publishLocale('en-US', localeENUS);
