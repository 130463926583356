import {
  DragNDropComponents,
  TabOptions
} from '@/components/organisms/CurriculumSetup/CurriculumSetup.const';
import { CurriculumForm, Session, Topic } from '@/types';
import {
  Dispatch,
  MouseEvent,
  MutableRefObject,
  RefObject,
  SetStateAction,
  createContext
} from 'react';
import { UseFormSetValue } from 'react-hook-form';

export type SelectedTopic = { id: Topic['id']; index: number; sourceSession: SelectedSession };
export type SelectedSession = { id: Session['id']; index: number };

export enum ActionTypes {
  ADD,
  DELETE,
  DELETE_ALL,
  REPLACE
}

export const DEFAULT_TOPIC_COLOR = '#a0a29e';
export const DEFAULT_TOPIC_DURATION = 60;
export const DEFAULT_TOPIC_START_TIME = '00:00';
export const DEFAULT_ID = '0';
export type handleTypes<T> =
  | { type: ActionTypes.ADD; selection: T }
  | { type: ActionTypes.DELETE; selection: T }
  | { type: ActionTypes.DELETE_ALL; selection?: T }
  | { type: ActionTypes.REPLACE; selection: T[] };

export enum ScaleConstraints {
  MAX = 8,
  MIN = 1
}

export const ZOOM_VALUES = [1, 2, 3, 4, 6, 8];

export type Position = {
  x: number;
  y: number;
};

export type CurriculumSetupContextType = {
  onMouseMove: (e: MouseEvent<HTMLDivElement>) => void;
  stopScrolling: (setValue: UseFormSetValue<CurriculumForm>) => void;
  onDragToScroll: (htmlDiv: HTMLDivElement, posX: number, sessionId: string) => void;
  currentSession: RefObject<HTMLDivElement>;
  selectedTopics: SelectedTopic[];
  handleSelectedTopics: (params: handleTypes<SelectedTopic>) => void;
  selectedSessions: SelectedSession[];
  selectAllSessions: (sessions: Session[]) => void;
  handleSelectedSessions: (params: handleTypes<SelectedSession>) => void;
  getUniqueKey: () => number;
  readOnly: boolean;
  setReadOnly: (value: boolean) => void;
  resetSelectedElements: () => void;
  setCounter: (value: number) => void;
  selectedType: TabOptions;
  setSelectedType: (value: TabOptions) => void;
  position: Position;
  setPosition: (value: Position) => void;
  isOverSession: boolean;
  setIsOverSession: (value: boolean) => void;
  globalDragging: boolean;
  setGlobalDragging: (value: boolean) => void;
  currentTab: string;
  setCurrentTab: (value: string) => void;
  highlightedItems: string[];
  setHighlightedItems: (value: string[]) => void;
  createNewElement: (
    type:
      | DragNDropComponents.NEW_SESION
      | DragNDropComponents.NEW_TIME_SESSION
      | DragNDropComponents.NEW_TOPIC
  ) => Topic | Session;
  isError: MutableRefObject<boolean>;
  setIsError: (value: boolean) => void;
  selectedResourceSlots: string[];
  setSelectedResourceSlots: Dispatch<SetStateAction<string[]>>;
  selectedContinuities: string[];
  setSelectedContinuities: Dispatch<SetStateAction<string[]>>;
};

export default createContext<CurriculumSetupContextType>({} as CurriculumSetupContextType);
